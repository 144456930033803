import moment from 'moment';
import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

interface RelativeTimeProps {
  date: string;
  shortFormat?: boolean;
}

const RelativeTime: React.FC<RelativeTimeProps> = ({ date, shortFormat = false }) => {
  const { t } = useTranslation('datesAndTime');

  const format = shortFormat
    ? {
        sameDay: 'LT',
        lastDay: t('yesterday'),
        lastWeek: 'L',
        sameElse: 'L',
      }
    : {
        sameDay: t('todayAt', { time: 'LT' }),
        lastDay: t('yesterdayAt', { time: 'LT' }),
        lastWeek: t('someDayAt', { date: 'L', time: 'LT' }),
        sameElse: t('someDayAt', { date: 'L', time: 'LT' }),
      };

  // Have to return a fragment due to React typings not being able to handle
  // string components:
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
  return <>{moment(date).calendar(undefined, format)}</>;
};

export default RelativeTime;
