import { Button, PageLoader } from '@cosuno/cosuno-ui';
import * as Sentry from '@sentry/browser';
import React, { type ErrorInfo, type PropsWithChildren } from 'react';
import { type WithTranslation, withTranslation } from 'react-i18next';

import Console from '~/shared/utils/console';
import { isNetworkError as getIsNetworkError } from '~/shared/utils/errors';

import { Heading, Text, Wrapper } from './Styles';

interface ErrorBoundaryState {
  hasError: boolean;
  isNetworkError: boolean;
  reloading: boolean;
}

type ErrorBoundaryProps = PropsWithChildren<WithTranslation>;

class ErrorBoundaryWithoutTranslation extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false, isNetworkError: false, reloading: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Console.log({ error });
    Sentry.withScope((scope) => {
      scope.setExtras({ ...errorInfo });
      Sentry.captureException(error);
    });
    this.setState({ isNetworkError: getIsNetworkError(error) });
  }

  private readonly reload = () => {
    // Show a PageLoader to give the user instant feedback when clicking "reload"
    this.setState({ reloading: true });
    window.location.reload();
  };

  render() {
    const { children, t } = this.props;
    const { hasError, isNetworkError, reloading } = this.state;

    const { title, message } = isNetworkError
      ? {
          title: t('networkError.title'),
          message: t('networkError.message'),
        }
      : {
          title: t('heading'),
          message: t('explanation'),
        };

    const getContent = () => {
      if (reloading) return <PageLoader />;

      return (
        <>
          <Heading>{title}</Heading>
          <Text>{message}</Text>
          <Button icon="refresh" onClick={this.reload}>
            {t('reloadButton')}
          </Button>
        </>
      );
    };

    if (hasError) {
      return <Wrapper>{getContent()}</Wrapper>;
    }

    return children;
  }
}

export const ErrorBoundary = withTranslation('errorBoundary')(ErrorBoundaryWithoutTranslation);
