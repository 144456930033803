import type React from 'react';
import { useEffect } from 'react';

import { COMPANY_TYPE, type CurrentUserQuery } from '~/__gql__/graphql';
import {
  type CompanyFeatureFlags,
  isCompanyFeatureEnabled,
  useFeatureFlags,
} from '~/packages/FeatureFlags';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import { env } from '~/shared/utils/env';
import injectScript from '~/shared/utils/injectScript';
import { assertUnreachable } from '~/shared/utils/typescript';

const getBeamerCustomUserAttributes = (
  user: CurrentUserQuery['me'],
  featureFlags: CompanyFeatureFlags,
) => {
  const enabledFeatureFlagsJoined = Object.entries(featureFlags)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join(';');
  const baseAttrs = {
    enabled_feature_flags: enabledFeatureFlagsJoined,
    user_role: user.type,
    company_id: user.company.id,
  };

  switch (user.company.type) {
    case COMPANY_TYPE.principal:
      return {
        ...baseAttrs,
        is_enterprise_opportunity: Boolean(user.company.isEnterpriseOpportunity),
        package_type: user.company.packageType,
      };
    case COMPANY_TYPE.agent:
      return {
        ...baseAttrs,
        is_network_subcontractor_associated: Boolean(
          user.company.hasNetworkSubcontractorsAssociated,
        ),
        is_subcontractor_profile_completed: Boolean(user.company.isAgentProfileComplete),
        bid_request_count: Number(user.company.bidRequestsCount),
        bid_request_with_bids_count: Number(user.company.bidRequestsWithSubmittedBidsCount),
        marketplace_bid_request_with_bids_count: Number(user.company.bidsSubmittedOnMarketplace),
        public_certificates_count: Number(user.company.submittedCertificatesCount),
      };
    default:
      assertUnreachable(user.company.type);
  }
};

const BeamerScript: React.FC = () => {
  const user = useCurrentUser();
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (!user || env.REACT_APP_BEAMER_DISABLED === 'true') return;

    const beamerNotificationsEnabled = isCompanyFeatureEnabled(
      user.company,
      'conditionalBeamerNotifications',
    );
    const filters = [
      `company-type-${user.company.type}`,
      ...(beamerNotificationsEnabled ? ['notifications-enabled'] : []),
    ];

    const beamerCustomAttributes = getBeamerCustomUserAttributes(user, featureFlags);

    window.beamer_config = {
      product_id: env.REACT_APP_BEAMER_PRODUCT_ID,
      language: user.language,
      top: -1,
      right: 0,
      button: false,
      bounce: false,
      user_id: user.id,
      user_email: user.email,
      user_firstname: decodeURIComponent(encodeURIComponent(user.firstName || '')),
      user_lastname: decodeURIComponent(encodeURIComponent(user.lastName || '')),
      company_type: user.company.type,
      filter: filters.join(';'),
      ...beamerCustomAttributes,
    };

    injectScript({ src: 'https://app.getbeamer.com/js/beamer-embed.js', defer: true });
  }, [user, featureFlags]);

  return null;
};

export default BeamerScript;
