export const TABLE_ITEMS_PER_PAGE = 10;
export const DROPDOWN_ITEMS_PER_PAGE = 20;

/**
 * The maximum number of items a table can have in order to use the "open all
 * items" feature. If there are more items, the feature should be disabled. This
 * is a performance optimization.
 */
export const OPEN_ALL_TABLE_ITEMS_THRESHOLD = 400;

export const CARD_OPENING_TRANSITION_DURATION = 200;

export const PROMPT_HEIGHT = '--prompt-height';
