import React, { useCallback } from 'react';

import type { CURRENCY } from '~/__gql__/graphql';
import useTranslation from '~/shared/hooks/useTranslation';

const NON_BREAKING_SPACE = '\u00A0';

interface SharedProps {
  value: number;
  trailingZeros?: number;
  minimumTrailingZeros?: number;
  showSign?: boolean;
}

interface CurrencyProps extends SharedProps {
  type: 'currency';
  currencyCode: CURRENCY;
}

interface PercentOrPlainProps extends SharedProps {
  type: 'percent' | 'plain';
}

export type NumberFormatterProps = CurrencyProps | PercentOrPlainProps;

const formatNumber = (locale: string, props: NumberFormatterProps): string => {
  const { value, trailingZeros = 2, minimumTrailingZeros, showSign } = props;
  const formattedValue = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumTrailingZeros ?? Math.min(trailingZeros, 2),
    maximumFractionDigits: trailingZeros,
    signDisplay: showSign ? 'always' : undefined,
  }).format(Number(value));

  if (props.type === 'currency') {
    return formattedValue + NON_BREAKING_SPACE + props.currencyCode;
  }

  if (props.type === 'percent') {
    return `${formattedValue + NON_BREAKING_SPACE}%`;
  }

  return formattedValue;
};

export const useNumberFormatter = () => {
  const { i18n } = useTranslation();

  return useCallback(
    (props: NumberFormatterProps) => formatNumber(i18n.language, props),
    [i18n.language],
  );
};

const NumberFormatter: React.FC<NumberFormatterProps> = (props) => {
  const format = useNumberFormatter();

  return <>{format(props)}</>;
};

export default NumberFormatter;
