import { useActive, useChainedCommands } from '@remirror/react';
import React from 'react';

import { belongsToEnum } from '~/shared/utils/typescript';

import { TEXT_EDITOR_FONT_FAMILY } from './constants';
import { StyledFontFamilySelect, StyledFontFamilySelectOption } from './Styles';

const ARIAL = { fontKey: TEXT_EDITOR_FONT_FAMILY.arial, fontLabel: 'Arial' } as const;
const COURIER = {
  fontKey: TEXT_EDITOR_FONT_FAMILY.courierNew,
  fontLabel: 'Courier New',
} as const;
const TIMES_NEW_ROMAN = {
  fontKey: TEXT_EDITOR_FONT_FAMILY.timesNewRoman,
  fontLabel: 'Times New Roman',
} as const;
const FONTS = [ARIAL, COURIER, TIMES_NEW_ROMAN];
const getFontByKey = (fontKey: string) => {
  if (!belongsToEnum(fontKey, TEXT_EDITOR_FONT_FAMILY)) {
    return;
  }

  return FONTS.find((font) => font.fontKey === fontKey);
};

const FontFamilySelect: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const chain = useChainedCommands();
  const active = useActive();

  const options = FONTS.map(({ fontKey, fontLabel }) => ({
    value: fontKey,
    label: fontLabel,
  }));

  const currentFont =
    FONTS.find(({ fontLabel }) => active.fontFamily({ fontFamily: fontLabel })) ?? ARIAL;

  const handleChange = (newFontKey: string) => {
    const newFont = getFontByKey(newFontKey);
    if (!newFont || newFont.fontKey === currentFont.fontKey) {
      return;
    }

    chain.setFontFamily(newFont.fontLabel).focus().run();
  };

  return (
    <StyledFontFamilySelect
      name="font-family-select"
      required
      disabled={disabled}
      fixedDropdownCardWidth={170}
      dropdownPlacement="bottom-start"
      showSearch={false}
      size="small"
      value={currentFont.fontKey}
      options={options}
      onChange={handleChange}
      renderOption={({ downshift, index, option, ...props }) => (
        <StyledFontFamilySelectOption
          key={index}
          $fontFamily={getFontByKey(option.value ?? '')?.fontLabel}
          isDisplayed={downshift.isOpen}
          {...downshift.getItemProps({ item: option, index })}
          {...props}
        >
          {option.label}
        </StyledFontFamilySelectOption>
      )}
    />
  );
};

export default FontFamilySelect;
