import { Button, EmptyContent, PageLoader } from '@cosuno/cosuno-ui';
import { groupBy, intersectionBy } from 'lodash';
import { useMemo, useState } from 'react';

import { WORK_CATEGORY_TYPE_FILTER } from '~/__gql__/graphql';
import Focus from '~/shared/components/Focus';
import Trans from '~/shared/components/Trans';
import { MAX_LIST_LENGTH } from '~/shared/constants';
import useDebouncedQuery from '~/shared/hooks/useDebouncedQuery';
import useLanguage from '~/shared/hooks/useLanguage';
import useTranslation from '~/shared/hooks/useTranslation';
import { intercom } from '~/shared/utils/intercom';
import { queryWorkCategoriesWithGroups } from '~/shared/utils/queries';
import { assertWorkCategoriesWithGroups } from '~/shared/utils/workCategories';

import { SingleGroup } from './SingleGroup';
import {
  AllGroupsWrapper,
  ContentBody,
  ContentHeader,
  ContentWrapper,
  Feedback,
  FeedbackButton,
  Footer,
  StyledButtonGroup,
  StyledSearchInput,
} from './Styles';
import type { GroupsForDisplay, ModalContentProps } from './types';

export const ModalContent: React.FC<ModalContentProps> = ({
  onCloseModal,
  selected,
  setValue,
  onSave,
  showIsNewIndicators,
}) => {
  const { t } = useTranslation('workCategoriesAdvancedSearch');
  const [expandedGroup, setExpandedGroup] = useState<string | null>(null);
  const [query, setQuery] = useState('');
  const language = useLanguage();

  const queryResult = useDebouncedQuery(queryWorkCategoriesWithGroups, {
    variables: {
      query,
      type: WORK_CATEGORY_TYPE_FILTER.network,
      limit: MAX_LIST_LENGTH,
      language,
    },
  });
  const loading = queryResult.loading && !queryResult.called;

  const groups: GroupsForDisplay = useMemo(() => {
    const selectedIds = selected.map(({ id }) => id);

    const workCategories = queryResult?.data?.workCategories.workCategories ?? [];

    assertWorkCategoriesWithGroups(workCategories);

    return Object.entries(groupBy(workCategories, (item) => item.group.id))
      .map(([, groupedWorkCategories]) => ({
        id: groupedWorkCategories[0].group.name,
        name: groupedWorkCategories[0].group.translation,
        workCategories: groupedWorkCategories.map((workCategory) => ({
          ...workCategory,
          selected: selectedIds.includes(workCategory.id),
        })),
        selectedCount: intersectionBy(selected, groupedWorkCategories, (item) => item.id).length,
        position: groupedWorkCategories[0].group.position,
      }))
      .sort((a, b) => a.position - b.position);
  }, [queryResult?.data?.workCategories.workCategories, selected]);

  return (
    <ContentWrapper>
      <ContentHeader>{t('modalTitle')}</ContentHeader>
      <ContentBody>
        <div>
          <Focus>
            {({ ref }) => (
              <StyledSearchInput forwardRef={ref} data-cy-search-input onChange={setQuery} />
            )}
          </Focus>
        </div>
        {loading && <PageLoader />}
        {!loading && (
          <AllGroupsWrapper>
            {groups.length > 0 &&
              groups.map((group) => (
                <SingleGroup
                  key={group.id}
                  isExpanded={query.length > 0 || expandedGroup === group.id}
                  setValue={setValue}
                  selected={selected}
                  setExpanded={setExpandedGroup}
                  showIsNewIndicators={showIsNewIndicators}
                  {...group}
                />
              ))}
            {groups.length === 0 && (
              <EmptyContent>
                <EmptyContent.CallToAction>{t('searchable.noResults')}</EmptyContent.CallToAction>
                <EmptyContent.Explanation>{t('emptyState')}</EmptyContent.Explanation>
              </EmptyContent>
            )}
          </AllGroupsWrapper>
        )}
      </ContentBody>
      <Footer>
        <Feedback>
          <Trans
            t={t}
            i18nKey="feedback"
            components={{
              Link: (
                <FeedbackButton
                  onClick={() => {
                    onCloseModal();
                    intercom.open();
                  }}
                />
              ),
              br: <br />,
            }}
          />
        </Feedback>
        <StyledButtonGroup>
          <Button variant="secondary" onClick={onCloseModal}>
            {t('cancel')}
          </Button>
          <Button
            data-cy-button-save
            onClick={onSave}
            disabled={selected.length === 0}
            variant="primary"
          >
            {t('saveButton', { count: selected.length })}
          </Button>
        </StyledButtonGroup>
      </Footer>
    </ContentWrapper>
  );
};
