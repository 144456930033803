import { Decimal } from 'decimal.js';
import { intersection, pick } from 'lodash';
import type { ElementOf } from 'ts-essentials';

import type { BidInput, DraftBid } from '~/__gql__/graphql';
import type { ApolloCache } from '~/apolloClient';
import type { TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import { typeSafeFromEntries, typeSafeObjectKeys } from '~/shared/utils/typescript';

// TODO: Create a package for this, so there is no duplication between frontend and backend.
// Until then, keep this in sync with api/src/utils/bids.ts.

interface TotalAndDiscountValues {
  totalValue: number | Decimal | null;
  discountRate: number | Decimal | null;
  absoluteDiscount: number | Decimal | null;
}

export interface PriceDetailsInput {
  absoluteDiscount: number | null;
  additionalCosts?: number | null;
  discountRate: number | null;
  skontoRate: number | null;
  taxRate: number | null;
  totalNet: number | null;
}

export interface PriceDetails {
  absoluteDiscount: number;
  additionalCosts: number;
  discountRate: number;
  skontoRate: number;
  skontoValue: number;
  taxRate: number;
  taxValue: number;
  totalGross: number;
  totalGrossWithSkonto: number;
  totalNet: number;
  totalNetWithDiscount: number;
}

export const getDiscountValue = ({
  totalValue: totalValueProp,
  discountRate: discountRateProp,
  absoluteDiscount: absoluteDiscountProp,
}: TotalAndDiscountValues) => {
  const totalValue = new Decimal(totalValueProp ?? 0).toDecimalPlaces(2);
  const discountRate = new Decimal(discountRateProp ?? 0).toDecimalPlaces(4);
  const absoluteDiscount = new Decimal(absoluteDiscountProp ?? 0).toDecimalPlaces(2);

  if (!discountRate.equals(0)) {
    // To be consistent with GAEB, the total has to be multiplied with 1 - discount rate, and then
    // rounded to 2 decimal places.
    const totalWithDiscount = totalValue
      .times(new Decimal(1).minus(new Decimal(discountRate)))
      .toDecimalPlaces(2);
    return totalValue.minus(new Decimal(totalWithDiscount)).toNumber();
  }

  if (!absoluteDiscount.equals(0)) {
    return absoluteDiscount.toNumber();
  }

  return 0;
};

export const getDiscountPercent = ({
  totalValue: totalValueProp,
  discountRate: discountRateProp,
  absoluteDiscount: absoluteDiscountProp,
}: TotalAndDiscountValues) => {
  const totalValue = new Decimal(totalValueProp ?? 0).toDecimalPlaces(2);
  const discountRate = new Decimal(discountRateProp ?? 0).toDecimalPlaces(4);
  const absoluteDiscount = new Decimal(absoluteDiscountProp ?? 0).toDecimalPlaces(2);

  if (!discountRate.equals(0)) {
    return new Decimal(discountRate).toNumber();
  }

  if (!absoluteDiscount.equals(0) && !totalValue.equals(0)) {
    return new Decimal(absoluteDiscount).dividedBy(totalValue).toDecimalPlaces(4).toNumber();
  }

  return 0;
};

export const getBidPriceDetails = ({
  absoluteDiscount: absoluteDiscountProp,
  additionalCosts: additionalCostsProp,
  discountRate: discountRateProp,
  skontoRate: skontoRateProp,
  taxRate: taxRateProp,
  totalNet: totalNetProp,
}: PriceDetailsInput): PriceDetails => {
  const absoluteDiscount = new Decimal(absoluteDiscountProp ?? 0).toDecimalPlaces(2);
  const additionalCosts = new Decimal(additionalCostsProp ?? 0).toDecimalPlaces(2);
  const discountRate = new Decimal(discountRateProp ?? 0).toDecimalPlaces(4);
  const skontoRate = new Decimal(skontoRateProp ?? 0).toDecimalPlaces(4);
  const taxRate = new Decimal(taxRateProp ?? 0).toDecimalPlaces(4);
  const totalNet = new Decimal(totalNetProp ?? 0).toDecimalPlaces(2);

  const derivedDiscountRate = getDiscountPercent({
    totalValue: totalNet,
    discountRate,
    absoluteDiscount,
  });

  const derivedAbsoluteDiscount = getDiscountValue({
    totalValue: totalNet,
    discountRate,
    absoluteDiscount,
  });

  const totalNetWithDiscount = totalNet.minus(derivedAbsoluteDiscount).plus(additionalCosts);

  const taxValue = totalNetWithDiscount.times(taxRate).toDecimalPlaces(2);
  const totalGross = totalNetWithDiscount.plus(taxValue);

  const skontoValue = getDiscountValue({
    totalValue: totalGross,
    discountRate: skontoRate,
    absoluteDiscount: null,
  });

  const totalGrossWithSkonto = totalGross.minus(skontoValue);

  return {
    absoluteDiscount: derivedAbsoluteDiscount,
    additionalCosts: additionalCosts.toNumber(),
    discountRate: derivedDiscountRate,
    skontoRate: skontoRate.toNumber(),
    skontoValue,
    taxRate: taxRate.toNumber(),
    taxValue: taxValue.toNumber(),
    totalGross: totalGross.toNumber(),
    totalGrossWithSkonto: totalGrossWithSkonto.toNumber(),
    totalNet: totalNet.toNumber(),
    totalNetWithDiscount: totalNetWithDiscount.toNumber(),
  };
};

interface BidForTitle {
  revision: number;
  version: number | string;
  isSimulated: boolean | null;
}

export const getBidTitle = (t: TypeSafeTFunction, bid: BidForTitle) =>
  t(bid.isSimulated ? 'common:bid.simulatedBidNumber' : 'common:bid.bidNumber', {
    revision: bid.revision,
    version: bid.version,
  });

export const getBidTitleWithDate = (t: TypeSafeTFunction, bid: { date: string } & BidForTitle) =>
  t(bid.isSimulated ? 'common:bid.simulatedBidNumberWithDate' : 'common:bid.bidNumberWithDate', {
    revision: bid.revision,
    version: bid.version,
    date: bid.date,
  });

const bidDetailsProperties = [
  'totalNet',
  'skontoRate',
  'skontoDeadline',
  'discountRate',
  'absoluteDiscount',
  'taxRate',
  'message',
] as const;

type DraftBidDetails = Pick<DraftBid, ElementOf<typeof bidDetailsProperties>>;

/**
 * Updates a draft bid's details with the values received in a mutation result.
 *
 * Locally, only the properties that we actually tried to change will be updated.
 *
 * To be called after a successful mutation of a draft bid's summary.
 */
export const applyDraftBidDetailsChangesToCache = (
  cache: ApolloCache,
  draftBidId: string,
  upToDateDetails: Partial<DraftBidDetails>,
  input: BidInput,
) => {
  const upToDateSummaryValues = pick({ ...upToDateDetails }, bidDetailsProperties);

  /** Bid details properties that we attempted to change. */
  const changedProperties = typeSafeObjectKeys<Partial<DraftBidDetails>>(input).filter(
    (key) => input[key] !== undefined,
  );
  /** Keys of bid details properties that we attempted to change and received back as well. */
  const upToDateKeys = intersection(typeSafeObjectKeys(upToDateSummaryValues), changedProperties);

  cache.modify({
    id: cache.identify({ __typename: 'DraftBid', id: draftBidId }),
    fields: {
      ...typeSafeFromEntries(upToDateKeys.map((key) => [key, () => upToDateDetails[key]])),
    },
  });
};

export const getRevisionAndVersion = (
  currentRevision: number | null,
  bid: {
    id: string;
    version: number | null;
    parentBid: {
      id: string;
      version: number | null;
      billOfQuantitiesRevision: { revision: number | null };
    } | null;
  },
) => {
  if (bid.parentBid) {
    return {
      revision: bid.parentBid.billOfQuantitiesRevision.revision ?? 1,
      version: bid.parentBid.version ?? 1,
    };
  }

  return { revision: currentRevision ?? 1, version: bid.version ?? 1 };
};
