import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { queryStringToObject } from '~/shared/utils/url';

import useRoutes, { CompanySettingsTabs } from './useRoutes';
import useUserAuthState from './useUserAuthState';

export const useAgentSignupRedirectRoute = () => {
  const routes = useRoutes();
  const { isUserAuthenticated } = useUserAuthState();

  const matchesReverseMarketplace = useRouteMatch(routes.agentReverseMarketplace());
  const matchesReverseMarketplaceBidPackage = useRouteMatch(routes.reverseMarketplaceBidPackage());
  const matchesReverseMarketplaceSuggestion = useRouteMatch(
    routes.agentReverseMarketplaceSuggestion(),
  );
  const matchesAgentPremium = useRouteMatch(routes.premium());
  const matchesLegacyAgentPremium = useRouteMatch(
    routes.company({ tab: CompanySettingsTabs.premium }),
  );
  const matchesShowTask = useRouteMatch<{ id: string }>(routes.showTask());
  const shouldRedirectToSignup =
    !isUserAuthenticated &&
    (matchesReverseMarketplace ||
      matchesReverseMarketplaceBidPackage ||
      matchesReverseMarketplaceSuggestion ||
      matchesAgentPremium ||
      matchesLegacyAgentPremium ||
      matchesShowTask);

  const getFullRoute = useCallback(() => {
    if (matchesShowTask) {
      const search = queryStringToObject<{
        sentEmailId?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
      }>(window.location.search);

      // if query does not contain sentEmailId, redirect to default login page
      if (!search.sentEmailId) {
        return undefined;
      }

      // if query contains sentEmailUrl, redirect to custom login page
      return routes.taskLogin({
        ...search,
        taskId: matchesShowTask.params.id,
      });
    }

    return routes.agentSignup({ redirect: window.location.pathname });
  }, [routes, matchesShowTask]);

  return useMemo(() => {
    if (!shouldRedirectToSignup) {
      return undefined;
    }

    const fullRoute = getFullRoute();

    if (!fullRoute) {
      return undefined;
    }

    const [pathname, search] = fullRoute.split('?');

    return {
      pathname,
      search,
      fullRoute,
    };
  }, [shouldRedirectToSignup, getFullRoute]);
};
