import { graphql } from '~/__gql__';

export const mutationRequestMultipleOfficesUpsell = graphql(/* GraphQL */ `
  mutation RequestMultipleOfficesUpsell {
    requestMultipleOfficesUpsell
  }
`);

export const mutationRequestCustomSignaturesUpsell = graphql(/* GraphQL */ `
  mutation RequestCustomSignaturesUpsell {
    requestCustomSignaturesUpsell
  }
`);
