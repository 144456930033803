import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

import { ErrorScrollMarker } from './Styles';

interface Props {
  name: string;
}

const FieldErrorScrollMarker: React.FC<Props> = ({ name }) => {
  const errorScrollMarkerRef = useRef<HTMLDivElement | null>(null);
  const { isValid, isSubmitting, errors } = useFormikContext();

  useEffect(() => {
    const getFirstErrorKey = (errorObj: { [key: string]: unknown }) => {
      const [[topLevelKey, topLevelValue]] = Object.entries(errorObj);
      if (Array.isArray(topLevelValue)) {
        for (const [index, nestedValue] of topLevelValue.entries()) {
          if (nestedValue && Object.keys(nestedValue).length !== 0) {
            // Works for one level of form nesting, such as exists in the subcontractor modal
            return `${topLevelKey}.${index}.${Object.keys(nestedValue)[0]}`;
          }
        }
      }

      return topLevelKey;
    };
    if (isSubmitting && !isValid) {
      const firstErrorKey = getFirstErrorKey(errors);
      if (firstErrorKey === name) {
        errorScrollMarkerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [isSubmitting, name, isValid, errors, errorScrollMarkerRef]);

  return <ErrorScrollMarker ref={errorScrollMarkerRef} />;
};

export default FieldErrorScrollMarker;
