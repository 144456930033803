import { Button, Cell, RadioButton, Table } from '@cosuno/cosuno-ui';
import React from 'react';

import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import type { WorkCategoryInputForSubcontractorWithId } from '~/shared/types/workCategories';

import type { AgentWorkCategoriesFieldProps } from '.';
import { NameCell } from './Styles';

export interface RowProps extends AgentWorkCategoriesFieldProps {
  workCategory: WorkCategoryInputForSubcontractorWithId;
}

export const Row: React.FC<RowProps> = (props) => {
  const { isReadOnly, onChange, value, workCategory } = props;

  const { trackEvent } = useAnalytics();
  const user = useCurrentUser();

  const trackingProps = {
    userId: user?.id,
    userEmail: user?.email,
    workCategoryId: workCategory.id,
    workCategoryName: workCategory.name,
  };

  return (
    <Table.Row
      data-cy-work-categories-row={workCategory.name}
      size="compact"
      key={workCategory.id}
      rowId={workCategory.id}
    >
      <NameCell>{workCategory.name}</NameCell>
      <Cell.Base alignItems="center">
        <RadioButton
          checked={workCategory.isPrimary}
          disabled={isReadOnly}
          onChange={() => {
            trackEvent('myCompanySubcontractorProfilePrimaryWorkCategory', trackingProps);
            onChange(
              value.map((item) => ({
                ...item,
                isPrimary: item.id === workCategory.id ? true : item.isPrimary,
              })),
            );
          }}
        />
      </Cell.Base>
      <Cell.Base alignItems="center">
        <RadioButton
          checked={!workCategory.isPrimary}
          disabled={isReadOnly}
          onChange={() => {
            trackEvent('myCompanySubcontractorProfileSecondaryWorkCategory', trackingProps);
            onChange(
              value.map((item) => ({
                ...item,
                isPrimary: item.id === workCategory.id ? false : item.isPrimary,
              })),
            );
          }}
        />
      </Cell.Base>
      {!isReadOnly && (
        <Cell.Base>
          <Button
            data-cy-work-categories-delete
            onlyIcon={{ type: 'delete' }}
            onClick={() => {
              onChange(value.filter((item) => item.id !== workCategory.id));
            }}
            variant="secondaryDanger"
          />
        </Cell.Base>
      )}
    </Table.Row>
  );
};
