const NETWORK_ERROR_MESSAGE = 'Network Error';
const APOLLO_NETWORK_ERROR_MESSAGE = 'Failed to fetch';
const CHUNK_LOAD_ERROR_NAME = 'ChunkLoadError';
const DYNAMIC_IMPORT_ERROR_MESSAGE_PREFIX = 'Failed to fetch dynamically imported module';

export const isNetworkError = (error: Error) =>
  error.message === NETWORK_ERROR_MESSAGE ||
  error.message === APOLLO_NETWORK_ERROR_MESSAGE ||
  error.name === CHUNK_LOAD_ERROR_NAME ||
  error.message.startsWith(DYNAMIC_IMPORT_ERROR_MESSAGE_PREFIX);

export default {
  invalidBoQ: () => new Error('Invalid BoQ content'),
};
