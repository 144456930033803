import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { type PRICING_PLAN, USER_TYPE } from '~/__gql__/graphql';
import { generateRoutes } from '~/shared/constants/routes';
import AnalyticsEntryPointContext, {
  type ANALYTICS_ENTRY_POINT,
} from '~/shared/contexts/analyticsEntryPointContext';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useModalState from '~/shared/hooks/useModalState';
import toast from '~/shared/utils/toast';

import { PricingPlanModal } from './PricingPlanModal';

const routes = generateRoutes({ withoutWorkspace: true });

export default function useShowPricingPlan(
  highlightedPlan: PRICING_PLAN | null = null,
  entrypoint?: ANALYTICS_ENTRY_POINT,
) {
  const history = useHistory();
  const user = useCurrentUser();
  const modalState = useModalState();

  const showPricingPlan = useCallback(() => {
    try {
      if (user?.type === USER_TYPE.administrator) {
        history.push(routes.pricingPlans({ plan: highlightedPlan ?? undefined }), { entrypoint });
      } else {
        modalState.openModal();
      }
    } catch (error) {
      toast.error(error);
    }
  }, [entrypoint, highlightedPlan, history, modalState, user?.type]);

  const renderModal = () => (
    <AnalyticsEntryPointContext.Provider value={entrypoint}>
      <PricingPlanModal highlightedPlan={highlightedPlan} {...modalState} />
    </AnalyticsEntryPointContext.Provider>
  );

  return { renderModal, showPricingPlan };
}
