import React from 'react';

// https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript/3561711#3561711
const escapeRegex = (string: string) => string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

interface HighlightedSubstringsProps {
  string: string;
  substring: string;
}

export const HighlightedSubstrings: React.FC<HighlightedSubstringsProps> = ({
  string,
  substring,
}) => {
  // utilises regex to facilitate case insensitive splitting
  const stringFragments = substring.length
    ? string.split(new RegExp(`(${escapeRegex(substring)})`, 'gi'))
    : [string];

  return (
    <>
      {stringFragments.map((stringFragment, i) =>
        stringFragment.toLocaleLowerCase() === substring.toLocaleLowerCase() ? (
          <strong key={`${stringFragment}_${i}`}>{stringFragment}</strong>
        ) : (
          stringFragment
        ),
      )}
    </>
  );
};
