import type { ImageTransformOptions, ImageUploader } from '@cosuno/cosuno-ui';
import { getImageUrl as cosunoUiGetImageUrl, showToast } from '@cosuno/cosuno-ui';
import axios from 'axios';
import type { ComponentProps } from 'react';
import { assert } from 'ts-essentials';

import apolloClient from '~/apolloClient';
import { wait } from '~/shared/utils/promise';

import type { TypeSafeTFunction } from '../hooks/useTranslation';
import { queryImageUploadUrl } from './queries';

// We're defining all possible image sizes here to encourage
// reusing existing sizes to achieve efficient caching
export enum IMAGE_TYPE {
  companyLogo = 'companyLogo',
  officeLogo = 'officeLogo',
  officeLogoRevamp = 'officeLogoRevamp',
  officeLogoSmall = 'officeLogoSmall',
  subcontractorListLogo = 'subcontractorListLogo',
  subcontractorLogo = 'subcontractorLogo',
  project = 'project',
  profile = 'profile',
  referenceProject = 'referenceProject',
  thumbnail = 'thumbnail',
}

export const imageOptionsByType: Record<IMAGE_TYPE, ImageTransformOptions> = {
  [IMAGE_TYPE.companyLogo]: { width: 55, height: 220, format: 'png' },
  [IMAGE_TYPE.officeLogo]: { width: 300, height: 80, format: 'png' },
  [IMAGE_TYPE.officeLogoRevamp]: { width: 460, height: 220, format: 'png' },
  [IMAGE_TYPE.officeLogoSmall]: { width: 120, height: 40, format: 'png' },
  [IMAGE_TYPE.subcontractorLogo]: { width: 250, height: 150, format: 'png' },
  [IMAGE_TYPE.subcontractorListLogo]: { width: 120, height: 60, format: 'png' },
  [IMAGE_TYPE.project]: {},
  [IMAGE_TYPE.profile]: { format: 'png' },
  [IMAGE_TYPE.referenceProject]: { format: 'png' },
  [IMAGE_TYPE.thumbnail]: { format: 'png', width: 300, height: 200 },
};

export const getImageSrcSet = (storageKey: string, type: IMAGE_TYPE): string => `
  ${getImageUrl(storageKey, type, { devicePixelRatio: 1 })} 1x,
  ${getImageUrl(storageKey, type, { devicePixelRatio: 2 })} 2x
`;

export const getImageUrl = (
  storageKey: string,
  type: IMAGE_TYPE,
  options?: ImageTransformOptions,
): string => cosunoUiGetImageUrl(storageKey, { ...imageOptionsByType[type], ...options });

export const uploadImage: ComponentProps<typeof ImageUploader>['uploadImage'] = async (
  file,
  options,
) => {
  const {
    data: {
      imageUploadUrl: { uploadUrl, storageKey },
    },
  } = await apolloClient.query({
    query: queryImageUploadUrl,
    variables: { filename: file.name },
  });

  await axios.put(uploadUrl, file, options);

  return { storageKey };
};

// Set the maximum image size to 20 MiB, which corresponds to our Cloudinary plan.
// To be kept in sync with other image uploaders.
const MAX_IMAGE_MEBIBYTES = 20;
const MAX_IMAGE_BYTES = MAX_IMAGE_MEBIBYTES * 1024 ** 2;

/** If image exceeds max size, shows error toast and returns false. */
export const validateImageSize = (files: File[], t: TypeSafeTFunction): boolean => {
  if (files.some((file) => file.size > MAX_IMAGE_BYTES)) {
    showToast({
      title: t('validation:max', { maxValue: `${MAX_IMAGE_MEBIBYTES} MiB` }),
      type: 'danger',
    });
    return false;
  }
  return true;
};

export const ensureImageIsReady = async (imageUrl: string, maxAttempts = 5, timeoutBase = 1000) => {
  const check = async (url: string, attempt = 1) => {
    try {
      await axios.head(url);
    } catch {
      assert(attempt < maxAttempts, 'Too many times checked');
      await wait(timeoutBase * attempt);
      await check(url, attempt + 1);
    }
  };

  await check(imageUrl);
};

export class ImageTooLarge extends Error {
  constructor() {
    super('Image too large');
  }
}
