// We do not declare properly-typed modules for Moment locales
// because doing so causes the finally-imported module to always
// load. See https://stackoverflow.com/questions/50506389/moment-auto-set-locale-after-importing-the-data
// and https://github.com/moment/moment/issues/3535 for similar symptoms
import 'moment/dist/locale/bg';
import 'moment/dist/locale/cs';
import 'moment/dist/locale/da';
import 'moment/dist/locale/de';
import 'moment/dist/locale/el';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/es';
import 'moment/dist/locale/et';
import 'moment/dist/locale/fi';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/hr';
import 'moment/dist/locale/hu';
import 'moment/dist/locale/it';
import 'moment/dist/locale/lt';
import 'moment/dist/locale/lv';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/ro';
import 'moment/dist/locale/sk';
import 'moment/dist/locale/sv';

import i18n from 'i18next';
import moment from 'moment';

import { LANGUAGE } from '~/__gql__/graphql';
import { STANDARD_DATE_FORMAT, STANDARD_TIME_FORMAT } from '~/shared/constants/dateTime';
import { env } from '~/shared/utils/env';

import { FALLBACK_LANGUAGE } from './shared/utils/language';

type LangString =
  | 'bg'
  | 'cs'
  | 'da'
  | 'de'
  | 'el'
  | 'en-gb'
  | 'es'
  | 'et'
  | 'fi'
  | 'fr'
  | 'hr'
  | 'hu'
  | 'it'
  | 'lt'
  | 'lv'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'sk'
  | 'sv';

const LanguageToLocale: Record<LANGUAGE, LangString> = {
  [LANGUAGE.bg]: 'bg',
  [LANGUAGE.cs]: 'cs',
  [LANGUAGE.da]: 'da',
  [LANGUAGE.de]: 'de',
  [LANGUAGE.en]: 'en-gb',
  [LANGUAGE.es]: 'es',
  [LANGUAGE.et]: 'et',
  [LANGUAGE.fi]: 'fi',
  [LANGUAGE.fr]: 'fr',
  [LANGUAGE.gr]: 'el',
  [LANGUAGE.hr]: 'hr',
  [LANGUAGE.hu]: 'hu',
  [LANGUAGE.it]: 'it',
  [LANGUAGE.lt]: 'lt',
  [LANGUAGE.lv]: 'lv',
  [LANGUAGE.nl]: 'nl',
  [LANGUAGE.pl]: 'pl',
  [LANGUAGE.pt]: 'pt',
  [LANGUAGE.ro]: 'ro',
  [LANGUAGE.sk]: 'sk',
  [LANGUAGE.sv]: 'sv',
};

const getMomentCalendarFormat = () =>
  `${STANDARD_DATE_FORMAT} [${i18n.t('timeSeparatorAt')}] ${STANDARD_TIME_FORMAT}`;

const configureLocale = (language: LANGUAGE) => {
  const locale = LanguageToLocale[language];
  const calendarFormat = getMomentCalendarFormat();

  moment.locale(locale, {
    calendar: {
      lastDay: calendarFormat,
      sameDay: calendarFormat,
      nextDay: calendarFormat,
      lastWeek: calendarFormat,
      nextWeek: calendarFormat,
      sameElse: calendarFormat,
    },
  });
};

const setupMoment = () => {
  // normally i18next will be initialized by the time this is called
  let currentLanguage = i18n.language as LANGUAGE;
  if (!currentLanguage) {
    currentLanguage = env.REACT_APP_DEFAULT_LANG === 'de' ? LANGUAGE.de : FALLBACK_LANGUAGE;
  }
  configureLocale(currentLanguage);

  // this is a fallback in case i18next is not yet initialized
  i18n.on('initialized', ({ lng: language }) => {
    if (language && language in LANGUAGE) {
      configureLocale(language as LANGUAGE);
    }
  });

  i18n.on('languageChanged', configureLocale);
};

export default setupMoment;
