import { COLOR, Spinner } from '@cosuno/cosuno-ui';

import { ErrorBoundary } from '~/shared/components/ErrorBoundary';
import HtmlHeadTags from '~/shared/components/HtmlHeadTags';
import { Heading } from '~/shared/components/UnauthenticatedStyles';

import Logo from '../Logo';
import {
  LeftPanel,
  LoadingWrapper,
  RightPanel,
  RightPanelContentWrapper,
  RightPanelLogoWrapper,
  SplitScreenWrapper,
} from './Styles';

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const SplitScreenUnauthenticatedPage: React.FC<
  Props & { renderOnLeftPanel?: () => React.ReactNode; renderLogo?: () => React.ReactNode }
> = ({ title, children, renderOnLeftPanel, renderLogo, ...props }) => (
  <SplitScreenWrapper {...props}>
    <ErrorBoundary>
      <HtmlHeadTags title={title ?? null} themeColor={COLOR.brand} />
      <LeftPanel>
        {renderLogo ? renderLogo() : <Logo color={COLOR.white} width={220} />}
        {renderOnLeftPanel && renderOnLeftPanel()}
      </LeftPanel>
      <RightPanel>
        <RightPanelContentWrapper>
          <RightPanelLogoWrapper>
            {renderLogo ? renderLogo() : <Logo color={COLOR.brand} width={200} />}
          </RightPanelLogoWrapper>
          {children}
        </RightPanelContentWrapper>
      </RightPanel>
    </ErrorBoundary>
  </SplitScreenWrapper>
);

export const SplitScreenUnauthenticatedLoadingPage: React.FC<Pick<Props, 'title'>> = ({
  title,
}) => (
  <SplitScreenUnauthenticatedPage title={title}>
    <LoadingWrapper>
      <Spinner size={56} />
      <Heading>{title}</Heading>
    </LoadingWrapper>
  </SplitScreenUnauthenticatedPage>
);
