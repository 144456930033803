import { ExternalLink, Popover } from '@cosuno/cosuno-ui';
import { useAttrs, useCurrentSelection, usePositioner } from '@remirror/react';
import React, { type PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { createMarkPositioner, getPositioner } from 'remirror/extensions';

import Trans from '~/shared/components/Trans';
import useTranslation from '~/shared/hooks/useTranslation';

import { PopoverContent, PopoverLinkWrapper, PopoverWrapper } from './Styles';

export const LinkPopover: React.FC<PropsWithChildren<{ isOpen?: boolean }>> = ({
  isOpen,
  children,
}) => {
  const { t } = useTranslation('textEditor');

  const selection = useCurrentSelection();
  const url = (useAttrs().link()?.href as string) ?? '';

  // positioner is used to create empty divs in place of links with corresponding dimensions
  const positioner = useMemo(() => createMarkPositioner({ type: 'link' }), []);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const { ref, x, y, width, height, active } = usePositioner(
    () => getPositioner(positioner),
    [positioner],
  );

  const applySelectionRef = useCallback(
    (elem: HTMLDivElement | null) => {
      // ref is a RefCallback<HTMLElement> if a link is clicked, and undefined if not
      if (ref) {
        setAnchor(elem);
        ref(elem);
      }
    },
    [ref],
  );

  return (
    <PopoverWrapper>
      <Popover.Controlled<HTMLDivElement>
        minWidth={150}
        isOpen={active && selection.empty && Boolean(isOpen ?? true)}
        triggerRef={anchor}
        placement="bottom"
        onClose={() => {}}
        renderContent={() => (
          <PopoverContent>
            <PopoverLinkWrapper>
              <Trans
                t={t}
                i18nKey="links.popover"
                components={{ Link: <ExternalLink to={url} /> }}
                values={{ linkText: url }}
              />
            </PopoverLinkWrapper>
            {children}
          </PopoverContent>
        )}
      >
        <div
          ref={applySelectionRef}
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            left: x,
            top: y,
            width,
            height,
          }}
        />
      </Popover.Controlled>
    </PopoverWrapper>
  );
};
