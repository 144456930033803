import styled, { css } from 'styled-components';

import { PROMPT_HEIGHT } from '~/shared/constants';
import { sizes } from '~/shared/utils/styles';

interface PageContainerProps {
  $isResponsive: boolean;
  $fullHeight: boolean;
  $enableHorizontalScrolling?: boolean;
}

export const PageContainer = styled.div<PageContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.$fullHeight &&
    css`
      min-height: calc(100vh - var(${PROMPT_HEIGHT}, 0px) - ${props.theme.topOffset}px);
    `}

  ${(props) =>
    props.$enableHorizontalScrolling &&
    css`
      height: 100vh;
      overflow-x: auto;
    `}

  ${(props) =>
    !props.$enableHorizontalScrolling &&
    !props.$isResponsive &&
    css`
      min-width: ${sizes.minViewportWidth}px;
    `}
`;
