import { useContext, useMemo } from 'react';

import GoogleMapsApiContext from './context';

const useGoogleMapsApi = () => {
  const getGoogleMapsApi = useContext(GoogleMapsApiContext);

  return useMemo(() => getGoogleMapsApi(), [getGoogleMapsApi]);
};

export default useGoogleMapsApi;
