import { Button, COLOR, DEFAULT_ICON_SIZE, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import PageContent from '~/shared/components/PageContent';

// Some, though not all, content has this as max width
const MAX_CONTENT_WIDTH = 820;

export const Heading = styled.div`
  ${textStyles.titleMedium700};
  color: ${COLOR.textPrimary};
`;

export const GoldHighlight = styled.span`
  color: ${COLOR.warning};
`;

export const HeroSection = styled.div`
  height: 270px;
  overflow: hidden;
  position: relative;
`;

export const HeroTextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(45, 74, 114, 0.5) 0%, transparent 100%);
`;

export const HeroText = styled.div`
  max-width: ${MAX_CONTENT_WIDTH}px;
  text-align: center;

  /* Custom text styles */
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  color: ${COLOR.white};
`;

export const HeroImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left 0 top 75%;
  z-index: -1;
  pointer-events: none;
`;

export const BenefitsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 0;
  background-color: ${COLOR.backgroundLight};
`;

export const BenefitsHeading = styled.div`
  ${textStyles.titleMedium700};
  color: ${COLOR.textSecondary};
  text-align: center;
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
`;

export const StyledBenefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 175px;
  ${textStyles.bodyLarge450};
  color: ${COLOR.textSecondary};
  text-align: center;
`;

export const FeaturesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 60px auto;
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

export const FeatureTitle = styled.div`
  ${textStyles.titleMedium700};
  color: ${COLOR.textPrimary};
`;

export const FeatureListWithIllustration = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FEATURE_LIST_ITEM_GAP = 7;

export const FeatureListItem = styled.div`
  display: flex;
  gap: ${FEATURE_LIST_ITEM_GAP}px;
  ${textStyles.bodyMedium450};
  color: ${COLOR.textSecondary};
`;

export const FeatureCta = styled(Button)`
  margin-left: ${DEFAULT_ICON_SIZE + FEATURE_LIST_ITEM_GAP}px;
`;

export const FeaturesHelpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  ${textStyles.bodyMedium450};
  color: ${COLOR.textSecondary};
`;

export const PlansSection = styled(PageContent)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1600px;
  margin: 0 auto;
`;

export const PlansTitle = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLOR.borderLight};
  ${textStyles.titleMedium700};
  color: ${COLOR.textPrimary};
`;
