import { useEffect } from 'react';

const useOnUnmount = (onUnmount: () => Promise<void> | void) => {
  useEffect(
    () => () => {
      void onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useOnUnmount;
