import React, { useState } from 'react';

import { MFA_METHOD } from '~/__gql__/graphql';
import type { LoginParams } from '~/shared/hooks/useAuthentication';

import AuthenticatorSetup from './AuthenticatorSetup';
import ChooseMethod from './ChooseMethod';
import PhoneSetup from './PhoneSetup';

interface Props {
  loginToken?: string;
  companyName: string;
  onLogIn?: (params: LoginParams) => Promise<void>;
}

const MfaSetupForm: React.FC<Props> = ({ loginToken, companyName, onLogIn }) => {
  const [mfaMethod, setMfaMethod] = useState<MFA_METHOD | null>(null);

  switch (mfaMethod) {
    case MFA_METHOD.phone: {
      return (
        <PhoneSetup
          goBackToStart={() => setMfaMethod(null)}
          loginToken={loginToken}
          onLogIn={onLogIn}
        />
      );
    }
    case MFA_METHOD.authenticator: {
      return (
        <AuthenticatorSetup
          goBackToStart={() => setMfaMethod(null)}
          loginToken={loginToken}
          onLogIn={onLogIn}
        />
      );
    }
    case null: {
      return <ChooseMethod setMfaMethod={setMfaMethod} companyName={companyName} />;
    }
  }
};

export default MfaSetupForm;
