import { useMutation } from '@apollo/client';
import { Button, PageLoader } from '@cosuno/cosuno-ui';
import axios from 'axios';
import { type CountryCode, getCountries } from 'libphonenumber-js';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { assert } from 'ts-essentials';

import { MissingMfaToken } from '~/packages/Authentication/errors';
import Field from '~/shared/components/Field';
import Form from '~/shared/components/Form';
import { Heading, Tip } from '~/shared/components/UnauthenticatedStyles';
import AuthContext from '~/shared/contexts/authContext';
import type { LoginParams } from '~/shared/hooks/useAuthentication';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';
import { env } from '~/shared/utils/env';
import toast from '~/shared/utils/toast';

import { mutationStoreMfaPhoneNumber, mutationVerifyMfaPhoneNumber } from './api';
import { BottomLink, StyledFormElement, StyledLink } from './Styles';

interface Props {
  loginToken?: string;
  goBackToStart: () => void;
  onLogIn?: (params: LoginParams) => Promise<void>;
}

const PhoneSetup: React.FC<Props> = ({ loginToken, goBackToStart, onLogIn }) => {
  const { t } = useTranslation('auth');
  const routes = useRoutes();

  const [stage, setStage] = useState<'setPhoneNumber' | 'enterCode' | 'complete'>('setPhoneNumber');
  const [phone, setPhone] = useState<string | null>(null);
  const [hasLoadedDefaultPhoneCountry, setHasLoadedDefaultPhoneCountry] = useState(false);
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState<CountryCode | null>(null);

  const { fetchCurrentUser } = useContext(AuthContext);

  const [verifyMfaPhoneNumber, { loading: loadingVerifyMfaPhoneNumber }] = useMutation(
    mutationVerifyMfaPhoneNumber,
  );

  const [storeMfaPhoneNumber, { loading: loadingStoreMfaPhoneNumber }] = useMutation(
    mutationStoreMfaPhoneNumber,
  );

  useEffect(() => {
    void (async () => {
      try {
        const response = await axios.get(`https://ipinfo.io?token=${env.REACT_APP_IPINFO_TOKEN}`);
        if (response.data?.country && getCountries().includes(response.data.country)) {
          setDefaultPhoneCountry(response.data.country);
        }
      } catch (error) {
        // Do nothing
      }
      setHasLoadedDefaultPhoneCountry(true);
    })();
  }, []);

  if (stage === 'setPhoneNumber') {
    if (!hasLoadedDefaultPhoneCountry) {
      return <PageLoader />;
    }

    return (
      <Form<{ phone: string }>
        initialValues={{ phone: '' }}
        validations={{
          phone: [Form.is.required(), Form.is.mobilePhone()],
        }}
        onSubmit={async (values) => {
          setPhone(values.phone);

          try {
            await verifyMfaPhoneNumber({
              variables: { phone: values.phone, loginToken },
            });

            setStage('enterCode');
          } catch (error) {
            toast.error(error);
          }
        }}
      >
        <Heading>{t('mfa.setPhoneNumberHeadline')}</Heading>
        <Tip>{t('mfa.setPhoneNumberPrompt')}</Tip>
        <StyledFormElement>
          <Field.PhoneInput
            required
            useMargins={false}
            name="phone"
            label={t('mfa.mobilePhone')}
            showAsRequired={false}
            defaultCountry={defaultPhoneCountry}
          />
          <Button fullWidth type="submit" working={loadingVerifyMfaPhoneNumber}>
            {t('continue')}
          </Button>
          <BottomLink>
            <StyledLink onClick={goBackToStart}>{t('back')}</StyledLink>
          </BottomLink>
        </StyledFormElement>
      </Form>
    );
  }

  if (stage === 'enterCode') {
    if (loadingVerifyMfaPhoneNumber) {
      return <PageLoader />;
    }

    return (
      <Form<{ code: string }>
        initialValues={{ code: '' }}
        validations={{
          code: Form.is.required(),
        }}
        onSubmit={async (values, helpers) => {
          assert(phone, 'Phone number not set');

          try {
            const { data } = await storeMfaPhoneNumber({
              variables: { phone, code: values.code, loginToken },
            });
            const mfaToken = data?.storeMfaPhoneNumber.mfaToken;

            if (mfaToken) {
              if (onLogIn && loginToken) {
                await onLogIn({ loginToken, mfaToken });
              } else {
                toast.success(t('mfa.setupSuccess'));
                await fetchCurrentUser();
                setStage('complete');
              }
            } else {
              throw new MissingMfaToken();
            }
          } catch (error) {
            helpers.setFieldError('code', t('mfa.errorCodeInvalidOrExpired'));
            void helpers.setFieldTouched('code', true);
          }
        }}
      >
        <Heading>{t('mfa.setPhoneNumberHeadline')}</Heading>
        <Tip>{t('mfa.enterCodePrompt', { phone: phone ?? '' })}</Tip>
        <Field.Input
          required
          name="code"
          label={t('mfa.enterCode')}
          useMargins={false}
          showAsRequired={false}
        />
        <StyledLink
          onClick={async () => {
            assert(phone, 'Phone number not set');

            try {
              await verifyMfaPhoneNumber({
                variables: { phone, loginToken },
              });
              toast.success(t('mfa.resendCodeSuccess'));
            } catch (error) {
              toast.error(error);
            }
          }}
        >
          {t('mfa.resendCode')}
        </StyledLink>
        <StyledFormElement>
          <Button fullWidth type="submit" working={loadingStoreMfaPhoneNumber}>
            {t('continue')}
          </Button>
          <BottomLink>
            <StyledLink onClick={() => setStage('setPhoneNumber')}>{t('back')}</StyledLink>
          </BottomLink>
        </StyledFormElement>
      </Form>
    );
  }

  return <Redirect to={`${routes.profile()}/security`} />;
};

export default PhoneSetup;
