import { useQuery } from '@apollo/client';
import { authFor, useAuth } from '@cosuno/authorization-frontend';
import { assert } from 'ts-essentials';

import { COMPANY_TYPE } from '~/__gql__/graphql';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import { isDoneLoading } from '~/shared/utils/apollo';

import { queryShowSubcontractorProfilePrompt } from './api';
import useMatchesExclusionRoutes from './useMatchesExclusionRoutes';

const defaultState = { showPrompt: false, isAgentProfileComplete: false };

const useShowSubcontractorProfilePrompt = () => {
  const matchesExclusionRoutes = useMatchesExclusionRoutes();
  const user = useCurrentUser();

  const {
    results: { canCreateNetworkSubcontractor },
    loading: loadingAuth,
  } = useAuth({
    canCreateNetworkSubcontractor:
      user && authFor('Company').canI('createNetworkSubcontractor').where({ id: user.company.id }),
  });

  const skipQuery =
    !user || user.company.type !== COMPANY_TYPE.agent || !canCreateNetworkSubcontractor;

  const { error, ...queryResults } = useQuery(queryShowSubcontractorProfilePrompt, {
    skip: skipQuery,
  });

  assert(!error, 'Failed ShowSubcontractorProfilePrompt query');

  if (skipQuery) {
    return { ...defaultState, loading: false };
  }
  if (!isDoneLoading(queryResults) || loadingAuth) {
    return { ...defaultState, loading: true };
  }

  const {
    data: {
      company: { isAgentProfileComplete },
    },
  } = queryResults;

  return {
    isAgentProfileComplete,
    loading: false,
    showPrompt: !isAgentProfileComplete && !matchesExclusionRoutes,
  };
};

export default useShowSubcontractorProfilePrompt;
