import { isEnter, isEntered, isExited, isExiting, Portal } from '@cosuno/cosuno-ui';
import { fromPairs, toPairs } from 'lodash';
import React, { type ReactNode } from 'react';
import FocusLock from 'react-focus-lock';
import { Transition } from 'react-transition-group';

import { MODAL_VARIANTS } from '~/shared/constants';
import type { ModalStateWithParamsData } from '~/shared/hooks/useModalStateWithParams';
import { NavigationConfirmationEnvironmentContext } from '~/shared/hooks/useNavigationConfirmation';

import {
  Card,
  CloseButton,
  Layout,
  LayoutInner,
  Overlay,
  ScrollableCardContent,
  TRANSITION_DURATION,
} from './Styles';

export interface ModalProps<Params> extends Omit<ModalStateWithParamsData<Params>, 'events'> {
  name?: string;
  className?: string;
  variant?: MODAL_VARIANTS;
  trapFocus?: boolean;
  renderContent: (options: { isReadyToLoad: boolean; params: Params }) => ReactNode;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
  hasPrimaryColorOverlay?: boolean;
  // Enables gradient for Modal card (instead of a plain white color)
  hasGradient?: boolean;
  // By default, we don't allow content escaping from the Modal card.
  // However, in some cases (like standardized upsell modal for gold package) it's required by design.
  allowOverflow?: boolean;
  isTopLevelModal?: boolean;
  maxWidth?: number;
  isScrollableWithin?: boolean;
}

const Modal = <Params extends unknown>({
  name,
  className,
  isActive,
  isOpen,
  isScrollableWithin,
  closeModal,
  openModal: _,
  isClosingDisabled,
  variant = MODAL_VARIANTS.center,
  renderContent,
  navigationConfirmationEnvironmentId,
  isNavigatingAway,
  subscribeToForm,
  forwardRef,
  hasPrimaryColorOverlay,
  hasGradient,
  allowOverflow,
  isTopLevelModal,
  params,
  maxWidth,
  trapFocus = true,
  tryNavigatingAway,
  ...otherProps
}: ModalProps<Params>): ReturnType<React.FC<ModalProps<Params>>> => {
  const dataAttributes = fromPairs(toPairs(otherProps).filter(([key]) => key.startsWith('data')));

  return (
    <NavigationConfirmationEnvironmentContext.Provider
      value={{
        subscribeToForm,
        environmentId: navigationConfirmationEnvironmentId,
        isNavigatingAway,
        tryNavigatingAway,
      }}
    >
      <Portal>
        <Transition in={isOpen} timeout={TRANSITION_DURATION} appear unmountOnExit>
          {(state) => {
            if (params === null) {
              return null;
            }

            const isReadyToLoad = isEntered(state) || isExiting(state);

            const content = (
              <div ref={forwardRef}>
                <Overlay
                  $transitionState={state}
                  $isActive={isActive || isExiting(state)}
                  $isTopLevelModal={isTopLevelModal}
                  $hasPrimaryColorOverlay={hasPrimaryColorOverlay}
                />
                <Layout
                  data-testid="modal-layout"
                  data-test-is-active={isActive}
                  data-cy-is-active-modal={isActive}
                  data-cy-is-open-modal={isEntered(state)}
                  data-cy-modal-name={name}
                  $variant={variant}
                  $transitionState={state}
                  $isActive={isActive || isExiting(state)}
                  $isTopLevelModal={isTopLevelModal}
                  {...dataAttributes}
                >
                  <LayoutInner $transitionState={state}>
                    <Card
                      $maxWidth={maxWidth}
                      $isScrollableWithin={isScrollableWithin}
                      $hasGradient={hasGradient}
                      $allowOverflow={allowOverflow}
                      className={className}
                      variant={variant}
                      transitionState={state}
                    >
                      {!isExited(state) && !isClosingDisabled && (
                        <CloseButton
                          data-cy-close-modal
                          data-testid="modal-close-button"
                          onlyIcon={{
                            type: 'close',
                            size: 16,
                          }}
                          size="small"
                          variant="secondary"
                          hidden={variant === MODAL_VARIANTS.right}
                          onClick={closeModal}
                        />
                      )}
                      {!isExited(state) && (
                        <ScrollableCardContent $allowOverflow={allowOverflow} data-cy-modal-body>
                          {renderContent({
                            isReadyToLoad,
                            params,
                          })}
                        </ScrollableCardContent>
                      )}
                    </Card>
                  </LayoutInner>
                </Layout>
              </div>
            );

            if (!trapFocus) {
              return content;
            }

            return (
              <FocusLock disabled={!isEnter(state)} autoFocus={false}>
                {content}
              </FocusLock>
            );
          }}
        </Transition>
      </Portal>
    </NavigationConfirmationEnvironmentContext.Provider>
  );
};

export default Modal;
