import { Input, type InputProps, type InputRenderProps } from '@cosuno/cosuno-ui';
import type CleaveInstance from 'cleave.js';
import Cleave from 'cleave.js/react';
import React, { useEffect, useRef, useState } from 'react';
import { assert } from 'ts-essentials';

import { CURRENCY } from '~/__gql__/graphql';
import useTranslation from '~/shared/hooks/useTranslation';
import { getCurrencySymbol } from '~/shared/utils/currencies';
import { isNotUndefined } from '~/shared/utils/typescript';

import {
  formatWithLocale,
  getDecimalSeparator,
  getFormattedRawValue,
  getNumeralGroupSeparator,
  removeTrailingZeros,
} from './utils';

export interface FormattedNumberInputProps extends InputProps {
  value: string;
  trailingZeros?: number;
  defaultValue?: number;
  allowNegativeValues?: boolean;
  currency?: CURRENCY;
  onBlur?: (event: React.FocusEvent<HTMLInputElement> & { target: { rawValue: string } }) => void;
}

const FormattedNumberInput = React.forwardRef<HTMLInputElement, FormattedNumberInputProps>(
  (
    { value, trailingZeros = 2, defaultValue, currency, allowNegativeValues = false, ...props },
    ref,
  ) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const formattedValue = useRef(formatWithLocale(locale, value, trailingZeros));
    const cleaveInstance = useRef<CleaveInstance>();
    const [state, setState] = useState<'initial' | 'changed'>('initial');
    const inputRef = useRef<HTMLInputElement | null>(null);

    assert(trailingZeros >= 0, 'Prop `trailingZeros` cannot have a negative value.');

    useEffect(() => {
      if (!cleaveInstance.current) return;
      if (Number(value) === Number(cleaveInstance.current.getRawValue())) return;

      // When the user enters a purely non-numerical value (e.g. '.'), we pass
      // an empty string to onChange. So when we get an empty string back as a
      // value, we have to ignore it in these situations.
      if (Number.isNaN(Number(cleaveInstance.current.getRawValue())) && value === '') return;

      setState('initial');
      cleaveInstance.current.setRawValue(getFormattedRawValue(value, trailingZeros));
    }, [locale, trailingZeros, value]);

    const setInputRef = (element: HTMLInputElement) => {
      inputRef.current = element;

      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    };

    const onCleaveInit = (cleave: CleaveInstance) => {
      cleaveInstance.current = cleave;
      updateTrailingZeros();
    };

    const updateTrailingZeros = () => {
      if (!cleaveInstance.current) return;
      const currentValue = cleaveInstance.current.getRawValue();
      cleaveInstance.current.setRawValue(getFormattedRawValue(currentValue, trailingZeros));
    };

    const getRawValueForEmitting = (rawValue: string): string => {
      const adjustedRawValue = allowNegativeValues ? rawValue : rawValue.replace('-', '');
      const isValidNumber = !Number.isNaN(Number(rawValue));

      return isValidNumber ? removeTrailingZeros(adjustedRawValue) : '';
    };

    const renderInput = ({
      onChange,
      onBlur,
      onFocus,
      ref: innerRef,
      ...inputProps
    }: InputRenderProps) => (
      <Cleave
        {...inputProps}
        htmlRef={innerRef as (element: HTMLInputElement) => void}
        value={formattedValue.current}
        onChange={(event) => {
          if (!allowNegativeValues && event.target.value.includes('-')) {
            event.target.value = formattedValue.current;
          } else {
            formattedValue.current = event.target.value;
          }

          if (
            state === 'initial' &&
            getFormattedRawValue(value, trailingZeros) === event.target.rawValue
          ) {
            return;
          }

          if (
            isNotUndefined(inputProps.max) &&
            Number(event.target.rawValue) > Number(inputProps.max)
          ) {
            cleaveInstance.current?.setRawValue(inputProps.max.toString());
            return;
          }
          if (
            isNotUndefined(inputProps.min) &&
            Number(event.target.rawValue) < Number(inputProps.min)
          ) {
            cleaveInstance.current?.setRawValue(inputProps.min.toString());
            return;
          }

          setState('changed');
          onChange(getRawValueForEmitting(event.target.rawValue), event);
        }}
        onInit={(cleave) => onCleaveInit(cleave as unknown as CleaveInstance)}
        onFocus={(e) => {
          onFocus?.(e);

          if (!cleaveInstance.current) {
            return;
          }

          setTimeout(() => {
            if (inputRef.current && inputRef.current === document.activeElement) {
              inputRef.current.select();
            }
          });
        }}
        onBlur={(event) => {
          if (!cleaveInstance.current) {
            return;
          }

          const rawValue = cleaveInstance.current.getRawValue();

          if (rawValue === '' && defaultValue !== undefined) {
            cleaveInstance.current.setRawValue(
              getFormattedRawValue(String(defaultValue), trailingZeros),
            );
          }

          if (rawValue !== '') {
            updateTrailingZeros();
          }

          if (onBlur) {
            // Cleave.js attaches data to event.target.rawValue, but we have to
            // override event.target.value instead, so that generic code in
            // Field can read it
            event.target.value = getRawValueForEmitting(rawValue);

            onBlur(event);
          }
        }}
        options={{
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: trailingZeros,
          numeralDecimalMark: getDecimalSeparator(locale),
          delimiter: getNumeralGroupSeparator(locale),
        }}
      />
    );

    // In the case of EUR we have an icon so we treat it differently.
    const prefixLabel =
      currency === undefined
        ? undefined
        : getCurrencySymbol(currency, locale, { preferNarrow: true });

    const icon =
      currency && (prefixLabel === undefined || currency === CURRENCY.EUR) ? 'euro' : undefined;

    return (
      <Input
        icon={icon}
        prefixLabel={icon === undefined ? prefixLabel : undefined}
        {...props}
        renderInput={renderInput}
        ref={setInputRef}
      />
    );
  },
);

FormattedNumberInput.displayName = 'FormattedNumberInput';

export default FormattedNumberInput;
