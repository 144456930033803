import {
  type DocumentNode,
  type OperationVariables,
  type QueryHookOptions,
  type QueryResult,
  type TypedDocumentNode,
  useQuery,
} from '@apollo/client';

import { useDebounce } from '~/packages/debounce';

export default function useDebouncedQuery<
  TData = any, // eslint-disable-line @typescript-eslint/no-explicit-any
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: Omit<QueryHookOptions<TData, TVariables>, 'variables' | 'skip'> & {
    variables: TVariables;
    debounceKey?: keyof TVariables;
    skip?: boolean | ((variables: TVariables) => boolean);
  },
): QueryResult<TData, TVariables> {
  const { variables, debounceKey = 'query', skip } = options;
  const [debouncedVariable] = useDebounce(variables[debounceKey]);
  const debouncedVariables = { ...variables, [debounceKey]: debouncedVariable };
  return useQuery(query, {
    ...options,
    variables: debouncedVariables,
    skip: typeof skip === 'function' ? skip(debouncedVariables) : skip,
  });
}
