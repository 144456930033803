import React from 'react';

import { PageContainer } from './Styles';

export interface PageLayoutProps {
  children: React.ReactNode;
  isResponsive?: boolean;
  fullHeight?: boolean;
  /** Careful: This can break stickyness of child elements */
  enableHorizontalScrolling?: boolean;
  renderHeader: () => React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  isResponsive = false,
  fullHeight = false,
  renderHeader,
  enableHorizontalScrolling,
  ...props
}) => (
  <PageContainer
    $enableHorizontalScrolling={enableHorizontalScrolling}
    $isResponsive={isResponsive}
    $fullHeight={fullHeight}
    {...props}
  >
    {renderHeader()}
    {children}
  </PageContainer>
);

export default PageLayout;
