import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { env } from '~/shared/utils/env';

const CHECK_INTERVAL = moment.duration(5, 'minutes');

interface UseMaintenanceModeResult {
  isEnabled: boolean;
  check: () => Promise<void>;
}

const useMaintenanceMode = (): UseMaintenanceModeResult => {
  const [isEnabled, setIsEnabled] = useState(false);

  const check = useCallback(async () => {
    if (env.MODE !== 'production' || env.REACT_APP_CYPRESS === 'true') return;

    try {
      const { data: isMaintenanceModeEnabled } = await axios.get<boolean | string>(
        '/maintenance.txt',
        {
          validateStatus: (status) => [200, 404].includes(status),
        },
      );
      // Abort on 404
      if (typeof isMaintenanceModeEnabled !== 'boolean') return;

      setIsEnabled(isMaintenanceModeEnabled);
    } catch (e) {
      // silence any network errors and similar
    }
  }, []);

  useEffect(() => {
    void check();
    // This should run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => check(), CHECK_INTERVAL.asMilliseconds());

    return () => clearInterval(intervalId);
    // This should run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isEnabled, check };
};

export default useMaintenanceMode;
