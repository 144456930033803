type ZIndexInput<T> = T | [T, number];

type ZIndexValues<T extends string> = { [key in T]: number };

const genZIndexValues = <T extends string>(...names: ZIndexInput<T>[]): ZIndexValues<T> => {
  const values = {} as ZIndexValues<T>;
  let lastValue = 0;

  names.forEach((nameOrNameWithValue) => {
    const [name, value] = Array.isArray(nameOrNameWithValue)
      ? nameOrNameWithValue
      : [nameOrNameWithValue, lastValue + 1];

    values[name] = value;
    lastValue = value;
  });

  return values;
};

export default genZIndexValues;
