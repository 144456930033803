import territoryDataBG from 'cldr-localenames-full/main/bg/territories.json';
import territoryDataCS from 'cldr-localenames-full/main/cs/territories.json';
import territoryDataDA from 'cldr-localenames-full/main/da/territories.json';
import territoryDataDE from 'cldr-localenames-full/main/de/territories.json';
import territoryDataGR from 'cldr-localenames-full/main/el/territories.json';
import territoryDataEN from 'cldr-localenames-full/main/en/territories.json';
import territoryDataES from 'cldr-localenames-full/main/es/territories.json';
import territoryDataET from 'cldr-localenames-full/main/et/territories.json';
import territoryDataFI from 'cldr-localenames-full/main/fi/territories.json';
import territoryDataFR from 'cldr-localenames-full/main/fr/territories.json';
import territoryDataHR from 'cldr-localenames-full/main/hr/territories.json';
import territoryDataHU from 'cldr-localenames-full/main/hu/territories.json';
import territoryDataIT from 'cldr-localenames-full/main/it/territories.json';
import territoryDataLT from 'cldr-localenames-full/main/lt/territories.json';
import territoryDataLV from 'cldr-localenames-full/main/lv/territories.json';
import territoryDataNL from 'cldr-localenames-full/main/nl/territories.json';
import territoryDataPL from 'cldr-localenames-full/main/pl/territories.json';
import territoryDataPT from 'cldr-localenames-full/main/pt/territories.json';
import territoryDataRO from 'cldr-localenames-full/main/ro/territories.json';
import territoryDataSK from 'cldr-localenames-full/main/sk/territories.json';
import territoryDataSV from 'cldr-localenames-full/main/sv/territories.json';

import { type COUNTRY_CODE, LANGUAGE } from '~/__gql__/graphql';

const territoryDataByLanguage = {
  [LANGUAGE.bg]: territoryDataBG.main.bg.localeDisplayNames.territories,
  [LANGUAGE.cs]: territoryDataCS.main.cs.localeDisplayNames.territories,
  [LANGUAGE.da]: territoryDataDA.main.da.localeDisplayNames.territories,
  [LANGUAGE.de]: territoryDataDE.main.de.localeDisplayNames.territories,
  [LANGUAGE.en]: territoryDataEN.main.en.localeDisplayNames.territories,
  [LANGUAGE.es]: territoryDataES.main.es.localeDisplayNames.territories,
  [LANGUAGE.et]: territoryDataET.main.et.localeDisplayNames.territories,
  [LANGUAGE.fi]: territoryDataFI.main.fi.localeDisplayNames.territories,
  [LANGUAGE.fr]: territoryDataFR.main.fr.localeDisplayNames.territories,
  [LANGUAGE.gr]: territoryDataGR.main.el.localeDisplayNames.territories,
  [LANGUAGE.hr]: territoryDataHR.main.hr.localeDisplayNames.territories,
  [LANGUAGE.hu]: territoryDataHU.main.hu.localeDisplayNames.territories,
  [LANGUAGE.it]: territoryDataIT.main.it.localeDisplayNames.territories,
  [LANGUAGE.lt]: territoryDataLT.main.lt.localeDisplayNames.territories,
  [LANGUAGE.lv]: territoryDataLV.main.lv.localeDisplayNames.territories,
  [LANGUAGE.nl]: territoryDataNL.main.nl.localeDisplayNames.territories,
  [LANGUAGE.pl]: territoryDataPL.main.pl.localeDisplayNames.territories,
  [LANGUAGE.pt]: territoryDataPT.main.pt.localeDisplayNames.territories,
  [LANGUAGE.ro]: territoryDataRO.main.ro.localeDisplayNames.territories,
  [LANGUAGE.sk]: territoryDataSK.main.sk.localeDisplayNames.territories,
  [LANGUAGE.sv]: territoryDataSV.main.sv.localeDisplayNames.territories,
};

export const translateCountryCode = (countryCode: COUNTRY_CODE, language: LANGUAGE) =>
  territoryDataByLanguage[language][countryCode];
