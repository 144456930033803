import { graphql } from '~/__gql__';

export const mutationSendVerificationCode = graphql(/* GraphQL */ `
  mutation SendVerificationCode($loginToken: String!) {
    sendVerificationCode(loginToken: $loginToken)
  }
`);

export const mutationCheckVerificationCode = graphql(/* GraphQL */ `
  mutation CheckVerificationCode($loginToken: String!, $code: String!) {
    checkVerificationCode(loginToken: $loginToken, code: $code) {
      mfaToken
    }
  }
`);

export const mutationRequestMfaReset = graphql(/* GraphQL */ `
  mutation RequestMfaReset($loginToken: String!) {
    requestMfaReset(loginToken: $loginToken)
  }
`);
