import { ClickableText } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

import Form from '../Form';

export const AuthenticatorSecret = styled.span`
  ${font.size(21)}
  ${font.bold}
`;

export const BottomLink = styled.div`
  text-align: center;
`;

export const StyledFormElement = styled(Form.Element)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export const StyledLink = styled(ClickableText)`
  display: inline-block;
  ${font.size(14)}
  ${mixins.link()}
`;

export const AuthenticatorIdentifiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
  align-items: center;
`;
