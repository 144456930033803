import { PageLoader } from '@cosuno/cosuno-ui';
import React, { type PropsWithChildren, useContext, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import PageNotFound from '~/components/PageNotFound';
import AuthContext from '~/shared/contexts/authContext';
import useUserAuthState from '~/shared/hooks/useUserAuthState';
import toast from '~/shared/utils/toast';

const WorkspaceRedirect: React.FC<
  PropsWithChildren<RouteComponentProps<{ workspaceId?: string }>>
> = ({ children, match, location, history }) => {
  const { currentUser, isUserAuthenticated } = useUserAuthState();
  const [isProcessed, setIsProcessed] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const { switchToWorkspace } = useContext(AuthContext);

  useEffect(() => {
    const switchToWorkspaceOrDisplayToast = async (workspaceId: string, redirectTo: string) => {
      try {
        await switchToWorkspace(workspaceId, redirectTo);
      } catch (error) {
        toast.error(error);
      }
    };

    if ('workspaceId' in match.params) {
      // not generating path from match.path and match.params because of issues with how this
      // works with our tabs (e.g. company() route doesn't have :tab param)
      const currentPath = `${location.pathname}${location.search}`;

      if (!match.params.workspaceId) {
        const workspace = currentUser?.workspaces.find(({ isCurrent }) => isCurrent);

        if (workspace) {
          const redirectTo = `/${workspace.id}${currentPath}`;

          history.push(redirectTo, location.state);
          return;
        }
      } else {
        const workspace = currentUser?.workspaces.find(({ id }) => id === match.params.workspaceId);

        if (workspace) {
          if (!workspace.isCurrent) {
            void switchToWorkspaceOrDisplayToast(workspace.id, currentPath);
            return;
          }
        } else {
          setIsNotFound(true);
        }
      }
    }

    setIsProcessed(true);
  }, [setIsProcessed, match, location, history, currentUser, switchToWorkspace]);

  if (!isProcessed) {
    return <PageLoader />;
  }

  if (isNotFound) {
    return isUserAuthenticated ? <PageNotFound.Authenticated /> : <PageNotFound.Unauthenticated />;
  }

  return <>{children}</>;
};

export default WorkspaceRedirect;
