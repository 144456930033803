import { useMutation } from '@apollo/client';
import { PageLoader } from '@cosuno/cosuno-ui';
import { type ReactNode, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { assert } from 'ts-essentials';

import {
  type COMPANY_TYPE,
  REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON,
  type RequestPasswordlessLoginCodeFragment,
  VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON,
  type ValidatePasswordlessLoginResponseFragment,
} from '~/__gql__/graphql';
import type { LoginParams } from '~/shared/hooks/useAuthentication';
import { useEffectOnce } from '~/shared/hooks/useEffectOnce';
import useRoutes from '~/shared/hooks/useRoutes';
import toast from '~/shared/utils/toast';

import {
  mutationRequestPasswordlessLoginCodeForBidSubmission,
  mutationRequestPasswordlessLoginCodeForInternalNote,
  mutationRequestPasswordlessLoginCodeForTask,
  mutationResendConfirmationEmail,
  mutationValidatePasswordlessLoginCode,
  mutationValidatePasswordlessLoginToken,
} from '../api';
import { handleLoginError } from '../handleLoginError';
import MfaForm, { useMfaFormProps } from '../MfaForm';
import type { LoginSuggestion } from '../types';
import { ChooseLoginMethodStep } from './ChooseLoginMethodStep';
import type { LoginError } from './ErrorMessages';
import { ProvideCodeStep } from './ProvideCodeStep';
import { RequestEmailStep } from './RequestEmailStep';

export enum PASSWORDLESS_LOGIN_VARIANT {
  submitBid = 'submitBid',
  viewTask = 'viewTask',
  addInternalNote = 'addInternalNote',
}

type Step =
  | {
      name: 'requestEmail';
      suggestion: LoginSuggestion | null | undefined;
      error: LoginError;
    }
  | {
      name: 'chooseLoginMethod';
      suggestion: LoginSuggestion;
      error: LoginError;
    }
  | {
      name: 'provideCode';
      suggestion: LoginSuggestion;
      error: LoginError;
    };

interface LoginWithMagicLinkFormProps {
  /** Only an agent-specific layout is available for this form. */
  variant: 'agent';
  passwordlessLoginOptions:
    | {
        variant: PASSWORDLESS_LOGIN_VARIANT.submitBid;
        /** The id of the bid package for which the agent user is trying submit a bid */
        bidPackageId: string;
      }
    | {
        variant: PASSWORDLESS_LOGIN_VARIANT.viewTask;
      }
    | {
        variant: PASSWORDLESS_LOGIN_VARIANT.addInternalNote;
      };
  loginSuggestions?: LoginSuggestion[];
  isLoading?: boolean;
  /**
   * Called when the user completes the login process. This function should
   * authenticate the user.
   */
  handleLogIn: (params: LoginParams) => Promise<void>;
  /**
   * Called when the user attempts to move to a password-based login form.
   */
  handleGoToPasswordLogin: (suggestion?: LoginSuggestion) => void;
  requestEmailTitle: string;
  requestEmailExplanation: [ReactNode, ReactNode];
  /**
   * A token for passwordless login, if one was provided via the URL.
   */
  token: string | null;
  /**
   * URL to be used in the passwordless login email (with a query param added to
   * it serverside). Users will be able to click on a link in the email and
   * auto-log in. The route under this URL needs to render this form component. The
   * current URL is used by default.
   */
  redirectUrl?: string;
  /**
   * Email address to prefill the form with. The user may have already provided
   * an email in another login form variant.
   */
  initialSuggestion?: LoginSuggestion | null;
  /**
   * Called when the user requests a magic link email.
   */
  trackRequestEmail: () => void;
  /**
   * Called when the user types in the validation code and submits.
   */
  trackSubmitCode: () => void;
  /**
   * Called when the user requests a magic link email, then goes back to try
   * other login options.
   */
  trackGoBackToRequestStep: () => void;
  /**
   * Called when the user switches to the password-based login method.
   */
  trackGoToPasswordLogin: () => void;
  /**
   * Called when the user opens a magic link provided to them in an email; that
   * is, when a passwordless login token gets provided to this component and we
   * make an attempt to auto-log in the user.
   */
  trackValidateMagicLinkToken: () => void;
  /**
   * If set, sends a company type to the auth api;
   * the api returns an error when trying to log in with incorrect company type;
   * a user will see an error message (see `isCompanyTypeNotFound` in `ErrorMessages`).
   */
  forceCompanyType?: COMPANY_TYPE;
}

const LoginWithMagicLinkForm: React.FC<LoginWithMagicLinkFormProps> = ({
  passwordlessLoginOptions,
  loginSuggestions,
  isLoading,
  handleLogIn,
  handleGoToPasswordLogin,
  requestEmailTitle,
  requestEmailExplanation,
  token,
  initialSuggestion: providedInitialSuggestion,
  redirectUrl = window.location.href,
  trackSubmitCode,
  trackGoBackToRequestStep,
  trackGoToPasswordLogin,
  trackRequestEmail,
  trackValidateMagicLinkToken,
  forceCompanyType,
}) => {
  const history = useHistory();
  const routes = useRoutes();
  const [initialSuggestion, setInitialSuggestion] = useState(providedInitialSuggestion);

  const [step, setStep] = useState<Step>({
    name: 'requestEmail',
    suggestion: initialSuggestion,
    error: null,
  });
  const { mfaFormProps, setMfaFormProps } = useMfaFormProps();

  const [requestCodeForBidSubmission, { loading: isRequestingCodeForBidSubmission }] = useMutation(
    mutationRequestPasswordlessLoginCodeForBidSubmission,
  );

  const [requestCodeForTask, { loading: isRequestingCodeForTask }] = useMutation(
    mutationRequestPasswordlessLoginCodeForTask,
  );

  const [requestCodeForIntenalNote, { loading: isRequestingCodeForInternalNote }] = useMutation(
    mutationRequestPasswordlessLoginCodeForInternalNote,
  );

  const [validateCode, { loading: isValidatingCode }] = useMutation(
    mutationValidatePasswordlessLoginCode,
  );

  const [validatePasswordlessLoginToken, { loading: isValidatingPasswordlessLoginToken }] =
    useMutation(mutationValidatePasswordlessLoginToken);

  const [resendConfirmationEmailMutation] = useMutation(mutationResendConfirmationEmail);

  const resendConfirmationEmail = async (email: string) => {
    await resendConfirmationEmailMutation({ variables: { email } });
  };

  const validateLogin = useCallback(
    async (getData: () => Promise<ValidatePasswordlessLoginResponseFragment | undefined>) => {
      try {
        const data = await getData();

        // Shouldn't happen, because we would expect `getData` to throw a
        // specific error instead
        assert(data, 'Passwordless login token validation failed');

        switch (data.__typename) {
          case 'CheckCredentialsResponse': {
            if (data.mfaRequired) {
              setMfaFormProps(data);
            } else {
              await handleLogIn(data);
            }

            break;
          }
          case 'ValidatePasswordlessLoginFailure':
            switch (data.reason) {
              case VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON.tokenExpired:
                setStep({ ...step, error: 'isCodeExpired' });
            }
        }
      } catch (error) {
        handleLoginError(error, {
          onInvalidCredentialsError: () => setStep({ ...step, error: 'isInvalidCode' }),
          onUserNotApprovedError: () => setStep({ ...step, error: 'isUserNotApproved' }),
          onUserEmailNotConfirmedError: () =>
            setStep({ ...step, error: 'isUserEmailNotConfirmed' }),
          onUserLockedError: () => history.push(routes.userLocked()),
          onCompanyTypeNotFoundError: () => setStep({ ...step, error: 'isCompanyTypeNotFound' }),
          onSsoUserPasswordLoginAttempt: () => {
            // nothing to do with SSO for magic links
          },
        });
      }
    },
    [history, handleLogIn, routes, setMfaFormProps, step],
  );

  useEffectOnce(
    async () => {
      if (token === null) return;

      trackValidateMagicLinkToken();

      await validateLogin(async () => {
        const { data } = await validatePasswordlessLoginToken({
          variables: {
            token,
            ...(forceCompanyType && { companyType: forceCompanyType }),
          },
        });

        return data?.validatePasswordlessLoginToken;
      });
    },
    { when: token !== null },
  );

  if (isValidatingPasswordlessLoginToken) return <PageLoader />;

  if (mfaFormProps) {
    return <MfaForm {...mfaFormProps} onLogIn={handleLogIn} />;
  }

  const hasExactlyOneSuggestion = loginSuggestions?.length === 1;

  switch (step.name) {
    case 'requestEmail':
      return (
        <RequestEmailStep
          title={requestEmailTitle}
          explanation={requestEmailExplanation}
          alreadyProvidedEmail={step.suggestion?.email ?? initialSuggestion?.email}
          isSubmitting={
            isRequestingCodeForBidSubmission ||
            isRequestingCodeForTask ||
            isRequestingCodeForInternalNote
          }
          error={step.error}
          isLoading={isLoading}
          loginSuggestions={loginSuggestions ?? []}
          resendConfirmationEmail={resendConfirmationEmail}
          onSubmit={({ suggestion }) => {
            assert(suggestion, 'Suggestion is required');
            setStep({
              name: 'chooseLoginMethod',
              suggestion,
              error: null,
            });
          }}
        />
      );
    case 'chooseLoginMethod':
      return (
        <ChooseLoginMethodStep
          explanation={hasExactlyOneSuggestion ? requestEmailExplanation[0] : null}
          title={requestEmailTitle}
          selectedSuggestion={step.suggestion}
          onGoBack={
            hasExactlyOneSuggestion
              ? undefined
              : () => {
                  setInitialSuggestion(null);
                  setStep({ name: 'requestEmail', suggestion: null, error: null });
                }
          }
          onSubmit={async ({ suggestion, suggestion: { email }, method }) => {
            try {
              trackRequestEmail();

              if (method === 'password') {
                trackGoToPasswordLogin();
                handleGoToPasswordLogin(step.suggestion);
                return;
              }

              let data: RequestPasswordlessLoginCodeFragment | undefined;

              switch (passwordlessLoginOptions.variant) {
                case PASSWORDLESS_LOGIN_VARIANT.submitBid: {
                  const response = await requestCodeForBidSubmission({
                    variables: { email, redirectUrl, ...passwordlessLoginOptions },
                  });
                  data = response.data?.requestPasswordlessLoginCodeForBidSubmission;
                  break;
                }
                case PASSWORDLESS_LOGIN_VARIANT.viewTask: {
                  const response = await requestCodeForTask({
                    variables: { email, redirectUrl, ...passwordlessLoginOptions },
                  });
                  data = response.data?.requestPasswordlessLoginCodeForTask;
                  break;
                }
                case PASSWORDLESS_LOGIN_VARIANT.addInternalNote: {
                  const response = await requestCodeForIntenalNote({
                    variables: { email, redirectUrl, ...passwordlessLoginOptions },
                  });
                  data = response.data?.requestPasswordlessLoginCodeForInternalNote;
                  break;
                }
              }

              assert(data, 'Failed to retrieve passwordless login code');

              switch (data.__typename) {
                case 'RequestPasswordlessLoginCodeFailure': {
                  switch (data.reason) {
                    case REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON.userMissingOrDeactivated: {
                      setStep({
                        ...step,
                        error: 'isUserDeactivated',
                      });
                    }
                  }
                  break;
                }
                case 'RequestPasswordlessLoginCodeSuccess': {
                  setStep({
                    name: 'provideCode',
                    suggestion,
                    error: null,
                  });
                  break;
                }
              }
            } catch (error) {
              toast.error(error);
            }
          }}
        />
      );
    case 'provideCode':
      return (
        <ProvideCodeStep
          suggestion={step.suggestion}
          isSubmitting={isValidatingCode}
          error={step.error}
          onCodeChange={() => {
            if (step.error === 'isInvalidCode') {
              setStep({ ...step, error: null });
            }
          }}
          onLoginWithPassword={handleGoToPasswordLogin}
          onGoBack={() => {
            trackGoBackToRequestStep();

            setStep({
              name: 'chooseLoginMethod',
              suggestion: step.suggestion,
              error: null,
            });
          }}
          resendConfirmationEmail={resendConfirmationEmail}
          onSubmit={async ({ code }) => {
            setStep({
              ...step,
              error: null,
            });

            trackSubmitCode();

            await validateLogin(async () => {
              const { data } = await validateCode({
                variables: {
                  email: step.suggestion.email,
                  verificationCode: code,
                  ...(forceCompanyType && { companyType: forceCompanyType }),
                },
              });

              return data?.validatePasswordlessLoginCode;
            });
          }}
        />
      );
  }
};

export default LoginWithMagicLinkForm;
export { usePasswordlessLoginTokenQueryParam } from './usePasswordlessLoginTokenQueryParam';
