import { FullStory, init as initFullStory } from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { fullStoryIntegration } from '@sentry/fullstory';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { assert } from 'ts-essentials';

import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import { type AuthContextBase, isAnonymous, isAuthenticated } from '~/shared/contexts/authContext';
import { useContextlessAnalytics } from '~/shared/hooks/useAnalytics';
import { env } from '~/shared/utils/env';
import injectScript from '~/shared/utils/injectScript';

type AnalyticsStub = {
  initialize: boolean;
  invoked: boolean;
  methods: string[];
  factory: (methodName: string) => () => void;
  load: (segmentKey: string, options: unknown) => void;
  SNIPPET_VERSION: string;
  _loadOptions: unknown;
  _writeKey: string;
} & Record<(typeof methods)[number], () => void>;

type AnalyticsReplay = Array<readonly [methodName: string, ...rest: unknown[]]>;

interface Analytics extends AnalyticsReplay, AnalyticsStub {}

declare let window: Window & {
  analytics?: Analytics;
};

const methods = [
  'trackSubmit',
  'trackClick',
  'trackLink',
  'trackForm',
  'pageview',
  'identify',
  'reset',
  'group',
  'track',
  'ready',
  'alias',
  'debug',
  'page',
  'once',
  'off',
  'on',
  'addSourceMiddleware',
  'addIntegrationMiddleware',
  'setAnonymousId',
  'addDestinationMiddleware',
] as const;

interface UseAnalyticsTrackerProps {
  enableAnalytics: boolean;
  authContext: AuthContextBase;
}

const useAnalyticsTracker = ({ enableAnalytics, authContext }: UseAnalyticsTrackerProps) => {
  const history = useHistory();
  const { load, trackPageview } = useContextlessAnalytics({
    isSuperAdmin: authContext.isSuperAdmin,
    experiments: undefined,
    contextEntryPoint: undefined,
  });
  const [isSegmentLoaded, setSegmentLoaded] = useState(false);

  useEffect(() => {
    if (!enableAnalytics || window.analytics) {
      return;
    }

    window.analytics = (() => {
      const analytics: Partial<AnalyticsStub> & AnalyticsReplay = [];
      analytics.invoked = true;
      // Define a factory to create stubs. These are placeholders for methods in Analytics.js so that
      // you never have to wait for it to load to actually record data. The `method` is stored as the
      // first argument, so we can replay the data.
      analytics.factory = function factory(methodName: string) {
        // eslint-disable-next-line func-names
        return function (...args) {
          analytics.push([methodName, ...args] as const);
          return analytics;
        };
      };
      // For each of our methods, generate a queueing stub.
      for (const key of methods) {
        analytics[key] = analytics.factory(key);
      }
      // Define a method to load Segment analytics.js and make sure to only ever load it once.
      analytics.load = function loadAnalytics(segmentKey: string, options: unknown) {
        injectScript({
          src: `https://cdn.segment.com/analytics.js/v1/${segmentKey}/analytics.min.js`,
          async: true,
        });
        // eslint-disable-next-line no-underscore-dangle
        analytics._loadOptions = options;
        // eslint-disable-next-line no-underscore-dangle
        analytics._writeKey = segmentKey;
      };
      analytics.SNIPPET_VERSION = '4.15.2';
      return analytics as Analytics;
    })();

    if (env.REACT_APP_FULLSTORY_ORG_ID) {
      initFullStory({
        host: 'eu1.fullstory.com',
        script: 'edge.eu1.fullstory.com/s/fs.js',
        orgId: env.REACT_APP_FULLSTORY_ORG_ID,
        startCaptureManually: true,
      });

      if (Sentry.isInitialized()) {
        assert(env.REACT_APP_SENTRY_ORG_SLUG, 'Unable to find Sentry organisation slug.');
        Sentry.addIntegration(
          fullStoryIntegration(env.REACT_APP_SENTRY_ORG_SLUG, { client: FullStory }),
        );
      }
    }
  }, [enableAnalytics]);

  useEffect(() => {
    if (!enableAnalytics) return;

    if (
      !isSegmentLoaded &&
      !env.REACT_APP_CYPRESS &&
      (isAnonymous(authContext) ||
        (isAuthenticated(authContext) &&
          isCompanyFeatureEnabled(
            authContext.authState.user.company,
            'conditionalSegmentTracking',
          )))
    ) {
      const segmentWriteKey = env.REACT_APP_SEGMENT_WRITE_KEY;

      assert(segmentWriteKey, 'Analytics is enabled but no segment write key was provided.');

      load(segmentWriteKey);
      setSegmentLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.authState]);

  useEffect(() => {
    if (!enableAnalytics) return;

    const stopListening = history.listen(trackPageview);

    return stopListening;
  }, [enableAnalytics, history, trackPageview]);
};

export default useAnalyticsTracker;
