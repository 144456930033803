import { Input, type InputProps } from '@cosuno/cosuno-ui';
import { type CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';

import PhoneCountrySelect from './PhoneCountrySelect';

export interface PhoneInputProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: string) => void;
  defaultCountry?: CountryCode | null;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, name, defaultCountry, ...props }) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(defaultCountry ?? 'DE');
  const [phoneNumberNationalPart, setPhoneNumberNationalPart] = useState('');

  useEffect(() => {
    if (phoneNumberNationalPart) {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        phoneNumberNationalPart,
        selectedCountry,
      );
      if (parsedPhoneNumber) {
        onChange(parsedPhoneNumber.number);
      } else {
        onChange('INVALID');
      }
    } else {
      onChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, phoneNumberNationalPart]);

  return (
    <Input
      type="tel"
      {...props}
      value={phoneNumberNationalPart}
      onChange={setPhoneNumberNationalPart}
      prefixLabel={
        <PhoneCountrySelect
          name={`${name ?? 'phone'}Country`}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      }
    />
  );
};

export default PhoneInput;
