import { COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { HeadingMedium } from '~/shared/components/Styles';
import { font } from '~/shared/utils/styles';

export const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: white;
`;

export const Text = styled.p`
  color: ${COLOR.textTertiary};
  ${font.size(16)};
  margin-bottom: 30px;
  max-width: 500px;
  text-align: center;
`;

export const Heading = styled(HeadingMedium)`
  padding-bottom: 12px;
`;
