import { Icon } from '@cosuno/cosuno-ui';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

export const RotatingIcon = styled(Icon)`
  display: block;
  animation: ${rotate} linear 2s infinite;
  color: white;
`;
