import i18n, { type Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import type { LANGUAGE } from '~/__gql__/graphql';

const setupI18n = (lng: LANGUAGE, fallbackLng: LANGUAGE, translations: Resource) =>
  void i18n.use(initReactI18next).init({
    resources: translations,
    lng,
    fallbackLng,
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: { escapeValue: false },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'p'],
    },
  });

export default setupI18n;
