import sanitizeHtml from 'sanitize-html';

import {
  EMPTY_HTML_STRING,
  MESSAGE_HTML_ALLOWED_TAGS,
  MESSAGE_TEXT_HTML_ALLOWED_ATTRIBUTES,
  MESSAGE_TEXT_HTML_ALLOWED_STYLES,
} from './constants';

export const sanitizeHtmlString = (
  dirtyHtml: string,
  allowedTags: string[] = MESSAGE_HTML_ALLOWED_TAGS,
  allowedAttributes = MESSAGE_TEXT_HTML_ALLOWED_ATTRIBUTES,
  allowedStyles = MESSAGE_TEXT_HTML_ALLOWED_STYLES,
) => sanitizeHtml(dirtyHtml, { allowedTags, allowedAttributes, allowedStyles });

export const isHtmlContentEmpty = (htmlString: string) =>
  (sanitizeHtmlString(htmlString, ['img'], {}, {}) ?? '').trim() === '';

export const replaceEmptyParagraphsWithBreaks = (htmlString: string): string =>
  htmlString.replaceAll(EMPTY_HTML_STRING, '<br /><br />');

export const replaceBreaksWithEmptyParagraphs = (htmlString: string): string => {
  const breakRegExpString = '<br( ?/)?>';
  const breakRegExp = new RegExp(breakRegExpString, 'g');
  const twoBreaksRegexp = new RegExp(`(${breakRegExpString}){2}`, 'g');
  return htmlString
    .replaceAll(twoBreaksRegexp, EMPTY_HTML_STRING)
    .replaceAll(breakRegExp, EMPTY_HTML_STRING);
};
