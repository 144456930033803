import { COLOR, Tag, textStyles } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { mixins } from '~/shared/utils/styles';

interface CardWrapperProps {
  $borderColor: COLOR;
  $isHighlighted: boolean;
}

const TOP_BORDER_WIDTH = 4;

export const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;

  grid-row: span 3;
  display: grid;
  grid-template-rows: subgrid;

  ${(props) =>
    props.$isHighlighted
      ? css`
          outline: 2px solid ${mixins.rgba(props.$borderColor, 0.2)};
          border-width: ${TOP_BORDER_WIDTH}px 1px 1px;
        `
      : css`
          border-width: ${TOP_BORDER_WIDTH}px 0 0;
          ${mixins.boxShadowLight2022()}
        `}

  border-style: solid;
  border-color: ${(props) => props.$borderColor};
  border-radius: 4px;

  background-color: ${COLOR.white};
`;

export const Card = styled.div`
  grid-row: span 3;
  display: grid;
  grid-template-rows: subgrid;
  gap: 24px;
  padding: 24px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PackageTitle = styled.h2<{ $color: COLOR }>`
  ${textStyles.titleSmall500};
  color: ${(props) => props.$color};
`;

export const PackageDescription = styled.div`
  ${textStyles.bodyMedium450};
`;

export const FeaturesSection = styled(Section)`
  flex-grow: 1;
`;

export const FeaturesTitle = styled.h3`
  ${textStyles.sectionTitle700};
  color: ${COLOR.textSecondary};
`;

export const FeaturesItemsList = styled.ul`
  ${textStyles.bodySmall450};
  color: ${COLOR.textSecondary};
  list-style: disc;
  margin-left: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HighlightTag = styled(Tag)<{ $background: COLOR }>`
  position: absolute;
  background-color: ${(props) => props.$background};
  left: 50%;
  transform: translateX(-50%) translateY(calc(-50% - ${TOP_BORDER_WIDTH / 2}px));
`;
