import { useCallback } from 'react';

import { useFeatureFlags } from '~/packages/FeatureFlags';

import type { PriceDetails } from '../utils/bids';

export function usePreferGrossTotals() {
  return useFeatureFlags().conditionalPreferGrossTotals;
}

export function useGetBidGrossOrNetTotalBasedOnFeatureFlag() {
  const preferGrossTotals = usePreferGrossTotals();
  return useCallback(
    (details: PriceDetails) => details[preferGrossTotals ? 'totalGross' : 'totalNetWithDiscount'],
    [preferGrossTotals],
  );
}
