import {
  Form as FormikForm,
  Formik,
  type FormikConfig,
  type FormikFormProps,
  type FormikHelpers,
  type FormikProps,
  useFormikContext,
} from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { useNavigationConfirmationFormMethods } from '~/shared/hooks/useNavigationConfirmation';
import useTranslation from '~/shared/hooks/useTranslation';
import { assignWith } from '~/shared/utils/javascript';
import type { Validations, Validator } from '~/shared/utils/validation';
import { generateErrors, is, validateIf } from '~/shared/utils/validation';

import DataLossPreventer from './DataLossPreventer';
import FormContext from './FormContext';
import RequiredFieldsNote from './RequiredFieldsNote';

type MapValuesToValidations<Values, AllValues = Values> = {
  [K in keyof Values]?: Values[K] extends Array<{}>
    ?
        | Validator<Values[K], Values, AllValues>
        | MapValuesToValidations<Values[K][0], AllValues>
        | Array<
            | Validator<Values[K], Values, AllValues>
            | MapValuesToValidations<Values[K][0], AllValues>
          >
    : Validator<Values[K], Values, AllValues> | Validator<Values[K], Values, AllValues>[];
};

export type FormOptions<Values, SubmitReturnValue = unknown> = Omit<
  FormikConfig<Values>,
  'validate' | 'initialValues' | 'onSubmit'
> & {
  validations?: MapValuesToValidations<Values>;
  initialValues: Required<Values>;
  /** Fires on submit, but before the form is reset. Allows canceling submission. */
  onBeforeSubmit?: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    initialValues: Values,
  ) => { cancelSubmission: boolean };
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    initialValues: Values,
  ) => SubmitReturnValue | Promise<SubmitReturnValue>;
};

enum FORM_STATUS {
  default,
  submitFailed,
  submitInterrupted,
}

export type FormProps<Values> = FormOptions<Values, unknown> & {
  preventDataLoss?: boolean | 'lazy';
};

interface FormContentProps<Values extends {}>
  extends Pick<FormProps<Values>, 'children' | 'preventDataLoss'> {
  setOnSubmitResume: React.Dispatch<React.SetStateAction<{ callback: () => void } | null>>;
  formikProps: FormikProps<Values>;
}

const Form = <Values extends {}>({
  validations,
  validateOnBlur = false,
  preventDataLoss,
  onBeforeSubmit,
  onSubmit,
  children,
  innerRef,
  ...props
}: FormProps<Values>): ReturnType<React.FC<FormProps<Values>>> => {
  const { t } = useTranslation();

  const { markAllFormsAsClean } = useNavigationConfirmationFormMethods();

  const [onSubmitResume, setOnSubmitResume] = useState<{ callback: () => void } | null>(null);

  return (
    <Formik
      {...props}
      innerRef={innerRef}
      validateOnBlur={validateOnBlur}
      validate={(values) => {
        if (validations) {
          return generateErrors(values, validations as Validations, undefined, t);
        }

        return {};
      }}
      onSubmit={async (values, formikHelpers) => {
        setOnSubmitResume(null);

        if (onBeforeSubmit) {
          const { cancelSubmission } = onBeforeSubmit(values, formikHelpers, props.initialValues);
          if (cancelSubmission) {
            formikHelpers.setStatus(FORM_STATUS.submitInterrupted);
            return;
          }
        }
        formikHelpers.setStatus(FORM_STATUS.default);
        if (preventDataLoss !== 'lazy') {
          formikHelpers.resetForm({ values });
          markAllFormsAsClean();
        }
        const trimmedValues = assignWith({}, values, (_, srcValue) => {
          if (typeof srcValue === 'string') return srcValue.trim();
          return srcValue;
        });
        try {
          const result = await onSubmit(trimmedValues, formikHelpers, props.initialValues);
          onSubmitResume?.callback();
          if (preventDataLoss === 'lazy') {
            formikHelpers.resetForm({ values });
            markAllFormsAsClean();
          }
          return result;
        } catch (error) {
          if (error instanceof SubmitInterruptedError) {
            return formikHelpers.setStatus(FORM_STATUS.submitInterrupted);
          }
          return formikHelpers.setStatus(FORM_STATUS.submitFailed);
        }
      }}
    >
      {(formikProps) => (
        <FormContent<Values> {...{ preventDataLoss, setOnSubmitResume }} formikProps={formikProps}>
          {children}
        </FormContent>
      )}
    </Formik>
  );
};

function FormContent<Values extends {}>({
  preventDataLoss,
  setOnSubmitResume,
  children,
  formikProps,
}: FormContentProps<Values>) {
  const wrapperRef = useRef<HTMLFormElement>(null);

  const handleCancelledNavigation = () => {
    const input = wrapperRef.current?.querySelector('input');

    if (input) {
      input.focus();
    }
  };

  return (
    <FormContext.Provider value={{ wrapperRef }}>
      <DataLossPreventer
        isDirty={formikProps.dirty}
        formInput={
          preventDataLoss && {
            onCancelledNavigation: handleCancelledNavigation,
            onDiscardChanges: formikProps.resetForm,
            submit: formikProps.submitForm,
            hasSubmitFailed: () =>
              formikProps.status === FORM_STATUS.submitFailed || !formikProps.isValid,
            hasSubmitInterrupted: () => formikProps.status === FORM_STATUS.submitInterrupted,
            onSubmitResume: (callback) => {
              setOnSubmitResume({ callback });
            },
          }
        }
      />
      {children instanceof Function ? children(formikProps) : children}
    </FormContext.Provider>
  );
}

const FormElement: React.FC<FormikFormProps> = (props) => {
  const { wrapperRef } = useContext(FormContext);

  return <FormikForm noValidate {...props} ref={wrapperRef} />;
};

export type ValidationStateChangeHandler = (isValid: boolean) => void;
interface ValidationNotifierProps {
  onValidationStateChange?: ValidationStateChangeHandler;
}
const ValidationNotifier: React.FC<ValidationNotifierProps> = (props) => {
  const { isValid } = useFormikContext();

  useEffect(() => {
    props.onValidationStateChange?.(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return null;
};

Form.ValidationNotifier = ValidationNotifier;

Form.Element = FormElement;

Form.is = is;

Form.if = validateIf;

Form.RequiredFieldsNote = RequiredFieldsNote;

export default Form;

class SubmitInterruptedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SubmitInterruptedError';
  }
}

export function interruptForm(): never {
  throw new SubmitInterruptedError('Form was interrupted');
}
