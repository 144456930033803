// import these from the useRoutes() hook

export enum CompanySettingsTabs {
  analytics = 'analytics',
  branding = 'branding',
  certificateTypes = 'certificate-types',
  contactImport = 'contact-import',
  customCatalogs = 'custom-catalogs',
  customEmail = 'custom-email',
  customFields = 'custom-fields',
  /** @deprecated Used only for redirecting old link to the new one */
  customProjectFields = 'custom-project-fields',
  dataExport = 'data-export',
  /** @deprecated Used only for redirecting old link to the new one */
  emailTemplates = 'email-templates',
  mfa = 'mfa',
  multiUserApprovals = 'multi-user-approvals',
  offices = 'offices',
  permissions = 'permissions',
  pricingPlans = 'pricing-plans',
  profile = 'profile',
  ratingTemplate = 'rating-template',
  reminders = 'reminders',
  sso = 'sso',
  templates = 'templates',
  textProviders = 'text-providers',
  users = 'users',
  notifications = 'notifications',
  /** @deprecated premium page moved to /premium */
  premium = 'premium',
}

export enum SubcontractorProfileTabs {
  overview = 'overview',
  bidRequests = 'bidRequests',
  notes = 'notes',
  certificates = 'certificates',
  ratings = 'ratings',
  messages = 'messages',
}

export enum ShowBidPackageTabs {
  preparation = 'preparation',
  overview = 'overview',
  documents = 'documents',
  boq = 'boq',
  bidders = 'bidders',
  'price-comparison' = 'price-comparison',
  messages = 'messages',
  contract = 'contract',
  addendums = 'addendums',
  billing = 'billing',
}
