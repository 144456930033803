import type { Selection } from '@remirror/core';
import { useAttrs, useChainedCommands, useCurrentSelection } from '@remirror/react';
import React, { useMemo } from 'react';
import { extractHref } from 'remirror/extensions';

import type { ConfirmationModalStateData } from '~/shared/components/ConfirmationModal/useConfirmationModalState';
import Field from '~/shared/components/Field';
import Form from '~/shared/components/Form';
import InputModal from '~/shared/components/InputModal';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useTranslation from '~/shared/hooks/useTranslation';

/**
 * Returns text from the first occurrence of a text node or sequential text nodes
 */
const getFirstTextInSelection = (selection: Selection) => {
  let text = '';

  selection.content().content.descendants((node) => {
    if (node.isText) {
      text += node.text;
    } else if (node.isLeaf && node.type.spec.leafText) {
      text += node.type.spec.leafText(node);
    } else if (!node.isText && text !== '') {
      return false;
    }

    return undefined;
  });

  return text;
};

/**
 * Returns text across all child nodes
 */
const getFullTextInSelection = (selection: Selection) =>
  selection.content().content.textBetween(0, selection.content().content.size);

interface Props extends ConfirmationModalStateData {
  disabled?: boolean;
}

export const LinkModal: React.FC<Props> = ({ disabled, ...modalState }) => {
  const { t } = useTranslation('textEditor');
  const { trackEvent } = useAnalytics();

  const chain = useChainedCommands();
  const selection = useCurrentSelection();

  const url = (useAttrs().link()?.href as string) ?? '';

  const [textToDisplay, isTextToDisplayReadOnly] = useMemo(() => {
    if (selection.empty) {
      return ['', false];
    }

    const firstText = getFirstTextInSelection(selection);
    const fullText = getFullTextInSelection(selection);
    const isReadOnly = firstText !== fullText;

    return [firstText + (isReadOnly ? '...' : ''), isReadOnly];
  }, [selection]);

  return (
    <InputModal<
      {
        url: string;
        textToDisplay: string;
      },
      {}
    >
      {...modalState}
      getFormOptions={(confirm) => ({
        initialValues: {
          url,
          textToDisplay,
        },
        validations: {
          url: [Form.is.required(), Form.is.url()],
          textToDisplay: Form.is.required(),
        },
        onSubmit: (values) => {
          trackEvent('myAccountSignatureLink');

          // adding protocol if it's not there
          const href = extractHref({
            url: values.url,
            defaultProtocol: 'http:',
          });

          if (
            selection.empty ||
            (!isTextToDisplayReadOnly && textToDisplay !== values.textToDisplay)
          ) {
            chain
              .insertText(values.textToDisplay, selection)
              .selectText({ from: selection.from, to: chain.tr().selection.to });
          }

          chain.updateLink({ href, auto: false }).focus('end').run();
          confirm();
        },
      })}
      title={t('links.editLink')}
      renderContent={() => (
        <>
          <Field.Input label={t('links.url')} name="url" autoFocus />
          <Field.Input
            label={t('links.textToDisplay')}
            name="textToDisplay"
            disabled={isTextToDisplayReadOnly}
          />
        </>
      )}
    />
  );
};
