import axios from 'axios';
import { useState } from 'react';

import { USER_TYPE } from '~/__gql__/graphql';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import { useInterval } from '~/shared/hooks/useInterval';
import Console from '~/shared/utils/console';
import { env } from '~/shared/utils/env';

export const CHECK_PERIOD_IN_SECONDS = 5;

const useIsPermissionsVersionOutdated = () => {
  const [isOutdated, setIsOutdated] = useState(false);
  const [version, setVersion] = useState<string | null>();
  const user = useCurrentUser();

  const pollingReasonable = ![undefined, USER_TYPE.administrator].includes(user?.type);

  useInterval(
    async () => {
      try {
        const { data, status } = await axios.get<number | null>(
          `${env.REACT_APP_API_BASE}/internal/permissions-version`,
          {
            withCredentials: true,
          },
        );

        if (status === 200) {
          const latestVersion = data !== null ? data.toString() : null;
          if (version !== undefined && version !== latestVersion) {
            setIsOutdated(true);
          }
          setVersion(latestVersion);
        }
      } catch (error) {
        Console.error(`Error while retrieving permission version number: ${String(error)}`);
      }
    },
    CHECK_PERIOD_IN_SECONDS * 1000,
    !isOutdated && pollingReasonable,
  );

  return isOutdated;
};

export default useIsPermissionsVersionOutdated;
