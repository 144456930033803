import { COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import Field from '~/shared/components/Field';
import { mixins } from '~/shared/utils/styles';

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BenefitsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${textStyles.bodyMedium450}
  color: ${COLOR.textSecondary};
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Strong = styled.strong`
  ${textStyles.labelMedium500}
`;

export const NameFields = styled.div`
  display: flex;

  & > :last-child {
    margin-left: 20px;
  }
`;

export const LogInLink = styled.span`
  ${mixins.link()}
`;

export const ConfirmPasswordField = styled(Field.Input)`
  margin-bottom: 30px;
`;
