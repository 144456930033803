import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface ScrollSentinelProps {
  /** Callback to fire when the sentinel appears in the viewport */
  onView?: () => void;

  /** Callback to fire when the sentinel disappears from the viewport */
  onUnview?: () => void;

  /** Sentinel's vertical position can be moved upwards, so that it will appear
   * in the viewport earlier than it would otherwise */
  offset?: number;

  /** If true, fire the callbacks whenever the sentinel appears or disappears
   * from the viewport. If false, only fire the callbacks when the hidden/shown
   * state changes, that is, only fire `onView` again after `onUnview` has been
   * called. Defaults to `true`. */
  callbackOnEveryIntersection?: boolean;

  /** Reference to the container element that should be intersected with. Defaults to viewport. */
  containerRef?: React.MutableRefObject<HTMLElement | null>;
}

const ScrollSentinelBase: React.FC<ScrollSentinelProps> = ({
  onView,
  onUnview,
  offset,
  callbackOnEveryIntersection = true,
  containerRef,
  ...props
}) => {
  const sentinelRef = useRef<HTMLDivElement>(null);
  const isInView = useRef(false);

  useEffect(() => {
    const currentSentinel = sentinelRef.current;

    if (!currentSentinel || (containerRef && !containerRef.current)) {
      return undefined;
    }

    const observerCallback: IntersectionObserverCallback = (entries) => {
      const { isIntersecting } = entries[0];

      if (isIntersecting && (callbackOnEveryIntersection || !isInView.current)) {
        isInView.current = true;
        onView?.();
      }

      if (!isIntersecting && (callbackOnEveryIntersection || isInView.current)) {
        isInView.current = false;
        onUnview?.();
      }
    };

    const observerOptions = {
      root: containerRef?.current ?? undefined,
    };

    const observerCurrent = new IntersectionObserver(observerCallback, observerOptions);

    observerCurrent.observe(currentSentinel);

    return () => {
      observerCurrent.unobserve(currentSentinel);
    };
  });

  return <div {...props} ref={sentinelRef} />;
};

const ScrollSentinel = styled(ScrollSentinelBase)<ScrollSentinelProps>`
  transform: translateY(-${(props) => props.offset || 0}px);
`;

export default ScrollSentinel;
