import {
  SelectMultiWithTags,
  type SelectMultiWithTagsOption,
  type SelectMultiWithTagsProps,
  Spinner,
} from '@cosuno/cosuno-ui';
import React, { useCallback, useMemo, useState } from 'react';

import type { WORK_CATEGORY_TYPE_FILTER, WorkCategoryInput } from '~/__gql__/graphql';
import { GroupedWorkCategoriesModal } from '~/shared/components/GroupedWorkCategoriesModal';
import QueryErrors from '~/shared/components/QueryErrors';
import { DROPDOWN_ITEMS_PER_PAGE } from '~/shared/constants/ui';
import useDebouncedQuery from '~/shared/hooks/useDebouncedQuery';
import useInfiniteScroll from '~/shared/hooks/useInfiniteScroll';
import useModalState from '~/shared/hooks/useModalState';
import useTranslation from '~/shared/hooks/useTranslation';
import type { WorkCategoryInputWithId } from '~/shared/types/workCategories';
import { uniqBy } from '~/shared/utils/javascript';
import { queryWorkCategories } from '~/shared/utils/queries';

import NewTag from './NewTag';

const VIEW_ALL_OPTION_VALUE = 'viewAll';

export interface Props extends Omit<SelectMultiWithTagsProps, 'options' | 'onChange' | 'value'> {
  type: WORK_CATEGORY_TYPE_FILTER;
  onChange?: (value: WorkCategoryInput[]) => void;
  selected?: WorkCategoryInput[];
  showAdvancedSearch?: boolean;
}

export const WorkCategoriesSelect: React.FC<Props> = ({
  type,
  selected: selectedWorkCategories = [],
  onChange,
  invalid,
  showAdvancedSearch,
  ...selectProps
}) => {
  const { t } = useTranslation();
  const groupedWorkCategoriesModalState = useModalState();

  const [query, setQuery] = useState('');
  const { error, ...queryResult } = useDebouncedQuery(queryWorkCategories, {
    variables: { query, type, limit: DROPDOWN_ITEMS_PER_PAGE },
  });

  const workCategories = useMemo(
    () => queryResult.data?.workCategories.workCategories ?? [],
    [queryResult],
  );

  const workCategoriesList = useMemo(
    () => uniqBy([...workCategories, ...selectedWorkCategories], 'name'),
    [selectedWorkCategories, workCategories],
  );

  const value = useMemo(() => selectedWorkCategories.map((w) => w.name), [selectedWorkCategories]);

  const mapWorkCategories = useCallback(
    (categoryNames: string[]): WorkCategoryInput[] =>
      categoryNames.map((name) => ({
        id: workCategoriesList.find((category) => category.name === name)?.id,
        name,
      })),
    [workCategoriesList],
  );

  const handleChange = useCallback(
    (categoryNames: string[]) => {
      if (categoryNames.includes(VIEW_ALL_OPTION_VALUE)) {
        groupedWorkCategoriesModalState.openModal();
      }
      const names = categoryNames.filter((name) => name !== VIEW_ALL_OPTION_VALUE);
      onChange?.(mapWorkCategories(names));
    },
    [onChange, mapWorkCategories, groupedWorkCategoriesModalState],
  );

  const infiniteScroll = useInfiniteScroll({
    itemsCount: queryResult.data?.workCategories.workCategories.length ?? 0,
    totalRecords: queryResult.data?.workCategories.totalRecords ?? 0,
    fetchMore: queryResult.fetchMore,
    variables: queryResult.variables,
    isLoading: queryResult.loading,
  });

  const modalValue = useMemo(
    () => selectedWorkCategories.filter(({ id }) => id) as WorkCategoryInputWithId[],
    [selectedWorkCategories],
  );

  const selectOptions = useMemo(() => {
    const options = workCategoriesList.map((workCategory) => ({
      value: workCategory.name,
      label: workCategory.name,
      subtitleRight: 'isNew' in workCategory && workCategory.isNew ? <NewTag /> : undefined,
    }));

    const viewAllOptionSection = [
      {
        value: VIEW_ALL_OPTION_VALUE,
        label: t('common:expandedView'),
        iconLeft: { type: 'external-link' },
      },
    ] as SelectMultiWithTagsOption[];

    return showAdvancedSearch ? [viewAllOptionSection, options] : options;
  }, [workCategoriesList, showAdvancedSearch, t]);

  if (error) return <QueryErrors error={error} />;

  // Prevent showing spinner and unmounting dropdown while user is typing
  const data = queryResult.data ?? queryResult.previousData;
  if (!data) return <Spinner />;

  return (
    <>
      {showAdvancedSearch && (
        <GroupedWorkCategoriesModal
          modalState={groupedWorkCategoriesModalState}
          value={modalValue}
          onChange={(workCategoriesFromModal) => onChange?.(workCategoriesFromModal)}
          showIsNewIndicators
        />
      )}
      <SelectMultiWithTags
        {...selectProps}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        onSearch={setQuery}
        infiniteScroll={infiniteScroll}
      />
    </>
  );
};
