import { Button, COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const BeamerButtonWrapper = styled.div`
  position: relative;
`;

export const BeamerButton = styled(Button).attrs({ hollow: true })`
  position: static !important;

  .beamer_icon {
    font-family: inherit !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    background: ${COLOR.danger} !important;
    border-radius: 8px !important;
    color: ${COLOR.white} !important;
    line-height: 16px !important;
    left: 18px !important;
    right: auto !important;
    top: 2px !important;
    width: auto !important;
    height: auto !important;
    padding: 0 5px !important;
    pointer-events: none;
  }
`;
