import { graphql } from '~/__gql__';

export const mutationResetPassword = graphql(/* GraphQL */ `
  mutation ResetPassword($email: String!, $reCaptchaToken: String) {
    resetPassword(email: $email, reCaptchaToken: $reCaptchaToken)
  }
`);

export const mutationCheckCredentials = graphql(/* GraphQL */ `
  mutation CheckCredentials($email: String!, $password: String!, $companyType: COMPANY_TYPE) {
    checkCredentials(email: $email, password: $password, companyType: $companyType) {
      loginToken
      mfaRequired
      mfaMethod
      mfaPhoneCensored
      companyName
    }
  }
`);

graphql(/* GraphQL */ `
  fragment RequestPasswordlessLoginCode on RequestPasswordlessLoginCodeResponse {
    ... on RequestPasswordlessLoginCodeFailure {
      reason
    }
  }
`);

export const mutationRequestPasswordlessLoginCodeForBidSubmission = graphql(/* GraphQL */ `
  mutation RequestPasswordlessLoginCodeForBidSubmission(
    $email: String!
    $bidPackageId: ID!
    $redirectUrl: String!
  ) {
    requestPasswordlessLoginCodeForBidSubmission(
      email: $email
      bidPackageId: $bidPackageId
      redirectUrl: $redirectUrl
    ) {
      ...RequestPasswordlessLoginCode
    }
  }
`);

export const mutationRequestPasswordlessLoginCodeForTask = graphql(/* GraphQL */ `
  mutation RequestPasswordlessLoginCodeForTask($email: String!, $redirectUrl: String!) {
    requestPasswordlessLoginCodeForTask(email: $email, redirectUrl: $redirectUrl) {
      ...RequestPasswordlessLoginCode
    }
  }
`);

export const mutationRequestPasswordlessLoginCodeForInternalNote = graphql(/* GraphQL */ `
  mutation RequestPasswordlessLoginCodeForInternalNote($email: String!, $redirectUrl: String!) {
    requestPasswordlessLoginCodeForInternalNote(email: $email, redirectUrl: $redirectUrl) {
      ...RequestPasswordlessLoginCode
    }
  }
`);

graphql(/* GraphQL */ `
  fragment ValidatePasswordlessLoginResponse on ValidatePasswordlessLoginResponse {
    ... on ValidatePasswordlessLoginFailure {
      reason
    }
    ... on CheckCredentialsResponse {
      loginToken
      mfaRequired
      mfaMethod
      mfaPhoneCensored
      companyName
    }
  }
`);

export const mutationValidatePasswordlessLoginCode = graphql(/* GraphQL */ `
  mutation ValidatePasswordlessLoginCode(
    $email: String!
    $verificationCode: String!
    $companyType: COMPANY_TYPE
  ) {
    validatePasswordlessLoginCode(
      email: $email
      verificationCode: $verificationCode
      companyType: $companyType
    ) {
      ...ValidatePasswordlessLoginResponse
    }
  }
`);

export const mutationValidatePasswordlessLoginToken = graphql(/* GraphQL */ `
  mutation ValidatePasswordlessLoginToken($token: String!, $companyType: COMPANY_TYPE) {
    validatePasswordlessLoginToken(token: $token, companyType: $companyType) {
      ...ValidatePasswordlessLoginResponse
    }
  }
`);

export const mutationResendConfirmationEmail = graphql(/* GraphQL */ `
  mutation ResendConfirmationEmail($email: String!) {
    resendOwnConfirmationEmail(email: $email)
  }
`);

export const mutationAgentSignup = graphql(/* GraphQL */ `
  mutation AgentSignup($input: AgentSignupUnsolicitedInput!) {
    agentSignupUnsolicited(input: $input) {
      isFirstUser
      subcontractorName
      company {
        id
        config {
          premiumEnabled
        }
      }
    }
  }
`);
