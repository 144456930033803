import {
  BOQ_FILE_FORMAT,
  BOQ_POSITION_TYPE,
  COST_GROUP_TYPE,
  PROJECT_COST_CATALOG_TYPE,
} from '~/__gql__/graphql';
import type { FileExtension } from '~/shared/utils/acceptFiles';
import { assertUnreachable } from '~/shared/utils/typescript';

import type { ToggleablePositionType } from '../types/positionTypes';
import type { CostGroupCatalogDescriptor } from '../types/shared';

export * from './positionCodes';
export * from './positionTypes';
export * from './reusing';

export const getRequestForProposalBoqFileExtensions = (options: {
  preferredFileFormat: BOQ_FILE_FORMAT | null;
  isExcelSupportEnabled: boolean;
  isDtaSupportEnabled: boolean;
}): FileExtension[] => {
  const { preferredFileFormat, isDtaSupportEnabled, isExcelSupportEnabled } = options;
  const extensions: FileExtension[] = [];

  if (
    preferredFileFormat === null ||
    preferredFileFormat === BOQ_FILE_FORMAT.gaeb90 ||
    preferredFileFormat === BOQ_FILE_FORMAT.gaebXml
  ) {
    extensions.push('.x81', '.x82', '.x83', '.p81', '.p82', '.p83', '.d81', '.d82', '.d83');
  }
  if (preferredFileFormat === null || preferredFileFormat === BOQ_FILE_FORMAT.oenorm) {
    extensions.push('.onlv');
    if (isDtaSupportEnabled) {
      extensions.push('.dta', '.dtn');
    }
  }
  if (isExcelSupportEnabled) {
    extensions.push('.xlsx');
  }

  return extensions;
};

export const getProposalBoqFileExtensions = (options: {
  preferredFileFormat: BOQ_FILE_FORMAT;
  isExcelSupportEnabled: boolean;
  isDtaSupportEnabled: boolean;
}): FileExtension[] => {
  const { preferredFileFormat, isDtaSupportEnabled, isExcelSupportEnabled } = options;
  const extensions: FileExtension[] = [];

  if (
    preferredFileFormat === BOQ_FILE_FORMAT.gaeb90 ||
    preferredFileFormat === BOQ_FILE_FORMAT.gaebXml
  ) {
    extensions.push('.x84', '.p84', '.d84');
  }
  if (preferredFileFormat === BOQ_FILE_FORMAT.oenorm) {
    extensions.push('.onlv');
    if (isDtaSupportEnabled) {
      extensions.push('.dta', '.dtn');
    }
  }

  if (isExcelSupportEnabled) {
    extensions.push('.xlsx');
  }

  return extensions;
};

export const getCustomBudgetBoqFileExtensions = (options: {
  preferredFileFormat: BOQ_FILE_FORMAT;
}): FileExtension[] => {
  const { preferredFileFormat } = options;
  const extensions: FileExtension[] = [];

  if (
    preferredFileFormat === BOQ_FILE_FORMAT.gaeb90 ||
    preferredFileFormat === BOQ_FILE_FORMAT.gaebXml
  ) {
    extensions.push('.x82', '.p82', '.d82');
  }
  if (preferredFileFormat === BOQ_FILE_FORMAT.oenorm) {
    extensions.push('.onlv');
  }

  return extensions;
};

export const POSSIBLE_PROPOSAL_BOQ_EXTENSIONS = ['x84', 'p84', 'd84', 'onlv', 'dta', 'dtn', 'xlsx'];

export const SERVICE_SPECIFICATION_PATH = 'serviceSpecifications[0]';

export const ORDERED_POSITION_TYPES = [
  BOQ_POSITION_TYPE.Base,
  BOQ_POSITION_TYPE.Alternative,
  BOQ_POSITION_TYPE.Optional,
  BOQ_POSITION_TYPE.OptionalWithTotal,
  BOQ_POSITION_TYPE.LumpSum,
  BOQ_POSITION_TYPE.FreeQuantity,
  BOQ_POSITION_TYPE.UnitPriceComponents,
  BOQ_POSITION_TYPE.NotApplicable,
  BOQ_POSITION_TYPE.ReferringExecutionDescription,
  BOQ_POSITION_TYPE.RepeatingDescription,
  BOQ_POSITION_TYPE.Surcharge,
  BOQ_POSITION_TYPE.ToBeSurcharged,
] as const satisfies readonly ToggleablePositionType[];

export const DESCRIPTION_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/gif'] as const;

export const BOQ_NUMERIC_RADIX = 10;
export const BOQ_ALPHANUMERIC_RADIX = 10;
export const BOQ_POSITION_INCREMENT = 10;
export const BOQ_GROUP_INCREMENT = 1;
/**
 * Increment for tier lengths of 1.
 *
 * If we were to use increments of 10 or more, no element could be added
 * for numeric tiers with single digits, so we enforce an increment of 1.
 */
export const BOQ_INCREMENT_FOR_TIER_LENGTH_1 = 1;

export const QUANTITY_FIELD_NAME = 'quantity';
export const UNIT_FIELD_NAME = 'unitTag';
export const FIELD_FOR_CHANGING_ITEM_NUMBER = 'itemNumber.stringRepresentation';
export const BASE_POSITION_REFERENCE_FIELD_NAME = 'alternativeTo';
export const ALTERNATIVE_GROUP_FIELD_NAME = 'alternativeGroupIdentifier';
export const ALTERNATIVE_IDENTIFIER_FIELD_NAME = 'alternativeIdentifier';
export const EXECUTION_DESCRIPTION_REFERENCE_FIELD_NAME = 'executionDescriptionReference';
export const REFERRAL_DESCRIPTION_REFERENCE_FIELD_NAME = 'repetitionTo';
export const IS_COMPLEMENTING_FIELD_NAME = 'isComplementingPosition';
export const COMPLEMENTS_POSITIONS_FIELD_NAME = 'complementsPositions';
export const COMPLEMENTING_PRICE_PERCENTAGE_FIELD_NAME = 'complementingPricePercentage';
export const COMPLEMENTED_BY_FIELD_NAME = 'complementedBy';
export const COMPLEMENTED_FIELD_NAME = 'complemented';
export const COMPLEMENTING_TYPE_FIELD_NAME = 'gaebComplementingType';
export const OENORM_POSITION_SHORT_TEXT_FIELD_NAME = 'oenormPositionProperties.oenormShortText';
export const OENORM_POSITION_SHORT_TEXT_ADDITION_FIELD_NAME =
  'oenormPositionProperties.oenormShortTextAddition';
export const CATALOGUE_REFERENCES_FIELD_NAME = 'catalogueReferences';

export enum CATALOGUE {
  din276 = 'din276',
  oenorm = 'oenorm',
  lbHochbau = 'lbHochbau',
  stlbWorkCategory = 'stlbWorkCategory',
  custom = 'custom',
}

export const CATALOGUE_NAMES: Readonly<Record<CATALOGUE, string>> = {
  [CATALOGUE.din276]: 'DIN276',
  [CATALOGUE.oenorm]: 'OENORM',
  [CATALOGUE.lbHochbau]: 'LBHochbau',
  [CATALOGUE.stlbWorkCategory]: 'STLBWorkCategory',
  [CATALOGUE.custom]: 'Custom',
} as const;

export const CATALOGUE_TYPE_CUSTOM = 'Unknown';
export const CATALOGUE_TYPE_COST_UNIT = 'CostUnit';
export const CATALOGUE_TYPES: Readonly<Record<CATALOGUE, string>> = {
  [CATALOGUE.din276]: 'DIN276',
  [CATALOGUE.oenorm]: 'OenormB1801CostGroup',
  [CATALOGUE.lbHochbau]: CATALOGUE_TYPE_CUSTOM,
  [CATALOGUE.stlbWorkCategory]: CATALOGUE_TYPE_CUSTOM,
  [CATALOGUE.custom]: CATALOGUE_TYPE_CUSTOM,
} as const;

export const CATALOGUE_BY_COST_GROUP_TYPE: Readonly<Record<COST_GROUP_TYPE, CATALOGUE>> = {
  [COST_GROUP_TYPE.din276]: CATALOGUE.din276,
  [COST_GROUP_TYPE.oenorm]: CATALOGUE.oenorm,
} as const;

export const COST_GROUP_CATALOG_BY_COST_GROUP_TYPE: Readonly<
  Record<COST_GROUP_TYPE, CostGroupCatalogDescriptor>
> = {
  [COST_GROUP_TYPE.din276]: { catalogType: PROJECT_COST_CATALOG_TYPE.din276 },
  [COST_GROUP_TYPE.oenorm]: { catalogType: PROJECT_COST_CATALOG_TYPE.oenorm },
} as const;

export enum EDITABLE_BOQ_FIELD_NAME {
  standardizedDescription = 'standardizedDescription',
  catalogueReferences = 'catalogueReferences',
  taxRate = 'taxRate',
  unitPrice = 'unitPrice',
  priceComponents = 'priceComponents',
  labourComponents = 'labourComponents',
  alternativeGroupIdentifier = 'alternativeGroupIdentifier',
  alternativeIdentifier = 'alternativeIdentifier',
  isComplementingPosition = 'isComplementingPosition',
  complementsPositions = 'complementsPositions',
  complementedBy = 'complementedBy',
  complemented = 'complemented',
  isOpeningText = 'isOpeningText',
  isClosingText = 'isClosingText',
}

export const TEXT_ADDITION = 'text-addition';
export const TEXT_ADDITION_CONTAINER = 'text-addition-container';
export const TEXT_ADDITION_LABEL = 'text-addition-label';
export const TEXT_ADDITION_BIDDER = 'text-addition-bidder';
export const TEXT_ADDITION_BUYER = 'text-addition-buyer';
export const TEXT_ADDITION_BUYER_DELETE = 'text-addition-buyer-delete';
export const TEXT_ADDITION_BUYER_TEXT = 'text-addition-buyer-text';
export const TEXT_ADDITION_BODY = 'text-addition-body';
export const TEXT_ADDITION_CAPTION = 'text-addition-caption';
export const TEXT_ADDITION_TAIL = 'text-addition-tail';

export enum BOQ_TEXT_EDITOR_HEADING {
  headerOne = 'headerOne',
  headerTwo = 'headerTwo',
  headerThree = 'headerThree',
  paragraph = 'paragraph',
}

export enum BOQ_TEXT_EDITOR_FONT_FAMILY {
  arial = 'arial',
  courierNew = 'courierNew',
  timesNewRoman = 'timesNewRoman',
}

export enum BOQ_TEXT_EDITOR_COLOR {
  aquamarine = 'aquamarine',
  black = 'black',
  blue = 'blue',
  darkBlue = 'darkBlue',
  darkGreen = 'darkGreen',
  darkRed = 'darkRed',
  green = 'green',
  grey = 'grey',
  lightGrey = 'lightGrey',
  ocher = 'ocher',
  purple = 'purple',
  red = 'red',
  teal = 'teal',
  violet = 'violet',
  yellow = 'yellow',
}

export enum BOQ_TABLE_MODE {
  view = 'view',
  edit = 'edit',
}

export enum BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL {
  GAEB = 'GAEB',
  GAEBorExcel = 'GAEBorExcel',
  OENORM = 'OENORM',
  OENORMorExcel = 'OENORMorExcel',
  Excel = 'Excel',
}

export const mapFileFormatToFileFormatCombinedWithExcel = (
  fileFormat: BOQ_FILE_FORMAT,
  isExcelSupportEnabled?: boolean,
) => {
  switch (fileFormat) {
    case BOQ_FILE_FORMAT.gaeb90:
    case BOQ_FILE_FORMAT.gaebXml:
      return isExcelSupportEnabled
        ? BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL.GAEBorExcel
        : BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL.GAEB;
    case BOQ_FILE_FORMAT.oenorm:
      return isExcelSupportEnabled
        ? BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL.OENORMorExcel
        : BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL.OENORM;
    case BOQ_FILE_FORMAT.xlsx:
      return BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL.Excel;
    default:
      assertUnreachable(fileFormat);
  }
};
