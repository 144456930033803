import './wdyr';
// ie11 end of service message depends on these polyfills
import 'core-js/features/symbol';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { polyfill as smoothScrollPolyfill } from 'seamless-scroll-polyfill';

import apolloClient from '~/apolloClient';
import App from '~/components/App/App';
import EndOfSupportMessageIE from '~/components/EndOfSupportMessageIE';
import { initializeSentry } from '~/sentry';
import setupI18n from '~/setupI18n';
import setupMoment from '~/setupMoment';
import { rootElementId } from '~/shared/constants';
import * as translations from '~/shared/translations';
import { isInternetExplorer } from '~/shared/utils/browser';
import { env } from '~/shared/utils/env';
import applyGoogleTranslateFix from '~/shared/utils/gtranslateFix';
import { detectLanguage, FALLBACK_LANGUAGE } from '~/shared/utils/language';

initializeSentry();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    throw error;
  },
);

axios.interceptors.request.use((request) => {
  request.headers.set('X-Cosuno-Client-Release', env.REACT_APP_RELEASE_HASH);
  return request;
});

setupI18n(detectLanguage(), FALLBACK_LANGUAGE, translations);

setupMoment();

applyGoogleTranslateFix();

smoothScrollPolyfill();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById(rootElementId)!);

root.render(
  isInternetExplorer ? (
    <EndOfSupportMessageIE />
  ) : (
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  ),
);
