import type { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import type { GraphQLError } from 'graphql';
import React, { useContext, useEffect } from 'react';

import { useTermsAcceptance } from '~/components/Terms/useTermsAcceptance';
import { ERROR_TYPES } from '~/shared/constants';
import AuthContext, { type LoginState } from '~/shared/contexts/authContext';

import PageError from './PageError';

interface Props {
  error: ApolloError;
  loginState?: LoginState;
}

const isUnauthenticatedError = (error: GraphQLError) =>
  error.extensions?.code === ERROR_TYPES.unauthenticated ||
  error.message === 'Access denied! You need to be authorized to perform this action!';
const isCommonNetworkError = (error: Error) => error.message === 'Failed to fetch';
const isTermsNotAcceptedError = (error: GraphQLError) =>
  error.extensions?.code === ERROR_TYPES.termsNotAccepted;

const QueryErrors: React.FC<Props> = ({ error: { graphQLErrors, networkError }, loginState }) => {
  const { logout } = useContext(AuthContext);
  const { requestTermsAcceptance } = useTermsAcceptance();

  useEffect(() => {
    if (networkError && !isCommonNetworkError(networkError)) {
      Sentry.captureException(networkError);
    }
  }, [networkError]);

  if (graphQLErrors.some(isTermsNotAcceptedError)) {
    requestTermsAcceptance(graphQLErrors);
    return null;
  }

  if (graphQLErrors.some(isUnauthenticatedError)) {
    logout({ redirectBack: true, loginState });
    return null;
  }

  return (
    <PageError
      message={[
        ...graphQLErrors.map(({ message }) => message),
        ...(networkError ? [networkError.message] : []),
      ]}
    />
  );
};

export default QueryErrors;
