import { env } from '~/shared/utils/env';

import type { StoredAnalyticsEvent } from './types';

export const storeEventForCypress = (event: StoredAnalyticsEvent) => {
  if (env.REACT_APP_CYPRESS !== 'true') return;

  const analyticsEventsRaw = sessionStorage.getItem('analyticsEvents');
  const analyticsEvents: Array<StoredAnalyticsEvent> = analyticsEventsRaw
    ? JSON.parse(analyticsEventsRaw)
    : [];
  analyticsEvents.push(event);
  sessionStorage.setItem('analyticsEvents', JSON.stringify(analyticsEvents));
};

export const getInformedBiddersPropValue = (
  notifyBidders: boolean,
  didShowNotifyBiddersModal: boolean,
) => {
  if (!didShowNotifyBiddersModal) {
    return 'n/a';
  }

  return notifyBidders ? 'yes' : 'no';
};
