import React from 'react';

import Page from '~/shared/components/Page';
import { SplitScreenUnauthenticatedPage } from '~/shared/components/UnauthenticatedPage';

import Topbar from '../Topbar';
import { AuthenticatedWrapper, Heading, Message } from './Styles';

interface MessagePage {
  isAuthenticated: boolean;
  pageTitle: string;
  heading?: string;
  message: React.ReactNode;
  renderAction?: () => React.ReactNode;
  is404?: boolean;
}

const MessagePage: React.FC<MessagePage> = ({
  isAuthenticated,
  pageTitle,
  heading,
  message,
  renderAction = () => null,
  is404 = false,
  ...props
}) => {
  const content = (
    <>
      {heading && <Heading is404={is404}>{heading}</Heading>}
      <Message>{message}</Message>
      {renderAction()}
    </>
  );

  if (isAuthenticated) {
    return (
      <Page {...props} title={pageTitle} renderHeader={() => <Topbar />}>
        <AuthenticatedWrapper>{content}</AuthenticatedWrapper>
      </Page>
    );
  }

  return (
    <SplitScreenUnauthenticatedPage {...props} title={pageTitle}>
      {content}
    </SplitScreenUnauthenticatedPage>
  );
};

export default MessagePage;
