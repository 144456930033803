import { TagInteractive } from '@cosuno/cosuno-ui';
import { flushSync } from 'react-dom';
import { preserveAnchorNodePosition } from 'scroll-anchoring';

import useTranslation from '~/shared/hooks/useTranslation';
import type { WorkCategoryInputForSubcontractorWithId } from '~/shared/types/workCategories';

import { SuggestionsLabel, SuggestionsTags, SuggestionsWrapper } from './Styles';
import type { SuggestionHints } from './types';
import { useSuggestions } from './useSuggestions';

export type { SuggestionHints };

export interface WorkCategorySuggestionsProps {
  onChange: (value: WorkCategoryInputForSubcontractorWithId[]) => void;
  value: WorkCategoryInputForSubcontractorWithId[];
  suggestionHints?: SuggestionHints;
}

export const WorkCategorySuggestions = ({
  onChange,
  value,
  suggestionHints,
}: WorkCategorySuggestionsProps) => {
  const { t } = useTranslation();
  const suggestions = useSuggestions(
    value.map(({ id }) => id),
    suggestionHints,
  );

  if (!suggestions.length) {
    return null;
  }

  return (
    <SuggestionsWrapper>
      <SuggestionsLabel>{t('subcontractor.workCategories.suggestionsForYou')}</SuggestionsLabel>
      <SuggestionsTags>
        {suggestions.map((suggestion) => (
          <TagInteractive
            key={suggestion.workCategory.id}
            rightIcon={{ type: 'plus' }}
            data-cy-tag-work-category-suggestion={suggestion.workCategory.name}
            onClick={() => {
              preserveAnchorNodePosition(document, () => {
                flushSync(() => {
                  onChange([
                    ...value,
                    {
                      id: suggestion.workCategory.id,
                      name: suggestion.workCategory.name,
                      isPrimary: false,
                    },
                  ]);
                });
              });
            }}
          >
            {suggestion.workCategory.name}
          </TagInteractive>
        ))}
      </SuggestionsTags>
    </SuggestionsWrapper>
  );
};
