import type { Context } from 'react';

export type Falsy = false | 0 | '' | null | undefined;

export type DefinitelyTruthy<T> = false extends T
  ? never
  : 0 extends T
    ? never
    : '' extends T
      ? never
      : null extends T
        ? never
        : undefined extends T
          ? never
          : T;

export const isNotUndefined = <T>(value: T | undefined): value is T => value !== undefined;

export const isNotNullish = <T>(value: T): value is NonNullable<T> =>
  value !== undefined && value !== null;

export const isNotFalse = <T>(value: T | false): value is T => value !== false;

export const isTruthy = <T>(x: T | Falsy): x is DefinitelyTruthy<T> => Boolean(x);

export const everyIsNotUndefined = <T>(values: Array<T | undefined>): values is T[] =>
  values.every((value) => value !== undefined);

export type ContextValue<T> = T extends Context<infer U> ? U : never;

export const belongsToEnum = <T extends string>(value: string, myEnum: Record<T, T>): value is T =>
  value in myEnum;

export const typeSafeObjectKeys = <T extends object>(object: T) =>
  Object.keys(object) as (keyof T & string)[];

export const typeSafeObjectValues = <K extends string, T>(object: { [_ in K]: T }): T[] =>
  Object.values(object);

export const typeSafeObjectEntries = <T extends object>(object: T) =>
  Object.entries(object) as [keyof T, T[keyof T]][];

export const typeSafeFromEntries = <T extends string, U>(
  entries: Iterable<readonly [T, U]>,
): Record<T, U> => Object.fromEntries(entries) as Record<T, U>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const asyncNoop = (..._args: any[]) => Promise.resolve(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export type EnumToString<T extends string> = `${T}`;

class Unreachable extends Error {
  constructor(value: never) {
    super(
      `Unexpected type of value: ${typeof value}.
This usually happens when a union type is extended to support
a new sub type that is not handled by the type checker.
Have you added a new API type that is not handled yet by the frontend?
Here's an example:

type Row = CommentRow | FormulaRow;

if (row.__typename === 'CommentRow') {
} else if (row.__typename === 'FormulaRow') {
} else {
  // This was supposed to never happen, but since a new type was added,
  // this branch can be reached.
  assertUnreachable(row.__typename);
}
`,
    );
  }
}

/**
 * @throws {Unreachable}
 */
export function assertUnreachable(value: never): never {
  throw new Unreachable(value);
}

/**
 * Used to return default value from e.g. switch case while also requiring all cases to be handled
 */
export const unreachableDefaultValue = <T>(match: never, defaultValue: T): T => defaultValue;

/**
 * Takes a type predicate function and returns the type it asserts.
 *
 * @example
 * ```
 * const hasSomeField = (x: Foo) => x.someField !== undefined;
 * type Test1 = GuardType<typeof hasSomeField>; // Foo
 *
 * const isSubtype = (x: Foo): x is FooSubtype => x.someField !== undefined;
 * type Test2 = GuardType<typeof isSubtype>; // FooSubtype
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GuardType<T extends (x: any) => boolean> = T extends (x: infer A) => boolean
  ? T extends ((x: A) => x is infer B extends A)
    ? B
    : A
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitDistributive<T, K extends PropertyKey> = T extends any
  ? T extends object
    ? OmitRecursively<T, K>
    : T
  : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OmitRecursively<T extends any, K extends PropertyKey> = Omit<
  { [P in keyof T]: OmitDistributive<T[P], K> },
  K
>;

export type MarkNullable<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? T[P] | null : T[P];
};

export type MaybePromise<T> = T | Promise<T>;

/**
 * Excludes all properties of type `V` from `T` that have a key of type `K`.
 * @example
 * ```
 * type Original =
 *   | { status: 'loading' }
 *   | { status: 'error', error: Error };
 * type OriginalWithoutError =
 *   ExcludeByKey<Original, 'status', 'error'>; // { status: 'loading' }
 * ```
 */
export type ExcludeByKey<T, K extends keyof T, V> = T extends Record<K, V> ? never : T;
