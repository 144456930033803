import { useMutation } from '@apollo/client';
import { Button, COLOR, EmptyContent } from '@cosuno/cosuno-ui';
import React, { type ReactNode, useState } from 'react';

import { PRICING_PLAN } from '~/__gql__/graphql';
import { mutationRequestPricingPlanUpsell, showUpsellSuccessToast } from '~/packages/Upsell';
import useTranslation from '~/shared/hooks/useTranslation';
import toast from '~/shared/utils/toast';

import { PricingPlanCard } from '../PricingPlanCard';
import { PlanCardsWrapper, StyledEmptyContent } from './Styles';

export const PACKAGES_CONSULTATION_EMAIL = 'packages@cosuno.de';

interface PricingPlansContentProps {
  /** The highlighted plan will be made to stand out visually and will be
   * recommended to users. */
  highlightedPlan?: PRICING_PLAN | null;
  /** If true, the cards will stretch to cover available horizontal space. If
   * false, they will be aligned left. */
  stretchCards?: boolean;
  /** Will be called when a user clicks on "Request more information" for one
   * of the plans. */
  onRequestPlan?: (plan: PRICING_PLAN) => void;
  /** An alternative can be provided to replace the standard "Request more
   * information" button. Returning `null` will result in nothing being
   * rendered. */
  renderRequestPlanButton?: (plan: PRICING_PLAN) => ReactNode;
}

const PricingPlansContent: React.FC<PricingPlansContentProps> = ({
  highlightedPlan = null,
  stretchCards = false,
  onRequestPlan,
  renderRequestPlanButton,
}) => {
  const { t } = useTranslation('company');

  const [requestingPlan, setRequestingPlan] = useState<PRICING_PLAN | null>(null);
  const [requestPricingPlanUpsell, { loading: isRequesting }] = useMutation(
    mutationRequestPricingPlanUpsell,
  );

  const plans = [
    { plan: PRICING_PLAN.bronze, color: COLOR.accent6 },
    { plan: PRICING_PLAN.silver, color: COLOR.textTertiary },
    { plan: PRICING_PLAN.gold, color: COLOR.warning },
    { plan: PRICING_PLAN.enterprise, color: COLOR.highlightNeon },
  ] as const;

  return (
    <>
      <PlanCardsWrapper $stretchCards={stretchCards}>
        {plans.map(({ plan, color }) => (
          <PricingPlanCard
            key={plan}
            plan={plan}
            color={color}
            isHighlighted={plan === highlightedPlan}
            highlightTagText={t('pricingPlans.highlightTag')}
            packageTitle={t(`pricingPlans.packages.${plan}.packageTitle`)}
            packageDescription={t(`pricingPlans.packages.${plan}.packageDescription`)}
            featuresTitle={t(`pricingPlans.packages.${plan}.featuresTitle`)}
            featuresItems={Array.from(
              t(`pricingPlans.packages.${plan}.featuresItems`, { returnObjects: true }),
            )}
            actionButton={
              renderRequestPlanButton ? (
                renderRequestPlanButton(plan)
              ) : (
                <Button
                  data-cy-button-name={`pricing-plan-${plan}`}
                  onClick={async () => {
                    onRequestPlan?.(plan);

                    try {
                      setRequestingPlan(plan);

                      await requestPricingPlanUpsell({
                        variables: { pricingPlan: plan },
                      });

                      showUpsellSuccessToast(t);
                    } catch (error) {
                      toast.error(error);
                    } finally {
                      setRequestingPlan(null);
                    }
                  }}
                  working={isRequesting && requestingPlan === plan}
                >
                  {t('pricingPlans.requestMoreInfoButton')}
                </Button>
              )
            }
          />
        ))}
      </PlanCardsWrapper>
      <StyledEmptyContent>
        <EmptyContent.CallToAction>{t('pricingPlans.undecidedPlan.cta')}</EmptyContent.CallToAction>
        <EmptyContent.Explanation>
          {t('pricingPlans.undecidedPlan.explanation')}
        </EmptyContent.Explanation>
        <Button
          data-cy-button-name="consultationEmail"
          iconLeft={{ type: 'email' }}
          variant="secondaryBrand"
          linkToExternal={`mailto:${PACKAGES_CONSULTATION_EMAIL}`}
        >
          {PACKAGES_CONSULTATION_EMAIL}
        </Button>
      </StyledEmptyContent>
    </>
  );
};

export default PricingPlansContent;
