import { createContext } from 'react';

import type { ModalsManagerMethods } from '~/shared/hooks/useModalsManager';

const ModalsContext = createContext<ModalsManagerMethods>({
  getIsActive: () => false,
  setAsActive: () => {},
  setAsInactive: () => {},
  getIsOpen: () => false,
});

export default ModalsContext;
