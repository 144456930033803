import type { CellProps, InputSize } from '@cosuno/cosuno-ui';
import { css } from 'styled-components';

import { sizes } from '../sizes';
import type { CSS } from './types';
import { createMixin } from './utils';

interface InputValueCellProps extends CellProps {
  $inputSize?: InputSize;
}

const getInputPadding = (props: InputValueCellProps) => (props.$inputSize === 'small' ? 8 : 12);

const CELL_HORIZONTAL_PADDING = 5;

const getPadding = (props: InputValueCellProps) =>
  getInputPadding(props) + sizes.inputBorderWidth + CELL_HORIZONTAL_PADDING;

const shouldPadLeft = ({ alignItems }: InputValueCellProps) =>
  alignItems === undefined || alignItems === 'flex-start';
const shouldPadRight = ({ alignItems }: InputValueCellProps) => alignItems === 'flex-end';

const inputValueCellPadding = (props: InputValueCellProps, overrides?: CSS) =>
  createMixin(
    css`
      ${shouldPadLeft(props) &&
      css`
        padding-left: ${getPadding(props)}px;
      `}

      ${shouldPadRight(props) &&
      css`
        padding-right: ${getPadding(props)}px;
      `}

      /**
       * These styles have higher specificity even when inherited
       * so we must override them here as well.
       */
      &:first-child {
        ${shouldPadLeft(props) &&
        css`
          padding-left: ${getPadding(props)}px;
        `}
      }

      &:last-child {
        ${shouldPadRight(props) &&
        css`
          padding-right: ${getPadding(props)}px;
        `}
      }
    `,
    overrides,
  );

export default inputValueCellPadding;
