import { Button, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

export const Heading = styled.h1`
  margin: 0;
  color: ${COLOR.textPrimary};
  ${textStyles.titleMedium700}
`;

export const Tip = styled.p<{ $color?: COLOR }>`
  margin: 0;
  color: ${(props) => props.$color ?? COLOR.textSecondary};
  ${textStyles.bodyMedium450}
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

export const BottomLink = styled.div`
  padding-top: 20px;
  text-align: center;
  ${font.size(14)}
  a {
    display: inline-block;
    padding: 3px 0;
    ${mixins.link()}
  }
`;
