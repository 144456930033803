import { graphql } from '~/__gql__';

export const queryWorkCategories = graphql(/* GraphQL */ `
  query WorkCategories(
    $type: WORK_CATEGORY_TYPE_FILTER!
    $query: String
    $offset: Int
    $limit: Int
    $ids: [ID!]
  ) {
    workCategories(type: $type, query: $query, offset: $offset, limit: $limit, ids: $ids) {
      totalRecords
      workCategories {
        id
        name
        type
        isNew
      }
    }
  }
`);

export const queryWorkCategoriesWithGroups = graphql(/* GraphQL */ `
  query WorkCategoriesWithGroups(
    $type: WORK_CATEGORY_TYPE_FILTER!
    $query: String
    $offset: Int
    $limit: Int
    $ids: [ID!]
    $language: LANGUAGE
  ) {
    workCategories(type: $type, query: $query, offset: $offset, limit: $limit, ids: $ids) {
      totalRecords
      workCategories {
        id
        name(language: $language)
        type
        isNew
        group {
          id
          name
          position
          translation(language: $language)
        }
      }
    }
  }
`);

export const queryAvailableWorkCategories = graphql(/* GraphQL */ `
  query AvailableWorkCategories(
    $type: WORK_CATEGORY_TYPE_FILTER!
    $query: String
    $offset: Int
    $limit: Int
    $ids: [ID!]
  ) {
    workCategories(type: $type, query: $query, offset: $offset, limit: $limit, ids: $ids) {
      totalRecords
      workCategories {
        id
        name
        type
        isNew
      }
    }
  }
`);

export const queryOutdatedWorkCategories = graphql(/* GraphQL */ `
  query OutdatedWorkCategories($type: WORK_CATEGORY_TYPE_FILTER!, $ids: [ID!]) {
    workCategories(type: $type, ids: $ids) {
      totalRecords
      workCategories {
        id
        name
        type
      }
    }
  }
`);

export const mutationUpdateBidRequestStatusBySubcontractor = graphql(/* GraphQL */ `
  mutation UpdateBidRequestStatusBySubcontractor($id: ID!, $status: BID_REQUEST_STATUS!) {
    updateBidRequestStatusBySubcontractor(id: $id, status: $status) {
      id
      status
    }
  }
`);

export const mutationUpdateBidRequestDeclinedReasonBySubcontractor = graphql(/* GraphQL */ `
  mutation mutationUpdateBidRequestDeclinedReason(
    $input: UpdateBidRequestDeclinedReasonByAgentInput!
  ) {
    updateBidRequestDeclinedReasonBySubcontractor(input: $input)
  }
`);

export const queryImageUploadUrl = graphql(/* GraphQL */ `
  query ImageUploadUrl($filename: String!) {
    imageUploadUrl(filename: $filename) {
      uploadUrl
      storageKey
    }
  }
`);

export const mutationSendPremiumPurchaseRequest = graphql(/* GraphQL */ `
  mutation AgentSendPremiumPurchaseRequest($subcontractorId: ID) {
    agentSendPremiumPurchaseRequest(subcontractorId: $subcontractorId)
  }
`);

export const querySubcontractorsByAgent = graphql(/* GraphQL */ `
  query SubcontractorsByAgent($includePremiumFields: Boolean!) {
    subcontractorsByAgent {
      id
      ...AgentSubcontractorForm_subcontractor
    }
  }
`);

export const updateSubcontractorWorkCategoriesByAgent = graphql(/* GraphQL */ `
  mutation UpdateSubcontractorWorkCategoriesByAgent(
    $bidRequestId: ID!
    $workCategories: [WorkCategoryInput!]
    $confirmWorkCategoriesModal: Boolean
  ) {
    updateSubcontractorWorkCategoriesByAgent(
      bidRequestId: $bidRequestId
      workCategories: $workCategories
      confirmWorkCategoriesModal: $confirmWorkCategoriesModal
    )
  }
`);

export const updateSubcontractorWorkCategories = graphql(/* GraphQL */ `
  mutation UpdateSubcontractorWorkCategories(
    $subcontractorId: ID!
    $token: ID!
    $workCategories: [WorkCategoryInput!]
    $confirmWorkCategoriesModal: Boolean
  ) {
    updateSubcontractorWorkCategories(
      subcontractorId: $subcontractorId
      modificationToken: $token
      workCategories: $workCategories
      confirmWorkCategoriesModal: $confirmWorkCategoriesModal
    )
  }
`);

export const germanRegistrationCourtsQuery = graphql(/* GraphQL */ `
  query GermanRegistrationCourts {
    germanRegistrationCourts {
      city
      postalCode
    }
  }
`);
