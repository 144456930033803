import { Button, HoverableElement } from '@cosuno/cosuno-ui';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import CombinedNotificationsContext from '~/shared/contexts/combinedNotificationsContext';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';

import { ButtonWithCountBadgeWrapper, CountBadge } from '../Styles';
import { getBadgeNumberWithMax3Digits } from '../utils';

const Messages: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const history = useHistory();
  const routes = useRoutes();

  const currentUser = useCurrentUser();

  const { combinedNotificationsResponse } = useContext(CombinedNotificationsContext);

  if (
    !currentUser ||
    !combinedNotificationsResponse?.data ||
    combinedNotificationsResponse.data.unreadMessageThreadsCount === undefined
  ) {
    return null;
  }

  const totalMessageThreadsUnread = getBadgeNumberWithMax3Digits(
    combinedNotificationsResponse.data?.unreadMessageThreadsCount,
  );

  return (
    <>
      <HoverableElement isTriggerElementClickable tooltipText={t('tooltips.icons.messages')}>
        <ButtonWithCountBadgeWrapper>
          <Button
            data-cy-message-icon
            variant="secondary"
            onClick={() => {
              trackEvent('messagesOpen', {
                userCompanyType: currentUser.company.type,
              });
              history.push(routes.messages());
            }}
            onlyIcon={{ type: 'message' }}
          />
          {totalMessageThreadsUnread ? (
            <CountBadge data-cy-message-count-badge>{totalMessageThreadsUnread}</CountBadge>
          ) : null}
        </ButtonWithCountBadgeWrapper>
      </HoverableElement>
    </>
  );
};

export default Messages;
