import type { CONTACT_TITLE } from '~/__gql__/graphql';
import type { TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import { translateContactTitle } from '~/shared/utils/dynamicTranslationMaps';

interface GetUserNameParams {
  firstName: string | null;
  lastName: string | null;
}

export const getUserName = ({ firstName, lastName }: GetUserNameParams): string =>
  [firstName, lastName].filter(Boolean).join(' ');

interface GetContactNameParams {
  isPerson: boolean;
  title?: CONTACT_TITLE | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export const getContactName = (contact: GetContactNameParams): string | null => {
  if (contact.isPerson) {
    return [contact.firstName, contact.lastName].filter(Boolean).join(' ') || null;
  }

  return contact.email || null;
};

export const getContactNameWithTitle = (
  t: TypeSafeTFunction,
  contact: GetContactNameParams,
): string | null => {
  if (contact.isPerson && contact.title && contact.lastName) {
    const title = translateContactTitle(t, contact.title);
    const name = [contact.firstName, contact.lastName].filter(Boolean).join(' ');

    return [title, name].filter(Boolean).join(' ') || null;
  }

  return getContactName(contact);
};
