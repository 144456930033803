import { useCallback, useMemo } from 'react';

import useStack from './useStack';

export interface ModalsManagerMethods {
  getIsActive: (modalId: string) => boolean;
  setAsActive: (modalId: string) => void;
  setAsInactive: (modalId: string) => void;
  getIsOpen: (modalId: string) => boolean;
}

const useModalsManager = (): ModalsManagerMethods => {
  const { peek, contains, push, remove } = useStack<string>();

  const getIsActive = useCallback((modalId: string) => peek() === modalId, [peek]);

  return useMemo(
    () => ({ getIsActive, setAsActive: push, setAsInactive: remove, getIsOpen: contains }),
    [contains, getIsActive, push, remove],
  );
};

export default useModalsManager;
