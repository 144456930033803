// In SubcontratorsList, we need to be able to store Infinity for the distance field.
const JSON_INFINITY_PLACEHOLDER = 'Infinity-4dc05d4c-5263-42d9-a69e-37e6c21caa81';

export const parseValue = (value: string | null) => {
  if (value === null) {
    return null;
  }

  try {
    return JSON.parse(value, (_, propValue) =>
      propValue === JSON_INFINITY_PLACEHOLDER ? Infinity : propValue,
    );
  } catch {
    return value;
  }
};

export const stringifyValue = (value: unknown) =>
  JSON.stringify(value, (_, propValue) =>
    propValue === Infinity ? JSON_INFINITY_PLACEHOLDER : propValue,
  );
