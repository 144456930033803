import { pull } from 'lodash';
import type { ElementOf } from 'ts-essentials';

import { env } from '~/shared/utils/env';

import { globalFeatureFlags } from './featureFlags';

export type GlobalFeatureFlag = ElementOf<typeof globalFeatureFlags>;

const enabledGlobalFeatures = (env.REACT_APP_ENABLED_FEATURES ?? '').split(',');

/**
 * Global feature flags that are present in the environment variable but have been removed.
 */
const unregisteredFeatures = pull([...enabledGlobalFeatures], ...globalFeatureFlags);

if (unregisteredFeatures.length > 0 && env.MODE === 'development') {
  // eslint-disable-next-line
  console.warn(
    'Unregistered features found in REACT_APP_ENABLED_FEATURES: ',
    ...unregisteredFeatures,
  );
}

/**
 * @returns The value of a system-wide feature flag.
 */
export const isGlobalFeatureEnabled = (feature: GlobalFeatureFlag) =>
  enabledGlobalFeatures.includes(feature);
