import type { i18n, TOptionsBase } from 'i18next';
import {
  // eslint-disable-next-line no-restricted-imports
  useTranslation as useI18NextTranslation,
} from 'react-i18next';
import { assert } from 'ts-essentials';

import { LANGUAGE } from '~/__gql__/graphql';
import type {
  TranslationKey,
  TranslationKeysByNamespace,
  TranslationVariable,
} from '~/shared/types/translationKeys';
import { belongsToEnum } from '~/shared/utils/typescript';

export type TFunctionParamsArgument<
  T extends keyof TranslationKeysByNamespace,
  U extends TranslationKey<T>,
> =
  TranslationVariable<T, U> extends never
    ? [params?: TOptionsBase]
    : [params: TOptionsBase & TranslationVariable<T, U>];

export type TypeSafeTFunction<T extends keyof TranslationKeysByNamespace = 'common'> = <
  U extends TranslationKey<T>,
>(
  key: U,
  ...rest: TFunctionParamsArgument<T, U>
) => string;

interface TypeSafeUseTranslationResponse<T extends keyof TranslationKeysByNamespace = 'common'> {
  t: TypeSafeTFunction<T>;
  i18n: i18n & { language: LANGUAGE };
  ready: boolean;
}

const useTranslation = <T extends keyof TranslationKeysByNamespace = 'common'>(
  namespace?: T,
): TypeSafeUseTranslationResponse<T> => {
  const useTranslationResponse = useI18NextTranslation(namespace);

  assert(
    belongsToEnum(useTranslationResponse.i18n.language, LANGUAGE),
    'Invalid language selected in i18next',
  );

  return useTranslationResponse as TypeSafeUseTranslationResponse<T>;
};

export default useTranslation;
