import { get, set, unset } from 'lodash';

import type { TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import Console from '~/shared/utils/console';

import type { Errors, Validations, Validator, Values } from './types';

const isValidator = (obj: object): obj is Validator => obj instanceof Function;
const isMultipleValidators = (obj: object): obj is Validator[] => Array.isArray(obj);
const isNestedValidators = (obj: object): obj is Validations =>
  !isValidator(obj) && !isMultipleValidators(obj);

const assignErrorBuilder =
  (errors: Errors) =>
  (
    validator: Validator,
    fieldName: string,
    fieldValues: Values,
    allFieldValues: Values,
    t: TypeSafeTFunction,
  ): void => {
    const value = get(fieldValues, fieldName);
    const errorMessage = validator({ value, values: fieldValues, allValues: allFieldValues, t });
    if (errorMessage && !get(errors, fieldName)) {
      set(errors, fieldName, errorMessage);
    }
  };

// We assume here that nested validation is used only for field arrays
const assignNestedErrorBuilder =
  (errors: Errors) =>
  (
    nestedValidators: Validations,
    fieldName: string,
    allValues: Values,
    t: TypeSafeTFunction,
  ): void => {
    const values = get(allValues, fieldName) as Values[];
    const errorMessages = values.map((nestedValues) =>
      generateErrors(nestedValues, nestedValidators, allValues, t),
    );
    const hasErrorMessages = errorMessages.find((item) => Object.keys(item).length > 0);

    if (hasErrorMessages && !get(errors, fieldName)) {
      set(errors, fieldName, errorMessages);
    } else if (!hasErrorMessages && !get(errors, fieldName)) {
      unset(errors, fieldName);
    }
  };

export const generateErrors = (
  fieldValues: Values,
  fieldValidators: Validations,
  allFieldValues: Values | undefined,
  t: TypeSafeTFunction,
): Errors => {
  const errors: Errors = {};
  const assignError = assignErrorBuilder(errors);
  const assignNestedError = assignNestedErrorBuilder(errors);

  try {
    Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
      if (!validators) {
        return;
      }

      [validators].flat().forEach((validator) => {
        if (isNestedValidators(validator)) {
          assignNestedError(validator, fieldName, fieldValues, t);
        } else {
          assignError(validator, fieldName, fieldValues, allFieldValues || fieldValues, t);
        }
      });
    });
  } catch (error) {
    // Errors in validators get caught somewhere (in Formik?) and muted.
    Console.error(error);
    throw error;
  }

  return errors;
};
