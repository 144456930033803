import { Avatar, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { font } from '~/shared/utils/styles';

export const Explanation = styled.div`
  margin: 20px 0;
  color: ${COLOR.textPrimary};
  ${textStyles.bodyMedium450};
`;

export const ExplanationChooseMethod = styled(Explanation)`
  margin-bottom: 30px;
`;

export const BoldedText = styled.span`
  color: ${COLOR.textSecondary};
  ${textStyles.labelMedium500};
`;

export const UserAvatar = styled(Avatar)`
  flex-shrink: 0;
  margin-right: 10px;
`;

export const SuggestionListItemNameAndEmail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const EmailText = styled.div<{ $isHighlighted?: boolean }>`
  ${font.size(14)}
  ${({ $isHighlighted }) =>
    !$isHighlighted &&
    css`
      ${font.size(12)}
      color: ${COLOR.textTertiary};
    `};
`;

export const SuggestionListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-inline-start: 0;
  width: 100%;
`;

export const UserName = styled.div`
  margin-bottom: 6px;
  ${font.size(14)}
`;

export const SuggestionListItemContainer = styled.li`
  margin: 0;
  text-align: start;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-block: 12px;
  min-width: 450px;
  cursor: pointer;
`;

export const StyledSuggestionList = styled.ul`
  ${SuggestionListItemContainer} {
    border-bottom: 1px solid ${COLOR.borderMedium};
  }
`;

export const LoginInMethodButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  direction: column;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
`;
