import { Button, COLOR, isEnter, isEntering, isExited, isExiting } from '@cosuno/cosuno-ui';
import type { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

import { MODAL_VARIANTS } from '~/shared/constants';
import { mediaQueries, mixins, zIndexValues } from '~/shared/utils/styles';

export const TRANSITION_DURATION = 200;
export const SLIDE_DURATION = 400;

interface OverlayProps {
  $isActive: boolean;
  $isTopLevelModal?: boolean;

  $transitionState: TransitionStatus;
  $hasPrimaryColorOverlay?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  z-index: ${(props) => {
    if (props.$isTopLevelModal) {
      return zIndexValues.topLevelModalOverlay;
    }
    return props.$isActive ? zIndexValues.modalOverlay : zIndexValues.modalOverlayInactive;
  }};
  position: fixed;
  inset: 0;
  background: ${COLOR.modalOverlay};
  transition: opacity ${TRANSITION_DURATION}ms;

  ${(props) =>
    isEnter(props.$transitionState)
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}

  ${({ $hasPrimaryColorOverlay }) =>
    $hasPrimaryColorOverlay &&
    css`
      background: ${mixins.rgba(COLOR.brand, 0.8)};
    `}
`;

interface LayoutProps {
  $isActive: boolean;
  $isTopLevelModal?: boolean;
  $transitionState: TransitionStatus;
  $variant: MODAL_VARIANTS;
}

export const Layout = styled.div<LayoutProps>`
  z-index: ${(props) => {
    if (props.$isTopLevelModal) {
      return zIndexValues.topLevelModal;
    }
    return props.$isActive ? zIndexValues.modal : zIndexValues.modalInactive;
  }};
  position: fixed;
  inset: 0;
  ${(props) => overlayStyles[props.$variant]}
  ${(props) =>
    isExited(props.$transitionState) &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

interface LayoutInnerProps {
  $transitionState: TransitionStatus;
}

export const LayoutInner = styled.div<LayoutInnerProps>`
  flex: 1;
  max-height: 100%;
  width: 100%;
  ${mixins.scrollableY()}

  ${(props) =>
    (isEntering(props.$transitionState) || isExiting(props.$transitionState)) &&
    css`
      overflow: hidden;
    `}
`;

const overlayStyles = {
  [MODAL_VARIANTS.center]: css<LayoutProps>`
    display: flex;
    align-items: center;
  `,
  [MODAL_VARIANTS.fullSize]: css<LayoutProps>`
    display: flex;
    align-items: center;
  `,
  [MODAL_VARIANTS.extraWide]: css<LayoutProps>`
    display: flex;
    align-items: center;
  `,
  [MODAL_VARIANTS.right]: css<LayoutProps>`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  `,
};

export const verticalModalMargin = 50;

interface CardProps {
  $maxWidth?: number;
  $isScrollableWithin?: boolean;
  $hasGradient?: boolean;
  $allowOverflow?: boolean;
  transitionState: TransitionStatus;
  variant: MODAL_VARIANTS;
}

export const Card = styled.div<CardProps>`
  position: relative;
  display: flex; /* allow inner content take the whole height and show scrollbar at the bottom of a modal if it's scrollable  */
  width: 100%;
  background: ${COLOR.white};
  transition-property: transform, opacity;
  transition-duration: ${TRANSITION_DURATION}ms;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  border-radius: 20px;
  ${mixins.boxShadowLight2022()}

  ${(props) => cardStyles[props.variant]}

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: min(${$maxWidth}px, calc(100% - 40px));
    `}

  ${({ $hasGradient }) =>
    $hasGradient &&
    css`
      background: linear-gradient(117.75deg, #fff 47.62%, #e0e6f0 115.71%, #e2e7f0 115.71%);
    `}
  
  ${({ $allowOverflow }) =>
    $allowOverflow &&
    css`
      overflow: visible;
    `}
`;

const cardStyles = {
  [MODAL_VARIANTS.center]: css<CardProps>`
    max-width: 580px;
    ${({ $isScrollableWithin }) =>
      $isScrollableWithin &&
      css`
        max-height: calc(100vh - ${2 * verticalModalMargin}px);
      `}
    vertical-align: middle;
    margin: ${verticalModalMargin}px auto;
    overflow: hidden;

    ${(props) =>
      isEnter(props.transitionState)
        ? css`
            opacity: 1;
            transform: translateY(0);
          `
        : css`
            opacity: 0;
            transform: translateY(50%);
          `}

    @media ${mediaQueries.mobile} {
      margin: 0;
      max-width: 100%;
      min-height: 100vh;
      ${({ $isScrollableWithin }) =>
        $isScrollableWithin &&
        css`
          max-height: 100vh;
        `}
      width: 100%;
    }
  `,
  [MODAL_VARIANTS.fullSize]: css<CardProps>`
    max-width: 1000px;
    min-height: 0;
    ${({ $isScrollableWithin }) =>
      $isScrollableWithin &&
      css`
        max-height: calc(100vh - ${2 * verticalModalMargin}px);
      `}
    margin: ${verticalModalMargin}px auto;

    ${(props) =>
      isEnter(props.transitionState)
        ? css`
            opacity: 1;
            transform: translateY(0);
          `
        : css`
            opacity: 0;
            transform: translateY(50%);
          `}

    @media ${mediaQueries.mobile} {
      margin: 0;
      min-height: 100vh;
      width: 100%;
      ${({ $isScrollableWithin }) =>
        $isScrollableWithin &&
        css`
          max-height: 100vh;
        `}
    }
  `,
  [MODAL_VARIANTS.extraWide]: css<CardProps>`
    max-width: 1440px;
    min-height: calc(100vh - ${2 * verticalModalMargin}px);
    ${({ $isScrollableWithin }) =>
      $isScrollableWithin &&
      css`
        max-height: calc(100vh - ${2 * verticalModalMargin}px);
      `}
    width: calc(100% - 50px);
    margin: ${verticalModalMargin}px auto;
    overflow: hidden;

    ${(props) =>
      isEnter(props.transitionState)
        ? css`
            opacity: 1;
            transform: translateY(0);
          `
        : css`
            opacity: 0;
            transform: translateY(50%);
          `}

    @media ${mediaQueries.mobile} {
      margin: 0;
      min-height: 100vh;
      width: 100%;
      ${({ $isScrollableWithin }) =>
        $isScrollableWithin &&
        css`
          max-height: 100vh;
        `}
    }
  `,
  [MODAL_VARIANTS.right]: css<CardProps>`
    min-height: 100vh;
    box-shadow: none;
    margin: 0;
    opacity: 1;
    width: 100%;
    overflow: hidden;
    border-radius: 0;

    transition: right ${SLIDE_DURATION}ms;

    @media ${mediaQueries.tablet} {
      /* keeping content at 600px width and adjusting side paddings */
      padding-left: calc(50% - 300px);
      padding-right: calc(50% - 300px);
    }

    @media ${mediaQueries.desktop} {
      padding-left: 0;
      padding-right: calc(
        50% - 720px
      ); /* keeping content at max 800px and adding space on the right */
      width: 50%;
      margin-left: auto;

      ${(props) =>
        isEnter(props.transitionState)
          ? css`
              right: 0;
            `
          : css`
              right: -50%;
            `}
    }
  `,
};

export const CloseButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: ${zIndexValues.modalCloseIcon};
`;

interface ScrollableCardContentProps {
  $allowOverflow?: boolean;
}

export const ScrollableCardContent = styled.div<ScrollableCardContentProps>`
  ${({ $allowOverflow }) =>
    $allowOverflow
      ? css`
          overflow: visible;
        `
      : css`
          overflow-x: auto;
          overflow-y: hidden;
        `}

  display: block;

  /* This is to prevent the focus borders of controls inside from being hidden */
  width: calc(100% + 4px);
  padding: 2px;
  margin: -2px;
`;
