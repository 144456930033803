import { MessagePopup } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { mediaQueries, sizes, zIndexValues } from '~/shared/utils/styles';

export const StyledMessagePopup = styled(MessagePopup)`
  position: fixed;
  bottom: 20px;
  z-index: ${zIndexValues.appOutdatedNotification};

  @media ${mediaQueries.mobile} {
    left: 20px;
  }

  @media ${mediaQueries.tablet} {
    left: ${sizes.navbarWidthDesktop + 20}px;
  }
`;
