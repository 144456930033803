import { Input } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const StyledNumberInput = styled(Input).attrs({ type: 'number' })`
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  & input[type='number'] {
    appearance: textfield;
  }
`;
