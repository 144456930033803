// eslint-disable-next-line no-restricted-imports
import { useDebounce as useDebounceHook } from 'use-debounce';

import type { Options } from './types';
import { getArguments } from './utils';

type ReturnValue<T> = ReturnType<typeof useDebounceHook<T>>;

export function useDebounce<T>(value: T, delay?: number): ReturnValue<T>;
export function useDebounce<T>(value: T, options?: Options): ReturnValue<T>;
export function useDebounce<T>(value: T, delayOrOptions?: number | Options): ReturnValue<T> {
  return useDebounceHook(value, ...getArguments(delayOrOptions));
}
