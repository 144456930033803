import { useState } from 'react';

import useQueryParam from '~/shared/hooks/useQueryParam';

export const usePasswordlessLoginTokenQueryParam = (): {
  passwordlessLoginToken: string | null;
} => {
  const [passwordlessLoginToken, setPasswordlessLoginToken] = useState<string | null>(null);

  useQueryParam('passwordlessLoginToken', (token) => {
    if (!token || typeof token !== 'string') return;

    setPasswordlessLoginToken(token);
  });

  return { passwordlessLoginToken };
};
