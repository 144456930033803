import currencyDataBG from 'cldr-numbers-full/main/bg/currencies.json';
import currencyDataCS from 'cldr-numbers-full/main/cs/currencies.json';
import currencyDataDA from 'cldr-numbers-full/main/da/currencies.json';
import currencyDataDE from 'cldr-numbers-full/main/de/currencies.json';
import currencyDataGR from 'cldr-numbers-full/main/el/currencies.json';
import currencyDataEN from 'cldr-numbers-full/main/en/currencies.json';
import currencyDataES from 'cldr-numbers-full/main/es/currencies.json';
import currencyDataET from 'cldr-numbers-full/main/et/currencies.json';
import currencyDataFI from 'cldr-numbers-full/main/fi/currencies.json';
import currencyDataFR from 'cldr-numbers-full/main/fr/currencies.json';
import currencyDataHR from 'cldr-numbers-full/main/hr/currencies.json';
import currencyDataHU from 'cldr-numbers-full/main/hu/currencies.json';
import currencyDataIT from 'cldr-numbers-full/main/it/currencies.json';
import currencyDataLT from 'cldr-numbers-full/main/lt/currencies.json';
import currencyDataLV from 'cldr-numbers-full/main/lv/currencies.json';
import currencyDataNL from 'cldr-numbers-full/main/nl/currencies.json';
import currencyDataPL from 'cldr-numbers-full/main/pl/currencies.json';
import currencyDataPT from 'cldr-numbers-full/main/pt/currencies.json';
import currencyDataRO from 'cldr-numbers-full/main/ro/currencies.json';
import currencyDataSK from 'cldr-numbers-full/main/sk/currencies.json';
import currencyDataSV from 'cldr-numbers-full/main/sv/currencies.json';
import type { ValueOf } from 'ts-essentials';

import { type CURRENCY, LANGUAGE } from '~/__gql__/graphql';

export const currencyDataByLanguage = {
  [LANGUAGE.bg]: currencyDataBG.main.bg.numbers.currencies,
  [LANGUAGE.cs]: currencyDataCS.main.cs.numbers.currencies,
  [LANGUAGE.da]: currencyDataDA.main.da.numbers.currencies,
  [LANGUAGE.de]: currencyDataDE.main.de.numbers.currencies,
  [LANGUAGE.en]: currencyDataEN.main.en.numbers.currencies,
  [LANGUAGE.es]: currencyDataES.main.es.numbers.currencies,
  [LANGUAGE.et]: currencyDataET.main.et.numbers.currencies,
  [LANGUAGE.fi]: currencyDataFI.main.fi.numbers.currencies,
  [LANGUAGE.fr]: currencyDataFR.main.fr.numbers.currencies,
  [LANGUAGE.gr]: currencyDataGR.main.el.numbers.currencies,
  [LANGUAGE.hr]: currencyDataHR.main.hr.numbers.currencies,
  [LANGUAGE.hu]: currencyDataHU.main.hu.numbers.currencies,
  [LANGUAGE.it]: currencyDataIT.main.it.numbers.currencies,
  [LANGUAGE.lt]: currencyDataLT.main.lt.numbers.currencies,
  [LANGUAGE.lv]: currencyDataLV.main.lv.numbers.currencies,
  [LANGUAGE.nl]: currencyDataNL.main.nl.numbers.currencies,
  [LANGUAGE.pl]: currencyDataPL.main.pl.numbers.currencies,
  [LANGUAGE.pt]: currencyDataPT.main.pt.numbers.currencies,
  [LANGUAGE.ro]: currencyDataRO.main.ro.numbers.currencies,
  [LANGUAGE.sk]: currencyDataSK.main.sk.numbers.currencies,
  [LANGUAGE.sv]: currencyDataSV.main.sv.numbers.currencies,
} as const;

const formatCurrency = ({ currency, displayName }: { currency: CURRENCY; displayName: string }) =>
  `${currency} – ${displayName}`;

export const translateCurrency = (currency: CURRENCY, language: LANGUAGE) =>
  formatCurrency({ currency, ...currencyDataByLanguage[language][currency] });

export const getCurrencySymbol = (
  currency: CURRENCY,
  language: LANGUAGE,
  {
    preferNarrow = true,
  }: {
    /** Whether to prefer a shorter symbol if available. */
    preferNarrow?: boolean;
  } = {},
) => {
  const allCurrencies: ValueOf<typeof currencyDataByLanguage> | undefined =
    currencyDataByLanguage[language];
  if (!allCurrencies) return undefined;

  const currencyData = allCurrencies[currency];
  if (!currencyData) return undefined;

  if (preferNarrow && 'symbol-alt-narrow' in currencyData) {
    return currencyData['symbol-alt-narrow'];
  }

  if ('symbol' in currencyData) return currencyData.symbol;

  return currency;
};
