import type { CustomCostGroupDefinitionInput } from '~/__gql__/graphql';

import { ReadOnlyNestedBaseCell, SpacedSpan, StyledRow } from './Styles';

interface CostGroupDefinition {
  localId: string;
  name: string;
  childCostGroups?: CostGroupDefinition[];
}

interface ReadOnlyCostGroupDefinitionsProps extends CostGroupDefinition {
  nestingLevel?: number;
  extraCell?: (props: CustomCostGroupDefinitionInput) => React.ReactNode;
}

const ReadOnlyCostGroupDefinitions: React.FC<ReadOnlyCostGroupDefinitionsProps> = ({
  childCostGroups,
  nestingLevel = 0,
  extraCell,
  ...props
}) => (
  <StyledRow rowId={props.localId} bottomBorder={nestingLevel === 0}>
    <ReadOnlyNestedBaseCell $nestedLevel={nestingLevel}>
      <SpacedSpan>{props.localId}</SpacedSpan>
      <span>{props.name}</span>
    </ReadOnlyNestedBaseCell>
    {extraCell?.({ ...props, childCostGroups })}

    {childCostGroups?.map((childCostGroup) => (
      <ReadOnlyCostGroupDefinitions
        {...childCostGroup}
        key={childCostGroup.localId}
        nestingLevel={nestingLevel + 1}
        extraCell={extraCell}
      />
    ))}
  </StyledRow>
);

export default ReadOnlyCostGroupDefinitions;
