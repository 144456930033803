import { MainNavigation } from '@cosuno/cosuno-ui';
import { useExperimentFlags } from '@cosuno/feature-flags-frontend';
import React from 'react';

import { COMPANY_TYPE, USER_TYPE } from '~/__gql__/graphql';
import { GoldIcon } from '~/components/GoldPackageOverview';
import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import { agentUserHelpUrl, principalUserHelpUrl } from '~/shared/constants/urls';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';
import { getImageSrcSet, getImageUrl, IMAGE_TYPE } from '~/shared/utils/images';

import { PremiumIcon } from '../AgentPremium/PremiumIcon';

interface NavbarProps {
  companyType: COMPANY_TYPE;
  canViewSubcontractors: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ companyType, canViewSubcontractors }) => {
  const { t } = useTranslation();

  const { trackEvent } = useAnalytics();

  const experimentFlags = useExperimentFlags();

  const routes = useRoutes();

  const user = useCurrentUser();
  if (!user) return null;

  const { company, language } = user;

  const customization = isCompanyFeatureEnabled(company, 'featureCustomBranding')
    ? {
        logoUrl: company.logoKey && getImageUrl(company.logoKey, IMAGE_TYPE.companyLogo),
        logoSrcSet: company.logoKey && getImageSrcSet(company.logoKey, IMAGE_TYPE.companyLogo),
        backgroundColor: company.primaryColor,
        textColor: company.contrastColor,
      }
    : undefined;
  const isInternalDocumentManagementEnabled = isCompanyFeatureEnabled(
    user?.company,
    'featureInternalDocumentManagement',
  );

  return (
    <MainNavigation.Container
      data-cy-main-navbar
      mainUrl={routes.root()}
      collapseButtonLabel={t('mainNavigation.collapse')}
      expandButtonLabel={t('mainNavigation.expand')}
      customLogoUrl={customization?.logoUrl || undefined}
      customLogoSrcSet={customization?.logoSrcSet || undefined}
      customBackgroundColor={customization?.backgroundColor || undefined}
      customTextColor={customization?.textColor || undefined}
    >
      {companyType === COMPANY_TYPE.principal && (
        <>
          <MainNavigation.Item
            url={routes.companyOnboarding()}
            iconType="rocket-launch"
            label={t('companyOnboarding:gettingStarted')}
            onClick={() => trackEvent('sidebarOnboarding')}
          />
          <MainNavigation.Item
            url={routes.projectsRoot()}
            iconType="archive"
            label={t('projects:projects')}
            onClick={() => trackEvent('sidebarProjects')}
          />
          <MainNavigation.Item
            url={routes.bidPackagesRoot()}
            iconType="folder-opened"
            label={t('bidPackages:title')}
            onClick={() => trackEvent('sidebarBidPackages')}
          />
          <MainNavigation.Item
            url={routes.bids()}
            iconType="file-text"
            label={t('bids:bids')}
            onClick={() => trackEvent('sidebarBids')}
          />
          <MainNavigation.Divider />
          {(isCompanyFeatureEnabled(company, 'featureCosunoNetwork') || canViewSubcontractors) && (
            <>
              <MainNavigation.Item
                url={routes.subcontractors()}
                iconType="people"
                label={t('subcontractors:subcontractors')}
                onClick={() => trackEvent('sidebarSubcontractors')}
              />
              <MainNavigation.Divider />
            </>
          )}
          {isCompanyFeatureEnabled(user.company, 'featureTaskManagement') && (
            <MainNavigation.Item
              url={routes.tasksRoot()}
              iconType="task"
              label={t('taskManagement:tasks')}
              onClick={() => trackEvent('sidebarTasks', { userType: user.type })}
            />
          )}
          {isCompanyFeatureEnabled(user.company, 'featureAddendums') && (
            <MainNavigation.Item
              url={routes.addendums()}
              iconType="file-plus"
              label={t('addendums:label')}
              onClick={() => trackEvent('sidebarAddenda')}
            />
          )}
          {isCompanyFeatureEnabled(user.company, 'featureInvoices') && (
            <MainNavigation.Item
              url={routes.invoices()}
              iconType="file-euro"
              label={t('billing:label')}
              onClick={() => trackEvent('sidebarBilling')}
            />
          )}
          {(isCompanyFeatureEnabled(user.company, 'featureAddendums') ||
            isCompanyFeatureEnabled(user.company, 'featureInvoices')) && <MainNavigation.Divider />}
          <MainNavigation.Item
            url={routes.internalDocuments()}
            iconType="hard-drive"
            label={t('pageTitles:internalDocuments')}
            onClick={() =>
              trackEvent('sidebarDocuments', {
                internalDocumentsEnabled: isInternalDocumentManagementEnabled,
              })
            }
          />
          {experimentFlags.experimentGoldPackageOnePager === 'Enabled' && (
            <MainNavigation.Item
              url={routes.goldPackageOverview()}
              label={t('goldPackageOverview:title')}
              renderIcon={() => <GoldIcon size={20} />}
              onClick={() => trackEvent('upsellGold')}
            />
          )}
          <MainNavigation.Item
            externalUrl={principalUserHelpUrl(language)}
            iconType="help"
            label={t('help')}
            onClick={() => trackEvent('sidebarHelpCenter', { userType: user.type })}
          />
        </>
      )}
      {companyType === COMPANY_TYPE.agent && (
        <>
          <MainNavigation.Item
            url={routes.agentCompanyOnboarding()}
            iconType="rocket-launch"
            label={t('companyOnboarding:gettingStarted')}
            onClick={() => trackEvent('sidebarOnboarding')}
          />
          <MainNavigation.Item
            url={routes.agentBidRequests()}
            iconType="archive"
            label={t('bidInvites:requests.bidRequests')}
            onClick={() => trackEvent('sidebarBidRequests')}
          />
          <MainNavigation.Item
            url={routes.agentAwardedBids()}
            iconType="signature"
            label={t('agentAwardedBids:title')}
            onClick={() => trackEvent('sidebarAwardedBids')}
          />
          <MainNavigation.Item
            url={routes.agentReverseMarketplace()}
            iconType="marketplace"
            label={t('pageTitles:marketplace')}
            onClick={() => trackEvent('sidebarReverseMarketplace')}
          />
          <MainNavigation.Item
            url={routes.agentCertificates()}
            iconType="file-text"
            label={t('common:certificates')}
            onClick={() => trackEvent('sidebarCertificates')}
          />
          {isCompanyFeatureEnabled(user.company, 'featureTaskManagement') && (
            <MainNavigation.Item
              url={routes.tasksRoot()}
              iconType="task"
              label={t('taskManagement:tasks')}
              onClick={() => trackEvent('sidebarTasks', { userType: user.type })}
            />
          )}
          {user.type === USER_TYPE.administrator && (
            <MainNavigation.Item
              url={routes.company()}
              iconType="settings-filled"
              label={t('pageTitles:company')}
              onClick={() => trackEvent('sidebarMyCompany')}
            />
          )}
          <MainNavigation.Item
            url={routes.premium()}
            renderIcon={(size: number) => (
              <span>
                <PremiumIcon variant="light" size={size} />
              </span>
            )}
            label={t('pageTitles:cosunoPremium')}
            onClick={() => trackEvent('sidebarPremium')}
          />
          <MainNavigation.Divider />
          <MainNavigation.Item
            externalUrl={agentUserHelpUrl(language)}
            iconType="help"
            label={t('help')}
            onClick={() => trackEvent('sidebarHelpCenter', { userType: user.type })}
          />
        </>
      )}
    </MainNavigation.Container>
  );
};

export default Navbar;
