import { authFor, useAuth } from '@cosuno/authorization-frontend';
import { PageLoader } from '@cosuno/cosuno-ui';
import React, { type PropsWithChildren } from 'react';

import Navbar from '~/shared/components/Navbar';
import { scrollContainerId } from '~/shared/constants';
import useUserAuthState from '~/shared/hooks/useUserAuthState';

import { Main, MainContentWrapper, PageLoaderOverlay } from './Styles';

interface AuthRouteContentProps {
  withLayout: boolean;
}

const AuthRouteContent: React.FC<PropsWithChildren<AuthRouteContentProps>> = ({
  withLayout,
  children,
}) => {
  const { companyType, currentUser } = useUserAuthState();

  const {
    results: { canViewSubcontractors },
    loading: loadingAuth,
  } = useAuth({
    canViewSubcontractors:
      currentUser &&
      authFor('Company').canI('view__subcontractors').where({ id: currentUser.company.id }),
  });

  return (
    <>
      <Main>
        {withLayout && companyType && (
          <Navbar companyType={companyType} canViewSubcontractors={canViewSubcontractors} />
        )}
        <MainContentWrapper id={scrollContainerId}>{children}</MainContentWrapper>
      </Main>
      {currentUser && loadingAuth && (
        <PageLoaderOverlay>
          <PageLoader />
        </PageLoaderOverlay>
      )}
    </>
  );
};

export default AuthRouteContent;
