/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-imports
import { useDebouncedCallback as useDebouncedCallbackHook } from 'use-debounce';

import type { Options } from './types';
import { getArguments } from './utils';

type ReturnValue<T extends (...args: any) => ReturnType<T>> = ReturnType<
  typeof useDebouncedCallbackHook<T>
>;

export function useDebouncedCallback<T extends (...args: any) => ReturnType<T>>(
  callback: T,
  delay?: number,
): ReturnValue<T>;
export function useDebouncedCallback<T extends (...args: any) => ReturnType<T>>(
  callback: T,
  options?: Options,
): ReturnValue<T>;
export function useDebouncedCallback<T extends (...args: any) => ReturnType<T>>(
  callback: T,
  delayOrOptions?: number | Options,
): ReturnValue<T> {
  return useDebouncedCallbackHook(callback, ...getArguments(delayOrOptions));
}
