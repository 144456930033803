import { useContext } from 'react';
import { assert } from 'ts-essentials';

import { NavigationConfirmationEnvironmentContext } from './useNavigationConfirmationEnvironment';
import {
  type FormInput,
  NavigationConfirmationManagerContext,
} from './useNavigationConfirmationManager';

export const useNavigationConfirmationFormMethods = () => {
  const environment = useContext(NavigationConfirmationEnvironmentContext);
  const manager = useContext(NavigationConfirmationManagerContext);

  assert(
    environment,
    '`useNavigationConfirmationFormMethods` can only be used inside of a `NavigationConfirmationEnvironmentContext`.',
  );

  const { subscribeToForm, environmentId } = environment;

  const markFormAsDirty = (formId: string, input: FormInput) => {
    manager.markFormAsDirty(formId, input);
    subscribeToForm(formId);
  };

  const markFormAsClean = (formId: string) => {
    manager.markFormAsClean(formId);
  };

  const markAllFormsAsClean = () => {
    manager
      .getAllFormsInEnvironment(environmentId)
      .forEach((form) => manager.markFormAsClean(form.id));
  };

  return {
    markFormAsDirty,
    markFormAsClean,
    markAllFormsAsClean,
  };
};
