import { BUTTON_VARIANT, type ButtonProps } from '@cosuno/cosuno-ui';
import type { FormikProps } from 'formik';
import React, { type ReactNode } from 'react';

import Form, { type FormOptions } from '~/shared/components/Form';
import Modal from '~/shared/components/Modal';
import { Dialog } from '~/shared/components/ModalStyles';
import { MODAL_VARIANTS } from '~/shared/constants';
import useTranslation from '~/shared/hooks/useTranslation';
import { isEqual } from '~/shared/utils/javascript';

import { ConfirmationModalActionPerformedEvent } from '../ConfirmationModal/imperativeApi';
import type { ConfirmationModalStateData } from '../ConfirmationModal/useConfirmationModalState';
import { ModalContent, Title } from './Styles';

export interface InputModalProps<FormValues, Params> extends ConfirmationModalStateData<Params> {
  name?: string;
  className?: string;
  title?: string | ((params: Params) => string);
  bodyText?: string;
  confirmText?: string;
  cancelText?: string;
  confirmButtonProps?: ButtonProps;
  hideCancel?: boolean;
  submitButtonVariant?: BUTTON_VARIANT;
  onConfirm?: () => void;
  onCancel?: () => void;
  renderContent: (formData: FormikProps<FormValues>, params: Params) => ReactNode;
  getFormOptions: (confirm: () => void, params: Params) => FormOptions<FormValues>;
  isWorking?: boolean;
  isConfirmDisabled?: boolean | ((formData: FormikProps<FormValues>) => boolean);
  isTopLevelModal?: boolean;
  maxWidth?: number;
  renderAdditionalButtons?: (formData: FormikProps<FormValues>, params: Params) => ReactNode;
  showResetButton?: boolean;
  /**
   * If set, clicking on reset button will replace the form values with the provided ones
   * using the formik's `resetForm` method. By default, formik resets to `initialValues`.
   */
  getResetValues?: (values: FormValues) => FormValues;
}

type ComponentReturnType<Props> = ReturnType<React.FC<Props>>;

const InputModal = <FormValues extends object, Params = {}>({
  name,
  className = '',
  title,
  bodyText,
  confirmText,
  confirmButtonProps,
  cancelText,
  hideCancel,
  submitButtonVariant = BUTTON_VARIANT.primary,
  onConfirm = () => {},
  onCancel = () => {},
  renderContent,
  getFormOptions,
  isWorking,
  isConfirmDisabled,
  maxWidth,
  renderAdditionalButtons,
  showResetButton = false,
  getResetValues,
  isTopLevelModal = false,
  ...modalStateData
}: InputModalProps<FormValues, Params>): ComponentReturnType<
  InputModalProps<FormValues, Params>
> => {
  const { t } = useTranslation();

  const handleCancel = () => {
    modalStateData.events.dispatchTypedEvent(
      'actionPerformed',
      new ConfirmationModalActionPerformedEvent('actionPerformed', {
        action: 'cancel',
        result: false,
        params: modalStateData.params,
      }),
    );
    modalStateData.closeModal();
    onCancel();
  };

  const confirm = () => {
    modalStateData.closeModal();
    onConfirm();
  };

  return (
    <Modal
      {...modalStateData}
      name={name}
      isTopLevelModal={isTopLevelModal}
      className={className}
      closeModal={handleCancel}
      data-cy-input-modal
      variant={MODAL_VARIANTS.center}
      maxWidth={maxWidth}
      renderContent={({ params }) => (
        <ModalContent>
          {title && <Title>{typeof title === 'function' ? title(params) : title}</Title>}
          {bodyText && <Dialog.Paragraph>{bodyText}</Dialog.Paragraph>}
          <Form<FormValues> {...getFormOptions(confirm, params)}>
            {(formData) => (
              <>
                <Form.Element>
                  {renderContent(formData, params)}
                  <Dialog.ControlWrapper>
                    {renderAdditionalButtons && renderAdditionalButtons(formData, params)}
                    {!hideCancel && (
                      <Dialog.ControlButton
                        hollow
                        onClick={handleCancel}
                        data-testid="cancel-button"
                      >
                        {cancelText || t('cancel')}
                      </Dialog.ControlButton>
                    )}
                    {showResetButton && (
                      <Dialog.ControlButton
                        variant="secondary"
                        onClick={() => {
                          formData.resetForm(
                            getResetValues && { values: getResetValues(formData.values) },
                          );
                        }}
                        disabled={
                          (getResetValues &&
                            isEqual(getResetValues(formData.values), formData.values)) ||
                          (!getResetValues && !formData.dirty)
                        }
                        data-testid="reset-button"
                      >
                        {t('reset')}
                      </Dialog.ControlButton>
                    )}
                    <Dialog.ControlButton
                      {...confirmButtonProps}
                      {...(isConfirmDisabled !== undefined && {
                        disabled:
                          typeof isConfirmDisabled === 'function'
                            ? isConfirmDisabled(formData)
                            : isConfirmDisabled,
                      })}
                      type="submit"
                      onClick={(e) => {
                        confirmButtonProps?.onClick?.(e);
                        modalStateData.events.dispatchTypedEvent(
                          'actionPerformed',
                          new ConfirmationModalActionPerformedEvent('actionPerformed', {
                            action: 'confirm',
                            result: formData.values,
                            params: modalStateData.params,
                          }),
                        );
                        return false;
                      }}
                      variant={submitButtonVariant}
                      working={isWorking}
                      data-cy-button-name="confirm"
                      data-testid="confirm-button"
                    >
                      {confirmText || t('confirm')}
                    </Dialog.ControlButton>
                  </Dialog.ControlWrapper>
                </Form.Element>
              </>
            )}
          </Form>
        </ModalContent>
      )}
    />
  );
};

export default InputModal;
