import { getClosestScrollableParent, Input, type InputProps } from '@cosuno/cosuno-ui';
import React, { useCallback, useEffect, useRef } from 'react';

import type { COUNTRY_CODE, STATE_CODE } from '~/__gql__/graphql';
import useGoogleMapsApi from '~/shared/hooks/useGoogleMapsApi';

import { parseAddressComponents, parseCountryAndStateCodes, selectFirstOnEnter } from './utils';

export interface Place {
  lat: number | null;
  long: number | null;
  countryCode: COUNTRY_CODE | null;
  stateCode: STATE_CODE | null;
  location: string | null;
}

export interface Address {
  street: string | null;
  postalCode: string | null;
  city: string | null;
  countryCode: COUNTRY_CODE | null;
}

interface LocationSearchBasicProps {
  onChange: (value: string) => void;
  onPlaceSelect?: (place: Place & Address) => void;
  hideIcon?: boolean;
}

export type LocationSearchProps = LocationSearchBasicProps &
  Omit<InputProps, keyof LocationSearchBasicProps>;

const boundsForGermany = {
  east: 15.0169958839,
  north: 54.983104153,
  south: 47.3024876979,
  west: 5.98865807458,
};

const LocationSearch: React.FC<LocationSearchProps> = ({
  onChange,
  onPlaceSelect,
  hideIcon,
  ...inputProps
}) => {
  const input = useRef<HTMLInputElement | null>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete>();

  const onChangeRef = useRef(onChange);
  const onPlaceSelectRef = useRef(onPlaceSelect);

  const google = useGoogleMapsApi();

  // save some re-renders which might mess up our google integration by heavily
  // subscribe/unsubscribe events etc by using refs
  onChangeRef.current = onChange;
  onPlaceSelectRef.current = onPlaceSelect;

  const updateLocation = useCallback(() => {
    if (!autocomplete.current) {
      return;
    }

    const place = autocomplete.current.getPlace();
    const isPlaceValid = place.formatted_address;

    onChangeRef.current(place.formatted_address || '');

    if (isPlaceValid && onPlaceSelectRef.current) {
      onPlaceSelectRef.current({
        lat: place.geometry?.location?.lat() ?? null,
        long: place.geometry?.location?.lng() ?? null,
        ...parseCountryAndStateCodes(place),
        location: place.formatted_address || null,
        ...parseAddressComponents(place.address_components),
      });
    }
  }, []);

  const mapsApi = google?.maps;
  useEffect(() => {
    const autocompleteInput = input.current;
    if (!autocompleteInput || !mapsApi) {
      return;
    }

    if (!autocomplete.current) {
      autocomplete.current = new mapsApi.places.Autocomplete(autocompleteInput, {
        types: ['geocode'],
        bounds: boundsForGermany,
      });
    }

    const placeListener = autocomplete.current.addListener('place_changed', updateLocation);

    return () => {
      if (autocompleteInput) {
        mapsApi?.event?.clearInstanceListeners(autocompleteInput);
      }
      autocomplete.current = undefined;
      placeListener.remove();
    };
  }, [mapsApi, updateLocation]);

  useEffect(() => {
    if (!input.current || !mapsApi) {
      return;
    }

    const updatePopupPosition = () => {
      if (autocomplete.current) {
        mapsApi?.event?.trigger(autocomplete.current, 'resize');
      }
    };

    const scrollableWrapper = getClosestScrollableParent(input.current);
    scrollableWrapper?.addEventListener('scroll', updatePopupPosition);

    return () => {
      scrollableWrapper?.removeEventListener('scroll', updatePopupPosition);
    };
  }, [mapsApi]);

  const refCallback = useCallback((el: HTMLInputElement | null) => {
    if (el) {
      selectFirstOnEnter(el);
    }
    input.current = el;
  }, []);

  /*
   *  Google Maps' autocomplete adds `autocomplete="off"` to this input,
   *  but Google Chrome still tries to _autofill_ the address.
   *  So we overwrite Google Maps and disable autofill for Chrome.
   */
  const handleFocus = useCallback((e: React.FocusEvent) => {
    e.target.setAttribute('autocomplete', 'chrome-off');
  }, []);

  return (
    <Input
      icon={hideIcon ? undefined : 'search'}
      {...inputProps}
      onChange={onChange}
      onFocus={handleFocus}
      ref={refCallback}
    />
  );
};

export default LocationSearch;
