import { graphql } from '~/__gql__';

export const mutationMarkAllNotificationsAsRead = graphql(/* GraphQL */ `
  mutation MarkAllNotificationsAsRead {
    markNotificationsAsRead
  }
`);

export const mutationMarkNotificationAsRead = graphql(/* GraphQL */ `
  mutation MarkNotificationAsRead($id: ID!) {
    markNotificationAsRead(id: $id)
  }
`);
