import { camelCase as unsafeCamelCase } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import type { CamelCase } from 'ts-essentials';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';

import type { VersionedUUID } from '~/shared/types/string';

export const uncapitalize = <T extends string>(input: T) =>
  `${input.charAt(0).toLowerCase()}${input.slice(1)}` as Uncapitalize<T>;

export const capitalize = <T extends string>(input: T) =>
  `${input.charAt(0).toUpperCase()}${input.slice(1)}` as Capitalize<T>;

/** A safe version of lodash's camel case which returns a strict camel cased literal type */
export function camelCase<Str extends string>(str: Str) {
  return unsafeCamelCase(str) as CamelCase<Str>;
}

export const getMaxLengthValidator = (maxLength: number) => (text: string) =>
  text.trim().length > maxLength;

function isUUID<TVersion extends number>(
  input: string,
  version: TVersion,
): input is VersionedUUID<TVersion> {
  return uuidValidate(input) && uuidVersion(input) === version;
}

export function isUUIDv4(input: string): input is VersionedUUID<4> {
  return isUUID(input, 4);
}

export const sanitizeEmailPreview = (text: string) =>
  sanitizeHtml(text, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['style', 'img']),
    // this suppresses the warning because of allowed `style` tag (which is used in email previews)
    allowVulnerableTags: true,
    // quite a number of attributes (like style, align, width, cellpadding etc.) exist in email
    // previews, so we allow all attributes for now
    allowedAttributes: false,
    allowedClasses: {
      '*': ['*'],
    },
    parseStyleAttributes: false,
  });

export const normalizeString = (str: string) =>
  str
    .toLowerCase()
    .replace(/\s+/g, ' ')
    // normalize special characters
    .replace(/('æ|ä)/g, 'ae')
    .replace(/(å)/g, 'aa')
    .replace(/(á|à|ã|â)/g, 'a')
    .replace(/(ç|č)/g, 'c')
    .replace(/(é|ê|è|ë|ē)/g, 'e')
    .replace(/(î|ï|í)/g, 'i')
    .replace(/(œ|ö)/g, 'oe')
    .replace(/(ó|õ|ô)/g, 'o')
    .replace(/(ś|š)/g, 's')
    .replace(/(ü)/g, 'ue')
    .replace(/(ù|ú|ŭ)/g, 'u')
    .replace(/(ß)/g, 'ss')
    .replace(/(ё)/g, 'е');
