import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { generateRoutes } from '~/shared/constants/routes';
import useCurrentUser from '~/shared/hooks/useCurrentUser';

export * from '~/shared/constants/routeTabs';

const useRoutes = (props?: { withoutWorkspace: boolean }) => {
  const { workspaceId } = useParams<{ workspaceId?: string }>();
  const user = useCurrentUser();

  return useMemo(
    () =>
      generateRoutes({
        workspaceId: workspaceId || user?.workspaces.find(({ isCurrent }) => isCurrent)?.id,
        withoutWorkspace: props?.withoutWorkspace,
      }),
    [workspaceId, user, props?.withoutWorkspace],
  );
};

export default useRoutes;
