import { COLOR, Icon } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font } from '~/shared/utils/styles';

export const Row = styled.ul`
  display: flex;
  gap: 20px;
  justify-content: center;
  ${font.size(14)}
  margin: 30px 0;
`;

export const Col = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BrowserIcon = styled.img`
  margin-bottom: 20px;
  width: 65px;
`;

export const ExternalLinkIcon = styled(Icon).attrs({
  type: 'external-link',
  size: 10,
})`
  margin-left: 7px;
  color: ${COLOR.brand};
`;
