import * as Sentry from '@sentry/browser';

import { typeSafeFromEntries, typeSafeObjectEntries } from '~/shared/utils/typescript';

interface CosunoIntercomActions {
  open: () => void;
  openNewMessage: () => void;
}

type IntercomAction = keyof CosunoIntercomActions;

type CosunoIntercom = CosunoIntercomActions & {
  workflowTag: Record<string, { [k: string]: boolean }>;
};

class IntercomUnavailable extends Error {
  constructor(action: IntercomAction) {
    super(`[Intercom] Integration unavailable for action: ${action}`);
  }
}

const withIntercom =
  (action: IntercomAction, callback: (intercom: (typeof window)['Intercom']) => void) => () => {
    if (!window.Intercom) {
      Sentry.captureException(new IntercomUnavailable(action));
      return;
    }

    callback(window.Intercom);
  };

const open = withIntercom('open', (intercom) => {
  intercom('show');
});

const openNewMessage = withIntercom('openNewMessage', (intercom) => {
  intercom('showNewMessage');
});

// Chat will be opened by Intercom in a proper workflow using CSS selector
// https://www.intercom.com/help/en/articles/2785347-when-a-customer-clicks-on-a-website-element
const workflowTags = {
  agentPremiumContact: 'data-intercom-subcontractor-premium-contact',
} as const;

export const intercom = {
  open,
  openNewMessage,
  workflowTag: typeSafeFromEntries(
    // this will create the entries with same key as the key in workflowTags and the value
    // as an object with the tag as the key so that it's spreadable into JSX attributes
    typeSafeObjectEntries(workflowTags).map(([key, value]) => [key, { [value]: true }]),
  ),
} as const satisfies Readonly<CosunoIntercom>;
