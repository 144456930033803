import type { i18n as i18nType } from 'i18next';
import { uniq } from 'lodash';

import { COMPANY_TYPE, type CompanyConfigFragment, LANGUAGE } from '~/__gql__/graphql';
import { env } from '~/shared/utils/env';

export const FALLBACK_LANGUAGE = LANGUAGE.en;

const updateBeamerLanguage = (language: LANGUAGE) => {
  if (window.Beamer) {
    window.Beamer.update({ language });
    window.Beamer.destroy();
    window.Beamer.init();
  }
};

// <-- These should be kept in sync with their counterparts in admin/src/utils/languages and api/src/constants/index
const BASE_LANGUAGES = [LANGUAGE.de, LANGUAGE.en, LANGUAGE.fr];
// -->

export const detectLanguage = (): LANGUAGE => {
  if (env.REACT_APP_CYPRESS) {
    // ignore any browser language in cypress to ensure consistent behavior
    return env.REACT_APP_DEFAULT_LANG;
  }
  // We only use the first 2 characters because the format can be `en-GB` or `de-DE`.
  const browserLanguage = navigator.language.slice(0, 2);

  const detectedLanguage = Object.values(LANGUAGE).find((language) => language === browserLanguage);
  if (detectedLanguage) {
    return detectedLanguage;
  }

  return env.REACT_APP_DEFAULT_LANG;
};

export const setCurrentLanguage = async (language: LANGUAGE, i18n: i18nType) => {
  updateBeamerLanguage(language);
  await i18n.changeLanguage(language);
};

export const excludeLanguagesHiddenFromAgentCompanies = (
  languages: LANGUAGE[],
  company: CompanyConfigFragment,
): LANGUAGE[] => {
  const EXCLUDED_LANGUAGES = [LANGUAGE.gr, LANGUAGE.hu, LANGUAGE.et, LANGUAGE.lv, LANGUAGE.lt];

  if (company.type === COMPANY_TYPE.agent) {
    return languages.filter((language) => !EXCLUDED_LANGUAGES.includes(language));
  }

  return languages;
};

export const getAvailableLanguages = (company: CompanyConfigFragment): LANGUAGE[] =>
  company.type === COMPANY_TYPE.agent
    ? excludeLanguagesHiddenFromAgentCompanies(Object.values(LANGUAGE), company)
    : uniq([...BASE_LANGUAGES, ...company.config.additionalLanguages]);
