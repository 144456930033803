export class TermsForAcceptanceNotFoundError extends Error {
  constructor() {
    super('Terms for acceptance not found');
  }
}

export class MissingRedirectAfterTermsAcceptanceError extends Error {
  constructor() {
    super('Missing redirect after terms acceptance error');
  }
}
