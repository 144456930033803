import { Button, MiniTooltip } from '@cosuno/cosuno-ui';
import { useChainedCommands, useCurrentSelection } from '@remirror/react';
import React, { useCallback } from 'react';

import { useConfirmationModalState } from '~/shared/components/ConfirmationModal/useConfirmationModalState';
import useTranslation from '~/shared/hooks/useTranslation';

import { StyledMenuButton } from '../Styles';
import { LinkModal } from './LinkModal';
import { LinkPopover } from './LinkPopover';
import { StyledButtonGroup } from './Styles';

export const LinkEditor: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const { t } = useTranslation('textEditor');

  const chain = useChainedCommands();
  const selection = useCurrentSelection();

  const modalState = useConfirmationModalState();

  const removeLink = useCallback(() => chain.selectLink().removeLink().focus().run(), [chain]);

  const editLink = useCallback(() => {
    if (selection.empty) {
      chain.selectLink().run();
    }

    modalState.openModal({});
  }, [chain, selection, modalState]);

  return (
    <>
      <MiniTooltip<HTMLButtonElement>
        isTriggerElementClickable={!disabled}
        bodyText={disabled ? null : t('tooltips.link')}
      >
        {({ ref: forwardRef, getReferenceProps }) => (
          <StyledMenuButton
            size="small"
            aria-pressed={false}
            disabled={disabled}
            onlyIcon={{ type: 'link' }}
            variant="secondary"
            forwardRef={forwardRef}
            {...getReferenceProps({ onClick: () => editLink() })}
          />
        )}
      </MiniTooltip>

      <LinkModal {...modalState} disabled={disabled} />

      <LinkPopover isOpen={!modalState.isOpen}>
        <StyledButtonGroup>
          <MiniTooltip<HTMLDivElement>
            isTriggerElementClickable={!disabled}
            bodyText={disabled ? null : t('common:edit')}
          >
            {({ ref, getReferenceProps }) => (
              <div ref={ref} {...getReferenceProps()}>
                <Button
                  size="small"
                  variant="secondary"
                  onlyIcon={{ type: 'edit' }}
                  onClick={editLink}
                  disabled={disabled}
                />
              </div>
            )}
          </MiniTooltip>
          <MiniTooltip<HTMLDivElement>
            isTriggerElementClickable={!disabled}
            bodyText={disabled ? null : t('common:delete')}
          >
            {({ ref, getReferenceProps }) => (
              <div ref={ref} {...getReferenceProps()}>
                <Button
                  size="small"
                  variant="secondary"
                  onlyIcon={{ type: 'delete' }}
                  onClick={removeLink}
                  disabled={disabled}
                />
              </div>
            )}
          </MiniTooltip>
        </StyledButtonGroup>
      </LinkPopover>
    </>
  );
};
