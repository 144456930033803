import { createContext, useMemo, useState } from 'react';

interface NavigationConfirmationHandlers {
  cancel: () => void;
  discard: () => void;
  save: () => void | Promise<void>;
}

export const useNavigationConfirmationPrompt = () => {
  const [currentHandlers, setCurrentHandlers] = useState<NavigationConfirmationHandlers>();

  return useMemo(
    () => ({
      cancel: () => {
        currentHandlers?.cancel();
        setCurrentHandlers(undefined);
      },
      discard: () => {
        currentHandlers?.discard();
        setCurrentHandlers(undefined);
      },
      save: async () => {
        await currentHandlers?.save();
        setCurrentHandlers(undefined);
      },
      isDisplayed: Boolean(currentHandlers),
      confirmNavigation: (handlers: NavigationConfirmationHandlers) => {
        setCurrentHandlers(handlers);
      },
    }),
    [currentHandlers],
  );
};

export const NavigationConfirmationPromptContext = createContext<{
  confirmNavigation: (handlers: NavigationConfirmationHandlers) => void;
}>({
  confirmNavigation: () => {},
});
