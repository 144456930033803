import {
  ApolloClient,
  ApolloLink,
  type FieldPolicy,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { type ErrorResponse, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import type { GraphQLError } from 'graphql';
import i18n from 'i18next';

import { getExponentialDelay } from '~/shared/utils/apollo';
import { env } from '~/shared/utils/env';
import { mergeWith, omit } from '~/shared/utils/javascript';
import toast from '~/shared/utils/toast';

import fragmentTypes from './__gql__/fragmentTypes.json';
import { isNotUndefined } from './shared/utils/typescript';

export type ApolloCache = (typeof apolloClient)['cache'];

export interface EmailError {
  email: string;
  messageKey?: string;
  translationKey?: string;
}

/**
 * These are operation names for errors from queries that are periodically made.
 * We ignore them because otherwise, if the user goes offline for an extended
 * period of time, a large number of these would accumulate.
 */
const IGNORED_ERRORS = ['CombinedNotifications', 'EditRequestBoQ'];

const RETRY_OPERATIONS = ['EditRequestBoQ', 'UpdateBillOfQuantitiesProposal'];

const errorLink = onError(({ operation, graphQLErrors = [], networkError }: ErrorResponse) => {
  graphQLErrors.forEach(formatError);

  if (IGNORED_ERRORS.includes(operation.operationName)) {
    return;
  }

  if (networkError) {
    toast.error(networkError);
  }
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, operation) =>
      Boolean(error) && RETRY_OPERATIONS.includes(operation.operationName),
  },
  delay: (count, operation) => {
    switch (operation.operationName) {
      case 'UpdateBillOfQuantitiesProposal':
        return getExponentialDelay({
          initialDelay: 1200,
          factor: 0.5 + Math.random() / 2,
          count,
        });
      default:
        return getExponentialDelay({
          initialDelay: 200,
          factor: 0.5 + Math.random() / 2,
          count,
        });
    }
  },
});

const extractTranslationKey = (error: GraphQLError): string =>
  error.extensions?.translationKey as string;

const formatError = (error: GraphQLError) => {
  switch (error.extensions?.code) {
    case 'EMAILS_NOT_DELIVERED':
      formatEmailDeliveryError(error);
      break;
    case 'BAD_USER_INPUT':
      formatBadUserInputError(error);
      break;
    case 'PROPOSAL_BOQ_IS_OUTDATED':
      formatOutdatedBoQError(error);
      break;
    default:
      error.message = i18n.t(
        extractTranslationKey(error) || `apiErrors:${error.message}`,
        error.extensions,
      );
  }
};

const formatEmailDeliveryError = (error: GraphQLError) => {
  const msg = i18n.t('apiErrors:emailsNotDelivered');
  const emailErrors = (error.extensions?.failedEmails as EmailError[])
    .map((params: { email: string; messageKey?: string; translationKey?: string }) => {
      const { email, messageKey, translationKey } = params;

      if (translationKey) {
        return translationKey !== 'apiErrors:emailsNotDelivered'
          ? i18n.t(translationKey, { email })
          : i18n.t('apiErrors:emailFailed', { email });
      }

      return messageKey && messageKey !== 'emailsNotDelivered'
        ? i18n.t(`apiErrors:${messageKey}`, { email })
        : i18n.t('apiErrors:emailFailed', { email });
    })
    .join('\n');
  error.message = `${msg}:\n\n${String(emailErrors)}`;
};

const formatBadUserInputError = (error: GraphQLError) => {
  const fields = error.extensions?.fields as Record<
    string,
    {
      name: string;
      args: string;
    }[]
  >;
  error.message = i18n.t('apiErrors:thereWereValidationErrors');

  if (!fields) return;

  Object.keys(fields).forEach((fieldName) => {
    (error.extensions.fields as Record<string, string[]>)[fieldName] = fields[fieldName].map(
      (err) => i18n.t(`validation:${err.name}`, err.args),
    );
    error.message = `${error.message}\n\n${fieldName}\n${fields[fieldName].join('\n')}`;
  });
};

const formatOutdatedBoQError = (error: GraphQLError) => {
  const reason = error.extensions?.reason;

  if (reason === 'requestBoQUpdated') {
    error.message = i18n.t('apiErrors:proposalBoqIsOutdated');
  }

  if (reason === 'requestBoQDeleted') {
    error.message = i18n.t('apiErrors:parentBoQDeleted');
  }
};

const httpLink = new HttpLink({
  uri: `${env.REACT_APP_API_BASE}/internal/graphql`,
  // Include cookies in all relevant requests, even to other origins
  // (API and frontend are usually on different origins)
  credentials: 'include',
  headers: {
    'X-Cosuno-Client-Release': env.REACT_APP_RELEASE_HASH,
  },
});

/** Concatenates incoming arrays with cached arrays for paginated queries. */
const queryWithPaginationOptions: FieldPolicy = {
  // Group query results with same args but different offset or limit into one cache.
  keyArgs: (args) => {
    const argsWithoutListParams = omit(args, 'offset', 'limit');

    return JSON.stringify(argsWithoutListParams);
  },
  merge: (existing, incoming, { variables }) => {
    if (variables?.offset === undefined || variables?.limit === undefined) {
      return incoming;
    }

    return mergeWith({}, existing, incoming, (existingArray, incomingArray) => {
      if (Array.isArray(incomingArray) && Array.isArray(existingArray)) {
        return [...existingArray.slice(0, variables.offset), ...incomingArray];
      }
      return undefined;
    });
  },
};

const cache = new InMemoryCache({
  possibleTypes: fragmentTypes.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        addendums: queryWithPaginationOptions,
        adminBidPackages: queryWithPaginationOptions,
        adminBidPackageBillOfQuantitiesRevisions: queryWithPaginationOptions,
        adminBidRequests: queryWithPaginationOptions,
        adminCompanies: queryWithPaginationOptions,
        adminSignups: queryWithPaginationOptions,
        adminUsers: queryWithPaginationOptions,
        awardedBidsForAgent: queryWithPaginationOptions,
        bids: queryWithPaginationOptions,
        bidRequests: queryWithPaginationOptions,
        bidRequestsForAgent: queryWithPaginationOptions,
        bidPackages: queryWithPaginationOptions,
        bidPackageListForMarketplace: queryWithPaginationOptions,
        boqTemplates: queryWithPaginationOptions,
        certificateRequestsForAgent: queryWithPaginationOptions,
        certificateTypes: queryWithPaginationOptions,
        invoiceNotes: queryWithPaginationOptions,
        invoices: queryWithPaginationOptions,
        messageThreads: queryWithPaginationOptions,
        notifications: queryWithPaginationOptions,
        offices: queryWithPaginationOptions,
        pricePositions: queryWithPaginationOptions,
        project: queryWithPaginationOptions,
        projects: queryWithPaginationOptions,
        subcontractors: queryWithPaginationOptions,
        taskNotes: queryWithPaginationOptions,
        tasks: queryWithPaginationOptions,
        users: queryWithPaginationOptions,
        workCategories: queryWithPaginationOptions,
      },
    },
    UpdateBoQFieldResponse: {
      fields: {
        positions: {
          merge: false,
        },
        groups: {
          merge: false,
        },
        subDescriptions: {
          merge: false,
        },
        notes: {
          merge: false,
        },
      },
    },
    WorkCategoryForSubcontractor: {
      keyFields: (workCategory) => {
        const isPrimary = workCategory.isPrimary as boolean | undefined;
        return ['id', isPrimary ? 'isPrimary' : undefined].filter(isNotUndefined);
      },
    },
    BidRequestContact: {
      keyFields: ['id', 'bidInvite'],
    },
    CustomFieldWithValue: {
      // This type is the same as CustomField, but with `value` added to it,
      // and `value` is unique to an entity (e.g. to a project). By default, apollo caches records by id,
      // which leads to the bug where a custom field has the same `value` cached
      // regardless of the entity it belongs to
      keyFields: ['id', 'entityId'],
    },
    CertificateTypeReport: {
      // Certificate type report can have different content of `certificates` field based on
      // which subcontractor certificate type it's combined with.
      keyFields: ['id', 'subcontractorTypeId'],
    },
  },
});

const link = ApolloLink.from([retryLink, errorLink, httpLink]);

const apolloClient = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    query: { fetchPolicy: 'network-only' },
    watchQuery: { fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' },
  },
});

export default apolloClient;
