import { Button, ButtonGroup, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

/**
 * Standard modal content styling.
 */
export const Content = styled.div`
  padding: 32px 40px 40px;
`;

/**
 * Standard modal title styling.
 */
export const Title = styled.div`
  padding: 0 20px 15px 0;

  ${textStyles.titleMedium700};
  color: ${COLOR.textPrimary};
`;

/**
 * Standard modal body text styling.
 */
export const Paragraph = styled.p`
  margin-bottom: 20px;
  white-space: pre-wrap;

  ${textStyles.bodyLarge450};
  color: ${COLOR.textSecondary};
`;

/**
 * Standard modal bottom controls wrapper.
 */
export const ControlWrapper = styled(ButtonGroup)`
  justify-content: flex-end;
  margin-top: 25px;
  flex-wrap: wrap;
`;

interface ModalControlButtonProps {
  $leftAligned?: boolean;
}

/**
 * Standard modal action button styling.
 */
export const ControlButton = styled(Button)<ModalControlButtonProps>`
  ${(props) =>
    props.$leftAligned &&
    css`
      margin-right: auto;
    `}
`;
