import { COLOR } from '@cosuno/cosuno-ui';
import { createGlobalStyle } from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

const SCROLLBAR_WIDTH = 11;
const BEAMER_BANNER_HEIGHT = 55;

export default createGlobalStyle`
  html, body, #root {
    height: 100vh;
    min-height: 100vh;
    width: 100%;
  }

  html {
    touch-action: manipulation;
  }

  body {
    overflow: auto;
    color: ${COLOR.textPrimary};
    -webkit-tap-highlight-color: transparent;
    line-height: 1.2;
    ${font.size(16)}
    ${font.family}
    ${font.regular}
    scrollbar-color: ${COLOR.backgroundMedium} white;

    &.beamerAnnouncementBarTopActive {
      transition: none !important;
      padding-top: 0 !important;
    }
  }

  #root {
    display: flex;
    flex-direction: column;

    .beamerAnnouncementBarTopActive & {
      padding-top: ${BEAMER_BANNER_HEIGHT}px;
    }
  }

  @media print {
    #root {
      /* Makes Firefox multi-page printing possible */
      display: block;
    }

    html, body, #root {
      /* auto height necessary to be able to print multiple pages.
       * Otherwise only viewport height is printed (results in a single page).
       */
      height: auto;
      min-height: auto;
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    ${font.regular}
  }

  *, *::after, *::before, input[type="search"] {
    box-sizing: border-box;
  }

  a, a:hover, a:visited, a:active {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, strong {
    ${font.bold}
  }

  button {
    background: none;
    border: none;
  }

  svg[data-icon] {
    circle, ellipse, path, rect {
      fill: currentColor;
    }
  }

  [role="button"], button, input, select, textarea {
    outline: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 1;
    }
  }
  [role="button"], button, input, textarea {
    appearance: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  select::-ms-expand {
    display: none;
  }
  select option {
    color: ${COLOR.textPrimary};
  }

  p {
    line-height: 1.6;
    a {
      ${mixins.link()}
    }
  }

  textarea {
    line-height: 1.6;
  }

  body, select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${mixins.placeholderColor(COLOR.textTertiary)}

  /**
   * To improve the UX, we want scrollbars to always be visible.
   * macOS auto hides scrollbars, but this can be prevented with customm styling.
   * The following styles imitate the native macOS scrollbars.
   */
  ::-webkit-scrollbar {
    height: ${SCROLLBAR_WIDTH}px;
    width: ${SCROLLBAR_WIDTH}px;
  }
  ::-webkit-scrollbar-track {
    background: white;
  }

  /**
   * Setting the border and background-clip results in a margin around the thumb.
   * The margin and padding properties are not supported.
   */
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${COLOR.borderLight};
    border-radius: ${SCROLLBAR_WIDTH / 2}px;
    border: 2px solid transparent;
  }

  *:hover::-webkit-scrollbar-thumb {
    background-color: ${COLOR.backgroundMedium};
  }

  /**
   * Remove all animations and transitions for people that prefer not to see them
   *
   * Durations are set to 0.01ms instead of 0s to not break animationend and
   * transitionend JavaScript events.
   */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
    }
  }
`;
