import { useEffect, useRef } from 'react';

export function useInterval(
  callback: () => Promise<void> | void,
  interval: number,
  isEnabled: boolean,
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    let timeout = setTimeout(() => void fn(), interval);

    async function fn() {
      await callbackRef.current?.();
      if (isEnabled) {
        timeout = setTimeout(() => void fn(), interval);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [interval, isEnabled]);
}
