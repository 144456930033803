import {
  type BID_INVITE_REMINDER_SCHEDULE,
  type BID_INVITE_STATUS,
  BID_PACKAGE_STATUS,
  type BID_REQUEST_DECLINED_REASON,
  type BID_REQUEST_STATUS,
  type BID_REQUEST_STATUS_FOR_AGENT,
  type BOQ_FILE_FORMAT,
  type BOQ_FORMAL_REVIEW_ERROR_REASON,
  type BOQ_POSITION_TEXT_PROVIDER,
  BOQ_VALIDATION_ERROR_REASON,
  BOQ_VALIDATION_WARNING_REASON,
  type CERTIFICATE_AVAILABILITY,
  CERTIFICATE_REQUEST_STATUS_FOR_AGENT,
  CERTIFICATE_TYPE_STATUS,
  type CONTACT_TITLE,
  type COST_CATALOG_TYPE,
  CUSTOM_COST_GROUP_CATALOG_STATUS,
  type DEPARTMENT_TYPE,
  EDITING_SESSION_TYPE,
  type EXTERNAL_CREDENTIAL_TYPE,
  type IMPORTABLE_BOQ_FIELD,
  type LANGUAGE,
  type PROJECT_CATEGORY,
  PROJECT_PHASE,
  type SUBCONTRACTOR_IMPORT_REQUEST_STATUS,
  type TASK_STATUS,
  USER_STATUS,
  type USER_TYPE,
} from '~/__gql__/graphql';
/** @todo Move this enum into __gql__/globalTypes */
import { ADDITIONAL_TEXT_POSITION } from '~/packages/BoQ/components/BoQEditor/types';
import {
  type BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL,
  type BOQ_GROUP_TYPE,
  type BOQ_TEXT_EDITOR_COLOR,
  type BOQ_TEXT_EDITOR_HEADING,
  type EXECUTION_DESCRIPTION_SELECT_ERROR,
  type IMPORTABLE_TEMPLATE_BOQ_FIELD,
  POSITION_TYPE_ERROR,
  type REFERRAL_DESCRIPTION_SELECT_ERROR,
  SURCHARGE_TYPE,
  type SURCHARGE_TYPE_SELECT_ERROR,
} from '~/packages/BoQ/constants';
import type { DOWNLOADABLE_BOQ_TYPE, ImportableBoQField } from '~/packages/BoQ/types';
import type { ToggleablePositionType } from '~/packages/BoQ/types/positionTypes';
import type { TEXT_EDITOR_COLOR } from '~/shared/components/TextEditor';
import type { TFunctionParamsArgument, TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import type { CompatibleCostStructureType } from '~/shared/types/costStructures';
import type { TranslationKey } from '~/shared/types/translationKeys';
import { uncapitalize } from '~/shared/utils/string';

import { belongsToEnum } from './typescript';

export const createTranslationMap =
  <T extends string, U extends TranslationKey<'common'>>(callback: (value: T) => U) =>
  (t: TypeSafeTFunction, value: T, ...params: TFunctionParamsArgument<'common', U>) =>
    // Without the unnecessary type assertion, Typescript thinks there's a
    // problem. This might be a bug (?).
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    t(callback(value), ...(params as TFunctionParamsArgument<'common', U>));

export const translateUserTypesSingular = createTranslationMap(
  (type: Exclude<USER_TYPE, USER_TYPE.custom>) => `userType.singular.${type}`,
);

export const translateUserTypeDescriptions = createTranslationMap(
  (type: Exclude<USER_TYPE, USER_TYPE.custom>) => `company:userTypeDescription.${type}`,
);

export const translateUserStatus = createTranslationMap(
  (status: USER_STATUS) =>
    `userStatus.${
      status === USER_STATUS.adminApprovalPending ? USER_STATUS.approvalPending : status
    }`,
);

export const translateBidInviteStatus = createTranslationMap(
  (status: BID_INVITE_STATUS) => `bidInviteStatus.${status}`,
);

export const translateBidRequestStatusForPrincipal = createTranslationMap(
  (status: BID_REQUEST_STATUS) => `request.${status}`,
);

export const translateBidRequestStatusForAgent = createTranslationMap(
  (status: BID_REQUEST_STATUS_FOR_AGENT) => `request.${status}`,
);

export const translateBidPackageStatus = createTranslationMap(
  (status: BID_PACKAGE_STATUS) => `bidPackage.${status}`,
);

export const translateBidRequestDeclinedReason = createTranslationMap(
  (reason: BID_REQUEST_DECLINED_REASON) => `request.declinedReason.${reason}`,
);

export const translateLanguage = createTranslationMap(
  (language: LANGUAGE) => `languages:${language}`,
);

export const translateBoQValidationWarningReason = createTranslationMap(
  (reason: BOQ_VALIDATION_WARNING_REASON) => `BoQ:boqValidationMessagesModal.warnings.${reason}`,
);

export const translateBoQValidationErrorReason = createTranslationMap(
  (reason: BOQ_VALIDATION_ERROR_REASON) => `BoQ:boqValidationMessagesModal.errors.${reason}`,
);

export const translateBoQValidationReason = (t: TypeSafeTFunction, reason: string) => {
  if (belongsToEnum(reason, BOQ_VALIDATION_ERROR_REASON)) {
    return translateBoQValidationErrorReason(t, reason);
  }

  if (belongsToEnum(reason, BOQ_VALIDATION_WARNING_REASON)) {
    return translateBoQValidationWarningReason(t, reason);
  }

  return t('common:unknownError');
};

export const translateBoqFormalReviewErrorReason = createTranslationMap(
  (reason: BOQ_FORMAL_REVIEW_ERROR_REASON) => `BoQ:boqFormalReviewMessagesModal.errors.${reason}`,
);

export const translateBidInviteReminderSchedule = createTranslationMap(
  (schedule: BID_INVITE_REMINDER_SCHEDULE) => `reminderSchedule.${schedule}`,
);

export const translatePositionTypeTag = (suffix: 'short' | 'long') =>
  createTranslationMap(
    (positionType: ToggleablePositionType) =>
      `BoQ:positionTypes.${uncapitalize(positionType)}.${suffix}`,
  );

export const translateGroupTypeTag = createTranslationMap(
  (groupType: BOQ_GROUP_TYPE) => `BoQ:groupTypes.${uncapitalize(groupType)}`,
);

export const translateImportableExcelBoQField = createTranslationMap(
  (field: Exclude<ImportableBoQField, IMPORTABLE_BOQ_FIELD.customCostCatalog>) =>
    `BoQ:uploadExcelBoQ.importableBoQFields.${field}`,
);

export const translateImportableBoQField = createTranslationMap(
  (field: Exclude<IMPORTABLE_TEMPLATE_BOQ_FIELD, 'costGroup'> | 'oenorm' | 'din276') =>
    `BoQ:importableFields.${field}`,
);

export const translateBoQLibraryTextProviderField = createTranslationMap(
  (
    field:
      | Exclude<IMPORTABLE_TEMPLATE_BOQ_FIELD, 'costGroup' | 'netTotal'>
      | 'oenorm'
      | 'din276'
      | 'stlbWorkCategory',
  ) => `BoQ:editor.textProviderModal.boqLibrary.fields.${field}`,
);

export const translateContactTitle = createTranslationMap(
  (title: CONTACT_TITLE) => `companyInfo.title.${title}`,
);

export const translateProjectPhase = createTranslationMap(
  (phase: PROJECT_PHASE) =>
    (
      ({
        [PROJECT_PHASE.allPhases]: 'common:project.allPhases',
        [PROJECT_PHASE.tendering]: 'common:project.tenderingPhase',
        [PROJECT_PHASE.estimation]: 'common:project.estimationPhase',
      }) as const
    )[phase],
);

export const translateCertificateRequestStatusForAgent = createTranslationMap(
  (status: CERTIFICATE_REQUEST_STATUS_FOR_AGENT) =>
    status === CERTIFICATE_REQUEST_STATUS_FOR_AGENT.rejected
      ? 'certificate.statusForAgent.done'
      : `certificate.statusForAgent.${status}`,
);

export const translateCertificateStatus = createTranslationMap(
  (status: CERTIFICATE_TYPE_STATUS | CERTIFICATE_AVAILABILITY) =>
    status === CERTIFICATE_TYPE_STATUS.requested
      ? 'certificatesTable:status.requestedWithoutDate'
      : `certificatesTable:status.${status}`,
);

export const translateBoQPositionTextProvider = createTranslationMap(
  (provider: BOQ_POSITION_TEXT_PROVIDER | EXTERNAL_CREDENTIAL_TYPE) =>
    `BoQ:editor.textProvider.${provider}`,
);

export const translateDepartmentType = createTranslationMap(
  (departmentType: DEPARTMENT_TYPE) => `departmentType.${departmentType}`,
);

export const translateBoQFileFormat = createTranslationMap(
  (fileFormat: BOQ_FILE_FORMAT) => `boqFileFormat.${fileFormat}`,
);

export const translateProjectCategory = createTranslationMap(
  (category: PROJECT_CATEGORY) => `common:project.categories.${category}`,
);

export const translateDownloadableBoqType = createTranslationMap(
  (type: DOWNLOADABLE_BOQ_TYPE) => `projects:boqTypes.${type}`,
);

export const translatePositionInvalidErrorTooltip = createTranslationMap(
  (error: POSITION_TYPE_ERROR) =>
    (
      ({
        [POSITION_TYPE_ERROR.missingBasePosition]:
          'BoQ:validation.basePosition.missingBasePosition.tooltip',
        [POSITION_TYPE_ERROR.missingCode]: 'BoQ:validation.code.missingCode',
        [POSITION_TYPE_ERROR.emptyCode]: 'BoQ:validation.code.emptyCode',
        [POSITION_TYPE_ERROR.nonNumericCode]: 'BoQ:validation.code.nonNumericCode',
        [POSITION_TYPE_ERROR.tooLargeCode]: 'BoQ:validation.code.tooLargeCode',
        [POSITION_TYPE_ERROR.missingExecutionDescriptionReference]:
          'BoQ:editor.positionTypeValidation.missingExecutionDescriptionReference',
        [POSITION_TYPE_ERROR.executionDescriptionNotFound]:
          'BoQ:editor.positionTypeValidation.executionDescriptionNotFound',
        [POSITION_TYPE_ERROR.missingReferralDescriptionReference]:
          'BoQ:editor.positionTypeValidation.missingReferralDescriptionReference',
        [POSITION_TYPE_ERROR.referralDescriptionNotFound]:
          'BoQ:editor.positionTypeValidation.referralDescriptionNotFound',
        [POSITION_TYPE_ERROR.missingSurchargePositionReference]:
          'BoQ:editor.positionTypeValidation.missingSurchargePositionReference',
        [POSITION_TYPE_ERROR.toBeSurchargedPositionNotFound]:
          'BoQ:editor.positionTypeValidation.toBeSurchargedPositionNotFound',
      }) as const
    )[error],
);

export const translateExecutionDescriptionSelectInvalidError = createTranslationMap(
  (error: EXECUTION_DESCRIPTION_SELECT_ERROR) =>
    (
      ({
        [POSITION_TYPE_ERROR.missingExecutionDescriptionReference]:
          'BoQ:editor.positionTypePanel.executionDescriptionSelect.validation.missingExecutionDescriptionReference',
        [POSITION_TYPE_ERROR.executionDescriptionNotFound]:
          'BoQ:editor.positionTypePanel.executionDescriptionSelect.validation.executionDescriptionNotFound',
      }) as const
    )[error],
);

export const translateReferralDescriptionSelectInvalidError = createTranslationMap(
  (error: REFERRAL_DESCRIPTION_SELECT_ERROR) =>
    (
      ({
        [POSITION_TYPE_ERROR.missingReferralDescriptionReference]:
          'BoQ:editor.positionTypePanel.referralDescriptionSelect.validation.missingReferralDescriptionReference',
        [POSITION_TYPE_ERROR.referralDescriptionNotFound]:
          'BoQ:editor.positionTypePanel.referralDescriptionSelect.validation.referralDescriptionNotFound',
      }) as const
    )[error],
);

export const translateSurchargeTypeSelectInvalidError = createTranslationMap(
  (error: SURCHARGE_TYPE_SELECT_ERROR) =>
    (
      ({
        [POSITION_TYPE_ERROR.missingSurchargePositionReference]:
          'BoQ:editor.positionTypePanel.surchargeTypeSelect.validation.missingSurchargePositionReference',
        [POSITION_TYPE_ERROR.toBeSurchargedPositionNotFound]:
          'BoQ:editor.positionTypePanel.surchargeTypeSelect.validation.toBeSurchargedPositionNotFound',
      }) as const
    )[error],
);

export const translateSurchargeTypeSelectOption = createTranslationMap(
  (surchargeType: SURCHARGE_TYPE) =>
    (
      ({
        [SURCHARGE_TYPE.AllPreviousPositions]:
          'BoQ:editor.positionTypePanel.surchargeTypeSelect.values.allPreviousPositions',

        [SURCHARGE_TYPE.ReferencedPositions]:
          'BoQ:editor.positionTypePanel.surchargeTypeSelect.values.referencedPositions',
      }) as const
    )[surchargeType],
);

export const translateAdditionalTextPosition = createTranslationMap(
  (position: ADDITIONAL_TEXT_POSITION) =>
    (
      ({
        [ADDITIONAL_TEXT_POSITION.opening]: 'BoQ:editor.addAdditionalTextModal.insertAtBeginning',
        [ADDITIONAL_TEXT_POSITION.closing]: 'BoQ:editor.addAdditionalTextModal.insertAtEnd',
      }) as const
    )[position],
);

export const translateBoQTextEditorHeading = createTranslationMap(
  (heading: BOQ_TEXT_EDITOR_HEADING) => `BoQ:editor.textEditor.heading.${heading}`,
);

export const translateBoQTextEditorColor = createTranslationMap(
  (color: BOQ_TEXT_EDITOR_COLOR) => `BoQ:editor.textEditor.colors.${color}`,
);

export const translateBoQUploadFileActionText = createTranslationMap(
  (fileFormatCombinedWithExcel: BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL) =>
    `BoQ:boqLanding.uploadCard.text.${fileFormatCombinedWithExcel}`,
);

export const translateBoQUploadButton = createTranslationMap(
  (fileFormatCombinedWithExcel: BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL) =>
    `BoQ:uploadBoQ.${fileFormatCombinedWithExcel}`,
);

export const translateBoQDropzoneDescription = createTranslationMap(
  (fileFormatCombinedWithExcel: BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL) =>
    `BoQ:dropzoneDescription.${fileFormatCombinedWithExcel}`,
);

export const translateBoQLandingDropzoneText = createTranslationMap(
  (fileFormatCombinedWithExcel: BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL) =>
    `BoQ:boqLanding.dropOverlayText.${fileFormatCombinedWithExcel}`,
);

export const translateBoQDownloadButton = createTranslationMap(
  (fileFormatCombinedWithExcel: BOQ_FILE_FORMAT_COMBINED_WITH_EXCEL) =>
    `BoQ:exportButton.label.${fileFormatCombinedWithExcel}`,
);

export const translateCostStructure = createTranslationMap(
  (costStructureType: Exclude<CompatibleCostStructureType, 'custom'>) =>
    `common:costCatalogs.${costStructureType}`,
);

export const translateCostEstimateCatalogType = createTranslationMap(
  (catalogType: Exclude<keyof typeof COST_CATALOG_TYPE, 'custom'>) =>
    `projectCostEstimation:catalogTypeSelect.${catalogType}`,
);

export const translateEditingSessionLockRequestTitle = createTranslationMap(
  (editingSessionType: EDITING_SESSION_TYPE) =>
    (
      ({
        [EDITING_SESSION_TYPE.bidPackageBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.initialRequestDenied.title',
        [EDITING_SESSION_TYPE.boqTemplateBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.initialRequestDenied.title',
        [EDITING_SESSION_TYPE.priceComparisonSimulationMode]:
          'BoQ:priceComparison.lockedOutFromSessionModal.initialRequestDenied.title',
        [EDITING_SESSION_TYPE.projectCostEstimation]:
          'projectCostEstimation:lockedOutFromSessionModal.initialRequestDenied.title',
      }) as const
    )[editingSessionType],
);

export const translateEditingSessionLockRequestMessage = createTranslationMap(
  (editingSessionType: EDITING_SESSION_TYPE) =>
    (
      ({
        [EDITING_SESSION_TYPE.bidPackageBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.initialRequestDenied.message',
        [EDITING_SESSION_TYPE.boqTemplateBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.initialRequestDenied.message',
        [EDITING_SESSION_TYPE.priceComparisonSimulationMode]:
          'BoQ:priceComparison.lockedOutFromSessionModal.initialRequestDenied.message',
        [EDITING_SESSION_TYPE.projectCostEstimation]:
          'projectCostEstimation:lockedOutFromSessionModal.initialRequestDenied.message',
      }) as const
    )[editingSessionType],
);

export const translateEditingSessionLockRequestConfirm = createTranslationMap(
  (editingSessionType: EDITING_SESSION_TYPE) =>
    (
      ({
        [EDITING_SESSION_TYPE.bidPackageBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.initialRequestDenied.confirmButton',
        [EDITING_SESSION_TYPE.boqTemplateBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.initialRequestDenied.confirmButton',
        [EDITING_SESSION_TYPE.priceComparisonSimulationMode]:
          'BoQ:priceComparison.lockedOutFromSessionModal.initialRequestDenied.confirmButton',
        [EDITING_SESSION_TYPE.projectCostEstimation]:
          'projectCostEstimation:lockedOutFromSessionModal.initialRequestDenied.confirmButton',
      }) as const
    )[editingSessionType],
);

export const translateEditingSessionLockedOutTitle = createTranslationMap(
  (editingSessionType: EDITING_SESSION_TYPE) =>
    (
      ({
        [EDITING_SESSION_TYPE.bidPackageBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.sessionTakenOver.title',
        [EDITING_SESSION_TYPE.boqTemplateBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.sessionTakenOver.title',
        [EDITING_SESSION_TYPE.priceComparisonSimulationMode]:
          'BoQ:priceComparison.lockedOutFromSessionModal.sessionTakenOver.title',
        [EDITING_SESSION_TYPE.projectCostEstimation]:
          'projectCostEstimation:lockedOutFromSessionModal.sessionTakenOver.title',
      }) as const
    )[editingSessionType],
);
export const translateEditingSessionLockedOutMessage = createTranslationMap(
  (editingSessionType: EDITING_SESSION_TYPE) =>
    (
      ({
        [EDITING_SESSION_TYPE.bidPackageBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.sessionTakenOver.message',
        [EDITING_SESSION_TYPE.boqTemplateBoqEditor]:
          'BoQ:editor.lockedOutFromSessionModal.sessionTakenOver.message',
        [EDITING_SESSION_TYPE.priceComparisonSimulationMode]:
          'BoQ:priceComparison.lockedOutFromSessionModal.sessionTakenOver.message',
        [EDITING_SESSION_TYPE.projectCostEstimation]:
          'projectCostEstimation:lockedOutFromSessionModal.sessionTakenOver.message',
      }) as const
    )[editingSessionType],
);

export const translateTextEditorColor = createTranslationMap(
  (color: TEXT_EDITOR_COLOR) => `textEditor:colors.${color}`,
);

export const translateShareBoQChangesMessage = createTranslationMap(
  (bidPackageStatus: BID_PACKAGE_STATUS) => {
    switch (bidPackageStatus) {
      case BID_PACKAGE_STATUS.inNegotiation:
        return 'BoQ:editor.confirmSaveModal.saveAndShareMessageNegotiation';
      case BID_PACKAGE_STATUS.bidAwarded:
        return 'BoQ:editor.confirmSaveModal.saveAndShareMessageAwarded';
      default:
        return 'BoQ:editor.confirmSaveModal.saveAndShareMessage';
    }
  },
);

export const translateCustomCostGroupCatalogStatus = createTranslationMap(
  (status: CUSTOM_COST_GROUP_CATALOG_STATUS) => {
    switch (status) {
      case CUSTOM_COST_GROUP_CATALOG_STATUS.active:
        return 'company:customCostGroupCatalogs.statusActive';
      case CUSTOM_COST_GROUP_CATALOG_STATUS.deactivated:
        return 'company:customCostGroupCatalogs.statusInactive';
    }
  },
);

export const translateTaskStatus = createTranslationMap(
  (status: TASK_STATUS) => `taskManagement:status.${status}`,
);

export const translateSubcontractorImportRequestStatus = createTranslationMap(
  (status: SUBCONTRACTOR_IMPORT_REQUEST_STATUS) =>
    `company:subcontractorImportRequests.status.${status}`,
);
