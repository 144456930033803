import { Button, COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { zIndexValues } from '~/shared/utils/styles';

export const InvisibleDiv = styled.div`
  display: none;
`;

const GAP = 10;

interface PromptBarProps {
  $color: COLOR;
}

export const PromptBar = styled.div<PromptBarProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: ${GAP}px;
  width: 100%;
  min-height: 62px;
  background-color: ${(props) => props.$color};
  padding: 16px 24px;
  z-index: ${zIndexValues.promptBar};
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP}px;
`;

export const PromptButton = styled(Button)`
  background-color: ${COLOR.white};
  color: ${COLOR.brand};
  border-width: 0;

  &:hover,
  &:focus {
    background-color: ${COLOR.white};
    border-width: 0;
  }
`;
