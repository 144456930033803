import { snakeCase } from 'change-case';
import { camelCase, chain } from 'lodash';

type TraverseCallback<T> = (object: T, key: string, value: unknown) => void;

export const traverseObject = <T extends object = Record<string, unknown>>(
  object: T,
  callback: TraverseCallback<T>,
) => {
  Object.entries(object).forEach(([key, value]) => {
    callback(object, key, value);
    if ((object as { [k: typeof key]: unknown })[key] !== null && typeof value === 'object') {
      traverseObject(value, callback);
    }
  });
};

export const omitNil = <T extends object>(obj: T): Partial<T> =>
  chain(obj)
    .omitBy((value) => value === null || value === undefined)
    .value();

export const convertObjectKeysToSnakeCase = (
  object: Record<string, unknown>,
): Record<string, unknown> =>
  Object.keys(object).reduce(
    (newObject, key) => ({ ...newObject, [snakeCase(key)]: object[key] }),
    {},
  );

export const convertObjectKeysToCamelCase = (
  object: Record<string, unknown>,
): Record<string, unknown> =>
  Object.keys(object).reduce(
    (newObject, key) => ({ ...newObject, [camelCase(key)]: object[key] }),
    {},
  );
