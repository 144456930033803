import type { CamelCaseFeatureFlags, PermanentFeatureFlags } from '@cosuno/feature-flags';

import type { CompanyConfigFragment } from '~/__gql__/graphql';

export type CompanyFeatureFlags = CamelCaseFeatureFlags<PermanentFeatureFlags>;

/**
 * Prefer using `useFeatureFlags()` if possible as it is more concise
 * and does not require you to access the current user or its company.
 *
 * You will only want to use this function in the following cases:
 * - You care about the feature flag of a company different than the current user (also see `OverrideFeatureFlagsContext`).
 * - You are outside of a hook or functional React component.
 * - You are somewhere above the main `<App />` component.
 *
 * @returns Whether the feature flag is enabled for the given company.
 */
export const isCompanyFeatureEnabled = (
  company: CompanyConfigFragment | undefined,
  feature: keyof CompanyFeatureFlags,
): boolean =>
  (company?.permanentFeatureFlags as CompanyFeatureFlags | undefined)?.[feature] ?? false;

export const areCompanyFeaturesEnabled = <T extends keyof CompanyFeatureFlags>(
  company: CompanyConfigFragment | undefined,
  features: T[],
): { [key in T]: boolean } =>
  features.reduce(
    (acc, feature) => ({ ...acc, [feature]: isCompanyFeatureEnabled(company, feature) }),
    {} as { [key in T]: boolean },
  );
