import { COLOR } from '@cosuno/cosuno-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { font, mediaQueries, sizes } from '~/shared/utils/styles';

export const Bar = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: ${sizes.topbarHeight}px;

  @media ${mediaQueries.mobile} {
    padding: 0 ${({ theme }) => theme.pageContentHorizontalPaddingMobile}px;
  }

  @media ${mediaQueries.tablet} {
    padding: 0 ${({ theme }) => theme.pageContentHorizontalPaddingDesktop}px;
  }
`;

export const BreadcrumbsContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const BackLink = styled(Link)`
  ${font.bold}
  ${font.size(11)}

  align-items: center;
  color: ${COLOR.textTertiary};
  display: flex;
  padding-right: 5px;
  margin-left: 0;
  text-transform: uppercase;

  /* by default will take all available space which can be all the
     space between the back button text and the user menu, this
     forces the clickable zone to be only the visible text/content */
  width: max-content;

  svg {
    margin-right: 10px;
  }
`;
