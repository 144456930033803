import { COLOR, Icon, Label } from '@cosuno/cosuno-ui';
import React from 'react';
import { Link } from 'react-router-dom';

import useAnalytics from '~/shared/hooks/useAnalytics';
import useRoutes, { CompanySettingsTabs } from '~/shared/hooks/useRoutes';
import useShowSubcontractorProfilePrompt from '~/shared/hooks/useShowSubcontractorProfilePrompt';
import useTranslation from '~/shared/hooks/useTranslation';
import { env } from '~/shared/utils/env';

import { InvisibleDiv, PromptBar, PromptButton, TextWrapper } from '../Styles';

export const SubcontractorProfilePrompt: React.FC = () => {
  const { t } = useTranslation('completeProfilePrompt');
  const { trackEvent } = useAnalytics();
  const routes = useRoutes();
  const { loading, showPrompt } = useShowSubcontractorProfilePrompt();

  if (loading) {
    return null;
  }

  if (!showPrompt) {
    if (env.REACT_APP_CYPRESS === 'true') {
      return <InvisibleDiv data-cy-subcontractor-profile-prompt-hidden />;
    }
    return null;
  }

  return (
    <PromptBar
      as={Link}
      to={routes.company({ tab: CompanySettingsTabs.profile })}
      onClick={() => trackEvent('createProfileBannerClickPrompt')}
      data-cy-subcontractor-profile-prompt
      $color={COLOR.highlightNeon}
    >
      <TextWrapper>
        <Icon type="info" color={COLOR.white} size={20} />
        <Label variant="large" color={COLOR.white}>
          {t('message')}
        </Label>
      </TextWrapper>
      <PromptButton size="small" iconLeft={{ type: 'arrow-right' }}>
        {t('completeProfile')}
      </PromptButton>
    </PromptBar>
  );
};
