import { graphql } from '~/__gql__';

export const queryShowSubcontractorProfilePrompt = graphql(/* GraphQL */ `
  query ShowSubcontractorProfilePrompt {
    company {
      id
      isAgentProfileComplete
    }
  }
`);
