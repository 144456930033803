import { Button } from '@cosuno/cosuno-ui';
import { useState } from 'react';

import Field from '~/shared/components/Field';
import Form from '~/shared/components/Form';
import Trans from '~/shared/components/Trans';
import { Heading, SubmitButton } from '~/shared/components/UnauthenticatedStyles';
import useTranslation from '~/shared/hooks/useTranslation';

import { Back } from '../Back';
import { Section } from '../Styles';
import type { LoginSuggestion } from '../types';
import { ErrorMessages, type LoginError } from './ErrorMessages';
import { BoldedText, Explanation, LoginInMethodButtonContainer } from './Styles';
import { SuggestionListItemContent } from './SuggestionListItemContent';

interface FormValues {
  code: string;
}

interface ProvideCodeStepProps {
  suggestion: LoginSuggestion;
  isSubmitting: boolean;
  error: LoginError;
  onSubmit: (values: FormValues) => Promise<void>;
  onLoginWithPassword: (suggestion: LoginSuggestion) => void;
  onGoBack: () => void;
  onCodeChange: () => void;
  resendConfirmationEmail: (email: string) => Promise<void>;
}

export const ProvideCodeStep: React.FC<ProvideCodeStepProps> = ({
  onSubmit,
  onGoBack,
  onCodeChange,
  onLoginWithPassword,
  suggestion,
  suggestion: { email },
  isSubmitting,
  error,
  resendConfirmationEmail,
}) => {
  const { t } = useTranslation('passwordlessLoginForm');

  const [hasResentConfirmationEmail, setHasResentConfirmationEmail] = useState(false);

  return (
    <div>
      <Back onClick={onGoBack} />
      <Form<FormValues>
        initialValues={{ code: '' }}
        validations={{ code: [Form.is.required(), Form.is.maxLength(6)] }}
        onSubmit={async (formValues) => {
          await onSubmit(formValues);
          setHasResentConfirmationEmail(false);
        }}
      >
        <ErrorMessages
          error={error}
          hasResentConfirmationEmail={hasResentConfirmationEmail}
          resendConfirmationEmail={async () => {
            await resendConfirmationEmail(email);
            setHasResentConfirmationEmail(true);
          }}
        />
        <Section>
          <Heading>{t('provideCode.title')}</Heading>
          <SuggestionListItemContent suggestion={suggestion} />
          <Explanation>
            <Trans
              t={t}
              i18nKey="agentAuth:revamp.loginLinkExplanation"
              components={{ b: <BoldedText /> }}
            />
          </Explanation>
        </Section>
        <Form.Element>
          <Section>
            <Field.Input
              name="code"
              label={t('provideCode.codeLabel')}
              icon="lock"
              filter={/^\d*$/}
              error={error === 'isInvalidCode' && t('provideCode.invalidCode')}
              showErrorsIfUntouched
              onChange={onCodeChange}
            />
          </Section>
          <Section>
            <LoginInMethodButtonContainer>
              <SubmitButton type="submit" working={isSubmitting}>
                {t('auth:login')}
              </SubmitButton>
              <Button
                fullWidth
                onClick={() => {
                  onLoginWithPassword(suggestion);
                }}
                variant="secondaryBrand"
              >
                {t('agentAuth:revamp.loginWithPassword')}
              </Button>
            </LoginInMethodButtonContainer>
          </Section>
        </Form.Element>
      </Form>
    </div>
  );
};
