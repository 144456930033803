import { Cell, Table } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const ReadOnlyNestedBaseCell = styled(Cell.Base)<{ $nestedLevel: number }>`
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  min-height: 42px;

  ${({ $nestedLevel }) =>
    $nestedLevel &&
    `
    margin-left: ${$nestedLevel * 40}px;
  `}
`;

export const SpacedSpan = styled.span`
  padding-right: 12px;
`;

export const StyledRow = styled(Table.Row)`
  grid-column: auto / span 4;
`;
