import { COLOR, Image, textStyles } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { mixins } from '~/shared/utils/styles';

export const StyledImagesUploader = styled.div<{ $hasFiles: boolean; $disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  border: 1px solid ${COLOR.borderMedium};
  border-radius: 8px;

  ${textStyles.bodyMedium450};

  ${(props) =>
    props.$hasFiles
      ? css`
          color: ${COLOR.textSecondary};
        `
      : css`
          color: ${COLOR.textTertiary};

          ${!props.$disabled &&
          css`
            &:hover {
              color: ${COLOR.highlightNeon};
            }
          `}

          &:focus-within {
            outline: 2px solid ${mixins.rgba(COLOR.brand, 0.2)};
            border-color: ${COLOR.highlightNeon};
          }
        `}

  ${(props) =>
    props.$disabled &&
    css`
      background-color: ${COLOR.brandLightestAlpha};
      pointer-events: none;
    `}
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: ' ';
    display: block;
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

  > * {
    z-index: 1;
  }
`;

interface DropzoneProps {
  $paddingBottom?: number;
}

export const Dropzone = styled.div<DropzoneProps>`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  outline: none;
  padding: 25px 25px ${(props) => props.$paddingBottom || 25}px;
  cursor: pointer;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 21px;
`;

export const ImagePreviewContainer = styled.div`
  display: grid;
  grid-template-areas: all;

  > * {
    grid-area: all;
  }
`;

export const ImagePreview = styled(Image)`
  max-height: 150px;
`;

export const RemoveLinkContainer = styled.div`
  padding-bottom: 12px;
  padding-top: 5px;
  text-align: center;
`;

export const UploadedImagesContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  gap: 20px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
