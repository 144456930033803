import './fontStyles.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import type { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';

import GoogleAutoCompleteStyles from '~/shared/components/LocationSearch/Styles';

import BaseStyles from './BaseStyles';
import NormalizeStyles from './NormalizeStyles';
import useCosunoTheme from './useCosunoTheme';

export const BootstrapStyles: FC<PropsWithChildren> = ({ children }) => {
  const theme = useCosunoTheme({ topOffset: 0 });

  return (
    <ThemeProvider theme={theme}>
      <NormalizeStyles />
      <BaseStyles />
      <GoogleAutoCompleteStyles />
      {children}
    </ThemeProvider>
  );
};
