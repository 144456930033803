import type { KEY_NAME } from '~/shared/constants/keyName';
import { castArray } from '~/shared/utils/javascript';

interface Modifiers {
  shift?: boolean;
  meta?: boolean;
  ctrl?: boolean;
  alt?: boolean;
}

/**
 * No modifier can be held while pressing the key unless specified as required.
 */
export const isKeyPressed = (
  event: KeyboardEvent | React.KeyboardEvent,
  keyOrKeys: KEY_NAME | KEY_NAME[],
  modifiers: Modifiers = {},
) =>
  castArray(keyOrKeys).includes(event.key as KEY_NAME) &&
  (modifiers.shift ? event.shiftKey : !event.shiftKey) &&
  (modifiers.meta ? event.metaKey : !event.metaKey) &&
  (modifiers.ctrl ? event.ctrlKey : !event.ctrlKey) &&
  (modifiers.alt ? event.altKey : !event.altKey);
