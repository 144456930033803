import { useRouteMatch } from 'react-router-dom';

import useRoutes from '~/shared/hooks/useRoutes';

const useMatchesExclusionRoutes = () => {
  const routes = useRoutes();
  const matchesCompanyRoute = useRouteMatch(routes.company());
  const matchesCompanyPremiumRoute = useRouteMatch(routes.premium());
  const matchesSubcontractorCreateBidRoute = useRouteMatch(routes.subcontractorCreateBid());
  const matchesBidRequestDeclinePageRoute = useRouteMatch(routes.bidInviteAction());
  const matchesBidRequestLandingPageRoute = useRouteMatch({
    path: routes.bidRequestLandingPage(),
    exact: true,
  });
  const matchesBidRequestTermsPageRoute = useRouteMatch(routes.bidRequestAcceptTerms());
  const matchesAgentMarketplaceSamples = useRouteMatch(
    routes.agentUnauthenticatedMarketplaceSamples(),
  );
  const matchesReverseMarketplace = useRouteMatch(routes.reverseMarketplaceBidPackage());
  const matchesReverseMarketplaceSuggestion = useRouteMatch(
    routes.agentReverseMarketplaceSuggestion(),
  );
  const matchesMessagesRoute = useRouteMatch(routes.messages());
  const matchesAgentSignContractRoute = useRouteMatch(routes.agentSignContract());
  const matchesAgentCompanyOnboarding = useRouteMatch(routes.agentCompanyOnboarding());

  return (
    matchesCompanyRoute ||
    matchesSubcontractorCreateBidRoute ||
    matchesBidRequestDeclinePageRoute ||
    matchesBidRequestLandingPageRoute ||
    matchesBidRequestTermsPageRoute ||
    matchesAgentMarketplaceSamples ||
    matchesReverseMarketplace ||
    matchesReverseMarketplaceSuggestion ||
    matchesMessagesRoute ||
    matchesAgentSignContractRoute ||
    matchesCompanyPremiumRoute ||
    matchesAgentCompanyOnboarding
  );
};

export default useMatchesExclusionRoutes;
