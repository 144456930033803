import { Callout, COLOR, Icon, SearchInput, TableWrapper } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { font, mediaQueries, mixins } from '~/shared/utils/styles';

export const HeadingMedium = styled.h3`
  padding-bottom: 5px;
  ${font.bold}
  ${font.size(32)}
`;

export const HeadingSmall = styled.h5`
  padding-bottom: 40px;
  ${font.bold}
  ${font.size(24)}
  line-height: 1.5;
`;

export const HeadingSmallest = styled.h6`
  ${font.bold}
  ${font.size(14)}
`;

export const SubcontractorStatsDetail = styled.div`
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  ${font.size(14)}
`;

export const SubcontractorStatsIcon = styled(Icon)`
  margin-right: 5px;
  color: ${COLOR.textTertiary};
`;

export const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  color: ${COLOR.textTertiary};
`;

export const SectionHint = styled(Callout).attrs({ size: 'compact' })`
  margin-bottom: 20px;
`;

interface SpaceBetweenProps {
  $centerVertically?: boolean;
}

export const SpaceBetween = styled.div<SpaceBetweenProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${(props) =>
    props.$centerVertically &&
    css`
      align-items: center;
    `}
`;

export const TableSearch = styled(SearchInput)`
  width: 200px;
`;

export const PermissionsTip = styled(Callout).attrs({ size: 'compact' })`
  margin-bottom: 30px;
`;

export const PermissionsTipLink = styled.a`
  ${mixins.link()};
`;

export const stickyHeaderStyles = css`
  left: 0;
  position: sticky;
`;

export const FullWidthWrapper = styled.div`
  width: 100%;
`;

// This component can be used as a replacement for the original TableWrapper
// in case of horizontally scrollable pages with sticky header(s) and only table on the page.
// It fixes the table at min 100% wide, so it occupies all the width of the page.
// Should be used with tables with noWrap prop
export const InlineTableWrapper = styled(TableWrapper)`
  display: inline-block;
  min-width: 100%;
  width: auto;
  position: relative;

  @media ${mediaQueries.mobile} {
    margin-right: ${({ theme }) => theme.pageContentHorizontalPaddingMobile}px;
  }

  @media ${mediaQueries.tablet} {
    margin-right: ${({ theme }) => theme.pageContentHorizontalPaddingDesktop}px;
  }
`;
