import type { ConditionalValidationPredicate, Validator, Values } from './types';

export const validateIf =
  <FieldValue, FormValues extends Values = Values>(
    predicate: ConditionalValidationPredicate<FieldValue, FormValues>,
    validator: Validator<FieldValue, FormValues, FormValues>,
  ): Validator<FieldValue, FormValues, FormValues> =>
  (...validationArgs: Parameters<Validator<FieldValue, FormValues, FormValues>>) => {
    if (predicate(...validationArgs)) {
      return validator(...validationArgs);
    }

    return false;
  };
