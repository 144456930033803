import { graphql } from '~/__gql__';

export const mutationVerifyMfaPhoneNumber = graphql(/* GraphQL */ `
  mutation VerifyMfaPhoneNumber($phone: String!, $loginToken: String) {
    verifyMfaPhoneNumber(phone: $phone, loginToken: $loginToken)
  }
`);

export const mutationStoreMfaPhoneNumber = graphql(/* GraphQL */ `
  mutation StoreMfaPhoneNumber($phone: String!, $code: String!, $loginToken: String) {
    storeMfaPhoneNumber(phone: $phone, code: $code, loginToken: $loginToken) {
      mfaToken
    }
  }
`);

export const mutationGenerateAuthenticatorSecret = graphql(/* GraphQL */ `
  mutation GenerateAuthenticatorSecret($loginToken: String) {
    generateAuthenticatorSecret(loginToken: $loginToken) {
      secret
      uri
    }
  }
`);

export const mutationStoreAuthenticatorSecret = graphql(/* GraphQL */ `
  mutation StoreAuthenticatorSecret($secret: String!, $code: String!, $loginToken: String) {
    storeAuthenticatorSecret(secret: $secret, code: $code, loginToken: $loginToken) {
      mfaToken
    }
  }
`);
