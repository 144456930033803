import type { Tag } from '@cosuno/cosuno-ui';
import { type ComponentPropsWithoutRef, forwardRef } from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

import { StyledTag } from './Styles';

export const PremiumBadge = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<typeof Tag>>(
  (props, ref) => {
    const { t } = useTranslation('topBar');

    return (
      <StyledTag ref={ref} {...props}>
        {t('premium')}
      </StyledTag>
    );
  },
);
