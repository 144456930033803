import { getCosunoUiThemeValues } from '@cosuno/cosuno-ui';
import { useMemo } from 'react';
import type { DefaultTheme } from 'styled-components';

import useUiTranslations from '~/shared/hooks/useUiTranslations';

interface UseCosunoThemeProps {
  topOffset: number;
}

const useCosunoTheme = ({ topOffset }: UseCosunoThemeProps): DefaultTheme => {
  const translations = useUiTranslations();

  return useMemo(
    () => getCosunoUiThemeValues({ translations, topOffset }),
    [topOffset, translations],
  );
};

export default useCosunoTheme;
