import type React from 'react';
import { useLayoutEffect, useRef } from 'react';

import {
  type FormInput,
  useNavigationConfirmationFormMethods,
} from '~/shared/hooks/useNavigationConfirmation';
import { uniqueId } from '~/shared/utils/javascript';

interface DataLossPreventerProps {
  formInput?: FormInput | false;
  isDirty: boolean;
}

export function useDataLossPreventer({ formInput, isDirty }: DataLossPreventerProps) {
  const { markFormAsClean, markFormAsDirty } = useNavigationConfirmationFormMethods();
  const id = useRef(uniqueId());

  // We have to fire this early to mark the form as clean before anything else
  // happens. Otherwise, we may end up with an unwanted confirmation modal
  useLayoutEffect(() => {
    if (!formInput) {
      return undefined;
    }

    const currentId = id.current;

    if (isDirty) {
      markFormAsDirty(currentId, formInput);
    } else {
      markFormAsClean(currentId);
    }

    return () => markFormAsClean(currentId);
  }, [formInput, markFormAsDirty, markFormAsClean, isDirty]);
}

const DataLossPreventer: React.FC<DataLossPreventerProps> = (props) => {
  useDataLossPreventer(props);

  return null;
};

export default DataLossPreventer;
