import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { objectToQueryString, queryStringToObject } from '~/shared/utils/url';

type ParamValue = string | null | ParamValue[];

const useQueryParam = <T = ParamValue>(
  paramName: string,
  callback: (value: T | null) => void,
): { hasRun: boolean } => {
  const location = useLocation();
  const history = useHistory();

  const [hasRun, setHasRun] = useState(false);

  const params = queryStringToObject<{ [key: string]: T }>(location.search);
  const paramValue = params[paramName];

  useEffect(() => {
    // If the param cannot be found, we want the callback to be triggered with `null`, but only if this hook
    // has not run yet. If it has run, it means the param might have been there previously, but was removed
    // by this hook.
    //
    // If the param can be found, we always want the callback to be triggered. The reason is that the param
    // is removed by this hook, so if it re-appears again later, some other action must have re-added it and
    // we should respond to that.
    if (hasRun && !paramValue) return;

    // Sometimes `location.search` will be empty on first render, but is then populated on
    // subsequent renders. That's why we don't set `hasRun` to `true` in this case.
    if (!location.search) {
      callback(null);
      return;
    }

    setHasRun(true);

    if (!paramValue) {
      callback(null);
      return;
    }

    const paramsWithoutThisParam = { ...params };
    delete paramsWithoutThisParam[paramName];
    const queryStringWithoutParam = objectToQueryString(paramsWithoutThisParam);

    history.replace(`${location.pathname}?${queryStringWithoutParam}`, location.state);

    callback(paramValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // note that if the `location.search` never gets a single param, the
  // `hasRun` will always be false
  return { hasRun };
};

export default useQueryParam;
