import type { ApolloQueryResult } from '@apollo/client/core/types';
import { orderBy } from 'lodash';
import { assert, type ElementOf } from 'ts-essentials';

import {
  WORK_CATEGORY_TYPE,
  WORK_CATEGORY_TYPE_FILTER,
  type WorkCategoriesQuery,
  type WorkCategoriesWithGroupsQuery,
  type WorkCategoryForSubcontractor,
  type WorkCategoryInput,
} from '~/__gql__/graphql';
import apolloClient from '~/apolloClient';
import type { MergedWorkCategory } from '~/shared/types/workCategories';

import { queryWorkCategories } from './queries';

type WorkCategory = ElementOf<WorkCategoriesQuery['workCategories']['workCategories']>;

export const normalizeWorkCategories = <T extends WorkCategoryInput>(
  workCategories: T[],
): Pick<T, 'name' | 'id' | 'isPrimary'>[] =>
  workCategories.map(({ id, name, isPrimary }) => ({ id, name, isPrimary }));

export const mergeWorkCategoriesKeepAllIds = (
  categories: Pick<WorkCategory, 'id' | 'name'>[],
): MergedWorkCategory[] => {
  const categoriesByName: Record<string, MergedWorkCategory> = {};

  for (const category of categories) {
    if (!categoriesByName[category.name]) {
      categoriesByName[category.name] = { name: category.name, ids: [] };
    }

    categoriesByName[category.name].ids.push(category.id);
  }

  return Object.values(categoriesByName);
};

export const mergeWorkCategoriesKeepNetwork = <T extends Pick<WorkCategory, 'name' | 'type'>>(
  workCategories?: T[],
): T[] => {
  if (!workCategories) {
    return [];
  }

  const categoriesByName: Record<string, T> = {};

  for (const category of workCategories) {
    if (categoriesByName[category.name]?.type === WORK_CATEGORY_TYPE.network) {
      continue;
    }

    categoriesByName[category.name] = category;
  }

  return Object.values(categoriesByName);
};

export const findWorkCategoryListByTerms = async (
  terms: string[],
  options: {
    type?: WORK_CATEGORY_TYPE_FILTER;
    limit?: number;
  } = {},
): Promise<WorkCategoryInput[]> => {
  const filteredTerms = terms
    .map((term) => term.toLowerCase().replace(/\d+/g, '').trim()) // exclude numbers from terms
    .filter((term) => term.length > 3) // ignore terms like "a", "and" or "the"
    .slice(0, 5); // ensure we don't spam the server with too many requests

  if (filteredTerms.length === 0) return [];

  const { limit = 3, type = WORK_CATEGORY_TYPE_FILTER.network } = options;

  const requestList = await Promise.allSettled(
    filteredTerms.map((query) =>
      apolloClient.query({
        query: queryWorkCategories,
        variables: { limit, query, type },
      }),
    ),
  );

  if (requestList.every((request) => request.status === 'rejected')) {
    throw requestList[0].reason;
  }

  return mergeWorkCategoriesKeepNetwork(
    requestList
      .filter(
        (request): request is PromiseFulfilledResult<ApolloQueryResult<WorkCategoriesQuery>> =>
          request.status === 'fulfilled',
      )
      .flatMap((request) => request.value.data.workCategories.workCategories ?? []),
  ).slice(0, limit);
};

export const getOrderedWorkCategories = (
  workCategories: Array<Pick<WorkCategoryForSubcontractor, 'id' | 'isPrimary' | 'name'>>,
) => orderBy(workCategories, ['isPrimary', 'name'], ['desc', 'asc']);

type WorkCategoryWithPossibleGroup =
  WorkCategoriesWithGroupsQuery['workCategories']['workCategories'][number];
interface WorkCategoryWithGroup extends WorkCategoryWithPossibleGroup {
  group: NonNullable<WorkCategoryWithPossibleGroup['group']>;
}
export function assertWorkCategoriesWithGroups(
  workCategories: WorkCategoryWithPossibleGroup[],
): asserts workCategories is WorkCategoryWithGroup[] {
  assert(
    workCategories.every((category) => category.group),
    'Every network work category should have group',
  );
}
