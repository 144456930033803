import type { COLOR } from '@cosuno/cosuno-ui';
import React from 'react';
import { Helmet } from 'react-helmet';

import useTranslation from '~/shared/hooks/useTranslation';
import { truncate } from '~/shared/utils/javascript';

interface HtmlHeadTagsProps {
  title: string | null;
  themeColor?: COLOR;
}

const HtmlHeadTags: React.FC<HtmlHeadTagsProps> = ({ title, themeColor }) => {
  const { i18n } = useTranslation();

  return (
    <Helmet>
      {i18n.language && <html lang={i18n.language} />}
      <title>{title ? `${truncate(title, { length: 100 })} | Cosuno` : 'Cosuno'}</title>
      {themeColor && <meta name="theme-color" content={themeColor} />}
    </Helmet>
  );
};

export default HtmlHeadTags;
