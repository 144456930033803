import queryString, { type ParseOptions, type StringifyOptions } from 'query-string';

import { env } from '~/shared/utils/env';

export const queryStringToObject = <S extends object>(str: string, options: ParseOptions = {}): S =>
  queryString.parse(str, {
    arrayFormat: 'bracket',
    ...options,
  }) as S;

export const objectToQueryString = <S extends object>(
  queryObj: S,
  options: StringifyOptions = {},
): string =>
  queryString.stringify(queryObj, {
    arrayFormat: 'bracket',
    ...options,
  });

export const urlWithQuery = <S extends object>(url: string, query: S): string => {
  const stringifiedQuery = objectToQueryString(query);

  return stringifiedQuery ? `${url}?${stringifiedQuery}` : url;
};

export const openInNewTab = (url: string) => {
  // Cypress doesn't support testing multiple tabs, so we have to open the page
  // in the same tab instead.
  if (env.REACT_APP_CYPRESS === 'true') {
    window.location.assign(url);
  } else {
    window.open(url);
  }
};

export const urlToPathname = (url: string): string => {
  try {
    const urlObj = new URL(url);

    return urlObj.pathname;
  } catch {
    return url;
  }
};

export const urlWithoutHost = (urlString: string): string => {
  try {
    const url = new URL(urlString);
    const path = url.pathname;
    const query = url.search;
    const anchor = url.hash;

    return `${path}${query}${anchor}`;
  } catch {
    return urlString;
  }
};
