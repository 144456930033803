import {
  LayersProvider,
  LightboxContext,
  MomentContext,
  PageLoader,
  PopoverContext,
  QueryMatchesContext,
  ScrollLockContext,
  useLightbox,
  usePopoverContextValues,
  useQueryMatches,
  useScrollLockManager,
} from '@cosuno/cosuno-ui';
import type { CamelCaseFeatureFlags, ExperimentFeatureFlags } from '@cosuno/feature-flags';
import { ExperimentFlagsProvider } from '@cosuno/feature-flags-frontend';
import moment from 'moment';
import React, { type PropsWithChildren } from 'react';
import { Provider as BalancerProvider } from 'react-wrap-balancer';

import { CurrentUserFeatureFlagsProvider } from '~/packages/FeatureFlags';
import QueryErrors from '~/shared/components/QueryErrors';
import { AUTH_STATUS } from '~/shared/constants';
import { AnalyticsExperimentsContextProvider } from '~/shared/contexts/analyticsExperimentsContext';
import AuthContext from '~/shared/contexts/authContext';
import CombinedNotificationsContext from '~/shared/contexts/combinedNotificationsContext';
import DownloadPreparationContext from '~/shared/contexts/downloadPreparationContext';
import ModalsContext from '~/shared/contexts/modalsContext';
import { RefreshFeatureFlagsContextProvider } from '~/shared/contexts/refreshFeatureFlagsContext';
import useAuthentication from '~/shared/hooks/useAuthentication';
import useCombinedNotifications from '~/shared/hooks/useCombinedNotifications';
import useDownloadPreparationManager from '~/shared/hooks/useDownloadPreparationManager';
import { GoogleMapsApiContext, useGoogleMapsApiManager } from '~/shared/hooks/useGoogleMapsApi';
import useModalsManager from '~/shared/hooks/useModalsManager';
import {
  NavigationConfirmationManagerContext,
  useNavigationConfirmationManager,
} from '~/shared/hooks/useNavigationConfirmation';
import { env } from '~/shared/utils/env';
import useAnalyticsTracker from '~/useAnalyticsTracker';

const Contexts: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const enableAnalytics =
    env.MODE === 'production' ||
    env.REACT_APP_CYPRESS === 'true' ||
    (env.MODE === 'development' && env.REACT_APP_ENABLE_ANALYTICS_IN_DEVELOPMENT === 'true');

  const authentication = useAuthentication();
  const modalManagerProps = useModalsManager();
  const queryMatches = useQueryMatches();
  const downloadPreparationManagerMethods = useDownloadPreparationManager();
  const scrollLockMethods = useScrollLockManager();
  const lightbox = useLightbox();
  const navigationConfirmationManager = useNavigationConfirmationManager();
  const getGoogleMapsApi = useGoogleMapsApiManager();
  const combinedNotifications = useCombinedNotifications(authentication.authState);
  const popoverContextValue = usePopoverContextValues();

  useAnalyticsTracker({ enableAnalytics, authContext: authentication });

  if (authentication.authState.status === AUTH_STATUS.authenticating) {
    return <PageLoader />;
  }

  if (combinedNotifications.combinedNotificationsResponse?.error) {
    return (
      <AuthContext.Provider value={authentication}>
        <QueryErrors error={combinedNotifications.combinedNotificationsResponse.error} />
      </AuthContext.Provider>
    );
  }

  return (
    <ExperimentFlagsProvider
      value={
        authentication.authState.status === AUTH_STATUS.authenticated &&
        (authentication.authState.user
          .featureFlags as CamelCaseFeatureFlags<ExperimentFeatureFlags>)
      }
    >
      <BalancerProvider>
        <LayersProvider>
          <MomentContext.Provider value={moment}>
            <AuthContext.Provider value={authentication}>
              <ModalsContext.Provider value={modalManagerProps}>
                <PopoverContext.Provider value={popoverContextValue}>
                  <QueryMatchesContext.Provider value={queryMatches}>
                    <DownloadPreparationContext.Provider value={downloadPreparationManagerMethods}>
                      <ScrollLockContext.Provider value={scrollLockMethods}>
                        <LightboxContext.Provider value={lightbox}>
                          <CombinedNotificationsContext.Provider value={combinedNotifications}>
                            <NavigationConfirmationManagerContext.Provider
                              value={navigationConfirmationManager}
                            >
                              <GoogleMapsApiContext.Provider value={getGoogleMapsApi}>
                                <AnalyticsExperimentsContextProvider>
                                  <CurrentUserFeatureFlagsProvider>
                                    <RefreshFeatureFlagsContextProvider>
                                      {children}
                                    </RefreshFeatureFlagsContextProvider>
                                  </CurrentUserFeatureFlagsProvider>
                                </AnalyticsExperimentsContextProvider>
                              </GoogleMapsApiContext.Provider>
                            </NavigationConfirmationManagerContext.Provider>
                          </CombinedNotificationsContext.Provider>
                        </LightboxContext.Provider>
                      </ScrollLockContext.Provider>
                    </DownloadPreparationContext.Provider>
                  </QueryMatchesContext.Provider>
                </PopoverContext.Provider>
              </ModalsContext.Provider>
            </AuthContext.Provider>
          </MomentContext.Provider>
        </LayersProvider>
      </BalancerProvider>
    </ExperimentFlagsProvider>
  );
};

export default Contexts;
