import { isNotNullish } from '~/shared/utils/typescript';

import type { CostGroupDefinition } from './types';

export const filterGroups = (
  groups: CostGroupDefinition[],
  value: string,
): CostGroupDefinition[] => {
  if (value === '') {
    return groups;
  }

  return groups
    .map((costGroup) => {
      if ([costGroup.localId, costGroup.name].join(' ').toLowerCase().includes(value)) {
        return costGroup;
      }

      const filteredChildGroups = filterGroups(costGroup.childCostGroups ?? [], value);

      if (!filteredChildGroups.length) {
        return null;
      }

      return {
        ...costGroup,
        childCostGroups: filteredChildGroups,
      };
    })
    .filter(isNotNullish);
};
