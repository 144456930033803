import { css } from 'styled-components';

import type { CSS } from './types';

export const createMixin = <Props extends {}>(
  mixin: CSS<Props>,
  overrides?: CSS<Props>,
): CSS<Props> => css<Props>`
  ${mixin};
  ${overrides};
`;
