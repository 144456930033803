import type { GraphQLError } from 'graphql';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ERROR_TYPES } from '~/shared/constants';
import useRoutes from '~/shared/hooks/useRoutes';
import { urlWithoutHost } from '~/shared/utils/url';

import { TermsForAcceptanceNotFoundError } from './errors';

type ErrorData =
  | {
      bidRequestId: string;
      subcontractorId: string;
      bidInviteId?: string;
    }
  | {
      bidPackageId: string;
      marketplaceSuggestionId?: string;
      sentEmailId?: string;
      subcontractorId?: string;
    };

export const useTermsAcceptance = () => {
  const history = useHistory();
  const routes = useRoutes();

  const requestTermsAcceptance = useCallback(
    (graphQLErrors: readonly GraphQLError[]) => {
      const metadata = graphQLErrors.find(
        (error) => error.extensions?.code === ERROR_TYPES.termsNotAccepted,
      )?.extensions as unknown as ErrorData;
      const currentPath = urlWithoutHost(window.location.href);

      if (!metadata) throw new TermsForAcceptanceNotFoundError();

      const route =
        'bidRequestId' in metadata
          ? routes.bidRequestAcceptTerms(metadata.bidRequestId, metadata)
          : routes.reverseMarketplaceBidPackageAcceptTerms(metadata.bidPackageId, metadata);

      history.replace(route, { redirect: currentPath });
    },
    [history, routes],
  );

  return useMemo(() => ({ requestTermsAcceptance }), [requestTermsAcceptance]);
};
