import { textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Title = styled.h1`
  ${textStyles.titleSmall500};
`;

export const PricingPlansModalContent = styled.div`
  padding: 30px;
`;
