import { useCallback, useState } from 'react';

import useModalState, { type ModalStateData, type UseModalStateProps } from './useModalState';

export interface ModalStateWithParamsData<Params>
  extends Omit<ModalStateData, 'params' | 'openModal'> {
  params: Params | null;
  openModal: (params: Params | null) => void;
}

export interface UseModalStateWithParamsProps<Params> extends Omit<UseModalStateProps, 'onClose'> {
  onClose?: (params: Params | null) => void;
  resetOnClose?: boolean;
}

/**
 * Same as `useModalState`, but allows you to define a record containing one or
 * more variables associated with the modal. For example, a modal for deleting
 * users may require a user id to be provided first for it to work.
 *
 * It's recommended that you define the generic parameter `Params` as an object.
 * For example:
 * ```ts
 * const modalState = useModalStateWithParams<{ userId: string }>();
 * ```
 *
 * `params` will be `null` when the modal is closed and not-`null` when it's
 * opened. Modals should only render content when `params` is not `null`.
 */
const useModalStateWithParams = <Params>({
  onClose,
  resetOnClose,
  ...props
}: UseModalStateWithParamsProps<Params> = {}): ModalStateWithParamsData<Params> => {
  const [params, setParams] = useState<Params | null>(null);

  const onCloseMemoized = useCallback(() => onClose?.(params), [onClose, params]);

  const modalData = useModalState({
    ...props,
    onClose: onCloseMemoized,
  });

  const openModal = (newParams: Params | null) => {
    setParams(newParams);
    modalData.openModal();
  };

  return {
    ...modalData,
    params,
    closeModal: () => {
      if (resetOnClose) {
        setParams(null);
      }
      modalData.closeModal();
    },
    openModal,
  };
};

export default useModalStateWithParams;
