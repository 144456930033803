import styled, { css } from 'styled-components';

import { mediaQueries } from '~/shared/utils/styles';

export const DisableVerticalScroll = styled.div``;

interface PageContentProps {
  fillVerticalSpace?: boolean;
  disableTopPadding?: boolean;
}

const PageContent = styled.div.attrs({ 'data-cy-page-content': true })<PageContentProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ fillVerticalSpace = true }) =>
    fillVerticalSpace &&
    css`
      flex: 1;
    `};

  @media ${mediaQueries.mobile} {
    padding: ${({ theme }) =>
      `${theme.pageContentTopPadding}px ${theme.pageContentHorizontalPaddingMobile}px  ${theme.pageContentBottomPadding}px`};

    ${({ disableTopPadding = false }) =>
      disableTopPadding &&
      css`
        padding-top: 0;
      `};
  }

  @media ${mediaQueries.tablet} {
    padding: ${({ theme }) =>
      `${theme.pageContentTopPadding}px ${theme.pageContentHorizontalPaddingDesktop}px  ${theme.pageContentBottomPadding}px`};

    ${({ disableTopPadding = false }) =>
      disableTopPadding &&
      css`
        padding-top: 0;
      `};
  }

  &:has(${DisableVerticalScroll}) {
    overflow-y: hidden;
  }
`;

export default PageContent;
export { default as PageContentWithToolbar } from './PageContentWithToolbar';
