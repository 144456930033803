import {
  type CompatibleStorageValue,
  getLocalStorageStateDefaultOptions,
  useInitStorageStateValue,
  useLocalStorageState as useLocalStorageStateFromCosunoUi,
  type UseLocalStorageStateOptions,
  useSynchronizeStorage,
} from '@cosuno/cosuno-ui';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { react } from 'signia';
import { useAtom } from 'signia-react';

import { parseValue, stringifyValue } from '../utils/json';

const otherDefaults = {
  parse: parseValue,
  stringify: stringifyValue,
};

export function useLocalStorageAtom<
  T extends CompatibleStorageValue,
  PersistedParsedValue extends CompatibleStorageValue = T,
>(
  name: string,
  storageKey: string,
  emptyValue: T,
  options: UseLocalStorageStateOptions<T, PersistedParsedValue> = {},
) {
  const optionsWithDefaults = {
    ...getLocalStorageStateDefaultOptions<T, PersistedParsedValue>(),
    ...otherDefaults,
    ...options,
  };
  const {
    when,
    migrationStrategy,
    shouldSuspendUpdateOnPageInvisibility,
    version,
    duration,
    readOnly,
    stringify,
    parse,
    transformValueOnRead,
    transformValueOnWrite,
  } = optionsWithDefaults;
  const { getInitState, whenRef } = useInitStorageStateValue<T, PersistedParsedValue>({
    unversionedKey: storageKey,
    migrationStrategy,
    transformValueOnRead,
    version,
    duration,
    stringify,
    parse,
    emptyValue,
    when,
  });

  const atom = useAtom<T>(name, getInitState);

  const setAtomValueIfChanged = useCallback(
    (newValue: T) => {
      atom.update((current) => (isEqual(current, newValue) ? current : newValue));
    },
    [atom],
  );

  const { forceWrite } = useSynchronizeStorage({
    storedValue: atom.value,
    setStoredValue: setAtomValueIfChanged,
    whenRef,
    emptyValue,
    unversionedKey: storageKey,
    version,
    duration,
    stringify,
    parse,
    shouldSuspendUpdateOnPageInvisibility,
    transformValueOnWrite,
    transformValueOnRead,
  });

  useEffect(() => {
    if (readOnly) return;

    const stop = react(`write atom value to local storage ${storageKey}`, () => {
      forceWrite(atom.value);
    });

    return stop;
  }, [atom.value, readOnly, storageKey, forceWrite]);

  useLayoutEffect(() => {
    atom.set(getInitState());
  }, [getInitState, atom, storageKey]);

  return atom;
}

export default function useLocalStorageState<
  T extends CompatibleStorageValue,
  PersistedParsedValue extends CompatibleStorageValue = T,
>(
  storageKey: string,
  emptyValue: T,
  options: UseLocalStorageStateOptions<T, PersistedParsedValue> = {},
) {
  return useLocalStorageStateFromCosunoUi(storageKey, emptyValue, {
    ...getLocalStorageStateDefaultOptions<T, PersistedParsedValue>(),
    ...otherDefaults,
    ...options,
  });
}
