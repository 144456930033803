import { useScrollLock } from '@cosuno/cosuno-ui';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import type { Exact } from 'ts-essentials';
import { TypedEventTarget } from 'typescript-event-target';

import { KEY_NAME } from '~/shared/constants';
import ModalsContext from '~/shared/contexts/modalsContext';
import useKeyboardEventCallback from '~/shared/hooks/useKeyboardEventCallback';
import { useNavigationConfirmationEnvironment } from '~/shared/hooks/useNavigationConfirmation';
import { uniqueId } from '~/shared/utils/javascript';

export class ModalEvent extends CustomEvent<{}> {}

export interface ModalEventMap {
  closed: ModalEvent;
}

export type ModalEvents = TypedEventTarget<ModalEventMap>;

export interface ModalStateData {
  id: string;
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  isActive: boolean;
  isClosingDisabled: boolean;
  navigationConfirmationEnvironmentId: string;
  isNavigatingAway: boolean;
  subscribeToForm: (formId: string) => void;
  params: Exact<{}, {}>;
  events: ModalEvents;
  tryNavigatingAway: (onSuccess: () => void) => void;
}

export interface UseModalStateProps {
  isOpen?: boolean;
  isClosingDisabled?: boolean;
  onClose?: () => void;
}

/**
 * Creates state that can be supplied to modals and used to control them.
 *
 * If you need to associate some variables with a modal (e.g. a delete modal
 * needs a user id to delete), then use `useModalStateWithParams`.
 */
const useModalState = (props: UseModalStateProps = {}): ModalStateData => {
  const { getIsActive, setAsActive, setAsInactive, getIsOpen } = useContext(ModalsContext);
  const id = useRef(uniqueId());
  const { tryNavigatingAway, isNavigatingAway, subscribeToForm, environmentId } =
    useNavigationConfirmationEnvironment();
  const isOpen = getIsOpen(id.current);
  const isOpenProp = props?.isOpen;
  const isActive = getIsActive(id.current);
  const upToDateSetAsInactive = useRef(setAsInactive);
  const events = useMemo(() => new TypedEventTarget<ModalEventMap>(), []);

  const { onClose } = props;

  upToDateSetAsInactive.current = setAsInactive;

  useScrollLock(isOpen, id.current);

  const openModal = useCallback(() => {
    setAsActive(id.current);
  }, [setAsActive]);

  const closeModal = useCallback(() => {
    tryNavigatingAway(() => setAsInactive(id.current));

    // zero-timeout allows to properly manage focus inside onClose
    setTimeout(() => {
      onClose?.();
      events.dispatchTypedEvent('closed', new ModalEvent('closed'));
    }, 0);
  }, [events, tryNavigatingAway, onClose, setAsInactive]);

  useEffect(() => {
    if (isOpenProp === undefined) return;
    if (isOpenProp) {
      setAsActive(id.current);
    } else {
      setAsInactive(id.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenProp]);

  useEffect(
    () => () => {
      upToDateSetAsInactive.current(id.current);
    },
    [],
  );

  useKeyboardEventCallback(window, KEY_NAME.escape, closeModal, {
    condition: isOpen && isActive && !props?.isClosingDisabled,
  });

  return useMemo(
    () => ({
      id: id.current,
      isOpen,
      openModal,
      closeModal,
      isActive,
      isClosingDisabled: props?.isClosingDisabled || false,
      navigationConfirmationEnvironmentId: environmentId,
      isNavigatingAway,
      subscribeToForm,
      params: {},
      events,
      tryNavigatingAway,
    }),
    [
      closeModal,
      environmentId,
      events,
      isActive,
      isNavigatingAway,
      isOpen,
      openModal,
      props?.isClosingDisabled,
      subscribeToForm,
      tryNavigatingAway,
    ],
  );
};

export default useModalState;
