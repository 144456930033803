export enum ERROR_TYPES {
  notFound = 'NOT_FOUND',
  termsNotAccepted = 'TERMS_NOT_ACCEPTED',
  subcontractorUnauthenticated = 'SUBCONTRACTOR_UNAUTHENTICATED',
  suggestionSentToInvalidSubcontractor = 'SUGGESTION_SENT_TO_INVALID_SUBCONTRACTOR',
  unauthenticated = 'UNAUTHENTICATED',
  unauthorized = 'UNAUTHORIZED',
  docuSignEnvelopeDoesNotExistError = 'DOCU_SIGN_ENVELOPE_DOES_NOT_EXIST_ERROR',
  ssoUserNotFound = 'S_S_O_USER_NOT_FOUND',
  ssoUserPasswordResetAttempt = 'S_S_O_USER_PASSWORD_RESET_ATTEMPT',
  ssoUserPasswordLoginAttempt = 'S_S_O_USER_PASSWORD_LOGIN_ATTEMPT',
}
