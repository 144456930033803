import {
  Checkbox,
  CheckboxGroup,
  type CheckboxGroupProps,
  type CheckboxProps,
  type DateOrTimePickerProps,
  DatePicker,
  FileUploader,
  type FileUploaderProps,
  FormField,
  ImageUploader,
  type ImageUploaderProps,
  Input,
  type InputProps,
  RadioButtonGroup,
  type RadioButtonGroupProps,
  SelectMulti,
  type SelectMultiProps,
  SelectMultiWithTags,
  type SelectMultiWithTagsProps,
  SelectSingle,
  type SelectSingleProps,
  Textarea,
  type TextareaProps,
} from '@cosuno/cosuno-ui';
import { useField, useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import { Autocomplete, type AutocompleteProps } from '~/shared/components/Autocomplete';
import CostGroupSelector, {
  type CostGroupSelectorProps,
} from '~/shared/components/CostGroupSelector';
import { DistanceInput, type DistanceInputProps } from '~/shared/components/DistanceInput';
import FieldErrorScrollMarker from '~/shared/components/FieldErrorScrollMarker';
import FormattedNumberInput, {
  type FormattedNumberInputProps,
} from '~/shared/components/FormattedNumberInput';
import ImagesUploader, { type ImagesUploaderProps } from '~/shared/components/ImagesUploader';
import LocationSearch, { type LocationSearchProps } from '~/shared/components/LocationSearch';
import PhoneInput, { type PhoneInputProps } from '~/shared/components/PhoneInput';
import TextEditor, { type TextEditorProps } from '~/shared/components/TextEditor';
import {
  type Props as WorkCategoriesSelectProps,
  WorkCategoriesSelect,
} from '~/shared/components/WorkCategoriesSelect';
import { uniqueId } from '~/shared/utils/javascript';

import {
  AgentWorkCategoriesField,
  type AgentWorkCategoriesFieldProps,
} from '../AgentWorkCategoriesField';
import { StyledNumberInput } from './Styles';

export interface FieldProps {
  name: string;
  id?: string;
  className?: string;
  label?: string;
  required?: boolean;
  showAsRequired?: boolean;
  /**
   * This prop is used to control the width of the FormComponent inside the field wrapper.
   */
  wrapperWidth?: number;
  disabled?: boolean;
  invalid?: boolean;
  error?: React.ReactNode;
  errorTooltip?: boolean;
  showErrors?: boolean;
  showErrorsIfUntouched?: boolean;
  onChange?: (value: unknown) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onBlurWithValue?: (value: unknown, values: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapFormValue?: (value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapChangedValue?: (value: any) => any;
  mapPercentage?: boolean;
  validate?: (value: unknown) => string | undefined;
  useMargins?: boolean;
  hint?: React.ReactNode;
}

type InjectedProps = 'value' | 'onChange';

const generateField = <Props extends {}>(
  FormComponent: React.ComponentType<Props>,
  options?: {
    /**
     * Some inputs (i.e. radio buttons or selects) don't emit the blur event during normal use,
     * so they need to be marked as touched on change instead.
     */
    markAsTouchedOnChange?: boolean;
    /** Enable this in case input renders its own error message */
    hideErrors?: boolean;
  },
) => {
  const FieldComponent = React.forwardRef<unknown, FieldProps & Omit<Props, InjectedProps>>(
    (
      {
        className,
        id,
        name,
        label,
        required = false,
        showAsRequired = required,
        invalid,
        error: errorProp,
        errorTooltip,
        onChange = () => {},
        onBlur: onBlurProp,
        onBlurWithValue,
        mapFormValue: mapFormValueProp = (x) => x,
        mapChangedValue: mapChangedValueProp = (x) => x,
        mapPercentage,
        useMargins = true,
        showErrors: showErrorsProp = true,
        showErrorsIfUntouched = false,
        hint,
        validate,
        wrapperWidth,
        ...props
      },
      ref,
    ) => {
      const [{ value, ...inputProps }, fieldMetaProps, { setTouched }] = useField({
        name,
        validate,
        onBlur: onBlurProp,
      });
      const { setFieldValue, values } = useFormikContext();
      const fieldId = useMemo(() => id || uniqueId('field-'), [id]);
      const error = errorProp ?? fieldMetaProps.error;
      const hasError = (showErrorsIfUntouched || fieldMetaProps.touched) && error;
      const showErrors = !options?.hideErrors && showErrorsProp;

      const mapFormValue = mapPercentage
        ? (valueToMap?: string | number) =>
            valueToMap ? (Number(valueToMap) * 100).toFixed(3) : '0'
        : mapFormValueProp;
      const mapChangedValue = mapPercentage
        ? (valueToMap?: string | number) => `${Number(valueToMap) / 100}`
        : mapChangedValueProp;

      const handleBlur: React.EventHandler<React.FocusEvent<HTMLInputElement>> = (event) => {
        inputProps.onBlur(event);

        if (onBlurWithValue) {
          onBlurWithValue(mapChangedValue(event.target.value), values);
        }

        onBlurProp?.(event);
      };
      const handleChange = (newValue: unknown) => {
        if (options?.markAsTouchedOnChange) {
          void setTouched(true);
        }

        const mappedNewValue = mapChangedValue(newValue);

        void setFieldValue(name, mappedNewValue);
        onChange(mappedNewValue);
      };

      // TODO figure out where does invalid come from and fix it to boolean
      // `invalid` is not a boolean, but it has the error message instead
      const isInvalid = Boolean(invalid || hasError);

      return (
        <FormField
          className={className}
          label={label}
          labelHtmlFor={fieldId}
          disabled={props.disabled}
          useMargins={useMargins}
          showAsRequired={showAsRequired}
          exactWidth={wrapperWidth}
          hint={hint}
          error={showErrors && hasError ? error : undefined}
          errorTooltip={errorTooltip}
          data-cy-is-invalid={isInvalid}
          data-cy-invalid-message={error}
        >
          {error && <FieldErrorScrollMarker name={name} />}
          <FormComponent
            // Seems like we have to cast props as any, even though they should be a
            // subset of Props
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
            {...inputProps}
            value={mapFormValue(value)}
            id={fieldId}
            invalid={isInvalid}
            required={required}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={ref}
          />
        </FormField>
      );
    },
  );

  FieldComponent.displayName = `Field.${FormComponent.displayName || FormComponent.name}`;

  return FieldComponent;
};

const Field = {
  Autocomplete: generateField<AutocompleteProps>(Autocomplete),
  Input: generateField<InputProps>(Input),
  SelectSingle: generateField<SelectSingleProps>(SelectSingle, { markAsTouchedOnChange: true }),
  SelectMulti: generateField<SelectMultiProps>(SelectMulti, { markAsTouchedOnChange: true }),
  SelectMultiWithTags: generateField<SelectMultiWithTagsProps>(SelectMultiWithTags, {
    markAsTouchedOnChange: true,
  }),
  Textarea: generateField<TextareaProps>(Textarea),
  DatePicker: generateField<DateOrTimePickerProps>(DatePicker, { markAsTouchedOnChange: true }),
  LocationSearch: generateField<LocationSearchProps>(LocationSearch),
  CheckboxGroup: generateField<CheckboxGroupProps>(CheckboxGroup, { markAsTouchedOnChange: true }),
  Checkbox: generateField<CheckboxProps>(Checkbox, { markAsTouchedOnChange: true }),
  RadioButtonGroup: generateField<RadioButtonGroupProps<string | number | boolean>>(
    RadioButtonGroup,
    { markAsTouchedOnChange: true },
  ),
  ImageUploader: generateField<ImageUploaderProps>(ImageUploader, { markAsTouchedOnChange: true }),
  ImagesUploader: generateField<ImagesUploaderProps>(ImagesUploader, {
    markAsTouchedOnChange: true,
  }),
  FileUploader: generateField<FileUploaderProps>(FileUploader, { markAsTouchedOnChange: true }),
  FormattedNumberInput: generateField<FormattedNumberInputProps>(FormattedNumberInput),
  DistanceInput: generateField<DistanceInputProps>(DistanceInput),
  NumberInput: generateField<InputProps>(StyledNumberInput),
  PhoneInput: generateField<PhoneInputProps>(PhoneInput),
  WorkCategoriesSelect: generateField<WorkCategoriesSelectProps>(WorkCategoriesSelect),
  TextEditor: generateField<TextEditorProps>(TextEditor),
  AgentWorkCategories: generateField<AgentWorkCategoriesFieldProps>(AgentWorkCategoriesField, {
    hideErrors: true,
  }),
  CostGroupSelector: generateField<CostGroupSelectorProps>(CostGroupSelector),
};

export default Field;
