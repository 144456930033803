import { useEffect, useRef } from 'react';

/**
 * Will only run the callback (inside of a regular `useEffect`) once. If a
 * `when` value is provided, the callback will only be run once that value
 * becomes truthy.
 */
export const useEffectOnce = (callback: () => void, options: { when?: boolean } = {}) => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current || options.when === false) return;

    hasRun.current = true;
    callback();
  }, [callback, options.when]);
};
