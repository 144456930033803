import type { AwardBidQueryParams, ShowAwardedBidQueryParams } from '~/components/AwardedBid';
import type { SubcontractorProfileQueryParams } from '~/components/Bid';
import type {
  CreateSubcontractorUrlParams,
  SubcontractorProfileUrlParams,
} from '~/shared/components/SubcontractorProfile';
import type { Routes } from '~/shared/constants/routes';

import { urlWithQuery } from './url';

export const submitBidUrlWithParams = (
  routes: Routes,
  projectId: string,
  bidPackageId: string,
  bidRequestId: string,
  params: SubcontractorProfileQueryParams,
) => urlWithQuery(routes.submitBid(projectId, bidPackageId, bidRequestId), params);

export const showAwardedBidUrlWithParams = (
  routes: Routes,
  projectId: string,
  bidPackageId: string,
  bidRequestId: string,
  params: ShowAwardedBidQueryParams,
) => urlWithQuery(routes.showAwardedBid(projectId, bidPackageId, bidRequestId), params);

export const awardBidUrlWithParams = (
  routes: Routes,
  projectId: string,
  bidPackageId: string,
  bidRequestId: string,
  bidId: string,
  params: AwardBidQueryParams = {},
) => urlWithQuery(routes.awardBid(projectId, bidPackageId, bidRequestId, bidId), params);

export const createSubcontractorUrlWithParams = (
  routes: Routes,
  params: CreateSubcontractorUrlParams,
) =>
  urlWithQuery(routes.newSubcontractor(), {
    ...params,
    trackingInfo: JSON.stringify(params.trackingInfo),
  });

export const subcontractorUrlWithParams = (
  routes: Routes,
  subcontractorId: string,
  params: SubcontractorProfileUrlParams,
) =>
  urlWithQuery(routes.subcontractor(subcontractorId), {
    ...params,
    addBidderTrackingProps: JSON.stringify(params.addBidderTrackingProps),
  });
