import { createContext } from 'react';

/**
 * Entry point for tracking events.
 *
 * Note: Values will be transformed into `snake_case`.
 */
export enum ANALYTICS_ENTRY_POINT {
  addBidder = 'addBidder',
  addBidders = 'addBidders',
  addendum = 'addendum',
  agentBidRequests = 'agentBidRequests',
  agentSignupConfirmation = 'agentSignupConfirmation',
  allAwardedBids = 'allAwardedBids',
  awardBidPackage = 'awardBidPackage',
  awardedBidModal = 'awardedBidModal',
  awardedBidsRequiringSignature = 'awardedBidsRequiringSignature',
  bidPackageOverview = 'bidPackageOverview',
  bidPackageAddenda = 'bidPackageAddenda',
  bidPackageBilling = 'bidPackageBilling',
  bidPackageBoq = 'bidPackageBoq',
  bidPackageDocuments = 'bidPackageDocuments',
  bidPackageInbox = 'bidPackageInbox',
  bidPackages = 'bidPackages',
  bidRequestPage = 'bidRequestPage',
  bidRequestInbox = 'bidRequestInbox',
  bidRequestSidebar = 'bidRequestSidebar',
  bids = 'bids',
  billing = 'billing',
  bim = 'bim',
  boqStructureDefineMode = 'boqStructureDefineMode',
  boqStructureRedefineMode = 'boqStructureRedefineMode',
  boqTemplate = 'boqTemplate',
  closedProjects = 'closedProjects',
  /** Certificate request modal */
  certificateRequest = 'certificateRequest',
  /** Uploading certificates in response to certificate requests */
  certificateRequestFlow = 'certificateRequestFlow',
  certificates = 'certificates',
  combinedInbox = 'combinedInbox',
  contractedBids = 'contractedBids',
  contractedBid = 'contractedBid',
  contractSignatureAgentRequestEmail = 'contractSignatureAgentRequestEmail',
  contractSignatureAgentRequestReminderEmail = 'contractSignatureAgentRequestReminderEmail',
  contractSignaturePrincipalRequestReminderEmail = 'contractSignaturePrincipalRequestReminderEmail',
  contractSignaturePrincipalRequestEmail = 'contractSignaturePrincipalRequestEmail',
  contractSigner = 'contractSigner',
  contracts = 'contracts',
  costAnalysis = 'costAnalysis',
  costEstimation = 'costEstimation',
  costGroups = 'costGroups',
  createBoQTemplate = 'createBoqTemplate',
  createProjectLanding = 'createProjectLanding',
  customBidInvite = 'customBidInvite',
  /** My company -> Branding */
  customBranding = 'customBranding',
  customFieldsProject = 'customFieldsProject',
  customFieldsSettings = 'customFieldsSettings',
  customFieldsSubcontractor = 'customFieldsSubcontractor',
  customRoles = 'customRoles',
  emailTemplates = 'emailTemplates',
  gaeb90 = 'gaeb90',
  gaebXml = 'gaebXml',
  goldPager = 'goldPager',
  grossTotal = 'grossTotal',
  heinzeVob = 'heinzeVob',
  internalDocuments = 'internalDocuments',
  internalDocumentsBoqLibrary = 'boqLibrary',
  intromail = 'intromail',
  marketplace = 'marketplace',
  messagingSignatures = 'messagingSignatures',
  messagingTemplates = 'messagingTemplates',
  nda = 'nda',
  netTotal = 'netTotal',
  oenorm = 'oenorm',
  offices = 'offices',
  openIndex = 'openIndex',
  openProjects = 'openProjects',
  permissions = 'permissions',
  priceComparison = 'priceComparison',
  principalContract = 'principalContract',
  projectDocuments = 'projectDocuments',
  projectDropdown = 'projectDropdown',
  projectOverview = 'projectOverview',
  publishBidPackage = 'publishBidPackage',
  resendInvite = 'resendInvite',
  rejectBidder = 'rejectBidder',
  sendInvite = 'sendInvite',
  settings = 'settings',
  sidebar = 'sidebar',
  stlb = 'stlb',
  signatureRequestEmail = 'signatureRequestEmail',
  subcontractorBidPage = 'subcontractorBidPage',
  subcontractorLockedReverseMarketplaceUpsell = 'subcontractorLockedReverseMarketplaceUpsell',
  subcontractorProfile = 'subcontractorProfile',
  subcontractors = 'subcontractors',
  subcontractorUpsellPremiumModal = 'subcontractorUpsellPremiumModal',
  subcontractorPublishPremiumModal = 'subcontractorPublishPremiumModal',
  subcontractorPublishPremiumModalCardComparison = 'subcontractorPublishPremiumModalCardComparison',
  taskAssignee = 'taskAssignee',
  taskDetails = 'taskDetails',
  taskOverview = 'taskOverview',
  templateSettings = 'templateSettings',
  premiumPageFooter = 'premiumPageFooter',
  premiumPageTopbar = 'premiumPageTopbar',
  userJoinRequest = 'userJoinRequest',
  website = 'website',
  workCategories = 'workCategories',
  /** Used for overflow menu in column headers in the price comparison table */
  more = 'more',
  /** Used for the adjust columns modal in the price comparison table */
  hideBids = 'hideBids',
  /** Both used for marketplace saved search "save search" button location */
  resultHeader = 'resultHeader',
  emptyStateAction = 'emptyStateAction',
  /** Used for work categories advanced select positions */
  companyProfile = 'companyProfile',
  companyProfileDialog = 'companyProfileDialog',
  addMoreWorkCategoriesToProfile = 'addMoreWorkCategoriesToProfile',
  marketplaceSearch = 'marketplaceSearch',
  /** Used in My Account -> Signature */
  signatureSettings = 'signatureSettings',
  /** Used in BidRequestLandingPage when changing agent own bid request status */
  menu = 'menu',
  mainCta = 'mainCta',
  bidRequestLandingPage = 'bidRequestLandingPage',
}

const AnalyticsEntryPointContext = createContext<ANALYTICS_ENTRY_POINT | undefined>(undefined);

export default AnalyticsEntryPointContext;
