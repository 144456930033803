import type { GraphQLError } from 'graphql';
import { get, has } from 'lodash';
import { assert } from 'ts-essentials';

import { isEmpty } from '~/shared/utils/javascript';

// We check whether data is empty as well because of a bug in Apollo:
// https://github.com/apollographql/react-apollo/issues/1314
export const isLoading = (queryObj: { loading: boolean; data: unknown | undefined }) =>
  queryObj.loading || isEmpty(queryObj.data);

interface MaybeLoading<T, U> {
  loading: boolean;
  data: T | undefined;
  previousData?: T | undefined;
  variables?: U | undefined;
}
interface NotLoading<T, U> {
  loading: false;
  data: T;
  previousData?: T | undefined;
  variables: U;
}
interface HasData<T, U> {
  loading: boolean;
  data: T;
  previousData: undefined;
  variables: U;
}
interface HasPreviousData<T, U> {
  loading: boolean;
  data: undefined;
  previousData: T;
  variables: U;
}

export const isDoneLoading = <T, U>(queryObj: MaybeLoading<T, U>): queryObj is NotLoading<T, U> =>
  !isLoading(queryObj);

/**
 * Use when you want to show a load indicator on initial fetch, but not on
 * subsequent updates.
 *
 * Note: May return stale data while new data is getting fetched.
 */
export const hasData = <T, U>(
  queryObj: MaybeLoading<T, U>,
): queryObj is HasData<T, U> | HasPreviousData<T, U> =>
  !isEmpty(queryObj.data) || !isEmpty(queryObj.previousData);

export const doesErrorMatchCode = (
  error: { graphQLErrors: readonly GraphQLError[] },
  code: string,
): boolean => error?.graphQLErrors.some((gqlError) => gqlError.extensions?.code === code);

export const getErrorExtension = <T>(
  error: { graphQLErrors: readonly GraphQLError[] },
  path: string,
): T => {
  const errorWithPath = error.graphQLErrors.find((gqlError) => has(gqlError.extensions, path));
  const extension = get(errorWithPath?.extensions, path);

  assert(extension, 'Unable to find error extension.');

  return extension as T;
};

// We cannot simply use `instanceof GraphQLError` because of
// https://github.com/apollographql/apollo-link/issues/1116
export const isGraphQLError = (
  error: Partial<GraphQLError> | null | undefined,
): error is GraphQLError => {
  const hasMessage = typeof error?.message === 'string';
  const hasPath = Array.isArray(error?.path);
  const hasCode = typeof error?.extensions?.code === 'string';
  return hasMessage && hasPath && hasCode;
};

interface ExponentialDelayOpts {
  /**
   * The default delay for each count, as well as the exact delay
   * before the first attempt is made (`count` = 0).
   */
  initialDelay: number;
  /**
   * The current retry attempt. 0-based.
   */
  count: number;
  /**
   * Can be used to alter the delay specific to the current count.
   * @default 1
   */
  factor?: number;
}

/**
 * @returns A delay in milliseconds that gets exponentially larger
 * the more retry attempts (`count`) are made.
 * See also https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#avoiding-thundering-herd
 */
export const getExponentialDelay = ({ count, initialDelay, factor = 1 }: ExponentialDelayOpts) => {
  const exponentialCount = count ** 2;

  // Delays specific to current count
  const currentDelay = factor * initialDelay;
  const currentExponentialDelay = currentDelay * exponentialCount;

  return initialDelay + currentExponentialDelay;
};
