import { HoverableElement } from '@cosuno/cosuno-ui';
import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

import { BeamerButton, BeamerButtonWrapper } from './Styles';

const BeamerTrigger: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HoverableElement isTriggerElementClickable tooltipText={t('tooltips.icons.features')}>
      <BeamerButtonWrapper>
        <BeamerButton className="beamerTrigger" onlyIcon={{ type: 'gift' }} />
      </BeamerButtonWrapper>
    </HoverableElement>
  );
};

export default BeamerTrigger;
