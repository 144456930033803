import type { LANGUAGE } from '~/__gql__/graphql';

type StringBoolean = 'true' | 'false';

interface Env {
  MODE: 'ci-deployment' | 'development' | 'e2e' | 'production' | 'test';
  REACT_APP_API_BASE: string;
  REACT_APP_GOOGLE_MAPS_API_KEY: string;
  REACT_APP_SEGMENT_WRITE_KEY?: string;
  REACT_APP_DEFAULT_LANG: LANGUAGE;
  REACT_APP_S3_BUCKET: string;
  REACT_APP_AWS_REGION: string;
  REACT_APP_CLOUDINARY_DOMAIN: string;
  REACT_APP_CLOUDINARY_CLOUD_NAME: string;
  REACT_APP_CYPRESS: StringBoolean;
  REACT_APP_RELEASE_HASH: string;
  REACT_APP_BEAMER_DISABLED?: StringBoolean;
  REACT_APP_BEAMER_API_KEY: string;
  REACT_APP_BEAMER_PRODUCT_ID: string;
  REACT_APP_RECAPTCHA_DISABLED?: StringBoolean;
  REACT_APP_RECAPTCHA_SITE_KEY?: string;
  REACT_APP_SENTRY_DSN?: string;
  REACT_APP_SENTRY_ORG_SLUG?: string;
  REACT_APP_PSPDFKIT_LICENSE_KEY?: string;
  REACT_APP_SUBCONTRACTOR_REVAMP_ROLLOUT_PERCENTAGE?: string;
  REACT_APP_IPINFO_TOKEN: string;
  REACT_APP_CELLO_SCRIPT_URL: string;
  REACT_APP_CELLO_PRODUCT_ID: string;
  REACT_APP_CELLO_DISABLED?: StringBoolean;
  REACT_APP_ENABLE_WHY_DID_YOU_RENDER?: StringBoolean;
  REACT_APP_ENABLE_ANALYTICS_IN_DEVELOPMENT?: string;
  REACT_APP_BILLING_PROVIDER_URL?: string;
  REACT_APP_BILLING_PROVIDER_PORTAL_URL?: string;
  REACT_APP_BILLING_MONTHLY_SUBCONTRACTOR_PREMIUM_SUBSCRIPTION_PLAN?: string;
  REACT_APP_BILLING_YEARLY_SUBCONTRACTOR_PREMIUM_SUBSCRIPTION_PLAN?: string;
  REACT_APP_BILLING_BUSINESS_ENTITY_ID?: string;
  REACT_APP_HIDE_EXPORT_ALL_DATA?: StringBoolean;
  REACT_APP_ENABLED_FEATURES?: string;
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string;
  REACT_APP_FULLSTORY_ORG_ID?: string;
}

export const env = import.meta.env as unknown as Env;
