import { Icon } from '@cosuno/cosuno-ui';
import React, { type ReactNode } from 'react';

import { castArray } from '~/shared/utils/javascript';

import { Message, MessageBox, StyledPageError } from './Styles';

interface PageErrorProps {
  message: string | string[] | ReactNode;
}

const PageError: React.FC<PageErrorProps> = ({ message }) => {
  const hasMultipleMessages = Array.isArray(message) && message.length > 1;
  const messageArr = castArray(message);

  return (
    <StyledPageError data-cy-page-error>
      <MessageBox>
        {messageArr.map((text, i) => (
          <Message key={i} isOnlyMessage={!hasMultipleMessages}>
            <Icon type="alert" size={24} />
            <span data-testid="message">{text}</span>
          </Message>
        ))}
      </MessageBox>
    </StyledPageError>
  );
};

export default PageError;
