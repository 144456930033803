import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { COMPANY_TYPE, type CurrentUserQuery, USER_STATUS } from '~/__gql__/graphql';
import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import { AUTH_STATUS, ERROR_TYPES } from '~/shared/constants';
import {
  AUTH_TOKEN_EXPIRES_AFTER_DURATION,
  INCREASED_SECURITY_FEATURES_AUTH_TOKEN_EXPIRES_AFTER_DURATION,
} from '~/shared/constants/authToken';
import type { LoginState } from '~/shared/contexts/authContext';
import { useContextlessAnalytics } from '~/shared/hooks/useAnalytics';
import useInactivityTracker from '~/shared/hooks/useInactivityTracker';
import useQueryParamBoolean from '~/shared/hooks/useQueryParamBoolean';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';
import { doesErrorMatchCode } from '~/shared/utils/apollo';
import { env } from '~/shared/utils/env';
import { detectLanguage, setCurrentLanguage } from '~/shared/utils/language';

import { useEffectOnce } from '../useEffectOnce';
import {
  queryCheckWorkspace,
  queryCurrentAdmin,
  queryCurrentUser,
  querySSORedirectUrl,
} from './api';
import type { AuthState, LoginParams, LoginWithSSOParams } from './types';
import { useUtmParametersTracking } from './useUtmParametersTracking';

type CurrentUser_me = CurrentUserQuery['me'];

const TRACK_AUTH_SIGN_IN_PARAM = 'trackAuthSignIn';

class AuthenticationError extends Error {
  constructor() {
    super('Authentication Error');
  }
}

const useAuthState = () => {
  const [authStatus, setAuthStatus] = useState<AUTH_STATUS>(AUTH_STATUS.authenticating);
  const { data: currentUser } = useQuery(queryCurrentUser, { fetchPolicy: 'cache-only' });

  const authState = useMemo<AuthState>(() => {
    if (authStatus !== AUTH_STATUS.authenticated) {
      return { status: authStatus };
    }

    if (!currentUser) {
      return { status: AUTH_STATUS.anonymous };
    }

    return {
      status: authStatus,
      companyType: COMPANY_TYPE[currentUser.me.company.type],
      user: currentUser.me,
    };
  }, [authStatus, currentUser]);

  return { authState, setAuthStatus };
};

const useAuthentication = () => {
  const { i18n } = useTranslation();
  const { authState, setAuthStatus } = useAuthState();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { trackPageview, trackUserIdentity, resetUserIdentity, trackEvent } =
    useContextlessAnalytics({
      isSuperAdmin,
      experiments: undefined,
      contextEntryPoint: undefined,
    });
  const [shouldTrackAuthSignIn, setShouldTrackAuthSignIn] = useState(false);
  useQueryParamBoolean(TRACK_AUTH_SIGN_IN_PARAM, setShouldTrackAuthSignIn);
  const [userIdentityTracked, setUserIdentityTracked] = useState(false);
  const routes = useRoutes();
  const lastLogoutTime = useRef<number | null>(null);
  const { setUtmParameterCookie, deleteUtmParametersCookie } = useUtmParametersTracking();

  const [currentUserQuery] = useLazyQuery(queryCurrentUser);

  if (shouldTrackAuthSignIn && userIdentityTracked) {
    trackEvent('authSignIn');
    setShouldTrackAuthSignIn(false);
  }

  const updateUser = useCallback(
    async (user: CurrentUser_me | null) => {
      if (!user) {
        setAuthStatus(AUTH_STATUS.anonymous);
        await setCurrentLanguage(detectLanguage(), i18n);
        return;
      }

      setAuthStatus(AUTH_STATUS.authenticated);
      await setCurrentLanguage(user.language, i18n);
    },
    [i18n, setAuthStatus],
  );

  /** Gets the auth token cookie */
  const authenticate = useCallback(
    async (credentials?: { loginToken: string; mfaToken?: string } | { userId: string }) => {
      const response = await axios.post(
        `${env.REACT_APP_API_BASE}/internal/auth/user`,
        credentials,
        {
          withCredentials: true,
          validateStatus: (status) => [200, 401].includes(status),
        },
      );

      if (response.status !== 200) {
        await updateUser(null);
        throw new AuthenticationError();
      }
    },
    [updateUser],
  );

  const login = useCallback(
    async ({
      loginToken,
      mfaToken,
      redirectTo = routes.root(),
      trackAuthSignIn = false,
    }: LoginParams) => {
      await authenticate({ loginToken, mfaToken });

      // prepare the redirect url
      const url = new URL(redirectTo, window.location.origin);
      // provide ability to detect that tracking "authSignIn" event required after resetting js context
      if (trackAuthSignIn) {
        url.searchParams.set(TRACK_AUTH_SIGN_IN_PARAM, 'true');
      }

      // reset js context to get rid of any active tracking tools
      // https://github.com/cosuno/cosuno/pull/2094#discussion_r622360335
      // Setting href instead of pathname allows us to pass query params.
      window.location.href = url.toString();
    },
    [authenticate, routes],
  );

  const logout = useCallback(
    async (options?: {
      openRoute?: string;
      query?: string;
      redirectBack?: boolean;
      loginState?: LoginState;
    }) => {
      const pathnameBeforeLogout = window.location.pathname;

      // Invalidates cookie in DB and deletes it on the client
      try {
        await axios.delete(`${env.REACT_APP_API_BASE}/internal/auth/user`, {
          withCredentials: true,
        });
        // eslint-disable-next-line no-empty
      } catch {}

      resetUserIdentity();
      await updateUser(null);
      void apolloClient.clearStore();
      lastLogoutTime.current = Date.now();

      const openRoute = options?.openRoute || routes.login();
      history.push({
        pathname: openRoute,
        search: options?.query,
        state: {
          ...options?.loginState,
          redirect: options?.redirectBack ? pathnameBeforeLogout : undefined,
        },
      });
    },
    [resetUserIdentity, updateUser, apolloClient, routes, history],
  );

  const inactivityTracker = useInactivityTracker({
    onInactive: () =>
      logout({
        query: '?showInactivityMessage=true',
        redirectBack: true,
      }),
  });

  const isInactivityTrackerEnabled =
    authState.status === AUTH_STATUS.authenticated &&
    isCompanyFeatureEnabled(authState.user.company, 'featureIncreasedSecurityFeatures');

  useEffect(() => {
    // If the user is logged out, we don't know whether increased security features are enabled,
    // so we always stop the inactivity tracker.
    if (authState.status !== AUTH_STATUS.authenticated) {
      inactivityTracker.stop();
      return;
    }

    if (isInactivityTrackerEnabled) {
      inactivityTracker.start();
    }

    return inactivityTracker.stop;
    // Careful: Ensure these dependencies are stable, otherwise they will make the inactivity
    // tracker unreliable and cause flaky tests.
  }, [authState.status, isInactivityTrackerEnabled, inactivityTracker]);

  const fetchCurrentUser = useCallback(
    async (options: { trackUser?: boolean } = { trackUser: true }) => {
      const operationStartTime = Date.now();
      const response = await currentUserQuery();

      if (lastLogoutTime.current && operationStartTime <= lastLogoutTime.current) return;

      if (response.error) {
        if (doesErrorMatchCode(response.error, ERROR_TYPES.unauthenticated)) {
          // If we are unauthenticated, we reset the user identitiy and unset the current user.
          // We do not trigger logout because it may be that we are in a context where no
          // authentication is needed.
          resetUserIdentity();
          await updateUser(null);
          return;
        }
      }

      if (!response.data) {
        return;
      }

      await updateUser(response.data.me);

      if (options.trackUser) {
        trackUserIdentity(response.data.me);
        setUserIdentityTracked(true);
      }

      deleteUtmParametersCookie();
    },
    [currentUserQuery, resetUserIdentity, updateUser, deleteUtmParametersCookie, trackUserIdentity],
  );

  const fetchCurrentAdmin = useCallback(async (): Promise<boolean> => {
    const { data } = await apolloClient.query({
      query: queryCurrentAdmin,
    });

    if (data.adminMe) {
      setIsSuperAdmin(true);
      return true;
    }

    return false;
  }, [apolloClient]);

  // Re-authenticate on mount
  useEffectOnce(() => {
    setUtmParameterCookie();

    void authenticate()
      .catch(() => {})
      .then(() => fetchCurrentAdmin())
      .then((isSuperAdminResult) =>
        fetchCurrentUser({ trackUser: !isSuperAdminResult })
          .catch(() => {})
          // We want to call trackPageview after trackUserIdentity, so Segment doesn't interpret the
          // session as anonymous.
          .finally(() => {
            if (!isSuperAdminResult) trackPageview();
          }),
      );
  });

  // Periodically re-authenticate (generates new auth token and updates user)
  useEffect(() => {
    if (authState.status !== AUTH_STATUS.authenticated) return undefined;

    const tokenDurationMilliseconds = isCompanyFeatureEnabled(
      authState.user.company,
      'featureIncreasedSecurityFeatures',
    )
      ? INCREASED_SECURITY_FEATURES_AUTH_TOKEN_EXPIRES_AFTER_DURATION.asMilliseconds()
      : AUTH_TOKEN_EXPIRES_AFTER_DURATION.asMilliseconds();
    const refreshIntervalMilliseconds = tokenDurationMilliseconds / 2;

    const intervalId = setInterval(
      () =>
        authenticate()
          .then(() => fetchCurrentUser())
          .catch(() => {}),
      refreshIntervalMilliseconds,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [authState, authenticate, fetchCurrentUser]);

  const loginWithSSO = useCallback(
    async (params: LoginWithSSOParams) => {
      const { data } = await apolloClient.query({
        query: querySSORedirectUrl,
        variables: params,
      });

      window.location.href = data.ssoRedirectUrl;
    },
    [apolloClient],
  );

  const switchToWorkspace = useCallback(
    async (workspaceId: string, redirectTo?: string) => {
      if (authState.status !== AUTH_STATUS.authenticated) {
        return;
      }

      const ssoRequired = Boolean(
        authState.user.workspaces.find(({ id }) => id === workspaceId)?.ssoEnabled,
      );

      if (ssoRequired) {
        return loginWithSSO({
          email: authState.user.email,
          expectedUserStatus: USER_STATUS.active,
          redirectPath: redirectTo,
          workspaceId,
        });
      }

      const {
        data: {
          checkWorkspace: { loginToken },
        },
      } = await apolloClient.query({
        query: queryCheckWorkspace,
        variables: { workspaceId },
      });

      await login({ loginToken, redirectTo });
    },
    [login, apolloClient, authState, loginWithSSO],
  );

  return useMemo(
    () => ({
      authState,
      isSuperAdmin,
      login,
      authenticate,
      logout,
      fetchCurrentUser,
      loginWithSSO,
      switchToWorkspace,
      deleteUtmParametersCookie,
    }),
    [
      authState,
      isSuperAdmin,
      login,
      authenticate,
      logout,
      fetchCurrentUser,
      loginWithSSO,
      switchToWorkspace,
      deleteUtmParametersCookie,
    ],
  );
};

export default useAuthentication;

export type { AuthState, LoginParams, LoginWithSSOParams };
