import React, { type PropsWithChildren } from 'react';

import ConfirmationModal from '~/shared/components/ConfirmationModal';
import { Dialog } from '~/shared/components/ModalStyles';
import useModalState from '~/shared/hooks/useModalState';
import {
  NavigationConfirmationPromptContext,
  useNavigationConfirmationPrompt,
} from '~/shared/hooks/useNavigationConfirmation';
import useTranslation from '~/shared/hooks/useTranslation';

const NavigationConfirmationPrompt: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();

  const { isDisplayed, cancel, discard, save, confirmNavigation } =
    useNavigationConfirmationPrompt();

  const modalState = useModalState({
    isOpen: isDisplayed,
  });

  return (
    <>
      <ConfirmationModal
        {...modalState}
        title={t('navigationConfirmation:title')}
        message={t('navigationConfirmation:message')}
        confirmText={t('navigationConfirmation:saveButton')}
        cancelText={t('common:cancel')}
        onConfirm={async ({ closeModal }) => {
          await save();
          closeModal();
        }}
        onCancel={() => cancel()}
        renderExtraButtons={() => (
          <Dialog.ControlButton hollow onClick={() => discard()}>
            {t('navigationConfirmation:discardButton')}
          </Dialog.ControlButton>
        )}
      />
      <NavigationConfirmationPromptContext.Provider value={{ confirmNavigation }}>
        {children}
      </NavigationConfirmationPromptContext.Provider>
    </>
  );
};

export default NavigationConfirmationPrompt;
