import { COUNTRY_CODE, STATE_CODE } from '~/__gql__/graphql';
import { KEY_NAME } from '~/shared/constants';

import type { Address } from '.';

// Unfortunately, Google returns German state codes that do not follow ISO 3166-2:DE.
// Therefore, we need to define this mapping.
const stateCodeMap: Record<string, STATE_CODE> = {
  BB: STATE_CODE.DE_BB,
  BE: STATE_CODE.DE_BE,
  BW: STATE_CODE.DE_BW,
  BY: STATE_CODE.DE_BY,
  HB: STATE_CODE.DE_HB,
  HE: STATE_CODE.DE_HE,
  HH: STATE_CODE.DE_HH,
  MV: STATE_CODE.DE_MV,
  NDS: STATE_CODE.DE_NI,
  NRW: STATE_CODE.DE_NW,
  RP: STATE_CODE.DE_RP,
  SA: STATE_CODE.DE_ST,
  SH: STATE_CODE.DE_SH,
  SL: STATE_CODE.DE_SL,
  SN: STATE_CODE.DE_SN,
  TH: STATE_CODE.DE_TH,
};

export const selectFirstOnEnter = (inputEl: HTMLInputElement) => {
  const originalAddEventListener = inputEl.addEventListener;
  const originalRemoveEventListener = inputEl.removeEventListener;

  const listenerMap = new Map<
    string,
    WeakMap<EventListenerOrEventListenerObject, EventListenerOrEventListenerObject>
  >();

  const addEventListenerWrapper = (
    type: string,
    originalListener: EventListener,
    options?: boolean | AddEventListenerOptions,
  ) => {
    if (type !== 'keydown') {
      // hot path optimization
      originalAddEventListener.apply(inputEl, [type, originalListener, options]);
      return;
    }

    // Simulate an ArrowDown keydown event if no address has been selected
    const modifiedListener = (event: KeyboardEvent) => {
      if (event.key !== KEY_NAME.enter) {
        originalListener.apply(inputEl, [event]);
        return;
      }

      // Important: We need to prevent the default behavior on enter to avoid submitting the form
      // which contains this LocationSearch
      event.preventDefault();

      const selectedSuggestions = document.getElementsByClassName('pac-item-selected').length;
      if (selectedSuggestions > 0) {
        originalListener.apply(inputEl, [event]);
        return;
      }

      const suggestions = document.getElementsByClassName('pac-item').length;
      // only simulate a arrow down press when we actually have suggestions to select from
      if (suggestions > 0) {
        const simulatedArrowDownEvent = new KeyboardEvent('keydown', {
          key: 'ArrowDown',
          code: 'ArrowDown',
          keyCode: 40,
        } as unknown as KeyboardEventInit);

        originalListener.apply(inputEl, [simulatedArrowDownEvent]);
      } else {
        originalListener.apply(inputEl, [event]);
      }
    };

    if (!listenerMap.has(type)) {
      listenerMap.set(type, new WeakMap<EventListener, EventListener>());
    }
    listenerMap
      .get(type)
      ?.set(originalListener, modifiedListener as EventListenerOrEventListenerObject);

    originalAddEventListener.apply(inputEl, [
      type,
      modifiedListener as EventListenerOrEventListenerObject,
      options,
    ]);
  };

  const removeEventListenerWrapper = (
    type: string,
    originalListener: EventListener,
    options?: boolean | EventListenerOptions,
  ) => {
    if (type !== 'keydown') {
      // hot path optimization
      originalRemoveEventListener.apply(inputEl, [type, originalListener, options]);
    }

    // check if we have a ref to our overwritten listener to remove the wrapper accordingly
    const modifiedListener = listenerMap.get(type)?.get(originalListener);
    if (modifiedListener) {
      listenerMap.get(type)?.delete(originalListener);
      originalRemoveEventListener.apply(inputEl, [type, modifiedListener, options]);
    } else {
      originalRemoveEventListener.apply(inputEl, [type, originalListener, options]);
    }
  };

  inputEl.addEventListener = addEventListenerWrapper;
  inputEl.removeEventListener = removeEventListenerWrapper;
};

export const parseAddressComponents = (
  addressComponents?: google.maps.GeocoderAddressComponent[],
): Address => {
  if (!addressComponents) {
    return {
      street: null,
      postalCode: null,
      city: null,
      countryCode: null,
    };
  }

  const addressComponentsMap: Record<string, google.maps.GeocoderAddressComponent> = {};
  for (const component of addressComponents) {
    for (const type of component.types) {
      addressComponentsMap[type] = component;
    }
  }
  const { locality, street_number, route, country, postal_code, premise } = addressComponentsMap;

  return {
    street:
      [route?.long_name, street_number?.long_name].filter(Boolean).join(' ') ||
      premise?.long_name ||
      null,
    postalCode: postal_code?.long_name || null,
    city: locality?.long_name || null,
    countryCode: isCountryCode(country?.short_name) ? country.short_name : null,
  };
};

const isCountryCode = (maybeCountryCode?: string): maybeCountryCode is COUNTRY_CODE =>
  maybeCountryCode !== undefined && maybeCountryCode in COUNTRY_CODE;

const isStateCode = (maybeStateCode: string): maybeStateCode is keyof typeof stateCodeMap =>
  maybeStateCode in stateCodeMap;

export const parseCountryAndStateCodes = (
  result: google.maps.places.PlaceResult,
): {
  countryCode: COUNTRY_CODE | null;
  stateCode: STATE_CODE | null;
} => {
  const countryComponent = result.address_components?.find((component) =>
    component.types.includes('country'),
  );
  const countryCode = countryComponent?.short_name ?? null;

  if (!countryCode || !isCountryCode(countryCode)) {
    return { countryCode: null, stateCode: null };
  }
  if (countryCode !== COUNTRY_CODE.DE) {
    return { countryCode, stateCode: null };
  }

  const stateComponent = result.address_components?.find((component) =>
    component.types.includes('administrative_area_level_1'),
  );
  const stateCode = stateComponent?.short_name ?? null;

  if (!stateCode || !isStateCode(stateCode)) {
    return { countryCode, stateCode: null };
  }

  return { countryCode, stateCode: stateCodeMap[stateCode] };
};
