import { Button } from '@cosuno/cosuno-ui';
import React from 'react';

import MessagePage from '~/shared/components/MessagePage';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';

export const PageNotFoundAuthenticated: React.FC = () => {
  const { t } = useTranslation('notFound');
  const routes = useRoutes();

  return (
    <MessagePage
      data-cy-404-page
      is404
      isAuthenticated
      pageTitle={t('pageTitles:notFound')}
      heading={t('heading')}
      message={t('message')}
      renderAction={() => <Button linkTo={routes.root()}>{t('home')}</Button>}
    />
  );
};

const PageNotFoundUnauthenticated: React.FC = () => {
  const { t } = useTranslation('notFound');
  const routes = useRoutes();

  return (
    <MessagePage
      data-cy-404-page
      is404
      isAuthenticated={false}
      pageTitle={t('pageTitles:notFound')}
      heading={t('heading')}
      message={t('message')}
      renderAction={() => (
        <div>
          <Button linkTo={routes.root()}>{t('home')}</Button>
        </div>
      )}
    />
  );
};

const RequestWithdrawn: React.FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  const { t } = useTranslation('notFound');
  const routes = useRoutes();

  return (
    <MessagePage
      data-cy-404-page
      isAuthenticated={isAuthenticated}
      pageTitle={t('pageTitles:requestWithdrawn')}
      heading={t('requestWithdrawnHeading')}
      message={t('requestWithdrawnMessage')}
      renderAction={() => isAuthenticated && <Button linkTo={routes.root()}>{t('home')}</Button>}
    />
  );
};

export default {
  Authenticated: PageNotFoundAuthenticated,
  Unauthenticated: PageNotFoundUnauthenticated,
  RequestWithdrawn,
};
