import { COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font } from '~/shared/utils/styles';

export const RequiredFieldsNoteWrapper = styled.div`
  margin: 25px 0;
  color: ${COLOR.textTertiary};
  ${font.size(13)};
`;
