import { useCallback, useDebugValue, useEffect } from 'react';

import type { KEY_NAME } from '~/shared/constants';
import { isKeyPressed } from '~/shared/utils/keyboard';

const useKeyboardEventCallback = (
  element: Node | Window | null,
  keyCode: KEY_NAME | KEY_NAME[],
  callback: (event: KeyboardEvent) => void,
  { condition = true, preventDefault = false } = {},
): void => {
  useDebugValue(`key: ${keyCode.toString()}`);

  const handleEvent = useCallback(
    (event: Event) => {
      if (!isKeyPressed(event as KeyboardEvent, keyCode)) {
        return;
      }

      if (preventDefault) {
        event.preventDefault();
      }

      callback(event as KeyboardEvent);
    },
    [keyCode, callback, preventDefault],
  );

  useEffect(() => {
    if (element && condition) {
      element.addEventListener('keydown', handleEvent);
    }

    return () => {
      if (element) {
        element.removeEventListener('keydown', handleEvent);
      }
    };
  }, [condition, element, handleEvent]);
};

export default useKeyboardEventCallback;
