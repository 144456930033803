import { COLOR, GRADIENT, textStyles } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  background: ${GRADIENT.gradientLight};
  padding: 20px;
  min-height: 70px;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 1px solid ${COLOR.borderLight};
  color: ${COLOR.textPrimary};
  ${textStyles.labelLarge500};
  display: flex;
`;

export const ScrollableContent = styled.div<{
  $horizontallyScrollable?: boolean;
  $verticallyScrollable?: boolean;
}>`
  flex: 1;

  ${({ $horizontallyScrollable }) =>
    $horizontallyScrollable &&
    css`
      overflow-x: auto;
    `}

  ${({ $verticallyScrollable }) =>
    $verticallyScrollable &&
    css`
      overflow-y: auto;
    `}
`;

export const Footer = styled.div<{ $showBorder?: boolean }>`
  padding: 15px;
  flex-shrink: 0;

  ${({ $showBorder = true }) =>
    $showBorder &&
    css`
      border-top: 1px solid ${COLOR.borderMedium};
    `}
`;
