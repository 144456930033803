import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { LANGUAGE } from '~/__gql__/graphql';
import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useQueryParamString from '~/shared/hooks/useQueryParamString';
import { env } from '~/shared/utils/env';

const defaultLanguage = LANGUAGE.en;
const supportedLanguages = [LANGUAGE.en, LANGUAGE.de];

declare let window: Window & {
  cello?: {
    cmd: Array<
      (cello: {
        boot: (options: unknown) => void;
        open: () => void;
        changeLanguage: (language: string) => void;
      }) => void
    >;
  };
};

const CelloScript: React.FC = () => {
  const user = useCurrentUser();

  const [openOnLaunch, setOpenOnLaunch] = useState(false);
  const [celloLoaded, setCelloLoaded] = useState(false);

  const { trackEvent } = useAnalytics();

  const isCelloEnabled = isCompanyFeatureEnabled(user?.company, 'conditionalCelloWidget');

  useEffect(() => {
    if (!user || !isCelloEnabled || env.REACT_APP_CELLO_DISABLED === 'true') {
      return;
    }

    window.cello ||= { cmd: [] };

    const celloLanguage = supportedLanguages.includes(user.language)
      ? user.language
      : defaultLanguage;

    if (celloLoaded) {
      window.cello.cmd.push((cello) => cello.changeLanguage(celloLanguage));
      return;
    }

    window.cello.cmd.push((cello) =>
      cello.boot({
        productId: env.REACT_APP_CELLO_PRODUCT_ID,
        token: user.celloToken,
        customLauncherSelector: '.celloTrigger',
        hideDefaultLauncher: true,
        showOnBoot: true,
        language: celloLanguage,
        onOpen: () => trackEvent('topNavigationRecommendCosuno'),
      }),
    );

    if (openOnLaunch) {
      window.cello.cmd.push((cello) => cello.open());
    }

    setCelloLoaded(true);
  }, [user, openOnLaunch, celloLoaded, isCelloEnabled, trackEvent]);

  useQueryParamString('cello', (value) => {
    if (value === 'open') {
      setOpenOnLaunch(true);
    }
  });

  if (env.REACT_APP_CELLO_DISABLED === 'true') {
    return null;
  }

  return (
    <Helmet>
      <script type="module" src={env.REACT_APP_CELLO_SCRIPT_URL} async />
    </Helmet>
  );
};

export default CelloScript;
