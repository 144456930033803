import type { Accept } from 'react-dropzone';

export type FileExtension = `.${string}`;
export type MimeType = `${string}/${string}`;

/**
 * Returns an object in the format accepted by `react-dropzone` that will only
 * accept the provided file extensions.
 */
export const getAcceptConfigFromExtensions = (
  fileExtensions: readonly FileExtension[],
): Accept => ({
  // This hack seems to work by allowing only the specified extensions,
  // belonging to any mime type.
  'application/unspecified': fileExtensions as string[],
});

/**
 * Returns an object in the format accepted by `react-dropzone` that will accept
 * files with any extensions matching the provided mime type.
 */
export const getAcceptConfigFromMimeTypes = (mimeTypes: readonly MimeType[]): Accept =>
  Object.fromEntries(mimeTypes.map((mimeType) => [mimeType, []]));
