import { useMutation } from '@apollo/client';
import { Button, HoverableElement, PageLoader, Portal, useScrollLock } from '@cosuno/cosuno-ui';
import React, { useContext, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import InfiniteScrollWithFetch from '~/shared/components/InfiniteScrollWithFetch';
import QueryErrors from '~/shared/components/QueryErrors';
import { KEY_NAME } from '~/shared/constants';
import CombinedNotificationsContext from '~/shared/contexts/combinedNotificationsContext';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useClickOutside from '~/shared/hooks/useClickOutside';
import useKeyboardEventCallback from '~/shared/hooks/useKeyboardEventCallback';
import useTranslation from '~/shared/hooks/useTranslation';
import { isDoneLoading } from '~/shared/utils/apollo';

import { ButtonWithCountBadgeWrapper, CountBadge } from '../Styles';
import { getBadgeNumberWithMax3Digits } from '../utils';
import { mutationMarkAllNotificationsAsRead } from './api';
import Notification from './NotificationItem';
import {
  EmptyPlaceholder,
  EmptyPlaceholderExplanation,
  EmptyPlaceholderTitle,
  Header,
  Overlay,
  TRANSITION_DURATION,
} from './Styles';

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const { combinedNotificationsResponse } = useContext(CombinedNotificationsContext);

  const totalUnread = getBadgeNumberWithMax3Digits(
    combinedNotificationsResponse?.data?.notifications.totalUnread,
  );

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };
  const toggleOverlay = () => setIsOverlayOpen((prevIsOpen) => !prevIsOpen);

  const [markAllAsRead] = useMutation(mutationMarkAllNotificationsAsRead, {
    refetchQueries: ['CombinedNotifications'],
  });

  const markAllNotificationsAsRead = () => {
    trackEvent('notificationsMarkRead');
    void markAllAsRead();
  };

  const overlayRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  useClickOutside([overlayRef, triggerRef], closeOverlay, { condition: isOverlayOpen });

  useScrollLock(isOverlayOpen, 'notifications');

  useKeyboardEventCallback(document, KEY_NAME.escape, closeOverlay, {
    condition: isOverlayOpen,
  });

  const renderNotifications = () => {
    if (!combinedNotificationsResponse || !isDoneLoading(combinedNotificationsResponse)) {
      return <PageLoader />;
    }

    if (combinedNotificationsResponse.error) {
      return <QueryErrors error={combinedNotificationsResponse.error} />;
    }

    const {
      notifications: { notifications, totalRecords },
    } = combinedNotificationsResponse.data;

    if (!notifications.length) {
      return (
        <EmptyPlaceholder>
          <EmptyPlaceholderTitle>{t('notifications.emptyPlaceholderTitle')}</EmptyPlaceholderTitle>
          <EmptyPlaceholderExplanation>
            {t('notifications.emptyPlaceholderExplanation')}
          </EmptyPlaceholderExplanation>
        </EmptyPlaceholder>
      );
    }

    return (
      <InfiniteScrollWithFetch
        itemsCount={notifications.length}
        totalRecords={totalRecords}
        fetchMore={combinedNotificationsResponse.fetchMore}
        variables={combinedNotificationsResponse.variables}
      >
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} onClick={closeOverlay} />
        ))}
      </InfiniteScrollWithFetch>
    );
  };

  return (
    <>
      <HoverableElement isTriggerElementClickable tooltipText={t('tooltips.icons.notifications')}>
        <ButtonWithCountBadgeWrapper data-cy-notification-overlay-trigger>
          <Button
            forwardRef={triggerRef}
            variant="secondary"
            onClick={() => {
              trackEvent('notificationsOpen');
              toggleOverlay();
            }}
            onlyIcon={{ type: 'bell' }}
          />
          {totalUnread ? (
            <CountBadge data-cy-notification-count-badge>{totalUnread}</CountBadge>
          ) : null}
        </ButtonWithCountBadgeWrapper>
      </HoverableElement>
      <Portal>
        <Transition in={isOverlayOpen} timeout={TRANSITION_DURATION} unmountOnExit>
          {(state) => (
            <Overlay
              ref={overlayRef}
              $transitionState={state}
              $triggerCenterX={
                triggerRef.current !== null
                  ? triggerRef.current.getBoundingClientRect().x +
                    triggerRef.current.getBoundingClientRect().width / 2
                  : null
              }
              data-cy-notification-overlay
            >
              <Header>
                {t('notifications.header')}
                <Button
                  data-cy-notification-overlay-mark-all-as-read
                  variant="secondary"
                  onClick={markAllNotificationsAsRead}
                >
                  {t('notifications.markAllAsRead')}
                </Button>
              </Header>
              {renderNotifications()}
            </Overlay>
          )}
        </Transition>
      </Portal>
    </>
  );
};

export default Notifications;
