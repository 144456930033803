import { createContext } from 'react';

import type { DownloadPreparationManagerMethods } from '~/shared/hooks/useDownloadPreparationManager';

const DownloadPreparationContext = createContext<DownloadPreparationManagerMethods>({
  startDownloadPreparation: () => {},
  stopDownloadPreparation: () => {},
  getIsPreparingDownload: () => false,
  isPreparingAnyDownload: false,
});

export default DownloadPreparationContext;
