import { COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font } from '~/shared/utils/styles';

export const AuthenticatedWrapper = styled.div`
  margin: 50px auto 0;
  max-width: 500px;
  padding: 45px 50px;
  text-align: center;
  border-radius: 4px;
  background: ${COLOR.backgroundLight};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface HeadingProps {
  is404: boolean;
}

export const Heading = styled.h1<HeadingProps>`
  ${textStyles.titleMedium700}
  color: ${COLOR.textPrimary};
  margin: 0;

  ${(props) => props.is404 && font.size(45)};
`;

export const Message = styled.p`
  color: ${COLOR.textSecondary};
  ${textStyles.bodyMedium450}
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
