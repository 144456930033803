import { isInternalImageUrl } from '@cosuno/cosuno-ui';
import type { PasteRule } from '@remirror/pm/paste-rules';
import {
  type ApplySchemaAttributes,
  type CommandFunction,
  type ExtensionCommandReturn,
  isElementDomNode,
  type NodeExtensionSpec,
  type NodeSpecOverride,
} from 'remirror';
import { ImageExtension as RemirrorImageExtension } from 'remirror/extensions';

import type { TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import type { MimeType } from '~/shared/utils/acceptFiles';

import type { FileWithProgress } from './types';
import { uploadImageToExternalStorage } from './utils';

class ImageExtension extends RemirrorImageExtension {
  constructor(
    private readonly supportedImageMimeTypes: MimeType[],
    t: TypeSafeTFunction,
  ) {
    super({
      uploadHandler: (files: FileWithProgress[]) =>
        files.map((fileWithProgress) => () => uploadImageToExternalStorage(fileWithProgress, t)),
    });
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...super.createNodeSpec(extra, override),
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: (element) => {
            if (!isElementDomNode(element)) {
              return {};
            }

            const attrs = this.getImageAttributes({ element, parse: extra.parse });

            if (
              attrs.src &&
              (attrs.src.startsWith('data:') ||
                attrs.src.startsWith('file:///') ||
                !isInternalImageUrl(attrs.src))
            ) {
              return false;
            }

            return attrs;
          },
        },
        ...(override.parseDOM ?? []),
      ],
    };
  }

  getImageAttributes({
    element,
    parse,
  }: {
    element: HTMLElement;
    parse: ApplySchemaAttributes['parse'];
  }) {
    return {
      ...parse(element),
      alt: element.getAttribute('alt') ?? '',
      src: element.getAttribute('src') ?? null,
      fileName: element.getAttribute('data-file-name') ?? null,
    };
  }

  createPasteRules(): PasteRule[] {
    return [
      {
        type: 'file',
        fileHandler: (props) => {
          const imageFiles = props.files.filter((file) =>
            (this.supportedImageMimeTypes as string[]).includes(file.type),
          );

          if (imageFiles.length === 0) {
            // return true to prevent other file handlers to process these files
            // some of the default file handlers allow pasting non-image files (e.g. `.pdf`)
            // but it pastes them as icons of those files
            return true;
          }

          // if clipboard data contains text, we should prefer text to images
          // see `ImageOptions.preferPastedTextContent` in the original extension
          if (props.type === 'paste' && props.event.clipboardData?.getData('text/plain')) {
            // return true to prevent other file handlers to process these files
            return true;
          }

          const position = props.type === 'drop' ? props.pos : undefined;

          const { commands } = this.store;

          commands.addImageFiles(imageFiles, position);

          return true;
        },
      },
    ];
  }

  createCommands(): ExtensionCommandReturn {
    return {
      ...(super.createCommands?.() ?? {}),
      addImageFiles:
        (files: File[], position?: number): CommandFunction =>
        () => {
          const { uploadHandler } = this.options;

          const { commands, chain } = this.store;
          const filesWithProgress: FileWithProgress[] = files.map((file, index) => ({
            file,
            progress: (progress) => {
              commands.updatePlaceholder(uploads[index], progress);
            },
          }));

          const uploads = uploadHandler(filesWithProgress);

          if (position) {
            chain.selectText(position);
          }

          for (const upload of uploads) {
            chain.uploadImage(upload);
          }

          chain.focus();

          chain.run();

          return true;
        },
    };
  }
}

export default ImageExtension;
