import { EmptyContent } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const PlanCardsWrapper = styled.div<{ $stretchCards: boolean }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(280px, ${(props) => (props.$stretchCards ? 'max-content' : 'min-content')})
  );
  grid-template-rows: repeat(3, auto);
  gap: 30px;
`;

export const StyledEmptyContent = styled(EmptyContent)`
  padding-bottom: 0;
`;
