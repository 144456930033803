import { usePrevious } from '@cosuno/cosuno-ui';
import { useEffect, useState } from 'react';

export function usePageVisibility() {
  const [isPageVisible, setIsPageVisible] = useState(() => !document.hidden);
  const onVisibilityChange = () => setIsPageVisible(() => !document.hidden);
  const wasPagePreviouslyVisible = usePrevious(isPageVisible);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  });

  return { isPageVisible, wasPagePreviouslyVisible };
}
