import { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { COMPANY_TYPE, type CurrentUserQuery } from '~/__gql__/graphql';
import { AUTH_STATUS } from '~/shared/constants';
import { PROJECTS_ROUTE_TYPE } from '~/shared/constants/routes';
import AuthContext, {
  type AuthContextBase,
  isAnonymous,
  isAuthenticated,
} from '~/shared/contexts/authContext';
import useRoutes from '~/shared/hooks/useRoutes';

import { queryStringToObject, urlToPathname } from '../utils/url';

interface UserAuthState {
  isUserPrincipal: boolean;
  isUserAgent: boolean;
  isUserAuthenticated: boolean;
  isUserAuthenticating: boolean;
  isUserAnonymous: boolean;
  companyType: COMPANY_TYPE | undefined;
  userBaseUrl: string;
  currentUser: CurrentUserQuery['me'] | null;
}

const useUserBaseUrl = ({
  isUserAnonymous,
  isUserPrincipal,
  isUserAgent,
  isActiveCompanyOnboarding,
}: {
  isUserAnonymous: boolean;
  isUserPrincipal: boolean;
  isUserAgent: boolean;
  isActiveCompanyOnboarding: boolean;
}) => {
  const routes = useRoutes();
  const location = useLocation();
  const isLoginPage = useRouteMatch(routes.login());

  const { redirect } = queryStringToObject<{ redirect?: string }>(location.search);
  // remove the host from url to ensure no malicious redirects affect the app
  const redirectQuery = urlToPathname(redirect ?? '');

  if (redirectQuery) {
    return redirectQuery;
  }

  if (isUserPrincipal && isActiveCompanyOnboarding) {
    return routes.companyOnboarding();
  }

  if (isUserAgent && isActiveCompanyOnboarding) {
    return routes.agentCompanyOnboarding();
  }

  if (isUserAnonymous && !isLoginPage) return routes.login();

  if (isUserAgent) return routes.agentBidRequests();

  if (isUserPrincipal) return routes.projects(PROJECTS_ROUTE_TYPE.open);

  return routes.root();
};

const useUserAuthState = (): UserAuthState => {
  const authContext = useContext<AuthContextBase>(AuthContext);

  const isUserAuthenticated = isAuthenticated(authContext);

  const isUserAnonymous = isAnonymous(authContext);

  const isUserAuthenticating = authContext.authState.status === AUTH_STATUS.authenticating;

  const isUserPrincipal =
    isAuthenticated(authContext) && authContext.authState.companyType === COMPANY_TYPE.principal;

  const isUserAgent =
    isAuthenticated(authContext) && authContext.authState.companyType === COMPANY_TYPE.agent;

  const companyType = isAuthenticated(authContext) ? authContext.authState.companyType : undefined;

  const userBaseUrl = useUserBaseUrl({
    isActiveCompanyOnboarding:
      (authContext.authState.status === AUTH_STATUS.authenticated &&
        authContext.authState.user.isActiveCompanyOnboarding) ??
      false,
    isUserAgent,
    isUserAnonymous,
    isUserPrincipal,
  });

  return {
    isUserPrincipal,
    isUserAgent,
    isUserAuthenticated,
    isUserAuthenticating,
    isUserAnonymous,
    companyType,
    userBaseUrl,
    currentUser: isUserAuthenticated ? authContext.authState.user : null,
  };
};

export default useUserAuthState;
