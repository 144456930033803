import { createContext } from 'react';

import { AUTH_STATUS } from '~/shared/constants';
import type { AuthState, LoginParams, LoginWithSSOParams } from '~/shared/hooks/useAuthentication';

export interface LoginState {
  withSuggestions?: {
    subcontractorId?: string;
    bidRequestId?: string;
  };
  title?: string;
  explanation?: string;
  offerSignup?: { companyName: string | null };
}

export interface AuthContextBase {
  login: (loginParams: LoginParams) => Promise<void>;
  authenticate: (options: { loginToken: string; mfaToken?: string }) => Promise<void>;
  logout: (options?: {
    openRoute?: string;
    query?: string;
    redirectBack?: boolean;
    loginState?: LoginState;
  }) => void;
  fetchCurrentUser: (options?: { trackUser?: boolean }) => Promise<void>;
  authState: AuthState;
  isSuperAdmin: boolean;
  loginWithSSO: (loginParams: LoginWithSSOParams) => Promise<void>;
  switchToWorkspace: (workspaceId: string, redirectTo?: string) => Promise<void>;
  deleteUtmParametersCookie: () => void;
}

interface AuthContextAnonymous extends AuthContextBase {
  authState: Extract<AuthState, { status: AUTH_STATUS.anonymous }>;
}

interface AuthContextAuthenticated extends AuthContextBase {
  authState: Extract<AuthState, { status: AUTH_STATUS.authenticated }>;
}

export const isAuthenticated = (
  authContext: AuthContextBase,
): authContext is AuthContextAuthenticated =>
  authContext.authState.status === AUTH_STATUS.authenticated;

export const isAnonymous = (authContext: AuthContextBase): authContext is AuthContextAnonymous =>
  authContext.authState.status === AUTH_STATUS.anonymous;

const AuthContext = createContext<AuthContextBase>({
  authState: { status: AUTH_STATUS.anonymous },
  isSuperAdmin: false,
  login: () => Promise.resolve(),
  authenticate: () => Promise.resolve(),
  logout: () => undefined,
  fetchCurrentUser: () => Promise.resolve(),
  loginWithSSO: () => Promise.resolve(),
  switchToWorkspace: () => Promise.resolve(),
  deleteUtmParametersCookie: () => undefined,
});

export default AuthContext;
