import { Input, type InputProps } from '@cosuno/cosuno-ui';
import { useCombobox } from 'downshift';
import React, { useState } from 'react';

import { useDebouncedCallback } from '~/packages/debounce';

import { HighlightedSubstrings } from './HighlightedSubstrings';
import { AutocompleteContainer, Suggestion, SuggestionsContainer } from './Styles';

export interface BaseAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  fetchSuggestions: (value: string) => Promise<string[]>;
}

export type AutocompleteProps = BaseAutocompleteProps &
  Omit<InputProps, keyof BaseAutocompleteProps>;

export const Autocomplete: React.FC<AutocompleteProps> = ({
  value,
  fetchSuggestions,
  onChange,
  invalid,
  ...props
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const debouncedFetchSuggestions = useDebouncedCallback((searchString?: string) => {
    if (!searchString || searchString.length < 3) return;

    void fetchSuggestions(searchString).then(setSuggestions);
  });

  const { isOpen, highlightedIndex, getInputProps, getMenuProps, getItemProps } = useCombobox({
    inputValue: value,
    items: suggestions,
    // due to a bug in downshift (https://github.com/downshift-js/downshift/issues/1108)
    // we can't use the `onInputValueChange` callback as recommended in the downshift docs
    onStateChange: ({ type, inputValue: selectedItem }) => {
      if (
        selectedItem &&
        (type === useCombobox.stateChangeTypes.ItemClick ||
          type === useCombobox.stateChangeTypes.InputKeyDownEnter)
      ) {
        onChange(selectedItem);
      }
    },
  });

  const { onChange: onChangeCombobox, ...comboboxProps } = getInputProps();

  return (
    <AutocompleteContainer>
      <Input
        invalid={invalid}
        onChange={(newValue, event) => {
          onChange(newValue);
          onChangeCombobox?.(event);
          debouncedFetchSuggestions(newValue);
        }}
        {...comboboxProps}
        {...props}
      />
      <SuggestionsContainer
        data-cy-suggestions-container
        {...getMenuProps()}
        $isVisible={suggestions.length && isOpen}
      >
        {suggestions.map((suggestion, index) => (
          <Suggestion
            data-cy-suggestion
            $isHighlighted={highlightedIndex === index}
            key={suggestion}
            {...getItemProps({ index, item: suggestion })}
          >
            <HighlightedSubstrings string={suggestion} substring={value} />
          </Suggestion>
        ))}
      </SuggestionsContainer>
    </AutocompleteContainer>
  );
};
