import { textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const UpsellMessageContainer = styled.div`
  padding-top: 100px;
  display: flex;
  gap: 50px;
  justify-content: center;
`;
export const UpsellMessageContent = styled.div`
  width: 450px;
`;
export const UpsellMessageTitle = styled.div`
  ${textStyles.titleMedium700};
`;
export const UpsellMessageBody = styled.div`
  padding: 10px 0;
`;
export const UpsellMessageImageContainer = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;
