import { graphql } from '~/__gql__';

export const fragmentCompanyConfig = graphql(/* GraphQL */ `
  fragment CompanyConfig on Company {
    id
    type
    bidsSubmittedOnMarketplace
    config {
      id
      additionalLanguages
      premiumEnabled
    }
    permanentFeatureFlags
  }
`);

export const globalFeatureFlags = [
  'addSubContractorExtraFields',
  'showArchiveAutoReject',
  'importSubcontractorData',
  'publishBidPackageRevamp',
] as const;
