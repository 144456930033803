import { Clickable, COLOR, Icon } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { PremiumBadge } from '~/shared/components/AgentPremium/PremiumBadge';
import { font, mixins, sizes } from '~/shared/utils/styles';

export const TopbarControls = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const UserDropdownTrigger = styled(Clickable)`
  position: relative;
  display: flex;
  align-items: center;
  height: ${sizes.topbarHeight}px;
  margin-left: 10px;
  color: ${COLOR.textSecondary};
  ${mixins.clickable()}
  &:hover {
    color: ${COLOR.accent10};
  }
`;

export const Wrapper = styled.div`
  padding: 0 6px 0 10px;
  max-width: 205px;
`;

export const Workspace = styled.div`
  white-space: nowrap;
  ${font.medium};
  ${font.size(12)};
  line-height: 15px;
  ${mixins.truncateText()};
  color: ${COLOR.textSecondary};
`;

export const Username = styled.div`
  white-space: nowrap;
  ${font.medium};
  ${font.size(10)};
  line-height: 13px;
  ${mixins.truncateText()};
  color: ${COLOR.textTertiary};
`;

export const WorkspaceListItem = styled.div`
  display: flex;
`;

export const WorkspaceListItemName = styled.div<{ bold?: boolean }>`
  max-width: 190px;
  ${mixins.truncateText()};
  margin-right: 26px;
  flex-grow: 1;

  ${(props) =>
    props.bold &&
    css`
      color: ${COLOR.textPrimary};
      ${font.medium};
    `}
`;

export const WorkspaceListItemIcon = styled(Icon).attrs({
  color: COLOR.success,
  type: 'check-fat',
})`
  margin-left: -16px;
`;

export const CountBadge = styled.div`
  ${font.medium}

  background: ${COLOR.danger};
  border-radius: 8px;
  color: ${COLOR.white};
  line-height: 16px;
  ${font.size(12)}
  padding: 0 5px;
  position: absolute;
  left: 18px;
  top: 2px;
  pointer-events: none;
`;

export const ButtonWithCountBadgeWrapper = styled.div`
  position: relative;
`;

export const StyledPremiumBadge = styled(PremiumBadge)`
  margin-left: 10px;
`;
