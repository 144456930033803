import { COLOR } from '@cosuno/cosuno-ui';
import Color from 'color';
import { css } from 'styled-components';

import { CARD_OPENING_TRANSITION_DURATION } from '~/shared/constants';

import { font } from '../font';
import { mediaQueries } from '../mediaQueries';
import { sizes } from '../sizes';
import inputValueCellPadding from './inputValueCellPadding';
import type { CSS } from './types';
import { createMixin } from './utils';

const darken = (colorValue: string, amount: number): string =>
  Color(colorValue).darken(amount).string();

const lighten = (colorValue: string, amount: number): string =>
  Color(colorValue).lighten(amount).string();

const setLightness = (colorValue: string, fraction: number): string =>
  Color(colorValue)
    .lightness(fraction * 100)
    .string();

const rgba = (colorValue: string, opacity: number): string =>
  Color(colorValue).alpha(opacity).string();

const boxShadowLight = () => css`
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
`;

const boxShadowMedium = (overrides?: CSS) =>
  createMixin(
    css`
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    `,
    overrides,
  );

const boxShadowLight2022 = () => css`
  box-shadow:
    0 1px 4px 0 ${COLOR.boxShadow},
    0 4px 20px 0 ${COLOR.boxShadowLightAlpha};
`;

const boxShadowStrong = () => css`
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
`;

const boxShadowBorderMedium = (overrides?: CSS) =>
  createMixin(
    css`
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid ${COLOR.borderMedium};
      border-top: 1px solid ${COLOR.borderLight};
    `,
    overrides,
  );

/**
 * Displays text on one line and shows an ellipsis if it doesn't fit in the element
 */
const truncateText = (overrides?: CSS) =>
  createMixin(
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,
    overrides,
  );

/**
 * Displays text on a number of lines specified and shows an ellipsis at the end
 * of the last line if the text is too long.
 *
 * @param numberOfLines Number of lines to display.
 * @param lineHeight Height of each line as a CSS length (px, em, etc.).
 */
const lineClamp = (numberOfLines: number, lineHeight: string, overrides?: CSS) =>
  createMixin(
    css`
      /* Display ellipsis at the end of the nth line in Webkit/Blink browsers
      and Firefox */
      /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
      -webkit-line-clamp: ${numberOfLines};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

      /* Fallback for IE */
      line-height: ${lineHeight};
      max-height: calc(${numberOfLines} * ${lineHeight});
      overflow: hidden;
    `,
    overrides,
  );

const clickable = (overrides?: CSS) =>
  createMixin(
    css`
      cursor: pointer;
      user-select: none;
    `,
    overrides,
  );

const hardwareAccelerate = (overrides?: CSS) =>
  createMixin(
    css`
      backface-visibility: hidden;
      transform: translateZ(0);
    `,
    overrides,
  );

const placeholderColor = (colorValue: COLOR, overrides?: CSS) =>
  createMixin(
    css`
      ::placeholder {
        color: ${colorValue};

        /* opacity needs to be set for Firefox, as the default is <1 */
        opacity: 1;
      }
    `,
    overrides,
  );

const scrollableY = (overrides?: CSS) =>
  createMixin(
    css`
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    `,
    overrides,
  );

const backgroundImage = (overrides?: CSS) =>
  createMixin(
    css`
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: ${COLOR.backgroundLight};
    `,
    overrides,
  );

const link = (colorValue: string = COLOR.brand, overrides?: CSS) =>
  createMixin(
    css`
      cursor: pointer;
      color: ${colorValue};
      ${font.medium}
      &:hover, &:visited, &:active {
        color: ${colorValue};
      }
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &:disabled {
        color: ${COLOR.textTertiary};
        cursor: default;
        pointer-events: none;
      }
    `,
    overrides,
  );

const tagLabel = (overrides?: CSS) =>
  createMixin(
    css`
      ${font.medium}
      ${font.size(12)}
      ${truncateText()}

      color: ${COLOR.textPrimary};
      max-width: 250px;
      line-height: 22px;
    `,
    overrides,
  );

const tagIcon = (overrides?: CSS) =>
  createMixin(
    css`
      margin-left: 4px;
      vertical-align: text-bottom;
    `,
    overrides,
  );

const tag = (overrides?: CSS) =>
  createMixin(
    css`
      display: inline-flex;
      align-items: center;
      height: 24px;
      padding: 0 8px;
      border: 1px solid ${COLOR.borderMedium};
      border-radius: 4px;
      user-select: none;
      background: ${COLOR.backgroundLight};

      span {
        ${tagLabel()}
      }
      svg {
        ${tagIcon()}
      }
    `,
    overrides,
  );

const pageContentHorizontalPadding = (overrides?: CSS) =>
  createMixin(
    css`
      @media ${mediaQueries.mobile} {
        padding-left: ${({ theme }) => theme.pageContentHorizontalPaddingMobile}px;
        padding-right: ${({ theme }) => theme.pageContentHorizontalPaddingMobile}px;
      }

      @media ${mediaQueries.tablet} {
        padding-left: ${({ theme }) => theme.pageContentHorizontalPaddingDesktop}px;
        padding-right: ${({ theme }) => theme.pageContentHorizontalPaddingDesktop}px;
      }
    `,
    overrides,
  );

const printWrapperBasis = (overrides?: CSS) =>
  createMixin(
    css`
      /* Keeps IE from printing an extra blank page */
      height: 100%;
      margin-top: 20px;
      margin-left: 30px;
      margin-right: 30px;
    `,
    overrides,
  );

const printWrapperWhenPrinting = (overrides?: CSS) =>
  createMixin(
    css`
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      break-after: avoid-page;
    `,
    overrides,
  );

const cardOpeningAnimation = (isOpen: boolean, targetOpacity = 1, overrides?: CSS) =>
  createMixin(
    css`
      opacity: ${isOpen ? targetOpacity : 0};
      transform: scale(${isOpen ? 1 : 0.97});
      transition:
        opacity ${CARD_OPENING_TRANSITION_DURATION}ms,
        transform ${CARD_OPENING_TRANSITION_DURATION}ms;
    `,
    overrides,
  );

const tableBorder = (overrides?: CSS) =>
  createMixin(
    css`
      border-radius: ${sizes.tableBorderRadius}px;
      border: 1px solid ${COLOR.borderMedium};
    `,
    overrides,
  );

const sticky = (overrides?: CSS) =>
  createMixin(
    css`
      position: sticky;
      left: 0;
      top: ${(props) => props.theme.topOffset}px;
      z-index: 1;
    `,
    overrides,
  );

// may be required for word-wrap (overflow-wrap)
// prevents browser from estimating a min-width value and taking more horizontal space than intended
const wordWrapBasis = () => css`
  min-width: 1%;
`;

export const underlineText = (overrides?: CSS) =>
  createMixin(
    css`
      text-decoration: 1px underline dashed currentColor;
      text-underline-offset: 2px;
      cursor: pointer;
    `,
    overrides,
  );

export const mixins = {
  darken,
  lighten,
  setLightness,
  rgba,
  boxShadowLight,
  boxShadowLight2022,
  boxShadowMedium,
  boxShadowStrong,
  boxShadowBorderMedium,
  truncateText,
  lineClamp,
  clickable,
  hardwareAccelerate,
  placeholderColor,
  scrollableY,
  backgroundImage,
  link,
  tag,
  tagLabel,
  tagIcon,
  pageContentHorizontalPadding,
  printWrapperBasis,
  printWrapperWhenPrinting,
  cardOpeningAnimation,
  tableBorder,
  sticky,
  wordWrapBasis,
  inputValueCellPadding,
  underlineText,
};
