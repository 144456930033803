import type { ValueOf } from 'ts-essentials';

type ScriptAttributes = Partial<
  Pick<
    HTMLScriptElement,
    | 'type'
    | 'id'
    | 'async'
    | 'defer'
    | 'crossOrigin'
    | 'nonce'
    | 'integrity'
    | 'noModule'
    | 'referrerPolicy'
  >
> & { src: string };

function setAttribute<T extends object, U extends keyof T>(obj: T, key: U, value: T[U]) {
  obj[key] = value;
}

/**
 * Injects a script into the DOM.
 *
 * Script is injected before the first existing script tag that is found.
 * Doesn't do anything if a script with the given `src` already exists.
 */
export default function injectScript(attributes: ScriptAttributes): HTMLScriptElement | undefined {
  if (document.querySelectorAll(`script[src="${attributes.src}"]`).length > 0) {
    return;
  }

  const script = document.createElement('script');

  for (const [key, value] of Object.entries(attributes) as Array<
    [keyof ScriptAttributes, ValueOf<ScriptAttributes>]
  >) {
    if (value === undefined) continue;
    setAttribute(script, key, value);
  }

  const first = document.getElementsByTagName('script')[0];
  first.parentNode?.insertBefore(script, first);

  return script;
}
