import {
  Avatar,
  COLOR,
  DropdownActionSeparator,
  DropdownMenu,
  Icon,
  Tooltip,
} from '@cosuno/cosuno-ui';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { COMPANY_TYPE, USER_TYPE } from '~/__gql__/graphql';
import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import AuthContext, { isAuthenticated } from '~/shared/contexts/authContext';
import WorkspaceContext from '~/shared/contexts/workspaceContext';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import { NavigationConfirmationEnvironmentContext } from '~/shared/hooks/useNavigationConfirmation';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';
import { env } from '~/shared/utils/env';
import { getUserName } from '~/shared/utils/names';
import toast from '~/shared/utils/toast';

import BeamerTrigger from './BeamerTrigger';
import CelloTrigger from './CelloTrigger';
import Messages from './Messages';
import Notifications from './Notifications';
import {
  StyledPremiumBadge,
  TopbarControls,
  UserDropdownTrigger,
  Username,
  Workspace,
  WorkspaceListItem,
  WorkspaceListItemIcon,
  WorkspaceListItemName,
  Wrapper,
} from './Styles';
import Tasks from './Tasks';

const AuthenticatedContent: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const authContext = useContext(AuthContext);
  const user = useCurrentUser();
  const workspaceContext = useContext(WorkspaceContext);
  const routes = useRoutes();
  const navigationContext = useContext(NavigationConfirmationEnvironmentContext);

  if (!navigationContext || !isAuthenticated(authContext)) return null;

  const { tryNavigatingAway } = navigationContext;

  const { authState, logout } = authContext;
  const { firstName, lastName, workspaces } = authState.user;

  const isCelloEnabled = isCompanyFeatureEnabled(user?.company, 'conditionalCelloWidget');

  const shouldDisplayCello = env.REACT_APP_CELLO_DISABLED !== 'true' && isCelloEnabled;

  const shouldDisplayNewFeatures =
    env.REACT_APP_BEAMER_DISABLED !== 'true' && authState.companyType === COMPANY_TYPE.principal;

  const currentWorkspace = workspaces.find(({ isCurrent }) => isCurrent);
  const sortedWorkspaces = workspaces
    .slice()
    .sort((a, b) => a.company.name.localeCompare(b.company.name));

  const switchToWorkspace = async (workspaceId: string) => {
    try {
      await authContext.switchToWorkspace(workspaceId);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <TopbarControls>
      <Tasks />
      <Messages />
      <Notifications />
      {shouldDisplayCello && <CelloTrigger />}
      {shouldDisplayNewFeatures && <BeamerTrigger />}
      {user?.company.config.premiumEnabled && user.company.type === COMPANY_TYPE.agent && (
        <Tooltip<HTMLDivElement>
          isTriggerElementClickable={false}
          bodyText={t('topBar:premiumTooltip')}
        >
          {({ ref, getReferenceProps }) => (
            <StyledPremiumBadge ref={ref} {...getReferenceProps()} />
          )}
        </Tooltip>
      )}
      <DropdownMenu.Container
        isLarge
        renderTrigger={({ isTriggered, toggleTrigger }) => (
          <UserDropdownTrigger
            onClick={() => {
              if (!isTriggered) trackEvent('topNavigationOpen');
              toggleTrigger();
            }}
          >
            <Avatar name={currentWorkspace?.company.name} />
            <Wrapper>
              <Workspace data-cy-workspace-name>{currentWorkspace?.company.name}</Workspace>
              <Username>{getUserName({ firstName, lastName })}</Username>
            </Wrapper>
            <Icon type={isTriggered ? 'chevron-up' : 'chevron-down'} color={COLOR.textSecondary} />
          </UserDropdownTrigger>
        )}
      >
        {sortedWorkspaces.length > 1 && (
          <>
            {sortedWorkspaces.map((workspace) => (
              <DropdownMenu.Action
                key={workspace.id}
                disabled={workspace.isCurrent}
                onClick={() => {
                  workspaceContext.switch(() => {
                    void switchToWorkspace(workspace.id);
                  });
                }}
              >
                <WorkspaceListItem>
                  <WorkspaceListItemName bold={workspace.isCurrent}>
                    {workspace.company.name}
                  </WorkspaceListItemName>
                  {workspace.isCurrent && <WorkspaceListItemIcon />}
                </WorkspaceListItem>
              </DropdownMenu.Action>
            ))}
            <DropdownActionSeparator />
          </>
        )}
        <DropdownMenu.Action
          forwardedAs={NavLink}
          to={routes.profile()}
          onClick={() => trackEvent('topNavigationMyAccount')}
        >
          {t('profile:myAccount')}
        </DropdownMenu.Action>
        {user?.type === USER_TYPE.administrator && ( // TODO: find a way to use new permission system
          <DropdownMenu.Action
            forwardedAs={NavLink}
            to={routes.company()}
            onClick={() => trackEvent('topNavigationMyCompany')}
          >
            {t('company:company')}
          </DropdownMenu.Action>
        )}
        <DropdownMenu.Action
          data-cy-logout
          onClick={() => {
            trackEvent('authSignOut');
            tryNavigatingAway(() => logout());
          }}
        >
          {t('auth:signOut')}
        </DropdownMenu.Action>
      </DropdownMenu.Container>
    </TopbarControls>
  );
};

export default AuthenticatedContent;
