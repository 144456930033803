import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Route, type RouteProps, useHistory, useLocation } from 'react-router-dom';

import { USER_TYPE } from '~/__gql__/graphql';
import { usePasswordlessLoginTokenQueryParam } from '~/shared/components/AuthForms';
import PasswordlessLoginTokenContext from '~/shared/contexts/passwordlessLoginTokenContext';
import { useAgentSignupRedirectRoute } from '~/shared/hooks/useAgentSignupRedirectRoute';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useUserAuthState from '~/shared/hooks/useUserAuthState';

import AuthRouteContent from './AuthRouteContent';
import WorkspaceRedirect from './WorkspaceRedirect';

interface RouteInternalProps {
  /** `path={undefined}` will create a catch-all route */
  path: string | undefined;
  component: Exclude<RouteProps['component'], undefined>;
  exact?: boolean;
  withLayout?: boolean;
  mountRoute: boolean;
}

const RouteInternal: React.FC<RouteInternalProps> = ({
  path,
  component: Component,
  exact,
  withLayout = false,
  mountRoute,
}) => {
  const { pathname, search } = useLocation();
  const { userBaseUrl } = useUserAuthState();
  const history = useHistory();
  const redirectTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const agentSignupRedirectRoute = useAgentSignupRedirectRoute();

  const redirectToBaseUrl = useCallback(() => {
    history.replace({
      pathname: agentSignupRedirectRoute?.pathname ?? userBaseUrl,
      search: agentSignupRedirectRoute?.search ?? '',
      state: { redirect: `${pathname}${search ?? ''}` },
    });
  }, [history, agentSignupRedirectRoute, userBaseUrl, pathname, search]);

  // If the route is rendered by the parent router `Switch` but `mountRoute` is false for 500
  // milliseconds, redirect to the user type's base url.
  // This allows useAuthentication.logout to do its own route change, before the redirect here
  // takes effect.
  useEffect(() => {
    if (!mountRoute) {
      redirectTimeoutRef.current = setTimeout(() => redirectToBaseUrl(), 500);
    }

    return () => {
      if (redirectTimeoutRef.current) {
        clearTimeout(redirectTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mountRoute]);

  const { passwordlessLoginToken } = usePasswordlessLoginTokenQueryParam();
  const ctxValue = useMemo(() => ({ passwordlessLoginToken }), [passwordlessLoginToken]);

  if (!mountRoute) {
    return null;
  }

  return (
    <PasswordlessLoginTokenContext.Provider value={ctxValue}>
      <Route
        path={path}
        exact={exact}
        render={(routeProps) => (
          <AuthRouteContent withLayout={withLayout}>
            <WorkspaceRedirect {...routeProps}>
              <Component {...routeProps} />
            </WorkspaceRedirect>
          </AuthRouteContent>
        )}
      />
    </PasswordlessLoginTokenContext.Provider>
  );
};

type AuthRouteProps = Omit<RouteInternalProps, 'mountRoute'> & {
  /** allows access by unauthenticated users */
  allowAnonymous?: boolean;
  /** allows access by authenticated principal company users */
  allowPrincipal?: boolean;
  /** allows access by authenticated agent company users */
  allowAgent?: boolean;
  /** allows access by company admin users */
  allowCompanyAdmin?: boolean;
  /** allows access by all authenticated users */
  allowAuthenticated?: boolean;
  /** allows access by agent admin users */
  allowAgentAdmin?: boolean;
  /** If provided, the route will only be mounted if this condition is satisfied
   * _in addition to_ the conditions from props `allowAnonymous`,
   * `allowPrincipal` etc. */
  extraCondition?: boolean;
};

const AuthRoute = ({
  allowAgent,
  allowAnonymous,
  allowPrincipal,
  allowCompanyAdmin,
  withLayout,
  allowAuthenticated,
  allowAgentAdmin,
  extraCondition,
  ...props
}: AuthRouteProps) => {
  const user = useCurrentUser();
  const { isUserAuthenticated, isUserAgent, isUserPrincipal } = useUserAuthState();

  const isAdmin = user?.type === USER_TYPE.administrator;
  const satisfiesExtraCondition = extraCondition === undefined ? true : extraCondition;

  const mountRoute =
    Boolean(
      (allowAnonymous && !isUserAuthenticated) ||
        (allowAgent && isUserAgent) ||
        (allowPrincipal && isUserPrincipal) ||
        (allowCompanyAdmin && isAdmin) ||
        (allowAuthenticated && isUserAuthenticated) ||
        (allowAgentAdmin && isUserAgent && isAdmin),
    ) && satisfiesExtraCondition;

  return (
    <RouteInternal
      {...props}
      withLayout={withLayout && isUserAuthenticated}
      mountRoute={mountRoute}
    />
  );
};

export { AuthRoute };
