import type { PositionComplementingTypeDto } from '@dangl/avacloud-client-node';
import type { ElementOf } from 'ts-essentials';

import { BOQ_POSITION_TYPE } from '~/__gql__/graphql';

import type { ToggleablePositionType } from '../types/positionTypes';

export const POSITION_TYPES_PREVENTING_DIRECT_TOGGLING = [
  BOQ_POSITION_TYPE.RepeatingDescription,
  BOQ_POSITION_TYPE.ReferringExecutionDescription,
  BOQ_POSITION_TYPE.Surcharge,
] as const;

export type PositionTypePreventingDirectToggling = ElementOf<
  typeof POSITION_TYPES_PREVENTING_DIRECT_TOGGLING
>;

/*
 * NOTE: Position types work in combination and some of those combinations exclude other
 * positions. See MUTUALLY_EXCLUSIVE_POSITION_TYPE_GROUPS in frontend/src/packages/BoQ/
 * constants/positionTypes.ts
 * Base position excludes positions: Alternative, Optional, OptionalWithTotal
 * Alternative position excludes positions: Base, Optional, OptionalWithTotal
 * Optional position excludes positions: Base, Alternative, OptionalWithTotal
 * Optional position with total excludes positions: Base, Alternative, Optional
 *
 * Lump sum excludes Free Quantity Positions.
 * Free Quantity Position excludes Lump Sum Positions.
 */
export const MUTUALLY_EXCLUSIVE_POSITION_TYPE_GROUPS: Array<ToggleablePositionType[]> = [
  [
    BOQ_POSITION_TYPE.Base,
    BOQ_POSITION_TYPE.Alternative,
    BOQ_POSITION_TYPE.Optional,
    BOQ_POSITION_TYPE.OptionalWithTotal,
  ],
  [BOQ_POSITION_TYPE.FreeQuantity, BOQ_POSITION_TYPE.LumpSum, BOQ_POSITION_TYPE.Surcharge],
];

export enum POSITION_TYPE_ERROR {
  /**
   * No base position is being referenced, or the referenced one does not exist.
   */
  missingBasePosition = 'missingBasePosition',
  /**
   * No execution description is being referenced.
   */
  missingExecutionDescriptionReference = 'missingExecutionDescriptionReference',
  /**
   * The referenced execution description does not exist.
   */
  executionDescriptionNotFound = 'executionDescriptionNotFound',
  /**
   * No referral description is being referenced.
   */
  missingReferralDescriptionReference = 'missingReferralDescriptionReference',
  /**
   * The referenced referral description does not exist.
   */
  referralDescriptionNotFound = 'referralDescriptionNotFound',
  /**
   * The position is either not a surcharge position or it does not reference any positions.
   */
  missingSurchargePositionReference = 'missingSurchargePositionReference',
  /**
   * @todo This enum variant seems to not be used but it is unclear why.
   *       This is a potential oversight.
   */
  toBeSurchargedPositionNotFound = 'toBeSurchargedPositionNotFound',
  /**
   * The position has an empty code or a numeric one that is negative.
   */
  emptyCode = 'emptyCode',
  /**
   * @todo This enum variant seems to not be used but it is unclear why.
   *       This is a potential oversight.
   */
  missingCode = 'missingCode',
  /**
   * The position code contains non-numeric characters.
   */
  nonNumericCode = 'nonNumericCode',
  /**
   * The position code is numeric and exceeds `MAX_POSSIBLE_ALTERNATIVE_IDENTIFIER`.
   */
  tooLargeCode = 'tooLargeCode',
}

export const EXECUTION_DESCRIPTION_SELECT_ERRORS = [
  POSITION_TYPE_ERROR.missingExecutionDescriptionReference,
  POSITION_TYPE_ERROR.executionDescriptionNotFound,
] as const;

export type EXECUTION_DESCRIPTION_SELECT_ERROR = ElementOf<
  typeof EXECUTION_DESCRIPTION_SELECT_ERRORS
>;

export const REFERRAL_DESCRIPTION_SELECT_ERRORS = [
  POSITION_TYPE_ERROR.missingReferralDescriptionReference,
  POSITION_TYPE_ERROR.referralDescriptionNotFound,
] as const;

export type REFERRAL_DESCRIPTION_SELECT_ERROR = ElementOf<
  typeof REFERRAL_DESCRIPTION_SELECT_ERRORS
>;

export const SURCHARGE_TYPE_SELECT_ERROR = [
  POSITION_TYPE_ERROR.missingSurchargePositionReference,
  POSITION_TYPE_ERROR.toBeSurchargedPositionNotFound,
] as const;

export type SURCHARGE_TYPE_SELECT_ERROR = ElementOf<typeof SURCHARGE_TYPE_SELECT_ERROR>;

export type BASE_POSITION_SELECT_ERROR = POSITION_TYPE_ERROR.missingBasePosition;

export type BASE_POSITION_CODE_ERROR =
  | POSITION_TYPE_ERROR.emptyCode
  | POSITION_TYPE_ERROR.nonNumericCode
  | POSITION_TYPE_ERROR.tooLargeCode
  | POSITION_TYPE_ERROR.missingCode;

export enum SURCHARGE_TYPE {
  AllPreviousPositions = 'AllPreviousPositions',
  ReferencedPositions = 'ReferencedPositions',
}

const COMPLEMENTING_TYPE = {
  AllPreviousMarkedPositions: 'AllPreviousMarkedPositions',
  AllPreviousPositions: 'AllPreviousPositions',
  ReferencedPositions: 'ReferencedPositions',
  Undefined: 'Undefined',
} as const;

export const mapComplementingTypeToSurchargeType = (
  complementingType: PositionComplementingTypeDto,
) =>
  ({
    // AllPreviousMarkedPositions mapped to AllPreviousPositions because we are not supporting AllPreviousMarkedPositions
    // and they should be pretty similar
    [COMPLEMENTING_TYPE.AllPreviousMarkedPositions]: SURCHARGE_TYPE.ReferencedPositions,
    [COMPLEMENTING_TYPE.AllPreviousPositions]: SURCHARGE_TYPE.AllPreviousPositions,
    [COMPLEMENTING_TYPE.ReferencedPositions]: SURCHARGE_TYPE.ReferencedPositions,
    [COMPLEMENTING_TYPE.Undefined]: null,
  })[complementingType as string];

export const mapSurchargeTypeToComplementingType = (surchargeType: SURCHARGE_TYPE) => {
  const map: { [key in SURCHARGE_TYPE]: string } = {
    [SURCHARGE_TYPE.AllPreviousPositions]: COMPLEMENTING_TYPE.AllPreviousPositions,
    [SURCHARGE_TYPE.ReferencedPositions]: COMPLEMENTING_TYPE.ReferencedPositions,
  };

  return map[surchargeType];
};

export enum BOQ_GROUP_TYPE {
  Base = 'Base',
  Alternative = 'Alternative',
}
