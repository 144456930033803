import { kebabCase, omit } from 'lodash';

import type {
  MULTI_USER_APPROVAL_ACTION,
  PRICING_PLAN,
  SUBCONTRACTOR_TYPE,
} from '~/__gql__/graphql';
import type { AgentUnauthenticatedMarketplaceSamplesQueryParams } from '~/components/AgentUnauthenticated/MarketplaceSamples';
import type { FocusFieldsQuery as BidPackageFocusFields } from '~/components/BidPackage/Overview/BaseOverview';
import type { FocusFieldsQuery as ProjectFocusFields } from '~/components/Project/Overview';
import type { AgentSignupRouteProps } from '~/shared/components/AuthForms/AgentSignupPage/AgentSignup';
import type { ANALYTICS_ENTRY_POINT } from '~/shared/contexts/analyticsEntryPointContext';
import { urlWithQuery } from '~/shared/utils/url';

import type { ERROR_TYPES } from './errorTypes';
import {
  CompanySettingsTabs,
  type ShowBidPackageTabs,
  type SubcontractorProfileTabs,
} from './routeTabs';

const getRoutePathParams = (paramsEnum: { [key: string]: string }, name?: string): string => {
  const params = Object.keys(paramsEnum).join('|');

  return `${name || ''}(${params})`;
};

export enum PROJECTS_ROUTE_TYPE {
  open = 'open',
  closed = 'closed',
}

export enum BID_PACKAGES_ROUTE_TYPE {
  open = 'open',
  'awaiting-response' = 'awaiting-response',
}

export enum BID_INVITE_ACTION_ROUTE_TYPE {
  'accept' = 'accept',
  'decline' = 'decline',
  'decide-later' = 'decide-later',
}

export enum AGENT_ONBOARDING_SUBPAGE {
  firstSteps = 'first-steps',
  winMoreBids = 'win-more-bids',
}

export const generateRoutes = (props?: { withoutWorkspace?: boolean; workspaceId?: string }) => {
  const workspacePrefix = props?.withoutWorkspace
    ? ''
    : `/${props?.workspaceId ?? ':workspaceId?'}`;

  return {
    root: () => '/',

    companyOnboarding: () => `${workspacePrefix}/company-onboarding`,

    companyOnboardingSetup: () => `${workspacePrefix}/company-onboarding/setup`,

    companyOnboardingTraining: () => `${workspacePrefix}/company-onboarding/training`,

    tasksRoot: () => `${workspacePrefix}/tasks`,
    tasks: () => `${workspacePrefix}/tasks/all`,
    myTasks: () => `${workspacePrefix}/tasks/my`,
    showTask: (id = ':id') => `${workspacePrefix}/tasks/${id}`,
    taskLogin: (
      query: {
        taskId?: string;
        sentEmailId?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
      } = {},
    ) => urlWithQuery(`/task-login`, query),
    editTask: (id = ':id') => `${workspacePrefix}/tasks/${id}?edit=true`,
    createTask: (
      options: {
        trackingEntryPoint?: ANALYTICS_ENTRY_POINT;
        projectId?: string;
        bidPackageId?: string;
        bidRequestId?: string;
      } = {},
    ) => urlWithQuery(`${workspacePrefix}/tasks/new`, options),

    projectsRoot: () => `${workspacePrefix}/projects`,

    projects: (type?: PROJECTS_ROUTE_TYPE) =>
      `${workspacePrefix}/projects/${type || getRoutePathParams(PROJECTS_ROUTE_TYPE)}`,

    showProject: (
      projectId = ':projectId',
      options: {
        returnToBidPackages?: boolean;
        focusField?: ProjectFocusFields;
      } = {},
    ) => {
      const tabSubPath = options.focusField ? '/overview' : '';

      return urlWithQuery(`${workspacePrefix}/projects/${projectId}${tabSubPath}`, options);
    },

    projectBim(projectId = ':projectId') {
      return `${this.showProject(projectId)}/bim`;
    },

    projectBimDocument(projectId = ':projectId', documentId = ':documentId') {
      return `${this.projectBim(projectId)}/document/${documentId}`;
    },

    projectReporting: (projectId: string, options: { highlightBidPackageId?: string } = {}) =>
      urlWithQuery(`${workspacePrefix}/projects/${projectId}/reporting`, options),

    createProject: () => `${workspacePrefix}/projects/create`,

    createNewProject: () => `${workspacePrefix}/projects/create/new`,

    editProject: (projectId: string) =>
      `${workspacePrefix}/projects/${projectId}/overview?edit=true`,

    costEstimation: (projectId: string) =>
      `${workspacePrefix}/projects/${projectId}/cost-estimation`,

    projectBidPackages: (projectId = ':projectId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages`,

    showBidPackage: (
      projectId = ':projectId',
      bidPackageId = ':bidPackageId',
      options: Record<string, unknown> & {
        returnToBidPackages?: boolean;
        request?: string;
        tab?: ShowBidPackageTabs;
        focusField?: BidPackageFocusFields;
        openNewMessageWithSelectedRecipientIds?: string;
        threadId?: string;
        trackingEntryPoint?: ANALYTICS_ENTRY_POINT;
      } = {},
    ) => {
      const tabSubPath = options?.tab ? `/${options.tab}` : '';
      const optionsWithoutTab = omit(options, 'tab');

      return urlWithQuery(
        `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}${tabSubPath}`,
        optionsWithoutTab,
      );
    },

    costComparison: (
      projectId = ':projectId',
      bidPackageId = ':bidPackageId',
      options: { bidIds?: string; hideBidders?: string } = {},
    ) =>
      urlWithQuery(
        `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/cost-comparison`,
        options,
      ),

    addBidders: (
      projectId = ':projectId',
      bidPackageId = ':bidPackageId',
      options: { requestedType?: SUBCONTRACTOR_TYPE } = {},
    ) =>
      urlWithQuery(
        `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/bidders/add`,
        options,
      ),
    createBidPackage: (projectId = ':projectId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/new`,

    editBidPackage: (projectId: string, bidPackageId: string) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/overview?edit=true`,

    publishBidPackage: (projectId = ':projectId', bidPackageId = ':bidPackageId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/publish`,

    showBid: (projectId = ':projectId', bidPackageId = ':bidPackageId', bidId = ':bidId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/bids/${bidId}`,

    submitBid: (
      projectId = ':projectId',
      bidPackageId = ':bidPackageId',
      bidRequestId = ':bidRequestId',
    ) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/bid-requests/${bidRequestId}/submit-bid`,

    showAwardedBid: (
      projectId = ':projectId',
      bidPackageId = ':bidPackageId',
      bidRequestId = ':bidRequestId',
    ) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/bid-requests/${bidRequestId}/awarded-bid`,

    awardBid: (
      projectId = ':projectId',
      bidPackageId = ':bidPackageId',
      bidRequestId = ':bidRequestId',
      bidId = ':bidId',
    ) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/bid-requests/${bidRequestId}/award-bid/${bidId}`,

    bidPackagesRoot: () => `${workspacePrefix}/bid-packages`,

    bidPackages: (type?: BID_PACKAGES_ROUTE_TYPE) =>
      `${workspacePrefix}/bid-packages/${type || getRoutePathParams(BID_PACKAGES_ROUTE_TYPE)}`,

    agentCompanyOnboarding: () => `${workspacePrefix}/agent/company-onboarding`,

    agentCompanyOnboardingSubpage: (page?: AGENT_ONBOARDING_SUBPAGE) =>
      `${workspacePrefix}/agent/company-onboarding/${page ?? ':page'}`,

    agentBidRequests: () => `${workspacePrefix}/requests`,

    agentCreateBidRequest: () => `${workspacePrefix}/requests/new`,

    agentEditBidRequest: (bidRequestId = ':bidRequestId') =>
      `${workspacePrefix}/requests/${bidRequestId}/edit`,

    agentCertificates: () => `${workspacePrefix}/certificates`,

    agentPublicCertificates: (subcontractorId = ':subcontractorId') =>
      `/subcontractors/${subcontractorId}/certificates`,

    agentAwardedBids: () => `${workspacePrefix}/awarded-bids`,

    agentAwardedBid: (id = ':id') => `/awarded-bids/${id}`,

    agentSignContract: (id = ':id', contractRunAgentStepId = ':contractRunAgentStepId') =>
      `/awarded-bids/${id}/${contractRunAgentStepId}`,

    agentReverseMarketplace: () => '/marketplace',

    agentPremiumSuccess: () => '/agent-premium-success',

    reverseMarketplaceBidPackage: (
      bidPackageId = ':bidPackageId',
      params: {
        suggestion?: string;
        passwordlessLoginToken?: string;
        referrer?: string;
        sentEmailId?: string;
        subcontractorId?: string;
      } = {},
    ) => urlWithQuery(`/marketplace/${bidPackageId}`, params),

    reverseMarketplaceBidPackageAcceptTerms: (
      bidPackageId = ':bidPackageId',
      queryParams?: object,
    ) => urlWithQuery(`/marketplace/${bidPackageId}/terms`, queryParams ?? {}),

    /**
     * This route is only a fix for suggestion emails sent at 2024-01-08
     * which were missing the `suggestionId`
     * @deprecated not to be used
     */
    agentReverseMarketplaceSuggestionRedirect: () => '/marketplace-suggestion',

    agentReverseMarketplaceSuggestion: (id = ':id') => `/marketplace-suggestion/${id}`,

    bidInvite: (bidInviteId = ':bidInviteId', options: { showSignupSuggestion?: boolean } = {}) =>
      urlWithQuery(`/bid-invitations/${bidInviteId}`, options),

    bidRequestLandingPage: (
      bidRequestId = ':bidRequestId',
      options: {
        showSignupSuggestion?: boolean;
        showDocuments?: boolean;
        openMessages?: boolean;
        threadId?: string;
      } = {},
    ) => urlWithQuery(`/requests/${bidRequestId}`, options),

    previewBidRequestLandingPage: (bidPackageId = ':bidPackageId') =>
      `${workspacePrefix}/bid-packages/${bidPackageId}/preview`,

    subcontractorExternalBid: (bidRequestId = ':bidRequestId', bidId = ':bidId') =>
      `/requests/${bidRequestId}/bids/${bidId}/view`,

    subcontractorBid: (
      bidRequestId = ':bidRequestId',
      bidId = ':bidId',
      options: {
        showCompleteProfilePrompt?: boolean;
        showUpdateWorkCategories?: boolean;
        showMarketplaceSuggestions?: boolean;
      } = {},
    ) => urlWithQuery(`/requests/${bidRequestId}/bids/${bidId}`, options),

    subcontractorCreateBid: (bidRequestId = ':bidRequestId') =>
      `/requests/${bidRequestId}/bids/new`,

    bidInviteAction: (bidInviteId = ':bidInviteId', action?: BID_INVITE_ACTION_ROUTE_TYPE) =>
      `/bid-invitations/${bidInviteId}/${
        action || getRoutePathParams(BID_INVITE_ACTION_ROUTE_TYPE, ':action')
      }`,

    subcontractors: () => `${workspacePrefix}/subcontractors`,

    newSubcontractor: () => `${workspacePrefix}/subcontractors/new`,

    subcontractor: (
      subcontractorId = ':subcontractorId',
      options: { tab?: SubcontractorProfileTabs } = {},
    ) => urlWithQuery(`${workspacePrefix}/subcontractors/${subcontractorId}`, options),

    subcontractorReferenceProject: (
      subcontractorId = ':subcontractorId',
      referenceProjectId = ':referenceProjectId',
    ) =>
      `${workspacePrefix}/subcontractors/${subcontractorId}/reference-projects/${referenceProjectId}`,

    bids: () => `${workspacePrefix}/bids`,

    internalDocuments: (tab = '') => `${workspacePrefix}/internal-documents${tab ? `/${tab}` : ''}`,

    profile: () => `${workspacePrefix}/me`,

    company: ({
      tab,
      skipWorkspace,
      ...query
    }: { tab?: CompanySettingsTabs; subcontractor?: string; skipWorkspace?: boolean } = {}) =>
      urlWithQuery(`${skipWorkspace ? '' : workspacePrefix}/company${tab ? `/${tab}` : ''}`, query),

    premium: (options?: { buy?: 1; skipWorkspace?: true }) =>
      urlWithQuery(`${options?.skipWorkspace ? '' : `${workspacePrefix}/`}premium`, options ?? {}),

    pricingPlans: (options: { plan?: PRICING_PLAN } = {}) =>
      urlWithQuery(`${workspacePrefix}/company/${CompanySettingsTabs.pricingPlans}`, options),

    showCompanyRole: (roleOrMode = ':roleOrMode') =>
      `${workspacePrefix}/company/permissions/${roleOrMode}`,

    office: (officeId = ':officeId') => `${workspacePrefix}/company/offices/${officeId}`,

    multiUserApprovals: () => `${workspacePrefix}/company/multi-user-approvals`,

    newOffice: () => `${workspacePrefix}/company/offices/new`,

    multiUserApprovalRules: (action?: MULTI_USER_APPROVAL_ACTION) =>
      `${workspacePrefix}/company/multi-user-approvals/${action ? kebabCase(action) : ':action'}`,

    login: (options: { redirect?: string; errorCode?: string } = {}) =>
      urlWithQuery('/login', options),

    loginWithSSO: (options: { errorCode?: ERROR_TYPES } = {}) => urlWithQuery('/sso', options),

    ssoError: () => '/sso-error',

    agentSignup: (options: AgentSignupRouteProps | {} = {}) => urlWithQuery('/signup', options),

    agentUnauthenticatedUpdateProfileByBidRequest: (bidRequestId = ':bidRequestId') =>
      `/update-profile/${bidRequestId}`,

    agentUnauthenticatedUpdateProfileBySubcontractor: (subcontractorId = ':subcontractorId') =>
      `/update-subcontractor-profile/${subcontractorId}`,

    agentUnauthenticatedMarketplaceSamples: (
      bidRequestId = ':bidRequestId',
      options: AgentUnauthenticatedMarketplaceSamplesQueryParams | {} = {},
    ) => urlWithQuery(`/marketplace-samples/${bidRequestId}`, options),

    resetPassword: () => '/reset-password',

    createPassword: (token = ':token') => `/create-password/${token}`,

    userLocked: () => '/user-locked',

    invitation: (token = ':token', options: { isSSOAuthenticated?: boolean } = {}) =>
      urlWithQuery(`/invitations/${token}`, options),

    confirmInviteRequest: (token = ':token') => `/confirm-invite-request/${token}`,

    uploadCertificates: (officeId = ':officeId', subcontractorId = ':subcontractorId') =>
      `/upload-certificates/${officeId}/${subcontractorId}`,

    subcontractorCertificateRequest: (
      officeId = ':officeId',
      subcontractorId = ':subcontractorId',
      certificateRequestId = ':certificateRequestId',
    ) => `/upload-certificates/${officeId}/${subcontractorId}/${certificateRequestId}`,

    bidRequestAcceptTerms: (bidRequestId = ':bidRequestId', queryParams?: object) =>
      urlWithQuery(`/requests/${bidRequestId}/terms`, queryParams ?? {}),

    closeDocuSignModal: () => '/close-docusign-modal',

    invoices: () => `${workspacePrefix}/billing`,

    createInvoice: () => `${workspacePrefix}/billing/new`,

    invoice: (id = ':id') => `${workspacePrefix}/billing/${id}`,

    bidPackageInvoices: (projectId: string, bidPackageId: string) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/billing`,

    createBidPackageInvoice: (projectId = ':projectId', bidPackageId = ':bidPackageId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/billing/new`,

    bidPackageInvoice: (projectId = ':projectId', bidPackageId = ':bidPackageId', id = ':id') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/billing/${id}`,

    addendums: (options: { projectId?: string } = {}) =>
      urlWithQuery(`${workspacePrefix}/addendums`, options),

    createAddendum: () => `${workspacePrefix}/addendums/new`,

    bidPackageAddendums: (projectId: string, bidPackageId: string) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/addendums`,

    createBidPackageAddendum: (projectId = ':projectId', bidPackageId = ':bidPackageId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/addendums/new`,

    bidPackageAddendum: (projectId = ':projectId', bidPackageId = ':bidPackageId', id = ':id') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/addendums/${id}`,

    contract: (projectId: string, bidPackageId: string) =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/contract`,

    mfaSetup: () => `${workspacePrefix}/mfa-setup`,

    mfaInitialSetup: () => `${workspacePrefix}/mfa-setup/initial`,

    messages: () => `${workspacePrefix}/messages`,

    createBoQTemplate: () => `${workspacePrefix}/internal-documents/boq/new`,

    showBoQTemplate: (boqTemplateId = ':boqTemplateId') =>
      `${workspacePrefix}/internal-documents/boq/${boqTemplateId}`,

    showCustomCostGroupCatalog: (customCostGroupCatalogId = ':customCostGroupCatalogId') =>
      `${workspacePrefix}/company/custom-catalogs/${customCostGroupCatalogId}`,

    editCustomCostGroupCatalog: (customCostGroupCatalogId = ':customCostGroupCatalogId') =>
      `${workspacePrefix}/company/custom-catalogs/${customCostGroupCatalogId}/edit`,

    createCustomCostGroupCatalog: () => `${workspacePrefix}/company/custom-catalogs/new`,

    listCustomCostGroupCatalogs: () => `${workspacePrefix}/company/custom-catalogs`,

    companyImportContacts: () => `${workspacePrefix}/company/contact-import`,

    companyCustomFieldsForEntityType: (entityType = ':entityType') =>
      `${workspacePrefix}/company/${CompanySettingsTabs.customFields}/${entityType}`,

    expiredProjectDownloadLink: (projectName = ':projectName') =>
      `/expired-project-download-link/${projectName}`,

    redirect: (options: { link?: string; params?: string } = {}) =>
      urlWithQuery(`/redirect`, options),

    goldPackageOverview: () => '/gold',

    publishMarketplaceRestrictedView: (projectId = ':projectId', bidPackageId = ':bidPackageId') =>
      `${workspacePrefix}/projects/${projectId}/bid-packages/${bidPackageId}/publish-marketplace-restricted-view`,
  };
};

export type Routes = ReturnType<typeof generateRoutes>;
