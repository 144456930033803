import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

import { RequiredFieldsNoteWrapper } from './Styles';

const RequiredFieldsNote: React.FC = (props) => {
  const { t } = useTranslation();
  return (
    <RequiredFieldsNoteWrapper {...props}>{t('requiredFieldsNote')}</RequiredFieldsNoteWrapper>
  );
};

export default RequiredFieldsNote;
