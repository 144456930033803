import loadable from '@loadable/component';
import { type CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import React from 'react';

import { COUNTRY_CODE } from '~/__gql__/graphql';
import useTranslation from '~/shared/hooks/useTranslation';
import { translateCountryCode } from '~/shared/utils/countries';

import { getSelectTriggerStyles, SelectSingleStyled } from './Styles';

const CountryFlagIcons = loadable.lib(() => import('country-flag-icons/react/3x2'));

interface Props {
  name: string;
  selectedCountry: CountryCode;
  setSelectedCountry: (countryCode: CountryCode) => void;
}

const PhoneCountrySelect: React.FC<Props> = ({ name, selectedCountry, setSelectedCountry }) => {
  const { i18n } = useTranslation();

  const countryCodes = getCountries().filter((code) => code in COUNTRY_CODE);

  const getOptionLabel = (code: CountryCode) => {
    const countryName = translateCountryCode(code as COUNTRY_CODE, i18n.language);
    const callingCode = getCountryCallingCode(code);

    return `${countryName} (+${callingCode})`;
  };

  return (
    <SelectSingleStyled
      getTriggerAppearanceStyle={getSelectTriggerStyles}
      required
      name={name}
      value={selectedCountry}
      renderValue={(option) =>
        'value' in option ? (
          <CountryFlagIcons>
            {({ default: flags }) => React.createElement(flags[option.value as keyof typeof flags])}
          </CountryFlagIcons>
        ) : (
          <></>
        )
      }
      onChange={(value) => setSelectedCountry(value as CountryCode)}
      options={countryCodes
        .map((code) => ({
          value: code,
          label: getOptionLabel(code),
        }))
        .sort((country, otherCountry) => country.label.localeCompare(otherCountry.label))}
      fixedDropdownCardWidth={300}
      dropdownPlacement="bottom-start"
    />
  );
};

export default PhoneCountrySelect;
