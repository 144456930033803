import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { convertObjectKeysToCamelCase } from '~/shared/utils/object';
import { isNotNullish } from '~/shared/utils/typescript';
import { queryStringToObject } from '~/shared/utils/url';

const AVAILABLE_UTM_PARAMETERS = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
] as const;

type UtmParametersObject = Partial<{ [key in (typeof AVAILABLE_UTM_PARAMETERS)[number]]: string }>;

const UTM_PARAMETERS_COOKIE_NAME = 'utm_parameters';

const stripPortAndSubdomain = (hostname: string) =>
  hostname.match(/(\.[a-z-]+\.[a-z]+)$/i)?.[1] ?? hostname;
const domain = stripPortAndSubdomain(window.location.hostname);

const cookieSuffix = `Domain=${domain}; Path=/; SameSite=Lax;`;

const parseUtmParameters = (query: string): UtmParametersObject => {
  const utmParameters = queryStringToObject<{
    [key in (typeof AVAILABLE_UTM_PARAMETERS)[number]]?: string;
  }>(query);

  return Object.fromEntries(
    AVAILABLE_UTM_PARAMETERS.map((key) =>
      utmParameters[key] ? [key, utmParameters[key]] : null,
    ).filter(isNotNullish),
  );
};

const deleteUtmParametersCookie = () => {
  document.cookie = `${UTM_PARAMETERS_COOKIE_NAME}=; ${cookieSuffix} Expires=${new Date().toUTCString()}`;
};

export const useUtmParametersTracking = () => {
  const location = useLocation();

  return useMemo(
    () => ({
      setUtmParameterCookie: () => {
        const utmParameters = parseUtmParameters(location.search);

        if (isEmpty(utmParameters)) {
          return;
        }

        const cookieValue = convertObjectKeysToCamelCase({
          ...utmParameters,
          url: window.location.href,
          visitedAt: new Date(),
        });
        const cookie = `${UTM_PARAMETERS_COOKIE_NAME}=${JSON.stringify(cookieValue)}; ${cookieSuffix}`;

        document.cookie = cookie;
      },
      deleteUtmParametersCookie,
    }),
    [location.search],
  );
};
