import { graphql } from '~/__gql__';

export const textEditorImageUploadUrl = graphql(/* GraphQL */ `
  query TextEditorImageUploadUrl($filename: String!) {
    imageUploadUrl(filename: $filename) {
      uploadUrl
      storageKey
      url
    }
  }
`);
