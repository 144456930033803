import { useQuery } from '@apollo/client';

import { getSuggestionsQuery } from './api';
import type { SuggestionHints } from './types';

export function useSuggestions(selectedValues: string[], hints?: SuggestionHints) {
  const { data, previousData } = useQuery(getSuggestionsQuery, {
    variables: {
      categories: selectedValues,
      hints,
    },
    skip: !selectedValues.length,
  });
  const suggestions = data?.workCategorySuggestions;
  const previousSuggestions = previousData?.workCategorySuggestions;

  return suggestions ?? previousSuggestions ?? [];
}
