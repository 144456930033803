import { useEffectOnce } from '~/shared/hooks/useEffectOnce';

import type { LoginSuggestion } from '../types';
import { StyledSuggestionList } from './Styles';
import { SuggestionListItemContent } from './SuggestionListItemContent';

export function LoginSuggestionsList({
  options: suggestions,
  onSelectChange,
  autoChooseSingleSuggestion,
}: {
  options: LoginSuggestion[];
  onSelectChange: (suggestion: LoginSuggestion) => void;
  autoChooseSingleSuggestion?: boolean;
}) {
  const hasSingleSuggestion = suggestions.length === 1;

  useEffectOnce(
    () => {
      onSelectChange(suggestions[0]);
    },
    { when: autoChooseSingleSuggestion && hasSingleSuggestion },
  );

  return (
    <StyledSuggestionList role="radiogroup">
      {suggestions.map((suggestion) => (
        <SuggestionListItemContent
          key={suggestion.email}
          suggestion={suggestion}
          {...((!hasSingleSuggestion || !autoChooseSingleSuggestion) && {
            onClick: () => onSelectChange(suggestion),
            tabIndex: 0,
          })}
        />
      ))}
    </StyledSuggestionList>
  );
}
