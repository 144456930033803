import { COLOR, Icon } from '@cosuno/cosuno-ui';
import React, { type PropsWithChildren } from 'react';

import useIsAppOutdated from '~/shared/hooks/useIsAppOutdated';
import useIsPermissionsVersionOutdated from '~/shared/hooks/useIsPermissionsVersionOutdated';
import {
  NavigationConfirmationEnvironmentContext,
  useNavigationConfirmationEnvironment,
} from '~/shared/hooks/useNavigationConfirmation';
import useTranslation from '~/shared/hooks/useTranslation';

import { StyledMessagePopup } from './styles';

const AppOutdatedNotifier: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();

  const isAppOutdated = useIsAppOutdated();
  const isPermissionsVersionOutdated = useIsPermissionsVersionOutdated();

  const environmentProps = useNavigationConfirmationEnvironment();

  const reload = () => environmentProps.tryNavigatingAway(() => window.location.reload());

  return (
    <NavigationConfirmationEnvironmentContext.Provider value={environmentProps}>
      {(isAppOutdated || isPermissionsVersionOutdated) && (
        <StyledMessagePopup
          color={COLOR.warning}
          title={t('appOutdated:title')}
          message={t('appOutdated:message')}
          leftIcon={<Icon type="refresh" size={16} color={COLOR.white} />}
          onClick={reload}
        />
      )}
      {children}
    </NavigationConfirmationEnvironmentContext.Provider>
  );
};

export default AppOutdatedNotifier;
