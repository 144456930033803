import { type RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

type SetRef<T extends HTMLElement> = (node: T) => void;

interface FocusProps<T extends HTMLElement> {
  condition?: boolean;
  children: (props: { ref: RefObject<T>; setRef: SetRef<T> }) => React.ReactElement;
}

const Focus = <T extends HTMLElement = HTMLInputElement>({
  condition = true,
  children,
}: FocusProps<T>): ReturnType<React.FC<FocusProps<T>>> => {
  const ref = useRef<T | null>(null);

  const tryFocus = useCallback(() => {
    ref.current?.focus();
  }, []);

  // At the moment, `FormattedNumberInput` requires us to use a function-style
  // ref
  const setRef = useCallback(
    (element: T) => {
      ref.current = element;
      if (condition) {
        tryFocus();
      }
    },
    [condition, tryFocus],
  );

  useEffect(() => {
    if (condition) {
      tryFocus();
    }
  }, [condition, tryFocus]);

  return children(useMemo(() => ({ ref, setRef }), [setRef]));
};

export default Focus;
