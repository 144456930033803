import { COLOR, Icon } from '@cosuno/cosuno-ui';
import type { FC, ReactNode } from 'react';

import Trans from '~/shared/components/Trans';
import useTranslation from '~/shared/hooks/useTranslation';

import { AgentSignupLearnMoreLink } from '../AgentSignupLearnMoreLink';
import { Benefit, BenefitsSection, Strong } from './Styles';

export enum SIGN_UP_BENEFITS {
  addToWatchlist = 'addToWatchlist',
  findTendersViaCosuno = 'findTendersViaCosuno',
  freeBidSubmission = 'freeBidSubmission',
  manageBidRequests = 'manageBidRequests',
  moreBidInvites = 'moreBidInvites',
  principalPrefersBiddingViaCosuno = 'principalPrefersBiddingViaCosuno',
  principalPrefersCertificatesViaCosuno = 'principalPrefersCertificatesViaCosuno',
  principalPrefersCommunicationViaCosuno = 'principalPrefersCommunicationViaCosuno',
  registrationNecessaryForBidSubmission = 'registrationNecessaryForBidSubmission',
  signUpIsFree = 'signUpIsFree',
  uploadAndShareCertificatesFreely = 'uploadAndShareCertificatesFreely',
  manageAllBidRequestsInOnePlaceViaCosuno = 'manageAllBidRequestsInOnePlaceViaCosuno',
  getMoreBidInvites = 'getMoreBidInvites',
  viewTask = 'viewTask',
}

interface OfficeName {
  officeName: string;
}
interface UtmParams {
  utmCampaign: string;
}
export interface Props {
  [SIGN_UP_BENEFITS.addToWatchlist]?: UtmParams;
  [SIGN_UP_BENEFITS.findTendersViaCosuno]?: UtmParams;
  [SIGN_UP_BENEFITS.freeBidSubmission]?: true;
  [SIGN_UP_BENEFITS.manageBidRequests]?: UtmParams;
  [SIGN_UP_BENEFITS.moreBidInvites]?: true;
  [SIGN_UP_BENEFITS.principalPrefersBiddingViaCosuno]?: UtmParams & OfficeName;
  [SIGN_UP_BENEFITS.principalPrefersCertificatesViaCosuno]?: UtmParams & OfficeName;
  [SIGN_UP_BENEFITS.principalPrefersCommunicationViaCosuno]?: UtmParams & OfficeName;
  [SIGN_UP_BENEFITS.registrationNecessaryForBidSubmission]?: true;
  [SIGN_UP_BENEFITS.signUpIsFree]?: true;
  [SIGN_UP_BENEFITS.uploadAndShareCertificatesFreely]?: true;
  [SIGN_UP_BENEFITS.manageAllBidRequestsInOnePlaceViaCosuno]?: UtmParams;
  [SIGN_UP_BENEFITS.getMoreBidInvites]?: true;
  [SIGN_UP_BENEFITS.viewTask]?: true;
}

export const BenefitList: FC<Props> = (props: Props) => {
  const {
    addToWatchlist,
    findTendersViaCosuno,
    freeBidSubmission,
    manageBidRequests,
    moreBidInvites,
    principalPrefersBiddingViaCosuno,
    principalPrefersCertificatesViaCosuno,
    principalPrefersCommunicationViaCosuno,
    manageAllBidRequestsInOnePlaceViaCosuno,
    registrationNecessaryForBidSubmission,
    getMoreBidInvites,
    signUpIsFree,
    uploadAndShareCertificatesFreely,
    viewTask,
  } = props;

  const { t } = useTranslation('agentSignupForm');

  const renderItem = (item: ReactNode) => (
    <Benefit>
      <Icon type="check" color={COLOR.textTertiary} size={18} />
      <span>{item}</span>
    </Benefit>
  );

  return (
    <BenefitsSection>
      {principalPrefersBiddingViaCosuno &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.principalPrefersBiddingViaCosuno"
            components={{
              b: <Strong />,
              Link: (
                <AgentSignupLearnMoreLink
                  utmCampaign={principalPrefersBiddingViaCosuno.utmCampaign}
                />
              ),
            }}
            values={{ generalContractorName: principalPrefersBiddingViaCosuno.officeName }}
          />,
        )}
      {principalPrefersCertificatesViaCosuno &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.principalPrefersCertificatesViaCosuno"
            components={{
              b: <Strong />,
              Link: (
                <AgentSignupLearnMoreLink
                  utmCampaign={principalPrefersCertificatesViaCosuno.utmCampaign}
                />
              ),
            }}
            values={{ generalContractorName: principalPrefersCertificatesViaCosuno.officeName }}
          />,
        )}
      {principalPrefersCommunicationViaCosuno &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.principalPrefersCommunicationViaCosuno"
            components={{
              b: <Strong />,
              Link: (
                <AgentSignupLearnMoreLink
                  utmCampaign={principalPrefersCommunicationViaCosuno.utmCampaign}
                />
              ),
            }}
            values={{ generalContractorName: principalPrefersCommunicationViaCosuno.officeName }}
          />,
        )}
      {viewTask &&
        renderItem(<Trans t={t} i18nKey="benefits.viewTask" components={{ b: <Strong /> }} />)}
      {findTendersViaCosuno &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.findTendersViaCosuno"
            components={{
              b: <Strong />,
              Link: <AgentSignupLearnMoreLink utmCampaign={findTendersViaCosuno.utmCampaign} />,
            }}
          />,
        )}
      {manageAllBidRequestsInOnePlaceViaCosuno &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.manageAllBidRequestsInOnePlaceViaCosuno"
            components={{
              Link: (
                <AgentSignupLearnMoreLink
                  utmCampaign={manageAllBidRequestsInOnePlaceViaCosuno.utmCampaign}
                />
              ),
            }}
          />,
        )}
      {addToWatchlist &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.addToWatchlist"
            components={{
              b: <Strong />,
              Link: <AgentSignupLearnMoreLink utmCampaign={addToWatchlist.utmCampaign} />,
            }}
          />,
        )}
      {uploadAndShareCertificatesFreely &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.uploadAndShareCertificatesFreely"
            components={{ b: <Strong /> }}
          />,
        )}
      {manageBidRequests &&
        renderItem(
          <Trans
            t={t}
            i18nKey="benefits.manageBidRequests"
            components={{
              Link: <AgentSignupLearnMoreLink utmCampaign={manageBidRequests.utmCampaign} />,
            }}
          />,
        )}
      {moreBidInvites && renderItem(t('benefits.moreBidInvites'))}
      {registrationNecessaryForBidSubmission &&
        renderItem(<Trans t={t} i18nKey="benefits.registrationNecessaryForBidSubmission" />)}
      {freeBidSubmission &&
        renderItem(
          <Trans t={t} i18nKey="benefits.freeBidSubmission" components={{ b: <Strong /> }} />,
        )}
      {getMoreBidInvites && renderItem(<Trans t={t} i18nKey="benefits.getMoreBidInvites" />)}
      {signUpIsFree &&
        renderItem(<Trans t={t} i18nKey="benefits.signUpIsFree" components={{ b: <Strong /> }} />)}
    </BenefitsSection>
  );
};
