import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { useCallback, useMemo } from 'react';

import { mutationCreateAnalyticsEvent } from './api';

const useAnalyticsEventStore = () => {
  const [mutate] = useMutation(mutationCreateAnalyticsEvent);

  const createAnalyticsEvent = useCallback(
    async (name: string, props: object, deviceId?: string) => {
      try {
        await mutate({ variables: { input: { name, props, deviceId } } });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [mutate],
  );

  return useMemo(() => ({ createAnalyticsEvent }), [createAnalyticsEvent]);
};

export default useAnalyticsEventStore;
