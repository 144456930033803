import { MiniTooltip } from '@cosuno/cosuno-ui';
import { useChainedCommands } from '@remirror/react';
import React from 'react';

import DropzoneLink from '~/shared/components/DropzoneLink';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useTranslation from '~/shared/hooks/useTranslation';
import { getAcceptConfigFromMimeTypes } from '~/shared/utils/acceptFiles';

import { IMAGE_MIME_TYPES } from './constants';
import { StyledMenuButton } from './Styles';

const ImageSelect: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const { t } = useTranslation('textEditor');
  const { trackEvent } = useAnalytics();

  const chain = useChainedCommands();

  return (
    <DropzoneLink
      dropzoneProps={{
        disabled,
        multiple: false,
        accept: getAcceptConfigFromMimeTypes(IMAGE_MIME_TYPES),
        onDrop: ([file]) => {
          trackEvent('myAccountSignatureImage');
          chain.addImageFiles([file]).focus().run();
        },
      }}
    >
      <MiniTooltip<HTMLButtonElement>
        isTriggerElementClickable={!disabled}
        bodyText={disabled ? null : t('tooltips.image')}
      >
        {({ ref: forwardRef, getReferenceProps }) => (
          <StyledMenuButton
            size="small"
            aria-pressed={false}
            disabled={disabled}
            onlyIcon={{ type: 'image' }}
            variant="secondary"
            forwardRef={forwardRef}
            {...getReferenceProps()}
          />
        )}
      </MiniTooltip>
    </DropzoneLink>
  );
};

export default ImageSelect;
