import { HoverableElement } from '@cosuno/cosuno-ui';
import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

import { CelloButton, CelloButtonWrapper } from './Styles';

const CelloTrigger: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HoverableElement isTriggerElementClickable tooltipText={t('tooltips.icons.recommendCosuno')}>
      <CelloButtonWrapper>
        <CelloButton className="celloTrigger" onlyIcon={{ type: 'trophy' }} />
      </CelloButtonWrapper>
    </HoverableElement>
  );
};

export default CelloTrigger;
