import { COMPANY_SIZE, ORDER_RADIUS } from '~/__gql__/graphql';

export const orderedCompanySizes = [
  COMPANY_SIZE.size_1_4,
  COMPANY_SIZE.size_5_9,
  COMPANY_SIZE.size_10_19,
  COMPANY_SIZE.size_20_49,
  COMPANY_SIZE.size_50_99,
  COMPANY_SIZE.size_100_199,
  COMPANY_SIZE.size_200_499,
  COMPANY_SIZE.size_500plus,
];

export const orderedCompanySizeRangeStops = ['1', '5', '10', '20', '50', '100', '200'];

export const orderedOrderRadiuses = [
  ORDER_RADIUS.km_20,
  ORDER_RADIUS.km_50,
  ORDER_RADIUS.km_100,
  ORDER_RADIUS.km_200,
  ORDER_RADIUS.km_350,
  ORDER_RADIUS.km_500,
  ORDER_RADIUS.km_750,
  ORDER_RADIUS.km_1000,
  ORDER_RADIUS.km_2000,
  ORDER_RADIUS.km_3000,
  ORDER_RADIUS.unlimited,
];
