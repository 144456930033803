import { isEntered } from '@cosuno/cosuno-ui';
import type { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

import { font, mixins, zIndexValues } from '~/shared/utils/styles';

export const TRANSITION_DURATION = 100;
const OVERLAY_WIDTH = 650;

interface OverlayProps {
  $transitionState: TransitionStatus;
  $triggerCenterX: number | null;
}

const getOverlayRightPosition = (triggerCenterX: number | null): number => {
  const minimumRightPosition = 20;

  if (triggerCenterX === null) return minimumRightPosition;

  const triggerCenterXFromRight = window.innerWidth - triggerCenterX;

  return Math.max(triggerCenterXFromRight - OVERLAY_WIDTH / 2, minimumRightPosition);
};

export const Overlay = styled.div<OverlayProps>`
  ${mixins.boxShadowStrong()}

  background-color: white;
  border-radius: 4px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  position: fixed;
  right: ${(props) => getOverlayRightPosition(props.$triggerCenterX)}px;
  top: 50px;
  transform-origin: top center;
  width: ${OVERLAY_WIDTH}px;
  z-index: ${zIndexValues.modalOverlay};

  ${({ $transitionState }) =>
    isEntered($transitionState)
      ? css`
          opacity: 1;
          transform: scale(1) translateY(0);
          transition: ${TRANSITION_DURATION}ms ease-out;
        `
      : css`
          opacity: 0;
          transform: scale(0.5) translateY(-50px);
          transition: ${TRANSITION_DURATION}ms ease-in;
        `}
`;

export const Header = styled.div`
  ${font.size(21)}
  ${font.bold}

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const EmptyPlaceholder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px 0 70px;
`;

export const EmptyPlaceholderTitle = styled.div`
  ${font.medium}
`;

export const EmptyPlaceholderExplanation = styled.div`
  ${font.size(14)}

  margin-top: 10px;
`;
