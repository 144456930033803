import * as Sentry from '@sentry/browser';
import type { AxiosError } from 'axios';
import { assert } from 'ts-essentials';

import { env } from '~/shared/utils/env';

const SENTRY_OPTIONS = {
  dsn: env.REACT_APP_SENTRY_DSN,
  environment: env.MODE,
  release: env.REACT_APP_RELEASE_HASH,
};

const urlsToExcludeFromSentry = ['permissions-version', 'feature-flags-version', 'version'];

const shouldSendToSentry = (hint: Sentry.EventHint | undefined): boolean => {
  const { isAxiosError, config } = (hint?.originalException as AxiosError) || {};
  if (isAxiosError && config) {
    const url = config.url || '';
    for (const urlToExclude of urlsToExcludeFromSentry) {
      if (url.endsWith(urlToExclude)) {
        return false;
      }
    }
  }
  return true;
};

const shouldUseSentry = () => {
  // do not use in dev or test environments
  if (env.MODE !== 'production') {
    return false;
  }
  // do not use in production builds for cypress tests (ci)
  if (env.REACT_APP_CYPRESS === 'true') {
    return false;
  }
  // do not use when the page is not opened as cosuno hosted website (local saved file copy)
  if (typeof window !== 'undefined' && window.location?.protocol?.startsWith('http') !== true) {
    return false;
  }

  return true;
};

export const initializeSentry = () => {
  if (shouldUseSentry()) {
    assert(env.REACT_APP_SENTRY_DSN, 'Unable to find Sentry DSN environment variable.');

    Sentry.init({
      ...SENTRY_OPTIONS,
      beforeSend(event, hint) {
        return shouldSendToSentry(hint) ? event : null;
      },
    });
  }
};
