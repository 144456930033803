import { LANGUAGE } from '~/__gql__/graphql';

import { DEFAULT_LANGUAGE } from '../constants';
import { belongsToEnum } from '../utils/typescript';
import useCurrentUser from './useCurrentUser';
import useTranslation from './useTranslation';

const useLanguage = () => {
  const user = useCurrentUser();
  const { i18n } = useTranslation();

  if (user) {
    return user.language;
  }

  if (belongsToEnum(i18n.language, LANGUAGE)) {
    return i18n.language;
  }

  return DEFAULT_LANGUAGE;
};

export default useLanguage;
