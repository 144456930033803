import { COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { Dialog } from '~/shared/components/ModalStyles';

export const Content = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Title = styled(Dialog.Title)`
  padding-bottom: 0;
`;

export const TitleSuffix = styled.span`
  color: ${COLOR.warning};
`;

export const FeatureListWithIllustration = styled.div`
  display: flex;
`;

export const FeatureList = styled.div`
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 180px;
  justify-content: center;
  flex-shrink: 0;
`;

export const FeatureListItem = styled(Dialog.Paragraph)`
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const FeatureListItemDescription = styled.span`
  flex: 1;
`;

export const Actions = styled(Dialog.ControlWrapper)`
  margin-top: 10px;
  justify-content: flex-start;
`;

export const TrialDescription = styled.div`
  color: ${COLOR.textTertiary};
  ${textStyles.bodyMedium450};
  width: 645px;
`;

export const TrialDescriptionEmail = styled.span`
  ${textStyles.bodyMedium450};
`;
