import { useMutation } from '@apollo/client';
import { Button, ExternalLink, PageLoader } from '@cosuno/cosuno-ui';
import { QRCodeSVG } from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { MissingMfaToken } from '~/packages/Authentication/errors';
import Field from '~/shared/components/Field';
import Form from '~/shared/components/Form';
import QueryErrors from '~/shared/components/QueryErrors';
import { Heading, Tip } from '~/shared/components/UnauthenticatedStyles';
import AuthContext from '~/shared/contexts/authContext';
import type { LoginParams } from '~/shared/hooks/useAuthentication';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';
import toast from '~/shared/utils/toast';

import { mutationGenerateAuthenticatorSecret, mutationStoreAuthenticatorSecret } from './api';
import {
  AuthenticatorIdentifiersWrapper,
  AuthenticatorSecret,
  BottomLink,
  StyledFormElement,
  StyledLink,
} from './Styles';

interface Props {
  loginToken?: string;
  goBackToStart: () => void;
  onLogIn?: (params: LoginParams) => Promise<void>;
}

const AuthenticatorSetup: React.FC<Props> = ({ loginToken, goBackToStart, onLogIn }) => {
  const { t } = useTranslation('auth');
  const routes = useRoutes();

  const [stage, setStage] = useState<'setupAuthenticator' | 'complete'>('setupAuthenticator');

  const { fetchCurrentUser } = useContext(AuthContext);

  const [
    generateAuthenticatorSecret,
    { data: generateAuthenticatorSecretData, error: generateAuthenticatorSecretDataError },
  ] = useMutation(mutationGenerateAuthenticatorSecret);

  const [storeAuthenticatorSecret, { loading: loadingStoreAuthenticatorSecret }] = useMutation(
    mutationStoreAuthenticatorSecret,
  );

  useEffect(() => {
    void generateAuthenticatorSecret({ variables: { loginToken } }).catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (generateAuthenticatorSecretDataError) {
    return <QueryErrors error={generateAuthenticatorSecretDataError} />;
  }

  if (!generateAuthenticatorSecretData) {
    return <PageLoader />;
  }

  const { secret, uri } = generateAuthenticatorSecretData.generateAuthenticatorSecret;

  if (stage === 'setupAuthenticator') {
    return (
      <Form<{ code: string }>
        initialValues={{ code: '' }}
        validations={{
          code: Form.is.required(),
        }}
        onSubmit={async (values, helpers) => {
          try {
            const { data } = await storeAuthenticatorSecret({
              variables: { secret, code: values.code, loginToken },
            });
            const mfaToken = data?.storeAuthenticatorSecret.mfaToken;

            if (mfaToken) {
              if (onLogIn && loginToken) {
                await onLogIn({ loginToken, mfaToken });
              } else {
                toast.success(t('mfa.setupSuccess'));
                await fetchCurrentUser();
                setStage('complete');
              }
            } else {
              throw new MissingMfaToken();
            }
          } catch (error) {
            helpers.setFieldError('code', t('mfa.errorCodeInvalidOrExpired'));
            void helpers.setFieldTouched('code', true);
          }
        }}
      >
        <Heading>{t('mfa.authenticatorSetupHeadline')}</Heading>
        <Tip>
          <ExternalLink to="https://support.google.com/accounts/answer/1066447">
            {t('mfa.authenticatorDownloadApp')}
          </ExternalLink>
          {t('mfa.authenticatorSetupInstructions')}
        </Tip>
        <AuthenticatorIdentifiersWrapper>
          <QRCodeSVG value={uri} />
          <AuthenticatorSecret>{secret.match(/.{1,4}/g)?.join(' ')}</AuthenticatorSecret>
        </AuthenticatorIdentifiersWrapper>
        <Tip>{t('mfa.authenticatorEnterCode')}</Tip>
        <StyledFormElement>
          <Field.Input
            useMargins={false}
            required
            name="code"
            label={t('mfa.enterCode')}
            showAsRequired={false}
          />
          <Button fullWidth type="submit" working={loadingStoreAuthenticatorSecret}>
            {t('continue')}
          </Button>
          <BottomLink>
            <StyledLink onClick={goBackToStart}>{t('back')}</StyledLink>
          </BottomLink>
        </StyledFormElement>
      </Form>
    );
  }

  return <Redirect to={`${routes.profile()}/security`} />;
};

export default AuthenticatorSetup;
