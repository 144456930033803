import { LANGUAGE } from '~/__gql__/graphql';

export const principalUserHelpUrl = (language = LANGUAGE.de) =>
  language === LANGUAGE.de ? 'https://help.cosuno.com' : 'https://help.cosuno.com/en';

export const agentUserHelpUrl = (language = LANGUAGE.de) =>
  language === LANGUAGE.de
    ? 'https://help.cosuno.com/de/collections/2500825-fur-handwerker'
    : 'https://help.cosuno.com/en/collections/2500825-you-are-a-subcontractor';

export const agentLearnMoreUrl = (page: string, campaign: string, language: LANGUAGE) =>
  language === LANGUAGE.de
    ? `https://www.cosuno.com/de/subcontractor-features?utm_source=product&utm_medium=${page}&utm_campaign=${campaign}`
    : `https://www.cosuno.com/subcontractor-features?utm_source=product&utm_medium=${page}&utm_campaign=${campaign}`;

export const SURE_IN_LINK = 'https://surein.de/partner/cosuno?mid=de.par.p_001-0001';

export const COSUNO_FINANCE_URL = 'https://finanz.cosuno.com';
