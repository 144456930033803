import {
  Button,
  Cell,
  COLOR,
  ContentWithToolbar,
  Icon,
  SearchInput,
  SelectTrigger,
  Table,
  Toolbar,
  ValueSingle,
} from '@cosuno/cosuno-ui';
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';

import Modal from '~/shared/components/Modal';
import { ModalWindow } from '~/shared/components/ModalStyles';
import ReadOnlyCostGroupDefinitions from '~/shared/components/ReadOnlyCostGroupDefinitions';
import { MODAL_VARIANTS } from '~/shared/constants';
import useModalState from '~/shared/hooks/useModalState';
import useTranslation from '~/shared/hooks/useTranslation';

import { TableWrapper } from './Styles';
import type { CostGroupDefinition } from './types';
import { filterGroups } from './utils';

export interface CostGroupSelectorProps {
  catalogName: string;
  costGroups: CostGroupDefinition[];
  onChange: (localId: string) => void;
  name: string;
  value?: string;
  onTriggerClick?: () => void;
}

const CostGroupSelector: React.FC<CostGroupSelectorProps> = ({
  catalogName,
  costGroups,
  onChange,
  onTriggerClick,
  ...props
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const modalState = useModalState({ onClose: () => setSearchValue('') });

  const filteredCostGroups = filterGroups(costGroups, searchValue.trim().toLowerCase());

  return (
    <>
      <Modal
        {...modalState}
        name={`cost-group-selector-${props.name}`}
        isScrollableWithin
        variant={MODAL_VARIANTS.fullSize}
        renderContent={() => (
          <ModalWindow.Container>
            <ModalWindow.Header>{catalogName}</ModalWindow.Header>
            <ModalWindow.ScrollableContent $verticallyScrollable>
              {/* Have to reset topOffset here because of nested usage of ContentWithToolbar */}
              <ThemeProvider theme={(theme) => ({ ...theme, topOffset: 0 })}>
                <ContentWithToolbar
                  toolbar={
                    <Toolbar>
                      <SearchInput value={searchValue} onChange={setSearchValue} />
                    </Toolbar>
                  }
                >
                  <TableWrapper>
                    <Table
                      dataCount={filteredCostGroups.length}
                      columns={[
                        {
                          label: t('common:costGroupSelector.costGroup'),
                        },
                        {
                          minWidth: 0,
                        },
                      ]}
                      rows={filteredCostGroups.map((costGroup) => (
                        <ReadOnlyCostGroupDefinitions
                          {...costGroup}
                          key={costGroup.localId}
                          extraCell={({ localId, childCostGroups }) => (
                            <Cell.Base size="compact">
                              {!childCostGroups?.length && (
                                <Button
                                  variant="secondaryBrand"
                                  size="small"
                                  onClick={() => {
                                    onChange(localId);
                                    modalState.closeModal();
                                  }}
                                >
                                  {t('common:select')}
                                </Button>
                              )}
                            </Cell.Base>
                          )}
                        />
                      ))}
                    />
                  </TableWrapper>
                </ContentWithToolbar>
              </ThemeProvider>
            </ModalWindow.ScrollableContent>
            <ModalWindow.Footer $showBorder={false}>
              <Button
                variant="secondary"
                size="small"
                onClick={() => {
                  onChange('');
                  modalState.closeModal();
                }}
              >
                {t('common:costGroupSelector.clearButtonLabel')}
              </Button>
            </ModalWindow.Footer>
          </ModalWindow.Container>
        )}
      />
      <SelectTrigger
        data-name={props.name}
        placeholder={t('common:select')}
        isValueEmpty={!props.value}
        renderValue={() => <ValueSingle>{props.value}</ValueSingle>}
        iconRight={<Icon type="import" size={18} color={COLOR.textTertiary} />}
        onClick={() => {
          onTriggerClick?.();
          modalState.openModal();
        }}
      />
    </>
  );
};

export default CostGroupSelector;
