import { TypedEventTarget } from 'typescript-event-target';

import type { ModalEventMap } from '~/shared/hooks/useModalState';

type ActionPerformedArgs<Params, Result = unknown> =
  | { action: 'confirm'; result: Result; params: Params | null }
  | { action: 'discard' | 'cancel'; result: false; params: Params | null };

export class ConfirmationModalActionPerformedEvent<Params, Result = unknown> extends CustomEvent<
  ActionPerformedArgs<Params, Result>
> {
  constructor(type: string, detail: ActionPerformedArgs<Params, Result>) {
    super(type, { detail });
  }
}

export interface ConfirmationModalEventMap<Params, Result> extends ModalEventMap {
  confirm: ConfirmationModalEvent<Params>;
  cancel: ConfirmationModalEvent<Params>;
  actionPerformed: ConfirmationModalActionPerformedEvent<Params, Result>;
}

export class ConfirmationModalEvent<Params> extends CustomEvent<Params | null> {
  constructor(type: string, params: Params | null) {
    super(type, { detail: params });
  }
}

export class ConfirmationModalApi<Params, Result = {}> extends TypedEventTarget<
  ConfirmationModalEventMap<Params, Result>
> {
  open: (params: Params) => void;

  close: () => void;

  constructor(open: (params: Params) => void, close: () => void) {
    super();
    this.open = open;
    this.close = close;
  }
}
