import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import isURL from 'validator/es/lib/isURL';

import type { AnyDate } from '~/shared/constants/dateTime';

import { isEmailAddress } from '.';
import type { Validator, Values } from './types';

const isNil = <T>(value: undefined | null | T): value is undefined | null =>
  value === undefined || value === null;

const isEmpty = (value: unknown): boolean => typeof value === 'string' && value.trim() === '';

const match =
  <T = unknown>(testFn: (value: T, values: object) => boolean, message = ''): Validator<T> =>
  ({ value, values }) =>
    !testFn(value, values) && message;

const notAfter =
  <V = Values>(
    getTargetDate: (values: V) => AnyDate | null,
    message: string,
  ): Validator<AnyDate | null, V> =>
  ({ value, values }) => {
    const targetDate = getTargetDate(values);

    if (!value || !targetDate || moment(value).isSameOrBefore(targetDate, 'minutes')) {
      return false;
    }

    return message;
  };

const notBefore =
  <V = Values>(
    getTargetDate: (values: V) => AnyDate | null,
    message: string,
  ): Validator<AnyDate | null, V> =>
  ({ value, values }) => {
    const targetDate = getTargetDate(values);

    if (!value || !targetDate || moment(value).isSameOrAfter(targetDate, 'minutes')) {
      return false;
    }

    return message;
  };

const required =
  (message?: string): Validator =>
  ({ value, t }) => {
    if (Array.isArray(value) && value.length === 0) {
      return message ?? t('validation:required');
    }

    if (isNil(value) || isEmpty(value)) {
      return message ?? t('validation:required');
    }

    return false;
  };

const minLength =
  (min: number, message?: string): Validator<string | unknown[] | null> =>
  ({ value, t }) =>
    Boolean(value) &&
    value !== null &&
    (typeof value === 'string' ? value.trim() : value).length < min &&
    (message || t('validation:minLength', { min }));

const maxLength =
  (max: number): Validator<string | unknown[] | null> =>
  ({ value, t }) =>
    Boolean(value) &&
    value !== null &&
    (typeof value === 'string' ? value.trim() : value).length > max &&
    t('validation:maxLength', { max });

interface MinMaxOptions {
  treatFloatAsPercentage?: boolean;
}

const min =
  <V = Values>(
    minValue: number | ((values: V) => number | null),
    { treatFloatAsPercentage = false }: MinMaxOptions = {},
  ): Validator<string | number | null, V> =>
  ({ value, values, t }) => {
    const localMinValue = typeof minValue === 'number' ? minValue : minValue(values);

    if (isNil(localMinValue)) {
      return false;
    }

    const valueAsNumber = parseFloat(String(value));
    const transformedValue = treatFloatAsPercentage ? valueAsNumber * 100 : valueAsNumber;

    return transformedValue < localMinValue && t('validation:min', { minValue: localMinValue });
  };

const max =
  (
    maxValue: number,
    { treatFloatAsPercentage = false }: MinMaxOptions = {},
  ): Validator<string | number | null> =>
  ({ value, t }) => {
    if (isNil(value)) {
      return false;
    }

    const valueAsNumber = parseFloat(String(value));
    const transformedValue = treatFloatAsPercentage ? valueAsNumber * 100 : valueAsNumber;

    return transformedValue > maxValue && t('validation:max', { maxValue });
  };

const notEmptyArray =
  (): Validator<unknown[]> =>
  ({ value, t }) =>
    (!Array.isArray(value) || value.length === 0) && t('validation:notEmptyArray');

const email =
  (): Validator<string> =>
  ({ value, t }) =>
    Boolean(value) && !isEmailAddress(value) && t('validation:email');

const mobilePhone =
  (): Validator<string> =>
  ({ value, t }) =>
    !isValidPhoneNumber(value) && t('validation:mobilePhone');

const url =
  (): Validator<string> =>
  ({ value, t }) =>
    Boolean(value) &&
    !isURL(value, { protocols: ['http', 'https'], allow_underscores: true, disallow_auth: true }) &&
    t('validation:url');

const notSurroundedByWhitespace =
  (): Validator<string> =>
  ({ value, t }) =>
    Boolean(value) && /^\s|\s$/.test(value) && t('validation:notSurroundedByWhitespace');

const nestedValueUnique =
  <V = Values, AllV = Values>(
    getObjects: (allValues: AllV) => V[],
    getNestedValue: (values: V) => V[keyof V],
    message: string,
  ): Validator<V[keyof V], V, AllV> =>
  ({ value, allValues }) => {
    const sameNestedFields = getObjects(allValues).filter(
      (object) => getNestedValue(object) === value,
    );

    if (value && sameNestedFields.length > 1) {
      return message;
    }

    return false;
  };

const integer =
  (): Validator<string | number | null> =>
  ({ value, t }) =>
    Boolean(value) && parseFloat(String(value)) % 1 !== 0 && t('validation:integer');

const iban =
  (): Validator<string> =>
  ({ value, t }) =>
    Boolean(value) &&
    !/^[A-Z]{2}[0-9]{2}(?: ?[0-9A-Z]{4})+ ?[0-9A-Z]{1,4}$/.test(value) &&
    t('validation:iban');

const bic =
  (): Validator<string> =>
  ({ value, t }) =>
    Boolean(value) && !/^[A-Z]{6}[0-9A-Z]{2}([0-9A-Z]{3})?$/i.test(value) && t('validation:bic');

const unique =
  (existingValuesArr: unknown[], message: string): Validator<unknown> =>
  ({ value }) =>
    existingValuesArr.includes(value) ? message : false;

const some =
  <T extends unknown>(
    testFn: (value: T, values: object) => boolean,
    message = '',
  ): Validator<T[]> =>
  ({ value, values }) =>
    !value.some((element) => testFn(element, values)) && message;

const hexColor =
  (): Validator<string> =>
  ({ value, t }) =>
    Boolean(value) && !/^#(?:[0-9a-fA-F]{3}){1,2}$/i.test(value) && t('validation:hexColor');

export const is = {
  match,
  required,
  minLength,
  maxLength,
  min,
  max,
  notEmptyArray,
  email,
  mobilePhone,
  url,
  notAfter,
  notBefore,
  notSurroundedByWhitespace,
  nestedValueUnique,
  integer,
  iban,
  bic,
  unique,
  some,
  hexColor,
};
