import { graphql } from '~/__gql__';

export const mutationStartTrial = graphql(/* GraphQL */ `
  mutation StartTrial($module: TRIAL_MODULE!) {
    startTrial(module: $module)
  }
`);

export const mutationRequestPricingPlanUpsell = graphql(/* GraphQL */ `
  mutation RequestPricingPlanUpsell($pricingPlan: PRICING_PLAN) {
    requestPricingPlanUpsell(pricingPlan: $pricingPlan)
  }
`);
