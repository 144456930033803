import { useActive, useChainedCommands } from '@remirror/react';
import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

import { StyledFontSizeSelect } from './Styles';

const FONT_SIZES = [
  3, 4, 4.5, 5, 6, 6.5, 7, 8, 9, 10, 10.5, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 28, 34, 36, 48,
].map((size) => ({ size: String(size), sizeWithUnit: `${size}pt` }));

const FontSizeSelect: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation('textEditor');
  const active = useActive();
  const chain = useChainedCommands();

  const options = FONT_SIZES.map(({ size, sizeWithUnit }) => ({
    label: t('fontSize', { fontSize: size }),
    value: sizeWithUnit,
  }));

  const currentSize =
    FONT_SIZES.find(({ sizeWithUnit }) => active.fontSize({ size: sizeWithUnit }))?.sizeWithUnit ??
    '10.5pt';

  const handleFontSizeChange = (newValue: string) => {
    chain.setFontSize(newValue).focus().run();
  };

  return (
    <StyledFontSizeSelect
      name="font-size-select"
      required
      disabled={disabled}
      fixedDropdownCardWidth={105}
      dropdownPlacement="bottom-start"
      showSearch={false}
      size="small"
      value={currentSize}
      options={options}
      onChange={handleFontSizeChange}
    />
  );
};

export default FontSizeSelect;
