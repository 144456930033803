import { useCallback, useEffect, useState } from 'react';

import { env } from '~/shared/utils/env';
import injectScript from '~/shared/utils/injectScript';

import useTranslation from '../useTranslation';
import type { GetGoogleMapsApi } from './context';
import type { PlaceMock } from './mock/types';

export const useGoogleMapsApiManager = (): GetGoogleMapsApi => {
  const [state, setState] = useState<'idle' | 'loading' | 'done'>('idle');

  const { i18n } = useTranslation();

  const loadApi = useCallback(
    (useMock = env.REACT_APP_CYPRESS === 'true') => {
      // Fetch and load Maps API on init and when language changes
      if (window.google?.maps) {
        setState('loading');
        document.getElementById('gmApiScript')?.remove();
        delete (window.google as { maps?: unknown }).maps;
      }

      window.gmApiCallback = () => setState('done');

      if (!useMock) {
        injectScript({
          src: `https://maps.googleapis.com/maps/api/js?key=${env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=gmApiCallback&language=${i18n.language}`,
          id: 'gmApiScript',
        });
      } else {
        void import('./mock/mockLoader').then(({ default: register }) => {
          requestAnimationFrame(() => {
            // eslint-disable-next-line no-console
            console.log('registered google maps mock');
            register();
            setState('done');
          });
        });
      }
    },
    [i18n.language],
  );

  // expose binding for mocks
  if (env.REACT_APP_CYPRESS === 'true' || env.MODE !== 'production') {
    (window.mocks ||= {}).enablePlacesAutocompleteMock = () => {
      loadApi(true);
    };
    (window.mocks ||= {}).disablePlacesAutocompleteMock = () => {
      loadApi(false);
    };
    (window.mocks ||= {}).setPlacesAutocompleteMocks = (mocks: PlaceMock[]) => {
      (window.mocks ||= {}).placesAutocomplete = mocks;
    };
    (window.mocks ||= {}).addPlacesAutocompleteMock = (mock: PlaceMock) => {
      ((window.mocks ||= {}).placesAutocomplete ||= []).push(mock);
    };
  }

  useEffect(() => {
    if (state === 'idle') return;

    i18n.on('languageChanged', loadApi);

    return () => i18n.off('languageChanged', loadApi);
  });

  // noinspection UnnecessaryLocalVariableJS
  const getGoogleMapsApi = useCallback(() => {
    switch (state) {
      case 'idle': {
        loadApi();
        return null;
      }

      case 'loading':
        return null;

      case 'done':
        return window.google;
    }
  }, [loadApi, state]);

  return getGoogleMapsApi;
};

export default useGoogleMapsApiManager;
