import { repeat } from '~/shared/utils/javascript';

export const formatWithLocale = (locale: string, value: number | string, trailingZeros: number) =>
  value === ''
    ? ''
    : new Intl.NumberFormat(locale, {
        minimumFractionDigits: trailingZeros,
        maximumFractionDigits: trailingZeros,
      }).format(Number(value));

// As the Intl API does not provide any introspection capabilities, we have to use slightly hacky
// ways of getting localized separators from it. In the future, using `formatToParts` might be
// better, but it is not supported on IE11.
export const getDecimalSeparator = (locale: string) => {
  const numThatIncludesDecimalSeparator = formatWithLocale(locale, 1.5, 2);

  return numThatIncludesDecimalSeparator.replace(/\d/g, '');
};

export const getNumeralGroupSeparator = (locale: string) => {
  const numThatIncludesGroupSeparator = formatWithLocale(locale, 1000000000, 0);

  return numThatIncludesGroupSeparator.replace(/\d/g, '')[0];
};

export const removeTrailingZeros = (value: string) => {
  if (!value) return '';

  return value.replace(/\.0*$/, '');
};

const getValueWithTrailingZeros = (value: string, trailingZeros: number): string => {
  if (!value) {
    return '';
  }

  if (trailingZeros === 0) {
    return value.replace(/\.\d*$/, '');
  }

  if (!value.includes('.')) {
    return `${value}.${repeat('0', trailingZeros)}`;
  }

  const result = /\.(\d*)$/.exec(value);

  if (result) {
    const [, match] = result;

    if (match.length < trailingZeros) {
      return value + repeat('0', trailingZeros - match.length);
    }
  }

  return value;
};

const getValueWithoutLeadingSeparator = (value: string): string =>
  value.startsWith('.') ? `0${value}` : value;

export const getFormattedRawValue = (value: number | string, trailingZeros: number): string => {
  const stringValue = String(value);
  const isNegative = stringValue.startsWith('-');
  const valueWithoutSign = isNegative ? stringValue.slice(1) : stringValue;
  const formattedValue = getValueWithTrailingZeros(
    getValueWithoutLeadingSeparator(valueWithoutSign),
    trailingZeros,
  );

  if (Number(formattedValue) === 0) {
    return formattedValue;
  }

  return isNegative ? `-${formattedValue}` : formattedValue;
};
