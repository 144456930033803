/* eslint-disable no-console */
import { env } from '~/shared/utils/env';

type Noop = () => void;

const onlyRunInMode = <T>(func: T, allowedMode: typeof env.MODE | null): T | Noop => {
  if (allowedMode === null || env.MODE === allowedMode) return func;
  return () => {};
};

export const createConsole = (onlyRunInDevelopment = true) => {
  const allowedMode = onlyRunInDevelopment ? 'development' : null;

  return {
    groupCollapsed: onlyRunInMode(console.groupCollapsed.bind(console), allowedMode),
    groupEnd: onlyRunInMode(console.groupEnd.bind(console), allowedMode),
    log: onlyRunInMode(console.log.bind(console), allowedMode),
    error: onlyRunInMode(console.error.bind(console), allowedMode),
    table: onlyRunInMode(console.table.bind(console), allowedMode),
    warn: onlyRunInMode(console.warn.bind(console), allowedMode),
    trace: onlyRunInMode(console.trace.bind(console), allowedMode),
  };
};

export default createConsole();
