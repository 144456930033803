import { useContext } from 'react';
import { assert } from 'ts-essentials';

import { FeatureFlagsContext } from '../context/FeatureFlagsContext';

export const useFeatureFlags = () => {
  const featureFlags = useContext(FeatureFlagsContext);

  assert(featureFlags, '`useFeatureFlagsContext` must be used inside of a `FeatureFlagsContext`.');

  return featureFlags;
};
