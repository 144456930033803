import { COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { Dialog } from '~/shared/components/ModalStyles';

export const Wrapper = styled.div`
  display: flex;
`;

export const Main = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 470px;
`;

export const Title = styled(Dialog.Title)`
  padding-bottom: 0;
`;

export const Description = styled(Dialog.Paragraph)`
  margin-bottom: 0;
  flex-grow: 1;
`;

export const Actions = styled(Dialog.ControlWrapper)`
  margin-top: 10px;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 32px;
  width: 330px;
  flex-shrink: 0;
  border-radius: 0 20px 20px 0;
  background-color: ${COLOR.backgroundLight};
  box-shadow:
    0 4px 20px 0 rgba(0, 28, 75, 0.02),
    0 1px 4px 0 ${COLOR.boxShadowLightAlpha};
`;

export const SidebarTitle = styled.div`
  ${textStyles.labelLarge500};
  color: ${COLOR.textPrimary};
  width: 244px;
`;

interface SidebarItemsListProps {
  $columns: number;
}

export const SidebarItemsList = styled.ul<SidebarItemsListProps>`
  display: grid;
  grid-template-columns: ${(props) => Array(props.$columns).fill('1fr').join(' ')};
  gap: 12px;
`;

export const SidebarItem = styled.li`
  ${textStyles.bodyMedium450};
  color: ${COLOR.textSecondary};
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: ${COLOR.highlightNeon}0d; /* 0d here is for 5% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TrialDescription = styled(Description)`
  color: ${COLOR.textPrimary};
`;

export const TrialDescriptionEmail = styled.span`
  ${textStyles.labelLarge500};
`;
