import { Icon } from '@cosuno/cosuno-ui';

import { getUserName } from '~/shared/utils/names';

import type { LoginSuggestion } from '../types';
import {
  EmailText,
  SuggestionListItem,
  SuggestionListItemContainer,
  SuggestionListItemNameAndEmail,
  UserAvatar,
  UserName,
} from './Styles';

export function SuggestionListItemContent({
  suggestion,
  ...props
}: {
  suggestion: LoginSuggestion;
  tabIndex?: number;
  onClick?: () => void;
}) {
  const userName = getUserName(suggestion);

  return (
    <SuggestionListItemContainer
      role="listitem"
      {...{
        ...(props.onClick && {
          'data-cy-user-login-option': suggestion.email,
          as: 'button',
          role: 'radio',
        }),
      }}
      {...props}
    >
      <SuggestionListItem>
        {userName && <UserAvatar name={userName} />}
        <SuggestionListItemNameAndEmail>
          {userName && <UserName>{userName}</UserName>}
          <EmailText $isHighlighted={!userName}>{suggestion.email}</EmailText>
        </SuggestionListItemNameAndEmail>
      </SuggestionListItem>
      {props.onClick && <Icon type="chevron-right" size={16} />}
    </SuggestionListItemContainer>
  );
}
