import { Button, Image } from '@cosuno/cosuno-ui';

import useTranslation from '~/shared/hooks/useTranslation';

import {
  ImageContainer,
  ImagePreview,
  ImagePreviewContainer,
  RemoveLinkContainer,
  UploadedImagesContainer,
} from './Styles';

export const IMAGE_UPLOADER_IMAGE_OPTIONS = { width: 500, height: 150 };

interface UploadedImage {
  imageKey?: string;
  previewUrl?: string;
}

interface UploadedImageProps extends UploadedImage {
  onLoad: () => void;
}

const UploadedImage: React.FC<UploadedImageProps> = ({ imageKey, previewUrl, onLoad }) => {
  const { t } = useTranslation();

  return (
    <ImagePreviewContainer>
      {previewUrl && <ImagePreview src={previewUrl} alt={t('imageUploader.uploadedImage')} />}
      {imageKey && (
        <Image
          imageKey={imageKey}
          imageOptions={IMAGE_UPLOADER_IMAGE_OPTIONS}
          alt={t('imageUploader.uploadedImage')}
          onLoad={onLoad}
        />
      )}
    </ImagePreviewContainer>
  );
};

interface UploadedImagesProps {
  images: UploadedImage[];
  removePreview: (previewUrl: string) => void;
  removeImage: (imageKey: string) => void;
}

export const UploadedImages: React.FC<UploadedImagesProps> = ({
  images,
  removePreview,
  removeImage,
}) => {
  const { t } = useTranslation();

  return (
    <UploadedImagesContainer>
      {images.map(({ imageKey, previewUrl }, index) => (
        <ImageContainer key={imageKey ?? previewUrl ?? index}>
          <UploadedImage
            imageKey={imageKey}
            previewUrl={previewUrl}
            onLoad={() => {
              if (previewUrl) {
                removePreview(previewUrl);
              }
            }}
          />
          <RemoveLinkContainer>
            <Button
              variant="secondaryBrand"
              onClick={imageKey ? () => removeImage(imageKey) : undefined}
            >
              {t('imageUploader.removeImage')}
            </Button>
          </RemoveLinkContainer>
        </ImageContainer>
      ))}
    </UploadedImagesContainer>
  );
};
