import {
  type ApolloQueryResult,
  type OperationVariables,
  type QueryHookOptions,
  useQuery,
} from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { FetchMore, QueryResult, Refetch } from '~/shared/types/graphql';

export const FETCH_MORE_RACE_CONDITION_REJECTION =
  'This fetchMore call was rejected because it happened while we were refetching the same query.';

// The type for fetchMore in QueryResult is strange, resulting in arguments
// typed as any[]. So instead we're providing a slightly stricter version of
// that type
type QueryWithoutRaceConditionsResult<TData, TVariables extends OperationVariables> = Omit<
  QueryResult<TData, TVariables>,
  'fetchMore'
> & { fetchMore: FetchMore<TData, TVariables> };

interface MethodsWithoutRaceConditions<TData, TVariables> {
  fetchMore: FetchMore<TData, TVariables>;
  refetch: Refetch<TData, TVariables>;
}

type RefetchThunk<TData> = () => Promise<ApolloQueryResult<TData>>;

// There exists a race condition between fetchMore and refetch, both of which
// can be called simultaneously. See this Github issue for more details:
// https://github.com/apollographql/apollo-client/issues/2813. There exists a
// fix, but it hasn't been merged into Apollo. If it ever does, this workaround
// can be removed.
const useMethodsWithoutRaceConditions = <TData, TVariables>(
  options: MethodsWithoutRaceConditions<TData, TVariables>,
): MethodsWithoutRaceConditions<TData, TVariables> => {
  const [isLoading, setIsLoading] = useState(false);
  const lastRefetch = useRef<RefetchThunk<TData> | null>(null);

  const fetchMore: FetchMore<TData, TVariables> = useCallback(
    async (...args) => {
      if (isLoading) {
        return Promise.reject(FETCH_MORE_RACE_CONDITION_REJECTION);
      }

      setIsLoading(true);

      const result = await options.fetchMore(...args);

      setIsLoading(false);

      return result;
    },
    [isLoading, options],
  );

  const refetch: Refetch<TData, TVariables> = useCallback(
    async (...args) => {
      if (isLoading) {
        lastRefetch.current = () => options.refetch(...args);
      }

      setIsLoading(true);

      const result = await options.refetch(...args);

      setIsLoading(false);

      return result;
    },
    [isLoading, options],
  );

  const executeQueue = useCallback(async () => {
    if (!lastRefetch.current) {
      return;
    }

    setIsLoading(true);

    await lastRefetch.current();

    lastRefetch.current = null;
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    executeQueue();
  }, [executeQueue, fetchMore, isLoading, refetch]);

  return { fetchMore, refetch };
};

const useQueryWithoutRaceConditions = <TData, TVariables extends OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): QueryWithoutRaceConditionsResult<TData, TVariables> => {
  const queryResults = useQuery<TData, TVariables>(query, options);

  const { fetchMore, refetch } = useMethodsWithoutRaceConditions<TData, TVariables>(queryResults);

  return {
    ...queryResults,
    fetchMore,
    refetch,
  };
};

export default useQueryWithoutRaceConditions;
