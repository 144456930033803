import { type ApolloError, isApolloError } from '@apollo/client';
import { showToast } from '@cosuno/cosuno-ui';
import type { GraphQLError } from 'graphql';
import i18n from 'i18next';
import type React from 'react';

import { ERROR_TYPES } from '~/shared/constants';
import { doesErrorMatchCode, isGraphQLError } from '~/shared/utils/apollo';
import { isNetworkError } from '~/shared/utils/errors';

interface ErrorOptions {
  title?: string;
  message: string;
}

/**
 * Can be used to define which title and message should be shown
 * by throwing an error, without showing an additional toast,
 * in places where a caught exception is already passed to `toast.error`.
 *
 * This allows for more flexibility during implementation but
 * is not the default way to show an error toast. See {@link error} instead.
 */
export class ToastError extends Error {
  readonly extensions: { errorToast: ErrorOptions };

  constructor(errorToast: ErrorOptions) {
    super();

    this.extensions = { errorToast };
  }
}

const success = (title: React.ReactNode, message?: string): void => showToast({ title, message });

const info = (title: string, message?: string, duration?: number): void =>
  showToast({ title, message, duration, type: 'info' });

const warning = (title: string, message?: string): void =>
  showToast({ type: 'warning', title, message, duration: 0 });

const errorBase = ({ title, message }: ErrorOptions): void => {
  const errorTitle = title || i18n.t('error');

  showToast({
    type: 'danger',
    title: errorTitle,
    message,
    duration: 0,
  });
};

const errorGeneric = (): void => {
  errorBase({ title: i18n.t('genericError.title'), message: i18n.t('genericError.message') });
};

const shouldShowGeneralError = (graphQLError: GraphQLError) =>
  ['INTERNAL_SERVER_ERROR', 'GENERIC_ERROR'].includes(graphQLError.extensions?.code as string);

const error = (errorObjOrOptions?: Error | ApolloError | GraphQLError | ErrorOptions): void => {
  if (!errorObjOrOptions) {
    errorGeneric();
    return;
  }

  if (!(errorObjOrOptions instanceof Error)) {
    errorBase(errorObjOrOptions);
    return;
  }

  if (isNetworkError(errorObjOrOptions)) {
    errorBase({
      title: i18n.t('networkError.title'),
      message: i18n.t('networkError.message'),
    });
    return;
  }

  if (isApolloError(errorObjOrOptions)) {
    if (errorObjOrOptions.networkError) return;

    if (doesErrorMatchCode(errorObjOrOptions, ERROR_TYPES.docuSignEnvelopeDoesNotExistError)) {
      warning(
        i18n.t('projectBidPackages:contract.docuSignEnvelopeExpiredWarning.title'),
        i18n.t('projectBidPackages:contract.docuSignEnvelopeExpiredWarning.body'),
      );
      return;
    }

    const uniqueError = errorObjOrOptions.graphQLErrors.find(
      (graphQLError) => graphQLError.extensions && !shouldShowGeneralError(graphQLError),
    );

    if (uniqueError) {
      errorBase({ message: uniqueError.message });
      return;
    }
  }

  if (isGraphQLError(errorObjOrOptions) && !shouldShowGeneralError(errorObjOrOptions)) {
    errorBase({ message: errorObjOrOptions.message });
    return;
  }

  if (errorObjOrOptions instanceof ToastError) {
    errorBase(errorObjOrOptions.extensions.errorToast);
    return;
  }

  errorGeneric();
};

export default {
  error,
  success,
  info,
  warning,
};
