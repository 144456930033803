import { COLOR, Tag } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import useTranslation from '../hooks/useTranslation';

export const StyledNewTag = styled(Tag).attrs({ color: COLOR.brand })`
  padding: 1px 4px;
  margin-left: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;

const NewTag = () => {
  const { t } = useTranslation();
  return <StyledNewTag>{t('new')}</StyledNewTag>;
};

export default NewTag;
