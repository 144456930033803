import mapboxgl from 'mapbox-gl';
import React from 'react';
import { BrowserRouter, Prompt } from 'react-router-dom';

import MessagePage from '~/shared/components/MessagePage';
import { mapboxToken } from '~/shared/constants/mapbox';
import useMaintenanceMode from '~/shared/hooks/useMaintenanceMode';
import useTranslation from '~/shared/hooks/useTranslation';

import AppContent from './AppContent';
import BaseStyles from './BaseStyles';
import BeamerScript from './BeamerScript';
import { BootstrapStyles } from './BootstrapStyles';
import CelloScript from './CelloScript';
import Contexts from './Contexts';
import NormalizeStyles from './NormalizeStyles';

if (mapboxToken) {
  mapboxgl.accessToken = mapboxToken;
}

const App: React.FC = () => {
  const { t } = useTranslation();

  const { isEnabled: isMaintenanceModeEnabled } = useMaintenanceMode();

  if (isMaintenanceModeEnabled) {
    return (
      <>
        <NormalizeStyles />
        <BaseStyles />
        <MessagePage
          isAuthenticated={false}
          pageTitle={t('maintenance:title')}
          heading={t('maintenance:title')}
          message={t('maintenance:explanation')}
        />
      </>
    );
  }

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        if (window.getUserConfirmation) {
          window.getUserConfirmation(message, callback);
        } else {
          callback(true);
        }
      }}
    >
      <Prompt message="Prompt is required for getUserConfirmation to fire, even if the message is never used" />
      <BootstrapStyles>
        <Contexts>
          <BeamerScript />
          <CelloScript />
          <AppContent />
        </Contexts>
      </BootstrapStyles>
    </BrowserRouter>
  );
};

export default App;
