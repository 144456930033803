export enum KEY_NAME {
  escape = 'Escape',
  tab = 'Tab',
  enter = 'Enter',
  end = 'End',
  home = 'Home',
  arrowLeft = 'ArrowLeft',
  arrowUp = 'ArrowUp',
  arrowRight = 'ArrowRight',
  arrowDown = 'ArrowDown',
  space = ' ',
  Backspace = 'Backspace',
  Delete = 'Delete',
}
