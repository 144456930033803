import type {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';

import { usePageVisibility } from './usePageVisibility';

export const usePollWhenVisible = <
  TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: NonNullable<
    Omit<QueryHookOptions<TData, TVariables>, 'pollInterval'> &
      Required<Pick<QueryHookOptions<TData, TVariables>, 'pollInterval'>>
  >,
) => {
  const response = useQuery(query, options);
  const { isPageVisible, wasPagePreviouslyVisible } = usePageVisibility();
  const stopPollingTime = useRef<number | null>(null);

  useEffect(() => {
    if (isPageVisible === wasPagePreviouslyVisible) {
      return;
    }

    if (isPageVisible) {
      const timeSinceStopPolling =
        stopPollingTime.current === null ? 0 : Date.now() - stopPollingTime.current;

      // if the page was hidden for longer than the poll interval, refetch data right away
      if (timeSinceStopPolling > options.pollInterval) {
        void response.refetch();
      }

      response.startPolling(options.pollInterval);
    } else {
      stopPollingTime.current = Date.now();
      response.stopPolling();
    }
  }, [isPageVisible, response, options, wasPagePreviouslyVisible]);

  return response;
};
