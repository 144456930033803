import isEmpty from 'lodash/isEmpty';
import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useMemo, useState } from 'react';

import type { Experiments, ExperimentsKeys } from '~/shared/components/Experiment/types';

type ExperimentAdder = <T extends ExperimentsKeys>(experiment: T, version: Experiments[T]) => void;
type ExperimentRemover = <T extends ExperimentsKeys>(experiment: T) => void;

interface AnalyticsExperimentsContextType {
  experiments: undefined | Partial<Experiments>;
  addExperiment: ExperimentAdder;
  removeExperiment: ExperimentRemover;
}

export const AnalyticsExperimentsContext = createContext<AnalyticsExperimentsContextType>({
  experiments: undefined,
  addExperiment: () => {},
  removeExperiment: () => {},
});

export const AnalyticsExperimentsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [experiments, setExperiments] = useState<Partial<Experiments> | undefined>(undefined);

  const addExperiment = useCallback<ExperimentAdder>((experiment, version) => {
    setExperiments((prevExperiments) => ({
      ...prevExperiments,
      [experiment]: version,
    }));
  }, []);

  const removeExperiment = useCallback<ExperimentRemover>((experiment) => {
    setExperiments(({ [experiment]: toExclude, ...prevExperiments }) =>
      !isEmpty(prevExperiments) ? prevExperiments : undefined,
    );
  }, []);

  const value = useMemo(
    () => ({ experiments, addExperiment, removeExperiment }),
    [experiments, addExperiment, removeExperiment],
  );

  return (
    <AnalyticsExperimentsContext.Provider value={value}>
      {children}
    </AnalyticsExperimentsContext.Provider>
  );
};
