import { graphql } from '~/__gql__';

export const queryCurrentUser = graphql(/* GraphQL */ `
  query CurrentUser {
    me {
      id
      email
      firstName
      lastName
      jobTitle
      externalCompany
      phone
      fax
      intercomHash
      customSignature
      featureFlags
      company {
        id
        name
        type
        tags
        ...CompanyConfig
        primaryColor
        contrastColor
        logoKey
        mfaRequired
        hasOverdueInvoice
        organization {
          id
          ssoRequired
        }
        packageType
        isTestCompany
        isAgentProfileComplete
        isEnterpriseOpportunity
        bidRequestsCount
        bidRequestsWithSubmittedBidsCount
        submittedCertificatesCount
        hasNetworkSubcontractorsAssociated
        config {
          id
          fullUserLicenseCount
          guestLicenseCount
        }
        hasBillingAccount
        contractEndDate
      }
      type
      isLocked
      language
      departmentType
      customDepartmentName
      isActiveCompanyOnboarding
      notificationConfig {
        id
        appAgentSignedContract
        appBiddersAwaitingResponse
        appBiddingPeriodEnded
        appBiddingPeriodEnding
        appBidRequestDeclined
        appCertificateExpired
        appCertificateExpiring
        appCertificateNotProvidedInTime
        appCertificateUploadedBySubcontractor
        appContractMultiSigningCompleted
        appBidPackageStatusChanged
        appNewBid
        emailAgentSignedContract
        emailBiddersAwaitingResponse
        emailBidPackageStatusChanged
        emailBiddingPeriodEnded
        emailBiddingPeriodEnding
        emailBidRequestDeclined
        emailNewBid
        emailCertificateExpired
        emailCertificateExpiring
        emailCertificateNotProvidedInTime
        emailCertificateUploadedBySubcontractor
        emailContractMultiSigningCompleted
        emailNewMessageFromSubcontractor
        emailUserAddedToBidPackageTeam
      }
      ssoEnabled
      mfaMethod
      mfaPhoneCensored
      celloToken
      workspaces {
        id
        company {
          id
          name
        }
        isCurrent
        ssoEnabled
        mfaEnabled
        mfaRequired
      }
    }
  }
`);

export const queryCurrentAdmin = graphql(/* GraphQL */ `
  query CurrentAdmin {
    adminMe {
      id
    }
  }
`);

export const queryCheckWorkspace = graphql(/* GraphQL */ `
  query CheckWorkspace($workspaceId: String!) {
    checkWorkspace(workspaceId: $workspaceId) {
      loginToken
    }
  }
`);

export const querySSORedirectUrl = graphql(/* GraphQL */ `
  query SSORedirectUrl(
    $email: String!
    $expectedUserStatus: USER_STATUS!
    $redirectPath: String
    $workspaceId: String
  ) {
    ssoRedirectUrl(
      email: $email
      expectedUserStatus: $expectedUserStatus
      redirectPath: $redirectPath
      workspaceId: $workspaceId
    )
  }
`);
