import { ClickableText, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 384px;
  border-radius: 10px;
  padding: 12px;
  background-color: ${COLOR.backgroundLight};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.div`
  flex-grow: 1;
  color: ${COLOR.textPrimary};
  ${textStyles.labelMedium500};
`;

export const BodyText = styled.div`
  color: ${COLOR.textSecondary};
  ${textStyles.bodyMedium450};
`;

export const BodyTextBold = styled.span`
  color: ${COLOR.textPrimary};
  ${textStyles.labelMedium500};
`;

export const CtaButton = styled(ClickableText)`
  ${textStyles.labelMedium500};
`;
