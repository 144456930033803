import moment from 'moment';

// <-- These should be kept in sync with their counterparts in api/src/constants/index
export const AUTH_TOKEN_EXPIRES_AFTER_DURATION = moment.duration(24, 'hours');

export const INCREASED_SECURITY_FEATURES_AUTH_TOKEN_EXPIRES_AFTER_DURATION = moment.duration(
  30,
  'minutes',
);
// -->
