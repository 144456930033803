import { useContext } from 'react';

import AuthContext, { isAuthenticated } from '~/shared/contexts/authContext';

class NotAuthenticated extends Error {
  constructor(message = 'User is not signed in') {
    super(message);
  }
}

export default function useCurrentUser() {
  const authContext = useContext(AuthContext);

  if (isAuthenticated(authContext)) {
    return authContext.authState.user;
  }

  return undefined;
}

/**
 * Gets the current user and throws unless they're signed in.
 * @throws {NotAuthenticated}
 */
export function useEnsureCurrentUser(errorMessage?: string) {
  const currentUser = useCurrentUser();

  if (!currentUser) {
    throw new NotAuthenticated(errorMessage);
  }

  return currentUser;
}
