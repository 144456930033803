import { Breadcrumbs, Icon } from '@cosuno/cosuno-ui';
import React, { useContext, useState } from 'react';

import AuthContext, { isAuthenticated } from '~/shared/contexts/authContext';

import AuthenticatedContent from './AuthenticatedContent';
import { BackLink, Bar, BreadcrumbsContainer } from './Styles';

// As we are preparing to replace backLink with breadcrumbs,
// we want to make sure that EITHER neither backLink nor breadcrumbs
// are specified, OR both are specified. That way, there is no risk of
// new pages sneaking in with backLink but no defined breadcrumbs.
type Props =
  | {
      backLink: {
        to: string;
        label: string;
      };
      breadcrumbs: Parameters<typeof Breadcrumbs>[0]['items'];
    }
  | {
      backLink?: never;
      breadcrumbs?: never;
    };

const Topbar: React.FC<Props> = ({ backLink, breadcrumbs }) => {
  const authContext = useContext(AuthContext);

  const [breadcrumbsContainer, setBreadcrumbsContainer] = useState<HTMLElement | null>(null);

  // Prevent the top bar from being rendered if there is nothing to render
  if (backLink === undefined && !isAuthenticated(authContext)) return null;

  const renderBackLinkOrBreadcrumbs = () => {
    if (breadcrumbs && breadcrumbsContainer) {
      return <Breadcrumbs container={breadcrumbsContainer} items={breadcrumbs} />;
    }
    if (backLink) {
      return (
        <BackLink data-cy-back-link to={backLink.to}>
          <Icon type="arrow-left" size={18} />
          <span>{backLink.label}</span>
        </BackLink>
      );
    }
    return null;
  };

  return (
    <Bar data-cy-topbar>
      <BreadcrumbsContainer ref={setBreadcrumbsContainer}>
        {renderBackLinkOrBreadcrumbs()}
      </BreadcrumbsContainer>
      <AuthenticatedContent />
    </Bar>
  );
};

export default Topbar;
