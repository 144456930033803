import { COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const SuggestionsLabel = styled.div`
  ${textStyles.bodySmall450}
  color: ${COLOR.textTertiary};
`;

export const SuggestionsTags = styled.div`
  width: 100%;
  position: relative;
  margin-block-start: 8px;
  & > * {
    margin-inline-end: 5px;
    margin-block-end: 5px;
  }
`;

export const SuggestionsWrapper = styled.div`
  margin-top: 8px;
`;
