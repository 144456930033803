import { COLOR } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

export const StyledPageError = styled.div`
  width: 100%;
  padding: 100px;
`;

export const MessageBox = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 35px 50px 40px;
  border-radius: 4px;
  color: ${COLOR.danger};
  background: ${mixins.rgba(COLOR.danger, 0.1)};
  ${font.size(16)}
`;

interface MessageProps {
  isOnlyMessage: boolean;
}

export const Message = styled.div<MessageProps>`
  display: flex;
  flex-direction: ${(props) => (props.isOnlyMessage ? 'column' : 'row')};
  align-items: center;
  ${(props) =>
    !props.isOnlyMessage &&
    css`
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    `}

  svg {
    color: ${COLOR.danger};
    ${(props) =>
      props.isOnlyMessage &&
      css`
        margin-bottom: 25px;
      `}
    ${(props) =>
      !props.isOnlyMessage &&
      css`
        margin-right: 15px;
      `}
  }
`;
