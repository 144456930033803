import axios from 'axios';
import { useEffect, useState } from 'react';

import Console from '~/shared/utils/console';

export const CHECK_PERIOD_IN_SECONDS = 300;

const useIsAppOutdated = () => {
  const [isOutdated, setIsOutdated] = useState(false);

  useEffect(() => {
    let version: string | null = null;

    const checkVersion = async () => {
      try {
        const { data, status } = await axios.get<string>('/version.txt');

        if (status === 200 && data) {
          const latestVersion = data.trim();
          if (version && version !== latestVersion) {
            setIsOutdated(true);
          }
          version = latestVersion;
        }
      } catch (error) {
        Console.error(`Error while retrieving version number: ${String(error)}`);
      }
    };

    setInterval(checkVersion, CHECK_PERIOD_IN_SECONDS * 1000);
  }, []);

  return isOutdated;
};

export default useIsAppOutdated;
