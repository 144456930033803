import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Trans as ReactI18NextTrans, type TransProps } from 'react-i18next';

import useTranslation, { type TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import type { TranslationKey, TranslationKeysByNamespace } from '~/shared/types/translationKeys';

interface TypeSafeTransProps<T extends keyof TranslationKeysByNamespace>
  extends Omit<TransProps<TranslationKey<T>>, 'i18nKey' | 't'> {
  i18nKey: TranslationKey<T>;
  /** typesafe translation function, defaults to one that uses the default namespace */
  t?: TypeSafeTFunction<T>;
}

const Trans = <
  T extends keyof TranslationKeysByNamespace = 'common',
  U extends TranslationKey<T> = TranslationKey<T>,
>(
  props: TypeSafeTransProps<T>,
): ReturnType<React.FC<TypeSafeTransProps<T>>> => {
  const { t } = useTranslation();
  return <ReactI18NextTrans {...({ t, ...props } as TransProps<U>)} />;
};

export default Trans;

// temporarily till types are fixed for the trans component
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
export type KeysAndValues<T extends keyof TranslationKeysByNamespace = 'common'> = any;
