import type { CosunoUiTheme } from '@cosuno/cosuno-ui';
import { useMemo } from 'react';

import useTranslation from './useTranslation';

const useUiTranslations = (): CosunoUiTheme['translations'] => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      addMore: {
        addMore: t('addMore'),
      },
      breadcrumbs: {
        overflowButtonLabel: t('overflowButtonLabel'),
      },
      datePicker: {
        calendarWeek: t('calendarWeekAbbreviation'),
      },
      popover: {
        next: t('popover.next'),
        previous: t('popover.previous'),
        close: t('close'),
        outOf: (currentPosition: number, total: number) =>
          t('popover.outOf', { currentPosition, total }),
      },
      searchInput: {
        clearButtonLabel: t('searchable.clearButtonLabel'),
        search: t('search'),
      },
      select: {
        clearInput: t('selectComponent.clearInput'),
        create: (itemName: string) => t('selectComponent.create', { itemName }),
        creating: (itemName: string) => t('selectComponent.creating', { itemName }),
        noResults: t('noResults'),
      },
      fileUploader: {
        uploadFile: t('fileUploader:uploadFile'),
      },
      imageUploader: {
        tooLarge: (args) => t('validation:max', args),
        removeImage: t('imageUploader.removeImage'),
        uploadImage: t('imageUploader.uploadImage'),
        uploadedImage: t('imageUploader.uploadedImage'),
      },
      lightbox: {
        back: t('back'),
        close: t('close'),
        download: t('download'),
        pageDown: t('tooltips.icons.pageDown'),
        pageUp: t('tooltips.icons.pageUp'),
        zoomIn: t('tooltips.icons.zoomIn'),
        zoomOut: t('tooltips.icons.zoomOut'),
        print: t('lightbox:print'),
        imageCount: (args) => t('lightbox:imageCount', args),
        pageCount: (args) => t('lightbox:pageCount', args),
      },
      table: {
        noResults: t('noResults'),
      },
      tabs: {
        overflowButtonLabel: t('overflowButtonLabel'),
      },
    }),
    [t],
  );
};

export default useUiTranslations;
