import {
  ButtonGroup,
  COLOR,
  GRADIENT,
  Icon,
  SearchInput,
  Tag,
  textStyles,
} from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

import { mixins } from '~/shared/utils/styles';

export const ContentWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

export const ContentHeader = styled.div`
  display: flex;
  padding: 20px 20px 20px 30px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: ${GRADIENT.gradientLight};
  ${textStyles.titleSmall500}
  color: ${COLOR.textPrimary};
  border-bottom: 1px solid ${COLOR.borderLight};
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: ${COLOR.white};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid ${COLOR.borderLight};
`;

export const Feedback = styled.div`
  ${textStyles.bodySmall450}
  color: ${COLOR.textTertiary};
`;

export const FeedbackButton = styled.button`
  padding: 0;
  ${mixins.link()}
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1;
  justify-content: flex-end;
`;

export const GroupHeader = styled.div`
  display: flex;
  gap: 8px;
  padding: 15px 8px;
  ${textStyles.labelMedium500};
  color: ${COLOR.textPrimary};
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const CollapseExpandIcon = styled(Icon).attrs({ color: COLOR.textTertiary })``;

export const AllGroupsWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 310px);
  min-height: calc(100vh - 310px);
  overflow-y: scroll;
`;

export const GroupWrapper = styled.div`
  margin: 0 20px;
  border-bottom: 1px solid ${COLOR.borderLight};

  :last-of-type {
    border: none;
  }
`;

export const GroupBody = styled.div<{ $expanded: boolean }>`
  max-height: 1000px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
  padding: 0 0 ${(props) => (props.$expanded ? '12px' : '0')} 32px;
  overflow: hidden;

  ${(props) =>
    !props.$expanded &&
    css`
      max-height: 0;
    `}
`;

export const CountTag = styled(Tag)`
  padding: 1px 4px;
  ${textStyles.sectionTitle700}
  min-width: 16px;
  text-align: center;
`;

export const StyledSearchInput = styled(SearchInput)`
  margin: 20px 0 10px 20px;
`;
