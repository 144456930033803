import { COLOR } from '@cosuno/cosuno-ui';
import React, { useEffect } from 'react';

import { ErrorBoundary } from '~/shared/components/ErrorBoundary';
import HtmlHeadTags from '~/shared/components/HtmlHeadTags';
import WorkspaceContext from '~/shared/contexts/workspaceContext';
import {
  NavigationConfirmationEnvironmentContext,
  useNavigationConfirmationEnvironment,
} from '~/shared/hooks/useNavigationConfirmation';

import PageLayout, { type PageLayoutProps } from './PageLayout';

export interface PageProps extends PageLayoutProps {
  title: string;
}

const Page: React.FC<PageProps> = ({ title, ...props }) => {
  const environmentProps = useNavigationConfirmationEnvironment();

  useEffect(() => {
    window.getUserConfirmation = (_, callback) => {
      environmentProps.tryNavigatingAway(() => callback(true));
    };
  }, [environmentProps]);

  return (
    <>
      <HtmlHeadTags title={title} themeColor={COLOR.white} />
      <NavigationConfirmationEnvironmentContext.Provider value={environmentProps}>
        <WorkspaceContext.Provider
          value={{
            switch: (callback) => {
              environmentProps.tryNavigatingAway(() => callback());
            },
          }}
        >
          <ErrorBoundary>
            <PageLayout fullHeight {...props} />
          </ErrorBoundary>
        </WorkspaceContext.Provider>
      </NavigationConfirmationEnvironmentContext.Provider>
    </>
  );
};

export { PageLayout };
export default Page;
