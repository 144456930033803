import axios from 'axios';
import type { ImageAttributes } from 'remirror/extensions';

import apolloClient from '~/apolloClient';
import type { TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import { ensureImageIsReady, ImageTooLarge, validateImageSize } from '~/shared/utils/images';
import toast from '~/shared/utils/toast';

import { textEditorImageUploadUrl } from './api';
import type { FileWithProgress } from './types';

const uploadImageToStorage = (uploadUrl: string, file: File) =>
  axios.put(uploadUrl, file, {
    headers: { 'Content-Type': file.type },
  });

export const uploadImageToExternalStorage = async (
  { file }: FileWithProgress,
  t: TypeSafeTFunction,
): Promise<ImageAttributes> => {
  const isValid = validateImageSize([file], t);
  if (!isValid) {
    throw new ImageTooLarge();
  }

  try {
    const {
      data: {
        imageUploadUrl: { uploadUrl, url },
      },
    } = await apolloClient.query({
      query: textEditorImageUploadUrl,
      variables: { filename: file.name },
    });

    await uploadImageToStorage(uploadUrl, file);

    await ensureImageIsReady(url);

    return { src: url, fileName: file.name };
  } catch (error) {
    toast.error(error);
  }
  return { src: URL.createObjectURL(file), fileName: file.name };
};
