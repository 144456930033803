import React from 'react';

import DiamondDark from './DiamondDark.svg?react';
import DiamondLight from './DiamondLight.svg?react';

export interface Props extends React.SVGAttributes<SVGSVGElement> {
  size?: number;
  variant: 'light' | 'dark';
}

const variantToComponent = {
  light: (props: React.ComponentPropsWithRef<'svg'>) => (
    <DiamondLight data-premium-icon {...props} />
  ),
  dark: (props: React.ComponentPropsWithRef<'svg'>) => <DiamondDark data-premium-icon {...props} />,
} as const;

export const PremiumIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ size = 16, style, variant, ...props }, ref) => (
    <span style={{ display: 'flex' }}>
      {variantToComponent[variant]({
        width: size,
        height: size,
        style: { pointerEvents: 'none', ...style },
        ref,
        ...props,
      })}
    </span>
  ),
);
