import { Button } from '@cosuno/cosuno-ui';
import type { ReactNode } from 'react';

import { Heading } from '~/shared/components/UnauthenticatedStyles';
import useTranslation from '~/shared/hooks/useTranslation';

import { Back } from '../Back';
import type { LoginSuggestion } from '../types';
import { ExplanationChooseMethod, LoginInMethodButtonContainer } from './Styles';
import { SuggestionListItemContent } from './SuggestionListItemContent';

interface ChooseLoginMethodStepProps {
  title: ReactNode;
  explanation?: ReactNode | null;
  selectedSuggestion: LoginSuggestion;
  onSubmit: ({
    suggestion,
    method,
  }: {
    suggestion: LoginSuggestion;
    method: 'magicLink' | 'password';
  }) => void;
  onGoBack?: () => void;
}

export function ChooseLoginMethodStep({
  title,
  explanation,
  selectedSuggestion,
  onGoBack,
  onSubmit,
}: ChooseLoginMethodStepProps) {
  const { t } = useTranslation('agentAuth');

  return (
    <div>
      {onGoBack && <Back onClick={onGoBack} />}
      {explanation && <ExplanationChooseMethod>{explanation}</ExplanationChooseMethod>}
      <Heading>{title}</Heading>
      <SuggestionListItemContent suggestion={selectedSuggestion} />
      <div>{t('revamp.chooseLogin')}</div>
      <LoginInMethodButtonContainer>
        <Button
          fullWidth
          onClick={() => {
            onSubmit({ suggestion: selectedSuggestion, method: 'magicLink' });
          }}
          variant="primary"
        >
          {t('revamp.sendLoginCode')}
        </Button>
        <Button
          fullWidth
          onClick={() => {
            onSubmit({ suggestion: selectedSuggestion, method: 'password' });
          }}
          variant="secondaryBrand"
        >
          {t('revamp.loginWithPassword')}
        </Button>
      </LoginInMethodButtonContainer>
    </div>
  );
}
