import { authFor, useAuth } from '@cosuno/authorization-frontend';
import { ClickableText } from '@cosuno/cosuno-ui';
import moment from 'moment';
import { type ComponentProps, useMemo } from 'react';

import { COMPANY_TYPE, CURRENCY, SUBCONTRACTOR_TYPE } from '~/__gql__/graphql';
import { isCompanyFeatureEnabled, useFeatureFlags } from '~/packages/FeatureFlags';
import { useNumberFormatter } from '~/shared/components/NumberFormatter';
import type Trans from '~/shared/components/Trans';
import type { KeysAndValues } from '~/shared/components/Trans';
import { ShowBidPackageTabs, SubcontractorProfileTabs } from '~/shared/constants/routeTabs';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import { useGetBidGrossOrNetTotalBasedOnFeatureFlag } from '~/shared/hooks/usePreferGrossTotals';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation, { type TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import { getBidPriceDetails } from '~/shared/utils/bids';
import { subcontractorUrlWithParams } from '~/shared/utils/routes';
import { openInNewTab } from '~/shared/utils/url';

import { Emphasized } from './NotificationItem.styles';
import type { Notification } from './types';

const Empty: React.FC = () => <></>;

type NotificationInfo = Pick<ComponentProps<typeof Trans>, 'components'> &
  KeysAndValues & {
    /** Icon identifier used to map to both icon type and style  */
    icon: string;
    /** URL path the notification is linking to */
    path: string;
    /** callback on clicking the notification */
    onNotificationClick?: () => void;
  };

/**
 * Every in-app notification is a little different. This hook provides additional information
 * required to render a notification:
 *
 * - The icon identifier that maps to icon type and style
 * - The URL path the notification is linking to
 * - Translation information for the notification text such as the components that should be
 *   replaced, the values that should be interpolated and the translation key
 */
export const useNotificationInfo = (notification: Notification): NotificationInfo => {
  const format = useNumberFormatter();
  const getTotal = useGetBidGrossOrNetTotalBasedOnFeatureFlag();
  const routes = useRoutes();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { trackEvent } = useAnalytics();

  const user = useCurrentUser();

  const featureFlags = useFeatureFlags();

  const isCosunoNetworkEnabled = isCompanyFeatureEnabled(user?.company, 'featureCosunoNetwork');

  const advancedCertificatesEnabled = featureFlags.featureAdvancedCertificates;

  const { results: canSendCertificateRequest } = useAuth({
    canSendCertificateRequest:
      notification.__typename === 'CertificateExpiringNotification' &&
      Boolean(notification.props?.subcontractorId) &&
      authFor('Subcontractor')
        .canI('requestCertificates')
        .where({ id: notification.props.subcontractorId }),
  });

  return useMemo((): NotificationInfo => {
    const defaultValues = {
      components: { b: <Emphasized /> },
    };

    switch (notification.__typename) {
      case 'BidPackageStatusChangedNotification':
        return {
          ...defaultValues,
          path: routes.showBidPackage(
            notification.props.projectId,
            notification.props.bidPackageId,
            {
              tab: ShowBidPackageTabs.bidders,
            },
          ),
          icon: `bidPackage_${notification.props.status}`,
          i18nKey: 'notifications.text.type.BidPackageStatusChangedNotification',
          values: {
            status: t(`bidPackage.${notification.props.status}`),
            bidPackage: notification.props.bidPackageName,
            project: notification.props.projectName,
          },
        };
      case 'AwardBidderReminderNotification': {
        return {
          ...defaultValues,
          icon: 'help',
          path: routes.showBidPackage(
            notification.props.projectId,
            notification.props.bidPackageId,
            {
              tab: ShowBidPackageTabs.bidders,
            },
          ),
          i18nKey: 'notifications.text.type.AwardBidderReminderNotification',
          values: {
            ...notification.props,
            hasEndedWeeksAgo: t('notifications.text.hasEndedWeeksAgo', {
              weeks: notification.props.bidPackageBidsDueWeeksAgo,
            }),
            whoWasAwarded: t('notifications.text.whoWasAwarded'),
          },
        };
      }
      case 'BiddingPeriodEndingNotification': {
        const { bidPackageId, bidsCount, endingInDays, projectId } = notification.props;

        const hasEnded = endingInDays === 0;
        const hasNoBids = bidsCount === 0;
        const showInviteMoreBiddersLink =
          isCosunoNetworkEnabled && endingInDays > 0 && bidsCount < 2;

        return {
          icon: hasNoBids ? 'alert' : 'clock',
          path: showInviteMoreBiddersLink
            ? routes.addBidders(projectId, bidPackageId, {
                requestedType: SUBCONTRACTOR_TYPE.network,
              })
            : routes.showBidPackage(projectId, bidPackageId, { tab: ShowBidPackageTabs.bidders }),
          components: {
            a: showInviteMoreBiddersLink ? <ClickableText /> : <Empty />,
            b: <Emphasized />,
          },
          i18nKey: 'notifications.text.type.BiddingPeriodEndingNotification',
          values: {
            ...notification.props,
            bidsCount: t(
              hasNoBids ? 'notifications.text.noBidsYet' : 'notifications.text.countBidsSoFar',
              { bidsCount: String(bidsCount) },
            ),
            endingInDays: t(
              hasEnded ? 'notifications.text.ended' : 'notifications.text.endingInCountDays',
              { endingInDays: String(endingInDays) },
            ),
            inviteMoreBiddersLink: t('notifications.text.inviteMoreBidders'),
          },
        };
      }
      case 'BidPackageExportBidsNotification': {
        return {
          ...defaultValues,
          icon: 'checkmark',
          path: routes.showBidPackage(
            notification.props.projectId,
            notification.props.bidPackageId,
            {
              tab: ShowBidPackageTabs.bidders,
            },
          ),
          i18nKey: 'notifications.text.type.BidPackageExportBidsNotification',
          values: {
            ...notification.props,
          },
          components: {
            ...defaultValues.components,
            Link: (
              <ClickableText
                about="_blank"
                onClick={() => {
                  window.open(notification.props.downloadUrl, '_blank');
                }}
              />
            ),
          },
        };
      }
      case 'BidRequestDeclinedNotification': {
        return {
          ...defaultValues,
          icon: 'alert',
          path: routes.showBidPackage(
            notification.props.projectId,
            notification.props.bidPackageId,
            {
              tab: ShowBidPackageTabs.bidders,
              request: notification.props.bidRequestId,
            },
          ),
          i18nKey: 'notifications.text.type.BidRequestDeclinedNotification',
          values: {
            ...notification.props,
          },
        };
      }
      case 'CertificateExpiringNotification': {
        const { certificateTypeId, expiresInDays, subcontractorId } = notification.props;
        const showRequestAgainLink =
          canSendCertificateRequest && advancedCertificatesEnabled && Boolean(certificateTypeId);

        return {
          ...defaultValues,
          icon: expiresInDays === 0 ? 'alert' : 'clock',
          path: subcontractorUrlWithParams(routes, subcontractorId, {
            tab: SubcontractorProfileTabs.certificates,
            ...(showRequestAgainLink && {
              certificateTypeId: certificateTypeId as string,
              openRequestCertificateModal: true,
            }),
          }),
          ...(showRequestAgainLink && {
            onNotificationClick: () => {
              trackEvent('notificationsCertificateRequestAgain');
            },
          }),
          components: {
            a: showRequestAgainLink ? <ClickableText /> : <Empty />,
            b: <Emphasized />,
          },
          i18nKey: 'notifications.text.type.CertificateExpiringNotification',
          values: {
            ...notification.props,
            expiration: getCertificateExpiration(t, expiresInDays),
            requestAgainLink: t('notifications.text.requestAgain'),
          },
        };
      }
      case 'CertificateExpiringSubcontractorNotification': {
        return {
          ...defaultValues,
          icon: notification.props.expiresInDays === 0 ? 'alert' : 'clock',
          path: `${routes.agentCertificates()}/overview`,
          i18nKey: 'notifications.text.type.CertificateExpiringSubcontractorNotification',
          values: {
            ...notification.props,
            expiration: getCertificateExpiration(t, notification.props.expiresInDays),
          },
        };
      }
      case 'CertificateNotProvidedInTimeNotification': {
        return {
          ...defaultValues,
          icon: 'alert',
          path: routes.subcontractor(notification.props.subcontractorId, {
            tab: SubcontractorProfileTabs.certificates,
          }),
          i18nKey: 'notifications.text.type.CertificateNotProvidedInTimeNotification',
          values: {
            ...notification.props,
            certificateTypeNames: getTextList(t, notification.props.certificateTypeNames),
          },
        };
      }
      case 'CertificateUploadedBySubcontractorNotification': {
        return {
          ...defaultValues,
          icon: 'checkmark',
          path: routes.subcontractor(notification.props.subcontractorId, {
            tab: SubcontractorProfileTabs.certificates,
          }),
          i18nKey: 'notifications.text.type.CertificateUploadedBySubcontractorNotification',
          values: {
            ...notification.props,
            certificateTypeNames: getTextList(t, notification.props.certificateTypeNames),
          },
        };
      }
      case 'ContractAgentSignatureRequestedNotification': {
        return {
          ...defaultValues,
          icon: 'signature',
          path: routes.agentAwardedBid(notification.props.awardedBidId),
          i18nKey: 'notifications.text.type.ContractAgentSignatureRequestedNotification',
          values: {
            ...notification.props,
          },
        };
      }
      case 'ContractMultiSigningCompletedNotification': {
        const { bidPackageId, projectId } = notification.props;
        return {
          ...defaultValues,
          icon: 'checkmark',
          path: routes.showBidPackage(projectId, bidPackageId, {
            tab: ShowBidPackageTabs.contract,
          }),
          i18nKey: 'notifications.text.type.ContractMultiSigningCompletedNotification',
          values: {
            ...notification.props,
          },
        };
      }
      case 'ContractPrincipalSignatureRequestedNotification': {
        const { bidPackageId, projectId } = notification.props;
        return {
          ...defaultValues,
          icon: 'signature',
          path: routes.showBidPackage(projectId, bidPackageId, {
            tab: ShowBidPackageTabs.contract,
          }),
          i18nKey: 'notifications.text.type.ContractPrincipalSignatureRequestedNotification',
          values: {
            ...notification.props,
          },
        };
      }
      case 'ContractSignedByAgentNotification': {
        const { bidPackageId, projectId } = notification.props;
        return {
          ...defaultValues,
          icon: 'checkmark',
          path: routes.showBidPackage(projectId, bidPackageId, {
            tab: ShowBidPackageTabs.contract,
          }),
          i18nKey: 'notifications.text.type.ContractSignedByAgentNotification',
          values: {
            ...notification.props,
          },
        };
      }
      case 'InvoiceAwaitingApprovalNotification': {
        return {
          ...defaultValues,
          icon: 'watch',
          path: routes.invoice(notification.props.invoiceId),
          ...(hasInvoiceNameOrNumber(notification)
            ? {
                i18nKey: 'notifications.text.type.InvoiceAwaitingApprovalNotification',
                values: {
                  ...notification.props,
                  invoiceNumber: notification.props.invoiceNumber ?? '',
                  invoiceName: notification.props.invoiceName ?? '',
                },
              }
            : {
                i18nKey:
                  'notifications.text.type.InvoiceWithoutNameOrNumberAwaitingApprovalNotification',
                values: {
                  ...notification.props,
                  invoiceNumber: notification.props.invoiceNumber ?? '',
                  invoiceName: notification.props.invoiceName ?? '',
                },
              }),
        };
      }
      case 'InvoiceRejectedNotification': {
        return {
          ...defaultValues,
          icon: 'rejected',
          path: routes.invoice(notification.props.invoiceId),
          ...(hasInvoiceNameOrNumber(notification)
            ? {
                i18nKey: 'notifications.text.type.InvoiceRejectedNotification',
                values: {
                  ...notification.props,
                  invoiceNumber: notification.props.invoiceNumber ?? '',
                  invoiceName: notification.props.invoiceName ?? '',
                },
              }
            : {
                i18nKey: 'notifications.text.type.InvoiceWithoutNameOrNumberRejectedNotification',
                values: {
                  ...notification.props,
                  invoiceNumber: notification.props.invoiceNumber ?? '',
                  invoiceName: notification.props.invoiceName ?? '',
                },
              }),
        };
      }
      case 'NewBidNotification': {
        const {
          bidId,
          bidPackageId,
          bidRequestId,
          currency,
          projectId,
          totalNet,
          taxRate,
          skontoRate,
          discountRate,
          absoluteDiscount,
          additionalCosts,
          teamMemberName,
        } = notification.props;

        const bidAmount = format({
          currencyCode: currency ?? CURRENCY.EUR,
          type: 'currency',
          value:
            getTotal(
              getBidPriceDetails({
                totalNet,
                taxRate: taxRate ?? 0,
                skontoRate,
                discountRate,
                absoluteDiscount,
                additionalCosts,
              }),
            ) ?? 0,
        });

        return {
          ...defaultValues,
          icon: 'checkmark',
          path: routes.showBidPackage(projectId, bidPackageId, {
            tab: ShowBidPackageTabs.bidders,
            ...(totalNet === null ? {} : { request: bidRequestId, bid: bidId }),
          }),
          ...(totalNet === null
            ? {
                i18nKey: 'notifications.text.type.NewBidNotification.withoutAmount',
                values: {
                  ...notification.props,
                },
              }
            : {
                ...(teamMemberName
                  ? {
                      i18nKey: 'notifications.text.type.NewBidNotification.withAmountPrincipal',
                      values: {
                        ...notification.props,
                        teamMemberName,
                        bidAmount,
                      },
                    }
                  : {
                      i18nKey: 'notifications.text.type.NewBidNotification.withAmount',
                      values: {
                        ...notification.props,
                        bidAmount,
                      },
                    }),
              }),
        };
      }
      case 'NewMessageNotification': {
        return {
          ...defaultValues,
          icon: 'message',
          path:
            notification.props.authorType === COMPANY_TYPE.agent
              ? routes.showBidPackage(
                  notification.props.projectId,
                  notification.props.bidPackageId,
                  {
                    tab: ShowBidPackageTabs.messages,
                    threadId: notification.props.messageThreadId,
                  },
                )
              : routes.bidRequestLandingPage(notification.props.bidRequestId, {
                  openMessages: true,
                  threadId: notification.props.messageThreadId,
                }),
          i18nKey: 'notifications.text.type.NewMessageNotification',
          values: {
            ...notification.props,
          },
        };
      }
      case 'SubcontractorImportRequestCompletedNotification': {
        return {
          ...defaultValues,
          icon: 'checkmark',
          path: routes.subcontractors(),
          i18nKey: 'notifications.text.type.SubcontractorImportRequestCompletedNotification',
          components: { Link: <ClickableText /> },
        };
      }
      case 'ProjectExportCompleteNotification': {
        return {
          ...defaultValues,
          icon: 'checkmark',
          path: '#',
          onNotificationClick: () => openInNewTab(notification.props.downloadUrl),
          i18nKey: 'notifications.text.type.ProjectExportCompleteNotification',
          values: { projectName: notification.props.projectName },
          components: {
            Link: <ClickableText />,
          },
        };
      }
      case 'TaskAssignedNotification': {
        return {
          ...defaultValues,
          icon: 'taskAssigned',
          path: routes.showTask(notification.props.taskId),
          i18nKey: 'notifications.text.type.TaskAssignedNotification',
          values: { taskName: notification.props.taskName },
        };
      }
      case 'TaskCompletedNotification': {
        return {
          ...defaultValues,
          icon: 'taskCompleted',
          path: routes.showTask(notification.props.taskId),
          i18nKey: 'notifications.text.type.TaskCompletedNotification',
          values: {
            taskName: notification.props.taskName,
          },
        };
      }
      case 'BiddersAwaitingResponseNotification': {
        const baseProps = {
          ...defaultValues,
          icon: 'help',
          path: routes.showBidPackage(
            notification.props.projectId,
            notification.props.bidPackageId,
            {
              tab: ShowBidPackageTabs.bidders,
            },
          ),
        };

        if ('bidPackageBidsDueWeeksAgo' in notification.props) {
          return {
            ...baseProps,
            ...(notification.props.subcontractorNames.length === 1
              ? {
                  i18nKey:
                    'notifications.text.type.BiddersAwaitingResponseNotification.v1.textSingularBidder',
                  values: {
                    ...notification.props,
                    number: notification.props.bidPackageBidsDueWeeksAgo,
                    subcontractorName: notification.props.subcontractorNames[0],
                  },
                }
              : {
                  i18nKey: 'notifications.text.type.BiddersAwaitingResponseNotification.v1.text',
                  values: {
                    ...notification.props,
                    number: notification.props.bidPackageBidsDueWeeksAgo,
                    subcontractorName: notification.props.subcontractorNames[0],
                    count: notification.props.subcontractorNames.length - 1,
                  },
                }),
          };
        }

        const { unit, value } = notification.props.durationSinceBidsDue;
        const duration = Number.isFinite(value)
          ? new Intl.RelativeTimeFormat(language).format(value, unit as Intl.RelativeTimeFormatUnit)
          : '';

        return {
          ...baseProps,
          ...(notification.props.subcontractorNames.length === 1
            ? {
                i18nKey:
                  'notifications.text.type.BiddersAwaitingResponseNotification.textSingularBidder',
                values: {
                  ...notification.props,
                  subcontractorName: notification.props.subcontractorNames[0],
                  duration,
                },
              }
            : {
                i18nKey: 'notifications.text.type.BiddersAwaitingResponseNotification.text',
                values: {
                  ...notification.props,
                  subcontractorName: notification.props.subcontractorNames[0],
                  duration,
                  count: notification.props.subcontractorNames.length - 1,
                },
              }),
        };
      }
    }
  }, [
    notification,
    routes,
    t,
    isCosunoNetworkEnabled,
    canSendCertificateRequest,
    advancedCertificatesEnabled,
    trackEvent,
    format,
    getTotal,
    language,
  ]);
};

/**
 * Returns an array of strings as a comma-separated list with an "and" before the last item.
 */
const getTextList = (t: TypeSafeTFunction, items: string[]) =>
  `${
    items.length > 1
      ? `${items.slice(0, items.length - 1).join(', ')} ${t('notifications.text.and')}`
      : ''
  } ${items[items.length - 1]}`.trim();

/**
 * Returns a string with the expiration date of a certificate.
 */
const getCertificateExpiration = (t: TypeSafeTFunction, expiresInDays: number) =>
  expiresInDays > 0
    ? t('notifications.text.expiresInDuration', {
        duration: moment
          .duration(expiresInDays, 'days')
          // The passed argument ensures the duration is rendered in weeks
          .humanize({ d: 7, w: 4 }),
      })
    : t('notifications.text.hasExpired');

/**
 * Checks if the notification is an invoice notification and has a name or number.
 */
const hasInvoiceNameOrNumber = (notification: Notification) =>
  (notification.__typename === 'InvoiceRejectedNotification' ||
    notification.__typename === 'InvoiceAwaitingApprovalNotification') &&
  (notification.props.invoiceName || notification.props.invoiceNumber);
