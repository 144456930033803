/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: DateString; output: DateString };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: DateTime; output: DateTime };
  /** HTML scalar */
  HTML: { input: string; output: string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: JSON; output: JSON };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: JSONObject; output: JSONObject };
};

export enum ADDENDUM_SORT_FIELD {
  bidPackageName = 'bidPackageName',
  createdAt = 'createdAt',
  status = 'status',
}

export enum ADDENDUM_STATUS {
  approvalPending = 'approvalPending',
  approved = 'approved',
  draft = 'draft',
  objectivelyRecognized = 'objectivelyRecognized',
  rejected = 'rejected',
  withdrawn = 'withdrawn',
}

export enum ADMINS_SORT_FIELD {
  email = 'email',
  name = 'name',
}

export enum ADMIN_BID_PACKAGES_SORT_FIELD {
  biddersWithSubmittedBids = 'biddersWithSubmittedBids',
  bidsDueAt = 'bidsDueAt',
  marketplaceSuggestionsSent = 'marketplaceSuggestionsSent',
  name = 'name',
  projectName = 'projectName',
  publishedToMarketplaceAt = 'publishedToMarketplaceAt',
}

export enum ADMIN_BID_PACKAGE_BILL_OF_QUANTITIES_REVISIONS_SORT_FIELD {
  revision = 'revision',
}

export enum ADMIN_STATUS {
  active = 'active',
  inactive = 'inactive',
  inviteCanceled = 'inviteCanceled',
  inviteFailed = 'inviteFailed',
  invited = 'invited',
}

export enum ADMIN_SUBCONTRACTORS_IMPORT_REQUESTS_SORT_FIELD {
  createdAt = 'createdAt',
  status = 'status',
}

export enum ADMIN_SUBCONTRACTORS_SORT_FIELD {
  name = 'name',
}

export enum ADMIN_SUBCONTRACTOR_FILTER_TYPE {
  all = 'all',
  allExcludingLinked = 'allExcludingLinked',
  company = 'company',
  network = 'network',
  networkAndCompany = 'networkAndCompany',
}

export enum AGENT_COMPANY_ONBOARDING_ITEM_TYPE {
  addReferenceProject = 'addReferenceProject',
  completeCompanyProfile = 'completeCompanyProfile',
  createOwnRequest = 'createOwnRequest',
  discoverCosunoMarketplace = 'discoverCosunoMarketplace',
  inviteColleagues = 'inviteColleagues',
  respondToRequests = 'respondToRequests',
  saveSearch = 'saveSearch',
  specifyMainTrades = 'specifyMainTrades',
  uploadCertificates = 'uploadCertificates',
}

export type AbstractCompletableItem = {
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
};

export type AbstractContactSuggestionField = {
  changeType: CONTACT_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: CONTACT_SUGGESTION_FIELDS_FIELD_NAME;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<CONTACT_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AbstractEntity = {
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
};

export type AbstractMassDeterminationRow = {
  id: Scalars['ID']['output'];
  /** The unique, auto-increasing local ID of the row following the schema 1A0, 1B0, ...1Z0, 2A0... */
  localId: Scalars['String']['output'];
};

export type AbstractSubcontractorSuggestionField = {
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AddContactsToBidRequestResponse =
  | BidRequest
  | BidderAlreadyAddedError
  | BiddersLimitExceededError;

export type AddElementOperationDescriptor = {
  __typename: 'AddElementOperationDescriptor';
  index: Scalars['Int']['output'];
  isSchemaBased: Maybe<Scalars['Boolean']['output']>;
  newElementId: Scalars['String']['output'];
  newElementItemNumber: Maybe<Scalars['String']['output']>;
  newElementType: BOQ_ELEMENT_TYPE_DISCRIMINATOR;
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  path: Scalars['String']['output'];
};

export type Addendum = AbstractEntity & {
  __typename: 'Addendum';
  bidPackage: BidPackage;
  billOfQuantities: BillOfQuantities;
  contractRefNumber: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  executionDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  remarks: Maybe<Scalars['String']['output']>;
  status: ADDENDUM_STATUS;
  totalGross: Scalars['Float']['output'];
};

export enum AddendumAuthActions {
  delete = 'delete',
  update = 'update',
  updateStatus = 'updateStatus',
  updateStatusApprove = 'updateStatusApprove',
  view = 'view',
}

export type AddendumAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type AddendumAuthInput = {
  action: AddendumAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AddendumAuthArgsInput>;
};

export type AddendumsResponse = {
  __typename: 'AddendumsResponse';
  addendums: Array<Addendum>;
  totalRecords: Scalars['Int']['output'];
};

export type Admin = AbstractEntity & {
  __typename: 'Admin';
  canAccessEmailLog: Scalars['Boolean']['output'];
  canEditCompanyRelationships: Scalars['Boolean']['output'];
  canImpersonateAllUsers: Scalars['Boolean']['output'];
  canImpersonateInternalUsers: Scalars['Boolean']['output'];
  canManageAdmins: Scalars['Boolean']['output'];
  canManageCertificateTypes: Scalars['Boolean']['output'];
  canManageCompanies: Scalars['Boolean']['output'];
  canManageCompanyFeatureFlags: Scalars['Boolean']['output'];
  canManageSubcontractors: Scalars['Boolean']['output'];
  canManageWorkCategories: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  status: ADMIN_STATUS;
};

export type AdminAdminPermissionsInput = {
  canAccessEmailLog: Scalars['Boolean']['input'];
  canEditCompanyRelationships: Scalars['Boolean']['input'];
  canImpersonateAllUsers: Scalars['Boolean']['input'];
  canImpersonateInternalUsers: Scalars['Boolean']['input'];
  canManageAdmins: Scalars['Boolean']['input'];
  canManageCertificateTypes: Scalars['Boolean']['input'];
  canManageCompanies: Scalars['Boolean']['input'];
  canManageCompanyFeatureFlags: Scalars['Boolean']['input'];
  canManageSubcontractors: Scalars['Boolean']['input'];
  canManageWorkCategories: Scalars['Boolean']['input'];
};

export type AdminAgentCompanyMergeResponse = {
  __typename: 'AdminAgentCompanyMergeResponse';
  relations: AdminAgentCompanyRelationsMergeResult;
};

export type AdminAgentCompanyRelationsMergeResult = {
  __typename: 'AdminAgentCompanyRelationsMergeResult';
  certificateTypes: AdminAgentCompanyRelationsSummary;
  certificates: AdminAgentCompanyRelationsSummary;
  claimedSubcontractors: AdminAgentCompanySubcontractorMergeResult;
  createdCompanies: AdminAgentCompanyRelationsSummary;
  users: AdminAgentCompanyUserRelationMergeResult;
};

export type AdminAgentCompanyRelationsSummary = {
  __typename: 'AdminAgentCompanyRelationsSummary';
  toDelete: Scalars['Int']['output'];
  toMerge: Scalars['Int']['output'];
  toTransfer: Scalars['Int']['output'];
};

export type AdminAgentCompanySubcontractorMergeResult = {
  __typename: 'AdminAgentCompanySubcontractorMergeResult';
  toDelete: Array<DeletedSubcontractor>;
  toMerge: Array<Subcontractor>;
  toTransfer: Array<Subcontractor>;
};

export type AdminAgentCompanyUserRelationMergeResult = {
  __typename: 'AdminAgentCompanyUserRelationMergeResult';
  toDelete: Array<DeletedUser>;
  toMerge: Array<User>;
  toTransfer: Array<User>;
};

export enum AdminAuthActions {
  cancelOrRestoreInvite = 'cancelOrRestoreInvite',
  deactivate = 'deactivate',
  invite = 'invite',
  reactivate = 'reactivate',
  update__permissions = 'update__permissions',
  update__personalInfo = 'update__personalInfo',
  view = 'view',
}

export type AdminAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type AdminAuthInput = {
  action: AdminAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AdminAuthArgsInput>;
};

export type AdminBillOfQuantitiesResponse = AbstractEntity & {
  __typename: 'AdminBillOfQuantitiesResponse';
  bid: Maybe<Bid>;
  bidPackage: Maybe<BidPackage>;
  changedElementIds: Maybe<Array<Scalars['String']['output']>>;
  changesToPrevious: Maybe<Array<BoQEditOperationDescriptor>>;
  comments: Array<BillOfQuantitiesComment>;
  content: Scalars['JSONObject']['output'];
  contentHash: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  currency: CURRENCY;
  editingStatus: Maybe<BOQ_EDITING_STATUS>;
  id: Scalars['ID']['output'];
  internalNotes: Array<BillOfQuantitiesInternalNote>;
  massDeterminationRowIdsNotToKeep: Array<Scalars['String']['output']>;
  originalFilename: Maybe<Scalars['String']['output']>;
  parentBoQId: Maybe<Scalars['String']['output']>;
  type: BOQ_TYPE;
  unsavedChanges: Maybe<Array<BoQEditOperationDescriptor>>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type AdminBillOfQuantitiesRevision = AbstractEntity & {
  __typename: 'AdminBillOfQuantitiesRevision';
  createdAt: Scalars['DateTimeISO']['output'];
  hasBids: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isShareableWithBidders: Scalars['Boolean']['output'];
  requestForProposalBoQ: Maybe<BillOfQuantities>;
  requestForProposalBoQId: Maybe<Scalars['ID']['output']>;
  revision: Scalars['Int']['output'];
  totalChangesToPrevious: Maybe<Scalars['Int']['output']>;
  totalUnsavedChanges: Maybe<Scalars['Int']['output']>;
  wereBiddersNotified: Maybe<Scalars['Boolean']['output']>;
};

export type AdminBillOfQuantitiesRevisionsResponse = {
  __typename: 'AdminBillOfQuantitiesRevisionsResponse';
  billOfQuantitiesRevisions: Array<AdminBillOfQuantitiesRevision>;
  totalRecords: Scalars['Int']['output'];
};

export type AdminCertificateTypesResponse = {
  __typename: 'AdminCertificateTypesResponse';
  certificateTypes: Array<CertificateType>;
  totalRecords: Scalars['Int']['output'];
};

export type AdminCompanyConfigInput = {
  additionalLanguages: Array<LANGUAGE>;
  fullUserLicenseCount?: InputMaybe<Scalars['Int']['input']>;
  guestLicenseCount?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  increasedSecurityFeaturesEnabled: Scalars['Boolean']['input'];
  passwordPolicy?: InputMaybe<AdminCompanyPasswordPolicyInput>;
  premiumEnabled: Scalars['Boolean']['input'];
};

export type AdminCompanyFeatureFlagsInput = {
  enabled: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
};

export type AdminCompanyPasswordPolicyInput = {
  minLength?: InputMaybe<Scalars['Int']['input']>;
  requiresLowerCaseCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  requiresNumber?: InputMaybe<Scalars['Boolean']['input']>;
  requiresSpecialCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  requiresUpperCaseCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  resetIntervalInDays?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminCompanySubcontractorsResponse = {
  __typename: 'AdminCompanySubcontractorsResponse';
  subcontractors: Array<Subcontractor>;
  totalRecords: Scalars['Int']['output'];
};

export type AdminEmailTemplatesResponse = {
  __typename: 'AdminEmailTemplatesResponse';
  templates: Array<TemplateData>;
  templatesGroupName: EMAIL_TEMPLATES;
};

export type AdminInvitationResponse = {
  __typename: 'AdminInvitationResponse';
  invitee: Admin;
};

export type AdminInviteAdminInput = {
  canAccessEmailLog: Scalars['Boolean']['input'];
  canEditCompanyRelationships: Scalars['Boolean']['input'];
  canImpersonateAllUsers: Scalars['Boolean']['input'];
  canImpersonateInternalUsers: Scalars['Boolean']['input'];
  canManageAdmins: Scalars['Boolean']['input'];
  canManageCertificateTypes: Scalars['Boolean']['input'];
  canManageCompanies: Scalars['Boolean']['input'];
  canManageCompanyFeatureFlags: Scalars['Boolean']['input'];
  canManageSubcontractors: Scalars['Boolean']['input'];
  canManageWorkCategories: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
};

export type AdminInviteeInput = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AdminSubcontractorContactRelationMergeResult = {
  __typename: 'AdminSubcontractorContactRelationMergeResult';
  toDelete: Array<DeletedContact>;
  toMerge: Array<Contact>;
  toTransfer: Array<Contact>;
};

export type AdminSubcontractorEntityMergeResponse = {
  __typename: 'AdminSubcontractorEntityMergeResponse';
  aboutUs: Maybe<StringDiff>;
  agentCompanyId: Maybe<StringDiff>;
  city: Maybe<StringDiff>;
  companySize: Maybe<CompanySizeDiff>;
  countryCode: Maybe<CountryCodeDiff>;
  description: Maybe<StringDiff>;
  externalId: Maybe<StringDiff>;
  externalSource: Maybe<StringDiff>;
  foundingYear: Maybe<NumberDiff>;
  hasSustainableConstruction: Maybe<BooleanDiff>;
  imageKeys: Maybe<StringListDiff>;
  insuranceLastSuggestedAt: Maybe<DateDiff>;
  logoKey: Maybe<StringDiff>;
  modificationToken: Maybe<StringDiff>;
  moreInfo: Maybe<StringDiff>;
  nationalId: Maybe<StringDiff>;
  orderAreaCountryCode: Maybe<CountryCodeDiff>;
  orderAreaStateCodes: Maybe<StringListDiff>;
  orderAreaType: Maybe<OrderAreaTypeDiff>;
  orderRadius: Maybe<OrderRadiusDiff>;
  ownerName: Maybe<StringDiff>;
  postalCode: Maybe<StringDiff>;
  pqNumber: Maybe<StringDiff>;
  registrationCity: Maybe<StringDiff>;
  registrationPostalCode: Maybe<StringDiff>;
  revenueYear: Maybe<IntDiff>;
  street: Maybe<StringDiff>;
  sustainableConstructionDetails: Maybe<StringListDiff>;
  taxId: Maybe<StringDiff>;
  website: Maybe<StringDiff>;
  yearlyRevenue: Maybe<NumberDiff>;
};

export type AdminSubcontractorMergeResponse = {
  __typename: 'AdminSubcontractorMergeResponse';
  entity: AdminSubcontractorEntityMergeResponse;
  relations: AdminSubcontractorRelationsMergeResult;
};

export type AdminSubcontractorRelationsMergeResult = {
  __typename: 'AdminSubcontractorRelationsMergeResult';
  bidRequests: AdminSubcontractorRelationsSummary;
  certificates: AdminSubcontractorRelationsSummary;
  contacts: AdminSubcontractorContactRelationMergeResult;
  documentDownloads: AdminSubcontractorRelationsSummary;
  notes: AdminSubcontractorRelationsSummary;
  preferences: AdminSubcontractorRelationsSummary;
  subcontractorReferenceProjects: AdminSubcontractorRelationsSummary;
  workCategories: AdminSubcontractorWorkCategoryRelationMergeResult;
};

export type AdminSubcontractorRelationsSummary = {
  __typename: 'AdminSubcontractorRelationsSummary';
  toDelete: Scalars['Int']['output'];
  toMerge: Scalars['Int']['output'];
  toTransfer: Scalars['Int']['output'];
};

export type AdminSubcontractorWorkCategoryRelationMergeResult = {
  __typename: 'AdminSubcontractorWorkCategoryRelationMergeResult';
  toDelete: Array<DeletedWorkCategory>;
  toMerge: Array<WorkCategory>;
  toTransfer: Array<WorkCategory>;
};

export type AdminSubcontractorsImportRequestsResponse = {
  __typename: 'AdminSubcontractorsImportRequestsResponse';
  subcontractorImportRequests: Array<SubcontractorImportRequest>;
  totalRecords: Scalars['Int']['output'];
};

export type AdminSubcontractorsResponse = {
  __typename: 'AdminSubcontractorsResponse';
  subcontractors: Array<Subcontractor>;
  totalRecords: Scalars['Int']['output'];
  totalRecordsWithFailedEmails: Scalars['Int']['output'];
  totalRecordsWithUnconfirmedChanges: Scalars['Int']['output'];
};

export type AdminUpdateCompanyInput = {
  config: AdminCompanyConfigInput;
  hasOverdueInvoice: Scalars['Boolean']['input'];
  isTestCompany: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  salesforceServiceId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateMeInput = {
  email: Scalars['String']['input'];
};

export type AdminUpdateUserDepartmentInput = {
  customDepartmentName?: InputMaybe<Scalars['String']['input']>;
  departmentType: DEPARTMENT_TYPE;
};

export type AdminUpdateUserEmailInput = {
  email: Scalars['String']['input'];
};

export type AdminUserPermissionConstraints = {
  __typename: 'AdminUserPermissionConstraints';
  key: Scalars['String']['output'];
};

export type AdminUserPermissionResponse = {
  __typename: 'AdminUserPermissionResponse';
  action: Array<Scalars['String']['output']>;
  constraints: Maybe<AdminUserPermissionConstraints>;
  subject: Scalars['String']['output'];
};

export type AdminUsersResponse = {
  __typename: 'AdminUsersResponse';
  totalRecords: Scalars['Int']['output'];
  users: Array<UserWithConnectedUsers>;
};

export type AdminsResponse = {
  __typename: 'AdminsResponse';
  admins: Array<Admin>;
  totalRecords: Scalars['Int']['output'];
};

export type AgentCompanyOnboardAddReferenceProject = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardAddReferenceProject';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardCompleteCompanyProfile = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardCompleteCompanyProfile';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  projectsWithInvitesCount: Scalars['Float']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardCreateOwnRequest = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardCreateOwnRequest';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardDiscoverCosunoMarketplace = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardDiscoverCosunoMarketplace';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  projectsInMarketplaceCount: Scalars['Float']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardIncompleteItem =
  | AgentCompanyOnboardAddReferenceProject
  | AgentCompanyOnboardCompleteCompanyProfile
  | AgentCompanyOnboardCreateOwnRequest
  | AgentCompanyOnboardDiscoverCosunoMarketplace
  | AgentCompanyOnboardInviteColleagues
  | AgentCompanyOnboardRespondToRequests
  | AgentCompanyOnboardSaveSearch
  | AgentCompanyOnboardSpecifyMainTrades
  | AgentCompanyOnboardUploadCertificates;

export type AgentCompanyOnboardInviteColleagues = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardInviteColleagues';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardRespondToRequests = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardRespondToRequests';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
  unansweredRequests: Scalars['Float']['output'];
};

export type AgentCompanyOnboardSaveSearch = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardSaveSearch';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardSpecifyMainTrades = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardSpecifyMainTrades';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyOnboardUploadCertificates = AbstractCompletableItem & {
  __typename: 'AgentCompanyOnboardUploadCertificates';
  isCompletable: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type AgentCompanyProfilePrefillContact = {
  __typename: 'AgentCompanyProfilePrefillContact';
  email: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  isPerson: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  title: Maybe<CONTACT_TITLE>;
};

export type AgentCompanyProfilePrefillResponse = {
  __typename: 'AgentCompanyProfilePrefillResponse';
  aboutUs: Maybe<Scalars['HTML']['output']>;
  city: Maybe<Scalars['String']['output']>;
  companySize: Maybe<COMPANY_SIZE>;
  contacts: Array<AgentCompanyProfilePrefillContact>;
  countryCode: Maybe<COUNTRY_CODE>;
  imageKeys: Array<Scalars['String']['output']>;
  logoKey: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  orderAreaCountryCode: Maybe<COUNTRY_CODE>;
  orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
  orderAreaType: Maybe<ORDER_AREA_TYPE>;
  orderRadius: Maybe<ORDER_RADIUS>;
  postalCode: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
  workCategories: Array<AgentCompanyProfilePrefillWorkCategory>;
};

export type AgentCompanyProfilePrefillWorkCategory = {
  __typename: 'AgentCompanyProfilePrefillWorkCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AgentSignupForAwardedBidInput = {
  awardedBidId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AgentSignupForBidInviteInput = {
  bidInviteId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AgentSignupForBidSubmissionInput = {
  bidRequestId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AgentSignupForCertificatesSubmissionInput = {
  certificateRequestId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AgentSignupForMarketplaceSuggestionInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  marketplaceSuggestionId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

export type AgentSignupForSubcontractorSentEmailInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  sentEmailId: Scalars['ID']['input'];
};

export type AgentSignupResponse = {
  __typename: 'AgentSignupResponse';
  company: Company;
  isFirstUser: Scalars['Boolean']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type AgentSignupUnsolicitedInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isPremium?: Scalars['Boolean']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  subcontractorId?: InputMaybe<Scalars['String']['input']>;
  subcontractorName?: InputMaybe<Scalars['String']['input']>;
};

export type AlternativePositionSelection = {
  alternativeGroupId: Scalars['Int']['input'];
  billOfQuantitiesId: Scalars['String']['input'];
  positionId: Scalars['String']['input'];
};

export type AnalyticsEvent = AbstractEntity & {
  __typename: 'AnalyticsEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  deviceId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  props: Scalars['JSONObject']['output'];
};

export type AnalyticsEventInput = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  props: Scalars['JSONObject']['input'];
};

export type AnyNotification =
  | AwardBidderReminderNotification
  | BidPackageExportBidsNotification
  | BidPackageStatusChangedNotification
  | BidRequestDeclinedNotification
  | BiddersAwaitingResponseNotification
  | BiddingPeriodEndingNotification
  | CertificateExpiringNotification
  | CertificateExpiringSubcontractorNotification
  | CertificateNotProvidedInTimeNotification
  | CertificateUploadedBySubcontractorNotification
  | ContractAgentSignatureRequestedNotification
  | ContractMultiSigningCompletedNotification
  | ContractPrincipalSignatureRequestedNotification
  | ContractSignedByAgentNotification
  | InvoiceAwaitingApprovalNotification
  | InvoiceRejectedNotification
  | NewBidNotification
  | NewMessageNotification
  | ProjectExportCompleteNotification
  | SubcontractorImportRequestCompletedNotification
  | TaskAssignedNotification
  | TaskCompletedNotification;

export type AttachableInput = {
  attachableId: Scalars['ID']['input'];
  attachableType: DOCUMENT_ENTITY;
};

export type AuthenticatorData = {
  __typename: 'AuthenticatorData';
  secret: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type AuthorizeInput = {
  Addendum?: InputMaybe<AddendumAuthInput>;
  Admin?: InputMaybe<AdminAuthInput>;
  Bid?: InputMaybe<BidAuthInput>;
  BidInvite?: InputMaybe<BidInviteAuthInput>;
  BidPackage?: InputMaybe<BidPackageAuthInput>;
  BidRequest?: InputMaybe<BidRequestAuthInput>;
  BillOfQuantities?: InputMaybe<BillOfQuantitiesAuthInput>;
  Certificate?: InputMaybe<CertificateAuthInput>;
  CertificateType?: InputMaybe<CertificateTypeAuthInput>;
  CertificateTypeTranslation?: InputMaybe<CertificateTypeTranslationAuthInput>;
  Company?: InputMaybe<CompanyAuthInput>;
  CompanySubcontractorsImport?: InputMaybe<CompanySubcontractorsImportAuthInput>;
  CompanyTemplate?: InputMaybe<CompanyTemplateAuthInput>;
  Contact?: InputMaybe<ContactAuthInput>;
  CustomCostGroupCatalog?: InputMaybe<CustomCostGroupCatalogAuthInput>;
  ExternalCredential?: InputMaybe<ExternalCredentialAuthInput>;
  Invoice?: InputMaybe<InvoiceAuthInput>;
  MarketplaceSuggestion?: InputMaybe<MarketplaceSuggestionAuthInput>;
  Office?: InputMaybe<OfficeAuthInput>;
  Project?: InputMaybe<ProjectAuthInput>;
  SentEmail?: InputMaybe<SentEmailAuthInput>;
  Signup?: InputMaybe<SignupAuthInput>;
  Subcontractor?: InputMaybe<SubcontractorAuthInput>;
  SubcontractorNote?: InputMaybe<SubcontractorNoteAuthInput>;
  Task?: InputMaybe<TaskAuthInput>;
  TaskNote?: InputMaybe<TaskNoteAuthInput>;
  User?: InputMaybe<UserAuthInput>;
  WorkCategory?: InputMaybe<WorkCategoryAuthInput>;
  WorkCategoryTranslation?: InputMaybe<WorkCategoryTranslationAuthInput>;
};

export type AwardBidRequestInput = {
  absoluteDiscount?: InputMaybe<Scalars['Float']['input']>;
  absoluteDiscountCalculated: Scalars['Float']['input'];
  additionalCosts: Scalars['Float']['input'];
  awardedSumType?: InputMaybe<BID_AWARDED_SUM_TYPE>;
  awarding: EmailTemplateNotificationInput;
  bidId: Scalars['ID']['input'];
  bidRequestId: Scalars['ID']['input'];
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  discountRateCalculated: Scalars['Float']['input'];
  mainOrderNumber?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  rejection: EmailTemplateNotificationInput;
  skontoDeadline: Scalars['Int']['input'];
  skontoRate: Scalars['Float']['input'];
  taxRate: Scalars['Float']['input'];
  taxValue: Scalars['Float']['input'];
  totalGross: Scalars['Float']['input'];
  totalGrossWithSkonto: Scalars['Float']['input'];
  totalNet: Scalars['Float']['input'];
  totalNetWithDiscount: Scalars['Float']['input'];
};

export type AwardBidderReminderNotification = AbstractEntity &
  Notification & {
    __typename: 'AwardBidderReminderNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: AwardBidderReminderNotificationProps;
  };

export type AwardBidderReminderNotificationProps = {
  __typename: 'AwardBidderReminderNotificationProps';
  bidPackageBidsDueWeeksAgo: Scalars['Int']['output'];
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type AwardedBid = AbstractEntity & {
  __typename: 'AwardedBid';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Scalars['Float']['output'];
  awardedAt: Scalars['DateTimeISO']['output'];
  awardedSumType: Maybe<BID_AWARDED_SUM_TYPE>;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesRevision: BillOfQuantitiesRevision;
  boqEditingStatus: Maybe<BOQ_EDITING_STATUS>;
  createdAt: Scalars['DateTimeISO']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  documentsCount: Scalars['Int']['output'];
  hasBoQ: Scalars['Boolean']['output'];
  hasOutdatedBoQ: Maybe<REASON_PROPOSAL_BOQ_IS_OUTDATED>;
  id: Scalars['ID']['output'];
  isEligibleForAwarding: Scalars['Boolean']['output'];
  isSimulated: Maybe<Scalars['Boolean']['output']>;
  mainOrderNumber: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  parentBid: Maybe<Bid>;
  skontoDeadline: Scalars['Int']['output'];
  skontoRate: Scalars['Float']['output'];
  status: BID_STATUS;
  submittedAt: Maybe<Scalars['DateTimeISO']['output']>;
  submittedByUser: Maybe<User>;
  taxRate: Scalars['Float']['output'];
  taxValue: Scalars['Float']['output'];
  totalGross: Scalars['Float']['output'];
  totalNet: Scalars['Float']['output'];
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  version: Maybe<Scalars['Int']['output']>;
  warningMetrics: BidWarningMetrics;
};

export type AwardedBidsForAgentResponse = {
  __typename: 'AwardedBidsForAgentResponse';
  bids: Array<AwardedBid>;
  totalRecords: Scalars['Int']['output'];
};

export enum BIDDER_STATUS {
  new = 'new',
  pending = 'pending',
  responsive = 'responsive',
  unresponsive = 'unresponsive',
}

export enum BIDS_SORT_FIELD {
  awardedAt = 'awardedAt',
  bidPackageName = 'bidPackageName',
  bidPackageStatus = 'bidPackageStatus',
  officeName = 'officeName',
  projectName = 'projectName',
  submittedAt = 'submittedAt',
}

export enum BID_AWARDED_SUM_TYPE {
  lumpSum = 'lumpSum',
  unitPricesFromBoQ = 'unitPricesFromBoQ',
}

export enum BID_INVITE_REMINDER_SCHEDULE {
  everyTwoWeeks = 'everyTwoWeeks',
  none = 'none',
  oneDayBeforeDue = 'oneDayBeforeDue',
  oneWeekBeforeDue = 'oneWeekBeforeDue',
  threeDaysBeforeDue = 'threeDaysBeforeDue',
  twoWeeksBeforeDue = 'twoWeeksBeforeDue',
  weekly = 'weekly',
}

export enum BID_INVITE_STATUS {
  draft = 'draft',
  failed = 'failed',
  opened = 'opened',
  sent = 'sent',
  viewed = 'viewed',
}

export enum BID_INVITE_STATUS_CHANGE_STATUS {
  draft = 'draft',
  failed = 'failed',
  opened = 'opened',
  restored = 'restored',
  sent = 'sent',
  viewed = 'viewed',
  withdrawn = 'withdrawn',
}

export enum BID_PACKAGES_MARKETPLACE_SORT_FIELD {
  bidsDueAt = 'bidsDueAt',
  distance = 'distance',
  name = 'name',
  projectName = 'projectName',
  publishedToMarketplaceAt = 'publishedToMarketplaceAt',
  recommended = 'recommended',
}

export enum BID_PACKAGES_SORT_FIELD {
  addendaTotalGross = 'addendaTotalGross',
  addendaTotalNet = 'addendaTotalNet',
  awardedBidAndAddendaTotalNet = 'awardedBidAndAddendaTotalNet',
  awardedBidTotalGross = 'awardedBidTotalGross',
  awardedBidTotalNet = 'awardedBidTotalNet',
  bidRequestsAwaitingResponseCount = 'bidRequestsAwaitingResponseCount',
  bidsDueAt = 'bidsDueAt',
  budget = 'budget',
  name = 'name',
  number = 'number',
  projectName = 'projectName',
  status = 'status',
}

export enum BID_PACKAGE_STATUS {
  bidAwarded = 'bidAwarded',
  closed = 'closed',
  contractSigning = 'contractSigning',
  contracted = 'contracted',
  draft = 'draft',
  inNegotiation = 'inNegotiation',
  published = 'published',
}

export enum BID_REQUESTS_SORT_FIELD {
  bidDueAt = 'bidDueAt',
  bidPackageName = 'bidPackageName',
  bidRequestStatus = 'bidRequestStatus',
  bidRequestStatusForAgent = 'bidRequestStatusForAgent',
  companyName = 'companyName',
  distanceInKms = 'distanceInKms',
  lastStatusChangeAt = 'lastStatusChangeAt',
  officeName = 'officeName',
  projectName = 'projectName',
  projectNumber = 'projectNumber',
  subcontractorName = 'subcontractorName',
}

export enum BID_REQUEST_DECLINED_REASON {
  companyClosed = 'companyClosed',
  distanceTooLarge = 'distanceTooLarge',
  insufficientCapacity = 'insufficientCapacity',
  notOfferingThisService = 'notOfferingThisService',
  other = 'other',
}

export enum BID_REQUEST_SOURCE {
  invite = 'invite',
  marketplace = 'marketplace',
}

export enum BID_REQUEST_STATUS {
  accepted = 'accepted',
  bidAwarded = 'bidAwarded',
  bidRejected = 'bidRejected',
  bidSubmitted = 'bidSubmitted',
  inNegotiation = 'inNegotiation',
  inviteDeclined = 'inviteDeclined',
  invited = 'invited',
  opened = 'opened',
  pendingInvite = 'pendingInvite',
  undecided = 'undecided',
  viewed = 'viewed',
  watched = 'watched',
  withdrawn = 'withdrawn',
}

export enum BID_REQUEST_STATUS_CHANGED_BY {
  anonymousSubcontractor = 'anonymousSubcontractor',
  user = 'user',
}

export enum BID_REQUEST_STATUS_FOR_AGENT {
  accepted = 'accepted',
  bidAwarded = 'bidAwarded',
  bidLost = 'bidLost',
  bidSubmitted = 'bidSubmitted',
  closed = 'closed',
  contractSigning = 'contractSigning',
  contracted = 'contracted',
  declined = 'declined',
  pending = 'pending',
  undecided = 'undecided',
  watched = 'watched',
  withdrawn = 'withdrawn',
}

export enum BID_REQUEST_TYPE {
  createdByAgent = 'createdByAgent',
  createdByPrincipal = 'createdByPrincipal',
}

export enum BID_STATUS {
  awarded = 'awarded',
  draft = 'draft',
  generated = 'generated',
  submitted = 'submitted',
}

export enum BOQ_COMPARISON_MODE {
  average = 'average',
  customBudget = 'customBudget',
  ideal = 'ideal',
  lowest = 'lowest',
}

export enum BOQ_CONTAINER_TYPE {
  bid = 'bid',
  bidPackage = 'bidPackage',
  boqTemplate = 'boqTemplate',
}

export enum BOQ_EDITING_STATUS {
  complete = 'complete',
  empty = 'empty',
  incomplete = 'incomplete',
}

export enum BOQ_EDIT_OPERATION {
  addElement = 'addElement',
  clearField = 'clearField',
  deleteElement = 'deleteElement',
  fillOutField = 'fillOutField',
  moveElement = 'moveElement',
  renumber = 'renumber',
  togglePositionType = 'togglePositionType',
  updateProjectInformation = 'updateProjectInformation',
}

export enum BOQ_ELEMENT_TYPE_DISCRIMINATOR {
  AdditionalText = 'AdditionalText',
  ExecutionDescriptionDto = 'ExecutionDescriptionDto',
  NoteTextDto = 'NoteTextDto',
  PositionDto = 'PositionDto',
  ServiceSpecificationGroupDto = 'ServiceSpecificationGroupDto',
  SubDescriptionDto = 'SubDescriptionDto',
}

export enum BOQ_EXCHANGE_PHASE {
  alternativeProposal = 'alternativeProposal',
  contract = 'contract',
  costEstimation = 'costEstimation',
  proposal = 'proposal',
  requestForProposal = 'requestForProposal',
}

export enum BOQ_FILE_FORMAT {
  gaeb90 = 'gaeb90',
  gaebXml = 'gaebXml',
  oenorm = 'oenorm',
  xlsx = 'xlsx',
}

export enum BOQ_FILE_TYPE {
  d11 = 'd11',
  d81 = 'd81',
  d82 = 'd82',
  d83 = 'd83',
  d84 = 'd84',
  d86 = 'd86',
  dta = 'dta',
  onlv = 'onlv',
  p81 = 'p81',
  p82 = 'p82',
  p83 = 'p83',
  p84 = 'p84',
  p86 = 'p86',
  x31 = 'x31',
  x31_33 = 'x31_33',
  x81 = 'x81',
  x81_33 = 'x81_33',
  x82 = 'x82',
  x82_33 = 'x82_33',
  x83 = 'x83',
  x83_33 = 'x83_33',
  x84 = 'x84',
  x84_33 = 'x84_33',
  x86 = 'x86',
  x86_33 = 'x86_33',
  xlsxProposal = 'xlsxProposal',
  xlsxRequestForProposals = 'xlsxRequestForProposals',
}

export enum BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT {
  d86 = 'd86',
  p86 = 'p86',
  pdf = 'pdf',
  x86 = 'x86',
  x86_33 = 'x86_33',
}

export enum BOQ_FORMAL_REVIEW_ERROR_REASON {
  additionalTextNotAtBeginningOrEnd = 'additionalTextNotAtBeginningOrEnd',
  currencyDoesntMatchProject = 'currencyDoesntMatchProject',
  currencyNotSupported = 'currencyNotSupported',
  executionDescriptionExist = 'executionDescriptionExist',
  executionDescriptionNotReferencedByAnyPosition = 'executionDescriptionNotReferencedByAnyPosition',
  executionDescriptionNoteWithoutLongText = 'executionDescriptionNoteWithoutLongText',
  executionDescriptionShortTextIsMissing = 'executionDescriptionShortTextIsMissing',
  groupShortTextMissing = 'groupShortTextMissing',
  itemNumberContainsUnderscores = 'itemNumberContainsUnderscores',
  itemNumberDigitsAmountGreaterThan9GAEB90 = 'itemNumberDigitsAmountGreaterThan9GAEB90',
  itemNumberDigitsAmountGreaterThan12OENORM = 'itemNumberDigitsAmountGreaterThan12OENORM',
  itemNumberDigitsAmountGreaterThan14GAEBXML = 'itemNumberDigitsAmountGreaterThan14GAEBXML',
  itemNumberMainFragmentNotPadded = 'itemNumberMainFragmentNotPadded',
  itemNumberMustBeInAscendingOrder = 'itemNumberMustBeInAscendingOrder',
  itemNumberOfGroupIsAlphanumeric = 'itemNumberOfGroupIsAlphanumeric',
  itemNumberOfGroupIsMissing = 'itemNumberOfGroupIsMissing',
  itemNumberOfPositionIsMissing = 'itemNumberOfPositionIsMissing',
  itemNumberOfPositionIsNotUnique = 'itemNumberOfPositionIsNotUnique',
  itemNumberShouldContainParentItemNumber = 'itemNumberShouldContainParentItemNumber',
  longTextHasImage = 'longTextHasImage',
  longTextMissing = 'longTextMissing',
  noteTextShouldBeFirstChildren = 'noteTextShouldBeFirstChildren',
  positionCostGroupReferenceMissing = 'positionCostGroupReferenceMissing',
  positionHasBidderInput = 'positionHasBidderInput',
  positionHasBidderInputInShortText = 'positionHasBidderInputInShortText',
  positionLongTextLongerThan999 = 'positionLongTextLongerThan999',
  positionLongTextMissing = 'positionLongTextMissing',
  positionQuantityDecimalPartLongerThan3 = 'positionQuantityDecimalPartLongerThan3',
  positionQuantityIntegerPartLongerThan8 = 'positionQuantityIntegerPartLongerThan8',
  positionQuantityMissing = 'positionQuantityMissing',
  positionTypesAlternativePositionWithoutBasePosition = 'positionTypesAlternativePositionWithoutBasePosition',
  positionTypesBasePositionWithoutAlternatives = 'positionTypesBasePositionWithoutAlternatives',
  positionTypesLumpSumQuantityCannotBeCombinedWithFreeQuantity = 'positionTypesLumpSumQuantityCannotBeCombinedWithFreeQuantity',
  positionTypesLumpSumQuantityShouldBeOne = 'positionTypesLumpSumQuantityShouldBeOne',
  positionTypesSurchargeHasDifferentTaxRate = 'positionTypesSurchargeHasDifferentTaxRate',
  positionUnitPriceDecimalPartLongerThan3 = 'positionUnitPriceDecimalPartLongerThan3',
  positionUnitPriceIntegerPartLongerThan10 = 'positionUnitPriceIntegerPartLongerThan10',
  positionUnitTagMissing = 'positionUnitTagMissing',
  positionUnitTagNotSupportedByOENORM = 'positionUnitTagNotSupportedByOENORM',
  priceComponentsCountMoreThanExpected = 'priceComponentsCountMoreThanExpected',
  repeatingDescriptionBeforeReferralDescription = 'repeatingDescriptionBeforeReferralDescription',
  repeatingDescriptionNotReferringExistingReferralDescription = 'repeatingDescriptionNotReferringExistingReferralDescription',
  repeatingDescriptionReferringAnotherRepeatingDescription = 'repeatingDescriptionReferringAnotherRepeatingDescription',
  schemaTierCountLessThan3ForOENORM = 'schemaTierCountLessThan3ForOENORM',
  schemaTierCountMoreThan4ForGaeb90 = 'schemaTierCountMoreThan4ForGaeb90',
  schemaTierCountMoreThan5ForGaebXML = 'schemaTierCountMoreThan5ForGaebXML',
  schemaTierCountMoreThan5ForOENORM = 'schemaTierCountMoreThan5ForOENORM',
  schemaTierLengthGreaterThan2OENORM = 'schemaTierLengthGreaterThan2OENORM',
  shortTextMissing = 'shortTextMissing',
  subDescriptionIdentifierNotCompliant = 'subDescriptionIdentifierNotCompliant',
  subDescriptionIdentifierNotInAscendingOrder = 'subDescriptionIdentifierNotInAscendingOrder',
}

export enum BOQ_FORMAL_REVIEW_ISSUE_LEVEL {
  error = 'error',
  warning = 'warning',
}

export enum BOQ_FORMAL_REVIEW_RESULT {
  errors = 'errors',
  noIssues = 'noIssues',
  warnings = 'warnings',
}

export enum BOQ_MASS_DETERMINATION_STATUS {
  appliedAsQuantity = 'appliedAsQuantity',
  savedAsPartOfBoq = 'savedAsPartOfBoq',
}

export enum BOQ_POSITION_TEXT_PROVIDER {
  boqLibrary = 'boqLibrary',
  freeText = 'freeText',
  heinzeAt = 'heinzeAt',
  heinzeVob = 'heinzeVob',
  lbHochbau = 'lbHochbau',
  stlb = 'stlb',
}

export enum BOQ_POSITION_TYPE {
  Alternative = 'Alternative',
  Base = 'Base',
  FreeQuantity = 'FreeQuantity',
  LumpSum = 'LumpSum',
  NotApplicable = 'NotApplicable',
  Optional = 'Optional',
  OptionalWithTotal = 'OptionalWithTotal',
  ReferringExecutionDescription = 'ReferringExecutionDescription',
  Regular = 'Regular',
  RepeatingDescription = 'RepeatingDescription',
  Surcharge = 'Surcharge',
  ToBeSurcharged = 'ToBeSurcharged',
  UnitPriceComponents = 'UnitPriceComponents',
}

export enum BOQ_TEMPLATES_SORT_FIELD {
  name = 'name',
  updatedAt = 'updatedAt',
}

export enum BOQ_TEMPLATE_COST_CATALOG_TYPE {
  custom = 'custom',
  din276 = 'din276',
  oenorm = 'oenorm',
  stlbWorkCategory = 'stlbWorkCategory',
}

export enum BOQ_TIER_DATA_TYPE {
  Alphanumeric = 'Alphanumeric',
  Numeric = 'Numeric',
}

export enum BOQ_TIER_TYPE {
  Group = 'Group',
  Index = 'Index',
  Lot = 'Lot',
  Position = 'Position',
}

export enum BOQ_TYPE {
  addendum = 'addendum',
  customBudget = 'customBudget',
  proposal = 'proposal',
  requestForProposal = 'requestForProposal',
  template = 'template',
}

export enum BOQ_VALIDATION_ERROR_REASON {
  duplicateItemNumber = 'duplicateItemNumber',
  elementRowDoesNotMatchRequestForProposals = 'elementRowDoesNotMatchRequestForProposals',
  elementRowsNotFound = 'elementRowsNotFound',
  itemNumberContainsInvalidCharacters = 'itemNumberContainsInvalidCharacters',
  notEmptyNorNonNegativeNumber = 'notEmptyNorNonNegativeNumber',
  notEmptyNorNumber = 'notEmptyNorNumber',
  notEmptyNorPercentage = 'notEmptyNorPercentage',
  unknownElementType = 'unknownElementType',
  unrecognizedPositionCostGroup = 'unrecognizedPositionCostGroup',
}

export enum BOQ_VALIDATION_WARNING_REASON {
  doesntExist = 'doesntExist',
  duplicateTextAdditionLabel = 'duplicateTextAdditionLabel',
  notFilledIn = 'notFilledIn',
  positionHasInvalidCostGroupAssigned = 'positionHasInvalidCostGroupAssigned',
  quantitiesDontMatch = 'quantitiesDontMatch',
  quantityMissing = 'quantityMissing',
  quantityNotFilledIn = 'quantityNotFilledIn',
  unknownElementType = 'unknownElementType',
}

export enum BP_PUBLICATION_BID_PACKAGE_DURATION_ISSUE {
  endDateMissing = 'endDateMissing',
  startDateMissing = 'startDateMissing',
}

export enum BP_PUBLICATION_BOQ_INCLUDED_ISSUE {
  hasErrors = 'hasErrors',
  missingSchema = 'missingSchema',
  notProvided = 'notProvided',
}

export enum BP_PUBLICATION_CONTACT_DETAILS_INCLUDED_ISSUE {
  noPhoneUserIsContact = 'noPhoneUserIsContact',
  noPhoneUserIsNotAContact = 'noPhoneUserIsNotAContact',
}

export enum BP_PUBLICATION_DESCRIPTIVE_TENDER_SETUP_ISSUE {
  descriptionSameAsProject = 'descriptionSameAsProject',
  nameAndDescriptionSameAsProject = 'nameAndDescriptionSameAsProject',
  nameSameAsProject = 'nameSameAsProject',
  nameSameAsProjectNoDescription = 'nameSameAsProjectNoDescription',
  shortDescription = 'shortDescription',
}

export enum BP_PUBLICATION_DOCUMENTS_INCLUDED_ISSUE {
  noneUploaded = 'noneUploaded',
}

export enum BP_PUBLICATION_ENABLE_MARKETPLACE {
  notPublishedOnMarketplace = 'notPublishedOnMarketplace',
}

export enum BP_PUBLICATION_OFFICE_DETAILS_INCLUDED_ISSUE {
  notProvided = 'notProvided',
}

export enum BP_PUBLICATION_REASONABLE_BID_DUE_DATE_ISSUE {
  tooSoon = 'tooSoon',
}

export enum BP_PUBLICATION_SUFFICIENT_NUMBER_OF_BIDDERS_ISSUE {
  insufficient = 'insufficient',
}

export enum BULK_ADD_CONTACTS_AMOUNT {
  x10 = 'x10',
  x25 = 'x25',
  x50 = 'x50',
}

export type BaseBiddersAwaitingResponseNotificationProps = {
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  subcontractorNames: Array<Scalars['String']['output']>;
};

export type Bid = AbstractEntity & {
  __typename: 'Bid';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Maybe<Scalars['Float']['output']>;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesRevision: BillOfQuantitiesRevision;
  boqEditingStatus: Maybe<BOQ_EDITING_STATUS>;
  createdAt: Scalars['DateTimeISO']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  documentsCount: Scalars['Int']['output'];
  hasBoQ: Scalars['Boolean']['output'];
  hasOutdatedBoQ: Maybe<REASON_PROPOSAL_BOQ_IS_OUTDATED>;
  id: Scalars['ID']['output'];
  isEligibleForAwarding: Scalars['Boolean']['output'];
  isSimulated: Maybe<Scalars['Boolean']['output']>;
  message: Maybe<Scalars['String']['output']>;
  parentBid: Maybe<Bid>;
  skontoDeadline: Maybe<Scalars['Int']['output']>;
  skontoRate: Maybe<Scalars['Float']['output']>;
  status: BID_STATUS;
  submittedAt: Maybe<Scalars['DateTimeISO']['output']>;
  submittedByUser: Maybe<User>;
  taxRate: Maybe<Scalars['Float']['output']>;
  totalNet: Scalars['Float']['output'];
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  version: Maybe<Scalars['Int']['output']>;
  warningMetrics: BidWarningMetrics;
};

export enum BidAuthActions {
  manageDocuments = 'manageDocuments',
  signContract = 'signContract',
  update = 'update',
  update__message = 'update__message',
  view = 'view',
}

export type BidAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type BidAuthInput = {
  action: BidAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BidAuthArgsInput>;
};

export type BidColumnConfig = {
  bidId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId: Scalars['ID']['input'];
};

export type BidColumnResponse = {
  __typename: 'BidColumnResponse';
  availableBids: Array<Bid>;
  bid: SubmittedOrGeneratedSubmittedBid;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  subcontractor: Subcontractor;
};

export type BidInput = {
  absoluteDiscount?: InputMaybe<Scalars['Float']['input']>;
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  skontoDeadline?: InputMaybe<Scalars['Int']['input']>;
  skontoRate?: InputMaybe<Scalars['Float']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  totalNet?: InputMaybe<Scalars['Float']['input']>;
};

export type BidInvite = AbstractEntity & {
  __typename: 'BidInvite';
  bidRequest: BidRequest;
  contact: Contact;
  createdAt: Scalars['DateTimeISO']['output'];
  customInvitationEmailIntro: Maybe<Scalars['HTML']['output']>;
  id: Scalars['ID']['output'];
  status: BID_INVITE_STATUS;
};

export enum BidInviteAuthActions {
  delete = 'delete',
  view = 'view',
  withdraw = 'withdraw',
}

export type BidInviteAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type BidInviteAuthInput = {
  action: BidInviteAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BidInviteAuthArgsInput>;
};

export type BidInviteStatusChangeResponse = {
  __typename: 'BidInviteStatusChangeResponse';
  bidInviteStatus: BID_INVITE_STATUS_CHANGE_STATUS;
  contact: Contact;
  id: Scalars['ID']['output'];
  occurredAt: Scalars['DateTimeISO']['output'];
  userName: Maybe<Scalars['String']['output']>;
};

export type BidPackage = AbstractEntity & {
  __typename: 'BidPackage';
  addedContactIds: Array<Scalars['ID']['output']>;
  addendaTotalGross: Maybe<Scalars['Float']['output']>;
  addendaTotalNet: Maybe<Scalars['Float']['output']>;
  addendumNumbers: Array<Scalars['Int']['output']>;
  agentBidRequest: Maybe<BidRequest>;
  allCorrespondingWorkCategories: Array<WorkCategory>;
  allowBiddersDownloadBoQPdf: Scalars['Boolean']['output'];
  allowBidsAfterDueDate: Scalars['Boolean']['output'];
  awardedBid: Maybe<AwardedBid>;
  awardedBidRequestId: Maybe<Scalars['ID']['output']>;
  awardedBidTotalGross: Maybe<Scalars['Float']['output']>;
  awardedBidTotalNet: Maybe<Scalars['Float']['output']>;
  awardingDate: Maybe<Scalars['Date']['output']>;
  bidColumnsForPriceComparison: Array<BidColumnResponse>;
  bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
  bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
  bidPackageUsers: Array<BidPackageUser>;
  bidRequests: Array<BidRequest>;
  bidRequestsAwaitingResponseCount: Scalars['Int']['output'];
  bidRequestsNotNotifiedOfRejection: Array<Scalars['ID']['output']>;
  bidRequestsToNotifyOfClosingCount: Scalars['Int']['output'];
  bidRequestsToNotifyOfDeletionCount: Scalars['Int']['output'];
  biddersWithBidsCount: Scalars['Int']['output'];
  bidsDueAt: Scalars['DateTimeISO']['output'];
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesId: Maybe<Scalars['ID']['output']>;
  billOfQuantitiesRevision: Maybe<BillOfQuantitiesRevision>;
  billOfQuantitiesRevisions: Array<BillOfQuantitiesRevision>;
  bindingPeriod: Maybe<Scalars['Date']['output']>;
  blindTenderBidsOpenedAt: Maybe<Scalars['DateTimeISO']['output']>;
  boqPositionTextProvider: BOQ_POSITION_TEXT_PROVIDER;
  budget: Maybe<Scalars['Float']['output']>;
  contactPersons: Array<BidPackageUser>;
  contractAgentAssignee: Maybe<ContractAgentAssignee>;
  contractAssignee: Maybe<ContractAssignee>;
  contractDocumentsRequiringSignature: Array<Scalars['ID']['output']>;
  contractDocumentsRequiringSignatureTotalSize: Scalars['Int']['output'];
  contractRunAgentStepContacts: Array<Contact>;
  contractRunStepUsers: Array<User>;
  createdAt: Scalars['DateTimeISO']['output'];
  currency: CURRENCY;
  customBudgetBoQ: Maybe<BillOfQuantities>;
  customInvitationEmailIntro: Maybe<Scalars['HTML']['output']>;
  description: Maybe<Scalars['String']['output']>;
  detachedProposalBillOfQuantities: Maybe<BillOfQuantities>;
  distanceInKm: Maybe<Scalars['Float']['output']>;
  documentCount: Scalars['Int']['output'];
  endsOn: Maybe<Scalars['Date']['output']>;
  hasAttachedDocuments: Scalars['Boolean']['output'];
  hasBidders: Scalars['Boolean']['output'];
  hasBiddersFromCosunoNetwork: Scalars['Boolean']['output'];
  hasBiddersWithSubmittedBids: Scalars['Boolean']['output'];
  hasBillOfQuantitiesSchema: Scalars['Boolean']['output'];
  hasContractDocuments: Scalars['Boolean']['output'];
  hasContractDocumentsRequiringSignature: Scalars['Boolean']['output'];
  hasContractDocumentsRequiringSignatureToggling: Scalars['Boolean']['output'];
  hasOutdatedContractBoQ: Scalars['Boolean']['output'];
  hasUnsavedBillOfQuantitiesChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invitedBiddersCount: Scalars['Int']['output'];
  isBlindTender: Scalars['Boolean']['output'];
  isContractSignedByAgent: Scalars['Boolean']['output'];
  isContractSignedByPrincipal: Scalars['Boolean']['output'];
  isContractSignedDocumentPending: Scalars['Boolean']['output'];
  isContractSignedPerUpload: Scalars['Boolean']['output'];
  isContractSigningEnabled: Scalars['Boolean']['output'];
  isDocuSignEnvelopeReset: Scalars['Boolean']['output'];
  isDocuSignOpened: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isLockedDueToPendingRequest: Scalars['Boolean']['output'];
  isPublishedOnMarketplace: Scalars['Boolean']['output'];
  isVisibleOnMarketplace: Scalars['Boolean']['output'];
  lastInvoice: Maybe<Invoice>;
  lat: Maybe<Scalars['Float']['output']>;
  long: Maybe<Scalars['Float']['output']>;
  marketplaceSuggestionsSent: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  networkAwaitingResponseCountIfRestricted: Maybe<Scalars['Int']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['String']['output']>;
  openingDate: Maybe<Scalars['DateTimeISO']['output']>;
  partialInvoices: Array<PartialInvoice>;
  primaryContactPerson: Maybe<PrimaryContactPersonResponse>;
  primaryUser: Maybe<User>;
  project: Project;
  projectId: Scalars['ID']['output'];
  publishedAt: Maybe<Scalars['DateTimeISO']['output']>;
  publishedToMarketplaceAt: Maybe<Scalars['DateTimeISO']['output']>;
  queryMatchesBoQ: Maybe<Scalars['Boolean']['output']>;
  reasonForClosing: Maybe<Scalars['String']['output']>;
  requestStatusCounts: BidRequestStatusCounts;
  startsOn: Maybe<Scalars['Date']['output']>;
  status: BID_PACKAGE_STATUS;
  submittableBidRequests: Array<BidRequest>;
  suggestedBiddersCount: Scalars['Int']['output'];
  unsavedBillOfQuantitiesAddedElementsIds: Array<Scalars['String']['output']>;
  withdrawnContactIds: Array<Scalars['ID']['output']>;
  workCategories: Array<WorkCategory>;
};

export type BidPackagebidColumnsForPriceComparisonArgs = {
  bidColumnConfigs: Array<BidColumnConfig>;
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type BidPackagebiddersWithBidsCountArgs = {
  source?: InputMaybe<BID_REQUEST_SOURCE>;
};

export type BidPackagebillOfQuantitiesRevisionArgs = {
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type BidPackagecustomBudgetBoQArgs = {
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export enum BidPackageAuthActions {
  addContacts = 'addContacts',
  close = 'close',
  createAddendum = 'createAddendum',
  createInvoice = 'createInvoice',
  createOrUpdateBoQ = 'createOrUpdateBoQ',
  createOrUpdateCalculationBoQ = 'createOrUpdateCalculationBoQ',
  delete = 'delete',
  deleteBoQ = 'deleteBoQ',
  duplicate = 'duplicate',
  modifyMarketplacePublicationConfig = 'modifyMarketplacePublicationConfig',
  openBlindTenderBids = 'openBlindTenderBids',
  openBlindTenderBidsApprove = 'openBlindTenderBidsApprove',
  predictPrices = 'predictPrices',
  prepareContract = 'prepareContract',
  previewMarketplaceSuggestion = 'previewMarketplaceSuggestion',
  printBoQ = 'printBoQ',
  publish = 'publish',
  publishApprove = 'publishApprove',
  reopen = 'reopen',
  sendMarketplaceSuggestions = 'sendMarketplaceSuggestions',
  sendMessage = 'sendMessage',
  simulatePriceComparison = 'simulatePriceComparison',
  submitContract = 'submitContract',
  submitContractApprove = 'submitContractApprove',
  update = 'update',
  updateBoQInternalNote = 'updateBoQInternalNote',
  update__internalData = 'update__internalData',
  uploadContractAgentSignature = 'uploadContractAgentSignature',
  view = 'view',
  viewMarketplaceList = 'viewMarketplaceList',
  viewOnMarketplace = 'viewOnMarketplace',
  view__documents = 'view__documents',
  view__internalData = 'view__internalData',
  view__messages = 'view__messages',
  view__priceComparison = 'view__priceComparison',
  view__priceComparisonDownloadUrl = 'view__priceComparisonDownloadUrl',
  view__reportingData = 'view__reportingData',
  withdrawContract = 'withdrawContract',
}

export type BidPackageAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type BidPackageAuthInput = {
  action: BidPackageAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BidPackageAuthArgsInput>;
};

export type BidPackageCompletionResponse =
  | BidPackageDuration
  | BoQIncluded
  | ContactDetailsIncluded
  | DescriptiveTenderSetup
  | DocumentsIncluded
  | EnableCosunoMarketplace
  | OfficeDetailsIncluded
  | ReasonableBidDueDate
  | SufficientNumberOfBidders;

export type BidPackageDuration = {
  __typename: 'BidPackageDuration';
  issue: Maybe<BP_PUBLICATION_BID_PACKAGE_DURATION_ISSUE>;
};

export type BidPackageEditorActionsResponse = {
  __typename: 'BidPackageEditorActionsResponse';
  delete: Scalars['Boolean']['output'];
  edit: Scalars['Boolean']['output'];
  upload: Scalars['Boolean']['output'];
};

export type BidPackageExportBidsNotification = AbstractEntity &
  Notification & {
    __typename: 'BidPackageExportBidsNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: BidPackageExportBidsNotificationProps;
  };

export type BidPackageExportBidsNotificationProps = {
  __typename: 'BidPackageExportBidsNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type BidPackageInput = {
  allowBiddersDownloadBoQPdf?: InputMaybe<Scalars['Boolean']['input']>;
  allowBidsAfterDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  awardingDate?: InputMaybe<Scalars['Date']['input']>;
  bidInviteReminderScheduleForAccepted?: InputMaybe<BID_INVITE_REMINDER_SCHEDULE>;
  bidInviteReminderScheduleForNoResponse?: InputMaybe<BID_INVITE_REMINDER_SCHEDULE>;
  bidPackageUsers?: InputMaybe<Array<BidPackageUserInput>>;
  bidsDueAt: Scalars['DateTimeISO']['input'];
  bindingPeriod?: InputMaybe<Scalars['Date']['input']>;
  budget?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CURRENCY>;
  description?: InputMaybe<Scalars['String']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  isBlindTender?: InputMaybe<Scalars['Boolean']['input']>;
  isPublishedOnMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  openingDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  primaryUserId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  startsOn?: InputMaybe<Scalars['Date']['input']>;
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
};

export type BidPackageStatusChangedNotification = AbstractEntity &
  Notification & {
    __typename: 'BidPackageStatusChangedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: BidPackageStatusChangedNotificationProps;
  };

export type BidPackageStatusChangedNotificationProps = {
  __typename: 'BidPackageStatusChangedNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  status: BID_PACKAGE_STATUS;
};

export type BidPackageUser = AbstractEntity & {
  __typename: 'BidPackageUser';
  createdAt: Scalars['DateTimeISO']['output'];
  hasPendingMultiUserApprovalRequest: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isContactPerson: Scalars['Boolean']['output'];
  user: User;
  userRole: Scalars['String']['output'];
};

export type BidPackageUserInput = {
  isContactPerson: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
  userRole: Scalars['String']['input'];
};

export type BidPackagesForMarketplaceDefaultFilters = {
  __typename: 'BidPackagesForMarketplaceDefaultFilters';
  address: Maybe<Scalars['String']['output']>;
  distanceInKm: Maybe<Scalars['Int']['output']>;
  geoCoords: Maybe<Scalars['String']['output']>;
  workCategoryList: Maybe<Array<WorkCategory>>;
};

export type BidPackagesForMarketplaceResponse = {
  __typename: 'BidPackagesForMarketplaceResponse';
  bidPackages: Array<BidPackage>;
  defaultFilters: Maybe<BidPackagesForMarketplaceDefaultFilters>;
  /** @deprecated Field was used by the no longer running hide-premium-exclusive-bid-packages experiment. */
  premiumExclusiveBidPackages: Array<BidPackage>;
  /** @deprecated Field was used by the no longer running hide-premium-exclusive-bid-packages experiment. */
  premiumExclusiveBidPackagesCount: Scalars['Int']['output'];
  recentBidPackagesMatchingFiltersCount: Maybe<Scalars['Int']['output']>;
  totalRecords: Scalars['Int']['output'];
};

export type BidPackagesList = {
  __typename: 'BidPackagesList';
  bidPackages: Array<BidPackage>;
  totalRecords: Scalars['Int']['output'];
};

export type BidPackagesResponse = {
  __typename: 'BidPackagesResponse';
  bidPackages: Array<BidPackage>;
  totalRecords: Scalars['Int']['output'];
};

export type BidPdfSettings = {
  includeBiddersComments: Scalars['Boolean']['input'];
  includeExecutionDescriptions: Scalars['Boolean']['input'];
  includeLongDescriptions: Scalars['Boolean']['input'];
  includeNotes: Scalars['Boolean']['input'];
  orientation: ORIENTATION;
};

export type BidRequest = AbstractEntity & {
  __typename: 'BidRequest';
  agentCurrentBid: Maybe<SubmittedBid>;
  agentStatusSetBy: Maybe<User>;
  areDocumentsAvailable: Scalars['Boolean']['output'];
  awardedBid: Maybe<AwardedBid>;
  awardedBidId: Maybe<Scalars['ID']['output']>;
  bidPackage: BidPackage;
  bidPackageId: Maybe<Scalars['ID']['output']>;
  bidsEligibleForAwarding: Array<SubmittedBid>;
  contacts: Array<BidRequestContact>;
  createdAt: Scalars['DateTimeISO']['output'];
  currentBid: Maybe<SubmittedBid>;
  currentBidId: Maybe<Scalars['ID']['output']>;
  customBidsDueAt: Maybe<Scalars['DateTimeISO']['output']>;
  declinedReason: Maybe<BID_REQUEST_DECLINED_REASON>;
  declinedReasonOther: Maybe<Scalars['String']['output']>;
  distanceInKms: Maybe<Scalars['Float']['output']>;
  documentCount: Scalars['Float']['output'];
  historyItems: Array<HistoryItem>;
  id: Scalars['ID']['output'];
  isAgentStatusMutable: Scalars['Boolean']['output'];
  isAwaitingResponse: Scalars['Boolean']['output'];
  isProposedAwarded: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  lastStatusChangeAt: Scalars['DateTimeISO']['output'];
  marketplaceSamples: Array<MarketplaceBidPackageSample>;
  notes: Array<BidRequestNote>;
  notesCount: Scalars['Int']['output'];
  showAwardedStatusToSubcontractor: Maybe<Scalars['Boolean']['output']>;
  source: BID_REQUEST_SOURCE;
  status: BID_REQUEST_STATUS;
  statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
  subcontractor: Subcontractor;
  subcontractorId: Maybe<Scalars['ID']['output']>;
  submittedBid: SubmittedBid;
  submittedBids: Array<SubmittedBid>;
  type: BID_REQUEST_TYPE;
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  wasSubcontractorNotifiedOfRejection: Maybe<Scalars['Boolean']['output']>;
};

export type BidRequestsubmittedBidArgs = {
  bidId: Scalars['ID']['input'];
};

export enum BidRequestAuthActions {
  award = 'award',
  awardApprove = 'awardApprove',
  createNote = 'createNote',
  delete = 'delete',
  deleteBid = 'deleteBid',
  deleteNote = 'deleteNote',
  manageTasks = 'manageTasks',
  negotiate = 'negotiate',
  rate = 'rate',
  reject = 'reject',
  sendAwardingEmail = 'sendAwardingEmail',
  sendInvite = 'sendInvite',
  submit = 'submit',
  submitBid = 'submitBid',
  unAward = 'unAward',
  unReject = 'unReject',
  update = 'update',
  updateAgentStatus = 'updateAgentStatus',
  updateStatus = 'updateStatus',
  update__customBidsDueAt = 'update__customBidsDueAt',
  uploadBidBoQ = 'uploadBidBoQ',
  view = 'view',
}

export type BidRequestAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type BidRequestAuthInput = {
  action: BidRequestAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BidRequestAuthArgsInput>;
};

export type BidRequestByAgentInput = {
  bidPackageName: Scalars['String']['input'];
  bidsDueAt: Scalars['DateTimeISO']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  isEstimating: Scalars['Boolean']['input'];
  location: Scalars['String']['input'];
  officeCity?: InputMaybe<Scalars['String']['input']>;
  officeCountryCode?: InputMaybe<COUNTRY_CODE>;
  officeEmail: Scalars['String']['input'];
  officeFax?: InputMaybe<Scalars['String']['input']>;
  officeName: Scalars['String']['input'];
  officePhone?: InputMaybe<Scalars['String']['input']>;
  officePostalCode?: InputMaybe<Scalars['String']['input']>;
  officeStreet?: InputMaybe<Scalars['String']['input']>;
  projectName: Scalars['String']['input'];
  startsOn?: InputMaybe<Scalars['Date']['input']>;
};

export type BidRequestContact = AbstractEntity & {
  __typename: 'BidRequestContact';
  bidInvite: Maybe<BidInvite>;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPerson: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  notificationConfig: ContactNotificationConfig;
  phone: Maybe<Scalars['String']['output']>;
  subcontractor: Subcontractor;
  subcontractorId: Scalars['ID']['output'];
  suggestedChanges: Array<ContactSuggestion>;
  title: Maybe<CONTACT_TITLE>;
};

export type BidRequestCustomBidsDueAtChangeResponse = {
  __typename: 'BidRequestCustomBidsDueAtChangeResponse';
  customBidsDueAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  occurredAt: Scalars['DateTimeISO']['output'];
  user: Maybe<User>;
};

export type BidRequestDeclinedNotification = AbstractEntity &
  Notification & {
    __typename: 'BidRequestDeclinedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: BidRequestDeclinedNotificationProps;
  };

export type BidRequestDeclinedNotificationProps = {
  __typename: 'BidRequestDeclinedNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  bidRequestId: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type BidRequestNote = AbstractEntity &
  Note & {
    __typename: 'BidRequestNote';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    text: Scalars['String']['output'];
    user: User;
  };

export type BidRequestStatusChangeResponse = {
  __typename: 'BidRequestStatusChangeResponse';
  bidRequest: BidRequest;
  bidRequestStatus: BID_REQUEST_STATUS;
  changedBy: BID_REQUEST_STATUS_CHANGED_BY;
  id: Scalars['ID']['output'];
  occurredAt: Scalars['DateTimeISO']['output'];
  user: Maybe<User>;
};

export type BidRequestStatusCounts = {
  __typename: 'BidRequestStatusCounts';
  accepted: Scalars['Int']['output'];
  bidPackageId: Scalars['ID']['output'];
  bidders: Scalars['Int']['output'];
  bids: Scalars['Int']['output'];
  inviteDeclined: Scalars['Int']['output'];
  viewed: Scalars['Int']['output'];
};

export type BidRequestsForAgentCountsResponse = {
  __typename: 'BidRequestsForAgentCountsResponse';
  activeRecords: Scalars['Int']['output'];
  allRecords: Scalars['Int']['output'];
};

export type BidRequestsResponse = {
  __typename: 'BidRequestsResponse';
  bidRequests: Array<BidRequest>;
  totalRecords: Scalars['Int']['output'];
};

export type BidWarningMetrics = {
  __typename: 'BidWarningMetrics';
  elementsWithNotFilledBidderInputs: Array<BoQElementWithNotFilledBidderInput>;
  freeQuantityPositionsWithoutQuantitiesIds: Array<Scalars['String']['output']>;
  hasProposalInDocuments: Scalars['Boolean']['output'];
  notFilledPositionsIds: Array<Scalars['String']['output']>;
};

export type BidderAlreadyAddedError = {
  __typename: 'BidderAlreadyAddedError';
  contactId: Maybe<Scalars['ID']['output']>;
};

export type BidderStatus = {
  __typename: 'BidderStatus';
  status: BIDDER_STATUS;
  subcontractorId: Scalars['ID']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type BiddersAwaitingResponseNotification = AbstractEntity &
  Notification & {
    __typename: 'BiddersAwaitingResponseNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: BiddersAwaitingResponseNotificationProps;
  };

export type BiddersAwaitingResponseNotificationProps =
  | BiddersAwaitingResponseNotificationV1Props
  | BiddersAwaitingResponseNotificationV2Props;

export type BiddersAwaitingResponseNotificationV1Props =
  BaseBiddersAwaitingResponseNotificationProps & {
    __typename: 'BiddersAwaitingResponseNotificationV1Props';
    bidPackageBidsDueWeeksAgo: Scalars['Int']['output'];
    bidPackageId: Scalars['String']['output'];
    bidPackageName: Scalars['String']['output'];
    projectId: Scalars['String']['output'];
    projectName: Scalars['String']['output'];
    subcontractorNames: Array<Scalars['String']['output']>;
  };

export type BiddersAwaitingResponseNotificationV2Props =
  BaseBiddersAwaitingResponseNotificationProps & {
    __typename: 'BiddersAwaitingResponseNotificationV2Props';
    bidPackageId: Scalars['String']['output'];
    bidPackageName: Scalars['String']['output'];
    durationSinceBidsDue: Duration;
    projectId: Scalars['String']['output'];
    projectName: Scalars['String']['output'];
    subcontractorNames: Array<Scalars['String']['output']>;
  };

export type BiddersLimitExceededError = {
  __typename: 'BiddersLimitExceededError';
  limit: Scalars['Int']['output'];
};

export type BiddersSearchInput = {
  companySizes: Array<CompanySizeInput>;
  distance: Scalars['String']['input'];
  moreInfo: Scalars['String']['input'];
  workCategories: Array<WorkCategoryInput>;
};

export type BiddingPeriodEndingNotification = AbstractEntity &
  Notification & {
    __typename: 'BiddingPeriodEndingNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: BiddingPeriodEndingNotificationProps;
  };

export type BiddingPeriodEndingNotificationProps = {
  __typename: 'BiddingPeriodEndingNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  bidsCount: Scalars['Float']['output'];
  endingInDays: Scalars['Float']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type BidsResponse = {
  __typename: 'BidsResponse';
  bids: Array<SubmittedBid>;
  totalRecords: Scalars['Int']['output'];
};

export type BillOfQuantities = AbstractEntity & {
  __typename: 'BillOfQuantities';
  comments: Array<BillOfQuantitiesComment>;
  content: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  currency: CURRENCY;
  editingStatus: Maybe<BOQ_EDITING_STATUS>;
  id: Scalars['ID']['output'];
  internalNotes: Array<BillOfQuantitiesInternalNote>;
};

export enum BillOfQuantitiesAuthActions {
  downloadRequestForProposalPdf = 'downloadRequestForProposalPdf',
  update = 'update',
}

export type BillOfQuantitiesAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type BillOfQuantitiesAuthInput = {
  action: BillOfQuantitiesAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BillOfQuantitiesAuthArgsInput>;
};

export type BillOfQuantitiesComment = AbstractEntity & {
  __typename: 'BillOfQuantitiesComment';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type BillOfQuantitiesForEditingResponse = {
  __typename: 'BillOfQuantitiesForEditingResponse';
  content: Scalars['JSONObject']['output'];
  hasFormalReviewErrors: Scalars['Boolean']['output'];
  id: Maybe<Scalars['ID']['output']>;
  largestTextAdditionLabel: Scalars['Int']['output'];
  massDeterminationRowIdsNotToKeep: Array<Scalars['String']['output']>;
  massDeterminations: Array<MassDetermination>;
  preferredFileFormat: BOQ_FILE_FORMAT;
};

export type BillOfQuantitiesInternalNote = AbstractEntity & {
  __typename: 'BillOfQuantitiesInternalNote';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type BillOfQuantitiesRevision = AbstractEntity & {
  __typename: 'BillOfQuantitiesRevision';
  createdAt: Scalars['DateTimeISO']['output'];
  hasBids: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isShareableWithBidders: Scalars['Boolean']['output'];
  requestForProposalBoQ: Maybe<BillOfQuantities>;
  requestForProposalBoQId: Maybe<Scalars['ID']['output']>;
  revision: Scalars['Int']['output'];
  wereBiddersNotified: Maybe<Scalars['Boolean']['output']>;
};

export type BlindTenderOpeningResponse = {
  __typename: 'BlindTenderOpeningResponse';
  id: Scalars['ID']['output'];
  occurredAt: Scalars['DateTimeISO']['output'];
  users: Array<User>;
};

export type BoQEditOperationDescriptor =
  | AddElementOperationDescriptor
  | ClearFieldOperationDescriptor
  | DeleteElementOperationDescriptor
  | FillOutFieldOperationDescriptor
  | MoveElementOperationDescriptor
  | RenumberOperationDescriptor
  | TogglePositionTypeOperationDescriptor
  | UpdateProjectInformationOperationDescriptor;

export type BoQEditorPreSaveStateResponse = {
  __typename: 'BoQEditorPreSaveStateResponse';
  hasFormalReviewErrors: Scalars['Boolean']['output'];
  hasNotifiableChanges: Scalars['Boolean']['output'];
};

export type BoQElementWithNotFilledBidderInput = {
  __typename: 'BoQElementWithNotFilledBidderInput';
  bidderInputLabel: Maybe<Scalars['String']['output']>;
  elementId: Scalars['String']['output'];
};

export type BoQExportInput = {
  forceExchangePhase?: InputMaybe<BOQ_EXCHANGE_PHASE>;
};

export type BoQFormalReviewError = {
  __typename: 'BoQFormalReviewError';
  elementId: Maybe<Scalars['String']['output']>;
  level: BOQ_FORMAL_REVIEW_ISSUE_LEVEL;
  location: Maybe<Scalars['String']['output']>;
  reason: BOQ_FORMAL_REVIEW_ERROR_REASON;
  rowNumber: Maybe<Scalars['Int']['output']>;
};

export type BoQFormalReviewIssue = {
  __typename: 'BoQFormalReviewIssue';
  elementId: Maybe<Scalars['String']['output']>;
  level: BOQ_FORMAL_REVIEW_ISSUE_LEVEL;
  location: Maybe<Scalars['String']['output']>;
  reason: BOQ_FORMAL_REVIEW_ERROR_REASON;
  rowNumber: Maybe<Scalars['Int']['output']>;
};

export type BoQFormalReviewResponse = {
  __typename: 'BoQFormalReviewResponse';
  /** @deprecated field renamed to issues */
  errors: Array<BoQFormalReviewError>;
  formalReviewResult: BOQ_FORMAL_REVIEW_RESULT;
  issues: Array<BoQFormalReviewIssue>;
};

export type BoQIncluded = {
  __typename: 'BoQIncluded';
  issue: Maybe<BP_PUBLICATION_BOQ_INCLUDED_ISSUE>;
};

export type BoQPdfSettings = {
  includeExecutionDescriptions: Scalars['Boolean']['input'];
  includeLongDescriptions: Scalars['Boolean']['input'];
  includeNotes: Scalars['Boolean']['input'];
  orientation: ORIENTATION;
};

export type BoQStructureInput = {
  tierDataType: BOQ_TIER_DATA_TYPE;
  tierDigits: Scalars['Float']['input'];
  tierName: Scalars['String']['input'];
  tierType: BOQ_TIER_TYPE;
};

export type BoQTemplate = AbstractEntity & {
  __typename: 'BoQTemplate';
  boqFileFormat: BOQ_FILE_FORMAT;
  changedByUser: User;
  costGroupCatalogs: Array<BoQTemplateCostGroupCatalog>;
  createdAt: Scalars['DateTimeISO']['output'];
  currency: CURRENCY;
  hasUnsavedBillOfQuantitiesChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  taxRate: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type BoQTemplateBoQEditorPreSaveStateResponse = {
  __typename: 'BoQTemplateBoQEditorPreSaveStateResponse';
  hasFormalReviewErrors: Scalars['Boolean']['output'];
};

export type BoQTemplateCostGroupCatalog = {
  __typename: 'BoQTemplateCostGroupCatalog';
  catalogType: BOQ_TEMPLATE_COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalog: Maybe<CustomCostGroupCatalog>;
  customCatalogId: Maybe<Scalars['String']['output']>;
  customCatalogName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type BoQTemplateCostGroupCatalogInput = {
  catalogType: BOQ_TEMPLATE_COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
};

export type BoQTemplateInput = {
  billOfQuantitiesId?: InputMaybe<Scalars['ID']['input']>;
  boqFileFormat: BOQ_FILE_FORMAT;
  costGroupCatalogs: Array<BoQTemplateCostGroupCatalogInput>;
  currency: CURRENCY;
  name: Scalars['String']['input'];
};

export type BoQTemplatesResponse = {
  __typename: 'BoQTemplatesResponse';
  boqTemplates: Array<BoQTemplate>;
  totalRecords: Scalars['Int']['output'];
  totalRecordsForCompany: Scalars['Int']['output'];
};

export type BooleanDiff = {
  __typename: 'BooleanDiff';
  new: Maybe<Scalars['Boolean']['output']>;
  old: Maybe<Scalars['Boolean']['output']>;
};

export type BoqValidationError = {
  __typename: 'BoqValidationError';
  location: Maybe<Scalars['String']['output']>;
  reason: BOQ_VALIDATION_ERROR_REASON;
  rowNumber: Maybe<Scalars['Int']['output']>;
};

export type BoqValidationWarning = {
  __typename: 'BoqValidationWarning';
  location: Maybe<Scalars['String']['output']>;
  reason: BOQ_VALIDATION_WARNING_REASON;
  rowNumber: Maybe<Scalars['Int']['output']>;
};

export type BulkAddContactsResponse = {
  __typename: 'BulkAddContactsResponse';
  count: Scalars['Int']['output'];
};

export enum CERTIFICATE_AVAILABILITY {
  available = 'available',
  expired = 'expired',
  expiringSoon = 'expiringSoon',
  notAvailable = 'notAvailable',
}

export enum CERTIFICATE_FILE_TYPE {
  jpeg = 'jpeg',
  pdf = 'pdf',
  png = 'png',
  xls = 'xls',
  xlsx = 'xlsx',
}

export enum CERTIFICATE_REQUESTS_SORT_FIELD {
  company = 'company',
  dueDate = 'dueDate',
  requestedOn = 'requestedOn',
  status = 'status',
  statusForAgent = 'statusForAgent',
}

export enum CERTIFICATE_REQUEST_STATUS {
  pending = 'pending',
  rejected = 'rejected',
  submitted = 'submitted',
  withdrawn = 'withdrawn',
}

export enum CERTIFICATE_REQUEST_STATUS_FOR_AGENT {
  done = 'done',
  incomplete = 'incomplete',
  overdue = 'overdue',
  rejected = 'rejected',
}

export enum CERTIFICATE_STATUS {
  draft = 'draft',
  submitted = 'submitted',
}

export enum CERTIFICATE_TYPE_SCOPE {
  bidPackage = 'bidPackage',
  universal = 'universal',
}

export enum CERTIFICATE_TYPE_STATUS {
  available = 'available',
  expired = 'expired',
  expiringSoon = 'expiringSoon',
  notAvailable = 'notAvailable',
  rejected = 'rejected',
  requested = 'requested',
}

export enum CERTIFICATE_TYPE_TYPE {
  company = 'company',
  global = 'global',
}

export enum COMPANIES_SORT_FIELD {
  createdAt = 'createdAt',
  name = 'name',
}

export enum COMPANY_SIZE {
  size_1_4 = 'size_1_4',
  size_5_9 = 'size_5_9',
  size_10_19 = 'size_10_19',
  size_20_49 = 'size_20_49',
  size_50_99 = 'size_50_99',
  size_100_199 = 'size_100_199',
  size_200_499 = 'size_200_499',
  size_500plus = 'size_500plus',
}

export enum COMPANY_TEMPLATE_SORT_FIELD {
  name = 'name',
}

export enum COMPANY_TEMPLATE_TYPE {
  awarding = 'awarding',
  bidInviteIntroAwardProject = 'bidInviteIntroAwardProject',
  bidInviteIntroEstimateProject = 'bidInviteIntroEstimateProject',
  bidPackageClosed = 'bidPackageClosed',
  bidderIntroduction = 'bidderIntroduction',
  message = 'message',
  rejection = 'rejection',
}

export enum COMPANY_TYPE {
  agent = 'agent',
  principal = 'principal',
}

export enum CONTACT_SUGGESTION_CHANGE_TYPE {
  create = 'create',
  update = 'update',
}

export enum CONTACT_SUGGESTION_FIELDS_CHANGE_TYPE {
  append = 'append',
  remove = 'remove',
  set = 'set',
  unset = 'unset',
}

export enum CONTACT_SUGGESTION_FIELDS_FIELD_NAME {
  email = 'email',
  fax = 'fax',
  firstName = 'firstName',
  isPerson = 'isPerson',
  jobTitle = 'jobTitle',
  lastName = 'lastName',
  mobilePhone = 'mobilePhone',
  phone = 'phone',
  title = 'title',
}

export enum CONTACT_SUGGESTION_FIELDS_REVIEW_RESULT {
  approved = 'approved',
  dismissed = 'dismissed',
}

export enum CONTACT_TITLE {
  mr = 'mr',
  ms = 'ms',
}

export enum CONTRACT_REMINDER_SCHEDULE {
  everyTwoWeeks = 'everyTwoWeeks',
  none = 'none',
  weekly = 'weekly',
}

export enum COST_ANALYSIS_BREAKDOWN {
  bidPackage = 'bidPackage',
  custom = 'custom',
  din276 = 'din276',
  oenorm = 'oenorm',
  stlbWorkCategory = 'stlbWorkCategory',
}

export enum COST_ANALYSIS_TOP_LEVEL_GROUPING {
  bidPackage = 'bidPackage',
  project = 'project',
}

export enum COST_CATALOG_TYPE {
  bidPackage = 'bidPackage',
  custom = 'custom',
  din276 = 'din276',
  oenorm = 'oenorm',
  stlbWorkCategory = 'stlbWorkCategory',
}

export enum COST_GROUP_TYPE {
  din276 = 'din276',
  oenorm = 'oenorm',
}

export enum COUNTRY_CODE {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum CURRENCY {
  ALL = 'ALL',
  AMD = 'AMD',
  AZN = 'AZN',
  BAM = 'BAM',
  BGN = 'BGN',
  BYN = 'BYN',
  CHF = 'CHF',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  GEL = 'GEL',
  HRK = 'HRK',
  HUF = 'HUF',
  ISK = 'ISK',
  MDL = 'MDL',
  MKD = 'MKD',
  NOK = 'NOK',
  PLN = 'PLN',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  SEK = 'SEK',
  TRY = 'TRY',
  UAH = 'UAH',
}

export enum CUSTOM_COST_GROUP_CATALOGS_SORT_FIELD {
  name = 'name',
}

export enum CUSTOM_COST_GROUP_CATALOG_STATUS {
  active = 'active',
  deactivated = 'deactivated',
}

export enum CUSTOM_EMAIL_VALIDATION_RESULT {
  failed = 'failed',
  pending = 'pending',
  validated = 'validated',
}

export enum CUSTOM_FIELD_ENTITY_TYPE {
  project = 'project',
  subcontractor = 'subcontractor',
}

export enum CUSTOM_FIELD_TYPE {
  checkbox = 'checkbox',
  currency = 'currency',
  date = 'date',
  dateTime = 'dateTime',
  multiSelect = 'multiSelect',
  number = 'number',
  singleSelect = 'singleSelect',
  text = 'text',
}

export type CategoryRating = {
  __typename: 'CategoryRating';
  category: RATING_CATEGORY;
  rating: Scalars['Float']['output'];
};

export type Certificate = AbstractEntity & {
  __typename: 'Certificate';
  availability: CERTIFICATE_AVAILABILITY;
  bidPackage: Maybe<BidPackage>;
  certificateType: CertificateType;
  createdAt: Scalars['DateTimeISO']['output'];
  expiryDate: Maybe<Scalars['String']['output']>;
  /** Returns a string "certificateOwner_certificateTypeName_certificateCreationDate.fileType". If a certificate has not been created yet, please use "certificateFileName" query. */
  fileName: Scalars['String']['output'];
  fileSizeBytes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  issueDate: Scalars['String']['output'];
  status: CERTIFICATE_STATUS;
};

export enum CertificateAuthActions {
  delete = 'delete',
  publish = 'publish',
  update = 'update',
  view = 'view',
}

export type CertificateAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CertificateAuthInput = {
  action: CertificateAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificateAuthArgsInput>;
};

export type CertificateExpiringNotification = AbstractEntity &
  Notification & {
    __typename: 'CertificateExpiringNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: CertificateExpiringNotificationProps;
  };

export type CertificateExpiringNotificationProps = {
  __typename: 'CertificateExpiringNotificationProps';
  certificateTypeId: Maybe<Scalars['String']['output']>;
  certificateTypeName: Scalars['String']['output'];
  expiresInDays: Scalars['Float']['output'];
  subcontractorId: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type CertificateExpiringSubcontractorNotification = AbstractEntity &
  Notification & {
    __typename: 'CertificateExpiringSubcontractorNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: CertificateExpiringSubcontractorNotificationProps;
  };

export type CertificateExpiringSubcontractorNotificationProps = {
  __typename: 'CertificateExpiringSubcontractorNotificationProps';
  certificateTypeName: Scalars['String']['output'];
  expiresInDays: Scalars['Float']['output'];
};

export type CertificateNotProvidedInTimeNotification = AbstractEntity &
  Notification & {
    __typename: 'CertificateNotProvidedInTimeNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: CertificateNotProvidedInTimeNotificationProps;
  };

export type CertificateNotProvidedInTimeNotificationProps = {
  __typename: 'CertificateNotProvidedInTimeNotificationProps';
  certificateTypeNames: Array<Scalars['String']['output']>;
  subcontractorId: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type CertificateRequest = AbstractEntity & {
  __typename: 'CertificateRequest';
  bidPackage: Maybe<BidPackage>;
  certificateRequestItems: Array<CertificateRequestItem>;
  contact: Contact;
  contactId: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  dueDate: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  office: Office;
  officeId: Scalars['String']['output'];
  status: CERTIFICATE_REQUEST_STATUS;
  statusForAgent: CERTIFICATE_REQUEST_STATUS_FOR_AGENT;
};

export type CertificateRequestcertificateRequestItemsArgs = {
  excludeWithdrawn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CertificateRequestItem = AbstractEntity & {
  __typename: 'CertificateRequestItem';
  canSaveInProfile: Scalars['Boolean']['output'];
  certificateRequest: CertificateRequest;
  certificateType: CertificateType;
  /** @deprecated Deprecated due to simplification with CertificateTypeReportResolver.pendingCertificateRequestItemsForBidPackages */
  certificates: Maybe<Array<Certificate>>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  rejectionReason: Maybe<Scalars['String']['output']>;
  withdrawn: Scalars['Boolean']['output'];
};

export type CertificateRequestItemcertificatesArgs = {
  showDrafts: Scalars['Boolean']['input'];
};

export type CertificateRequestsResponse = {
  __typename: 'CertificateRequestsResponse';
  certificateRequests: Array<CertificateRequest>;
  totalRecords: Scalars['Int']['output'];
};

export type CertificateType = AbstractEntity & {
  __typename: 'CertificateType';
  baseName: Scalars['String']['output'];
  certificateRequestCertificates: Array<Certificate>;
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.certificateRequestItems */
  certificateRequestItems: Array<CertificateRequestItem>;
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.certificates */
  certificates: Array<Certificate>;
  countryCode: Maybe<COUNTRY_CODE>;
  createdAt: Scalars['DateTimeISO']['output'];
  /** @deprecated Deprecated in favor of isInUse, so certificate types with uploaded certificates are considered. */
  hasBeenRequested: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isExpiryDateMandatory: Scalars['Boolean']['output'];
  isInUse: Scalars['Boolean']['output'];
  isSelectedByDefaultWhenRequesting: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.requestedOn */
  lastRequestDate: Maybe<Scalars['DateTimeISO']['output']>;
  name: Scalars['String']['output'];
  scope: CERTIFICATE_TYPE_SCOPE;
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.certificates */
  subcontractorCertificates: Array<Certificate>;
  templateDocument: Maybe<Document>;
  translation: CertificateTypeTranslation;
  type: CERTIFICATE_TYPE_TYPE;
};

export type CertificateTypecertificateRequestCertificatesArgs = {
  certificateRequestId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypecertificateRequestItemsArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypelastRequestDateArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypesubcontractorCertificatesArgs = {
  showDrafts: Scalars['Boolean']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypetranslationArgs = {
  language: LANGUAGE;
};

export enum CertificateTypeAuthActions {
  cancelCertificateRequest = 'cancelCertificateRequest',
  createCertificate = 'createCertificate',
  createGlobalCertificateType = 'createGlobalCertificateType',
  delete = 'delete',
  select = 'select',
  sendCertificateRequest = 'sendCertificateRequest',
  update = 'update',
  view = 'view',
}

export type CertificateTypeAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CertificateTypeAuthInput = {
  action: CertificateTypeAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificateTypeAuthArgsInput>;
};

export type CertificateTypeReport = AbstractEntity & {
  __typename: 'CertificateTypeReport';
  baseName: Scalars['String']['output'];
  certificateRequestCertificates: Array<Certificate>;
  certificateRequestItems: Array<CertificateRequestItem>;
  certificates: Array<Certificate>;
  countryCode: Maybe<COUNTRY_CODE>;
  createdAt: Scalars['DateTimeISO']['output'];
  /** @deprecated Deprecated in favor of isInUse, so certificate types with uploaded certificates are considered. */
  hasBeenRequested: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isExpiryDateMandatory: Scalars['Boolean']['output'];
  isInUse: Scalars['Boolean']['output'];
  isSelectedByDefaultWhenRequesting: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.requestedOn */
  lastRequestDate: Maybe<Scalars['DateTimeISO']['output']>;
  maxExpiryDate: Maybe<Scalars['DateTimeISO']['output']>;
  name: Scalars['String']['output'];
  pendingCertificateRequestItemsForBidPackages: Array<CertificateRequestItem>;
  /** @deprecated There can be only one certificate request item per certificate request/type combination, but this field returns a generic `CertificateRequest` which contains all items for all types in the request. This makes it impossible to get information for this certificate type report that exists on the certificate request item level. Use `pendingCertificateRequestItemsForBidPackages` instead. */
  pendingCertificateRequestsForBidPackages: Array<CertificateRequest>;
  rejectionReason: Maybe<Scalars['String']['output']>;
  requestedDueDate: Maybe<Scalars['DateTimeISO']['output']>;
  requestedOn: Maybe<Scalars['DateTimeISO']['output']>;
  scope: CERTIFICATE_TYPE_SCOPE;
  status: CERTIFICATE_TYPE_STATUS;
  /** @deprecated Simplified by merging this logic into the regular "status" when requesting reports with "bidPackageId" */
  statusForBidPackage: Maybe<CERTIFICATE_TYPE_STATUS>;
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.certificates */
  subcontractorCertificates: Array<Certificate>;
  subcontractorTypeId: Maybe<Scalars['String']['output']>;
  templateDocument: Maybe<Document>;
  translation: CertificateTypeTranslation;
  type: CERTIFICATE_TYPE_TYPE;
};

export type CertificateTypeReportcertificateRequestCertificatesArgs = {
  certificateRequestId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypeReportlastRequestDateArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypeReportstatusForBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type CertificateTypeReportsubcontractorCertificatesArgs = {
  showDrafts: Scalars['Boolean']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type CertificateTypeReporttranslationArgs = {
  language: LANGUAGE;
};

export type CertificateTypeTranslation = AbstractEntity & {
  __typename: 'CertificateTypeTranslation';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum CertificateTypeTranslationAuthActions {
  update = 'update',
}

export type CertificateTypeTranslationAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CertificateTypeTranslationAuthInput = {
  action: CertificateTypeTranslationAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificateTypeTranslationAuthArgsInput>;
};

export type CertificateUploadedBySubcontractorNotification = AbstractEntity &
  Notification & {
    __typename: 'CertificateUploadedBySubcontractorNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: CertificateUploadedBySubcontractorNotificationProps;
  };

export type CertificateUploadedBySubcontractorNotificationProps = {
  __typename: 'CertificateUploadedBySubcontractorNotificationProps';
  certificateTypeNames: Array<Scalars['String']['output']>;
  subcontractorId: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type CheckCredentialsResponse = {
  __typename: 'CheckCredentialsResponse';
  companyName: Scalars['String']['output'];
  loginToken: Scalars['String']['output'];
  mfaMethod: Maybe<MFA_METHOD>;
  mfaPhoneCensored: Maybe<Scalars['String']['output']>;
  mfaRequired: Scalars['Boolean']['output'];
};

export type CheckResetPasswordTokenResponse = {
  __typename: 'CheckResetPasswordTokenResponse';
  passwordPolicy: Maybe<CompanyPasswordPolicy>;
};

export type CheckVerificationCodeResponse = {
  __typename: 'CheckVerificationCodeResponse';
  mfaToken: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ClearFieldOperationDescriptor = {
  __typename: 'ClearFieldOperationDescriptor';
  fieldName: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  priceComponentIndex: Maybe<Scalars['Int']['output']>;
};

export type ColumnDefinition = {
  columnLetter: Scalars['String']['input'];
  customCatalogId?: InputMaybe<Scalars['String']['input']>;
  field: IMPORTABLE_BOQ_FIELD;
  unitPriceComponentIndex?: InputMaybe<Scalars['Float']['input']>;
};

export type CombinedFileTreeResponse = {
  __typename: 'CombinedFileTreeResponse';
  documents: Array<Document>;
  fileTrees: Array<FileTree>;
};

export type CompaniesResponse = {
  __typename: 'CompaniesResponse';
  companies: Array<Company>;
  totalRecords: Scalars['Int']['output'];
};

export type Company = AbstractEntity & {
  __typename: 'Company';
  arePermanentFeatureFlagsEditable: Scalars['Boolean']['output'];
  bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
  bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
  bidRequestsCount: Scalars['Int']['output'];
  bidRequestsWithSubmittedBidsCount: Scalars['Int']['output'];
  bidsSubmittedOnMarketplace: Scalars['Int']['output'];
  claimedSubcontractors: Array<Subcontractor>;
  config: CompanyConfig;
  /** The date when the Salesforce contract expires for this company. `null` if there is no end date. See `SalesforceAccount` in Prisma schema for more info. */
  contractEndDate: Maybe<Scalars['DateTimeISO']['output']>;
  contractReminderSchedule: CONTRACT_REMINDER_SCHEDULE;
  contrastColor: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByAgentCompanyId: Maybe<Scalars['ID']['output']>;
  customUserRoles: Array<CustomUserRole>;
  hasBillingAccount: Scalars['Boolean']['output'];
  hasNetworkSubcontractorsAssociated: Scalars['Boolean']['output'];
  hasOverdueInvoice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAgentProfileComplete: Scalars['Boolean']['output'];
  isEnterpriseOpportunity: Maybe<Scalars['Boolean']['output']>;
  isTestCompany: Scalars['Boolean']['output'];
  logoKey: Maybe<Scalars['String']['output']>;
  mfaRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  packageType: Maybe<SALESFORCE_PACKAGE_TYPE>;
  permanentFeatureFlags: Scalars['JSONObject']['output'];
  primaryColor: Maybe<Scalars['String']['output']>;
  ratingCategories: Array<RATING_CATEGORY>;
  salesforceServiceId: Maybe<Scalars['String']['output']>;
  submittedCertificatesCount: Scalars['Int']['output'];
  tags: Scalars['JSONObject']['output'];
  trials: Array<Trial>;
  type: COMPANY_TYPE;
  usedSeats: CompanyUsedSeatsResponse;
  users: Array<User>;
};

export enum CompanyAuthActions {
  create = 'create',
  createAgentBidRequest = 'createAgentBidRequest',
  createAgentCertificateType = 'createAgentCertificateType',
  createCompanyCertificateType = 'createCompanyCertificateType',
  createCompanySubcontractor = 'createCompanySubcontractor',
  createCompanyTemplate = 'createCompanyTemplate',
  createCustomCostGroupCatalog = 'createCustomCostGroupCatalog',
  createNetworkSubcontractor = 'createNetworkSubcontractor',
  createOffice = 'createOffice',
  createOrUpdateBoQTemplate = 'createOrUpdateBoQTemplate',
  createSubcontractorImportRequests = 'createSubcontractorImportRequests',
  createTask = 'createTask',
  createTerms = 'createTerms',
  deleteBoQTemplate = 'deleteBoQTemplate',
  deleteSubcontractorImportRequests = 'deleteSubcontractorImportRequests',
  exportSubcontractors = 'exportSubcontractors',
  generateUploadUrlForSubcontractorsImport = 'generateUploadUrlForSubcontractorsImport',
  inviteUsers = 'inviteUsers',
  managePermissions = 'managePermissions',
  merge = 'merge',
  requestProfileCompletion = 'requestProfileCompletion',
  sendInviteRequests = 'sendInviteRequests',
  sendPremiumPurchaseRequest = 'sendPremiumPurchaseRequest',
  startTrial__costEstimationAndControlling = 'startTrial__costEstimationAndControlling',
  startTrial__individualisation = 'startTrial__individualisation',
  update = 'update',
  updateSubcontractorImportRequests = 'updateSubcontractorImportRequests',
  update__featureFlags = 'update__featureFlags',
  update__internalDocuments = 'update__internalDocuments',
  view = 'view',
  view__allOfficeProjects = 'view__allOfficeProjects',
  view__allUsersBidPackages = 'view__allUsersBidPackages',
  view__certificates = 'view__certificates',
  view__claimedSubcontractors = 'view__claimedSubcontractors',
  view__contacts = 'view__contacts',
  view__externalApiAccessKey = 'view__externalApiAccessKey',
  view__internalDocuments = 'view__internalDocuments',
  view__prefill = 'view__prefill',
  view__subcontractorImportRequests = 'view__subcontractorImportRequests',
  view__subcontractors = 'view__subcontractors',
}

export type CompanyAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CompanyAuthInput = {
  action: CompanyAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CompanyAuthArgsInput>;
};

export type CompanyBrandingInput = {
  contrastColor?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyConfig = AbstractEntity & {
  __typename: 'CompanyConfig';
  additionalLanguages: Array<LANGUAGE>;
  createdAt: Scalars['DateTimeISO']['output'];
  fullUserLicenseCount: Maybe<Scalars['Int']['output']>;
  guestLicenseCount: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  increasedSecurityFeaturesEnabled: Scalars['Boolean']['output'];
  passwordPolicy: Maybe<CompanyPasswordPolicy>;
  premiumEnabled: Scalars['Boolean']['output'];
  premiumEnabledReason: Maybe<PREMIUM_ENABLED_REASON>;
};

export type CompanyOnboardingResponse =
  | CompleteCompanyProfile
  | ImportSubcontractors
  | InviteColleagues;

export type CompanyPasswordPolicy = {
  __typename: 'CompanyPasswordPolicy';
  minLength: Scalars['Int']['output'];
  requiresLowerCaseCharacter: Scalars['Boolean']['output'];
  requiresNumber: Scalars['Boolean']['output'];
  requiresSpecialCharacter: Scalars['Boolean']['output'];
  requiresUpperCaseCharacter: Scalars['Boolean']['output'];
  resetIntervalInDays: Maybe<Scalars['Int']['output']>;
};

export type CompanySizeDiff = {
  __typename: 'CompanySizeDiff';
  new: Maybe<COMPANY_SIZE>;
  old: Maybe<COMPANY_SIZE>;
};

export type CompanySizeInput = {
  name: Scalars['String']['input'];
  size: COMPANY_SIZE;
};

export enum CompanySubcontractorsImportAuthActions {
  process = 'process',
  view = 'view',
}

export type CompanySubcontractorsImportAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CompanySubcontractorsImportAuthInput = {
  action: CompanySubcontractorsImportAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CompanySubcontractorsImportAuthArgsInput>;
};

export type CompanyTemplate = AbstractEntity & {
  __typename: 'CompanyTemplate';
  content: Scalars['HTML']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: COMPANY_TEMPLATE_TYPE;
};

export enum CompanyTemplateAuthActions {
  delete = 'delete',
  update = 'update',
  view = 'view',
}

export type CompanyTemplateAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CompanyTemplateAuthInput = {
  action: CompanyTemplateAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CompanyTemplateAuthArgsInput>;
};

export type CompanyTemplateCreateInput = {
  content: Scalars['HTML']['input'];
  name: Scalars['String']['input'];
  type: COMPANY_TEMPLATE_TYPE;
};

export type CompanyTemplateUpdateInput = {
  content?: InputMaybe<Scalars['HTML']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyTemplatesResponse = {
  __typename: 'CompanyTemplatesResponse';
  companyTemplates: Array<CompanyTemplate>;
  totalRecords: Scalars['Int']['output'];
};

export type CompanyUsedSeatsResponse = {
  __typename: 'CompanyUsedSeatsResponse';
  fullUsers: Scalars['Int']['output'];
  guests: Scalars['Int']['output'];
};

export type CompleteCompanyProfile = {
  __typename: 'CompleteCompanyProfile';
  incompleteOfficeIds: Array<Scalars['String']['output']>;
  isFulfilled: Scalars['Boolean']['output'];
};

export type ConfirmBillOfQuantitiesHasBeenUploaded =
  | ConfirmBillOfQuantitiesHasBeenUploadedError
  | ConfirmBillOfQuantitiesHasBeenUploadedSuccess;

export type ConfirmBillOfQuantitiesHasBeenUploadedError = {
  __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
  errors: Array<BoqValidationError>;
};

export type ConfirmBillOfQuantitiesHasBeenUploadedSuccess = {
  __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
  billOfQuantitiesId: Scalars['ID']['output'];
  netTotal: Maybe<Scalars['Float']['output']>;
  pricePredictions: Maybe<Array<PositionPricePredictionEvaluation>>;
  warnings: Array<BoqValidationWarning>;
};

export type ConfirmRequestBoQHasBeenUploaded =
  | ConfirmBillOfQuantitiesHasBeenUploadedError
  | ConfirmBillOfQuantitiesHasBeenUploadedSuccess
  | RequestEditingSessionFailure;

export type Contact = AbstractEntity & {
  __typename: 'Contact';
  createdAt: Scalars['DateTimeISO']['output'];
  email: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPerson: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  notificationConfig: ContactNotificationConfig;
  phone: Maybe<Scalars['String']['output']>;
  subcontractor: Subcontractor;
  subcontractorId: Scalars['ID']['output'];
  suggestedChanges: Array<ContactSuggestion>;
  title: Maybe<CONTACT_TITLE>;
};

export enum ContactAuthActions {
  suggestChanges = 'suggestChanges',
  update = 'update',
  update__notificationConfig = 'update__notificationConfig',
  view = 'view',
  view__notificationConfig = 'view__notificationConfig',
}

export type ContactAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type ContactAuthInput = {
  action: ContactAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContactAuthArgsInput>;
};

export type ContactCreationSuggestion = {
  __typename: 'ContactCreationSuggestion';
  comment: Maybe<Scalars['String']['output']>;
  contact: Contact;
  createdAt: Scalars['DateTimeISO']['output'];
  fieldIds: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  suggestedByUser: Maybe<User>;
};

export type ContactDetailsIncluded = {
  __typename: 'ContactDetailsIncluded';
  issue: Maybe<BP_PUBLICATION_CONTACT_DETAILS_INCLUDED_ISSUE>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPerson: Scalars['Boolean']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<CONTACT_TITLE>;
};

export type ContactNotificationConfig = {
  __typename: 'ContactNotificationConfig';
  insuranceSuggestions: Scalars['Boolean']['output'];
  marketplaceSuggestions: Scalars['Boolean']['output'];
};

export type ContactNotificationConfigInput = {
  contactId: Scalars['ID']['input'];
  insuranceSuggestions: Scalars['Boolean']['input'];
  marketplaceSuggestions: Scalars['Boolean']['input'];
};

export type ContactSuggestion = AbstractEntity & {
  __typename: 'ContactSuggestion';
  changeType: CONTACT_SUGGESTION_CHANGE_TYPE;
  changes: Array<ContactSuggestionField>;
  comment: Maybe<Scalars['String']['output']>;
  contactId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  suggestedByUser: Maybe<User>;
};

export type ContactSuggestionBooleanField = AbstractContactSuggestionField & {
  __typename: 'ContactSuggestionBooleanField';
  changeType: CONTACT_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: CONTACT_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<CONTACT_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ContactSuggestionField =
  | ContactSuggestionBooleanField
  | ContactSuggestionStringField
  | ContactSuggestionTitleField;

export type ContactSuggestionStringField = AbstractContactSuggestionField & {
  __typename: 'ContactSuggestionStringField';
  changeType: CONTACT_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: CONTACT_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<CONTACT_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ContactSuggestionTitleField = AbstractContactSuggestionField & {
  __typename: 'ContactSuggestionTitleField';
  changeType: CONTACT_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: CONTACT_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<CONTACT_TITLE>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<CONTACT_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ContactVersion = {
  __typename: 'ContactVersion';
  contact: Contact;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPerson: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  title: Maybe<CONTACT_TITLE>;
};

export type ContractAgentAssignee = {
  __typename: 'ContractAgentAssignee';
  currentStep: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  totalStepsCount: Scalars['Int']['output'];
};

export type ContractAgentSignatureRequestedNotification = AbstractEntity &
  Notification & {
    __typename: 'ContractAgentSignatureRequestedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: ContractAgentSignatureRequestedNotificationProps;
  };

export type ContractAgentSignatureRequestedNotificationProps = {
  __typename: 'ContractAgentSignatureRequestedNotificationProps';
  awardedBidId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  officeName: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type ContractAssignee = {
  __typename: 'ContractAssignee';
  currentStep: Scalars['Int']['output'];
  totalStepsCount: Scalars['Int']['output'];
  userFirstName: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['ID']['output']>;
  userLastName: Maybe<Scalars['String']['output']>;
};

export type ContractMultiSigningCompletedNotification = AbstractEntity &
  Notification & {
    __typename: 'ContractMultiSigningCompletedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: ContractMultiSigningCompletedNotificationProps;
  };

export type ContractMultiSigningCompletedNotificationProps = {
  __typename: 'ContractMultiSigningCompletedNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type ContractPrincipalSignatureRequestedNotification = AbstractEntity &
  Notification & {
    __typename: 'ContractPrincipalSignatureRequestedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: ContractPrincipalSignatureRequestedNotificationProps;
  };

export type ContractPrincipalSignatureRequestedNotificationProps = {
  __typename: 'ContractPrincipalSignatureRequestedNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type ContractRunAgentStep = {
  __typename: 'ContractRunAgentStep';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type ContractSignedByAgentNotification = AbstractEntity &
  Notification & {
    __typename: 'ContractSignedByAgentNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: ContractSignedByAgentNotificationProps;
  };

export type ContractSignedByAgentNotificationProps = {
  __typename: 'ContractSignedByAgentNotificationProps';
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
};

export type CostAnalysisCostGroup = {
  __typename: 'CostAnalysisCostGroup';
  actualCost: Maybe<CostAnalysisTotals>;
  addendumCost: Maybe<CostAnalysisTotals>;
  awardedBidCost: Maybe<CostAnalysisTotals>;
  bidPackageCost: Maybe<CostAnalysisTotals>;
  catalogType: Maybe<COST_CATALOG_TYPE>;
  children: Array<CostAnalysisCostGroup>;
  costEstimateCost: Maybe<CostAnalysisTotals>;
  costItem: CostItem;
  costItemId: Maybe<Scalars['String']['output']>;
  designation: Maybe<Scalars['String']['output']>;
  estimatedBudgetNet: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  localId: Scalars['String']['output'];
  parentId: Maybe<Scalars['ID']['output']>;
  parentLocalId: Maybe<Scalars['String']['output']>;
  /** ID of the entity this cost groups is connected to, e.g. bidPackageId */
  referenceId: Maybe<Scalars['String']['output']>;
};

export type CostAnalysisDefaultBreakdownResponse = {
  __typename: 'CostAnalysisDefaultBreakdownResponse';
  breakdown: COST_ANALYSIS_BREAKDOWN;
  customCatalogId: Maybe<Scalars['String']['output']>;
  topLevelGrouping: COST_ANALYSIS_TOP_LEVEL_GROUPING;
};

export type CostAnalysisForProjectValidationResponse = {
  __typename: 'CostAnalysisForProjectValidationResponse';
  validations: CostAnalysisValidations;
};

export type CostAnalysisResponse = {
  __typename: 'CostAnalysisResponse';
  /** @deprecated The currency is now configured on the project level. Use project.currency instead. */
  currency: CURRENCY;
  groups: Array<CostAnalysisCostGroup>;
};

export type CostAnalysisTotals = {
  __typename: 'CostAnalysisTotals';
  totalGross: Scalars['Float']['output'];
  totalNet: Scalars['Float']['output'];
};

export type CostAnalysisValidations = {
  __typename: 'CostAnalysisValidations';
  hasMissingBimVAT: Scalars['Boolean']['output'];
  hasMissingBoqVAT: Scalars['Boolean']['output'];
  hasMissingEstimationVAT: Scalars['Boolean']['output'];
  hasUnassignedBimCostGroups: Scalars['Boolean']['output'];
  hasUnassignedBoqCostGroups: Scalars['Boolean']['output'];
};

export type CostComparisonCostGroup = {
  __typename: 'CostComparisonCostGroup';
  children: Array<CostComparisonCostGroup>;
  designation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<CostComparisonItem>;
  localId: Scalars['String']['output'];
  parentId: Maybe<Scalars['ID']['output']>;
};

export type CostComparisonItem = {
  __typename: 'CostComparisonItem';
  bidId: Scalars['ID']['output'];
  notFilledIn: Scalars['Boolean']['output'];
  total: Maybe<Scalars['Float']['output']>;
};

export type CostComparisonResponse = {
  __typename: 'CostComparisonResponse';
  bids: Array<SubmittedOrGeneratedSubmittedBid>;
  boqHasElementsNotAssignedToCostGroups: Scalars['Boolean']['output'];
  groups: Array<CostComparisonCostGroup>;
  totals: Array<CostComparisonItem>;
};

export type CostEstimatesForProjectResponse = {
  __typename: 'CostEstimatesForProjectResponse';
  costEstimates: Array<CostEstimationEstimate>;
};

export type CostEstimationBidPackage = {
  __typename: 'CostEstimationBidPackage';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  number: Maybe<Scalars['String']['output']>;
};

export type CostEstimationBidPackageCostGroup = {
  __typename: 'CostEstimationBidPackageCostGroup';
  bidPackage: CostEstimationBidPackage;
  bidPackageId: Maybe<Scalars['ID']['output']>;
  costEstimateId: Scalars['ID']['output'];
  costItem: CostItem;
  costItemId: Scalars['ID']['output'];
  draftCostItem: Maybe<CostItem>;
  id: Scalars['ID']['output'];
};

export type CostEstimationBidPackageEstimate = {
  __typename: 'CostEstimationBidPackageEstimate';
  bidPackageCostGroups: Array<CostEstimationBidPackageCostGroup>;
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
};

export type CostEstimationCustomCatalogCostGroup = {
  __typename: 'CostEstimationCustomCatalogCostGroup';
  childCostGroups: Array<CostEstimationCustomCatalogCostGroup>;
  costEstimateId: Scalars['ID']['output'];
  costItem: CostItem;
  costItemId: Scalars['ID']['output'];
  draftCostItem: Maybe<CostItem>;
  id: Scalars['ID']['output'];
  localId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCostGroupId: Maybe<Scalars['String']['output']>;
};

export type CostEstimationCustomCatalogEstimate = {
  __typename: 'CostEstimationCustomCatalogEstimate';
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogCostGroups: Array<CostEstimationCustomCatalogCostGroup>;
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
};

export type CostEstimationEstimate =
  | CostEstimationBidPackageEstimate
  | CostEstimationCustomCatalogEstimate
  | CostEstimationStandardCatalogEstimate
  | CostEstimationStlbWorkCategoryEstimate;

export type CostEstimationStandardCatalogCostGroup = {
  __typename: 'CostEstimationStandardCatalogCostGroup';
  childCostGroups: Array<CostEstimationStandardCatalogCostGroup>;
  costEstimateId: Scalars['ID']['output'];
  costGroupType: COST_GROUP_TYPE;
  costItem: CostItem;
  costItemId: Scalars['ID']['output'];
  designation: Scalars['String']['output'];
  draftCostItem: Maybe<CostItem>;
  id: Scalars['ID']['output'];
  localId: Scalars['String']['output'];
  parentCostGroupLocalId: Maybe<Scalars['String']['output']>;
};

export type CostEstimationStandardCatalogEstimate = {
  __typename: 'CostEstimationStandardCatalogEstimate';
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  standardCatalogCostGroups: Array<CostEstimationStandardCatalogCostGroup>;
};

export type CostEstimationStlbWorkCategoryCostGroup = {
  __typename: 'CostEstimationStlbWorkCategoryCostGroup';
  costEstimateId: Scalars['ID']['output'];
  costItem: CostItem;
  costItemId: Scalars['ID']['output'];
  draftCostItem: Maybe<CostItem>;
  id: Scalars['ID']['output'];
  localId: Scalars['String']['output'];
};

export type CostEstimationStlbWorkCategoryEstimate = {
  __typename: 'CostEstimationStlbWorkCategoryEstimate';
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  stlbWorkCategoryCostGroups: Array<CostEstimationStlbWorkCategoryCostGroup>;
};

export type CostGroupDefinition = {
  __typename: 'CostGroupDefinition';
  designation: Scalars['String']['output'];
  localId: Scalars['String']['output'];
  parentLocalId: Maybe<Scalars['String']['output']>;
};

export type CostGroupDefinitionparentLocalIdArgs = {
  costGroupType: COST_GROUP_TYPE;
};

export type CostGroupDefinitionResponse = {
  __typename: 'CostGroupDefinitionResponse';
  definitions: Array<CostGroupDefinition>;
  type: COST_GROUP_TYPE;
};

export type CostItem = {
  __typename: 'CostItem';
  factor: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  quantity: Maybe<Scalars['Float']['output']>;
  taxRate: Maybe<Scalars['Float']['output']>;
  unit: Maybe<Scalars['String']['output']>;
  unitPrice: Maybe<Scalars['Float']['output']>;
};

export type CountryCodeDiff = {
  __typename: 'CountryCodeDiff';
  new: Maybe<COUNTRY_CODE>;
  old: Maybe<COUNTRY_CODE>;
};

export type CreateBoQTemplateFailure = {
  __typename: 'CreateBoQTemplateFailure';
  success: Scalars['Boolean']['output'];
};

export type CreateBoQTemplateResponse = CreateBoQTemplateFailure | CreateBoQTemplateSuccess;

export type CreateBoQTemplateSuccess = {
  __typename: 'CreateBoQTemplateSuccess';
  template: BoQTemplate;
};

export type CreateCertificateResponse = {
  __typename: 'CreateCertificateResponse';
  certificate: Certificate;
  /** @deprecated Deprecated in favor of certificateUploadUrl query */
  uploadUrl: Scalars['String']['output'];
};

export type CreateCustomCostGroupCatalogFailure = {
  __typename: 'CreateCustomCostGroupCatalogFailure';
  success: Scalars['Boolean']['output'];
};

export type CreateCustomCostGroupCatalogResponse =
  | CreateCustomCostGroupCatalogFailure
  | CreateCustomCostGroupCatalogSuccess;

export type CreateCustomCostGroupCatalogSuccess = {
  __typename: 'CreateCustomCostGroupCatalogSuccess';
  catalog: CustomCostGroupCatalog;
};

export type CreateDocumentInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Float']['input'];
};

export type CreateDocumentResponse = AbstractEntity & {
  __typename: 'CreateDocumentResponse';
  createdAt: Scalars['DateTimeISO']['output'];
  filename: Scalars['String']['output'];
  hasBeenDownloaded: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parsedIfcFile: Maybe<ParsedIfcFile>;
  size: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  uploadUrl: Scalars['String']['output'];
  uploaded: Scalars['Boolean']['output'];
  user: Maybe<User>;
};

export type CreateDocumentResponsehasBeenDownloadedArgs = {
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateInvoiceInput = {
  approvedTotalNet: Scalars['Float']['input'];
  bidPackageId: Scalars['ID']['input'];
  cashDiscountDeadline?: InputMaybe<Scalars['String']['input']>;
  cashDiscountValue: Scalars['Float']['input'];
  customRows: Array<InvoiceNewCustomRow>;
  discountValue: Scalars['Float']['input'];
  fileId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  insuranceValue: Scalars['Float']['input'];
  issuedAt: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nonApprovedTotalNet: Scalars['Float']['input'];
  numberExternal?: InputMaybe<Scalars['String']['input']>;
  numberInternal?: InputMaybe<Scalars['String']['input']>;
  partialInvoiceNumber?: InputMaybe<Scalars['Int']['input']>;
  paymentDeadline: Scalars['String']['input'];
  receiptDate?: InputMaybe<Scalars['String']['input']>;
  retentionValue: Scalars['Float']['input'];
  subcontractorBic: Scalars['String']['input'];
  subcontractorCity: Scalars['String']['input'];
  subcontractorCountryCode: COUNTRY_CODE;
  subcontractorEmail: Scalars['String']['input'];
  subcontractorIban: Scalars['String']['input'];
  subcontractorName: Scalars['String']['input'];
  subcontractorPhone: Scalars['String']['input'];
  subcontractorPostalCode: Scalars['String']['input'];
  subcontractorStreet: Scalars['String']['input'];
  taxRate: Scalars['Float']['input'];
};

export type CreateInvoiceUploadUrlResponse = {
  __typename: 'CreateInvoiceUploadUrlResponse';
  id: Scalars['ID']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type CreatePasswordResponse = {
  __typename: 'CreatePasswordResponse';
  companyName: Scalars['String']['output'];
  loginToken: Scalars['String']['output'];
  mfaMethod: Maybe<MFA_METHOD>;
  mfaPhoneCensored: Maybe<Scalars['String']['output']>;
  mfaRequired: Scalars['Boolean']['output'];
};

export type CreateSubcontractorImportRequestInput = {
  fileName: Scalars['String']['input'];
  fileType: SUBCONTRACTOR_IMPORT_REQUEST_FILE_TYPE;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSubcontractorImportRequestResponse = {
  __typename: 'CreateSubcontractorImportRequestResponse';
  uploadUrl: Scalars['String']['output'];
};

export type CreateTermsResponse = AbstractEntity & {
  __typename: 'CreateTermsResponse';
  createdAt: Scalars['DateTimeISO']['output'];
  downloadUrls: TermsUrls;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  size: Scalars['Int']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type CustomCostGroupCatalog = AbstractEntity & {
  __typename: 'CustomCostGroupCatalog';
  changedByUser: User;
  costGroups: Array<CustomCostGroupDefinition>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  isCatalogEditable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  status: CUSTOM_COST_GROUP_CATALOG_STATUS;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export enum CustomCostGroupCatalogAuthActions {
  update = 'update',
  view = 'view',
}

export type CustomCostGroupCatalogAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type CustomCostGroupCatalogAuthInput = {
  action: CustomCostGroupCatalogAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CustomCostGroupCatalogAuthArgsInput>;
};

export type CustomCostGroupCatalogsResponse = {
  __typename: 'CustomCostGroupCatalogsResponse';
  customCostGroupCatalogs: Array<CustomCostGroupCatalog>;
  totalRecords: Scalars['Int']['output'];
};

export type CustomCostGroupDefinition = AbstractEntity & {
  __typename: 'CustomCostGroupDefinition';
  childCostGroups: Array<CustomCostGroupDefinition>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  localId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CustomCostGroupDefinitionInput = {
  childCostGroups?: InputMaybe<Array<CustomCostGroupDefinitionInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  localId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CustomEmailState = {
  __typename: 'CustomEmailState';
  customEmail: Maybe<Scalars['String']['output']>;
  customEmailEnabled: Scalars['Boolean']['output'];
  customEmailValidation: Maybe<CustomEmailValidation>;
};

export type CustomEmailValidation = {
  __typename: 'CustomEmailValidation';
  dkimRecordName: Scalars['String']['output'];
  dkimRecordValue: Scalars['String']['output'];
  dkimStatus: CUSTOM_EMAIL_VALIDATION_RESULT;
  overallResult: CUSTOM_EMAIL_VALIDATION_RESULT;
  ownershipToken: Scalars['String']['output'];
  ownershipTokenRecordName: Scalars['String']['output'];
  senderStatus: CUSTOM_EMAIL_VALIDATION_RESULT;
  spfRecordValue: Scalars['String']['output'];
  spfStatus: CUSTOM_EMAIL_VALIDATION_RESULT;
};

export type CustomField = AbstractEntity & {
  __typename: 'CustomField';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  options: Maybe<Array<CustomFieldOption>>;
  type: CUSTOM_FIELD_TYPE;
};

export type CustomFieldInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isRequired: Scalars['Boolean']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<CustomFieldOptionInput>>;
  type?: InputMaybe<CUSTOM_FIELD_TYPE>;
};

export type CustomFieldOption = AbstractEntity & {
  __typename: 'CustomFieldOption';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type CustomFieldOptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type CustomFieldValueInput = {
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type CustomFieldWithValue =
  | CustomFieldWithValueCheckbox
  | CustomFieldWithValueCurrency
  | CustomFieldWithValueDate
  | CustomFieldWithValueDateTime
  | CustomFieldWithValueMultiSelect
  | CustomFieldWithValueNumber
  | CustomFieldWithValueSingleSelect
  | CustomFieldWithValueText;

export type CustomFieldWithValueBase = {
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type CustomFieldWithValueCheckbox = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueCheckbox';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type CustomFieldWithValueCurrency = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueCurrency';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type CustomFieldWithValueDate = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueDate';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Maybe<Scalars['Date']['output']>;
};

export type CustomFieldWithValueDateTime = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueDateTime';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CustomFieldWithValueMultiSelect = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueMultiSelect';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  options: Array<CustomFieldWithValueOption>;
  value: Array<Scalars['ID']['output']>;
};

export type CustomFieldWithValueNumber = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueNumber';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type CustomFieldWithValueOption = {
  __typename: 'CustomFieldWithValueOption';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type CustomFieldWithValueSingleSelect = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueSingleSelect';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  options: Array<CustomFieldWithValueOption>;
  value: Maybe<Scalars['ID']['output']>;
};

export type CustomFieldWithValueText = CustomFieldWithValueBase & {
  __typename: 'CustomFieldWithValueText';
  entityId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type CustomFieldsUsage = {
  __typename: 'CustomFieldsUsage';
  count: Scalars['Int']['output'];
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
};

export type CustomSignatureInput = {
  customSignature?: InputMaybe<Scalars['HTML']['input']>;
};

export type CustomUserRole = {
  __typename: 'CustomUserRole';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum DEPARTMENT_TYPE {
  architect = 'architect',
  calculation = 'calculation',
  client = 'client',
  constructionManagement = 'constructionManagement',
  managementBoard = 'managementBoard',
  other = 'other',
  preparationBackOffice = 'preparationBackOffice',
  projectManagement = 'projectManagement',
  purchasing = 'purchasing',
  specialistPlanning = 'specialistPlanning',
  technical = 'technical',
}

export enum DISCOUNT_METHOD {
  absolute = 'absolute',
  percentage = 'percentage',
}

export enum DOCUMENT_ENTITY {
  Addendum = 'Addendum',
  Bid = 'Bid',
  BidPackage = 'BidPackage',
  BidPackageInternal = 'BidPackageInternal',
  BidRequestInNegotiation = 'BidRequestInNegotiation',
  Company = 'Company',
  Contract = 'Contract',
  ContractBoQ = 'ContractBoQ',
  InitialContract = 'InitialContract',
  Project = 'Project',
  ProjectBim = 'ProjectBim',
  ProjectInternal = 'ProjectInternal',
}

export type DateDiff = {
  __typename: 'DateDiff';
  new: Maybe<Scalars['Date']['output']>;
  old: Maybe<Scalars['Date']['output']>;
};

export type DeleteElementOperationDescriptor = {
  __typename: 'DeleteElementOperationDescriptor';
  idsToRemove: Array<Scalars['String']['output']>;
  isSchemaBased: Maybe<Scalars['Boolean']['output']>;
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  pathsToRemove: Array<Scalars['String']['output']>;
};

export type DeletedContact = AbstractEntity & {
  __typename: 'DeletedContact';
  _targetId: Scalars['ID']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  email: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPerson: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  notificationConfig: ContactNotificationConfig;
  phone: Maybe<Scalars['String']['output']>;
  subcontractor: Subcontractor;
  subcontractorId: Scalars['ID']['output'];
  suggestedChanges: Array<ContactSuggestion>;
  title: Maybe<CONTACT_TITLE>;
};

export type DeletedSubcontractor = AbstractEntity &
  GeocodedGraphQLEntity & {
    __typename: 'DeletedSubcontractor';
    _targetId: Scalars['ID']['output'];
    aboutUs: Maybe<Scalars['HTML']['output']>;
    agentCompany: Maybe<Company>;
    bidRequestsAwaitingResponseCount: Scalars['Int']['output'];
    bidRequestsCount: Maybe<Scalars['Int']['output']>;
    city: Maybe<Scalars['String']['output']>;
    company: Maybe<Company>;
    companyId: Maybe<Scalars['ID']['output']>;
    companySize: Maybe<COMPANY_SIZE>;
    confirmedByAgent: Scalars['Boolean']['output'];
    contacts: Array<Contact>;
    countryCode: Maybe<COUNTRY_CODE>;
    createdAt: Scalars['DateTimeISO']['output'];
    customFields: Maybe<Array<CustomFieldWithValue>>;
    description: Maybe<Scalars['String']['output']>;
    foundingYear: Maybe<Scalars['Float']['output']>;
    generatedDescription: Maybe<Scalars['String']['output']>;
    hasSustainableConstruction: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    imageKeys: Array<Scalars['String']['output']>;
    isBanned: Scalars['Boolean']['output'];
    isClaimedByAgentCompany: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    isFavorite: Scalars['Boolean']['output'];
    lat: Maybe<Scalars['Float']['output']>;
    logoKey: Maybe<Scalars['String']['output']>;
    long: Maybe<Scalars['Float']['output']>;
    moreInfo: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    nationalId: Maybe<Scalars['String']['output']>;
    notes: Maybe<Array<SubcontractorNote>>;
    notesCount: Maybe<Scalars['Int']['output']>;
    orderAreaCountryCode: Maybe<COUNTRY_CODE>;
    orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: Maybe<ORDER_RADIUS>;
    ownerName: Maybe<Scalars['String']['output']>;
    postalCode: Maybe<Scalars['String']['output']>;
    pqNumber: Maybe<Scalars['String']['output']>;
    rating: Maybe<Scalars['Float']['output']>;
    referenceProjects: Array<SubcontractorReferenceProject>;
    registrationCity: Maybe<Scalars['String']['output']>;
    registrationPostalCode: Maybe<Scalars['String']['output']>;
    revenueYear: Maybe<Scalars['Float']['output']>;
    slug: Scalars['String']['output'];
    street: Maybe<Scalars['String']['output']>;
    /** Returns number of submitted certificate types (this includes certificates uploaded by both principal and agent users). Returns null if a user or company cannot access certificates */
    submittedCertificateTypesCount: Maybe<Scalars['Int']['output']>;
    suggestedChanges: Array<SubcontractorSuggestion>;
    suggestedNewContacts: Array<ContactCreationSuggestion>;
    sustainableConstructionDetails: Maybe<Scalars['String']['output']>;
    taxId: Maybe<Scalars['String']['output']>;
    type: SUBCONTRACTOR_TYPE;
    unreadMessageThreadsCount: Maybe<Scalars['Int']['output']>;
    website: Maybe<Scalars['String']['output']>;
    workCategories: Array<WorkCategoryForSubcontractor>;
    yearlyRevenue: Maybe<Scalars['Float']['output']>;
  };

export type DeletedSubcontractorbidRequestsCountArgs = {
  status?: InputMaybe<Array<BID_REQUEST_STATUS>>;
};

export type DeletedUser = AbstractEntity & {
  __typename: 'DeletedUser';
  _targetId: Scalars['ID']['output'];
  celloToken: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  customDepartmentName: Maybe<Scalars['String']['output']>;
  customSignature: Maybe<Scalars['HTML']['output']>;
  departmentType: Maybe<DEPARTMENT_TYPE>;
  email: Scalars['String']['output'];
  externalApiAccessKey: Scalars['String']['output'];
  externalCompany: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  featureFlags: Scalars['JSONObject']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intercomHash: Scalars['String']['output'];
  invitationLink: Scalars['String']['output'];
  isActiveCompanyOnboarding: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  language: LANGUAGE;
  lastActivityAt: Maybe<Scalars['DateTimeISO']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mfaLastResetAt: Maybe<Scalars['DateTimeISO']['output']>;
  mfaMethod: Maybe<MFA_METHOD>;
  mfaPhoneCensored: Maybe<Scalars['String']['output']>;
  notificationConfig: UserNotificationConfig;
  offices: Array<Office>;
  passwordPolicy: Maybe<CompanyPasswordPolicy>;
  permissions: Array<AdminUserPermissionResponse>;
  phone: Maybe<Scalars['String']['output']>;
  role: UserRole;
  ssoEnabled: Scalars['Boolean']['output'];
  status: USER_STATUS;
  type: USER_TYPE;
  workspaces: Array<Workspace>;
};

export type DeletedWorkCategory = AbstractEntity & {
  __typename: 'DeletedWorkCategory';
  _targetId: Scalars['ID']['output'];
  aliasSuggestions: Array<Scalars['String']['output']>;
  aliases: Array<Scalars['String']['output']>;
  baseName: Scalars['String']['output'];
  correspondingNetworkWorkCategories: Array<WorkCategory>;
  createdAt: Scalars['DateTimeISO']['output'];
  group: Maybe<WorkCategoryGroup>;
  id: Scalars['ID']['output'];
  isNew: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  slugs: Array<Array<Scalars['String']['output']>>;
  translation: WorkCategoryTranslation;
  translations: Array<WorkCategoryTranslation>;
  type: WORK_CATEGORY_TYPE;
};

export type DeletedWorkCategoryaliasesArgs = {
  language: LANGUAGE;
};

export type DeletedWorkCategorynameArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type DeletedWorkCategoryslugArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type DeletedWorkCategorytranslationArgs = {
  language: LANGUAGE;
};

export type DescriptiveTenderSetup = {
  __typename: 'DescriptiveTenderSetup';
  issue: Maybe<BP_PUBLICATION_DESCRIPTIVE_TENDER_SETUP_ISSUE>;
};

export type DiscardCostEstimateForProjectResponse =
  | DiscardCostEstimateForProjectSuccess
  | RequestEditingSessionFailure;

export type DiscardCostEstimateForProjectSuccess = {
  __typename: 'DiscardCostEstimateForProjectSuccess';
  success: Scalars['Boolean']['output'];
};

export type DiscardSimulationChangesResponse =
  | DiscardSimulationChangesSuccess
  | RequestEditingSessionFailure;

export type DiscardSimulationChangesSuccess = {
  __typename: 'DiscardSimulationChangesSuccess';
  success: Scalars['Boolean']['output'];
};

export type Document = AbstractEntity & {
  __typename: 'Document';
  createdAt: Scalars['DateTimeISO']['output'];
  filename: Scalars['String']['output'];
  hasBeenDownloaded: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parsedIfcFile: Maybe<ParsedIfcFile>;
  size: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  uploaded: Scalars['Boolean']['output'];
  user: Maybe<User>;
};

export type DocumenthasBeenDownloadedArgs = {
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type DocumentsIncluded = {
  __typename: 'DocumentsIncluded';
  issue: Maybe<BP_PUBLICATION_DOCUMENTS_INCLUDED_ISSUE>;
};

export type DraftAddendum = AbstractEntity & {
  __typename: 'DraftAddendum';
  bidPackage: BidPackage;
  billOfQuantities: BillOfQuantities;
  contractRefNumber: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  executionDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  remarks: Maybe<Scalars['String']['output']>;
  status: ADDENDUM_STATUS;
  totalGross: Maybe<Scalars['Float']['output']>;
};

export type DraftBid = AbstractEntity & {
  __typename: 'DraftBid';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Maybe<Scalars['Float']['output']>;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesRevision: BillOfQuantitiesRevision;
  boqEditingStatus: Maybe<BOQ_EDITING_STATUS>;
  canNotifyBidderOnPrincipalSubmission: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  documentsCount: Scalars['Int']['output'];
  hasBoQ: Scalars['Boolean']['output'];
  hasOutdatedBoQ: Maybe<REASON_PROPOSAL_BOQ_IS_OUTDATED>;
  id: Scalars['ID']['output'];
  isEligibleForAwarding: Scalars['Boolean']['output'];
  isSimulated: Maybe<Scalars['Boolean']['output']>;
  message: Maybe<Scalars['String']['output']>;
  parentBid: Maybe<Bid>;
  skontoDeadline: Maybe<Scalars['Int']['output']>;
  skontoRate: Maybe<Scalars['Float']['output']>;
  status: BID_STATUS;
  submittedAt: Maybe<Scalars['DateTimeISO']['output']>;
  submittedByUser: Maybe<User>;
  taxRate: Maybe<Scalars['Float']['output']>;
  totalNet: Maybe<Scalars['Float']['output']>;
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  version: Maybe<Scalars['Int']['output']>;
  warningMetrics: BidWarningMetrics;
};

export type DuplicateProjectInput = {
  category?: InputMaybe<PROJECT_CATEGORY>;
  endsOn?: InputMaybe<Scalars['String']['input']>;
  includeBidPackageTeam?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesBidderList?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesBoQ?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesDescription?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesNumbering?: InputMaybe<Scalars['Boolean']['input']>;
  includeCustomFields?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectDescription?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectEstimatedCost?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectImages?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectTeam?: InputMaybe<Scalars['Boolean']['input']>;
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
  officeId: Scalars['ID']['input'];
  originalProjectId: Scalars['ID']['input'];
  startsOn?: InputMaybe<Scalars['String']['input']>;
};

export type Duration = {
  __typename: 'Duration';
  unit: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export enum EDITING_SESSION_TYPE {
  bidPackageBoqEditor = 'bidPackageBoqEditor',
  boqTemplateBoqEditor = 'boqTemplateBoqEditor',
  priceComparisonSimulationMode = 'priceComparisonSimulationMode',
  projectCostEstimation = 'projectCostEstimation',
}

export enum EMAIL_SALUTATION_MODE {
  autoPrepend = 'autoPrepend',
  editable = 'editable',
}

export enum EMAIL_STATUS {
  blocked = 'blocked',
  bounced = 'bounced',
  clicked = 'clicked',
  delivered = 'delivered',
  opened = 'opened',
  pending = 'pending',
  reportedAsSpam = 'reportedAsSpam',
  unsubscribed = 'unsubscribed',
}

export enum EMAIL_TEMPLATES {
  AdminInvite = 'AdminInvite',
  AdminResetPassword = 'AdminResetPassword',
  AgentSignupConfirmation = 'AgentSignupConfirmation',
  AgentSignupReminder = 'AgentSignupReminder',
  AgentUpdateProfileReminder = 'AgentUpdateProfileReminder',
  AgentUserInvite = 'AgentUserInvite',
  AgentUserInviteReminder = 'AgentUserInviteReminder',
  BidConfirmation = 'BidConfirmation',
  BidConfirmationAfterPrincipalSubmission = 'BidConfirmationAfterPrincipalSubmission',
  BidInvite = 'BidInvite',
  BidInviteReminder = 'BidInviteReminder',
  BidInvitesFailed = 'BidInvitesFailed',
  BidNotification = 'BidNotification',
  BidPackageChangedNotification = 'BidPackageChangedNotification',
  BidPackageClosed = 'BidPackageClosed',
  BidPackageExportBidsNotification = 'BidPackageExportBidsNotification',
  BidPackageStatusChangedNotification = 'BidPackageStatusChangedNotification',
  BidRequestAwardedNotification = 'BidRequestAwardedNotification',
  BidRequestDeclined = 'BidRequestDeclined',
  BidRequestRejectedNotification = 'BidRequestRejectedNotification',
  BidderIntroduction = 'BidderIntroduction',
  BiddersAwaitingResponse = 'BiddersAwaitingResponse',
  BiddingPeriodEnding = 'BiddingPeriodEnding',
  BoQChangedNotification = 'BoQChangedNotification',
  CertificateExpiring = 'CertificateExpiring',
  CertificateNotProvidedInTime = 'CertificateNotProvidedInTime',
  CertificateRequest = 'CertificateRequest',
  CertificatesRejectedNotification = 'CertificatesRejectedNotification',
  CertificatesUploadedNotification = 'CertificatesUploadedNotification',
  ContractAgentRequest = 'ContractAgentRequest',
  ContractSignature = 'ContractSignature',
  ContractWithdrawn = 'ContractWithdrawn',
  DocumentNotification = 'DocumentNotification',
  ExternalBidNotification = 'ExternalBidNotification',
  InsuranceSuggestion = 'InsuranceSuggestion',
  InvoiceApprovalRequired = 'InvoiceApprovalRequired',
  InvoiceForward = 'InvoiceForward',
  MarketplaceBidRequestBidsDueNotification = 'MarketplaceBidRequestBidsDueNotification',
  MarketplaceSuggestion = 'MarketplaceSuggestion',
  MarketplaceTenderClosedNotification = 'MarketplaceTenderClosedNotification',
  MfaResetNotice = 'MfaResetNotice',
  MfaResetRequest = 'MfaResetRequest',
  MfaSetupNotice = 'MfaSetupNotice',
  MultiUserApprovalRequest = 'MultiUserApprovalRequest',
  MultipleMarketplaceSuggestions = 'MultipleMarketplaceSuggestions',
  NewMessageNotification = 'NewMessageNotification',
  PasswordlessLogin = 'PasswordlessLogin',
  ProjectExportComplete = 'ProjectExportComplete',
  PublishMarketplaceRestrictedView = 'PublishMarketplaceRestrictedView',
  ReportAgentCompanyClosed = 'ReportAgentCompanyClosed',
  RequestProfileCompletion = 'RequestProfileCompletion',
  ResetPassword = 'ResetPassword',
  SalesforceAgentUnsolicitedSignupEmail = 'SalesforceAgentUnsolicitedSignupEmail',
  SalesforceNewCompanyContactImportRequest = 'SalesforceNewCompanyContactImportRequest',
  SavedSearchNotification = 'SavedSearchNotification',
  SignupConfirmation = 'SignupConfirmation',
  SignupDenial = 'SignupDenial',
  SignupSubcontractorConfirmation = 'SignupSubcontractorConfirmation',
  StaleBidPackageDraftReminder = 'StaleBidPackageDraftReminder',
  SubcontractorImportRequestCompleted = 'SubcontractorImportRequestCompleted',
  TaskAssigned = 'TaskAssigned',
  TaskCompleted = 'TaskCompleted',
  TaskInProgress = 'TaskInProgress',
  TaskReminder = 'TaskReminder',
  TrialEndingSoon = 'TrialEndingSoon',
  TrialFeedback = 'TrialFeedback',
  TrialStarting = 'TrialStarting',
  UserAddedToBidPackageTeam = 'UserAddedToBidPackageTeam',
  UserAddedToMultipleBidPackageTeams = 'UserAddedToMultipleBidPackageTeams',
  UserInvite = 'UserInvite',
  UserInviteReminder = 'UserInviteReminder',
  UserInviteRequest = 'UserInviteRequest',
  UserInviteV2 = 'UserInviteV2',
  UserInvitesFailed = 'UserInvitesFailed',
  UserJoinRequest = 'UserJoinRequest',
  UserLocked = 'UserLocked',
  UserPasswordOutdatedSoon = 'UserPasswordOutdatedSoon',
}

export enum EXTERNAL_CREDENTIAL_TYPE {
  heinzeVob = 'heinzeVob',
  stlb = 'stlb',
}

export type EditRequestBoQResponse = EditRequestBoQSuccess | RequestEditingSessionFailure;

export type EditRequestBoQSuccess = {
  __typename: 'EditRequestBoQSuccess';
  hasTaxRateChanges: Maybe<Scalars['Boolean']['output']>;
  hasUnitChanges: Maybe<Scalars['Boolean']['output']>;
  hasUnitPriceChanges: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type EditRequestForProposalsBoQInput = {
  boqStructure?: InputMaybe<Array<BoQStructureInput>>;
  entries?: InputMaybe<Array<RenumberEntryInput>>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  idsToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  index?: InputMaybe<Scalars['Float']['input']>;
  isSchemaBased?: InputMaybe<Scalars['Boolean']['input']>;
  labourTimeLabel?: InputMaybe<Scalars['String']['input']>;
  moves?: InputMaybe<Array<MoveOperationInput>>;
  newElementId?: InputMaybe<Scalars['String']['input']>;
  newElementItemNumber?: InputMaybe<Scalars['String']['input']>;
  newElementType?: InputMaybe<BOQ_ELEMENT_TYPE_DISCRIMINATOR>;
  newValue?: InputMaybe<Scalars['String']['input']>;
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  pathsToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  positionType?: InputMaybe<BOQ_POSITION_TYPE>;
  priceComponentIndex?: InputMaybe<Scalars['Int']['input']>;
  priceComponents?: InputMaybe<Array<Scalars['String']['input']>>;
  skipIsSupportedCatalogueReferenceValidation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EditTemplateBoQResponse = EditTemplateBoQSuccess | RequestEditingSessionFailure;

export type EditTemplateBoQSuccess = {
  __typename: 'EditTemplateBoQSuccess';
  hasTaxRateChanges: Maybe<Scalars['Boolean']['output']>;
  hasUnitChanges: Maybe<Scalars['Boolean']['output']>;
  hasUnitPriceChanges: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type EditingSession = AbstractEntity & {
  __typename: 'EditingSession';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  type: EDITING_SESSION_TYPE;
  user: User;
};

export type EmailTemplateNotification = {
  __typename: 'EmailTemplateNotification';
  emailTemplate: Maybe<Scalars['String']['output']>;
  shouldSend: Scalars['Boolean']['output'];
};

export type EmailTemplateNotificationInput = {
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
  shouldSend: Scalars['Boolean']['input'];
};

export type EmailTemplatePlaceholderInput = {
  absoluteDiscount?: InputMaybe<Scalars['Float']['input']>;
  absoluteDiscountCalculated: Scalars['Float']['input'];
  additionalCosts: Scalars['Float']['input'];
  bidRequestId: Scalars['ID']['input'];
  bidSubmittedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  discountRateCalculated: Scalars['Float']['input'];
  mainOrderNumber?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  skontoDeadline: Scalars['Int']['input'];
  skontoRate: Scalars['Float']['input'];
  taxRate: Scalars['Float']['input'];
  taxValue: Scalars['Float']['input'];
  totalGross: Scalars['Float']['input'];
  totalGrossWithSkonto: Scalars['Float']['input'];
  totalNet: Scalars['Float']['input'];
  totalNetWithDiscount: Scalars['Float']['input'];
};

export type EnableCosunoMarketplace = {
  __typename: 'EnableCosunoMarketplace';
  issue: Maybe<BP_PUBLICATION_ENABLE_MARKETPLACE>;
};

export type ExcelBoQImportOptions = {
  columnDefinitions: Array<ColumnDefinition>;
  positionTypeDefinitions: Array<PositionTypeDefinition>;
  positionTypeLabelSeparator: Scalars['String']['input'];
  skippedRows: Array<Scalars['Float']['input']>;
  startRow: Scalars['Float']['input'];
  timeTracking?: InputMaybe<Scalars['String']['input']>;
  unitPriceComponents?: InputMaybe<Array<Scalars['String']['input']>>;
  worksheetId: Scalars['Float']['input'];
};

export type ExtendedNetworkSubcontractorWithMergedIntoRedirect =
  | MergedIntoSubcontractor
  | NetworkSubcontractorWithContactAvailability;

export enum ExternalCredentialAuthActions {
  update = 'update',
  view = 'view',
}

export type ExternalCredentialAuthArgsInput = {
  companyId: Scalars['ID']['input'];
  type: EXTERNAL_CREDENTIAL_TYPE;
};

export type ExternalCredentialAuthInput = {
  action: ExternalCredentialAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ExternalCredentialAuthArgsInput>;
};

export type ExternalCredentialInput = {
  password: Scalars['String']['input'];
  type: EXTERNAL_CREDENTIAL_TYPE;
  username: Scalars['String']['input'];
};

export type ExternalCredentialResponse = {
  __typename: 'ExternalCredentialResponse';
  type: EXTERNAL_CREDENTIAL_TYPE;
  username: Scalars['String']['output'];
};

export type FileTree = AbstractEntity & {
  __typename: 'FileTree';
  attachableId: Scalars['ID']['output'];
  attachableType: DOCUMENT_ENTITY;
  createdAt: Scalars['DateTimeISO']['output'];
  fileTree: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type FileTreeAdditionsUploadDataResponse = {
  __typename: 'FileTreeAdditionsUploadDataResponse';
  id: Scalars['ID']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type FileTreeResponse = {
  __typename: 'FileTreeResponse';
  documents: Array<Document>;
  documentsCount: Scalars['Float']['output'];
  fileTree: FileTree;
};

export type FileUploadResponse = {
  __typename: 'FileUploadResponse';
  uploadKey: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type FillOutBoQInput = {
  complementingPricePercentageUpdates: Array<UpdateFillableBoQComplementingPricePercentageInput>;
  descriptionUpdates: Array<UpdateFillableBoQDescriptionInput>;
  freeQuantityUpdates: Array<UpdateFillableBoQFreeQuantityInput>;
  priceComponentUpdates: Array<UpdateFillableBoQPriceComponentInput>;
  priceUpdates: Array<UpdateFillableBoQPriceInput>;
  surchargeOrDiscountUpdates: Array<UpdateFillableBoQSurchargeOrDiscountInput>;
  timeTrackingUpdates: Array<UpdateFillableBoQTimeTrackingInput>;
};

export type FillOutFieldOperationDescriptor = {
  __typename: 'FillOutFieldOperationDescriptor';
  fieldName: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  isSchemaBased: Maybe<Scalars['Boolean']['output']>;
  newValue: Scalars['String']['output'];
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  priceComponentIndex: Maybe<Scalars['Int']['output']>;
  skipIsSupportedCatalogueReferenceValidation: Maybe<Scalars['Boolean']['output']>;
};

export type GeneralValidationConstraintError = {
  __typename: 'GeneralValidationConstraintError';
  error: Maybe<Scalars['String']['output']>;
  type: NETWORK_SUBCONTRACTOR_IMPORT_GENERAL_VALIDATION_CONSTRAINT;
};

export type GenerateUrlAndIdResponse = {
  __typename: 'GenerateUrlAndIdResponse';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type GeocodedGraphQLEntity = {
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  lat: Maybe<Scalars['Float']['output']>;
  long: Maybe<Scalars['Float']['output']>;
};

export type HeaderValidationConstraintError = {
  __typename: 'HeaderValidationConstraintError';
  columns: Array<Scalars['String']['output']>;
  type: NETWORK_SUBCONTRACTOR_IMPORT_HEADER_VALIDATION_CONSTRAINT;
};

export type HeinzeGuiUrlResponse = {
  __typename: 'HeinzeGuiUrlResponse';
  baseUrl: Scalars['String']['output'];
  homeUrl: Scalars['String']['output'];
};

export type HiddenBidder = {
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
};

export enum HighlightPriceMode {
  extremes = 'extremes',
  outliers = 'outliers',
}

export type HistoryItem =
  | BidInviteStatusChangeResponse
  | BidRequestCustomBidsDueAtChangeResponse
  | BidRequestStatusChangeResponse
  | BlindTenderOpeningResponse
  | SentEmailForStatusHistoryResponse
  | SubmittedBidHistoryItem
  | TermsAcceptanceResponse;

export enum IMPORTABLE_BOQ_FIELD {
  customCostCatalog = 'customCostCatalog',
  descriptionLong = 'descriptionLong',
  descriptionShort = 'descriptionShort',
  din276 = 'din276',
  itemNumber = 'itemNumber',
  oenorm = 'oenorm',
  positionType = 'positionType',
  quantity = 'quantity',
  stlbWorkCategory = 'stlbWorkCategory',
  taxRate = 'taxRate',
  timeTracking = 'timeTracking',
  totalPrice = 'totalPrice',
  totalPriceGross = 'totalPriceGross',
  unitPrice = 'unitPrice',
  unitPriceComponent = 'unitPriceComponent',
  unitTag = 'unitTag',
}

export enum INVITE_REQUEST_SOURCE_TYPE {
  bidPackageUsers = 'bidPackageUsers',
  projectBillingRunUsers = 'projectBillingRunUsers',
  projectUsers = 'projectUsers',
}

export enum INVOICE_CUSTOM_ROW_TYPE {
  absolute = 'absolute',
  percent = 'percent',
}

export enum INVOICE_HISTORY_STATUS {
  approved = 'approved',
  modified = 'modified',
  rejected = 'rejected',
  rejectedAndReturnedForAdjustments = 'rejectedAndReturnedForAdjustments',
}

export enum INVOICE_SORT_FIELD {
  bidPackageName = 'bidPackageName',
  numberExternal = 'numberExternal',
  status = 'status',
}

export enum INVOICE_STATUS {
  approvalPending = 'approvalPending',
  approved = 'approved',
  paid = 'paid',
  paymentInProgress = 'paymentInProgress',
  rejected = 'rejected',
}

export type ImageUploadUrlResponse = {
  __typename: 'ImageUploadUrlResponse';
  storageKey: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ImportBidPackageResponse = {
  __typename: 'ImportBidPackageResponse';
  bidPackage: BidPackage;
};

export type ImportSubcontractors = {
  __typename: 'ImportSubcontractors';
  isFulfilled: Scalars['Boolean']['output'];
};

export type IntDiff = {
  __typename: 'IntDiff';
  new: Maybe<Scalars['Int']['output']>;
  old: Maybe<Scalars['Int']['output']>;
};

export type InvitationResponse = {
  __typename: 'InvitationResponse';
  invitee: User;
  inviter: User;
  isInvitationToWorkspace: Scalars['Boolean']['output'];
  isPasswordUpdateRequired: Scalars['Boolean']['output'];
  passwordPolicy: Maybe<CompanyPasswordPolicy>;
};

export type InviteColleagues = {
  __typename: 'InviteColleagues';
  isFulfilled: Scalars['Boolean']['output'];
};

export type InviteRequestSourceInput = {
  bidPackageId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  type: INVITE_REQUEST_SOURCE_TYPE;
};

export type InviteeInput = {
  customDepartmentName?: InputMaybe<Scalars['String']['input']>;
  departmentType?: InputMaybe<DEPARTMENT_TYPE>;
  firstName: Scalars['String']['input'];
  language: LANGUAGE;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type Invoice = {
  __typename: 'Invoice';
  approvedTotalNet: Scalars['Float']['output'];
  assignee: Maybe<InvoiceAssignee>;
  bidPackage: BidPackage;
  cashDiscountDeadline: Maybe<Scalars['String']['output']>;
  cashDiscountValue: Scalars['Float']['output'];
  customRows: Array<InvoiceCustomRow>;
  discountValue: Scalars['Float']['output'];
  fileId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insuranceValue: Scalars['Float']['output'];
  issuedAt: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
  nonApprovedTotalNet: Scalars['Float']['output'];
  numberExternal: Maybe<Scalars['String']['output']>;
  numberInternal: Maybe<Scalars['String']['output']>;
  partialInvoiceNumber: Maybe<Scalars['Int']['output']>;
  paymentDeadline: Scalars['String']['output'];
  receiptDate: Maybe<Scalars['String']['output']>;
  retentionValue: Scalars['Float']['output'];
  status: INVOICE_STATUS;
  statusChanges: Array<InvoiceStatusChange>;
  subcontractorBic: Scalars['String']['output'];
  subcontractorCity: Scalars['String']['output'];
  subcontractorCountryCode: COUNTRY_CODE;
  subcontractorEmail: Scalars['String']['output'];
  subcontractorIban: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
  subcontractorPhone: Scalars['String']['output'];
  subcontractorPostalCode: Scalars['String']['output'];
  subcontractorStreet: Scalars['String']['output'];
  taxRate: Scalars['Float']['output'];
};

export type InvoiceAssignee = {
  __typename: 'InvoiceAssignee';
  currentStep: Maybe<Scalars['Int']['output']>;
  totalStepsCount: Scalars['Int']['output'];
  userFirstName: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  userLastName: Maybe<Scalars['String']['output']>;
};

export enum InvoiceAuthActions {
  createInvoiceNote = 'createInvoiceNote',
  delete = 'delete',
  update = 'update',
  view = 'view',
}

export type InvoiceAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type InvoiceAuthInput = {
  action: InvoiceAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<InvoiceAuthArgsInput>;
};

export type InvoiceAwaitingApprovalNotification = AbstractEntity &
  Notification & {
    __typename: 'InvoiceAwaitingApprovalNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: InvoiceAwaitingApprovalNotificationProps;
  };

export type InvoiceAwaitingApprovalNotificationProps = {
  __typename: 'InvoiceAwaitingApprovalNotificationProps';
  bidPackageName: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  invoiceName: Maybe<Scalars['String']['output']>;
  invoiceNumber: Maybe<Scalars['String']['output']>;
  projectName: Scalars['String']['output'];
};

export type InvoiceCustomRow = {
  __typename: 'InvoiceCustomRow';
  absoluteValue: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  invoiceId: Scalars['ID']['output'];
  isNegative: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  type: INVOICE_CUSTOM_ROW_TYPE;
};

export type InvoiceCustomRowForUpdate = {
  absoluteValue: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isNegative: Scalars['Boolean']['input'];
  position: Scalars['Int']['input'];
  rate: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  type: INVOICE_CUSTOM_ROW_TYPE;
};

export type InvoiceNewCustomRow = {
  absoluteValue: Scalars['Float']['input'];
  isNegative: Scalars['Boolean']['input'];
  position: Scalars['Int']['input'];
  rate: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  type: INVOICE_CUSTOM_ROW_TYPE;
};

export type InvoiceNote = AbstractEntity &
  Note & {
    __typename: 'InvoiceNote';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    text: Scalars['String']['output'];
    user: User;
  };

export type InvoiceNotesResponse = {
  __typename: 'InvoiceNotesResponse';
  notes: Array<InvoiceNote>;
  totalRecords: Scalars['Int']['output'];
};

export type InvoiceRejectedNotification = AbstractEntity &
  Notification & {
    __typename: 'InvoiceRejectedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: InvoiceRejectedNotificationProps;
  };

export type InvoiceRejectedNotificationProps = {
  __typename: 'InvoiceRejectedNotificationProps';
  bidPackageName: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  invoiceName: Maybe<Scalars['String']['output']>;
  invoiceNumber: Maybe<Scalars['String']['output']>;
  projectName: Scalars['String']['output'];
};

export type InvoiceStatusChange = {
  __typename: 'InvoiceStatusChange';
  createdAt: Scalars['DateTimeISO']['output'];
  currentStep: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  status: INVOICE_HISTORY_STATUS;
  totalStepsCount: Scalars['Int']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};

export type InvoicesResponse = {
  __typename: 'InvoicesResponse';
  invoices: Array<Invoice>;
  totalRecords: Scalars['Int']['output'];
};

export enum LANGUAGE {
  bg = 'bg',
  cs = 'cs',
  da = 'da',
  de = 'de',
  en = 'en',
  es = 'es',
  et = 'et',
  fi = 'fi',
  fr = 'fr',
  gr = 'gr',
  hr = 'hr',
  hu = 'hu',
  it = 'it',
  lt = 'lt',
  lv = 'lv',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  sk = 'sk',
  sv = 'sv',
}

export enum LONG_RUNNING_TASK_STATE {
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
}

export type LbHochbauRestrictedItemNumbersResponse = {
  __typename: 'LbHochbauRestrictedItemNumbersResponse';
  restrictedItemNumbers: Array<Scalars['String']['output']>;
};

export type LbHochbauTextProviderElementsTreeResponse = {
  __typename: 'LbHochbauTextProviderElementsTreeResponse';
  lbHochbauBoQElements: Scalars['JSONObject']['output'];
};

export type LocationFilter = {
  countryCode?: InputMaybe<COUNTRY_CODE>;
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  stateCode?: InputMaybe<STATE_CODE>;
};

export type LoginSuggestion = {
  __typename: 'LoginSuggestion';
  email: Scalars['String']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
};

export type LoginTokenResponse = {
  __typename: 'LoginTokenResponse';
  loginToken: Scalars['String']['output'];
};

export type LongRunningTask = AbstractEntity & {
  __typename: 'LongRunningTask';
  createdAt: Scalars['DateTimeISO']['output'];
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  percentComplete: Maybe<Scalars['Float']['output']>;
  state: LONG_RUNNING_TASK_STATE;
};

export enum MAILJET_EVENT_TYPE {
  blocked = 'blocked',
  bounce = 'bounce',
  click = 'click',
  open = 'open',
  sent = 'sent',
  spam = 'spam',
  unsub = 'unsub',
}

export enum MAILJET_MESSAGE_STATUS {
  blocked = 'blocked',
  bounce = 'bounce',
  clicked = 'clicked',
  deferred = 'deferred',
  hardbounced = 'hardbounced',
  opened = 'opened',
  queued = 'queued',
  sent = 'sent',
  softbounced = 'softbounced',
  spam = 'spam',
  unknown = 'unknown',
  unsub = 'unsub',
}

export enum MARKETPLACE_BID_REQUEST_STATUS {
  accepted = 'accepted',
  undecided = 'undecided',
  watched = 'watched',
}

export enum MARKETPLACE_SUGGESTIONS_SORT_FIELD {
  sentAt = 'sentAt',
}

export enum MARKETPLACE_SUGGESTION_STATUS {
  bidSubmitted = 'bidSubmitted',
  draft = 'draft',
  failed = 'failed',
  opened = 'opened',
  sent = 'sent',
  viewed = 'viewed',
}

export enum MESSAGE_THREAD_SORT_FIELD {
  lastMessageCreatedAt = 'lastMessageCreatedAt',
}

export enum MFA_METHOD {
  authenticator = 'authenticator',
  phone = 'phone',
}

export enum MULTI_USER_APPROVAL_ACTION {
  approveAddendum = 'approveAddendum',
  approveInvoice = 'approveInvoice',
  awardBid = 'awardBid',
  openBids = 'openBids',
  publishBidPackage = 'publishBidPackage',
  sendContract = 'sendContract',
}

export type MarketplaceBidPackageSample = {
  __typename: 'MarketplaceBidPackageSample';
  bidsDueDate: Scalars['DateTimeISO']['output'];
  customerName: Maybe<Scalars['String']['output']>;
  endsOn: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  officeLogoKey: Maybe<Scalars['String']['output']>;
  officeName: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  projectName: Scalars['String']['output'];
  startsOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type MarketplaceSavedSearch = AbstractEntity & {
  __typename: 'MarketplaceSavedSearch';
  address: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  distanceInKm: Maybe<Scalars['Float']['output']>;
  dueDateInMinDays: Maybe<Scalars['Int']['output']>;
  endsOn: Maybe<Scalars['Date']['output']>;
  geoCoords: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isNotificationEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  query: Maybe<Scalars['String']['output']>;
  showExpiredTenders: Scalars['Boolean']['output'];
  sortBy: Scalars['String']['output'];
  startsOn: Maybe<Scalars['Date']['output']>;
  workCategories: Array<WorkCategory>;
};

export type MarketplaceSavedSearchInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  distanceInKm?: InputMaybe<Scalars['Float']['input']>;
  dueDateInMinDays?: InputMaybe<Scalars['Int']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  geoCoords?: InputMaybe<Scalars['String']['input']>;
  isNotificationEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  showExpiredTenders: Scalars['Boolean']['input'];
  sortBy: Scalars['String']['input'];
  sortDirection: SORT_DIRECTION;
  startsOn?: InputMaybe<Scalars['Date']['input']>;
  workCategoryIds?: Array<Scalars['String']['input']>;
};

export type MarketplaceSuggestion = AbstractEntity & {
  __typename: 'MarketplaceSuggestion';
  bidPackageId: Scalars['ID']['output'];
  contact: Contact;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  status: MARKETPLACE_SUGGESTION_STATUS;
};

export enum MarketplaceSuggestionAuthActions {
  view = 'view',
}

export type MarketplaceSuggestionAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type MarketplaceSuggestionAuthInput = {
  action: MarketplaceSuggestionAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<MarketplaceSuggestionAuthArgsInput>;
};

export type MarketplaceSuggestionPreviewResponse = {
  __typename: 'MarketplaceSuggestionPreviewResponse';
  content: Scalars['HTML']['output'];
  defaultIntro: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  greeting: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type MarketplaceSuggestionsInput = {
  bidPackageId: Scalars['ID']['input'];
  contactIds: Array<Scalars['ID']['input']>;
  emailIntroduction: Scalars['HTML']['input'];
};

export type MarketplaceSuggestionsResponse = {
  __typename: 'MarketplaceSuggestionsResponse';
  sentSuggestions: Array<MarketplaceSuggestion>;
  totalCount: Scalars['Int']['output'];
};

export type MassDetermination = {
  __typename: 'MassDetermination';
  billOfQuantities: BillOfQuantities;
  billOfQuantitiesId: Scalars['ID']['output'];
  boqRowId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastUsedLocalId: Maybe<Scalars['String']['output']>;
  rows: Array<MassDeterminationRow>;
  status: BOQ_MASS_DETERMINATION_STATUS;
};

export type MassDeterminationCommentRow = AbstractMassDeterminationRow & {
  __typename: 'MassDeterminationCommentRow';
  comment: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The unique, auto-increasing local ID of the row following the schema 1A0, 1B0, ...1Z0, 2A0... */
  localId: Scalars['String']['output'];
};

export type MassDeterminationCustomFormulaRow = AbstractMassDeterminationRow & {
  __typename: 'MassDeterminationCustomFormulaRow';
  expression: Maybe<Scalars['String']['output']>;
  factor: Maybe<Scalars['Float']['output']>;
  formulaId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The unique, auto-increasing local ID of the row following the schema 1A0, 1B0, ...1Z0, 2A0... */
  localId: Scalars['String']['output'];
  note: Maybe<Scalars['String']['output']>;
};

export type MassDeterminationFormulaRow = AbstractMassDeterminationRow & {
  __typename: 'MassDeterminationFormulaRow';
  factor: Maybe<Scalars['Float']['output']>;
  formulaId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The unique, auto-increasing local ID of the row following the schema 1A0, 1B0, ...1Z0, 2A0... */
  localId: Scalars['String']['output'];
  note: Maybe<Scalars['String']['output']>;
  value1: Maybe<Scalars['Float']['output']>;
  value2: Maybe<Scalars['Float']['output']>;
  value3: Maybe<Scalars['Float']['output']>;
  value4: Maybe<Scalars['Float']['output']>;
  value5: Maybe<Scalars['Float']['output']>;
};

export type MassDeterminationRow =
  | MassDeterminationCommentRow
  | MassDeterminationCustomFormulaRow
  | MassDeterminationFormulaRow;

export type MergedIntoSubcontractor = {
  __typename: 'MergedIntoSubcontractor';
  countryCode: Maybe<COUNTRY_CODE>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type Message = AbstractEntity & {
  __typename: 'Message';
  /** Subcontractor name or general contractor office name */
  authorCompanyName: Maybe<Scalars['String']['output']>;
  authorName: Maybe<Scalars['String']['output']>;
  authorType: COMPANY_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  documents: Array<Document>;
  hasBeenRead: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isMyMessage: Scalars['Boolean']['output'];
  messageText: Scalars['HTML']['output'];
  recipients: Array<MessageRecipient>;
};

export type MessageDetails = {
  __typename: 'MessageDetails';
  arrivedAt: Scalars['String']['output'];
  attemptCount: Scalars['Int']['output'];
  state: Maybe<Scalars['String']['output']>;
  statePermanent: Scalars['Boolean']['output'];
  status: MAILJET_MESSAGE_STATUS;
};

export type MessageHistory = {
  __typename: 'MessageHistory';
  comment: Scalars['String']['output'];
  eventAt: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type MessageRecipient = {
  __typename: 'MessageRecipient';
  hasReadMessage: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type MessageThread = AbstractEntity & {
  __typename: 'MessageThread';
  bidPackage: BidPackage;
  bidRequest: BidRequest;
  createdAt: Scalars['DateTimeISO']['output'];
  hasUnreadMessages: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastMessage: Message;
  messages: Array<Message>;
  participatingCompanyOffice: Office;
  respondingCompanyName: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
};

export type MessageThreadResponse = {
  __typename: 'MessageThreadResponse';
  messageThreads: Array<MessageThread>;
  totalRecords: Scalars['Int']['output'];
};

export type MoveElementOperationDescriptor = {
  __typename: 'MoveElementOperationDescriptor';
  isSchemaBased: Maybe<Scalars['Boolean']['output']>;
  moves: Array<MoveElementOperationMoveDescriptor>;
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  pathsToRemove: Array<Scalars['String']['output']>;
};

export type MoveElementOperationMoveDescriptor = {
  __typename: 'MoveElementOperationMoveDescriptor';
  newPath: Scalars['String']['output'];
  originalPath: Scalars['String']['output'];
};

export type MoveOperationInput = {
  newPath: Scalars['String']['input'];
  originalPath: Scalars['String']['input'];
};

export type MultiUserApprovalRequest = AbstractEntity & {
  __typename: 'MultiUserApprovalRequest';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  props: Maybe<MultiUserApprovalRequestProps>;
  requester: User;
  users: Array<MultiUserApprovalRequestUser>;
};

export type MultiUserApprovalRequestAwardBidProps = {
  __typename: 'MultiUserApprovalRequestAwardBidProps';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  absoluteDiscountCalculated: Scalars['Float']['output'];
  additionalCosts: Scalars['Float']['output'];
  awardedSumType: BID_AWARDED_SUM_TYPE;
  awarding: EmailTemplateNotification;
  bidId: Scalars['ID']['output'];
  bidRequestId: Scalars['ID']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  discountRateCalculated: Scalars['Float']['output'];
  mainOrderNumber: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  rejection: EmailTemplateNotification;
  skontoDeadline: Scalars['Int']['output'];
  skontoRate: Scalars['Float']['output'];
  taxRate: Scalars['Float']['output'];
  taxValue: Scalars['Float']['output'];
  totalGross: Scalars['Float']['output'];
  totalGrossWithSkonto: Scalars['Float']['output'];
  totalNet: Scalars['Float']['output'];
  totalNetWithDiscount: Scalars['Float']['output'];
};

export type MultiUserApprovalRequestAwardBidPropsInput = {
  absoluteDiscount?: InputMaybe<Scalars['Float']['input']>;
  absoluteDiscountCalculated: Scalars['Float']['input'];
  additionalCosts: Scalars['Float']['input'];
  awardedSumType?: InputMaybe<BID_AWARDED_SUM_TYPE>;
  awarding: EmailTemplateNotificationInput;
  bidId: Scalars['ID']['input'];
  bidRequestId: Scalars['ID']['input'];
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  discountRateCalculated: Scalars['Float']['input'];
  mainOrderNumber?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  rejection: EmailTemplateNotificationInput;
  skontoDeadline: Scalars['Int']['input'];
  skontoRate: Scalars['Float']['input'];
  taxRate: Scalars['Float']['input'];
  taxValue: Scalars['Float']['input'];
  totalGross: Scalars['Float']['input'];
  totalGrossWithSkonto: Scalars['Float']['input'];
  totalNet: Scalars['Float']['input'];
  totalNetWithDiscount: Scalars['Float']['input'];
};

export type MultiUserApprovalRequestProps =
  | MultiUserApprovalRequestAwardBidProps
  | MultiUserApprovalRequestPublishBidPackageProps
  | MultiUserApprovalRequestSendContractProps;

export type MultiUserApprovalRequestPropsInput = {
  awardBid?: InputMaybe<MultiUserApprovalRequestAwardBidPropsInput>;
  publishBidPackage?: InputMaybe<MultiUserApprovalRequestPublishBidPackagePropsInput>;
  sendContract?: InputMaybe<MultiUserApprovalRequestSendContractPropsInput>;
};

export type MultiUserApprovalRequestPublishBidPackageProps = {
  __typename: 'MultiUserApprovalRequestPublishBidPackageProps';
  customInvitationEmailIntro: Maybe<Scalars['HTML']['output']>;
  introductionEmailContent: Scalars['HTML']['output'];
  introductionEmailRecipients: Array<Scalars['ID']['output']>;
  sendIntroductionEmails: Scalars['Boolean']['output'];
  skipInvitations: Scalars['Boolean']['output'];
};

export type MultiUserApprovalRequestPublishBidPackagePropsInput = {
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  introductionEmailContent?: InputMaybe<Scalars['HTML']['input']>;
  introductionEmailRecipients: Array<Scalars['ID']['input']>;
  sendIntroductionEmails: Scalars['Boolean']['input'];
  skipInvitations: Scalars['Boolean']['input'];
};

export type MultiUserApprovalRequestSendContractProps = {
  __typename: 'MultiUserApprovalRequestSendContractProps';
  isContractSigningEnabled: Scalars['Boolean']['output'];
};

export type MultiUserApprovalRequestSendContractPropsInput = {
  isContractSigningEnabled: Scalars['Boolean']['input'];
};

export type MultiUserApprovalRequestUser = AbstractEntity & {
  __typename: 'MultiUserApprovalRequestUser';
  createdAt: Scalars['DateTimeISO']['output'];
  hasApproved: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type MultiUserApprovalRule = AbstractEntity & {
  __typename: 'MultiUserApprovalRule';
  createdAt: Scalars['DateTimeISO']['output'];
  hasPendingRequests: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  minAmount: Scalars['Float']['output'];
  minUsers: Scalars['Int']['output'];
  timeLimitMinutes: Maybe<Scalars['Int']['output']>;
  userRoles: Array<UserRole>;
};

export type MultiUserApprovalRuleInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  minAmount: Scalars['Float']['input'];
  minUsers: Scalars['Int']['input'];
  timeLimitMinutes?: InputMaybe<Scalars['Int']['input']>;
  userRoles: Array<UserRoleInput>;
};

export type MultiUserApprovalSummary = {
  __typename: 'MultiUserApprovalSummary';
  approveAddendum: Maybe<Scalars['Int']['output']>;
  approveInvoice: Maybe<Scalars['Int']['output']>;
  awardBid: Maybe<Scalars['Int']['output']>;
  openBids: Maybe<Scalars['Int']['output']>;
  publishBidPackage: Maybe<Scalars['Int']['output']>;
  sendContract: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename: 'Mutation';
  abandonEditingSession: Scalars['Boolean']['output'];
  acceptTerms: Scalars['Boolean']['output'];
  activateInvitee: LoginTokenResponse;
  addBidRequestNote: Scalars['Boolean']['output'];
  addContactsToBidRequest: AddContactsToBidRequestResponse;
  addFilesToFileTree: Scalars['Boolean']['output'];
  addFolderToFileTree: Scalars['Boolean']['output'];
  addSubcontractorNote: Scalars['Boolean']['output'];
  addTaskNote: Scalars['Boolean']['output'];
  adminActivateInvitee: Scalars['Boolean']['output'];
  adminApproveSignup: Scalars['Boolean']['output'];
  adminApproveSignupByEmail: Scalars['Boolean']['output'];
  adminAssignWorkCategoriesToSubcontractors: Scalars['Boolean']['output'];
  adminCancelInvite: Scalars['Boolean']['output'];
  adminChangeSubcontractorAgentCompany: Scalars['Boolean']['output'];
  adminCreateCompany: Company;
  adminCreateGlobalCertificateType: CertificateType;
  adminCreateNetworkSubcontractor: Subcontractor;
  adminCreateNetworkWorkCategory: WorkCategory;
  adminCreatePassword: Scalars['Boolean']['output'];
  adminCreateWorkCategoryGroup: WorkCategoryGroup;
  adminDeactivateAdmin: Scalars['Boolean']['output'];
  adminDeactivateUser: Scalars['Boolean']['output'];
  adminDeleteGlobalCertificateType: Scalars['Boolean']['output'];
  adminDeleteSignup: Scalars['Boolean']['output'];
  adminDeleteUser: Scalars['Boolean']['output'];
  adminDeleteWorkCategory: Scalars['Boolean']['output'];
  adminDeleteWorkCategoryGroup: Scalars['Boolean']['output'];
  adminDenySignup: Scalars['Boolean']['output'];
  adminEnforceMfaImmediately: Scalars['Boolean']['output'];
  adminGenerateResetPasswordToken: Scalars['String']['output'];
  adminInviteAdmin: Scalars['Boolean']['output'];
  adminMergeAgentCompanies: AdminAgentCompanyMergeResponse;
  adminMergeSubcontractors: AdminSubcontractorMergeResponse;
  adminProcessBackgroundJobs: Scalars['Boolean']['output'];
  adminProcessScheduledEvents: Scalars['Boolean']['output'];
  adminReactivateAdmin: Scalars['Boolean']['output'];
  adminReactivateUser: Scalars['Boolean']['output'];
  adminResendEmail: Scalars['Boolean']['output'];
  adminResetPassword: Scalars['Boolean']['output'];
  adminRestoreDeletedSubcontractor: Scalars['Boolean']['output'];
  adminRestoreInvite: Scalars['Boolean']['output'];
  adminSwapWorkCategoryGroupPositions: Scalars['Boolean']['output'];
  adminUpdateAdminPermissions: Scalars['Boolean']['output'];
  adminUpdateCompany: Company;
  adminUpdateCompanyFeatureFlags: Company;
  adminUpdateCorrespondingNetworkWorkCategories: Scalars['Boolean']['output'];
  adminUpdateGlobalCertificateTypeTranslations: Array<CertificateTypeTranslation>;
  adminUpdateMe: Scalars['Boolean']['output'];
  adminUpdateNetworkSubcontractor: Subcontractor;
  adminUpdateNetworkWorkCategory: WorkCategory;
  adminUpdatePassword: Scalars['Boolean']['output'];
  adminUpdateUserDepartment: Scalars['Boolean']['output'];
  adminUpdateUserEmail: Scalars['Boolean']['output'];
  adminUpdateWorkCategoryGroup: WorkCategoryGroup;
  adminUpdateWorkCategoryTranslations: Array<WorkCategoryTranslation>;
  agentSendPremiumPurchaseRequest: Scalars['Boolean']['output'];
  agentSignupForAwardedBid: LoginTokenResponse;
  agentSignupForBidInvite: LoginTokenResponse;
  agentSignupForBidSubmission: LoginTokenResponse;
  agentSignupForCertificatesSubmission: LoginTokenResponse;
  agentSignupForMarketplaceSuggestion: LoginTokenResponse;
  agentSignupForSubcontractorSentEmail: LoginTokenResponse;
  agentSignupUnsolicited: AgentSignupResponse;
  approveInvoice: Scalars['Boolean']['output'];
  approveJoinRequest: Scalars['Boolean']['output'];
  approveMultiUserApprovalRequest: Scalars['Boolean']['output'];
  awardBidRequest: Scalars['ID']['output'];
  batchCreateNetworkWorkCategories: Array<WorkCategory>;
  bulkAddContacts: BulkAddContactsResponse;
  cancelCertificateRequest: Scalars['Boolean']['output'];
  cancelInvite: Scalars['Boolean']['output'];
  changeBidRequestCustomBidsDueAt: Scalars['Boolean']['output'];
  checkCredentials: CheckCredentialsResponse;
  checkVerificationCode: CheckVerificationCodeResponse;
  claimAnonymousDraftBid: Scalars['Boolean']['output'];
  claimSubcontractorBy: Scalars['Boolean']['output'];
  claimSubcontractorByMarketplaceSuggestion: Scalars['Boolean']['output'];
  claimSubcontractorBySentEmailId: Scalars['Boolean']['output'];
  closeBidPackage: Scalars['Boolean']['output'];
  closeProject: Scalars['Boolean']['output'];
  completeAgentCompanyOnboardItem: Scalars['Boolean']['output'];
  completeContractSigning: Scalars['Boolean']['output'];
  confirmCustomBudgetBillOfQuantitiesHasBeenUploaded: ConfirmBillOfQuantitiesHasBeenUploaded;
  confirmExcelRequestForProposalBoQUploaded: ConfirmRequestBoQHasBeenUploaded;
  confirmExcelTemplateBoQHasBeenUploaded: ConfirmBillOfQuantitiesHasBeenUploaded;
  confirmInviteRequestByInviteeId: User;
  confirmInviteRequestByToken: User;
  confirmProposalBoQUploadedByPrincipalUser: ConfirmBillOfQuantitiesHasBeenUploaded;
  confirmProposalBoQUploadedBySubcontractor: ConfirmBillOfQuantitiesHasBeenUploaded;
  confirmRequestForProposalBoqUploaded: ConfirmRequestBoQHasBeenUploaded;
  confirmTemplateBoQHasBeenUploaded: ConfirmBillOfQuantitiesHasBeenUploaded;
  copyItemsFromAnotherFileTree: Scalars['Boolean']['output'];
  createAgentCompanyCertificate: CreateCertificateResponse;
  createAgentCompanyCertificateType: CertificateType;
  createAnalyticsEvent: Scalars['Boolean']['output'];
  createBidPackage: BidPackage;
  createBidRequestByAgent: BidRequest;
  createBoQTemplate: CreateBoQTemplateResponse;
  /** Creates a new certificate type with the type "company" */
  createCompanyCertificateType: CertificateType;
  createCompanySubcontractor: Subcontractor;
  createCompanyTemplate: CompanyTemplate;
  createContractBoQPDFFromUpload: Scalars['Boolean']['output'];
  createCustomCostGroupCatalog: CreateCustomCostGroupCatalogResponse;
  createDocuSignRecipientViewUrl: Scalars['String']['output'];
  createDocuSignSenderViewUrl: Scalars['String']['output'];
  createDocument: CreateDocumentResponse;
  createDraftAddendum: DraftAddendum;
  createInvoice: Scalars['ID']['output'];
  createInvoiceNote: Scalars['Boolean']['output'];
  createInvoiceUploadUrl: CreateInvoiceUploadUrlResponse;
  createMessageThread: MessageThread;
  createMultiUserApprovalRequest: Scalars['Boolean']['output'];
  createNetworkSubcontractorByAgentUser: Subcontractor;
  createNetworkSubcontractorsImport: NetworkSubcontractorsImportCreatedResponse;
  createOffice: Office;
  createPassword: CreatePasswordResponse;
  createProject: Project;
  createSubcontractorCertificate: CreateCertificateResponse;
  createSubcontractorCertificateDraft: CreateCertificateResponse;
  createSubcontractorImportRequest: CreateSubcontractorImportRequestResponse;
  createSubcontractorPrivateContact: Contact;
  createTask: Task;
  createTerms: CreateTermsResponse;
  deactivateOffice: Scalars['Boolean']['output'];
  deactivateUser: Scalars['Boolean']['output'];
  deleteAddendum: Scalars['Boolean']['output'];
  deleteBid: Scalars['Boolean']['output'];
  deleteBidPackage: Scalars['Boolean']['output'];
  deleteBidRequest: Scalars['Boolean']['output'];
  deleteBidRequestNote: Scalars['Boolean']['output'];
  deleteBoQInBidPackage: EditRequestBoQResponse;
  deleteBoQPositionMassDetermination: Scalars['Boolean']['output'];
  deleteBoQTemplate: Scalars['Boolean']['output'];
  deleteCertificate: Scalars['Boolean']['output'];
  /** Deletes the requested certificate type with the type "company" */
  deleteCompanyCertificateType: Scalars['Boolean']['output'];
  deleteCompanyTemplate: Scalars['Boolean']['output'];
  deleteContactFromBidRequest: Scalars['Boolean']['output'];
  deleteCustomPermissionControlsConfig: Scalars['Boolean']['output'];
  /** @deprecated Deprecated due to simplification of certificate upload flow */
  deleteDraftCertificate: Scalars['Boolean']['output'];
  deleteExternalCredential: Scalars['Boolean']['output'];
  deleteFailedInvite: Scalars['Boolean']['output'];
  deleteFileTreeItem: Scalars['Boolean']['output'];
  deleteInviteRequest: Scalars['Boolean']['output'];
  deleteInvoice: Scalars['Boolean']['output'];
  deleteJoinRequest: Scalars['Boolean']['output'];
  deleteMarketplaceSavedSearch: Scalars['Boolean']['output'];
  deleteNetworkSubcontractor: Scalars['Boolean']['output'];
  deleteOffice: Scalars['Boolean']['output'];
  deleteProject: Scalars['Boolean']['output'];
  deleteSubcontractor: Scalars['Boolean']['output'];
  deleteSubcontractorImportRequest: Scalars['Boolean']['output'];
  deleteSubcontractorNote: Scalars['Boolean']['output'];
  deleteSubcontractorRating: Scalars['Boolean']['output'];
  deleteTask: Scalars['Boolean']['output'];
  deleteTaskNote: Scalars['Boolean']['output'];
  disableCustomEmail: Scalars['Boolean']['output'];
  discardCostEstimateForProject: DiscardCostEstimateForProjectResponse;
  discardDraftBid: Scalars['Boolean']['output'];
  discardDraftBoQ: Scalars['Boolean']['output'];
  discardRequestBillOfQuantities: EditRequestBoQResponse;
  discardSimulationChanges: DiscardSimulationChangesResponse;
  discardTemplateBillOfQuantities: EditTemplateBoQResponse;
  duplicateBidPackage: Scalars['Boolean']['output'];
  duplicateBoQ: Scalars['Boolean']['output'];
  duplicateCustomCostGroupCatalog: Scalars['Boolean']['output'];
  duplicateProject: Scalars['Boolean']['output'];
  editRequestBillOfQuantities: EditRequestBoQResponse;
  editTemplateBillOfQuantities: EditTemplateBoQResponse;
  enableCustomEmail: Scalars['Boolean']['output'];
  exportBids: Scalars['Boolean']['output'];
  fillOutBidBoQ: UpdateBoQFieldResponse;
  fillOutCalculationBoQ: UpdateBoQFieldResponse;
  forwardInvoice: Scalars['Boolean']['output'];
  generateAuthenticatorSecret: AuthenticatorData;
  generateContractBoQ: Scalars['Boolean']['output'];
  generateUploadUrlForBidPackageImport: TemporaryUploadUrlResponse;
  generateUploadUrlForSubcontractorsImport: GenerateUrlAndIdResponse;
  getBoQEditorPreSaveState: BoQEditorPreSaveStateResponse;
  getBoQTemplateBoQEditorPreSaveState: BoQTemplateBoQEditorPreSaveStateResponse;
  getOrCreateDraftBid: DraftBid;
  getOrCreateMarketplaceBidRequest: BidRequest;
  importBidPackage: ImportBidPackageResponse;
  inviteUsers: Array<User>;
  /** @deprecated Use markThreadsAsRead instead */
  markAsRead: Scalars['Boolean']['output'];
  markDocumentAsDownloaded: Scalars['Boolean']['output'];
  markNotificationAsRead: Scalars['Boolean']['output'];
  markNotificationsAsRead: Scalars['Boolean']['output'];
  markTaskAsDone: Task;
  markTaskAsInProgress: Task;
  markTaskAsTodo: Task;
  markThreadsAsRead: Scalars['Boolean']['output'];
  moveFileTreeItem: Scalars['Boolean']['output'];
  negotiateBidRequest: Scalars['Boolean']['output'];
  negotiateSomeBidRequests: Scalars['Boolean']['output'];
  openBlindTenderBids: Scalars['Boolean']['output'];
  publishBidPackage: Scalars['Boolean']['output'];
  reactivateOffice: Scalars['Boolean']['output'];
  reactivateUser: Scalars['Boolean']['output'];
  refreshEditingSession: RefreshEditingSessionResponse;
  rejectBidRequest: Scalars['Boolean']['output'];
  rejectCertificateRequestItem: Scalars['Boolean']['output'];
  rejectInvoice: Scalars['Boolean']['output'];
  rejectMultiUserApprovalRequest: Scalars['Boolean']['output'];
  rejectSomeBidRequests: Scalars['Boolean']['output'];
  removeMfaConfig: Scalars['Boolean']['output'];
  removeOutdatedDocuments: Scalars['Boolean']['output'];
  renameContractFile: Scalars['Boolean']['output'];
  renameFileTreeItem: Scalars['Boolean']['output'];
  reopenBidPackage: Scalars['Boolean']['output'];
  reopenProject: Scalars['Boolean']['output'];
  replyToMessageThread: Message;
  reportAgentCompanyClosed: Scalars['Boolean']['output'];
  reportAgentCompanyClosedBySubcontractor: Scalars['Boolean']['output'];
  requestAVAToolMoreInfo: Scalars['Boolean']['output'];
  requestAdditionalLanguagesUpsell: Scalars['Boolean']['output'];
  requestBidderIntroductionEmailUpsell: Scalars['Boolean']['output'];
  requestCertificatesUpsell: Scalars['Boolean']['output'];
  requestCompanyBrandingUpsell: Scalars['Boolean']['output'];
  requestCompanyTemplatesUpsell: Scalars['Boolean']['output'];
  requestContractExtension: Scalars['Boolean']['output'];
  requestContractsUpsell: Scalars['Boolean']['output'];
  requestCustomAccountTypesUpsell: Scalars['Boolean']['output'];
  requestCustomBidInvitesUpsell: Scalars['Boolean']['output'];
  requestCustomFieldsUpsell: Scalars['Boolean']['output'];
  requestCustomSignaturesUpsell: Scalars['Boolean']['output'];
  requestDataAndAnalyticsUpsell: Scalars['Boolean']['output'];
  requestEditingSession: RequestEditingSessionResponse;
  requestGoldPlanOffer: Scalars['Boolean']['output'];
  requestInternalDocumentManagementUpsell: Scalars['Boolean']['output'];
  requestMarketplaceUsageReport: Scalars['Boolean']['output'];
  requestMfaReset: Scalars['Boolean']['output'];
  requestMultipleOfficesUpsell: Scalars['Boolean']['output'];
  requestNDAUpsell: Scalars['Boolean']['output'];
  /** Generates a verification code for passwordless login. Sends an email containing a verification code and a "magic link" with the code embedded. This mutation is meant for agent users trying to submit a bid. */
  requestPasswordlessLoginCodeForBidSubmission: RequestPasswordlessLoginCodeResponse;
  /** Generates a verification code for passwordless login. Sends an email containing a verification code and a "magic link" with the code embedded. This mutation is meant for agent users trying to add an internal note. */
  requestPasswordlessLoginCodeForInternalNote: RequestPasswordlessLoginCodeResponse;
  /** Generates a verification code for passwordless login. Sends an email containing a verification code and a "magic link" with the code embedded. This mutation is meant for agent users trying to view a task. */
  requestPasswordlessLoginCodeForTask: RequestPasswordlessLoginCodeResponse;
  requestPricingPlanUpsell: Scalars['Boolean']['output'];
  /** @deprecated bid package custom fields upsell hint was removed */
  requestProductIdeaInformation: Scalars['Boolean']['output'];
  requestProfileCompletion: Scalars['Boolean']['output'];
  requestProjectExport: Scalars['Boolean']['output'];
  requestReverseTrialUpsell: Scalars['Boolean']['output'];
  resendOwnConfirmationEmail: Scalars['Boolean']['output'];
  resendUserInvite: Scalars['Boolean']['output'];
  resetContractBoQFileTree: Scalars['Boolean']['output'];
  resetMfaConfig: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  restoreInvite: Scalars['Boolean']['output'];
  revealContactInfo: RevealedContactInfo;
  saveCostEstimateForProject: SaveCostEstimateForProjectResponse;
  saveInProfile: Scalars['Boolean']['output'];
  saveMarketplaceSearch: Scalars['Boolean']['output'];
  savePriceComparisonSimulation: SavePriceComparisonResponse;
  saveRequestBillOfQuantities: EditRequestBoQResponse;
  saveTemplateBillOfQuantities: EditTemplateBoQResponse;
  /** Connects the requested certificate type with the user's company */
  selectCertificateType: CertificateType;
  /** Connects the requested global certificate type with the user's company */
  selectGlobalCertificateType: CertificateType;
  sendBidAwardingAndRejectionEmailsAfterBidRequestWasAwarded: Scalars['Boolean']['output'];
  sendBidRejectionEmailAfterBidRequestWasRejected: Scalars['Boolean']['output'];
  sendCertificateRequest: Scalars['Boolean']['output'];
  sendInvitation: Scalars['Boolean']['output'];
  sendInviteRequests: Array<User>;
  sendMarketplaceSuggestions: Scalars['Boolean']['output'];
  sendVerificationCode: Scalars['Boolean']['output'];
  setBoQPositionMassDetermination: MassDetermination;
  showInterestInMarketplaceBidPackage: BidRequest;
  startTrial: Scalars['Boolean']['output'];
  storeAuthenticatorSecret: CheckVerificationCodeResponse;
  storeMfaPhoneNumber: CheckVerificationCodeResponse;
  storeSimulationChange: StoreSimulationChangesResponse;
  subcontractorSubmitBid: Bid;
  submitBid: Bid;
  submitBidRequest: Scalars['Boolean']['output'];
  submitBiddersSearchRequest: Scalars['Boolean']['output'];
  submitCertificateRequest: Scalars['Boolean']['output'];
  submitContract: Scalars['Boolean']['output'];
  suggestChangesToNetworkSubcontractor: Scalars['Boolean']['output'];
  suggestChangesToNetworkSubcontractorContacts: Scalars['Boolean']['output'];
  suggestNetworkSubcontractorRemoval: Scalars['Boolean']['output'];
  takeOverEditingSession: Scalars['ID']['output'];
  toggleContractDocumentRequiringSignature: TOGGLE_CONTRACT_DOCUMENT_RESULT;
  trackBidInviteView: Scalars['Boolean']['output'];
  trackMarketplaceImpression: Scalars['Boolean']['output'];
  unAwardBidRequest: Scalars['Boolean']['output'];
  unRejectBidRequest: Scalars['Boolean']['output'];
  updateAddendum: Scalars['Boolean']['output'];
  updateAddendumStatus: Scalars['Boolean']['output'];
  updateAwardedBid: AwardedBid;
  updateBidPackage: BidPackage;
  updateBidRequestAgentStatus: BidRequest;
  updateBidRequestByAgent: BidRequest;
  updateBidRequestDeclinedReasonBySubcontractor: Scalars['Boolean']['output'];
  updateBidRequestProposedAwarded: Scalars['Boolean']['output'];
  updateBidRequestStatus: Scalars['Boolean']['output'];
  updateBidRequestStatusBySubcontractor: BidRequest;
  updateBillOfQuantitiesComment: Scalars['Boolean']['output'];
  updateBillOfQuantitiesInternalNote: Scalars['Boolean']['output'];
  updateBoQPositionTextProvider: Scalars['Boolean']['output'];
  updateBoQTemplate: UpdateBoQTemplateResponse;
  updateCertificate: Scalars['Boolean']['output'];
  updateCompany: Company;
  updateCompanyBranding: Company;
  /** Edits the requested certificate type with the type "company" */
  updateCompanyCertificateType: CertificateType;
  updateCompanyMfaConfig: Scalars['Boolean']['output'];
  updateCompanyRatingCategories: Scalars['Boolean']['output'];
  updateCompanySSOConfig: Scalars['Boolean']['output'];
  updateCompanySubcontractor: Subcontractor;
  updateCompanySubcontractorReminderSchedules: Scalars['Boolean']['output'];
  updateCompanyTemplate: CompanyTemplate;
  updateContactNotificationConfigs: Array<ContactNotificationConfig>;
  updateContractRunAgentStepContactIds: Scalars['Boolean']['output'];
  updateContractRunStepUserIds: Scalars['Boolean']['output'];
  updateCostEstimateForProject: UpdateCostEstimateForProjectResponse;
  updateCurrentUserLanguage: Scalars['Boolean']['output'];
  updateCurrentUserNotificationConfig: Scalars['Boolean']['output'];
  updateCustomCostGroupCatalog: CustomCostGroupCatalog;
  updateCustomEmail: Scalars['Boolean']['output'];
  updateCustomFields: Scalars['Boolean']['output'];
  updateDocument: Document;
  updateDraftBid: DraftBid;
  updateInvoice: Scalars['Boolean']['output'];
  updateIsContractSigningEnabled: Scalars['Boolean']['output'];
  updateMarketplaceSavedSearch: MarketplaceSavedSearch;
  updateMe: User;
  updateMessagesSignature: Scalars['Boolean']['output'];
  updateMultiUserApprovalRules: Scalars['Boolean']['output'];
  updateNetworkSubcontractorByAgentUser: Subcontractor;
  updateOffice: Office;
  updateParsedIfcFileWithAssociatedCosts: UpdateParsedIfcFileWithAssociatedCostsResponse;
  updatePassword: Scalars['Boolean']['output'];
  updatePermissionControlsConfig: Scalars['Boolean']['output'];
  updateProject: Project;
  updateSomeBidRequestStatuses: Scalars['Boolean']['output'];
  updateSubcontractorCustomFields: Scalars['Boolean']['output'];
  updateSubcontractorImportRequestStatus: Scalars['Boolean']['output'];
  updateSubcontractorIsBanned: Subcontractor;
  updateSubcontractorIsFavorite: Subcontractor;
  updateSubcontractorPrivateContacts: Subcontractor;
  updateSubcontractorWorkCategories: Scalars['Boolean']['output'];
  updateSubcontractorWorkCategoriesByAgent: Scalars['Boolean']['output'];
  updateTask: Task;
  updateUser: User;
  upsertCompanySubcontractors: LongRunningTask;
  upsertExternalCredential: Scalars['Boolean']['output'];
  upsertNetworkSubcontractor: Subcontractor;
  upsertSubcontractor: Subcontractor;
  upsertSubcontractorRating: SubcontractorRating;
  useTemplateBoQInBidPackage: Scalars['Boolean']['output'];
  validateDNS: Scalars['Boolean']['output'];
  validateNetworkSubcontractorsImport: Array<NetworkSubcontractorsImportValidationResponse>;
  validatePasswordlessLoginCode: ValidatePasswordlessLoginResponse;
  validatePasswordlessLoginToken: ValidatePasswordlessLoginResponse;
  verifyMfaPhoneNumber: Scalars['Boolean']['output'];
  withdrawBidInvite: Scalars['Boolean']['output'];
  withdrawContract: Scalars['Boolean']['output'];
  withdrawMultiUserApprovalRequest: Scalars['Boolean']['output'];
};

export type MutationabandonEditingSessionArgs = {
  sessionId: Scalars['ID']['input'];
};

export type MutationacceptTermsArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  termsId: Scalars['ID']['input'];
};

export type MutationactivateInviteeArgs = {
  input: InviteeInput;
  token: Scalars['String']['input'];
};

export type MutationaddBidRequestNoteArgs = {
  bidRequestId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationaddContactsToBidRequestArgs = {
  bidPackageId: Scalars['ID']['input'];
  contactId?: InputMaybe<Scalars['ID']['input']>;
  customBidsDueAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  skipInvitation: Scalars['Boolean']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type MutationaddFilesToFileTreeArgs = {
  fileTreeAdditionsId: Scalars['ID']['input'];
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
};

export type MutationaddFolderToFileTreeArgs = {
  fileTreeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type MutationaddSubcontractorNoteArgs = {
  subcontractorId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationaddTaskNoteArgs = {
  taskId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationadminActivateInviteeArgs = {
  input: AdminInviteeInput;
  token: Scalars['String']['input'];
};

export type MutationadminApproveSignupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminApproveSignupByEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationadminAssignWorkCategoriesToSubcontractorsArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  subcontractorIds: Array<Scalars['ID']['input']>;
  workCategoryIds: Array<Scalars['ID']['input']>;
};

export type MutationadminCancelInviteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminChangeSubcontractorAgentCompanyArgs = {
  agentCompanyId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type MutationadminCreateCompanyArgs = {
  isTest: Scalars['Boolean']['input'];
  language: LANGUAGE;
  name: Scalars['String']['input'];
  salesforceServiceId?: InputMaybe<Scalars['String']['input']>;
  type: COMPANY_TYPE;
};

export type MutationadminCreateGlobalCertificateTypeArgs = {
  countryCode: COUNTRY_CODE;
  name: Scalars['String']['input'];
};

export type MutationadminCreateNetworkSubcontractorArgs = {
  input: SubcontractorInput;
};

export type MutationadminCreateNetworkWorkCategoryArgs = {
  input: NetworkWorkCategoryInput;
};

export type MutationadminCreatePasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationadminCreateWorkCategoryGroupArgs = {
  input: WorkCategoryGroupInput;
};

export type MutationadminDeactivateAdminArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDeactivateUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDeleteGlobalCertificateTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDeleteSignupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDeleteWorkCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDeleteWorkCategoryGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminDenySignupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminEnforceMfaImmediatelyArgs = {
  companyId: Scalars['ID']['input'];
};

export type MutationadminGenerateResetPasswordTokenArgs = {
  email: Scalars['String']['input'];
};

export type MutationadminInviteAdminArgs = {
  input: AdminInviteAdminInput;
};

export type MutationadminMergeAgentCompaniesArgs = {
  sourceId: Scalars['ID']['input'];
  targetId: Scalars['ID']['input'];
  validateOnly?: Scalars['Boolean']['input'];
};

export type MutationadminMergeSubcontractorsArgs = {
  sourceId: Scalars['ID']['input'];
  targetId: Scalars['ID']['input'];
  validateOnly?: Scalars['Boolean']['input'];
};

export type MutationadminProcessBackgroundJobsArgs = {
  timeToProcessAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type MutationadminProcessScheduledEventsArgs = {
  timeToProcessAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type MutationadminReactivateAdminArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminReactivateUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminResendEmailArgs = {
  id: Scalars['ID']['input'];
  newRecipient?: InputMaybe<Scalars['String']['input']>;
  resendReason: Scalars['String']['input'];
};

export type MutationadminResetPasswordArgs = {
  email: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationadminRestoreDeletedSubcontractorArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminRestoreInviteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminSwapWorkCategoryGroupPositionsArgs = {
  groupAId: Scalars['ID']['input'];
  groupBId: Scalars['ID']['input'];
};

export type MutationadminUpdateAdminPermissionsArgs = {
  id: Scalars['ID']['input'];
  input: AdminAdminPermissionsInput;
};

export type MutationadminUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  input: AdminUpdateCompanyInput;
};

export type MutationadminUpdateCompanyFeatureFlagsArgs = {
  flags: Array<AdminCompanyFeatureFlagsInput>;
  id: Scalars['ID']['input'];
};

export type MutationadminUpdateCorrespondingNetworkWorkCategoriesArgs = {
  companyWorkCategoryId: Scalars['ID']['input'];
  correspondingNetworkWorkCategoryIds: Array<Scalars['ID']['input']>;
};

export type MutationadminUpdateGlobalCertificateTypeTranslationsArgs = {
  translations: Array<TranslationUpdateInput>;
};

export type MutationadminUpdateMeArgs = {
  input: AdminUpdateMeInput;
};

export type MutationadminUpdateNetworkSubcontractorArgs = {
  id: Scalars['ID']['input'];
  input: SubcontractorInput;
  reason?: InputMaybe<Scalars['String']['input']>;
  reviewedSuggestions?: InputMaybe<ReviewedSuggestionsInput>;
};

export type MutationadminUpdateNetworkWorkCategoryArgs = {
  id: Scalars['ID']['input'];
  input: NetworkWorkCategoryInput;
};

export type MutationadminUpdatePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type MutationadminUpdateUserDepartmentArgs = {
  id: Scalars['ID']['input'];
  input: AdminUpdateUserDepartmentInput;
};

export type MutationadminUpdateUserEmailArgs = {
  id: Scalars['ID']['input'];
  input: AdminUpdateUserEmailInput;
};

export type MutationadminUpdateWorkCategoryGroupArgs = {
  id: Scalars['ID']['input'];
  input: WorkCategoryGroupInput;
};

export type MutationadminUpdateWorkCategoryTranslationsArgs = {
  translations: Array<TranslationUpdateInput>;
};

export type MutationagentSendPremiumPurchaseRequestArgs = {
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationagentSignupForAwardedBidArgs = {
  input: AgentSignupForAwardedBidInput;
};

export type MutationagentSignupForBidInviteArgs = {
  input: AgentSignupForBidInviteInput;
};

export type MutationagentSignupForBidSubmissionArgs = {
  input: AgentSignupForBidSubmissionInput;
};

export type MutationagentSignupForCertificatesSubmissionArgs = {
  input: AgentSignupForCertificatesSubmissionInput;
};

export type MutationagentSignupForMarketplaceSuggestionArgs = {
  input: AgentSignupForMarketplaceSuggestionInput;
};

export type MutationagentSignupForSubcontractorSentEmailArgs = {
  input: AgentSignupForSubcontractorSentEmailInput;
};

export type MutationagentSignupUnsolicitedArgs = {
  input: AgentSignupUnsolicitedInput;
};

export type MutationapproveInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationapproveJoinRequestArgs = {
  type?: InputMaybe<STANDARD_USER_TYPE>;
  userId: Scalars['ID']['input'];
};

export type MutationapproveMultiUserApprovalRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationawardBidRequestArgs = {
  input: AwardBidRequestInput;
};

export type MutationbatchCreateNetworkWorkCategoriesArgs = {
  input: Array<WorkCategoryWithTranslationsListInput>;
};

export type MutationbulkAddContactsArgs = {
  amount: BULK_ADD_CONTACTS_AMOUNT;
  bidPackageId: Scalars['ID']['input'];
  customBidsDueAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skipInvitation: Scalars['Boolean']['input'];
  sortBy?: InputMaybe<SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  subcontractorFilters: SubcontractorFiltersInput;
};

export type MutationcancelCertificateRequestArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  certificateTypeId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type MutationcancelInviteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationchangeBidRequestCustomBidsDueAtArgs = {
  bidRequestId: Scalars['ID']['input'];
  customBidsDueAt: Scalars['DateTimeISO']['input'];
};

export type MutationcheckCredentialsArgs = {
  companyType?: InputMaybe<COMPANY_TYPE>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationcheckVerificationCodeArgs = {
  code: Scalars['String']['input'];
  loginToken: Scalars['String']['input'];
};

export type MutationclaimAnonymousDraftBidArgs = {
  bidId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId: Scalars['ID']['input'];
};

export type MutationclaimSubcontractorByArgs = {
  id: Scalars['ID']['input'];
  type: SUBCONTRACTOR_CLAIM_REFERENCE;
};

export type MutationclaimSubcontractorByMarketplaceSuggestionArgs = {
  suggestionId: Scalars['ID']['input'];
};

export type MutationclaimSubcontractorBySentEmailIdArgs = {
  sentEmailId: Scalars['ID']['input'];
};

export type MutationcloseBidPackageArgs = {
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationcloseProjectArgs = {
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
  emailType: PROJECT_CLOSE_EMAIL_TYPE;
  id: Scalars['ID']['input'];
  shouldNotifyBidders: Scalars['Boolean']['input'];
};

export type MutationcompleteAgentCompanyOnboardItemArgs = {
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
};

export type MutationcompleteContractSigningArgs = {
  bidPackageId: Scalars['ID']['input'];
  contractRunAgentStepId?: InputMaybe<Scalars['ID']['input']>;
  signedDocumentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationconfirmCustomBudgetBillOfQuantitiesHasBeenUploadedArgs = {
  bidPackageId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
  uploadKey: Scalars['ID']['input'];
};

export type MutationconfirmExcelRequestForProposalBoQUploadedArgs = {
  bidPackageId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  options: ExcelBoQImportOptions;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  uploadKey: Scalars['ID']['input'];
};

export type MutationconfirmExcelTemplateBoQHasBeenUploadedArgs = {
  filename: Scalars['String']['input'];
  options: ExcelBoQImportOptions;
  uploadKey: Scalars['ID']['input'];
};

export type MutationconfirmInviteRequestByInviteeIdArgs = {
  inviteeId: Scalars['ID']['input'];
};

export type MutationconfirmInviteRequestByTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationconfirmProposalBoQUploadedByPrincipalUserArgs = {
  bidRequestId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  uploadKey: Scalars['ID']['input'];
};

export type MutationconfirmProposalBoQUploadedBySubcontractorArgs = {
  bidRequestId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  uploadKey: Scalars['ID']['input'];
};

export type MutationconfirmRequestForProposalBoqUploadedArgs = {
  bidPackageId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  uploadKey: Scalars['ID']['input'];
};

export type MutationconfirmTemplateBoQHasBeenUploadedArgs = {
  filename: Scalars['String']['input'];
  uploadKey: Scalars['ID']['input'];
};

export type MutationcopyItemsFromAnotherFileTreeArgs = {
  paths: Array<Scalars['String']['input']>;
  sourceAttachable: AttachableInput;
  targetAttachable: AttachableInput;
};

export type MutationcreateAgentCompanyCertificateArgs = {
  certificateTypeId: Scalars['ID']['input'];
  fileSizeBytes: Scalars['Int']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  uploadKey?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcreateAgentCompanyCertificateTypeArgs = {
  name: Scalars['String']['input'];
};

export type MutationcreateAnalyticsEventArgs = {
  input: AnalyticsEventInput;
};

export type MutationcreateBidPackageArgs = {
  input: BidPackageInput;
};

export type MutationcreateBidRequestByAgentArgs = {
  input: BidRequestByAgentInput;
};

export type MutationcreateBoQTemplateArgs = {
  input: BoQTemplateInput;
};

export type MutationcreateCompanyCertificateTypeArgs = {
  isExpiryDateMandatory?: Scalars['Boolean']['input'];
  isSelectedByDefaultWhenRequesting: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  scope?: InputMaybe<CERTIFICATE_TYPE_SCOPE>;
  templateDocumentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcreateCompanySubcontractorArgs = {
  input: SubcontractorInput;
};

export type MutationcreateCompanyTemplateArgs = {
  input: CompanyTemplateCreateInput;
};

export type MutationcreateContractBoQPDFFromUploadArgs = {
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  pdfSettings?: InputMaybe<BidPdfSettings>;
};

export type MutationcreateCustomCostGroupCatalogArgs = {
  customCostGroups: Array<CustomCostGroupDefinitionInput>;
  name: Scalars['String']['input'];
};

export type MutationcreateDocuSignRecipientViewUrlArgs = {
  bidPackageId: Scalars['ID']['input'];
  contractRunAgentStepId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<LANGUAGE>;
};

export type MutationcreateDocuSignSenderViewUrlArgs = {
  bidPackageId: Scalars['ID']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type MutationcreateDocumentArgs = {
  input: CreateDocumentInput;
};

export type MutationcreateDraftAddendumArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationcreateInvoiceArgs = {
  input: CreateInvoiceInput;
};

export type MutationcreateInvoiceNoteArgs = {
  invoiceId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationcreateInvoiceUploadUrlArgs = {
  fileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcreateMessageThreadArgs = {
  authorType: COMPANY_TYPE;
  bidPackageId: Scalars['ID']['input'];
  bidRequestIds: Array<Scalars['ID']['input']>;
  documentIds: Array<Scalars['String']['input']>;
  messageText: Scalars['HTML']['input'];
  subject: Scalars['String']['input'];
};

export type MutationcreateMultiUserApprovalRequestArgs = {
  entityId: Scalars['ID']['input'];
  requestProps?: InputMaybe<MultiUserApprovalRequestPropsInput>;
  ruleId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type MutationcreateNetworkSubcontractorByAgentUserArgs = {
  input: SubcontractorInput;
};

export type MutationcreateNetworkSubcontractorsImportArgs = {
  input: NetworkSubcontractorsImportCreateInput;
};

export type MutationcreateOfficeArgs = {
  input: OfficeInput;
};

export type MutationcreatePasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationcreateProjectArgs = {
  input: ProjectInput;
  projectTemplateInput?: InputMaybe<ProjectTemplateInput>;
};

export type MutationcreateSubcontractorCertificateArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  certificateRequestItemId?: InputMaybe<Scalars['ID']['input']>;
  certificateTypeId: Scalars['ID']['input'];
  fileSizeBytes: Scalars['Int']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  subcontractorId: Scalars['ID']['input'];
  uploadKey?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcreateSubcontractorCertificateDraftArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  certificateRequestItemId?: InputMaybe<Scalars['ID']['input']>;
  certificateTypeId: Scalars['ID']['input'];
  fileSizeBytes: Scalars['Int']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  subcontractorId: Scalars['ID']['input'];
  uploadKey?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcreateSubcontractorImportRequestArgs = {
  input: CreateSubcontractorImportRequestInput;
};

export type MutationcreateSubcontractorPrivateContactArgs = {
  contact: ContactInput;
  id: Scalars['ID']['input'];
};

export type MutationcreateTaskArgs = {
  input: TaskInput;
};

export type MutationcreateTermsArgs = {
  filename: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['ID']['input']>;
  size: Scalars['Int']['input'];
};

export type MutationdeactivateOfficeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeactivateUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteAddendumArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteBidArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteBidPackageArgs = {
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationdeleteBidRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteBidRequestNoteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteBoQInBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationdeleteBoQPositionMassDeterminationArgs = {
  billOfQuantitiesId: Scalars['ID']['input'];
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  boqRowId: Scalars['ID']['input'];
};

export type MutationdeleteBoQTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteCertificateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteCompanyCertificateTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteCompanyTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteContactFromBidRequestArgs = {
  bidInviteId: Scalars['ID']['input'];
};

export type MutationdeleteCustomPermissionControlsConfigArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteDraftCertificateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteExternalCredentialArgs = {
  externalCredentialType: EXTERNAL_CREDENTIAL_TYPE;
};

export type MutationdeleteFailedInviteArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationdeleteFileTreeItemArgs = {
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
};

export type MutationdeleteInviteRequestArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationdeleteInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteJoinRequestArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationdeleteMarketplaceSavedSearchArgs = {
  savedSearchId: Scalars['ID']['input'];
};

export type MutationdeleteNetworkSubcontractorArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationdeleteOfficeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteProjectArgs = {
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationdeleteSubcontractorArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteSubcontractorImportRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteSubcontractorNoteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteSubcontractorRatingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteTaskArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteTaskNoteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdiscardCostEstimateForProjectArgs = {
  costEstimateId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type MutationdiscardDraftBidArgs = {
  bidRequestId: Scalars['ID']['input'];
  draftBidId: Scalars['ID']['input'];
};

export type MutationdiscardDraftBoQArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type MutationdiscardRequestBillOfQuantitiesArgs = {
  bidPackageId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationdiscardSimulationChangesArgs = {
  bidPackageId: Scalars['ID']['input'];
  editingSessionId?: InputMaybe<Scalars['ID']['input']>;
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationdiscardTemplateBillOfQuantitiesArgs = {
  boqTemplateId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationduplicateBidPackageArgs = {
  destinationProjectId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  includeBidPackageTeam: Scalars['Boolean']['input'];
  includeBindingPeriod: Scalars['Boolean']['input'];
  includeBoQ: Scalars['Boolean']['input'];
  includeContacts: Scalars['Boolean']['input'];
  includeDescription: Scalars['Boolean']['input'];
  includeDocuments: Scalars['Boolean']['input'];
  newName: Scalars['String']['input'];
};

export type MutationduplicateBoQArgs = {
  destinationBidPackageId: Scalars['ID']['input'];
  sourceBidPackageId: Scalars['ID']['input'];
};

export type MutationduplicateCustomCostGroupCatalogArgs = {
  catalogId: Scalars['ID']['input'];
};

export type MutationduplicateProjectArgs = {
  input: DuplicateProjectInput;
};

export type MutationeditRequestBillOfQuantitiesArgs = {
  bidPackageId: Scalars['ID']['input'];
  inputs: Array<EditRequestForProposalsBoQInput>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationeditTemplateBillOfQuantitiesArgs = {
  boqTemplateId: Scalars['ID']['input'];
  inputs: Array<EditRequestForProposalsBoQInput>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationexportBidsArgs = {
  bidPackageId: Scalars['ID']['input'];
  boqFileType: BOQ_FILE_TYPE;
};

export type MutationfillOutBidBoQArgs = {
  id: Scalars['ID']['input'];
  input: FillOutBoQInput;
};

export type MutationfillOutCalculationBoQArgs = {
  id: Scalars['ID']['input'];
  input: FillOutBoQInput;
};

export type MutationforwardInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationgenerateAuthenticatorSecretArgs = {
  loginToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationgenerateContractBoQArgs = {
  bidPackageId: Scalars['ID']['input'];
  boqFileType: BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT;
  pdfSettings?: InputMaybe<BidPdfSettings>;
};

export type MutationgenerateUploadUrlForBidPackageImportArgs = {
  filename: Scalars['String']['input'];
};

export type MutationgenerateUploadUrlForSubcontractorsImportArgs = {
  allowDuplicateSubcontractors: Scalars['Boolean']['input'];
  companyId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
};

export type MutationgetBoQEditorPreSaveStateArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationgetBoQTemplateBoQEditorPreSaveStateArgs = {
  boqTemplateId: Scalars['ID']['input'];
};

export type MutationgetOrCreateDraftBidArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type MutationgetOrCreateMarketplaceBidRequestArgs = {
  bidPackageId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type MutationimportBidPackageArgs = {
  projectId: Scalars['ID']['input'];
  storageKey: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationinviteUsersArgs = {
  users: Array<UserToInviteInput>;
};

export type MutationmarkAsReadArgs = {
  messageThreadId: Scalars['ID']['input'];
};

export type MutationmarkDocumentAsDownloadedArgs = {
  id: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type MutationmarkNotificationAsReadArgs = {
  id: Scalars['ID']['input'];
};

export type MutationmarkTaskAsDoneArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationmarkTaskAsInProgressArgs = {
  id: Scalars['ID']['input'];
};

export type MutationmarkTaskAsTodoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationmarkThreadsAsReadArgs = {
  messageThreadIds: Array<Scalars['ID']['input']>;
};

export type MutationmoveFileTreeItemArgs = {
  sourceFileTreeId: Scalars['ID']['input'];
  sourcePath: Scalars['String']['input'];
  targetFileTreeId: Scalars['ID']['input'];
  targetPath: Scalars['String']['input'];
};

export type MutationnegotiateBidRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationnegotiateSomeBidRequestsArgs = {
  bidRequestIds: Array<Scalars['ID']['input']>;
};

export type MutationopenBlindTenderBidsArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationpublishBidPackageArgs = {
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  id: Scalars['ID']['input'];
  introductionEmailContent: Scalars['HTML']['input'];
  introductionEmailRecipients: Array<Scalars['ID']['input']>;
  sendIntroductionEmails: Scalars['Boolean']['input'];
  skipInvitations: Scalars['Boolean']['input'];
};

export type MutationreactivateOfficeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationreactivateUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationrefreshEditingSessionArgs = {
  resourceId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
  type: EDITING_SESSION_TYPE;
};

export type MutationrejectBidRequestArgs = {
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
  id: Scalars['ID']['input'];
  shouldSendNotification: Scalars['Boolean']['input'];
};

export type MutationrejectCertificateRequestItemArgs = {
  certificateRequestItemId: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
};

export type MutationrejectInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationrejectMultiUserApprovalRequestArgs = {
  id: Scalars['ID']['input'];
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationrejectSomeBidRequestsArgs = {
  bidRequestIds: Array<Scalars['ID']['input']>;
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
  shouldSendNotification: Scalars['Boolean']['input'];
};

export type MutationremoveOutdatedDocumentsArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationrenameContractFileArgs = {
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
};

export type MutationrenameFileTreeItemArgs = {
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MutationreopenBidPackageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationreopenProjectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationreplyToMessageThreadArgs = {
  authorType: COMPANY_TYPE;
  documentIds: Array<Scalars['String']['input']>;
  messageText: Scalars['HTML']['input'];
  messageThreadId: Scalars['ID']['input'];
};

export type MutationreportAgentCompanyClosedArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type MutationreportAgentCompanyClosedBySubcontractorArgs = {
  modificationToken: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
};

export type MutationrequestEditingSessionArgs = {
  resourceId: Scalars['ID']['input'];
  type: EDITING_SESSION_TYPE;
};

export type MutationrequestMfaResetArgs = {
  loginToken: Scalars['String']['input'];
};

export type MutationrequestPasswordlessLoginCodeForBidSubmissionArgs = {
  bidPackageId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type MutationrequestPasswordlessLoginCodeForInternalNoteArgs = {
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type MutationrequestPasswordlessLoginCodeForTaskArgs = {
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type MutationrequestPricingPlanUpsellArgs = {
  pricingPlan?: InputMaybe<PRICING_PLAN>;
};

export type MutationrequestProductIdeaInformationArgs = {
  idea: PRODUCT_IDEA_NAME;
};

export type MutationrequestProjectExportArgs = {
  boqType: BOQ_FILE_TYPE;
  projectId: Scalars['ID']['input'];
};

export type MutationresendOwnConfirmationEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationresendUserInviteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationresetContractBoQFileTreeArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationresetMfaConfigArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationresetPasswordArgs = {
  email: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationrestoreInviteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationrevealContactInfoArgs = {
  input: RevealContactInfoInput;
};

export type MutationsaveCostEstimateForProjectArgs = {
  costEstimateId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type MutationsaveInProfileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationsaveMarketplaceSearchArgs = {
  input: MarketplaceSavedSearchInput;
};

export type MutationsavePriceComparisonSimulationArgs = {
  bidPackageId: Scalars['ID']['input'];
  editingSessionId?: InputMaybe<Scalars['ID']['input']>;
  revision?: InputMaybe<Scalars['Int']['input']>;
  saveOptions: SaveSimulationOptionsInput;
};

export type MutationsaveRequestBillOfQuantitiesArgs = {
  bidPackageId: Scalars['ID']['input'];
  isShareableWithBidders?: InputMaybe<Scalars['Boolean']['input']>;
  message: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  shouldNotifyBidders?: InputMaybe<Scalars['Boolean']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationsaveTemplateBillOfQuantitiesArgs = {
  boqTemplateId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationselectCertificateTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationselectGlobalCertificateTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationsendBidAwardingAndRejectionEmailsAfterBidRequestWasAwardedArgs = {
  input: SendAwardingEmailInput;
};

export type MutationsendBidRejectionEmailAfterBidRequestWasRejectedArgs = {
  input: SendRejectionEmailInput;
};

export type MutationsendCertificateRequestArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  certificateTypeIds: Array<Scalars['ID']['input']>;
  contactId: Scalars['ID']['input'];
  dueDate?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['ID']['input'];
};

export type MutationsendInvitationArgs = {
  bidInviteId: Scalars['ID']['input'];
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
};

export type MutationsendInviteRequestsArgs = {
  source: InviteRequestSourceInput;
  users: Array<UserToInviteInput>;
};

export type MutationsendMarketplaceSuggestionsArgs = {
  input: MarketplaceSuggestionsInput;
};

export type MutationsendVerificationCodeArgs = {
  loginToken: Scalars['String']['input'];
};

export type MutationsetBoQPositionMassDeterminationArgs = {
  billOfQuantitiesId: Scalars['ID']['input'];
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  boqPositionRowId: Scalars['ID']['input'];
  input: Array<SetMassDeterminationRowInput>;
  lastUsedLocalId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationshowInterestInMarketplaceBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
  status: MARKETPLACE_BID_REQUEST_STATUS;
  subcontractorId: Scalars['ID']['input'];
};

export type MutationstartTrialArgs = {
  module: TRIAL_MODULE;
};

export type MutationstoreAuthenticatorSecretArgs = {
  code: Scalars['String']['input'];
  loginToken?: InputMaybe<Scalars['String']['input']>;
  secret: Scalars['String']['input'];
};

export type MutationstoreMfaPhoneNumberArgs = {
  code: Scalars['String']['input'];
  loginToken?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type MutationstoreSimulationChangeArgs = {
  bidPackageId: Scalars['ID']['input'];
  editingSessionId?: InputMaybe<Scalars['ID']['input']>;
  input: SimulationChangeInput;
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationsubcontractorSubmitBidArgs = {
  bidId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId: Scalars['ID']['input'];
  input?: InputMaybe<BidInput>;
};

export type MutationsubmitBidArgs = {
  bidRequestId: Scalars['ID']['input'];
  input?: InputMaybe<PrincipalSubmitBidInput>;
};

export type MutationsubmitBidRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationsubmitBiddersSearchRequestArgs = {
  bidPackageId: Scalars['ID']['input'];
  input: BiddersSearchInput;
};

export type MutationsubmitCertificateRequestArgs = {
  certificateId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationsubmitContractArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationsuggestChangesToNetworkSubcontractorArgs = {
  id: Scalars['ID']['input'];
  input: SubcontractorSuggestionInput;
};

export type MutationsuggestChangesToNetworkSubcontractorContactsArgs = {
  contacts: Array<ContactInput>;
  id: Scalars['ID']['input'];
  suggestionComment: Scalars['String']['input'];
};

export type MutationsuggestNetworkSubcontractorRemovalArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationtakeOverEditingSessionArgs = {
  resourceId: Scalars['ID']['input'];
  type: EDITING_SESSION_TYPE;
};

export type MutationtoggleContractDocumentRequiringSignatureArgs = {
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
};

export type MutationtrackBidInviteViewArgs = {
  bidInviteId: Scalars['ID']['input'];
};

export type MutationtrackMarketplaceImpressionArgs = {
  bidPackageId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type MutationunAwardBidRequestArgs = {
  id: Scalars['ID']['input'];
  newStatus: BID_REQUEST_STATUS;
};

export type MutationunRejectBidRequestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationupdateAddendumArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAddendumInput;
};

export type MutationupdateAddendumStatusArgs = {
  id: Scalars['ID']['input'];
  newStatus: ADDENDUM_STATUS;
};

export type MutationupdateAwardedBidArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAwardedBidInput;
};

export type MutationupdateBidPackageArgs = {
  comment?: InputMaybe<Scalars['HTML']['input']>;
  id: Scalars['ID']['input'];
  input: BidPackageInput;
  notifyBidders: Scalars['Boolean']['input'];
};

export type MutationupdateBidRequestAgentStatusArgs = {
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS_FOR_AGENT;
};

export type MutationupdateBidRequestByAgentArgs = {
  id: Scalars['ID']['input'];
  input: BidRequestByAgentInput;
};

export type MutationupdateBidRequestDeclinedReasonBySubcontractorArgs = {
  input: UpdateBidRequestDeclinedReasonByAgentInput;
};

export type MutationupdateBidRequestProposedAwardedArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type MutationupdateBidRequestStatusArgs = {
  declinedReason?: InputMaybe<BID_REQUEST_DECLINED_REASON>;
  declinedReasonOther?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
};

export type MutationupdateBidRequestStatusBySubcontractorArgs = {
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
};

export type MutationupdateBillOfQuantitiesCommentArgs = {
  id: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type MutationupdateBillOfQuantitiesInternalNoteArgs = {
  id: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type MutationupdateBoQPositionTextProviderArgs = {
  id: Scalars['ID']['input'];
  textProvider: BOQ_POSITION_TEXT_PROVIDER;
};

export type MutationupdateBoQTemplateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBoQTemplateInput;
};

export type MutationupdateCertificateArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  issueDate: Scalars['String']['input'];
};

export type MutationupdateCompanyArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type MutationupdateCompanyBrandingArgs = {
  input: CompanyBrandingInput;
};

export type MutationupdateCompanyCertificateTypeArgs = {
  id: Scalars['ID']['input'];
  isExpiryDateMandatory?: Scalars['Boolean']['input'];
  isSelectedByDefaultWhenRequesting: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  scope?: InputMaybe<CERTIFICATE_TYPE_SCOPE>;
  templateDocumentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationupdateCompanyMfaConfigArgs = {
  mfaRequired: Scalars['Boolean']['input'];
};

export type MutationupdateCompanyRatingCategoriesArgs = {
  ratingCategories: Array<RATING_CATEGORY>;
};

export type MutationupdateCompanySSOConfigArgs = {
  identityProviderEntityId: Scalars['String']['input'];
  identityProviderTargetUrl: Scalars['String']['input'];
  signingCertificate: Scalars['String']['input'];
  ssoDomains?: InputMaybe<Array<SSODomainInput>>;
  ssoFilterDomains: Scalars['Boolean']['input'];
  ssoRequired: Scalars['Boolean']['input'];
};

export type MutationupdateCompanySubcontractorArgs = {
  id: Scalars['ID']['input'];
  input: SubcontractorInput;
};

export type MutationupdateCompanySubcontractorReminderSchedulesArgs = {
  forAcceptedInvites: BID_INVITE_REMINDER_SCHEDULE;
  forContracts: CONTRACT_REMINDER_SCHEDULE;
  forInvitesWithNoResponse: BID_INVITE_REMINDER_SCHEDULE;
  id: Scalars['ID']['input'];
};

export type MutationupdateCompanyTemplateArgs = {
  id: Scalars['ID']['input'];
  input: CompanyTemplateUpdateInput;
};

export type MutationupdateContactNotificationConfigsArgs = {
  input: Array<ContactNotificationConfigInput>;
};

export type MutationupdateContractRunAgentStepContactIdsArgs = {
  bidPackageId: Scalars['ID']['input'];
  contactIds: Array<Scalars['ID']['input']>;
};

export type MutationupdateContractRunStepUserIdsArgs = {
  bidPackageId: Scalars['ID']['input'];
  contractRunStepUserIds: Array<Scalars['ID']['input']>;
};

export type MutationupdateCostEstimateForProjectArgs = {
  costEstimateId: Scalars['ID']['input'];
  costGroupLocalId: Scalars['String']['input'];
  input: UpdateCostItemInput;
  sessionId: Scalars['ID']['input'];
};

export type MutationupdateCurrentUserLanguageArgs = {
  language: LANGUAGE;
};

export type MutationupdateCurrentUserNotificationConfigArgs = {
  input: UserNotificationConfigInput;
};

export type MutationupdateCustomCostGroupCatalogArgs = {
  catalogId: Scalars['ID']['input'];
  input: UpdateCustomCostGroupCatalogInput;
};

export type MutationupdateCustomEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationupdateCustomFieldsArgs = {
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
  fields: Array<CustomFieldInput>;
};

export type MutationupdateDocumentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDocumentInput;
};

export type MutationupdateDraftBidArgs = {
  bidRequestId: Scalars['ID']['input'];
  input: BidInput;
};

export type MutationupdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};

export type MutationupdateIsContractSigningEnabledArgs = {
  bidPackageId: Scalars['ID']['input'];
  isContractSigningEnabled: Scalars['Boolean']['input'];
};

export type MutationupdateMarketplaceSavedSearchArgs = {
  id: Scalars['ID']['input'];
  isNotificationEnabled: Scalars['Boolean']['input'];
};

export type MutationupdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationupdateMessagesSignatureArgs = {
  input: CustomSignatureInput;
};

export type MutationupdateMultiUserApprovalRulesArgs = {
  action: MULTI_USER_APPROVAL_ACTION;
  inputs: Array<MultiUserApprovalRuleInput>;
};

export type MutationupdateNetworkSubcontractorByAgentUserArgs = {
  id: Scalars['ID']['input'];
  input: SubcontractorInput;
};

export type MutationupdateOfficeArgs = {
  id: Scalars['ID']['input'];
  input: OfficeInput;
};

export type MutationupdateParsedIfcFileWithAssociatedCostsArgs = {
  documentId: Scalars['ID']['input'];
  input: UpdateParsedIfcFileNodeAssociatedCost;
  nodeLocalId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type MutationupdatePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type MutationupdatePermissionControlsConfigArgs = {
  config: PermissionControlsConfigInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userType: USER_TYPE;
};

export type MutationupdateProjectArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  input: ProjectInput;
  notifyBidders: Scalars['Boolean']['input'];
};

export type MutationupdateSomeBidRequestStatusesArgs = {
  bidRequestIds: Array<Scalars['ID']['input']>;
  status: BID_REQUEST_STATUS;
};

export type MutationupdateSubcontractorCustomFieldsArgs = {
  customFields: Array<CustomFieldValueInput>;
  id: Scalars['ID']['input'];
};

export type MutationupdateSubcontractorImportRequestStatusArgs = {
  id: Scalars['ID']['input'];
  status: SUBCONTRACTOR_IMPORT_REQUEST_STATUS;
};

export type MutationupdateSubcontractorIsBannedArgs = {
  id: Scalars['ID']['input'];
  isBanned: Scalars['Boolean']['input'];
};

export type MutationupdateSubcontractorIsFavoriteArgs = {
  id: Scalars['ID']['input'];
  isFavorite: Scalars['Boolean']['input'];
};

export type MutationupdateSubcontractorPrivateContactsArgs = {
  contacts: Array<ContactInput>;
  id: Scalars['ID']['input'];
};

export type MutationupdateSubcontractorWorkCategoriesArgs = {
  confirmWorkCategoriesModal?: InputMaybe<Scalars['Boolean']['input']>;
  modificationToken: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
};

export type MutationupdateSubcontractorWorkCategoriesByAgentArgs = {
  bidRequestId: Scalars['ID']['input'];
  confirmWorkCategoriesModal?: InputMaybe<Scalars['Boolean']['input']>;
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
};

export type MutationupdateTaskArgs = {
  id: Scalars['ID']['input'];
  input: TaskInput;
};

export type MutationupdateUserArgs = {
  customUserRoleId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: USER_TYPE;
};

export type MutationupsertCompanySubcontractorsArgs = {
  allowDuplicateSubcontractors: Scalars['Boolean']['input'];
  importId: Scalars['ID']['input'];
  importToUpdateId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationupsertExternalCredentialArgs = {
  externalCredentialInput: ExternalCredentialInput;
};

export type MutationupsertNetworkSubcontractorArgs = {
  input: UpsertSubcontractorInput;
};

export type MutationupsertSubcontractorArgs = {
  input: UpsertSubcontractorInput;
};

export type MutationupsertSubcontractorRatingArgs = {
  input: SubcontractorRatingInput;
};

export type MutationuseTemplateBoQInBidPackageArgs = {
  costGroupCatalogsToImport: TemplateBoQCostGroupCatalogsToImportInput;
  destinationBidPackageId: Scalars['ID']['input'];
  fieldsToImport: TemplateBoQFieldsToImportInput;
  sourceBoQTemplateId: Scalars['ID']['input'];
};

export type MutationvalidateNetworkSubcontractorsImportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationvalidatePasswordlessLoginCodeArgs = {
  companyType?: InputMaybe<COMPANY_TYPE>;
  email: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationvalidatePasswordlessLoginTokenArgs = {
  companyType?: InputMaybe<COMPANY_TYPE>;
  token: Scalars['String']['input'];
};

export type MutationverifyMfaPhoneNumberArgs = {
  loginToken?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type MutationwithdrawBidInviteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationwithdrawContractArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type MutationwithdrawMultiUserApprovalRequestArgs = {
  id: Scalars['ID']['input'];
};

export enum NETWORK_SUBCONTRACTOR_IMPORT_GENERAL_VALIDATION_CONSTRAINT {
  empty = 'empty',
  invalid = 'invalid',
}

export enum NETWORK_SUBCONTRACTOR_IMPORT_HEADER_VALIDATION_CONSTRAINT {
  missingColumns = 'missingColumns',
  unknownColumns = 'unknownColumns',
}

export enum NETWORK_SUBCONTRACTOR_IMPORT_OPERATION {
  create = 'create',
  update = 'update',
  upsert = 'upsert',
}

export enum NETWORK_SUBCONTRACTOR_IMPORT_RELATIONS_STRATEGY {
  appendOnly = 'appendOnly',
  override = 'override',
}

export enum NETWORK_SUBCONTRACTOR_IMPORT_ROW_VALIDATION_CONSTRAINT {
  deleted = 'deleted',
  invalidType = 'invalidType',
  invalidValue = 'invalidValue',
  missingIdentifier = 'missingIdentifier',
  missingProperties = 'missingProperties',
  notFound = 'notFound',
}

export enum NETWORK_SUBCONTRACTOR_IMPORT_STATUS {
  created = 'created',
  processing = 'processing',
  processingCompleted = 'processingCompleted',
  validationFailed = 'validationFailed',
  validationSuccessful = 'validationSuccessful',
}

export type NetworkSubcontractor = AbstractEntity & {
  __typename: 'NetworkSubcontractor';
  aboutUs: Maybe<Scalars['String']['output']>;
  certificatesCount: Scalars['Int']['output'];
  city: Maybe<Scalars['String']['output']>;
  companySize: Maybe<COMPANY_SIZE>;
  countryCode: Maybe<COUNTRY_CODE>;
  createdAt: Scalars['DateTimeISO']['output'];
  description: Maybe<Scalars['String']['output']>;
  generatedDescription: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageKeys: Array<Scalars['String']['output']>;
  lat: Maybe<Scalars['Float']['output']>;
  logoKey: Maybe<Scalars['String']['output']>;
  long: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  orderAreaCountryCode: Maybe<COUNTRY_CODE>;
  orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius: Maybe<ORDER_RADIUS>;
  postalCode: Maybe<Scalars['String']['output']>;
  referenceProject: Maybe<SubcontractorReferenceProject>;
  referenceProjects: Array<SubcontractorReferenceProject>;
  slug: Scalars['String']['output'];
  workCategories: Array<WorkCategory>;
};

export type NetworkSubcontractordescriptionArgs = {
  language: LANGUAGE;
};

export type NetworkSubcontractorreferenceProjectArgs = {
  position: Scalars['Int']['input'];
};

export type NetworkSubcontractorWithContactAvailability = AbstractEntity & {
  __typename: 'NetworkSubcontractorWithContactAvailability';
  aboutUs: Maybe<Scalars['String']['output']>;
  certificatesCount: Scalars['Int']['output'];
  city: Maybe<Scalars['String']['output']>;
  companySize: Maybe<COMPANY_SIZE>;
  countryCode: Maybe<COUNTRY_CODE>;
  createdAt: Scalars['DateTimeISO']['output'];
  description: Maybe<Scalars['String']['output']>;
  generatedDescription: Maybe<Scalars['String']['output']>;
  hasEmail: Scalars['Boolean']['output'];
  hasPhone: Scalars['Boolean']['output'];
  hasStreet: Scalars['Boolean']['output'];
  hasWebsite: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageKeys: Array<Scalars['String']['output']>;
  lat: Maybe<Scalars['Float']['output']>;
  logoKey: Maybe<Scalars['String']['output']>;
  long: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  orderAreaCountryCode: Maybe<COUNTRY_CODE>;
  orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius: Maybe<ORDER_RADIUS>;
  postalCode: Maybe<Scalars['String']['output']>;
  referenceProject: Maybe<SubcontractorReferenceProject>;
  referenceProjects: Array<SubcontractorReferenceProject>;
  slug: Scalars['String']['output'];
  workCategories: Array<WorkCategory>;
};

export type NetworkSubcontractorWithContactAvailabilitydescriptionArgs = {
  language: LANGUAGE;
};

export type NetworkSubcontractorWithContactAvailabilityreferenceProjectArgs = {
  position: Scalars['Int']['input'];
};

export type NetworkSubcontractorsByWorkCategoryResponse = {
  __typename: 'NetworkSubcontractorsByWorkCategoryResponse';
  /** This field returns the correct work category object even if it has already a new slug for given language */
  selectedWorkCategory: WorkCategory;
  /** This field returns the correct work category id even if it has already a new slug for given language */
  selectedWorkCategoryId: Scalars['String']['output'];
  subcontractors: Array<NetworkSubcontractor>;
  totalRecords: Scalars['Int']['output'];
};

export type NetworkSubcontractorsImport = AbstractEntity & {
  __typename: 'NetworkSubcontractorsImport';
  createdAt: Scalars['DateTimeISO']['output'];
  dryRun: Maybe<Scalars['Boolean']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  operation: NETWORK_SUBCONTRACTOR_IMPORT_OPERATION;
  percentComplete: Maybe<Scalars['Int']['output']>;
  relationsStrategy: NETWORK_SUBCONTRACTOR_IMPORT_RELATIONS_STRATEGY;
  reportUrl: Maybe<Scalars['String']['output']>;
  skipEmpty: Maybe<Scalars['Boolean']['output']>;
  status: NETWORK_SUBCONTRACTOR_IMPORT_STATUS;
  validateOnly: Maybe<Scalars['Boolean']['output']>;
};

export type NetworkSubcontractorsImportCreateInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  filename: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  operation: NETWORK_SUBCONTRACTOR_IMPORT_OPERATION;
  relationsStrategy: NETWORK_SUBCONTRACTOR_IMPORT_RELATIONS_STRATEGY;
  skipEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NetworkSubcontractorsImportCreatedResponse = AbstractEntity & {
  __typename: 'NetworkSubcontractorsImportCreatedResponse';
  createdAt: Scalars['DateTimeISO']['output'];
  dryRun: Maybe<Scalars['Boolean']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  operation: NETWORK_SUBCONTRACTOR_IMPORT_OPERATION;
  percentComplete: Maybe<Scalars['Int']['output']>;
  relationsStrategy: NETWORK_SUBCONTRACTOR_IMPORT_RELATIONS_STRATEGY;
  reportUrl: Maybe<Scalars['String']['output']>;
  skipEmpty: Maybe<Scalars['Boolean']['output']>;
  status: NETWORK_SUBCONTRACTOR_IMPORT_STATUS;
  uploadUrl: Scalars['String']['output'];
  validateOnly: Maybe<Scalars['Boolean']['output']>;
};

export type NetworkSubcontractorsImportValidationResponse =
  | GeneralValidationConstraintError
  | HeaderValidationConstraintError
  | RowValidationConstraintError;

export type NetworkSubcontractorsResponse = {
  __typename: 'NetworkSubcontractorsResponse';
  subcontractors: Array<NetworkSubcontractor>;
  totalRecords: Scalars['Int']['output'];
};

export type NetworkWorkCategoryInput = {
  groupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  translations: Array<WorkCategoryTranslationInput>;
};

export type NewBidNotification = AbstractEntity &
  Notification & {
    __typename: 'NewBidNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: NewBidNotificationProps;
  };

export type NewBidNotificationProps = {
  __typename: 'NewBidNotificationProps';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Maybe<Scalars['Float']['output']>;
  bidId: Scalars['String']['output'];
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  bidRequestId: Scalars['String']['output'];
  currency: Maybe<CURRENCY>;
  discountRate: Maybe<Scalars['Float']['output']>;
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  skontoDeadline: Maybe<Scalars['Float']['output']>;
  skontoRate: Maybe<Scalars['Float']['output']>;
  subcontractorName: Scalars['String']['output'];
  taxRate: Maybe<Scalars['Float']['output']>;
  teamMemberName: Maybe<Scalars['String']['output']>;
  totalNet: Maybe<Scalars['Float']['output']>;
};

export type NewMessageNotification = AbstractEntity &
  Notification & {
    __typename: 'NewMessageNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: NewMessageNotificationProps;
  };

export type NewMessageNotificationProps = {
  __typename: 'NewMessageNotificationProps';
  authorId: Scalars['String']['output'];
  authorName: Scalars['String']['output'];
  authorType: COMPANY_TYPE;
  bidPackageId: Scalars['String']['output'];
  bidPackageName: Scalars['String']['output'];
  bidRequestId: Scalars['String']['output'];
  messageThreadId: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type Note = {
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  user: Maybe<User>;
};

export type Notification = {
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  isRead: Scalars['Boolean']['output'];
};

export type NotificationsResponse = {
  __typename: 'NotificationsResponse';
  notifications: Array<AnyNotification>;
  totalRecords: Scalars['Int']['output'];
  totalUnread: Scalars['Int']['output'];
};

export type NumberDiff = {
  __typename: 'NumberDiff';
  new: Maybe<Scalars['Float']['output']>;
  old: Maybe<Scalars['Float']['output']>;
};

export enum OFFICE_REQUIRE_TERMS_OPTION {
  none = 'none',
  requiredAlways = 'requiredAlways',
  requiredPerProject = 'requiredPerProject',
}

export enum OFFICE_STATUS {
  active = 'active',
  inactive = 'inactive',
}

export enum ORDER_AREA_TYPE {
  country = 'country',
  radius = 'radius',
  state = 'state',
  worldwide = 'worldwide',
}

export enum ORDER_RADIUS {
  km_20 = 'km_20',
  km_50 = 'km_50',
  km_100 = 'km_100',
  km_200 = 'km_200',
  km_350 = 'km_350',
  km_500 = 'km_500',
  km_750 = 'km_750',
  km_1000 = 'km_1000',
  km_2000 = 'km_2000',
  km_3000 = 'km_3000',
  unlimited = 'unlimited',
}

export enum ORIENTATION {
  landscape = 'landscape',
  portrait = 'portrait',
}

export type Office = AbstractEntity &
  GeocodedGraphQLEntity & {
    __typename: 'Office';
    aboutUs: Maybe<Scalars['HTML']['output']>;
    aboutUsImageKeys: Array<Scalars['String']['output']>;
    acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
    city: Maybe<Scalars['String']['output']>;
    company: Company;
    companySize: Maybe<COMPANY_SIZE>;
    countryCode: Maybe<COUNTRY_CODE>;
    createdAt: Scalars['DateTimeISO']['output'];
    creditreformLink: Maybe<Scalars['String']['output']>;
    currency: CURRENCY;
    currentTerms: Maybe<Terms>;
    email: Maybe<Scalars['String']['output']>;
    fax: Maybe<Scalars['String']['output']>;
    foundingYear: Maybe<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    language: LANGUAGE;
    lat: Maybe<Scalars['Float']['output']>;
    legalDisclosure: Maybe<Scalars['String']['output']>;
    logoKey: Maybe<Scalars['String']['output']>;
    long: Maybe<Scalars['Float']['output']>;
    marketplacePublishedBidPackagesCount: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    ownerName: Maybe<Scalars['String']['output']>;
    phone: Maybe<Scalars['String']['output']>;
    postalCode: Maybe<Scalars['String']['output']>;
    publishToMarketplaceForAwarding: Scalars['Boolean']['output'];
    publishToMarketplaceForEstimation: Scalars['Boolean']['output'];
    registrationCity: Maybe<Scalars['String']['output']>;
    registrationNumber: Maybe<Scalars['String']['output']>;
    registrationPostalCode: Maybe<Scalars['String']['output']>;
    status: OFFICE_STATUS;
    street: Maybe<Scalars['String']['output']>;
    tradeRegisterLink: Maybe<Scalars['String']['output']>;
    website: Maybe<Scalars['String']['output']>;
  };

export enum OfficeAuthActions {
  createProject = 'createProject',
  createTerms = 'createTerms',
  deactivateOrReactivate = 'deactivateOrReactivate',
  delete = 'delete',
  update = 'update',
  view = 'view',
}

export type OfficeAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type OfficeAuthInput = {
  action: OfficeAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OfficeAuthArgsInput>;
};

export type OfficeDetailsIncluded = {
  __typename: 'OfficeDetailsIncluded';
  issue: Maybe<BP_PUBLICATION_OFFICE_DETAILS_INCLUDED_ISSUE>;
};

export type OfficeInput = {
  aboutUs?: InputMaybe<Scalars['HTML']['input']>;
  aboutUsImageKeys: Array<Scalars['String']['input']>;
  acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
  city?: InputMaybe<Scalars['String']['input']>;
  companySize?: InputMaybe<COMPANY_SIZE>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  creditreformLink?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<CURRENCY>;
  currentTermsId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  language: LANGUAGE;
  legalDisclosure?: InputMaybe<Scalars['HTML']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  publishToMarketplaceForAwarding?: InputMaybe<Scalars['Boolean']['input']>;
  publishToMarketplaceForEstimation?: InputMaybe<Scalars['Boolean']['input']>;
  registrationCity?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  registrationPostalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  tradeRegisterLink?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrderAreaTypeDiff = {
  __typename: 'OrderAreaTypeDiff';
  new: Maybe<ORDER_AREA_TYPE>;
  old: Maybe<ORDER_AREA_TYPE>;
};

export type OrderRadiusDiff = {
  __typename: 'OrderRadiusDiff';
  new: Maybe<ORDER_RADIUS>;
  old: Maybe<ORDER_RADIUS>;
};

export type Organization = AbstractEntity & {
  __typename: 'Organization';
  companies: Array<Company>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  identityProviderEntityId: Maybe<Scalars['String']['output']>;
  identityProviderTargetUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  serviceProviderAcsUrl: Scalars['String']['output'];
  serviceProviderEntityId: Scalars['String']['output'];
  signingCertificate: Maybe<Scalars['String']['output']>;
  ssoDirectLoginUrl: Scalars['String']['output'];
  ssoDomains: Maybe<Array<SSODomain>>;
  ssoFilterDomains: Scalars['Boolean']['output'];
  ssoNameId: Scalars['String']['output'];
  ssoRequired: Scalars['Boolean']['output'];
};

export enum PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE {
  error = 'error',
  pending = 'pending',
  success = 'success',
}

export enum PARSED_IFC_FILE_NODE_BASE_QUANTITY_TYPE {
  crossSectionArea = 'crossSectionArea',
  grossArea = 'grossArea',
  grossFootprintArea = 'grossFootprintArea',
  grossVolume = 'grossVolume',
  length = 'length',
  netArea = 'netArea',
  netVolume = 'netVolume',
  netWeight = 'netWeight',
  outerSurfaceArea = 'outerSurfaceArea',
  width = 'width',
}

export type PCTPdfSettings = {
  includeBiddersComments: Scalars['Boolean']['input'];
  includeExecutionDescriptions: Scalars['Boolean']['input'];
  includeInternalNotes: Scalars['Boolean']['input'];
  includeLongDescriptions: Scalars['Boolean']['input'];
  includeNotes: Scalars['Boolean']['input'];
  orientation: ORIENTATION;
};

export enum PREMIUM_ENABLED_REASON {
  organization = 'organization',
  subscription = 'subscription',
}

export enum PRICE_POSITIONS_SORT_FIELD {
  submittedAt = 'submittedAt',
}

export enum PRICE_PREDICTION_CONTEXT {
  proposalDraft = 'proposalDraft',
  requestForProposals = 'requestForProposals',
  requestForProposalsDraft = 'requestForProposalsDraft',
}

export enum PRICE_PREDICTION_EVALUATION_STATUS {
  high = 'high',
  low = 'low',
  within = 'within',
}

export enum PRICING_PLAN {
  bronze = 'bronze',
  enterprise = 'enterprise',
  gold = 'gold',
  silver = 'silver',
}

export enum PRODUCT_IDEA_NAME {
  bidPackageCustomFields = 'bidPackageCustomFields',
}

export enum PROJECTS_SORT_FIELD {
  createdAt = 'createdAt',
  customField = 'customField',
  customer = 'customer',
  endsOn = 'endsOn',
  name = 'name',
  number = 'number',
  officeName = 'officeName',
  primaryUser = 'primaryUser',
  startsOn = 'startsOn',
}

export enum PROJECT_CATEGORY {
  masterAgreement = 'masterAgreement',
  modification = 'modification',
  newBuilding = 'newBuilding',
  other = 'other',
  rehabilitation = 'rehabilitation',
  renovation = 'renovation',
}

export enum PROJECT_CLOSE_EMAIL_TYPE {
  projectClosed = 'projectClosed',
  rejection = 'rejection',
}

export enum PROJECT_COST_CATALOG_TYPE {
  custom = 'custom',
  din276 = 'din276',
  oenorm = 'oenorm',
  stlbWorkCategory = 'stlbWorkCategory',
}

export enum PROJECT_PHASE {
  allPhases = 'allPhases',
  estimation = 'estimation',
  tendering = 'tendering',
}

export enum PROJECT_STATUS {
  closed = 'closed',
  completed = 'completed',
  draft = 'draft',
  published = 'published',
}

export type ParsedIfcFile = AbstractEntity & {
  __typename: 'ParsedIfcFile';
  autodeskObjectId: Scalars['String']['output'];
  autodeskProcessingState: PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE;
  createdAt: Scalars['DateTimeISO']['output'];
  document: Document;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  nodes: Maybe<Array<ParsedIfcFileNode>>;
};

export type ParsedIfcFileNode = {
  __typename: 'ParsedIfcFileNode';
  associatedCost: Maybe<ParsedIfcFileNodeAssociatedCost>;
  baseQuantities: Maybe<Array<ParsedIfcFileNodeBaseQuantity>>;
  id: Scalars['ID']['output'];
  localId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentNodeLocalId: Maybe<Scalars['String']['output']>;
  sourceParsedFileId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type ParsedIfcFileNodeAssociatedCost = {
  __typename: 'ParsedIfcFileNodeAssociatedCost';
  bidPackageId: Maybe<Scalars['ID']['output']>;
  costGroupLocalId: Maybe<Scalars['String']['output']>;
  factor: Maybe<Scalars['Float']['output']>;
  quantity: Maybe<Scalars['Float']['output']>;
  quantityFormula: Maybe<Scalars['String']['output']>;
  taxRate: Maybe<Scalars['Float']['output']>;
  unit: Maybe<Scalars['String']['output']>;
  unitPrice: Maybe<Scalars['Float']['output']>;
};

export type ParsedIfcFileNodeBaseQuantity = {
  __typename: 'ParsedIfcFileNodeBaseQuantity';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  type: PARSED_IFC_FILE_NODE_BASE_QUANTITY_TYPE;
  unit: Maybe<Scalars['String']['output']>;
};

export type ParsedStlbDataResponse = {
  __typename: 'ParsedStlbDataResponse';
  costGroupId: Maybe<Scalars['String']['output']>;
  htmlLongText: Scalars['String']['output'];
  shortText: Scalars['String']['output'];
  standardizedDescription: StandardizedDescriptionResponse;
  unitPrice: Scalars['Float']['output'];
  unitTag: Scalars['String']['output'];
};

export type PartialInvoice = {
  __typename: 'PartialInvoice';
  approvedTotalNet: Scalars['Float']['output'];
  assignee: Maybe<InvoiceAssignee>;
  bidPackage: BidPackage;
  cashDiscountDeadline: Maybe<Scalars['String']['output']>;
  cashDiscountValue: Scalars['Float']['output'];
  customRows: Array<InvoiceCustomRow>;
  discountValue: Scalars['Float']['output'];
  fileId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insuranceValue: Scalars['Float']['output'];
  issuedAt: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
  nonApprovedTotalNet: Scalars['Float']['output'];
  numberExternal: Maybe<Scalars['String']['output']>;
  numberInternal: Maybe<Scalars['String']['output']>;
  partialInvoiceNumber: Scalars['Int']['output'];
  paymentDeadline: Scalars['String']['output'];
  receiptDate: Maybe<Scalars['String']['output']>;
  retentionValue: Scalars['Float']['output'];
  status: INVOICE_STATUS;
  statusChanges: Array<InvoiceStatusChange>;
  subcontractorBic: Scalars['String']['output'];
  subcontractorCity: Scalars['String']['output'];
  subcontractorCountryCode: COUNTRY_CODE;
  subcontractorEmail: Scalars['String']['output'];
  subcontractorIban: Scalars['String']['output'];
  subcontractorName: Scalars['String']['output'];
  subcontractorPhone: Scalars['String']['output'];
  subcontractorPostalCode: Scalars['String']['output'];
  subcontractorStreet: Scalars['String']['output'];
  taxRate: Scalars['Float']['output'];
};

export type PermissionControlsConfig = {
  __typename: 'PermissionControlsConfig';
  bidPackageCreateEditPublish: Scalars['Boolean']['output'];
  bidPackageDelete: Scalars['Boolean']['output'];
  bidPackageInternalDataView: Scalars['Boolean']['output'];
  bidPackageModifyMarketplacePublishing: Scalars['Boolean']['output'];
  bidPackageProjectDocumentsViewDownload: Scalars['Boolean']['output'];
  bidPackageView: Scalars['Boolean']['output'];
  bidPackagesCanDeletePublished: Scalars['Boolean']['output'];
  bidderDeleteBids: Scalars['Boolean']['output'];
  bidderInviteManageBids: Scalars['Boolean']['output'];
  boqDelete: Scalars['Boolean']['output'];
  boqImportEdit: Scalars['Boolean']['output'];
  boqTemplateCreateEdit: Scalars['Boolean']['output'];
  boqTemplateDelete: Scalars['Boolean']['output'];
  certificateTypesCreate: Scalars['Boolean']['output'];
  companyDocumentsViewDownload: Scalars['Boolean']['output'];
  companySubcontractorsExport: Scalars['Boolean']['output'];
  companySubcontractorsView: Scalars['Boolean']['output'];
  contractPrepare: Scalars['Boolean']['output'];
  contractSend: Scalars['Boolean']['output'];
  invoicesDeleteApprovedRejected: Scalars['Boolean']['output'];
  messageSend: Scalars['Boolean']['output'];
  messageView: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
  pctExport: Scalars['Boolean']['output'];
  pctView: Scalars['Boolean']['output'];
  projectCloseReopen: Scalars['Boolean']['output'];
  projectCreateEdit: Scalars['Boolean']['output'];
  projectDelete: Scalars['Boolean']['output'];
  projectInternalDataView: Scalars['Boolean']['output'];
  projectsAllowDeletingWithNonDraftBidPackages: Scalars['Boolean']['output'];
  projectsFromAccessibleOffices: Scalars['Boolean']['output'];
  reportingView: Scalars['Boolean']['output'];
  subcontractorAddEdit: Scalars['Boolean']['output'];
  subcontractorBanFavorite: Scalars['Boolean']['output'];
  subcontractorBidRequestsView: Scalars['Boolean']['output'];
  subcontractorCertificatesRequest: Scalars['Boolean']['output'];
  subcontractorCertificatesUpdateDelete: Scalars['Boolean']['output'];
  subcontractorCertificatesView: Scalars['Boolean']['output'];
  subcontractorDelete: Scalars['Boolean']['output'];
  subcontractorNotesViewUpdateCreate: Scalars['Boolean']['output'];
  subcontractorRate: Scalars['Boolean']['output'];
  userAddEditDeactivate: Scalars['Boolean']['output'];
};

export type PermissionControlsConfigInput = {
  bidPackageCreateEditPublish: Scalars['Boolean']['input'];
  bidPackageDelete: Scalars['Boolean']['input'];
  bidPackageInternalDataView: Scalars['Boolean']['input'];
  bidPackageModifyMarketplacePublishing: Scalars['Boolean']['input'];
  bidPackageProjectDocumentsViewDownload: Scalars['Boolean']['input'];
  bidPackageView: Scalars['Boolean']['input'];
  bidPackagesCanDeletePublished: Scalars['Boolean']['input'];
  bidderDeleteBids: Scalars['Boolean']['input'];
  bidderInviteManageBids: Scalars['Boolean']['input'];
  boqDelete: Scalars['Boolean']['input'];
  boqImportEdit: Scalars['Boolean']['input'];
  boqTemplateCreateEdit: Scalars['Boolean']['input'];
  boqTemplateDelete: Scalars['Boolean']['input'];
  certificateTypesCreate?: InputMaybe<Scalars['Boolean']['input']>;
  companyDocumentsViewDownload: Scalars['Boolean']['input'];
  companySubcontractorsExport: Scalars['Boolean']['input'];
  companySubcontractorsView: Scalars['Boolean']['input'];
  contractPrepare: Scalars['Boolean']['input'];
  contractSend: Scalars['Boolean']['input'];
  invoicesDeleteApprovedRejected: Scalars['Boolean']['input'];
  messageSend: Scalars['Boolean']['input'];
  messageView: Scalars['Boolean']['input'];
  pctExport: Scalars['Boolean']['input'];
  pctView: Scalars['Boolean']['input'];
  projectCloseReopen: Scalars['Boolean']['input'];
  projectCreateEdit: Scalars['Boolean']['input'];
  projectDelete: Scalars['Boolean']['input'];
  projectInternalDataView: Scalars['Boolean']['input'];
  projectsAllowDeletingWithNonDraftBidPackages: Scalars['Boolean']['input'];
  projectsFromAccessibleOffices: Scalars['Boolean']['input'];
  reportingView: Scalars['Boolean']['input'];
  subcontractorAddEdit: Scalars['Boolean']['input'];
  subcontractorBanFavorite: Scalars['Boolean']['input'];
  subcontractorBidRequestsView: Scalars['Boolean']['input'];
  subcontractorCertificatesRequest: Scalars['Boolean']['input'];
  subcontractorCertificatesUpdateDelete: Scalars['Boolean']['input'];
  subcontractorCertificatesView: Scalars['Boolean']['input'];
  subcontractorDelete: Scalars['Boolean']['input'];
  subcontractorNotesViewUpdateCreate: Scalars['Boolean']['input'];
  subcontractorRate: Scalars['Boolean']['input'];
  userAddEditDeactivate: Scalars['Boolean']['input'];
};

export type PositionPricePredictionEvaluation = {
  __typename: 'PositionPricePredictionEvaluation';
  location: Scalars['String']['output'];
  positionId: Scalars['String']['output'];
  prediction: Maybe<PriceRange>;
  rowNumber: Maybe<Scalars['Int']['output']>;
  status: Maybe<PRICE_PREDICTION_EVALUATION_STATUS>;
};

export type PositionTypeDefinition = {
  label: Scalars['String']['input'];
  positionType: BOQ_POSITION_TYPE;
};

export type PriceComparisonOutlierSettings = {
  aboveAverage?: InputMaybe<Scalars['String']['input']>;
  belowAverage?: InputMaybe<Scalars['String']['input']>;
};

export type PriceComparisonSpreadsheetIncludeOptions = {
  bidderComments: Scalars['Boolean']['input'];
  discountsAndSurcharges?: InputMaybe<Scalars['Boolean']['input']>;
  executionDescriptions: Scalars['Boolean']['input'];
  internalNotes: Scalars['Boolean']['input'];
  longDescriptions: Scalars['Boolean']['input'];
  notes: Scalars['Boolean']['input'];
  unitPrices: Scalars['Boolean']['input'];
};

export type PricePosition = AbstractEntity & {
  __typename: 'PricePosition';
  bid: SubmittedBid;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  longText: Maybe<Scalars['String']['output']>;
  shortText: Scalars['String']['output'];
  unitTag: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type PricePositionsResponse = {
  __typename: 'PricePositionsResponse';
  pricePositions: Array<PricePosition>;
  totalRecords: Scalars['Int']['output'];
};

export type PricePredictionConstraint = {
  __typename: 'PricePredictionConstraint';
  gt: Maybe<Scalars['Float']['output']>;
  gte: Maybe<Scalars['Float']['output']>;
  lt: Maybe<Scalars['Float']['output']>;
  lte: Maybe<Scalars['Float']['output']>;
};

export type PricePredictionMeta = {
  __typename: 'PricePredictionMeta';
  supportedPriceRange: PricePredictionConstraint;
  version: Scalars['String']['output'];
};

export type PricePredictionResponse = {
  __typename: 'PricePredictionResponse';
  meta: PricePredictionMeta;
  predictions: Scalars['JSONObject']['output'];
};

export type PriceRange = {
  __typename: 'PriceRange';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type PrimaryContactPersonResponse = {
  __typename: 'PrimaryContactPersonResponse';
  email: Scalars['String']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  role: Maybe<Scalars['String']['output']>;
};

export type PrincipalSubmitBidInput = {
  absoluteDiscount?: InputMaybe<Scalars['Float']['input']>;
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sendConfirmationEmailToSubcontractor?: InputMaybe<Scalars['Boolean']['input']>;
  skontoDeadline?: InputMaybe<Scalars['Int']['input']>;
  skontoRate?: InputMaybe<Scalars['Float']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  totalNet?: InputMaybe<Scalars['Float']['input']>;
};

export type PrintOptions = {
  includeBiddersComments: Scalars['Boolean']['input'];
  includeExecutionDescriptions: Scalars['Boolean']['input'];
  includeInternalNotes: Scalars['Boolean']['input'];
  includeLongDescriptions: Scalars['Boolean']['input'];
  includeNotes: Scalars['Boolean']['input'];
  includeShortDescriptions: Scalars['Boolean']['input'];
};

export type Project = AbstractEntity &
  GeocodedGraphQLEntity & {
    __typename: 'Project';
    bidPackages: Array<BidPackage>;
    /** @deprecated Deprecated due to changes in the reporting tab */
    bidPackagesAddendaTotalGross: Maybe<Scalars['Float']['output']>;
    /** @deprecated Deprecated due to changes in the reporting tab */
    bidPackagesAddendaTotalNet: Maybe<Scalars['Float']['output']>;
    /** @deprecated Deprecated due to changes in the reporting tab */
    bidPackagesAwardedBidTotalGross: Maybe<Scalars['Float']['output']>;
    /** @deprecated Deprecated due to changes in the reporting tab */
    bidPackagesAwardedBidTotalNet: Maybe<Scalars['Float']['output']>;
    bidPackagesCurrencies: Maybe<Array<CURRENCY>>;
    bidPackagesTotalBudget: Maybe<Scalars['Float']['output']>;
    bidRequestsAwaitingResponseCount: Scalars['Int']['output'];
    bidRequestsToNotifyOfDeletionCount: Scalars['Int']['output'];
    billingRunStepUsers: Array<User>;
    budget: Maybe<Scalars['Float']['output']>;
    category: Maybe<PROJECT_CATEGORY>;
    closingConditions: ProjectClosingConditionsResponse;
    contactPersons: Array<ProjectUser>;
    costGroupCatalogs: Array<ProjectCostGroupCatalog>;
    costGroupType: COST_GROUP_TYPE;
    createdAt: Scalars['DateTimeISO']['output'];
    currency: CURRENCY;
    customFields: Maybe<Array<CustomFieldWithValue>>;
    customer: Maybe<Scalars['String']['output']>;
    customerCity: Maybe<Scalars['String']['output']>;
    customerCountryCode: Maybe<COUNTRY_CODE>;
    customerEmail: Maybe<Scalars['String']['output']>;
    customerFax: Maybe<Scalars['String']['output']>;
    customerPhone: Maybe<Scalars['String']['output']>;
    customerPostalCode: Maybe<Scalars['String']['output']>;
    customerStreet: Maybe<Scalars['String']['output']>;
    customerWebsite: Maybe<Scalars['String']['output']>;
    defaultTaxRate: Maybe<Scalars['Float']['output']>;
    description: Maybe<Scalars['String']['output']>;
    desiredSustainabilityCertification: Maybe<Scalars['String']['output']>;
    endsOn: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    imageKeys: Array<Scalars['String']['output']>;
    isEstimating: Scalars['Boolean']['output'];
    isTermsAcceptanceRequired: Scalars['Boolean']['output'];
    lat: Maybe<Scalars['Float']['output']>;
    location: Scalars['String']['output'];
    locationCountryCode: Maybe<COUNTRY_CODE>;
    locationStateCode: Maybe<STATE_CODE>;
    long: Maybe<Scalars['Float']['output']>;
    marketplacePublishedBidPackagesCount: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    notes: Maybe<Scalars['String']['output']>;
    number: Maybe<Scalars['String']['output']>;
    office: Office;
    preferredBoQFileFormat: BOQ_FILE_FORMAT;
    primaryUser: Maybe<User>;
    projectUsers: Array<ProjectUser>;
    requireAcceptingTerms: Scalars['Boolean']['output'];
    startsOn: Maybe<Scalars['String']['output']>;
    status: PROJECT_STATUS;
  };

export type ProjectbidRequestsAwaitingResponseCountArgs = {
  ignoreDueDate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectcustomFieldsArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ProjectAuthActions {
  close = 'close',
  createBidPackage = 'createBidPackage',
  createBidPackage__withoutSelf = 'createBidPackage__withoutSelf',
  delete = 'delete',
  duplicate = 'duplicate',
  exportAllData = 'exportAllData',
  reopen = 'reopen',
  update = 'update',
  update__internalData = 'update__internalData',
  view = 'view',
  view__bim = 'view__bim',
  view__costAnalysis = 'view__costAnalysis',
  view__costEstimation = 'view__costEstimation',
  view__documents = 'view__documents',
  view__internalData = 'view__internalData',
  view__reportingData = 'view__reportingData',
  view__teams = 'view__teams',
}

export type ProjectAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type ProjectAuthInput = {
  action: ProjectAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ProjectAuthArgsInput>;
};

export type ProjectClosingConditionsResponse = {
  __typename: 'ProjectClosingConditionsResponse';
  canSendNotification: Scalars['Boolean']['output'];
  mustNotifyBidders: Scalars['Boolean']['output'];
  shouldWarnAboutUnawardedBidders: Scalars['Boolean']['output'];
};

export type ProjectCostGroupCatalog = {
  __typename: 'ProjectCostGroupCatalog';
  catalogType: PROJECT_COST_CATALOG_TYPE;
  costGroupDefinitions: Array<CostGroupDefinition>;
  customCatalog: Maybe<CustomCostGroupCatalog>;
  customCatalogId: Maybe<Scalars['String']['output']>;
  customCatalogName: Maybe<Scalars['String']['output']>;
};

export type ProjectCostGroupCatalogInput = {
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProjectCostGroupCatalogsResponse = {
  __typename: 'ProjectCostGroupCatalogsResponse';
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalogId: Maybe<Scalars['String']['output']>;
  customCatalogName: Maybe<Scalars['String']['output']>;
};

export type ProjectExportCompleteNotification = AbstractEntity &
  Notification & {
    __typename: 'ProjectExportCompleteNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: ProjectExportCompleteNotificationProps;
  };

export type ProjectExportCompleteNotificationProps = {
  __typename: 'ProjectExportCompleteNotificationProps';
  downloadUrl: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type ProjectInput = {
  billingRunStepUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  budget?: InputMaybe<Scalars['Float']['input']>;
  category?: InputMaybe<PROJECT_CATEGORY>;
  costGroupCatalogs: Array<ProjectCostGroupCatalogInput>;
  currency?: InputMaybe<CURRENCY>;
  customFields?: InputMaybe<Array<CustomFieldValueInput>>;
  customer: Scalars['String']['input'];
  customerCity?: InputMaybe<Scalars['String']['input']>;
  customerCountryCode?: InputMaybe<COUNTRY_CODE>;
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  customerFax?: InputMaybe<Scalars['String']['input']>;
  customerPhone?: InputMaybe<Scalars['String']['input']>;
  customerPostalCode?: InputMaybe<Scalars['String']['input']>;
  customerStreet?: InputMaybe<Scalars['String']['input']>;
  customerWebsite?: InputMaybe<Scalars['String']['input']>;
  defaultTaxRate?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredSustainabilityCertification?: InputMaybe<Scalars['String']['input']>;
  endsOn?: InputMaybe<Scalars['String']['input']>;
  imageKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  isEstimating: Scalars['Boolean']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  location: Scalars['String']['input'];
  long?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['ID']['input'];
  preferredBoQFileFormat: BOQ_FILE_FORMAT;
  primaryUserId: Scalars['ID']['input'];
  projectUsers: Array<ProjectUserInput>;
  requireAcceptingTerms?: Scalars['Boolean']['input'];
  startsOn?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectTemplateInput = {
  includeBidPackageTeam?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesBidderList?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesBoQ?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesDescription?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  includeBidPackagesNumbering?: InputMaybe<Scalars['Boolean']['input']>;
  includeCustomFields?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectDescription?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectEstimatedCost?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectImages?: InputMaybe<Scalars['Boolean']['input']>;
  includeProjectTeam?: InputMaybe<Scalars['Boolean']['input']>;
  templateProjectId: Scalars['ID']['input'];
};

export type ProjectUser = AbstractEntity & {
  __typename: 'ProjectUser';
  createdAt: Scalars['DateTimeISO']['output'];
  hasPendingMultiUserApprovalRequest: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isContactPerson: Scalars['Boolean']['output'];
  user: User;
  userRole: Scalars['String']['output'];
};

export type ProjectUserInput = {
  isContactPerson: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
  userRole: Scalars['String']['input'];
};

export type ProjectsResponse = {
  __typename: 'ProjectsResponse';
  hasAnyOpenProjects: Maybe<Scalars['Boolean']['output']>;
  projects: Array<Project>;
  totalRecords: Scalars['Int']['output'];
};

export type PublicNetworkSubcontractorProfile = AbstractEntity & {
  __typename: 'PublicNetworkSubcontractorProfile';
  certificateTypesReport: Array<CertificateTypeReport>;
  /** @deprecated Deprecated in favor of certificateTypesReport */
  certificates: Array<Certificate>;
  city: Maybe<Scalars['String']['output']>;
  companySize: Maybe<COMPANY_SIZE>;
  countryCode: Maybe<COUNTRY_CODE>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  isClaimedByAgentCompany: Scalars['Boolean']['output'];
  logoKey: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postalCode: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
  workCategories: Array<WorkCategoryForSubcontractor>;
};

export type PublicNetworkSubcontractorProfileWithMergedIntoRedirect =
  | MergedIntoSubcontractor
  | PublicNetworkSubcontractorProfile;

export type PublicSubcontractorProfile = AbstractEntity & {
  __typename: 'PublicSubcontractorProfile';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  isClaimedByAgentCompany: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type QuantityComponent = {
  __typename: 'QuantityComponent';
  result: Scalars['Float']['output'];
};

export type Query = {
  __typename: 'Query';
  addendum: Addendum;
  addendums: AddendumsResponse;
  adminAdmins: AdminsResponse;
  adminAnalyticsEventsForTests: Array<AnalyticsEvent>;
  adminBidPackageBillOfQuantitiesRevisions: AdminBillOfQuantitiesRevisionsResponse;
  adminBidPackages: BidPackagesResponse;
  adminBidRequests: BidRequestsResponse;
  adminBillOfQuantities: AdminBillOfQuantitiesResponse;
  adminCheckResetPasswordToken: Scalars['Boolean']['output'];
  adminCompanies: CompaniesResponse;
  adminCompany: Company;
  adminCompanySubcontractors: AdminCompanySubcontractorsResponse;
  adminCompanyWorkCategories: WorkCategoriesResponse;
  adminDownloadUrlForBidPackageBoQPdf: Scalars['String']['output'];
  adminEmailTemplates: AdminEmailTemplatesResponse;
  adminGlobalCertificateTypes: AdminCertificateTypesResponse;
  adminInvitation: AdminInvitationResponse;
  adminMe: Maybe<Admin>;
  adminNetworkSubcontractorVersions: Array<SubcontractorVersion>;
  adminNetworkWorkCategories: Array<WorkCategory>;
  adminNetworkWorkCategory: WorkCategory;
  adminProjects: ProjectsResponse;
  adminSentEmail: SentEmail;
  adminSentEmailEventsForTests: Array<SentEmailEvent>;
  adminSentEmails: SentEmailsResponse;
  adminSentEmailsForTests: Array<SentEmail>;
  adminSignups: SignupsResponse;
  adminSubcontractor: SubcontractorForAdmin;
  adminSubcontractorBidRequests: BidRequestsResponse;
  adminSubcontractorImportRequests: AdminSubcontractorsImportRequestsResponse;
  adminSubcontractors: AdminSubcontractorsResponse;
  adminUser: User;
  adminUsers: AdminUsersResponse;
  adminWorkCategoryGroup: WorkCategoryGroup;
  adminWorkCategoryGroups: Array<WorkCategoryGroup>;
  /** Returns the list of certificate types which are available for user */
  agentCertificateTypes: Array<CertificateType>;
  agentCertificateTypesReport: Array<CertificateTypeReport>;
  agentCompanyOnboarding: Array<AgentCompanyOnboardIncompleteItem>;
  agentCompanyProfilePrefill: AgentCompanyProfilePrefillResponse;
  applicableMultiUserApprovalRule: Maybe<MultiUserApprovalRule>;
  authorize: Scalars['Boolean']['output'];
  authorizeAdmin: Scalars['Boolean']['output'];
  authorizeMultiple: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Returns a list of global certificate types that can be used by a subcontractor */
  availableGlobalCertificateTypes: Array<CertificateType>;
  awardedBidForAgent: AwardedBid;
  awardedBidForBidPackage: Maybe<AwardedBid>;
  awardedBidsForAgent: AwardedBidsForAgentResponse;
  bid: SubmittedBid;
  bidInvite: BidInvite;
  bidInviteDefaultIntro: Scalars['String']['output'];
  bidInvitePreview: TemplatePreview;
  bidPackage: BidPackage;
  bidPackageBoQEditorActions: BidPackageEditorActionsResponse;
  bidPackageChangedBiddersNotificationPreview: TemplatePreview;
  bidPackageChangedDefaultTemplate: Scalars['String']['output'];
  bidPackageCompletionStatus: Array<BidPackageCompletionResponse>;
  bidPackageListForMarketplace: BidPackagesForMarketplaceResponse;
  bidPackages: BidPackagesResponse;
  bidPackagesAwaitingResponseCount: Scalars['Int']['output'];
  bidPackagesPublishedOnMarketplaceCount: Scalars['Int']['output'];
  bidRequest: BidRequest;
  bidRequestForSubcontractor: BidRequest;
  bidRequestLandingPreviewBidPackage: BidPackage;
  bidRequests: BidRequestsResponse;
  bidRequestsForAgent: BidRequestsResponse;
  bidRequestsForAgentCounts: BidRequestsForAgentCountsResponse;
  bidderStatuses: Array<BidderStatus>;
  bids: BidsResponse;
  billOfQuantitiesForBidPackage: Maybe<BillOfQuantities>;
  billOfQuantitiesForBidRequest: Maybe<BillOfQuantities>;
  billOfQuantitiesForEditing: BillOfQuantitiesForEditingResponse;
  billOfQuantitiesFormalReview: BoQFormalReviewResponse;
  boqChangedNotificationDefaultTemplate: Scalars['String']['output'];
  boqChangedNotificationPreview: TemplatePreview;
  boqTemplate: BoQTemplate;
  boqTemplateBoQ: Maybe<BillOfQuantities>;
  boqTemplateBoQForEditing: BillOfQuantitiesForEditingResponse;
  boqTemplates: BoQTemplatesResponse;
  boqUploadUrl: FileUploadResponse;
  /** Returns a string "certificateOwner_certificateTypeName_currentDate.fileType". This query implies that a Certificate entity does not exist yet. For an existing certificate, please use "fileName" field resolver. */
  certificateFileName: Scalars['String']['output'];
  certificateRequest: Maybe<CertificateRequest>;
  certificateRequestsForAgent: CertificateRequestsResponse;
  certificateUploadUrl: FileUploadResponse;
  /** @deprecated Replaced with more detailed calculation on frontend side */
  certificatesCountForSubcontractor: Scalars['Int']['output'];
  changedElementIdsSinceLatestSubmittedBid: Array<Scalars['String']['output']>;
  checkResetPasswordToken: CheckResetPasswordTokenResponse;
  checkWorkspace: LoginTokenResponse;
  /** Returns certificate types created by the principal company, combined with certificate types created by the requested subcontractor; field "certificates" consists of certificates from both sources, thus other certificate-related fields (such as "status" and "maxExpiryDate") are calculated based on certificates from both sources */
  combinedCertificateTypesReport: Array<CertificateTypeReport>;
  combinedFileTreeWithDocuments: CombinedFileTreeResponse;
  company: Company;
  /** Returns all certificate types with the type "company"; "company" certificate types are created by principal users */
  companyCertificateTypes: Array<CertificateType>;
  companyOnboarding: Array<CompanyOnboardingResponse>;
  companyTemplatePreview: TemplatePreview;
  companyTemplates: CompanyTemplatesResponse;
  contractBoQContent: Scalars['JSONObject']['output'];
  contractRunAgentStep: ContractRunAgentStep;
  costAnalysis: CostAnalysisResponse;
  costComparison: CostComparisonResponse;
  costEstimatesForProject: CostEstimatesForProjectResponse;
  costGroupDefinitions: CostGroupDefinitionResponse;
  countryNetworkSubcontractorsCount: Scalars['Float']['output'];
  customCostGroupCatalog: CustomCostGroupCatalog;
  customCostGroupCatalogs: CustomCostGroupCatalogsResponse;
  customEmailState: CustomEmailState;
  customFields: Array<CustomField>;
  customFieldsUsage: Array<CustomFieldsUsage>;
  customFieldsWithInitialValues: Maybe<Array<CustomFieldWithValue>>;
  defaultBidderIntroductionTemplateContent: Scalars['String']['output'];
  /** Returns the first available grouping+breakdown for the cost analysis table with DIN 276 being the highest priority option. Grouping+breakdown is considered available if the cost analysis has non-zero value at any level of the tree for any cost item, including project, bid package, and all selected cost groups. Returns null if nothing is available. */
  defaultCostAnalysisBreakdown: Maybe<CostAnalysisDefaultBreakdownResponse>;
  document: Document;
  downloadURLForBillOfQuantities: Scalars['String']['output'];
  downloadUrlForBidPackageBoQPdf: Scalars['String']['output'];
  downloadUrlForBidPackageDocuments: Scalars['String']['output'];
  downloadUrlForBidPdf: Scalars['String']['output'];
  downloadUrlForBidRequestInNegotiationDocuments: Scalars['String']['output'];
  downloadUrlForBidderStatusHistoryPdf: Scalars['String']['output'];
  downloadUrlForBiddersListPdf: Scalars['String']['output'];
  downloadUrlForContractBoQ: Scalars['String']['output'];
  downloadUrlForCostAnalysisSpreadsheet: Scalars['String']['output'];
  downloadUrlForCostComparisonSpreadsheet: Scalars['String']['output'];
  downloadUrlForCostEstimationSpreadsheet: Scalars['String']['output'];
  downloadUrlForOverviewPdf: Scalars['String']['output'];
  downloadUrlForPriceComparisonPdf: Scalars['String']['output'];
  downloadUrlForPriceComparisonSpreadsheet: Scalars['String']['output'];
  downloadUrlForProjectDocuments: Scalars['String']['output'];
  downloadUrlForProjectOverviewPdf: Scalars['String']['output'];
  downloadUrlForRequestForProposalBoQPdf: Scalars['String']['output'];
  /** @deprecated This query has the side effect of creating a draft bid if none exists. Prefer using sideEffectFreeDraftBid and getOrCreateDraftBid instead. */
  draftBid: DraftBid;
  fileTreeAdditionsUploadData: FileTreeAdditionsUploadDataResponse;
  fileTreeWithDocuments: FileTreeResponse;
  findSimilarBidPackageNames: Array<Scalars['String']['output']>;
  germanRegistrationCourts: Array<RegistrationCourt>;
  getBoQPositionMassDeterminationForEditing: Maybe<MassDetermination>;
  getExternalCredentials: Array<ExternalCredentialResponse>;
  getSalesforceAccountDetails: SalesforceAccountDetailsForAdmin;
  hasImportedSubcontractors: Scalars['Boolean']['output'];
  heinzeAtBoQElements: Array<Scalars['JSONObject']['output']>;
  heinzeAtGuiUrl: HeinzeGuiUrlResponse;
  heinzeVobBoQElements: Array<Scalars['JSONObject']['output']>;
  heinzeVobGuiUrl: HeinzeGuiUrlResponse;
  imageUploadUrl: ImageUploadUrlResponse;
  incompleteCertificateRequestsCountForAgent: Scalars['Float']['output'];
  invitation: InvitationResponse;
  invoice: Invoice;
  invoiceCoverSheetUrl: Scalars['String']['output'];
  invoiceNotes: InvoiceNotesResponse;
  invoices: InvoicesResponse;
  isCompanyWaitingForSignupApproval: Scalars['Boolean']['output'];
  lBHochbauTextProviderElementsTree: LbHochbauTextProviderElementsTreeResponse;
  lastCertificateRequest: Maybe<CertificateRequest>;
  lbHochbauRestrictedItemNumbers: LbHochbauRestrictedItemNumbersResponse;
  longRunningTask: LongRunningTask;
  marketplaceBidPackageSuggestions: Array<MarketplaceBidPackageSample>;
  marketplaceBidPackagesForMap: BidPackagesList;
  marketplaceBidSubmissionsCount: Scalars['Int']['output'];
  marketplaceSavedSearches: Array<MarketplaceSavedSearch>;
  marketplaceSuggestion: MarketplaceSuggestion;
  marketplaceSuggestionPreview: MarketplaceSuggestionPreviewResponse;
  marketplaceSuggestions: MarketplaceSuggestionsResponse;
  me: User;
  messageThread: MessageThread;
  messageThreads: MessageThreadResponse;
  messageThreadsForSubcontractor: MessageThreadResponse;
  messageThreadsInBidPackage: MessageThreadResponse;
  messageThreadsInBidRequest: MessageThreadResponse;
  multiUserApprovalRequest: Maybe<MultiUserApprovalRequest>;
  multiUserApprovalRules: Array<MultiUserApprovalRule>;
  multiUserApprovalSummary: MultiUserApprovalSummary;
  myTasksInStatusCount: Scalars['Int']['output'];
  networkSubcontractor: Maybe<ExtendedNetworkSubcontractorWithMergedIntoRedirect>;
  networkSubcontractors: NetworkSubcontractorsResponse;
  networkSubcontractorsByWorkCategorySlug: NetworkSubcontractorsByWorkCategoryResponse;
  networkSubcontractorsImport: NetworkSubcontractorsImport;
  notifications: NotificationsResponse;
  office: Office;
  offices: Array<Office>;
  organizations: Array<Organization>;
  parsedCompanyTemplatesByType: Array<CompanyTemplate>;
  parsedIfcFile: ParsedIfcFile;
  parsedIfcFileWithAssociatedCosts: ParsedIfcFile;
  permissionControlsConfig: PermissionControlsConfig;
  predictPriceForBidPackage: PricePredictionResponse;
  previewBidConfirmationEmailAfterPrincipalSubmission: TemplatePreview;
  pricePositions: PricePositionsResponse;
  project: Project;
  projectChangedBiddersNotificationPreview: TemplatePreview;
  projectCostGroupCatalogs: Array<ProjectCostGroupCatalogsResponse>;
  projectRatingsPerBidRequest: Array<SubcontractorRatingPerBidRequest>;
  projects: ProjectsResponse;
  projectsByIds: Array<Project>;
  projectsForDuplicatingBidPackages: ProjectsResponse;
  publicNetworkSubcontractorProfile: PublicNetworkSubcontractorProfileWithMergedIntoRedirect;
  publicSubcontractorProfile: PublicSubcontractorProfile;
  recentMarketplaceBidRequestsCreationDates: Array<Scalars['DateTimeISO']['output']>;
  refreshStlbSession: StlbSessionResponse;
  reverseMarketplaceBidPackage: BidPackage;
  searchSalesforceAccounts: Array<SalesforceAccount>;
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.agentCertificateTypesReport */
  selectedGlobalCertificateTypes: Array<CertificateType>;
  sentEmailStatistics: Maybe<SentEmailStatisticsResponse>;
  shouldPromptWorkCategoriesUpdate: Scalars['Boolean']['output'];
  sideEffectFreeDraftBid: Maybe<DraftBid>;
  similarSubcontractors: SimilarSubcontractorsResponse;
  simulationChanges: SimulationChanges;
  ssoRedirectUrl: Scalars['String']['output'];
  stlbGetData: ParsedStlbDataResponse;
  stlbHomeUrl: Scalars['String']['output'];
  stlbWorkCategoryDefinitions: StlbWorkCategoryDefinitionResponse;
  subcontractor: Subcontractor;
  subcontractorBySentEmail: Maybe<Subcontractor>;
  subcontractorForProfile: SubcontractorWithMergedToRedirect;
  subcontractorForSuggestion: Subcontractor;
  subcontractorImportRequests: Array<SubcontractorImportRequest>;
  subcontractorLoginSuggestions: Array<LoginSuggestion>;
  subcontractorRatingByBidRequest: Array<SubcontractorRating>;
  subcontractorRatingPerBidRequest: Array<SubcontractorRatingPerBidRequest>;
  subcontractorRatingSummary: SubcontractorRatingSummaryResponse;
  subcontractorReferenceProject: SubcontractorReferenceProject;
  /** @deprecated Deprecated in favor of CertificateTypeReportResolver.combinedCertificateTypesReport */
  subcontractorSelectedGlobalCertificateTypes: Array<CertificateType>;
  subcontractorType: SUBCONTRACTOR_TYPE;
  subcontractors: SubcontractorsResponse;
  subcontractorsByAgent: Array<Subcontractor>;
  subcontractorsImportStatus: SubcontractorsImportStatusResponse;
  subcontractorsImports: SubcontractorsImportsResponse;
  /** Returns a list of global certificate types that can be used by a general contractor */
  suggestedGlobalCertificateTypes: SuggestedGlobalCertificateTypesResponse;
  task: Task;
  taskNotes: TaskNotesResponse;
  taskPublicData: TaskPublicDataResponse;
  tasks: TasksResponse;
  termsForBidRequest: Terms;
  topWorkCategories: Array<WorkCategory>;
  totalAvailablePricePositions: Scalars['Int']['output'];
  unreadMessageThreadsCount: Scalars['Float']['output'];
  users: UsersResponse;
  usersWithAccessToBidPackage: Array<User>;
  usersWithAccessToProject: Array<User>;
  validateBidMainOrderNumber: ValidateBidMainOrderNumberResponse;
  validateCostAnalysisForProject: CostAnalysisForProjectValidationResponse;
  validateSubcontractorsImport: SubcontractorsImportValidationResponse;
  validateWorkCategoryWithTranslationInput: Scalars['Boolean']['output'];
  workCategories: WorkCategoriesResponse;
  workCategorySuggestions: Array<WorkCategorySuggestion>;
};

export type QueryaddendumArgs = {
  id: Scalars['ID']['input'];
};

export type QueryaddendumsArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sortBy?: InputMaybe<ADDENDUM_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminAdminsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ADMINS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminBidPackageBillOfQuantitiesRevisionsArgs = {
  bidPackageId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ADMIN_BID_PACKAGE_BILL_OF_QUANTITIES_REVISIONS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminBidPackagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyInBoQContents?: InputMaybe<Scalars['Boolean']['input']>;
  onlyMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ADMIN_BID_PACKAGES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminBidRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminBillOfQuantitiesArgs = {
  id: Scalars['ID']['input'];
};

export type QueryadminCheckResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryadminCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<COMPANIES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  type?: InputMaybe<COMPANY_TYPE>;
};

export type QueryadminCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryadminCompanySubcontractorsArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryadminCompanyWorkCategoriesArgs = {
  isStandardized?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<WORK_CATEGORY_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminDownloadUrlForBidPackageBoQPdfArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QueryadminEmailTemplatesArgs = {
  language: LANGUAGE;
  templateName: EMAIL_TEMPLATES;
};

export type QueryadminGlobalCertificateTypesArgs = {
  countryCode: COUNTRY_CODE;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryadminInvitationArgs = {
  token: Scalars['String']['input'];
};

export type QueryadminNetworkSubcontractorVersionsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryadminNetworkWorkCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryadminProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PROJECTS_SORT_FIELD>;
  sortById?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminSentEmailArgs = {
  id: Scalars['ID']['input'];
};

export type QueryadminSentEmailsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyFailed?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  recipientType?: InputMaybe<RECIPIENT_ENTITY_TYPE>;
  shouldSearchMatchExactly?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<SENT_EMAILS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<EMAIL_STATUS>>;
  subcontractorId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryadminSignupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SIGNUP_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<SIGNUP_STATUS>>;
};

export type QueryadminSubcontractorArgs = {
  id: Scalars['ID']['input'];
};

export type QueryadminSubcontractorBidRequestsArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminSubcontractorImportRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ADMIN_SUBCONTRACTORS_IMPORT_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<SUBCONTRACTOR_IMPORT_REQUEST_STATUS>>;
};

export type QueryadminSubcontractorsArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filterType?: InputMaybe<ADMIN_SUBCONTRACTOR_FILTER_TYPE>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ADMIN_SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  withFailedEmails?: InputMaybe<Scalars['Boolean']['input']>;
  withUnconfirmedChanges?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryadminUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryadminUsersArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<USERS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryadminWorkCategoryGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryagentCertificateTypesArgs = {
  countryCode: COUNTRY_CODE;
};

export type QueryapplicableMultiUserApprovalRuleArgs = {
  action: MULTI_USER_APPROVAL_ACTION;
  entityId: Scalars['ID']['input'];
  requestProps?: InputMaybe<MultiUserApprovalRequestPropsInput>;
};

export type QueryauthorizeArgs = {
  input: AuthorizeInput;
};

export type QueryauthorizeAdminArgs = {
  input: AuthorizeInput;
};

export type QueryauthorizeMultipleArgs = {
  inputs: Array<InputMaybe<AuthorizeInput>>;
};

export type QueryavailableGlobalCertificateTypesArgs = {
  countryCode: COUNTRY_CODE;
};

export type QueryawardedBidForAgentArgs = {
  contractRunAgentStepId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type QueryawardedBidForBidPackageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryawardedBidsForAgentArgs = {
  excludeContractSignedByAgent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BIDS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerybidArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybidInviteArgs = {
  bidInviteId: Scalars['ID']['input'];
};

export type QuerybidInviteDefaultIntroArgs = {
  projectId: Scalars['ID']['input'];
};

export type QuerybidInvitePreviewArgs = {
  bidPackageId: Scalars['ID']['input'];
  contactId?: InputMaybe<Scalars['String']['input']>;
  customBidsDueAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
};

export type QuerybidPackageArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybidPackageBoQEditorActionsArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QuerybidPackageChangedBiddersNotificationPreviewArgs = {
  comment?: InputMaybe<Scalars['HTML']['input']>;
  id: Scalars['ID']['input'];
  input: BidPackageInput;
};

export type QuerybidPackageChangedDefaultTemplateArgs = {
  bidPackageName?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
};

export type QuerybidPackageCompletionStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybidPackageListForMarketplaceArgs = {
  allowBidsAfterDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  calculateDefaultFilters?: InputMaybe<Scalars['Boolean']['input']>;
  distanceInKm?: InputMaybe<Scalars['Float']['input']>;
  dueDateInMinDays?: InputMaybe<Scalars['Int']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  excludeWorkCategoryIds?: InputMaybe<Array<WorkCategoryIds>>;
  geoCoords?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_PACKAGES_MARKETPLACE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  startsOn?: InputMaybe<Scalars['Date']['input']>;
  workCategoryIds?: InputMaybe<Array<WorkCategoryIds>>;
};

export type QuerybidPackagesArgs = {
  excludeClosedProjects?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyIncludeAwaitingResponse?: InputMaybe<Scalars['Boolean']['input']>;
  onlyIncludeOwnBidPackages?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectPhase?: InputMaybe<PROJECT_PHASE>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_PACKAGES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<BID_PACKAGE_STATUS>>;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerybidRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybidRequestForSubcontractorArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type QuerybidRequestLandingPreviewBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QuerybidRequestsArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  excludeDeletedSubcontractors?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchByContactNames?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<BID_REQUEST_STATUS>>;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerybidRequestsForAgentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerybidderStatusesArgs = {
  id: Scalars['ID']['input'];
};

export type QuerybidsArgs = {
  fetchOwnBids: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectPhase?: InputMaybe<PROJECT_PHASE>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BIDS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerybillOfQuantitiesForBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerybillOfQuantitiesForBidRequestArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type QuerybillOfQuantitiesForEditingArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QuerybillOfQuantitiesFormalReviewArgs = {
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  selectedStandard?: InputMaybe<BOQ_FILE_FORMAT>;
  skipUnsavedBoQChanges?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryboqChangedNotificationDefaultTemplateArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QueryboqChangedNotificationPreviewArgs = {
  bidPackageId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['HTML']['input']>;
};

export type QueryboqTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryboqTemplateBoQArgs = {
  boqTemplateId: Scalars['ID']['input'];
};

export type QueryboqTemplateBoQForEditingArgs = {
  boqTemplateId: Scalars['ID']['input'];
};

export type QueryboqTemplatesArgs = {
  boqFileFormat?: InputMaybe<BOQ_FILE_FORMAT>;
  fetchOwnBoQTemplates: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BOQ_TEMPLATES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerycertificateFileNameArgs = {
  certificateTypeId: Scalars['ID']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycertificateRequestArgs = {
  certificateRequestId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type QuerycertificateRequestsForAgentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CERTIFICATE_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerycertificatesCountForSubcontractorArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type QuerychangedElementIdsSinceLatestSubmittedBidArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type QuerycheckResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};

export type QuerycheckWorkspaceArgs = {
  workspaceId: Scalars['String']['input'];
};

export type QuerycombinedCertificateTypesReportArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type QuerycombinedFileTreeWithDocumentsArgs = {
  attachables: Array<AttachableInput>;
};

export type QuerycompanyTemplatePreviewArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['HTML']['input']>;
  salutationMode?: InputMaybe<EMAIL_SALUTATION_MODE>;
  templateType: COMPANY_TEMPLATE_TYPE;
};

export type QuerycompanyTemplatesArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<COMPANY_TEMPLATE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  type?: InputMaybe<COMPANY_TEMPLATE_TYPE>;
};

export type QuerycontractBoQContentArgs = {
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
};

export type QuerycontractRunAgentStepArgs = {
  bidPackageId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QuerycostAnalysisArgs = {
  breakdown: COST_ANALYSIS_BREAKDOWN;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  topLevelGrouping: COST_ANALYSIS_TOP_LEVEL_GROUPING;
};

export type QuerycostComparisonArgs = {
  bidIds: Array<Scalars['ID']['input']>;
  bidPackageId: Scalars['ID']['input'];
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycostEstimatesForProjectArgs = {
  catalogType: COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type QuerycostGroupDefinitionsArgs = {
  costGroupType: COST_GROUP_TYPE;
};

export type QuerycountryNetworkSubcontractorsCountArgs = {
  countryCode: COUNTRY_CODE;
};

export type QuerycustomCostGroupCatalogArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycustomCostGroupCatalogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<CUSTOM_COST_GROUP_CATALOGS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerycustomFieldsArgs = {
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<CUSTOM_FIELD_TYPE>>;
};

export type QuerycustomFieldsWithInitialValuesArgs = {
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
};

export type QuerydefaultBidderIntroductionTemplateContentArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerydefaultCostAnalysisBreakdownArgs = {
  projectId: Scalars['ID']['input'];
};

export type QuerydocumentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydownloadURLForBillOfQuantitiesArgs = {
  id: Scalars['ID']['input'];
  options: BoQExportInput;
  type: BOQ_FILE_TYPE;
};

export type QuerydownloadUrlForBidPackageBoQPdfArgs = {
  bidPackageId: Scalars['ID']['input'];
  pdfSettings?: InputMaybe<BoQPdfSettings>;
};

export type QuerydownloadUrlForBidPackageDocumentsArgs = {
  id: Scalars['ID']['input'];
  orientation: ORIENTATION;
};

export type QuerydownloadUrlForBidPdfArgs = {
  id: Scalars['ID']['input'];
  orientation?: InputMaybe<ORIENTATION>;
  pdfSettings?: InputMaybe<BidPdfSettings>;
};

export type QuerydownloadUrlForBidRequestInNegotiationDocumentsArgs = {
  id: Scalars['ID']['input'];
  orientation: ORIENTATION;
};

export type QuerydownloadUrlForBidderStatusHistoryPdfArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type QuerydownloadUrlForBiddersListPdfArgs = {
  bidPackageId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerydownloadUrlForContractBoQArgs = {
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
  exportType: BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT;
  fileName: Scalars['String']['input'];
  sourceBoQContractDocumentId: Scalars['ID']['input'];
};

export type QuerydownloadUrlForCostAnalysisSpreadsheetArgs = {
  breakdown: COST_ANALYSIS_BREAKDOWN;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  topLevelGrouping: COST_ANALYSIS_TOP_LEVEL_GROUPING;
};

export type QuerydownloadUrlForCostComparisonSpreadsheetArgs = {
  bidIds: Array<Scalars['ID']['input']>;
  bidPackageId: Scalars['ID']['input'];
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  hiddenBidders: Array<HiddenBidder>;
};

export type QuerydownloadUrlForCostEstimationSpreadsheetArgs = {
  catalogType: COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type QuerydownloadUrlForOverviewPdfArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QuerydownloadUrlForPriceComparisonPdfArgs = {
  alternativePositionSelections: Array<AlternativePositionSelection>;
  bidColumnConfigs: Array<BidColumnConfig>;
  bidPackageId: Scalars['ID']['input'];
  hiddenBidders?: InputMaybe<Array<HiddenBidder>>;
  hiddenComparisonColumns: Array<BOQ_COMPARISON_MODE>;
  hiddenElementIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  highlightPriceMode: HighlightPriceMode;
  outlierSettings?: InputMaybe<PriceComparisonOutlierSettings>;
  pdfSettings?: InputMaybe<PCTPdfSettings>;
  printOptions?: InputMaybe<PrintOptions>;
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerydownloadUrlForPriceComparisonSpreadsheetArgs = {
  alternativePositionSelections: Array<AlternativePositionSelection>;
  bidColumnConfigs: Array<BidColumnConfig>;
  bidPackageId: Scalars['ID']['input'];
  hiddenBidders?: InputMaybe<Array<HiddenBidder>>;
  hiddenComparisonColumns: Array<BOQ_COMPARISON_MODE>;
  hiddenElementIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  highlightPriceMode?: InputMaybe<HighlightPriceMode>;
  includeOptions?: InputMaybe<PriceComparisonSpreadsheetIncludeOptions>;
  outlierSettings?: InputMaybe<PriceComparisonOutlierSettings>;
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerydownloadUrlForProjectDocumentsArgs = {
  id: Scalars['ID']['input'];
  orientation: ORIENTATION;
};

export type QuerydownloadUrlForProjectOverviewPdfArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydownloadUrlForRequestForProposalBoQPdfArgs = {
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  boqId: Scalars['ID']['input'];
  pdfSettings?: InputMaybe<BoQPdfSettings>;
};

export type QuerydraftBidArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId: Scalars['ID']['input'];
};

export type QueryfileTreeWithDocumentsArgs = {
  attachable: AttachableInput;
};

export type QueryfindSimilarBidPackageNamesArgs = {
  name: Scalars['String']['input'];
};

export type QuerygetBoQPositionMassDeterminationForEditingArgs = {
  billOfQuantitiesId: Scalars['ID']['input'];
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  boqPositionRowId: Scalars['ID']['input'];
};

export type QuerygetSalesforceAccountDetailsArgs = {
  id: Scalars['String']['input'];
};

export type QueryheinzeAtBoQElementsArgs = {
  file: Scalars['String']['input'];
};

export type QueryheinzeVobBoQElementsArgs = {
  token: Scalars['String']['input'];
};

export type QueryheinzeVobGuiUrlArgs = {
  forceAnonymous?: Scalars['Boolean']['input'];
};

export type QueryimageUploadUrlArgs = {
  filename: Scalars['String']['input'];
};

export type QueryinvitationArgs = {
  token: Scalars['String']['input'];
};

export type QueryinvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryinvoiceCoverSheetUrlArgs = {
  id: Scalars['ID']['input'];
};

export type QueryinvoiceNotesArgs = {
  invoiceId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryinvoicesArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  requiresApprovalByCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<INVOICE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryisCompanyWaitingForSignupApprovalArgs = {
  companyId: Scalars['ID']['input'];
};

export type QuerylastCertificateRequestArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type QuerylongRunningTaskArgs = {
  id: Scalars['ID']['input'];
};

export type QuerymarketplaceBidPackageSuggestionsArgs = {
  limit: Scalars['Int']['input'];
};

export type QuerymarketplaceBidPackagesForMapArgs = {
  distanceInKm: Scalars['Int']['input'];
  geoCoords: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_PACKAGES_MARKETPLACE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerymarketplaceSuggestionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerymarketplaceSuggestionPreviewArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QuerymarketplaceSuggestionsArgs = {
  bidPackageId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MARKETPLACE_SUGGESTIONS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerymessageThreadArgs = {
  messageThreadId: Scalars['ID']['input'];
};

export type QuerymessageThreadsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  showMessageThreadsFromAllProjects?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<MESSAGE_THREAD_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerymessageThreadsForSubcontractorArgs = {
  query: Scalars['String']['input'];
  showMessageThreadsFromAllProjects?: InputMaybe<Scalars['Boolean']['input']>;
  subcontractorId: Scalars['ID']['input'];
};

export type QuerymessageThreadsInBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
};

export type QuerymessageThreadsInBidRequestArgs = {
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  query: Scalars['String']['input'];
};

export type QuerymultiUserApprovalRequestArgs = {
  action: MULTI_USER_APPROVAL_ACTION;
  entityId: Scalars['ID']['input'];
};

export type QuerymultiUserApprovalRulesArgs = {
  action: MULTI_USER_APPROVAL_ACTION;
};

export type QuerymyTasksInStatusCountArgs = {
  status: TASK_STATUS;
};

export type QuerynetworkSubcontractorArgs = {
  countryCode: COUNTRY_CODE;
  slug: Scalars['String']['input'];
};

export type QuerynetworkSubcontractorsArgs = {
  companySizes?: InputMaybe<Array<COMPANY_SIZE>>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LocationFilter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  workCategoryIdsList?: InputMaybe<Array<WorkCategoryIds>>;
};

export type QuerynetworkSubcontractorsByWorkCategorySlugArgs = {
  countryCode: COUNTRY_CODE;
  distance?: InputMaybe<Scalars['Float']['input']>;
  language: LANGUAGE;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LocationFilter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  sortBy?: InputMaybe<SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QuerynetworkSubcontractorsImportArgs = {
  id: Scalars['ID']['input'];
};

export type QuerynotificationsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryofficeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryorganizationsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryparsedCompanyTemplatesByTypeArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  emailTemplatePlaceholderInput?: InputMaybe<EmailTemplatePlaceholderInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  salutationMode?: InputMaybe<EMAIL_SALUTATION_MODE>;
  type: COMPANY_TEMPLATE_TYPE;
};

export type QueryparsedIfcFileArgs = {
  documentId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type QueryparsedIfcFileWithAssociatedCostsArgs = {
  documentId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type QuerypermissionControlsConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userType: USER_TYPE;
};

export type QuerypredictPriceForBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
  pricePredictionContext?: InputMaybe<PRICE_PREDICTION_CONTEXT>;
};

export type QuerypreviewBidConfirmationEmailAfterPrincipalSubmissionArgs = {
  bidRequestId: Scalars['ID']['input'];
  draftBidId: Scalars['ID']['input'];
};

export type QuerypricePositionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  omitBidRequestId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PRICE_POSITIONS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
};

export type QueryprojectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryprojectChangedBiddersNotificationPreviewArgs = {
  comment?: InputMaybe<Scalars['HTML']['input']>;
  id: Scalars['ID']['input'];
  input: ProjectInput;
};

export type QueryprojectCostGroupCatalogsArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryprojectRatingsPerBidRequestArgs = {
  projectId: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryprojectsArgs = {
  fetchAllOfficeProjects?: InputMaybe<Scalars['Boolean']['input']>;
  fromCreationDate?: InputMaybe<Scalars['Date']['input']>;
  fromEndDate?: InputMaybe<Scalars['Date']['input']>;
  fromStartDate?: InputMaybe<Scalars['Date']['input']>;
  includeClosed?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  officeId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  phase?: InputMaybe<PROJECT_PHASE>;
  primaryUserId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PROJECTS_SORT_FIELD>;
  sortById?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  status?: InputMaybe<Scalars['String']['input']>;
  toCreationDate?: InputMaybe<Scalars['Date']['input']>;
  toEndDate?: InputMaybe<Scalars['Date']['input']>;
  toStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryprojectsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QuerypublicNetworkSubcontractorProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypublicSubcontractorProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryrefreshStlbSessionArgs = {
  oldSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryreverseMarketplaceBidPackageArgs = {
  id: Scalars['ID']['input'];
  referrerUserId?: InputMaybe<Scalars['ID']['input']>;
  sentEmailId?: InputMaybe<Scalars['ID']['input']>;
  suggestionId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerysearchSalesforceAccountsArgs = {
  query: Scalars['String']['input'];
};

export type QuerysentEmailStatisticsArgs = {
  sentEmailId: Scalars['ID']['input'];
};

export type QueryshouldPromptWorkCategoriesUpdateArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type QuerysideEffectFreeDraftBidArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId: Scalars['ID']['input'];
};

export type QuerysimilarSubcontractorsArgs = {
  bidPackageId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerysimulationChangesArgs = {
  bidPackageId: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryssoRedirectUrlArgs = {
  email: Scalars['String']['input'];
  expectedUserStatus: USER_STATUS;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerystlbGetDataArgs = {
  sessionId: Scalars['String']['input'];
};

export type QuerystlbHomeUrlArgs = {
  sessionId: Scalars['String']['input'];
};

export type QuerysubcontractorArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysubcontractorBySentEmailArgs = {
  sentEmailId: Scalars['ID']['input'];
};

export type QuerysubcontractorForProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysubcontractorForSuggestionArgs = {
  suggestionId: Scalars['ID']['input'];
};

export type QuerysubcontractorLoginSuggestionsArgs = {
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerysubcontractorRatingByBidRequestArgs = {
  bidRequestId: Scalars['ID']['input'];
};

export type QuerysubcontractorRatingPerBidRequestArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type QuerysubcontractorRatingSummaryArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type QuerysubcontractorReferenceProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysubcontractorSelectedGlobalCertificateTypesArgs = {
  subcontractorId: Scalars['ID']['input'];
};

export type QuerysubcontractorTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysubcontractorsArgs = {
  companySizes?: InputMaybe<Array<InputMaybe<COMPANY_SIZE>>>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  filters?: InputMaybe<SubcontractorFiltersInput>;
  frequentStatuses?: InputMaybe<Array<BID_REQUEST_STATUS>>;
  hasPQNumber?: InputMaybe<Scalars['Boolean']['input']>;
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  isRated?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LocationFilter>;
  matchAllWorkCategories?: InputMaybe<Scalars['Boolean']['input']>;
  maxYearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
  minYearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  ratings?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortBy?: InputMaybe<SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  sortMode?: InputMaybe<SUBCONTRACTORS_SORT_MODE>;
  type?: InputMaybe<SUBCONTRACTOR_LIST_TYPE>;
  workCategoryIdsList?: InputMaybe<Array<WorkCategoryIds>>;
};

export type QuerysubcontractorsByAgentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerysubcontractorsImportStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysubcontractorsImportsArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerytaskArgs = {
  id: Scalars['ID']['input'];
};

export type QuerytaskNotesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  taskId: Scalars['ID']['input'];
};

export type QuerytaskPublicDataArgs = {
  id: Scalars['ID']['input'];
};

export type QuerytasksArgs = {
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  fetchOwnTasks?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<TASKS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<TASK_STATUS>>;
};

export type QuerytermsForBidRequestArgs = {
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerytotalAvailablePricePositionsArgs = {
  omitBidRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryusersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  officeId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<USERS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  statuses?: InputMaybe<Array<USER_STATUS>>;
  userRoles?: InputMaybe<Array<UserRoleInput>>;
};

export type QueryusersWithAccessToBidPackageArgs = {
  bidPackageId: Scalars['ID']['input'];
};

export type QueryusersWithAccessToProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryvalidateBidMainOrderNumberArgs = {
  ignoreBidId?: InputMaybe<Scalars['ID']['input']>;
  mainOrderNumber: Scalars['String']['input'];
  officeId: Scalars['ID']['input'];
};

export type QueryvalidateCostAnalysisForProjectArgs = {
  catalogType: COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type QueryvalidateSubcontractorsImportArgs = {
  id: Scalars['ID']['input'];
};

export type QueryvalidateWorkCategoryWithTranslationInputArgs = {
  input: Array<WorkCategoryWithTranslationsListInput>;
};

export type QueryworkCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<LANGUAGE>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<WORK_CATEGORY_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  type?: WORK_CATEGORY_TYPE_FILTER;
  useCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryworkCategorySuggestionsArgs = {
  categories: Array<Scalars['ID']['input']>;
  hints?: InputMaybe<WorkCategorySuggestionsHints>;
};

export enum RATING_CATEGORY {
  addenda = 'addenda',
  adherenceToDeadlines = 'adherenceToDeadlines',
  availability = 'availability',
  bidQuality = 'bidQuality',
  communication = 'communication',
  environmentalAspects = 'environmentalAspects',
  invoicingAndBilling = 'invoicingAndBilling',
  occupationalSafety = 'occupationalSafety',
  priceLevel = 'priceLevel',
  qualityOfWork = 'qualityOfWork',
  reliability = 'reliability',
  timeManagement = 'timeManagement',
}

export enum REASON_PROPOSAL_BOQ_IS_OUTDATED {
  requestBoQDeleted = 'requestBoQDeleted',
  requestBoQUpdated = 'requestBoQUpdated',
}

export enum RECIPIENT_ENTITY_TYPE {
  Account = 'Account',
  Admin = 'Admin',
  Contact = 'Contact',
  User = 'User',
}

export enum REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON {
  userMissingOrDeactivated = 'userMissingOrDeactivated',
}

export type ReasonableBidDueDate = {
  __typename: 'ReasonableBidDueDate';
  issue: Maybe<BP_PUBLICATION_REASONABLE_BID_DUE_DATE_ISSUE>;
};

export type RefreshEditingSessionResponse =
  | RefreshEditingSessionSuccess
  | RequestEditingSessionFailure;

export type RefreshEditingSessionSuccess = {
  __typename: 'RefreshEditingSessionSuccess';
  success: Scalars['Boolean']['output'];
};

export type RegistrationCourt = {
  __typename: 'RegistrationCourt';
  city: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type RenumberEntry = {
  __typename: 'RenumberEntry';
  code: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type RenumberEntryInput = {
  code: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type RenumberOperationDescriptor = {
  __typename: 'RenumberOperationDescriptor';
  entries: Array<RenumberEntry>;
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
};

export type RequestEditingSessionFailure = {
  __typename: 'RequestEditingSessionFailure';
  existingSession: EditingSession;
};

export type RequestEditingSessionResponse =
  | RequestEditingSessionFailure
  | RequestEditingSessionSuccess;

export type RequestEditingSessionSuccess = {
  __typename: 'RequestEditingSessionSuccess';
  newSession: EditingSession;
};

export type RequestPasswordlessLoginCodeFailure = {
  __typename: 'RequestPasswordlessLoginCodeFailure';
  reason: REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON;
  success: Scalars['Boolean']['output'];
};

export type RequestPasswordlessLoginCodeResponse =
  | RequestPasswordlessLoginCodeFailure
  | RequestPasswordlessLoginCodeSuccess;

export type RequestPasswordlessLoginCodeSuccess = {
  __typename: 'RequestPasswordlessLoginCodeSuccess';
  success: Scalars['Boolean']['output'];
};

export type RequiredRejectionEmailTemplateNotificationInput = {
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
};

export type RevealContactInfoInput = {
  contact: RevealContactInfoInputContact;
  subcontractorId: Scalars['ID']['input'];
  utm?: InputMaybe<RevealContactInfoInputUtm>;
};

export type RevealContactInfoInputContact = {
  company: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type RevealContactInfoInputUtm = {
  campaign: Scalars['String']['input'];
  medium: Scalars['String']['input'];
  source: Scalars['String']['input'];
};

export type RevealedContact = {
  __typename: 'RevealedContact';
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
};

export type RevealedContactInfo = {
  __typename: 'RevealedContactInfo';
  contacts: Array<RevealedContact>;
  street: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
};

export type ReviewedSuggestionsInput = {
  approvedContactSuggestionFieldIds: Array<Scalars['ID']['input']>;
  approvedSubcontractorSuggestionFieldIds: Array<Scalars['ID']['input']>;
  approvedSubcontractorSuggestionIds: Array<Scalars['ID']['input']>;
  autoDismissNonApproved?: Scalars['Boolean']['input'];
  dismissedContactSuggestionFieldIds: Array<Scalars['ID']['input']>;
  dismissedSubcontractorSuggestionFieldIds: Array<Scalars['ID']['input']>;
  dismissedSubcontractorSuggestionIds: Array<Scalars['ID']['input']>;
};

export type RowValidationConstraintError = {
  __typename: 'RowValidationConstraintError';
  error: Maybe<Scalars['String']['output']>;
  properties: Maybe<Array<Scalars['String']['output']>>;
  property: Maybe<Scalars['String']['output']>;
  row: Scalars['Int']['output'];
  type: NETWORK_SUBCONTRACTOR_IMPORT_ROW_VALIDATION_CONSTRAINT;
  value: Maybe<Scalars['String']['output']>;
};

export enum SALESFORCE_PACKAGE_TYPE {
  bronze = 'bronze',
  enterprise = 'enterprise',
  gold = 'gold',
  noPricingPackage = 'noPricingPackage',
  silver = 'silver',
}

export enum SENT_EMAILS_SORT_FIELD {
  from = 'from',
  sentAt = 'sentAt',
  status = 'status',
  subject = 'subject',
  to = 'to',
}

export enum SIGNUP_SORT_FIELD {
  createdAt = 'createdAt',
  email = 'email',
  status = 'status',
}

export enum SIGNUP_STATUS {
  approved = 'approved',
  completed = 'completed',
  denied = 'denied',
  started = 'started',
}

export enum SIMULATION_CHANGE_STORE_ACTION {
  remove = 'remove',
  update = 'update',
}

export enum SIMULATION_CHANGE_TYPE {
  bidFreeQuantity = 'bidFreeQuantity',
  bidUnitPrice = 'bidUnitPrice',
  boqQuantity = 'boqQuantity',
  customBudgetFreeQuantity = 'customBudgetFreeQuantity',
  customBudgetUnitPrice = 'customBudgetUnitPrice',
}

export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SSODomain = {
  __typename: 'SSODomain';
  domain: Scalars['String']['output'];
};

export type SSODomainInput = {
  domain: Scalars['String']['input'];
};

export enum STANDARD_USER_TYPE {
  administrator = 'administrator',
  collaborator = 'collaborator',
  employee = 'employee',
  guest = 'guest',
  manager = 'manager',
}

export enum STATE_CODE {
  DE_BB = 'DE_BB',
  DE_BE = 'DE_BE',
  DE_BW = 'DE_BW',
  DE_BY = 'DE_BY',
  DE_HB = 'DE_HB',
  DE_HE = 'DE_HE',
  DE_HH = 'DE_HH',
  DE_MV = 'DE_MV',
  DE_NI = 'DE_NI',
  DE_NW = 'DE_NW',
  DE_RP = 'DE_RP',
  DE_SH = 'DE_SH',
  DE_SL = 'DE_SL',
  DE_SN = 'DE_SN',
  DE_ST = 'DE_ST',
  DE_TH = 'DE_TH',
}

export enum STATUS_HISTORY_EMAIL_TEMPLATE_NAME {
  bidInvite = 'bidInvite',
  bidInviteNew = 'bidInviteNew',
  bidInviteReminder = 'bidInviteReminder',
  bidInviteReminderNew = 'bidInviteReminderNew',
  bidInviteReminderSC12989_V1 = 'bidInviteReminderSC12989_V1',
  bidInviteReminderSC12989_V2 = 'bidInviteReminderSC12989_V2',
  bidInviteSC12989_V1 = 'bidInviteSC12989_V1',
  bidInviteSC12989_V2 = 'bidInviteSC12989_V2',
  bidInviteSC12989_V3 = 'bidInviteSC12989_V3',
  bidInviteSC12989_V4 = 'bidInviteSC12989_V4',
  bidInviteSC12989_V5 = 'bidInviteSC12989_V5',
  bidInviteSC12989_V6 = 'bidInviteSC12989_V6',
  bidInviteSC22946_V1 = 'bidInviteSC22946_V1',
  bidInviteSC22946_V2 = 'bidInviteSC22946_V2',
  bidPackageChangedNotification = 'bidPackageChangedNotification',
  bidPackageClosed = 'bidPackageClosed',
  bidRequestAwardedNotification = 'bidRequestAwardedNotification',
  bidRequestRejectedNotification = 'bidRequestRejectedNotification',
  bidderIntroduction = 'bidderIntroduction',
  boqChangedNotification = 'boqChangedNotification',
  contractAgentRequest = 'contractAgentRequest',
  contractSignature = 'contractSignature',
  contractWithdrawn = 'contractWithdrawn',
  documentNotification = 'documentNotification',
  marketplaceBidRequestBidsDueNotification = 'marketplaceBidRequestBidsDueNotification',
  newMessageNotification = 'newMessageNotification',
}

export enum SUBCONTRACTORS_IMPORT_STATUS {
  failed = 'failed',
  initial = 'initial',
  pending = 'pending',
  running = 'running',
  succeeded = 'succeeded',
  validated = 'validated',
}

export enum SUBCONTRACTORS_SORT_FIELD {
  distance = 'distance',
  name = 'name',
}

export enum SUBCONTRACTORS_SORT_MODE {
  algorithmic = 'algorithmic',
  simple = 'simple',
}

export enum SUBCONTRACTOR_CLAIM_REFERENCE {
  bidInvite = 'bidInvite',
  marketplaceSuggestion = 'marketplaceSuggestion',
  sentEmail = 'sentEmail',
}

export enum SUBCONTRACTOR_IMPORT_ERROR_REASON {
  contactHasNoContactMethod = 'contactHasNoContactMethod',
  countryCodeMissing = 'countryCodeMissing',
  externalIdMissing = 'externalIdMissing',
  headersAreMissing = 'headersAreMissing',
  invalidContactEmailFormat = 'invalidContactEmailFormat',
  nameIsEmpty = 'nameIsEmpty',
  noContactsProvided = 'noContactsProvided',
  unsupportedCountryCode = 'unsupportedCountryCode',
}

export enum SUBCONTRACTOR_IMPORT_REQUEST_FILE_TYPE {
  auer = 'auer',
  brz = 'brz',
  cosuno = 'cosuno',
  itwo = 'itwo',
  microsoftTeams = 'microsoftTeams',
  nevaris = 'nevaris',
  orca = 'orca',
  other = 'other',
  outlook = 'outlook',
}

export enum SUBCONTRACTOR_IMPORT_REQUEST_STATUS {
  completed = 'completed',
  onHold = 'onHold',
  pending = 'pending',
}

export enum SUBCONTRACTOR_LIST_TYPE {
  company = 'company',
  companyAndNetwork = 'companyAndNetwork',
  network = 'network',
}

export enum SUBCONTRACTOR_SUGGESTION_CHANGE_TYPE {
  delete = 'delete',
  update = 'update',
}

export enum SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE {
  append = 'append',
  remove = 'remove',
  set = 'set',
  unset = 'unset',
}

export enum SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME {
  city = 'city',
  companySize = 'companySize',
  countryCode = 'countryCode',
  description = 'description',
  foundingYear = 'foundingYear',
  logoKey = 'logoKey',
  name = 'name',
  nationalId = 'nationalId',
  orderAreaCountryCode = 'orderAreaCountryCode',
  orderAreaStateCodes = 'orderAreaStateCodes',
  orderAreaType = 'orderAreaType',
  orderRadius = 'orderRadius',
  ownerName = 'ownerName',
  postalCode = 'postalCode',
  pqNumber = 'pqNumber',
  registrationCity = 'registrationCity',
  registrationPostalCode = 'registrationPostalCode',
  revenueYear = 'revenueYear',
  street = 'street',
  taxId = 'taxId',
  website = 'website',
  workCategories = 'workCategories',
  yearlyRevenue = 'yearlyRevenue',
}

export enum SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT {
  approved = 'approved',
  dismissed = 'dismissed',
}

export enum SUBCONTRACTOR_TYPE {
  company = 'company',
  network = 'network',
}

export type SalesforceAccount = {
  __typename: 'SalesforceAccount';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SalesforceAccountDetailsForAdmin = {
  __typename: 'SalesforceAccountDetailsForAdmin';
  isEnterpriseOpportunity: Maybe<Scalars['Boolean']['output']>;
  licenseData: Maybe<SalesforceAccountLicenseData>;
  name: Scalars['String']['output'];
  packageType: Maybe<SALESFORCE_PACKAGE_TYPE>;
};

export type SalesforceAccountLicenseData = {
  __typename: 'SalesforceAccountLicenseData';
  fullUserLicenseCount: Maybe<Scalars['Float']['output']>;
  fullUserLicenseUnlimited: Scalars['Boolean']['output'];
  guestLicenseCount: Maybe<Scalars['Float']['output']>;
  guestLicenseUnlimited: Scalars['Boolean']['output'];
};

export type SaveCostEstimateForProjectResponse =
  | RequestEditingSessionFailure
  | SaveCostEstimateForProjectSuccess;

export type SaveCostEstimateForProjectSuccess = {
  __typename: 'SaveCostEstimateForProjectSuccess';
  success: Scalars['Boolean']['output'];
};

export type SavePriceComparisonResponse = RequestEditingSessionFailure | SavePriceComparisonSuccess;

export type SavePriceComparisonSuccess = {
  __typename: 'SavePriceComparisonSuccess';
  success: Scalars['Boolean']['output'];
};

export type SaveSimulationOptionsInput = {
  saveBoQ: Scalars['Boolean']['input'];
  saveCustomBudget: Scalars['Boolean']['input'];
  selectedBidIds: Array<Scalars['String']['input']>;
};

export type SendAwardingEmailInput = {
  awarding: EmailTemplateNotificationInput;
  bidRequestId: Scalars['ID']['input'];
  rejection: EmailTemplateNotificationInput;
};

export type SendRejectionEmailInput = {
  bidRequestId: Scalars['ID']['input'];
  rejection: RequiredRejectionEmailTemplateNotificationInput;
};

export type SentEmail = AbstractEntity & {
  __typename: 'SentEmail';
  attachments: Maybe<Scalars['JSON']['output']>;
  canBeResent: Scalars['Boolean']['output'];
  contentHtml: Maybe<Scalars['HTML']['output']>;
  contentText: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  customId: Maybe<Scalars['ID']['output']>;
  fromEmail: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recipient: Maybe<Contact>;
  replyToEmail: Maybe<Scalars['String']['output']>;
  resendAttempts: Array<SentEmail>;
  resendReason: Maybe<Scalars['String']['output']>;
  status: EMAIL_STATUS;
  subject: Maybe<Scalars['String']['output']>;
  template: Scalars['String']['output'];
  templateProps: Scalars['JSONObject']['output'];
  toEmail: Scalars['String']['output'];
  triggeredByAdmin: Maybe<Admin>;
};

export enum SentEmailAuthActions {
  resend = 'resend',
  view = 'view',
}

export type SentEmailAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type SentEmailAuthInput = {
  action: SentEmailAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SentEmailAuthArgsInput>;
};

export type SentEmailEvent = AbstractEntity & {
  __typename: 'SentEmailEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  payload: Scalars['JSONObject']['output'];
  sentEmailId: Scalars['ID']['output'];
  type: MAILJET_EVENT_TYPE;
};

export type SentEmailForStatusHistoryResponse = {
  __typename: 'SentEmailForStatusHistoryResponse';
  contact: Contact;
  id: Scalars['ID']['output'];
  occurredAt: Scalars['DateTimeISO']['output'];
  sentEmail: SentEmail;
  templateName: STATUS_HISTORY_EMAIL_TEMPLATE_NAME;
};

export type SentEmailStatisticsResponse = {
  __typename: 'SentEmailStatisticsResponse';
  messageDetails: MessageDetails;
  messageHistory: Array<MessageHistory>;
  messageId: Scalars['String']['output'];
};

export type SentEmailsResponse = {
  __typename: 'SentEmailsResponse';
  sentEmails: Array<SentEmail>;
  totalRecords: Scalars['Int']['output'];
};

export type SetMassDeterminationRowInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  expression?: InputMaybe<Scalars['String']['input']>;
  factor?: InputMaybe<Scalars['Float']['input']>;
  formulaId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  localId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value1?: InputMaybe<Scalars['Float']['input']>;
  value2?: InputMaybe<Scalars['Float']['input']>;
  value3?: InputMaybe<Scalars['Float']['input']>;
  value4?: InputMaybe<Scalars['Float']['input']>;
  value5?: InputMaybe<Scalars['Float']['input']>;
};

export type Signup = AbstractEntity & {
  __typename: 'Signup';
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPremium: Scalars['Boolean']['output'];
  language: LANGUAGE;
  lastName: Scalars['String']['output'];
  status: SIGNUP_STATUS;
  subcontractorId: Maybe<Scalars['String']['output']>;
  subcontractorName: Scalars['String']['output'];
};

export enum SignupAuthActions {
  approve = 'approve',
  delete = 'delete',
  deny = 'deny',
  view = 'view',
}

export type SignupAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type SignupAuthInput = {
  action: SignupAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SignupAuthArgsInput>;
};

export type SignupsResponse = {
  __typename: 'SignupsResponse';
  signups: Array<Signup>;
  totalRecords: Scalars['Float']['output'];
};

export type SimilarSubcontractorsResponse = {
  __typename: 'SimilarSubcontractorsResponse';
  subcontractors: Array<Subcontractor>;
  totalRecords: Scalars['Int']['output'];
};

export type SimulationBidChanges = {
  __typename: 'SimulationBidChanges';
  bidId: Scalars['ID']['output'];
  boqUnitPriceChanges: Array<SimulationNullableChange>;
  freeQuantityChanges: Array<SimulationNullableChange>;
};

export type SimulationChangeInput = {
  action: SIMULATION_CHANGE_STORE_ACTION;
  bidId?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  type: SIMULATION_CHANGE_TYPE;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type SimulationChanges = {
  __typename: 'SimulationChanges';
  bids: Array<SimulationBidChanges>;
  boqQuantityChanges: Array<SimulationQuantityChange>;
  customBudgetBoQFreeQuantityChanges: Array<SimulationNullableChange>;
  customBudgetBoQUnitPriceChanges: Array<SimulationNullableChange>;
};

export type SimulationNullableChange = {
  __typename: 'SimulationNullableChange';
  path: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type SimulationQuantityChange = {
  __typename: 'SimulationQuantityChange';
  path: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type StandardUserRole = {
  __typename: 'StandardUserRole';
  type: STANDARD_USER_TYPE;
};

export type StandardizedDescriptionResponse = {
  __typename: 'StandardizedDescriptionResponse';
  standardReferenceType: Scalars['String']['output'];
  stlbReference: StlbReferenceResponse;
};

export type StlbKeyResponse = {
  __typename: 'StlbKeyResponse';
  artIdentifier: Scalars['String']['output'];
  artIndex: Maybe<Scalars['String']['output']>;
  kindIdentifier: Maybe<Scalars['String']['output']>;
};

export type StlbReferenceResponse = {
  __typename: 'StlbReferenceResponse';
  catalogueName: Scalars['String']['output'];
  group: Scalars['String']['output'];
  keys: Array<StlbKeyResponse>;
  versionDate: Scalars['DateTimeISO']['output'];
};

export type StlbSessionResponse = {
  __typename: 'StlbSessionResponse';
  authorizationFailed: Scalars['Boolean']['output'];
  sessionId: Scalars['String']['output'];
};

export type StlbWorkCategoryDefinition = {
  __typename: 'StlbWorkCategoryDefinition';
  designation: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type StlbWorkCategoryDefinitionResponse = {
  __typename: 'StlbWorkCategoryDefinitionResponse';
  definitions: Array<StlbWorkCategoryDefinition>;
};

export type StoreSimulationChangesResponse =
  | RequestEditingSessionFailure
  | StoreSimulationChangesSuccess;

export type StoreSimulationChangesSuccess = {
  __typename: 'StoreSimulationChangesSuccess';
  success: Scalars['Boolean']['output'];
};

export type StringDiff = {
  __typename: 'StringDiff';
  new: Maybe<Scalars['String']['output']>;
  old: Maybe<Scalars['String']['output']>;
};

export type StringListDiff = {
  __typename: 'StringListDiff';
  new: Maybe<Array<Scalars['String']['output']>>;
  old: Maybe<Array<Scalars['String']['output']>>;
};

export type Subcontractor = AbstractEntity &
  GeocodedGraphQLEntity & {
    __typename: 'Subcontractor';
    aboutUs: Maybe<Scalars['HTML']['output']>;
    agentCompany: Maybe<Company>;
    bidRequestsAwaitingResponseCount: Scalars['Int']['output'];
    bidRequestsCount: Maybe<Scalars['Int']['output']>;
    city: Maybe<Scalars['String']['output']>;
    company: Maybe<Company>;
    companyId: Maybe<Scalars['ID']['output']>;
    companySize: Maybe<COMPANY_SIZE>;
    confirmedByAgent: Scalars['Boolean']['output'];
    contacts: Array<Contact>;
    countryCode: Maybe<COUNTRY_CODE>;
    createdAt: Scalars['DateTimeISO']['output'];
    customFields: Maybe<Array<CustomFieldWithValue>>;
    description: Maybe<Scalars['String']['output']>;
    foundingYear: Maybe<Scalars['Float']['output']>;
    generatedDescription: Maybe<Scalars['String']['output']>;
    hasSustainableConstruction: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    imageKeys: Array<Scalars['String']['output']>;
    isBanned: Scalars['Boolean']['output'];
    isClaimedByAgentCompany: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    isFavorite: Scalars['Boolean']['output'];
    lat: Maybe<Scalars['Float']['output']>;
    logoKey: Maybe<Scalars['String']['output']>;
    long: Maybe<Scalars['Float']['output']>;
    moreInfo: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    nationalId: Maybe<Scalars['String']['output']>;
    notes: Maybe<Array<SubcontractorNote>>;
    notesCount: Maybe<Scalars['Int']['output']>;
    orderAreaCountryCode: Maybe<COUNTRY_CODE>;
    orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: Maybe<ORDER_RADIUS>;
    ownerName: Maybe<Scalars['String']['output']>;
    postalCode: Maybe<Scalars['String']['output']>;
    pqNumber: Maybe<Scalars['String']['output']>;
    rating: Maybe<Scalars['Float']['output']>;
    referenceProjects: Array<SubcontractorReferenceProject>;
    registrationCity: Maybe<Scalars['String']['output']>;
    registrationPostalCode: Maybe<Scalars['String']['output']>;
    revenueYear: Maybe<Scalars['Float']['output']>;
    slug: Scalars['String']['output'];
    street: Maybe<Scalars['String']['output']>;
    /** Returns number of submitted certificate types (this includes certificates uploaded by both principal and agent users). Returns null if a user or company cannot access certificates */
    submittedCertificateTypesCount: Maybe<Scalars['Int']['output']>;
    suggestedChanges: Array<SubcontractorSuggestion>;
    suggestedNewContacts: Array<ContactCreationSuggestion>;
    sustainableConstructionDetails: Maybe<Scalars['String']['output']>;
    taxId: Maybe<Scalars['String']['output']>;
    type: SUBCONTRACTOR_TYPE;
    unreadMessageThreadsCount: Maybe<Scalars['Int']['output']>;
    website: Maybe<Scalars['String']['output']>;
    workCategories: Array<WorkCategoryForSubcontractor>;
    yearlyRevenue: Maybe<Scalars['Float']['output']>;
  };

export type SubcontractorbidRequestsCountArgs = {
  status?: InputMaybe<Array<BID_REQUEST_STATUS>>;
};

export enum SubcontractorAuthActions {
  addNote = 'addNote',
  ban = 'ban',
  changeSubcontractorAgentCompany = 'changeSubcontractorAgentCompany',
  createContact = 'createContact',
  createNetworkImport = 'createNetworkImport',
  delete = 'delete',
  favorite = 'favorite',
  importNetworkSubcontractors = 'importNetworkSubcontractors',
  manageCustomFields = 'manageCustomFields',
  managePrivateContacts = 'managePrivateContacts',
  merge = 'merge',
  rate = 'rate',
  requestCertificates = 'requestCertificates',
  restore = 'restore',
  submitCertificatesToRequest = 'submitCertificatesToRequest',
  suggestChanges = 'suggestChanges',
  update = 'update',
  uploadCertificateForSubcontractor = 'uploadCertificateForSubcontractor',
  view = 'view',
  viewNetworkImport = 'viewNetworkImport',
  view__bidRequests = 'view__bidRequests',
  view__certificates = 'view__certificates',
  view__notes = 'view__notes',
}

export type SubcontractorAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type SubcontractorAuthInput = {
  action: SubcontractorAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SubcontractorAuthArgsInput>;
};

export type SubcontractorFiltersInput = {
  companySizes?: InputMaybe<Array<InputMaybe<COMPANY_SIZE>>>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  frequentStatuses?: InputMaybe<Array<BID_REQUEST_STATUS>>;
  hasPQNumber?: InputMaybe<Scalars['Boolean']['input']>;
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  isRated?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<LocationFilter>;
  matchAllWorkCategories?: InputMaybe<Scalars['Boolean']['input']>;
  maxYearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
  minYearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
  ratings?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortMode?: InputMaybe<SUBCONTRACTORS_SORT_MODE>;
  type: SUBCONTRACTOR_LIST_TYPE;
  workCategoryIdsList?: InputMaybe<Array<WorkCategoryIds>>;
};

export type SubcontractorForAdmin = AbstractEntity &
  GeocodedGraphQLEntity & {
    __typename: 'SubcontractorForAdmin';
    aboutUs: Maybe<Scalars['HTML']['output']>;
    agentCompany: Maybe<Company>;
    agentCompanyId: Maybe<Scalars['String']['output']>;
    bidRequestsAwaitingResponseCount: Scalars['Int']['output'];
    bidRequestsCount: Maybe<Scalars['Int']['output']>;
    city: Maybe<Scalars['String']['output']>;
    company: Maybe<Company>;
    companyId: Maybe<Scalars['ID']['output']>;
    companySize: Maybe<COMPANY_SIZE>;
    confirmedByAgent: Scalars['Boolean']['output'];
    contacts: Array<Contact>;
    contactsForAdmin: Array<Contact>;
    countryCode: Maybe<COUNTRY_CODE>;
    createdAt: Scalars['DateTimeISO']['output'];
    customFields: Maybe<Array<CustomFieldWithValue>>;
    description: Maybe<Scalars['String']['output']>;
    externalId: Maybe<Scalars['String']['output']>;
    externalSource: Maybe<Scalars['String']['output']>;
    foundingYear: Maybe<Scalars['Float']['output']>;
    generatedDescription: Maybe<Scalars['String']['output']>;
    hasSustainableConstruction: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    imageKeys: Array<Scalars['String']['output']>;
    isBanned: Scalars['Boolean']['output'];
    isBannedByPrincipalCompany: Scalars['Boolean']['output'];
    isClaimedByAgentCompany: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    isFavorite: Scalars['Boolean']['output'];
    lat: Maybe<Scalars['Float']['output']>;
    logoKey: Maybe<Scalars['String']['output']>;
    long: Maybe<Scalars['Float']['output']>;
    mergedIntoSubcontractor: Maybe<SubcontractorForAdmin>;
    moreInfo: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    nationalId: Maybe<Scalars['String']['output']>;
    notes: Maybe<Array<SubcontractorNote>>;
    notesCount: Maybe<Scalars['Int']['output']>;
    orderAreaCountryCode: Maybe<COUNTRY_CODE>;
    orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: Maybe<ORDER_RADIUS>;
    ownerName: Maybe<Scalars['String']['output']>;
    postalCode: Maybe<Scalars['String']['output']>;
    pqNumber: Maybe<Scalars['String']['output']>;
    rating: Maybe<Scalars['Float']['output']>;
    referenceProjects: Array<SubcontractorReferenceProject>;
    registrationCity: Maybe<Scalars['String']['output']>;
    registrationPostalCode: Maybe<Scalars['String']['output']>;
    revenueYear: Maybe<Scalars['Float']['output']>;
    slug: Scalars['String']['output'];
    street: Maybe<Scalars['String']['output']>;
    /** Returns number of submitted certificate types (this includes certificates uploaded by both principal and agent users). Returns null if a user or company cannot access certificates */
    submittedCertificateTypesCount: Maybe<Scalars['Int']['output']>;
    suggestedChanges: Array<SubcontractorSuggestion>;
    suggestedNewContacts: Array<ContactCreationSuggestion>;
    sustainableConstructionDetails: Maybe<Scalars['String']['output']>;
    taxId: Maybe<Scalars['String']['output']>;
    type: SUBCONTRACTOR_TYPE;
    unreadMessageThreadsCount: Maybe<Scalars['Int']['output']>;
    website: Maybe<Scalars['String']['output']>;
    workCategories: Array<WorkCategoryForSubcontractor>;
    yearlyRevenue: Maybe<Scalars['Float']['output']>;
  };

export type SubcontractorForAdminbidRequestsCountArgs = {
  status?: InputMaybe<Array<BID_REQUEST_STATUS>>;
};

export type SubcontractorForAdmincontactsForAdminArgs = {
  includePrivateForNetworkSubcontractor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubcontractorImportRequest = {
  __typename: 'SubcontractorImportRequest';
  company: Company;
  createdAt: Scalars['DateTimeISO']['output'];
  fileName: Scalars['String']['output'];
  fileType: SUBCONTRACTOR_IMPORT_REQUEST_FILE_TYPE;
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: Maybe<Scalars['String']['output']>;
  status: SUBCONTRACTOR_IMPORT_REQUEST_STATUS;
  user: User;
};

export type SubcontractorImportRequestCompletedNotification = AbstractEntity &
  Notification & {
    __typename: 'SubcontractorImportRequestCompletedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: SubcontractorImportRequestCompletedNotificationProps;
  };

export type SubcontractorImportRequestCompletedNotificationProps = {
  __typename: 'SubcontractorImportRequestCompletedNotificationProps';
  importRequestId: Scalars['ID']['output'];
};

export type SubcontractorInput = {
  aboutUs?: InputMaybe<Scalars['HTML']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companySize?: InputMaybe<COMPANY_SIZE>;
  contacts: Array<ContactInput>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  customFields?: InputMaybe<Array<CustomFieldValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalSource?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  hasSustainableConstruction?: InputMaybe<Scalars['Boolean']['input']>;
  imageKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  moreInfo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nationalId?: InputMaybe<Scalars['String']['input']>;
  orderAreaCountryCode?: InputMaybe<COUNTRY_CODE>;
  orderAreaStateCodes?: InputMaybe<Array<STATE_CODE>>;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius?: InputMaybe<ORDER_RADIUS>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pqNumber?: InputMaybe<Scalars['String']['input']>;
  referenceProjects?: InputMaybe<Array<SubcontractorReferenceProjectInput>>;
  registrationCity?: InputMaybe<Scalars['String']['input']>;
  registrationPostalCode?: InputMaybe<Scalars['String']['input']>;
  revenueYear?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  suggestionComment?: InputMaybe<Scalars['String']['input']>;
  sustainableConstructionDetails?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
  yearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
};

export type SubcontractorNote = AbstractEntity &
  Note & {
    __typename: 'SubcontractorNote';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    text: Scalars['String']['output'];
    user: Maybe<User>;
  };

export enum SubcontractorNoteAuthActions {
  delete = 'delete',
  view = 'view',
}

export type SubcontractorNoteAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type SubcontractorNoteAuthInput = {
  action: SubcontractorNoteAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SubcontractorNoteAuthArgsInput>;
};

export type SubcontractorRating = AbstractEntity & {
  __typename: 'SubcontractorRating';
  bidRequestId: Scalars['String']['output'];
  category: RATING_CATEGORY;
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
  user: User;
};

export type SubcontractorRatingInput = {
  bidRequestId: Scalars['ID']['input'];
  category: RATING_CATEGORY;
  comment: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type SubcontractorRatingPerBidRequest = {
  __typename: 'SubcontractorRatingPerBidRequest';
  bidPackageId: Scalars['ID']['output'];
  bidPackageName: Scalars['String']['output'];
  bidRequest: BidRequest;
  bidRequestId: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  projectName: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  ratingByCategory: Array<CategoryRating>;
  ratingCategories: Maybe<Array<RATING_CATEGORY>>;
  subcontractor: Subcontractor;
  userRatedCategories: Array<RATING_CATEGORY>;
};

export type SubcontractorRatingSummaryResponse = {
  __typename: 'SubcontractorRatingSummaryResponse';
  ratingByCategory: Array<CategoryRating>;
};

export type SubcontractorReferenceProject = AbstractEntity & {
  __typename: 'SubcontractorReferenceProject';
  createdAt: Scalars['DateTimeISO']['output'];
  description: Scalars['HTML']['output'];
  id: Scalars['ID']['output'];
  imageKeys: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  position: Scalars['Float']['output'];
  subcontractor: Subcontractor;
};

export type SubcontractorReferenceProjectInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  imageKeys: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Float']['input'];
};

export type SubcontractorResponse = {
  __typename: 'SubcontractorResponse';
  distanceInMeters: Maybe<Scalars['Float']['output']>;
  score: Scalars['Float']['output'];
  subcontractor: Subcontractor;
};

export type SubcontractorSuggestion = AbstractEntity & {
  __typename: 'SubcontractorSuggestion';
  changeType: SUBCONTRACTOR_SUGGESTION_CHANGE_TYPE;
  changes: Array<SubcontractorSuggestionField>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  suggestedByUser: Maybe<User>;
};

export type SubcontractorSuggestionCompanySizeField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionCompanySizeField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<COMPANY_SIZE>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorSuggestionCountryCodeField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionCountryCodeField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<COUNTRY_CODE>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorSuggestionField =
  | SubcontractorSuggestionCompanySizeField
  | SubcontractorSuggestionCountryCodeField
  | SubcontractorSuggestionNumberField
  | SubcontractorSuggestionOrderAreaCountryCodeField
  | SubcontractorSuggestionOrderAreaStateCodesField
  | SubcontractorSuggestionOrderAreaTypeField
  | SubcontractorSuggestionOrderRadiusField
  | SubcontractorSuggestionStringField
  | SubcontractorSuggestionWorkCategoryField;

export type SubcontractorSuggestionInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companySize?: InputMaybe<COMPANY_SIZE>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  description?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nationalId?: InputMaybe<Scalars['String']['input']>;
  orderAreaCountryCode?: InputMaybe<COUNTRY_CODE>;
  orderAreaStateCodes?: InputMaybe<Array<STATE_CODE>>;
  orderAreaType?: InputMaybe<ORDER_AREA_TYPE>;
  orderRadius?: InputMaybe<ORDER_RADIUS>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pqNumber?: InputMaybe<Scalars['String']['input']>;
  registrationCity?: InputMaybe<Scalars['String']['input']>;
  registrationPostalCode?: InputMaybe<Scalars['String']['input']>;
  revenueYear?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  suggestionComment?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
  yearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
};

export type SubcontractorSuggestionNumberField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionNumberField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorSuggestionOrderAreaCountryCodeField =
  AbstractSubcontractorSuggestionField & {
    __typename: 'SubcontractorSuggestionOrderAreaCountryCodeField';
    changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
    fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
    fieldValue: Maybe<COUNTRY_CODE>;
    id: Scalars['ID']['output'];
    reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
    reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
  };

export type SubcontractorSuggestionOrderAreaStateCodesField =
  AbstractSubcontractorSuggestionField & {
    __typename: 'SubcontractorSuggestionOrderAreaStateCodesField';
    changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
    fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
    fieldValue: Maybe<STATE_CODE>;
    id: Scalars['ID']['output'];
    reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
    reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
  };

export type SubcontractorSuggestionOrderAreaTypeField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionOrderAreaTypeField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<ORDER_AREA_TYPE>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorSuggestionOrderRadiusField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionOrderRadiusField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<ORDER_RADIUS>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorSuggestionStringField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionStringField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorSuggestionWorkCategory = {
  __typename: 'SubcontractorSuggestionWorkCategory';
  id: Scalars['String']['output'];
};

export type SubcontractorSuggestionWorkCategoryField = AbstractSubcontractorSuggestionField & {
  __typename: 'SubcontractorSuggestionWorkCategoryField';
  changeType: SUBCONTRACTOR_SUGGESTION_FIELDS_CHANGE_TYPE;
  fieldName: SUBCONTRACTOR_SUGGESTION_FIELDS_FIELD_NAME;
  fieldValue: Maybe<SubcontractorSuggestionWorkCategory>;
  id: Scalars['ID']['output'];
  reviewResult: Maybe<SUBCONTRACTOR_SUGGESTION_FIELDS_REVIEW_RESULT>;
  reviewedOn: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SubcontractorVersion = AbstractEntity & {
  __typename: 'SubcontractorVersion';
  agentCompanyId: Maybe<Scalars['ID']['output']>;
  changeReason: Maybe<Scalars['String']['output']>;
  changedByAdmin: Maybe<Admin>;
  changedByUser: Maybe<User>;
  city: Maybe<Scalars['String']['output']>;
  companySize: Maybe<COMPANY_SIZE>;
  contactVersions: Array<ContactVersion>;
  countryCode: Maybe<COUNTRY_CODE>;
  createdAt: Scalars['DateTimeISO']['output'];
  description: Maybe<Scalars['String']['output']>;
  foundingYear: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  logoKey: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nationalId: Maybe<Scalars['String']['output']>;
  orderAreaCountryCode: Maybe<COUNTRY_CODE>;
  orderAreaStateCodes: Maybe<Array<STATE_CODE>>;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius: Maybe<ORDER_RADIUS>;
  ownerName: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  pqNumber: Maybe<Scalars['String']['output']>;
  registrationCity: Maybe<Scalars['String']['output']>;
  registrationPostalCode: Maybe<Scalars['String']['output']>;
  revenueYear: Maybe<Scalars['Float']['output']>;
  street: Maybe<Scalars['String']['output']>;
  taxId: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
  workCategories: Array<WorkCategoryForSubcontractor>;
  yearlyRevenue: Maybe<Scalars['Float']['output']>;
};

export type SubcontractorWithMergedToRedirect = MergedIntoSubcontractor | Subcontractor;

export type SubcontractorsImport = AbstractEntity & {
  __typename: 'SubcontractorsImport';
  allowDuplicateSubcontractors: Scalars['Boolean']['output'];
  company: Company;
  createdAt: Scalars['DateTimeISO']['output'];
  errors: Array<UpsertError>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  processed: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  validated: Scalars['Boolean']['output'];
};

export type SubcontractorsImportStatusResponse = AbstractEntity & {
  __typename: 'SubcontractorsImportStatusResponse';
  allowDuplicateSubcontractors: Scalars['Boolean']['output'];
  company: Company;
  createdAt: Scalars['DateTimeISO']['output'];
  errors: Array<UpsertError>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  processed: Scalars['Boolean']['output'];
  progress: Scalars['Int']['output'];
  status: SUBCONTRACTORS_IMPORT_STATUS;
  updatedAt: Scalars['DateTimeISO']['output'];
  validated: Scalars['Boolean']['output'];
};

export type SubcontractorsImportValidationError = {
  __typename: 'SubcontractorsImportValidationError';
  line: Maybe<Scalars['Int']['output']>;
  reason: SUBCONTRACTOR_IMPORT_ERROR_REASON;
};

export type SubcontractorsImportValidationResponse = {
  __typename: 'SubcontractorsImportValidationResponse';
  errors: Array<SubcontractorsImportValidationError>;
};

export type SubcontractorsImportsResponse = {
  __typename: 'SubcontractorsImportsResponse';
  subcontractorsImports: Array<SubcontractorsImport>;
  totalRecords: Scalars['Int']['output'];
};

export type SubcontractorsResponse = {
  __typename: 'SubcontractorsResponse';
  subcontractors: Array<SubcontractorResponse>;
  totalRecords: Scalars['Int']['output'];
};

export type SubmittedBid = AbstractEntity & {
  __typename: 'SubmittedBid';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Maybe<Scalars['Float']['output']>;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesRevision: BillOfQuantitiesRevision;
  boqEditingStatus: Maybe<BOQ_EDITING_STATUS>;
  createdAt: Scalars['DateTimeISO']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  documentsCount: Scalars['Int']['output'];
  hasBoQ: Scalars['Boolean']['output'];
  hasOutdatedBoQ: Maybe<REASON_PROPOSAL_BOQ_IS_OUTDATED>;
  id: Scalars['ID']['output'];
  isEligibleForAwarding: Scalars['Boolean']['output'];
  isSimulated: Maybe<Scalars['Boolean']['output']>;
  message: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['DateTimeISO']['output'];
  parentBid: Maybe<Bid>;
  skontoDeadline: Scalars['Int']['output'];
  skontoRate: Scalars['Float']['output'];
  status: BID_STATUS;
  submittedAt: Scalars['DateTimeISO']['output'];
  submittedByUser: Maybe<User>;
  taxRate: Scalars['Float']['output'];
  taxValue: Scalars['Float']['output'];
  totalGross: Scalars['Float']['output'];
  totalNet: Scalars['Float']['output'];
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  version: Scalars['Int']['output'];
  warningMetrics: BidWarningMetrics;
};

export type SubmittedBidHistoryItem = AbstractEntity & {
  __typename: 'SubmittedBidHistoryItem';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Maybe<Scalars['Float']['output']>;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesRevision: BillOfQuantitiesRevision;
  boqEditingStatus: Maybe<BOQ_EDITING_STATUS>;
  createdAt: Scalars['DateTimeISO']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  documentsCount: Scalars['Int']['output'];
  hasBoQ: Scalars['Boolean']['output'];
  hasOutdatedBoQ: Maybe<REASON_PROPOSAL_BOQ_IS_OUTDATED>;
  id: Scalars['ID']['output'];
  isEligibleForAwarding: Scalars['Boolean']['output'];
  isSimulated: Maybe<Scalars['Boolean']['output']>;
  message: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['DateTimeISO']['output'];
  parentBid: Maybe<Bid>;
  skontoDeadline: Scalars['Int']['output'];
  skontoRate: Scalars['Float']['output'];
  status: BID_STATUS;
  submittedAt: Scalars['DateTimeISO']['output'];
  submittedByUser: Maybe<User>;
  taxRate: Scalars['Float']['output'];
  taxValue: Scalars['Float']['output'];
  totalGross: Scalars['Float']['output'];
  totalNet: Scalars['Float']['output'];
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  version: Scalars['Int']['output'];
  warningMetrics: BidWarningMetrics;
};

export type SubmittedOrGeneratedSubmittedBid = AbstractEntity & {
  __typename: 'SubmittedOrGeneratedSubmittedBid';
  absoluteDiscount: Maybe<Scalars['Float']['output']>;
  additionalCosts: Maybe<Scalars['Float']['output']>;
  bidRequest: BidRequest;
  billOfQuantities: Maybe<BillOfQuantities>;
  billOfQuantitiesRevision: BillOfQuantitiesRevision;
  boqEditingStatus: Maybe<BOQ_EDITING_STATUS>;
  createdAt: Scalars['DateTimeISO']['output'];
  discountRate: Maybe<Scalars['Float']['output']>;
  documentsCount: Scalars['Int']['output'];
  hasBoQ: Scalars['Boolean']['output'];
  hasOutdatedBoQ: Maybe<REASON_PROPOSAL_BOQ_IS_OUTDATED>;
  id: Scalars['ID']['output'];
  isEligibleForAwarding: Scalars['Boolean']['output'];
  isSimulated: Maybe<Scalars['Boolean']['output']>;
  message: Maybe<Scalars['String']['output']>;
  parentBid: Maybe<Bid>;
  skontoDeadline: Scalars['Int']['output'];
  skontoRate: Scalars['Float']['output'];
  status: BID_STATUS;
  submittedAt: Maybe<Scalars['DateTimeISO']['output']>;
  submittedByUser: Maybe<User>;
  taxRate: Scalars['Float']['output'];
  totalNet: Scalars['Float']['output'];
  updatedAt: Maybe<Scalars['DateTimeISO']['output']>;
  version: Maybe<Scalars['Int']['output']>;
  warningMetrics: BidWarningMetrics;
};

export type SufficientNumberOfBidders = {
  __typename: 'SufficientNumberOfBidders';
  issue: Maybe<BP_PUBLICATION_SUFFICIENT_NUMBER_OF_BIDDERS_ISSUE>;
};

export type SuggestedGlobalCertificateTypesResponse = {
  __typename: 'SuggestedGlobalCertificateTypesResponse';
  certificateTypes: Array<Scalars['String']['output']>;
};

export type SurchargeOrDiscount = {
  discountMethod: DISCOUNT_METHOD;
  discountValue: Scalars['Float']['input'];
};

export enum TASKS_SORT_FIELD {
  assignee = 'assignee',
  bidPackageName = 'bidPackageName',
  dueAt = 'dueAt',
  name = 'name',
  projectName = 'projectName',
  status = 'status',
}

export enum TASK_ASSIGNEE_TYPE {
  myCompany = 'myCompany',
  subcontractor = 'subcontractor',
}

export enum TASK_STATUS {
  done = 'done',
  inProgress = 'inProgress',
  todo = 'todo',
}

export enum TOGGLE_CONTRACT_DOCUMENT_RESULT {
  documentInfected = 'documentInfected',
  toggledSuccessfully = 'toggledSuccessfully',
  togglingInProgress = 'togglingInProgress',
}

export enum TRIAL_MODULE {
  costEstimationAndControlling = 'costEstimationAndControlling',
  individualisation = 'individualisation',
}

export type Task = AbstractEntity & {
  __typename: 'Task';
  assignee: Maybe<User>;
  assigneeType: TASK_ASSIGNEE_TYPE;
  bidPackage: Maybe<BidPackage>;
  bidPackageId: Maybe<Scalars['ID']['output']>;
  bidRequest: Maybe<BidRequest>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: User;
  description: Maybe<Scalars['String']['output']>;
  dueAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  project: Maybe<Project>;
  projectId: Maybe<Scalars['ID']['output']>;
  status: TASK_STATUS;
};

export type TaskAssignedNotification = AbstractEntity &
  Notification & {
    __typename: 'TaskAssignedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: TaskAssignedNotificationProps;
  };

export type TaskAssignedNotificationProps = {
  __typename: 'TaskAssignedNotificationProps';
  taskId: Scalars['String']['output'];
  taskName: Scalars['String']['output'];
};

export enum TaskAuthActions {
  changeStatus = 'changeStatus',
  createNote = 'createNote',
  delete = 'delete',
  update = 'update',
  view = 'view',
}

export type TaskAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type TaskAuthInput = {
  action: TaskAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TaskAuthArgsInput>;
};

export type TaskCompletedNotification = AbstractEntity &
  Notification & {
    __typename: 'TaskCompletedNotification';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    isRead: Scalars['Boolean']['output'];
    props: TaskCompletedNotificationProps;
  };

export type TaskCompletedNotificationProps = {
  __typename: 'TaskCompletedNotificationProps';
  taskId: Scalars['String']['output'];
  taskName: Scalars['String']['output'];
};

export type TaskInput = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueAt: Scalars['DateTimeISO']['input'];
  name: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskNote = AbstractEntity &
  Note & {
    __typename: 'TaskNote';
    createdAt: Scalars['DateTimeISO']['output'];
    id: Scalars['ID']['output'];
    text: Scalars['String']['output'];
    user: User;
  };

export enum TaskNoteAuthActions {
  delete = 'delete',
  view = 'view',
}

export type TaskNoteAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type TaskNoteAuthInput = {
  action: TaskNoteAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TaskNoteAuthArgsInput>;
};

export type TaskNotesResponse = {
  __typename: 'TaskNotesResponse';
  notes: Array<TaskNote>;
  totalRecords: Scalars['Int']['output'];
};

export type TaskPublicDataResponse = {
  __typename: 'TaskPublicDataResponse';
  bidPackageId: Maybe<Scalars['ID']['output']>;
  bidPackageName: Maybe<Scalars['String']['output']>;
  bidRequestId: Maybe<Scalars['ID']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  subcontractorId: Maybe<Scalars['ID']['output']>;
};

export type TasksResponse = {
  __typename: 'TasksResponse';
  tasks: Array<Task>;
  totalRecords: Scalars['Int']['output'];
};

export type TemplateBoQCostGroupCatalogsToImportInput = {
  catalogsToImport: Array<BoQTemplateCostGroupCatalogInput>;
};

export type TemplateBoQFieldsToImportInput = {
  netTotal: Scalars['Boolean']['input'];
  positionType: Scalars['Boolean']['input'];
  quantity: Scalars['Boolean']['input'];
  taxRate: Scalars['Boolean']['input'];
  unitPrice: Scalars['Boolean']['input'];
  unitTag: Scalars['Boolean']['input'];
};

export type TemplateData = {
  __typename: 'TemplateData';
  content: Scalars['HTML']['output'];
  name: Scalars['String']['output'];
};

export type TemplatePreview = {
  __typename: 'TemplatePreview';
  content: Scalars['HTML']['output'];
  fromEmail: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type TemporaryUploadUrlResponse = {
  __typename: 'TemporaryUploadUrlResponse';
  storageKey: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type Terms = AbstractEntity & {
  __typename: 'Terms';
  createdAt: Scalars['DateTimeISO']['output'];
  downloadUrls: TermsUrls;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  size: Scalars['Int']['output'];
};

export type TermsAcceptanceResponse = {
  __typename: 'TermsAcceptanceResponse';
  id: Scalars['ID']['output'];
  occurredAt: Scalars['DateTimeISO']['output'];
  user: Maybe<User>;
};

export type TermsUrls = {
  __typename: 'TermsUrls';
  openInNewTabUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type TogglePositionTypeOperationDescriptor = {
  __typename: 'TogglePositionTypeOperationDescriptor';
  index: Scalars['Int']['output'];
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  positionType: BOQ_POSITION_TYPE;
};

export type TranslationUpdateInput = {
  aliases?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type Trial = {
  __typename: 'Trial';
  endsAt: Scalars['DateTimeISO']['output'];
  module: TRIAL_MODULE;
};

export enum USERS_SORT_FIELD {
  company = 'company',
  department = 'department',
  email = 'email',
  jobTitle = 'jobTitle',
  lastActivityAt = 'lastActivityAt',
  name = 'name',
  status = 'status',
  userType = 'userType',
}

export enum USER_STATUS {
  active = 'active',
  adminApprovalPending = 'adminApprovalPending',
  approvalPending = 'approvalPending',
  inactive = 'inactive',
  inviteCanceled = 'inviteCanceled',
  inviteFailed = 'inviteFailed',
  inviteRequested = 'inviteRequested',
  invited = 'invited',
}

export enum USER_TYPE {
  administrator = 'administrator',
  collaborator = 'collaborator',
  custom = 'custom',
  employee = 'employee',
  guest = 'guest',
  manager = 'manager',
}

export type UpdateAddendumInput = {
  bidPackageId: Scalars['ID']['input'];
  boqContent: Scalars['JSONObject']['input'];
  contractRefNumber?: InputMaybe<Scalars['String']['input']>;
  executionDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  number: Scalars['Int']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAwardedBidInput = {
  absoluteDiscount?: InputMaybe<Scalars['Float']['input']>;
  absoluteDiscountCalculated?: InputMaybe<Scalars['Float']['input']>;
  additionalCosts?: InputMaybe<Scalars['Float']['input']>;
  awardedSumType?: InputMaybe<BID_AWARDED_SUM_TYPE>;
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  discountRateCalculated?: InputMaybe<Scalars['Float']['input']>;
  mainOrderNumber?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  skontoDeadline?: InputMaybe<Scalars['Int']['input']>;
  skontoRate?: InputMaybe<Scalars['Float']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  taxValue?: InputMaybe<Scalars['Float']['input']>;
  totalGross?: InputMaybe<Scalars['Float']['input']>;
  totalGrossWithSkonto?: InputMaybe<Scalars['Float']['input']>;
  totalNet?: InputMaybe<Scalars['Float']['input']>;
  totalNetWithDiscount?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateBidPackageEstimateForProjectResponse = {
  __typename: 'UpdateBidPackageEstimateForProjectResponse';
  bidPackageCostGroups: Array<CostEstimationBidPackageCostGroup>;
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
};

export type UpdateBidRequestDeclinedReasonByAgentInput = {
  declinedReason: BID_REQUEST_DECLINED_REASON;
  declinedReasonOther: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orderAreaCountryCode?: InputMaybe<COUNTRY_CODE>;
  orderAreaStateCodes?: InputMaybe<Array<STATE_CODE>>;
  orderAreaType?: InputMaybe<ORDER_AREA_TYPE>;
  orderRadius?: InputMaybe<ORDER_RADIUS>;
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
};

export type UpdateBoQFieldResponse = {
  __typename: 'UpdateBoQFieldResponse';
  groups: Array<UpdateBoQFieldResponseGroup>;
  id: Scalars['ID']['output'];
  notes: Array<UpdateBoQFieldResponseNote>;
  positions: Array<UpdateBoQFieldResponsePosition>;
  subDescriptions: Array<UpdateBoQFieldResponseSubDescription>;
};

export type UpdateBoQFieldResponseGroup = {
  __typename: 'UpdateBoQFieldResponseGroup';
  absoluteDeduction: Maybe<Scalars['Float']['output']>;
  deductedPrice: Scalars['Float']['output'];
  deductionFactor: Scalars['Float']['output'];
  path: Scalars['String']['output'];
  totalPrice: Scalars['Float']['output'];
  totalPriceGross: Maybe<Scalars['Float']['output']>;
  totalPriceGrossDeducted: Maybe<Scalars['Float']['output']>;
};

export type UpdateBoQFieldResponseNote = {
  __typename: 'UpdateBoQFieldResponseNote';
  htmlLongText: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
};

export type UpdateBoQFieldResponsePosition = {
  __typename: 'UpdateBoQFieldResponsePosition';
  complementingPricePercentage: Maybe<Scalars['Float']['output']>;
  deductedPrice: Scalars['Float']['output'];
  deductionFactor: Scalars['Float']['output'];
  htmlLongText: Maybe<Scalars['String']['output']>;
  labourComponents: UpdateBoQFieldResponsePositionLabourPriceComponent;
  longText: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  priceComponents: Array<UpdateBoQFieldResponsePositionPriceComponent>;
  quantity: Scalars['Float']['output'];
  quantityComponents: Array<QuantityComponent>;
  taxRate: Maybe<Scalars['Float']['output']>;
  totalPrice: Scalars['Float']['output'];
  totalPriceGross: Maybe<Scalars['Float']['output']>;
  totalPriceGrossDeducted: Maybe<Scalars['Float']['output']>;
  unitPrice: Scalars['Float']['output'];
};

export type UpdateBoQFieldResponsePositionLabourPriceComponent = {
  __typename: 'UpdateBoQFieldResponsePositionLabourPriceComponent';
  label: Maybe<Scalars['String']['output']>;
  totalTime: Scalars['Float']['output'];
  values: Maybe<Array<UpdateBoQFieldResponsePositionPriceCalculation>>;
};

export type UpdateBoQFieldResponsePositionPriceCalculation = {
  __typename: 'UpdateBoQFieldResponsePositionPriceCalculation';
  result: Scalars['Float']['output'];
};

export type UpdateBoQFieldResponsePositionPriceComponent = {
  __typename: 'UpdateBoQFieldResponsePositionPriceComponent';
  label: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  values: Maybe<Array<UpdateBoQFieldResponsePositionPriceCalculation>>;
};

export type UpdateBoQFieldResponseSubDescription = {
  __typename: 'UpdateBoQFieldResponseSubDescription';
  htmlLongText: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
};

export type UpdateBoQTemplateFailure = {
  __typename: 'UpdateBoQTemplateFailure';
  success: Scalars['Boolean']['output'];
};

export type UpdateBoQTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBoQTemplateResponse = UpdateBoQTemplateFailure | UpdateBoQTemplateSuccess;

export type UpdateBoQTemplateSuccess = {
  __typename: 'UpdateBoQTemplateSuccess';
  template: BoQTemplate;
};

export type UpdateCostEstimateForProjectResponse =
  | UpdateBidPackageEstimateForProjectResponse
  | UpdateCustomCatalogEstimateForProjectResponse
  | UpdateStandardCatalogEstimateForProjectResponse
  | UpdateStlbWorkCategoryEstimateForProjectResponse;

export type UpdateCostItemInput = {
  factor?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCustomCatalogEstimateForProjectResponse = {
  __typename: 'UpdateCustomCatalogEstimateForProjectResponse';
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogCostGroups: Array<CostEstimationCustomCatalogCostGroup>;
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
};

export type UpdateCustomCostGroupCatalogInput = {
  costGroups?: InputMaybe<Array<CustomCostGroupDefinitionInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CUSTOM_COST_GROUP_CATALOG_STATUS>;
};

export type UpdateDocumentInput = {
  uploaded: Scalars['Boolean']['input'];
};

export type UpdateFillableBoQComplementingPricePercentageInput = {
  path: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFillableBoQDescriptionInput = {
  path: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFillableBoQFreeQuantityInput = {
  path: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFillableBoQPriceComponentInput = {
  path: Scalars['String']['input'];
  priceComponentIndex: Scalars['Float']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFillableBoQPriceInput = {
  path: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFillableBoQSurchargeOrDiscountInput = {
  path: Scalars['String']['input'];
  value?: InputMaybe<SurchargeOrDiscount>;
};

export type UpdateFillableBoQTimeTrackingInput = {
  path: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInvoiceInput = {
  approvedTotalNet: Scalars['Float']['input'];
  bidPackageId: Scalars['ID']['input'];
  cashDiscountDeadline?: InputMaybe<Scalars['String']['input']>;
  cashDiscountValue: Scalars['Float']['input'];
  customRows: Array<InvoiceCustomRowForUpdate>;
  discountValue: Scalars['Float']['input'];
  fileId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  insuranceValue: Scalars['Float']['input'];
  issuedAt: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nonApprovedTotalNet: Scalars['Float']['input'];
  numberExternal?: InputMaybe<Scalars['String']['input']>;
  numberInternal?: InputMaybe<Scalars['String']['input']>;
  partialInvoiceNumber?: InputMaybe<Scalars['Int']['input']>;
  paymentDeadline: Scalars['String']['input'];
  receiptDate?: InputMaybe<Scalars['String']['input']>;
  retentionValue: Scalars['Float']['input'];
  subcontractorBic: Scalars['String']['input'];
  subcontractorCity: Scalars['String']['input'];
  subcontractorCountryCode: COUNTRY_CODE;
  subcontractorEmail: Scalars['String']['input'];
  subcontractorIban: Scalars['String']['input'];
  subcontractorName: Scalars['String']['input'];
  subcontractorPhone: Scalars['String']['input'];
  subcontractorPostalCode: Scalars['String']['input'];
  subcontractorStreet: Scalars['String']['input'];
  taxRate: Scalars['Float']['input'];
};

export type UpdateMeInput = {
  customDepartmentName?: InputMaybe<Scalars['String']['input']>;
  departmentType?: InputMaybe<DEPARTMENT_TYPE>;
  email: Scalars['String']['input'];
  externalCompany?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateParsedIfcFileNodeAssociatedCost = {
  bidPackageId?: InputMaybe<Scalars['String']['input']>;
  costGroupLocalId?: InputMaybe<Scalars['String']['input']>;
  factor?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  quantityFormula?: InputMaybe<Scalars['String']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateParsedIfcFileWithAssociatedCostsResponse = {
  __typename: 'UpdateParsedIfcFileWithAssociatedCostsResponse';
  affectedNodes: Array<ParsedIfcFileNode>;
  id: Scalars['ID']['output'];
};

export type UpdateProjectInformationOperationBoQStructureDescriptor = {
  __typename: 'UpdateProjectInformationOperationBoQStructureDescriptor';
  tierDataType: BOQ_TIER_DATA_TYPE;
  tierDigits: Scalars['Float']['output'];
  tierName: Scalars['String']['output'];
  tierType: BOQ_TIER_TYPE;
};

export type UpdateProjectInformationOperationDescriptor = {
  __typename: 'UpdateProjectInformationOperationDescriptor';
  boqStructure: Maybe<Array<UpdateProjectInformationOperationBoQStructureDescriptor>>;
  labourTimeLabel: Maybe<Scalars['String']['output']>;
  operation: BOQ_EDIT_OPERATION;
  operationId: Scalars['ID']['output'];
  priceComponents: Maybe<Array<Scalars['String']['output']>>;
};

export type UpdateStandardCatalogEstimateForProjectResponse = {
  __typename: 'UpdateStandardCatalogEstimateForProjectResponse';
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  standardCatalogCostGroups: Array<CostEstimationStandardCatalogCostGroup>;
};

export type UpdateStlbWorkCategoryEstimateForProjectResponse = {
  __typename: 'UpdateStlbWorkCategoryEstimateForProjectResponse';
  catalogType: COST_CATALOG_TYPE;
  createdAt: Scalars['DateTimeISO']['output'];
  customCatalogId: Maybe<Scalars['String']['output']>;
  hasUnsavedChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  stlbWorkCategoryCostGroups: Array<CostEstimationStlbWorkCategoryCostGroup>;
};

export type UpsertError = {
  __typename: 'UpsertError';
  externalId: Scalars['String']['output'];
  line: Scalars['Float']['output'];
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type UpsertSubcontractorInput = {
  aboutUs?: InputMaybe<Scalars['HTML']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companySize?: InputMaybe<COMPANY_SIZE>;
  contacts: Array<ContactInput>;
  countryCode?: InputMaybe<COUNTRY_CODE>;
  customFields?: InputMaybe<Array<CustomFieldValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  externalSource: Scalars['String']['input'];
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  hasSustainableConstruction?: InputMaybe<Scalars['Boolean']['input']>;
  imageKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  moreInfo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nationalId?: InputMaybe<Scalars['String']['input']>;
  orderAreaCountryCode?: InputMaybe<COUNTRY_CODE>;
  orderAreaStateCodes?: InputMaybe<Array<STATE_CODE>>;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius?: InputMaybe<ORDER_RADIUS>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pqNumber?: InputMaybe<Scalars['String']['input']>;
  referenceProjects?: InputMaybe<Array<SubcontractorReferenceProjectInput>>;
  registrationCity?: InputMaybe<Scalars['String']['input']>;
  registrationPostalCode?: InputMaybe<Scalars['String']['input']>;
  revenueYear?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  suggestionComment?: InputMaybe<Scalars['String']['input']>;
  sustainableConstructionDetails?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  workCategories?: InputMaybe<Array<WorkCategoryInput>>;
  yearlyRevenue?: InputMaybe<Scalars['Float']['input']>;
};

export type User = AbstractEntity & {
  __typename: 'User';
  celloToken: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  customDepartmentName: Maybe<Scalars['String']['output']>;
  customSignature: Maybe<Scalars['HTML']['output']>;
  departmentType: Maybe<DEPARTMENT_TYPE>;
  email: Scalars['String']['output'];
  externalApiAccessKey: Scalars['String']['output'];
  externalCompany: Maybe<Scalars['String']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  featureFlags: Scalars['JSONObject']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intercomHash: Scalars['String']['output'];
  invitationLink: Scalars['String']['output'];
  isActiveCompanyOnboarding: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  jobTitle: Maybe<Scalars['String']['output']>;
  language: LANGUAGE;
  lastActivityAt: Maybe<Scalars['DateTimeISO']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mfaLastResetAt: Maybe<Scalars['DateTimeISO']['output']>;
  mfaMethod: Maybe<MFA_METHOD>;
  mfaPhoneCensored: Maybe<Scalars['String']['output']>;
  notificationConfig: UserNotificationConfig;
  offices: Array<Office>;
  passwordPolicy: Maybe<CompanyPasswordPolicy>;
  permissions: Array<AdminUserPermissionResponse>;
  phone: Maybe<Scalars['String']['output']>;
  role: UserRole;
  ssoEnabled: Scalars['Boolean']['output'];
  status: USER_STATUS;
  type: USER_TYPE;
  workspaces: Array<Workspace>;
};

export enum UserAuthActions {
  approveOrDeleteJoinRequest = 'approveOrDeleteJoinRequest',
  confirmOrDeleteInviteRequest = 'confirmOrDeleteInviteRequest',
  deactivateOrReactivate = 'deactivateOrReactivate',
  delete = 'delete',
  deleteFailedInvite = 'deleteFailedInvite',
  resetMfaConfig = 'resetMfaConfig',
  resetPassword = 'resetPassword',
  update = 'update',
  update__personalInfo = 'update__personalInfo',
  update__typeAndOffices = 'update__typeAndOffices',
  view = 'view',
  view__mfaLastResetAt = 'view__mfaLastResetAt',
  view__mfaMethod = 'view__mfaMethod',
  view__privateData = 'view__privateData',
}

export type UserAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type UserAuthInput = {
  action: UserAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserAuthArgsInput>;
};

export type UserNotificationConfig = AbstractEntity & {
  __typename: 'UserNotificationConfig';
  appAgentSignedContract: Scalars['Boolean']['output'];
  appBidPackageStatusChanged: Scalars['Boolean']['output'];
  appBidRequestDeclined: Scalars['Boolean']['output'];
  appBiddersAwaitingResponse: Scalars['Boolean']['output'];
  appBiddingPeriodEnded: Scalars['Boolean']['output'];
  appBiddingPeriodEnding: Scalars['Boolean']['output'];
  appCertificateExpired: Scalars['Boolean']['output'];
  appCertificateExpiring: Scalars['Boolean']['output'];
  appCertificateNotProvidedInTime: Scalars['Boolean']['output'];
  appCertificateUploadedBySubcontractor: Scalars['Boolean']['output'];
  appContractMultiSigningCompleted: Scalars['Boolean']['output'];
  appNewBid: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  emailAgentSignedContract: Scalars['Boolean']['output'];
  emailBidPackageStatusChanged: Scalars['Boolean']['output'];
  emailBidRequestDeclined: Scalars['Boolean']['output'];
  emailBiddersAwaitingResponse: Scalars['Boolean']['output'];
  emailBiddingPeriodEnded: Scalars['Boolean']['output'];
  emailBiddingPeriodEnding: Scalars['Boolean']['output'];
  emailCertificateExpired: Scalars['Boolean']['output'];
  emailCertificateExpiring: Scalars['Boolean']['output'];
  emailCertificateNotProvidedInTime: Scalars['Boolean']['output'];
  emailCertificateUploadedBySubcontractor: Scalars['Boolean']['output'];
  emailContractMultiSigningCompleted: Scalars['Boolean']['output'];
  emailNewBid: Scalars['Boolean']['output'];
  emailNewMessageFromSubcontractor: Scalars['Boolean']['output'];
  emailUserAddedToBidPackageTeam: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type UserNotificationConfigInput = {
  appAgentSignedContract?: InputMaybe<Scalars['Boolean']['input']>;
  appBidPackageStatusChanged: Scalars['Boolean']['input'];
  appBidRequestDeclined: Scalars['Boolean']['input'];
  appBiddersAwaitingResponse: Scalars['Boolean']['input'];
  appBiddingPeriodEnded: Scalars['Boolean']['input'];
  appBiddingPeriodEnding: Scalars['Boolean']['input'];
  appCertificateExpired: Scalars['Boolean']['input'];
  appCertificateExpiring: Scalars['Boolean']['input'];
  appCertificateNotProvidedInTime: Scalars['Boolean']['input'];
  appCertificateUploadedBySubcontractor: Scalars['Boolean']['input'];
  appContractMultiSigningCompleted: Scalars['Boolean']['input'];
  appNewBid: Scalars['Boolean']['input'];
  emailAgentSignedContract?: InputMaybe<Scalars['Boolean']['input']>;
  emailBidPackageStatusChanged: Scalars['Boolean']['input'];
  emailBidRequestDeclined: Scalars['Boolean']['input'];
  emailBiddersAwaitingResponse: Scalars['Boolean']['input'];
  emailBiddingPeriodEnded: Scalars['Boolean']['input'];
  emailBiddingPeriodEnding: Scalars['Boolean']['input'];
  emailCertificateExpired: Scalars['Boolean']['input'];
  emailCertificateExpiring: Scalars['Boolean']['input'];
  emailCertificateNotProvidedInTime: Scalars['Boolean']['input'];
  emailCertificateUploadedBySubcontractor: Scalars['Boolean']['input'];
  emailContractMultiSigningCompleted: Scalars['Boolean']['input'];
  emailNewBid: Scalars['Boolean']['input'];
  emailNewMessageFromSubcontractor: Scalars['Boolean']['input'];
  emailUserAddedToBidPackageTeam?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserRole = CustomUserRole | StandardUserRole;

export type UserRoleInput = {
  customTypeId?: InputMaybe<Scalars['ID']['input']>;
  type: USER_TYPE;
};

export type UserToInviteInput = {
  customUserRoleId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: USER_TYPE;
};

export type UserWithConnectedUsers = {
  __typename: 'UserWithConnectedUsers';
  connectedUsers: Array<User>;
  user: User;
};

export type UsersResponse = {
  __typename: 'UsersResponse';
  totalRecords: Scalars['Int']['output'];
  users: Array<User>;
};

export enum VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON {
  tokenExpired = 'tokenExpired',
}

export type ValidateBidMainOrderNumberResponse = {
  __typename: 'ValidateBidMainOrderNumberResponse';
  isValid: Scalars['Boolean']['output'];
};

export type ValidatePasswordlessLoginFailure = {
  __typename: 'ValidatePasswordlessLoginFailure';
  reason: VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON;
};

export type ValidatePasswordlessLoginResponse =
  | CheckCredentialsResponse
  | ValidatePasswordlessLoginFailure;

export enum WORK_CATEGORY_SORT_FIELD {
  name = 'name',
  subcontractorsCount = 'subcontractorsCount',
}

export enum WORK_CATEGORY_TYPE {
  company = 'company',
  network = 'network',
}

export enum WORK_CATEGORY_TYPE_FILTER {
  allAvailable = 'allAvailable',
  company = 'company',
  network = 'network',
  onlyAssigned = 'onlyAssigned',
}

export type WorkCategoriesResponse = {
  __typename: 'WorkCategoriesResponse';
  totalRecords: Scalars['Int']['output'];
  workCategories: Array<WorkCategory>;
};

export type WorkCategory = AbstractEntity & {
  __typename: 'WorkCategory';
  aliasSuggestions: Array<Scalars['String']['output']>;
  aliases: Array<Scalars['String']['output']>;
  baseName: Scalars['String']['output'];
  correspondingNetworkWorkCategories: Array<WorkCategory>;
  createdAt: Scalars['DateTimeISO']['output'];
  group: Maybe<WorkCategoryGroup>;
  id: Scalars['ID']['output'];
  isNew: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  slugs: Array<Array<Scalars['String']['output']>>;
  translation: WorkCategoryTranslation;
  translations: Array<WorkCategoryTranslation>;
  type: WORK_CATEGORY_TYPE;
};

export type WorkCategoryaliasesArgs = {
  language: LANGUAGE;
};

export type WorkCategorynameArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type WorkCategoryslugArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type WorkCategorytranslationArgs = {
  language: LANGUAGE;
};

export enum WorkCategoryAuthActions {
  createNetworkWorkCategory = 'createNetworkWorkCategory',
  deleteNetworkWorkCategory = 'deleteNetworkWorkCategory',
  updateNetworkWorkCategory = 'updateNetworkWorkCategory',
  update__correspondingNetworkWorkCategories = 'update__correspondingNetworkWorkCategories',
  view = 'view',
}

export type WorkCategoryAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type WorkCategoryAuthInput = {
  action: WorkCategoryAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkCategoryAuthArgsInput>;
};

export type WorkCategoryForSubcontractor = AbstractEntity & {
  __typename: 'WorkCategoryForSubcontractor';
  aliasSuggestions: Array<Scalars['String']['output']>;
  aliases: Array<Scalars['String']['output']>;
  baseName: Scalars['String']['output'];
  correspondingNetworkWorkCategories: Array<WorkCategory>;
  createdAt: Scalars['DateTimeISO']['output'];
  group: Maybe<WorkCategoryGroup>;
  id: Scalars['ID']['output'];
  isNew: Maybe<Scalars['Boolean']['output']>;
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  slugs: Array<Array<Scalars['String']['output']>>;
  translation: WorkCategoryTranslation;
  translations: Array<WorkCategoryTranslation>;
  type: WORK_CATEGORY_TYPE;
};

export type WorkCategoryForSubcontractoraliasesArgs = {
  language: LANGUAGE;
};

export type WorkCategoryForSubcontractornameArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type WorkCategoryForSubcontractorslugArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type WorkCategoryForSubcontractortranslationArgs = {
  language: LANGUAGE;
};

export type WorkCategoryGroup = AbstractEntity & {
  __typename: 'WorkCategoryGroup';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  translation: Scalars['String']['output'];
  translations: Array<WorkCategoryGroupTranslation>;
  workCategories: Array<WorkCategory>;
};

export type WorkCategoryGrouptranslationArgs = {
  language?: InputMaybe<LANGUAGE>;
};

export type WorkCategoryGroupInput = {
  name: Scalars['String']['input'];
  translations: Array<WorkCategoryGroupTranslationInput>;
};

export type WorkCategoryGroupTranslation = AbstractEntity & {
  __typename: 'WorkCategoryGroupTranslation';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  language: LANGUAGE;
  name: Scalars['String']['output'];
};

export type WorkCategoryGroupTranslationInput = {
  language: LANGUAGE;
  name: Scalars['String']['input'];
};

export type WorkCategoryIds = {
  ids: Array<Scalars['ID']['input']>;
};

export type WorkCategoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type WorkCategorySuggestion = {
  __typename: 'WorkCategorySuggestion';
  score: Scalars['Float']['output'];
  workCategory: WorkCategory;
};

export type WorkCategorySuggestionsHints = {
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkCategoryTranslation = AbstractEntity & {
  __typename: 'WorkCategoryTranslation';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  language: LANGUAGE;
  name: Scalars['String']['output'];
};

export enum WorkCategoryTranslationAuthActions {
  update = 'update',
}

export type WorkCategoryTranslationAuthArgsInput = {
  id: Scalars['ID']['input'];
};

export type WorkCategoryTranslationAuthInput = {
  action: WorkCategoryTranslationAuthActions;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkCategoryTranslationAuthArgsInput>;
};

export type WorkCategoryTranslationInput = {
  language: LANGUAGE;
  name: Scalars['String']['input'];
};

export type WorkCategoryWithTranslationsListInput = {
  name: Scalars['String']['input'];
  translations: Array<WorkCategoryTranslationInput>;
};

export type Workspace = {
  __typename: 'Workspace';
  company: Company;
  id: Scalars['String']['output'];
  isCurrent: Scalars['Boolean']['output'];
  mfaEnabled: Scalars['Boolean']['output'];
  mfaRequired: Scalars['Boolean']['output'];
  ssoEnabled: Scalars['Boolean']['output'];
};

export type UpdateAddendumStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  newStatus: ADDENDUM_STATUS;
}>;

export type UpdateAddendumStatusMutation = {
  __typename: 'Mutation';
  updateAddendumStatus: boolean;
};

export type UpdateAddendumMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAddendumInput;
}>;

export type UpdateAddendumMutation = { __typename: 'Mutation'; updateAddendum: boolean };

export type AddendumQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AddendumQuery = {
  __typename: 'Query';
  addendum: {
    __typename: 'Addendum';
    id: string;
    contractRefNumber: string | null;
    executionDate: DateString | null;
    name: string;
    number: number;
    remarks: string | null;
    status: ADDENDUM_STATUS;
    totalGross: number;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      addendumNumbers: Array<number>;
      currency: CURRENCY;
      name: string;
      number: string | null;
      awardedBid: {
        __typename: 'AwardedBid';
        id: string;
        bidRequest: {
          __typename: 'BidRequest';
          id: string;
          contacts: Array<{
            __typename: 'BidRequestContact';
            email: string | null;
            firstName: string | null;
            id: string;
            isPerson: boolean;
            jobTitle: string | null;
            phone: string | null;
            mobilePhone: string | null;
            fax: string | null;
            lastName: string | null;
            title: CONTACT_TITLE | null;
            bidInvite: { __typename: 'BidInvite'; id: string } | null;
          }>;
          subcontractor: {
            __typename: 'Subcontractor';
            city: string | null;
            countryCode: COUNTRY_CODE | null;
            id: string;
            name: string;
            postalCode: string | null;
            street: string | null;
            website: string | null;
          };
        };
        parentBid: {
          __typename: 'Bid';
          id: string;
          billOfQuantities: {
            __typename: 'BillOfQuantities';
            content: JSONObject;
            currency: CURRENCY;
            id: string;
            comments: Array<{
              __typename: 'BillOfQuantitiesComment';
              id: string;
              path: string;
              text: string;
            }>;
          } | null;
        } | null;
        submittedByUser: {
          __typename: 'User';
          email: string;
          firstName: string | null;
          id: string;
          lastName: string | null;
        } | null;
      } | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        status: PROJECT_STATUS;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        costGroupCatalogs: Array<{
          __typename: 'ProjectCostGroupCatalog';
          catalogType: PROJECT_COST_CATALOG_TYPE;
          customCatalogId: string | null;
          customCatalogName: string | null;
        }>;
      };
    };
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      content: JSONObject;
      id: string;
      comments: Array<{
        __typename: 'BillOfQuantitiesComment';
        id: string;
        path: string;
        text: string;
      }>;
    };
  };
};

export type AwardedBidForAgentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  contractRunAgentStepId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AwardedBidForAgentQuery = {
  __typename: 'Query';
  awardedBidForAgent: {
    __typename: 'AwardedBid';
    additionalCosts: number;
    discountRate: number | null;
    absoluteDiscount: number | null;
    id: string;
    message: string | null;
    skontoDeadline: number;
    skontoRate: number;
    taxRate: number;
    totalNet: number;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      bidPackage: {
        __typename: 'BidPackage';
        contractDocumentsRequiringSignature: Array<string>;
        id: string;
        isContractSignedByAgent: boolean;
        isContractSignedByPrincipal: boolean;
        isContractSignedDocumentPending: boolean;
        isContractSignedPerUpload: boolean;
        isContractSigningEnabled: boolean;
        name: string;
        number: string | null;
        currency: CURRENCY;
        status: BID_PACKAGE_STATUS;
        contractAssignee: {
          __typename: 'ContractAssignee';
          currentStep: number;
          totalStepsCount: number;
          userFirstName: string | null;
          userLastName: string | null;
          userId: string | null;
        } | null;
        contractAgentAssignee: {
          __typename: 'ContractAgentAssignee';
          currentStep: number;
          totalStepsCount: number;
          name: string | null;
        } | null;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          number: string | null;
          status: PROJECT_STATUS;
          office: {
            __typename: 'Office';
            id: string;
            name: string;
            company: { __typename: 'Company'; name: string };
          };
        };
      };
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        isClaimedByAgentCompany: boolean;
        type: SUBCONTRACTOR_TYPE;
      };
    };
  };
};

export type TasksForContractedBidQueryVariables = Exact<{
  sortBy?: InputMaybe<TASKS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type TasksForContractedBidQuery = {
  __typename: 'Query';
  tasks: {
    __typename: 'TasksResponse';
    totalRecords: number;
    tasks: Array<{
      __typename: 'Task';
      id: string;
      name: string;
      status: TASK_STATUS;
      dueAt: DateTime;
      assigneeType: TASK_ASSIGNEE_TYPE;
      assignee: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
      } | null;
      bidRequest: {
        __typename: 'BidRequest';
        subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
      } | null;
    }>;
  };
};

export type AwardedBidsForAgentQueryVariables = Exact<{
  excludeContractSignedByAgent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BIDS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
}>;

export type AwardedBidsForAgentQuery = {
  __typename: 'Query';
  awardedBidsForAgent: {
    __typename: 'AwardedBidsForAgentResponse';
    totalRecords: number;
    bids: Array<{
      __typename: 'AwardedBid';
      id: string;
      awardedAt: DateTime;
      totalNet: number;
      discountRate: number | null;
      absoluteDiscount: number | null;
      additionalCosts: number;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        bidPackage: {
          __typename: 'BidPackage';
          currency: CURRENCY;
          id: string;
          name: string;
          status: BID_PACKAGE_STATUS;
          project: {
            __typename: 'Project';
            id: string;
            name: string;
            status: PROJECT_STATUS;
            office: { __typename: 'Office'; id: string; name: string };
          };
        };
      };
    }>;
  };
};

export type BidRequestForEditQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type BidRequestForEditQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    id: string;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      bidsDueAt: DateTime;
      startsOn: DateString | null;
      endsOn: DateString | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        location: string;
        startsOn: string | null;
        endsOn: string | null;
        description: string | null;
        isEstimating: boolean;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          street: string | null;
          postalCode: string | null;
          city: string | null;
          countryCode: COUNTRY_CODE | null;
          email: string | null;
          phone: string | null;
          fax: string | null;
        };
      };
    };
  };
};

export type BidPackageDetailsFromBidRequestForAgentQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type BidPackageDetailsFromBidRequestForAgentQuery = {
  __typename: 'Query';
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    submittedBids: Array<{
      __typename: 'SubmittedBid';
      id: string;
      billOfQuantities: {
        __typename: 'BillOfQuantities';
        id: string;
        editingStatus: BOQ_EDITING_STATUS | null;
      } | null;
    }>;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      currency: CURRENCY;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        costGroupCatalogs: Array<{
          __typename: 'ProjectCostGroupCatalog';
          catalogType: PROJECT_COST_CATALOG_TYPE;
          customCatalogId: string | null;
          customCatalogName: string | null;
        }>;
      };
    };
  };
};

export type CreateBidRequestMutationVariables = Exact<{
  input: BidRequestByAgentInput;
}>;

export type CreateBidRequestMutation = {
  __typename: 'Mutation';
  createBidRequestByAgent: { __typename: 'BidRequest'; id: string };
};

export type UpdateBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: BidRequestByAgentInput;
}>;

export type UpdateBidRequestMutation = {
  __typename: 'Mutation';
  updateBidRequestByAgent: { __typename: 'BidRequest'; id: string };
};

export type BidRequestsForAgentCountsQueryVariables = Exact<{ [key: string]: never }>;

export type BidRequestsForAgentCountsQuery = {
  __typename: 'Query';
  bidRequestsForAgentCounts: {
    __typename: 'BidRequestsForAgentCountsResponse';
    allRecords: number;
    activeRecords: number;
  };
};

export type BidRequestsForAgentQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
}>;

export type BidRequestsForAgentQuery = {
  __typename: 'Query';
  bidRequestsForAgent: {
    __typename: 'BidRequestsResponse';
    totalRecords: number;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
      customBidsDueAt: DateTime | null;
      notesCount: number;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        bidsDueAt: DateTime;
        currency: CURRENCY;
        status: BID_PACKAGE_STATUS;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          number: string | null;
          location: string;
          customer: string | null;
          startsOn: string | null;
          endsOn: string | null;
          description: string | null;
          status: PROJECT_STATUS;
          office: { __typename: 'Office'; id: string; name: string };
        };
      };
      currentBid: {
        __typename: 'SubmittedBid';
        id: string;
        message: string | null;
        totalNet: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        submittedAt: DateTime;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
      } | null;
      awardedBid: {
        __typename: 'AwardedBid';
        id: string;
        parentBid: {
          __typename: 'Bid';
          id: string;
          totalNet: number;
          discountRate: number | null;
          absoluteDiscount: number | null;
          submittedAt: DateTime | null;
          skontoDeadline: number | null;
          skontoRate: number | null;
          taxRate: number | null;
        } | null;
      } | null;
      subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
    }>;
  };
};

export type MarketplaceBidPackageSuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type MarketplaceBidPackageSuggestionsQuery = {
  __typename: 'Query';
  marketplaceBidPackageSuggestions: Array<{
    __typename: 'MarketplaceBidPackageSample';
    id: string;
    name: string;
    officeName: string;
  }>;
};

export type AvailableGlobalCertificateTypesQueryVariables = Exact<{
  countryCode: COUNTRY_CODE;
}>;

export type AvailableGlobalCertificateTypesQuery = {
  __typename: 'Query';
  availableGlobalCertificateTypes: Array<{
    __typename: 'CertificateType';
    id: string;
    name: string;
  }>;
};

export type AgentCertificateTypesQueryVariables = Exact<{
  countryCode: COUNTRY_CODE;
}>;

export type AgentCertificateTypesQuery = {
  __typename: 'Query';
  agentCertificateTypes: Array<{
    __typename: 'CertificateType';
    id: string;
    name: string;
    type: CERTIFICATE_TYPE_TYPE;
    countryCode: COUNTRY_CODE | null;
  }>;
  agentCertificateTypesReport: Array<{
    __typename: 'CertificateTypeReport';
    id: string;
    name: string;
    type: CERTIFICATE_TYPE_TYPE;
    countryCode: COUNTRY_CODE | null;
    subcontractorTypeId: string | null;
  }>;
};

export type SelectCertificateTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SelectCertificateTypeMutation = {
  __typename: 'Mutation';
  selectCertificateType: { __typename: 'CertificateType'; id: string; name: string };
};

export type CreateAgentCompanyCertificateTypeMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type CreateAgentCompanyCertificateTypeMutation = {
  __typename: 'Mutation';
  createAgentCompanyCertificateType: { __typename: 'CertificateType'; id: string; name: string };
};

export type SubcontractorCertificateOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type SubcontractorCertificateOverviewQuery = {
  __typename: 'Query';
  agentCertificateTypesReport: Array<{
    __typename: 'CertificateTypeReport';
    id: string;
    type: CERTIFICATE_TYPE_TYPE;
    scope: CERTIFICATE_TYPE_SCOPE;
    name: string;
    isExpiryDateMandatory: boolean;
    subcontractorTypeId: string | null;
    status: CERTIFICATE_TYPE_STATUS;
    maxExpiryDate: DateTime | null;
    requestedOn: DateTime | null;
    requestedDueDate: DateTime | null;
    rejectionReason: string | null;
    templateDocument: { __typename: 'Document'; id: string; filename: string; size: number } | null;
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      issueDate: string;
      expiryDate: string | null;
      createdAt: DateTime;
      fileName: string;
      fileSizeBytes: number;
      availability: CERTIFICATE_AVAILABILITY;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: { __typename: 'Project'; id: string; name: string };
      } | null;
    }>;
    pendingCertificateRequestItemsForBidPackages: Array<{
      __typename: 'CertificateRequestItem';
      id: string;
      createdAt: DateTime;
      rejectionReason: string | null;
      certificateRequest: {
        __typename: 'CertificateRequest';
        dueDate: string | null;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          project: { __typename: 'Project'; id: string; name: string };
        } | null;
      };
    }>;
  }>;
  subcontractorsByAgent: Array<{
    __typename: 'Subcontractor';
    id: string;
    name: string;
    countryCode: COUNTRY_CODE | null;
    confirmedByAgent: boolean;
  }>;
};

export type CertificateRequestsForAgentUserQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  sortBy?: InputMaybe<CERTIFICATE_REQUESTS_SORT_FIELD>;
}>;

export type CertificateRequestsForAgentUserQuery = {
  __typename: 'Query';
  certificateRequestsForAgent: {
    __typename: 'CertificateRequestsResponse';
    totalRecords: number;
    certificateRequests: Array<{
      __typename: 'CertificateRequest';
      id: string;
      dueDate: string | null;
      createdAt: DateTime;
      statusForAgent: CERTIFICATE_REQUEST_STATUS_FOR_AGENT;
      office: { __typename: 'Office'; id: string; name: string };
      contact: {
        __typename: 'Contact';
        id: string;
        subcontractor: { __typename: 'Subcontractor'; id: string };
      };
      certificateRequestItems: Array<{
        __typename: 'CertificateRequestItem';
        id: string;
        rejectionReason: string | null;
      }>;
    }>;
  };
};

export type AgentCertificatesViewQueryVariables = Exact<{ [key: string]: never }>;

export type AgentCertificatesViewQuery = {
  __typename: 'Query';
  incompleteCertificateRequestsCountForAgent: number;
};

export type AgentCompanyOnboardingQueryVariables = Exact<{ [key: string]: never }>;

export type AgentCompanyOnboardingQuery = {
  __typename: 'Query';
  agentCompanyOnboarding: Array<
    | {
        __typename: 'AgentCompanyOnboardAddReferenceProject';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
      }
    | {
        __typename: 'AgentCompanyOnboardCompleteCompanyProfile';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
        projectsWithInvitesCount: number;
      }
    | {
        __typename: 'AgentCompanyOnboardCreateOwnRequest';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
      }
    | {
        __typename: 'AgentCompanyOnboardDiscoverCosunoMarketplace';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
        projectsInMarketplaceCount: number;
      }
    | {
        __typename: 'AgentCompanyOnboardInviteColleagues';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
      }
    | {
        __typename: 'AgentCompanyOnboardRespondToRequests';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
        unansweredRequests: number;
      }
    | {
        __typename: 'AgentCompanyOnboardSaveSearch';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
      }
    | {
        __typename: 'AgentCompanyOnboardSpecifyMainTrades';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
      }
    | {
        __typename: 'AgentCompanyOnboardUploadCertificates';
        type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
        isComplete: boolean;
        isCompletable: boolean;
      }
  >;
};

export type CompleteAgentCompanyOnboardingItemMutationVariables = Exact<{
  type: AGENT_COMPANY_ONBOARDING_ITEM_TYPE;
}>;

export type CompleteAgentCompanyOnboardingItemMutation = {
  __typename: 'Mutation';
  completeAgentCompanyOnboardItem: boolean;
};

export type MarketplaceBidPackagesForPremiumPageQueryVariables = Exact<{
  geoCoords: Scalars['String']['input'];
  distanceInKm: Scalars['Int']['input'];
}>;

export type MarketplaceBidPackagesForPremiumPageQuery = {
  __typename: 'Query';
  marketplaceBidPackagesForMap: {
    __typename: 'BidPackagesList';
    totalRecords: number;
    bidPackages: Array<{
      __typename: 'BidPackage';
      id: string;
      lat: number | null;
      long: number | null;
    }>;
  };
};

export type AgentPublicCertificatesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AgentPublicCertificatesQuery = {
  __typename: 'Query';
  publicNetworkSubcontractorProfile:
    | { __typename: 'MergedIntoSubcontractor'; id: string }
    | {
        __typename: 'PublicNetworkSubcontractorProfile';
        id: string;
        name: string;
        logoKey: string | null;
        street: string | null;
        city: string | null;
        postalCode: string | null;
        countryCode: COUNTRY_CODE | null;
        companySize: COMPANY_SIZE | null;
        website: string | null;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          isPrimary: boolean;
          name: string;
        }>;
        certificateTypesReport: Array<{
          __typename: 'CertificateTypeReport';
          id: string;
          type: CERTIFICATE_TYPE_TYPE;
          scope: CERTIFICATE_TYPE_SCOPE;
          name: string;
          isExpiryDateMandatory: boolean;
          subcontractorTypeId: string | null;
          status: CERTIFICATE_TYPE_STATUS;
          maxExpiryDate: DateTime | null;
          requestedOn: DateTime | null;
          requestedDueDate: DateTime | null;
          rejectionReason: string | null;
          templateDocument: {
            __typename: 'Document';
            id: string;
            filename: string;
            size: number;
          } | null;
          certificates: Array<{
            __typename: 'Certificate';
            id: string;
            issueDate: string;
            expiryDate: string | null;
            createdAt: DateTime;
            fileName: string;
            fileSizeBytes: number;
            availability: CERTIFICATE_AVAILABILITY;
            bidPackage: {
              __typename: 'BidPackage';
              id: string;
              name: string;
              project: { __typename: 'Project'; id: string; name: string };
            } | null;
          }>;
          pendingCertificateRequestItemsForBidPackages: Array<{
            __typename: 'CertificateRequestItem';
            id: string;
            createdAt: DateTime;
            rejectionReason: string | null;
            certificateRequest: {
              __typename: 'CertificateRequest';
              dueDate: string | null;
              bidPackage: {
                __typename: 'BidPackage';
                id: string;
                name: string;
                project: { __typename: 'Project'; id: string; name: string };
              } | null;
            };
          }>;
        }>;
      };
};

export type BidRequestForBidInviteFeedbackQueryVariables = Exact<{
  bidInviteId: Scalars['ID']['input'];
  language?: InputMaybe<LANGUAGE>;
}>;

export type BidRequestForBidInviteFeedbackQuery = {
  __typename: 'Query';
  bidInvite: {
    __typename: 'BidInvite';
    id: string;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      type: BID_REQUEST_TYPE;
      status: BID_REQUEST_STATUS;
      declinedReason: BID_REQUEST_DECLINED_REASON | null;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          number: string | null;
          office: {
            __typename: 'Office';
            id: string;
            company: { __typename: 'Company'; id: string; name: string };
          };
        };
      };
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        type: SUBCONTRACTOR_TYPE;
        isClaimedByAgentCompany: boolean;
        orderAreaType: ORDER_AREA_TYPE;
        orderRadius: ORDER_RADIUS | null;
        orderAreaStateCodes: Array<STATE_CODE> | null;
        orderAreaCountryCode: COUNTRY_CODE | null;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          name: string;
          isPrimary: boolean;
        }>;
      };
    };
  };
};

export type MarketplaceSamplesForBidRequestQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type MarketplaceSamplesForBidRequestQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    marketplaceSamples: Array<{
      __typename: 'MarketplaceBidPackageSample';
      bidsDueDate: DateTime;
      customerName: string | null;
      endsOn: DateTime | null;
      id: string;
      location: string;
      name: string;
      officeLogoKey: string | null;
      officeName: string;
      projectId: string;
      projectName: string;
      startsOn: DateTime | null;
    }>;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      isClaimedByAgentCompany: boolean;
      type: SUBCONTRACTOR_TYPE;
    };
  };
};

export type BidRequestForUpdateProfileUnauthenticatedQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type BidRequestForUpdateProfileUnauthenticatedQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    id: string;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      type: SUBCONTRACTOR_TYPE;
      workCategories: Array<{
        __typename: 'WorkCategoryForSubcontractor';
        id: string;
        name: string;
        isPrimary: boolean;
      }>;
    };
  };
};

export type SubcontractorForUpdateProfileUnauthenticatedQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
}>;

export type SubcontractorForUpdateProfileUnauthenticatedQuery = {
  __typename: 'Query';
  publicNetworkSubcontractorProfile:
    | { __typename: 'MergedIntoSubcontractor' }
    | {
        __typename: 'PublicNetworkSubcontractorProfile';
        id: string;
        name: string;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          name: string;
          isPrimary: boolean;
        }>;
      };
};

export type ReportAgentCompanyClosedByBidRequestMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type ReportAgentCompanyClosedByBidRequestMutation = {
  __typename: 'Mutation';
  reportAgentCompanyClosed: boolean;
};

export type ReportAgentCompanyClosedBySubcontractorMutationVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
  token: Scalars['ID']['input'];
}>;

export type ReportAgentCompanyClosedBySubcontractorMutation = {
  __typename: 'Mutation';
  reportAgentCompanyClosedBySubcontractor: boolean;
};

export type RequestContractExtensionMutationVariables = Exact<{ [key: string]: never }>;

export type RequestContractExtensionMutation = {
  __typename: 'Mutation';
  requestContractExtension: boolean;
};

export type CreatePasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type CreatePasswordMutation = {
  __typename: 'Mutation';
  createPassword: {
    __typename: 'CreatePasswordResponse';
    loginToken: string;
    mfaRequired: boolean;
    mfaMethod: MFA_METHOD | null;
    mfaPhoneCensored: string | null;
    companyName: string;
  };
};

export type CheckResetPasswordTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type CheckResetPasswordTokenQuery = {
  __typename: 'Query';
  checkResetPasswordToken: {
    __typename: 'CheckResetPasswordTokenResponse';
    passwordPolicy: {
      __typename: 'CompanyPasswordPolicy';
      minLength: number;
      requiresLowerCaseCharacter: boolean;
      requiresUpperCaseCharacter: boolean;
      requiresSpecialCharacter: boolean;
      requiresNumber: boolean;
    } | null;
  };
};

export type InvitationQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type InvitationQuery = {
  __typename: 'Query';
  invitation: {
    __typename: 'InvitationResponse';
    isInvitationToWorkspace: boolean;
    isPasswordUpdateRequired: boolean;
    inviter: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
    invitee: {
      __typename: 'User';
      id: string;
      email: string;
      firstName: string | null;
      lastName: string | null;
      status: USER_STATUS;
      ssoEnabled: boolean;
      company: { __typename: 'Company'; id: string; name: string; type: COMPANY_TYPE };
    };
    passwordPolicy: {
      __typename: 'CompanyPasswordPolicy';
      minLength: number;
      requiresLowerCaseCharacter: boolean;
      requiresUpperCaseCharacter: boolean;
      requiresSpecialCharacter: boolean;
      requiresNumber: boolean;
    } | null;
  };
};

export type ActivateInviteeMutationVariables = Exact<{
  token: Scalars['String']['input'];
  input: InviteeInput;
}>;

export type ActivateInviteeMutation = {
  __typename: 'Mutation';
  activateInvitee: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type ConfirmInviteRequestByTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ConfirmInviteRequestByTokenMutation = {
  __typename: 'Mutation';
  confirmInviteRequestByToken: { __typename: 'User'; id: string; email: string };
};

export type SubcontractorLoginSuggestionsQueryVariables = Exact<{
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SubcontractorLoginSuggestionsQuery = {
  __typename: 'Query';
  subcontractorLoginSuggestions: Array<{
    __typename: 'LoginSuggestion';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
  }>;
};

export type AwardedBid_bidRequestFragment = {
  __typename: 'BidRequest';
  id: string;
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    currency: CURRENCY;
    isLocked: boolean;
    name: string;
    number: string | null;
    status: BID_PACKAGE_STATUS;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      status: PROJECT_STATUS;
      number: string | null;
      office: { __typename: 'Office'; id: string; name: string };
    };
  };
  subcontractor: {
    __typename: 'Subcontractor';
    id: string;
    name: string;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    website: string | null;
  };
};

export type AwardedBid_bidFragment = {
  __typename: 'Bid';
  discountRate: number | null;
  absoluteDiscount: number | null;
  message: string | null;
  skontoDeadline: number | null;
  skontoRate: number | null;
  taxRate: number | null;
  totalNet: number;
};

export type ShowAwardedBidPageQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type ShowAwardedBidPageQuery = {
  __typename: 'Query';
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    awardedBidId: string | null;
    subcontractorId: string | null;
    awardedBid: {
      __typename: 'AwardedBid';
      id: string;
      absoluteDiscount: number | null;
      additionalCosts: number;
      awardedAt: DateTime;
      discountRate: number | null;
      message: string | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      totalNet: number;
      awardedSumType: BID_AWARDED_SUM_TYPE | null;
      mainOrderNumber: string | null;
      parentBid: {
        __typename: 'Bid';
        id: string;
        totalNet: number;
        skontoDeadline: number | null;
        skontoRate: number | null;
        taxRate: number | null;
        discountRate: number | null;
        billOfQuantities: {
          __typename: 'BillOfQuantities';
          id: string;
          editingStatus: BOQ_EDITING_STATUS | null;
        } | null;
      } | null;
    } | null;
    contacts: Array<{
      __typename: 'BidRequestContact';
      id: string;
      email: string | null;
      firstName: string | null;
      isPerson: boolean;
      jobTitle: string | null;
      phone: string | null;
      mobilePhone: string | null;
      fax: string | null;
      lastName: string | null;
      title: CONTACT_TITLE | null;
      bidInvite: { __typename: 'BidInvite'; id: string } | null;
    }>;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      currency: CURRENCY;
      isLocked: boolean;
      name: string;
      number: string | null;
      status: BID_PACKAGE_STATUS;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        status: PROJECT_STATUS;
        number: string | null;
        office: { __typename: 'Office'; id: string; name: string };
      };
    };
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      website: string | null;
    };
  };
};

export type AwardBidPageQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  bidId: Scalars['ID']['input'];
}>;

export type AwardBidPageQuery = {
  __typename: 'Query';
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    submittedBid: {
      __typename: 'SubmittedBid';
      id: string;
      discountRate: number | null;
      absoluteDiscount: number | null;
      message: string | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      totalNet: number;
      isEligibleForAwarding: boolean;
      billOfQuantities: {
        __typename: 'BillOfQuantities';
        id: string;
        editingStatus: BOQ_EDITING_STATUS | null;
      } | null;
    };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      currency: CURRENCY;
      isLocked: boolean;
      name: string;
      number: string | null;
      status: BID_PACKAGE_STATUS;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        status: PROJECT_STATUS;
        number: string | null;
        office: { __typename: 'Office'; id: string; name: string };
      };
    };
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      website: string | null;
    };
  };
};

export type ValidateBidMainOrderNumberQueryVariables = Exact<{
  officeId: Scalars['ID']['input'];
  mainOrderNumber: Scalars['String']['input'];
  ignoreBidId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ValidateBidMainOrderNumberQuery = {
  __typename: 'Query';
  validateBidMainOrderNumber: {
    __typename: 'ValidateBidMainOrderNumberResponse';
    isValid: boolean;
  };
};

export type UpdateAwardedBidMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAwardedBidInput;
}>;

export type UpdateAwardedBidMutation = {
  __typename: 'Mutation';
  updateAwardedBid: { __typename: 'AwardedBid'; id: string };
};

export type AwardBidRequestMutationVariables = Exact<{
  input: AwardBidRequestInput;
}>;

export type AwardBidRequestMutation = { __typename: 'Mutation'; awardBidRequest: string };

export type BidSummary_bidFragment = {
  __typename: 'SubmittedBid';
  id: string;
  submittedAt: DateTime;
  message: string | null;
  totalNet: number;
  skontoRate: number;
  skontoDeadline: number;
  discountRate: number | null;
  absoluteDiscount: number | null;
  taxRate: number;
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    contacts: Array<{
      __typename: 'BidRequestContact';
      id: string;
      isPerson: boolean;
      title: CONTACT_TITLE | null;
      firstName: string | null;
      lastName: string | null;
      jobTitle: string | null;
      email: string | null;
      phone: string | null;
      mobilePhone: string | null;
      fax: string | null;
      bidInvite: { __typename: 'BidInvite'; id: string } | null;
    }>;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      website: string | null;
    };
  };
  submittedByUser: {
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
  } | null;
};

export type ShowBidPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ShowBidPageQuery = {
  __typename: 'Query';
  bid: {
    __typename: 'SubmittedBid';
    id: string;
    totalNet: number;
    additionalCosts: number | null;
    version: number;
    isSimulated: boolean | null;
    isEligibleForAwarding: boolean;
    documentsCount: number;
    submittedAt: DateTime;
    message: string | null;
    skontoRate: number;
    skontoDeadline: number;
    discountRate: number | null;
    absoluteDiscount: number | null;
    taxRate: number;
    billOfQuantitiesRevision: {
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
    };
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        currency: CURRENCY;
        isLocked: boolean;
        name: string;
        status: BID_PACKAGE_STATUS;
        billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
        project: { __typename: 'Project'; id: string; name: string; status: PROJECT_STATUS };
      };
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        website: string | null;
      };
      contacts: Array<{
        __typename: 'BidRequestContact';
        id: string;
        isPerson: boolean;
        title: CONTACT_TITLE | null;
        firstName: string | null;
        lastName: string | null;
        jobTitle: string | null;
        email: string | null;
        phone: string | null;
        mobilePhone: string | null;
        fax: string | null;
        bidInvite: { __typename: 'BidInvite'; id: string } | null;
      }>;
    };
    submittedByUser: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
    } | null;
  };
};

export type PrincipalSubmitBid_draftBidFragment = {
  __typename: 'DraftBid';
  message: string | null;
  totalNet: number | null;
  skontoDeadline: number | null;
  skontoRate: number | null;
  discountRate: number | null;
  taxRate: number | null;
  documentsCount: number;
  hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
  canNotifyBidderOnPrincipalSubmission: boolean;
  billOfQuantities: {
    __typename: 'BillOfQuantities';
    id: string;
    editingStatus: BOQ_EDITING_STATUS | null;
  } | null;
};

export type SubmitBidPageQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type SubmitBidPageQuery = {
  __typename: 'Query';
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    status: BID_REQUEST_STATUS;
    subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      currency: CURRENCY;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        status: PROJECT_STATUS;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          company: {
            __typename: 'Company';
            id: string;
            type: COMPANY_TYPE;
            bidsSubmittedOnMarketplace: number;
            permanentFeatureFlags: JSONObject;
            config: {
              __typename: 'CompanyConfig';
              id: string;
              additionalLanguages: Array<LANGUAGE>;
              premiumEnabled: boolean;
            };
          };
        };
      };
    };
  };
  draftBid: {
    __typename: 'DraftBid';
    id: string;
    message: string | null;
    totalNet: number | null;
    skontoDeadline: number | null;
    skontoRate: number | null;
    discountRate: number | null;
    taxRate: number | null;
    documentsCount: number;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    canNotifyBidderOnPrincipalSubmission: boolean;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
  } | null;
};

export type GetOrCreateDraftBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type GetOrCreateDraftBidMutation = {
  __typename: 'Mutation';
  getOrCreateDraftBid: {
    __typename: 'DraftBid';
    id: string;
    message: string | null;
    totalNet: number | null;
    skontoDeadline: number | null;
    skontoRate: number | null;
    discountRate: number | null;
    taxRate: number | null;
    documentsCount: number;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    canNotifyBidderOnPrincipalSubmission: boolean;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
  };
};

export type UpdateDraftBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  input: BidInput;
}>;

export type UpdateDraftBidMutation = {
  __typename: 'Mutation';
  updateDraftBid: {
    __typename: 'DraftBid';
    totalNet: number | null;
    skontoRate: number | null;
    skontoDeadline: number | null;
    discountRate: number | null;
    absoluteDiscount: number | null;
    taxRate: number | null;
    message: string | null;
  };
};

export type SubmitBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  input: PrincipalSubmitBidInput;
}>;

export type SubmitBidMutation = {
  __typename: 'Mutation';
  submitBid: { __typename: 'Bid'; id: string };
};

export type PreviewSubmissionConfirmationEmailTemplatePreviewQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  draftBidId: Scalars['ID']['input'];
}>;

export type PreviewSubmissionConfirmationEmailTemplatePreviewQuery = {
  __typename: 'Query';
  previewBidConfirmationEmailAfterPrincipalSubmission: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type BidInviteQueryVariables = Exact<{
  bidInviteId: Scalars['ID']['input'];
}>;

export type BidInviteQuery = {
  __typename: 'Query';
  bidInvite: {
    __typename: 'BidInvite';
    id: string;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      subcontractorId: string | null;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          office: { __typename: 'Office'; id: string; name: string };
        };
      };
    };
  };
};

export type TrackBidInviteViewMutationVariables = Exact<{
  bidInviteId: Scalars['ID']['input'];
}>;

export type TrackBidInviteViewMutation = { __typename: 'Mutation'; trackBidInviteView: boolean };

export type BulkAddContactsMutationVariables = Exact<{
  amount: BULK_ADD_CONTACTS_AMOUNT;
  bidPackageId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  subcontractorFilters: SubcontractorFiltersInput;
  customBidsDueAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  skipInvitation: Scalars['Boolean']['input'];
}>;

export type BulkAddContactsMutation = {
  __typename: 'Mutation';
  bulkAddContacts: { __typename: 'BulkAddContactsResponse'; count: number };
};

export type BidPackageForAddBiddersQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidPackageForAddBiddersQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    status: BID_PACKAGE_STATUS;
    addedContactIds: Array<string>;
    withdrawnContactIds: Array<string>;
    customInvitationEmailIntro: string | null;
    bidsDueAt: DateTime;
    networkAwaitingResponseCountIfRestricted: number | null;
    project: {
      __typename: 'Project';
      id: string;
      status: PROJECT_STATUS;
      lat: number | null;
      long: number | null;
      location: string;
      locationCountryCode: COUNTRY_CODE | null;
      locationStateCode: STATE_CODE | null;
      name: string;
      number: string | null;
      isEstimating: boolean;
    };
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      customBidsDueAt: DateTime | null;
      subcontractorId: string | null;
      contacts: Array<{
        __typename: 'BidRequestContact';
        id: string;
        bidInvite: {
          __typename: 'BidInvite';
          id: string;
          status: BID_INVITE_STATUS;
          bidRequest: { __typename: 'BidRequest'; id: string };
        } | null;
      }>;
    }>;
    allCorrespondingWorkCategories: Array<{
      __typename: 'WorkCategory';
      id: string;
      name: string;
      type: WORK_CATEGORY_TYPE;
    }>;
  };
};

export type BidPackageChangedBiddersNotificationPreviewQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  input: BidPackageInput;
  comment?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type BidPackageChangedBiddersNotificationPreviewQuery = {
  __typename: 'Query';
  bidPackageChangedBiddersNotificationPreview: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type OpenBlindTenderBidsMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type OpenBlindTenderBidsMutation = { __typename: 'Mutation'; openBlindTenderBids: boolean };

export type WithdrawBidInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type WithdrawBidInviteMutation = { __typename: 'Mutation'; withdrawBidInvite: boolean };

export type SendInvitationMutationVariables = Exact<{
  bidInviteId: Scalars['ID']['input'];
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type SendInvitationMutation = { __typename: 'Mutation'; sendInvitation: boolean };

export type BidRequestWithHistoryQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type BidRequestWithHistoryQuery = {
  __typename: 'Query';
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    historyItems: Array<
      | {
          __typename: 'BidInviteStatusChangeResponse';
          id: string;
          bidInviteStatus: BID_INVITE_STATUS_CHANGE_STATUS;
          occurredAt: DateTime;
          userName: string | null;
          contact: {
            __typename: 'Contact';
            id: string;
            isPerson: boolean;
            title: CONTACT_TITLE | null;
            email: string | null;
            firstName: string | null;
            lastName: string | null;
          };
        }
      | {
          __typename: 'BidRequestCustomBidsDueAtChangeResponse';
          id: string;
          occurredAt: DateTime;
          customBidsDueAt: DateTime;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          } | null;
        }
      | {
          __typename: 'BidRequestStatusChangeResponse';
          id: string;
          bidRequestStatus: BID_REQUEST_STATUS;
          occurredAt: DateTime;
          changedBy: BID_REQUEST_STATUS_CHANGED_BY;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
            company: { __typename: 'Company'; id: string; type: COMPANY_TYPE };
          } | null;
          bidRequest: {
            __typename: 'BidRequest';
            id: string;
            source: BID_REQUEST_SOURCE;
            currentBid: {
              __typename: 'SubmittedBid';
              id: string;
              billOfQuantities: {
                __typename: 'BillOfQuantities';
                id: string;
                editingStatus: BOQ_EDITING_STATUS | null;
              } | null;
            } | null;
          };
        }
      | {
          __typename: 'BlindTenderOpeningResponse';
          id: string;
          occurredAt: DateTime;
          users: Array<{
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          }>;
        }
      | {
          __typename: 'SentEmailForStatusHistoryResponse';
          id: string;
          templateName: STATUS_HISTORY_EMAIL_TEMPLATE_NAME;
          occurredAt: DateTime;
          contact: {
            __typename: 'Contact';
            id: string;
            isPerson: boolean;
            title: CONTACT_TITLE | null;
            email: string | null;
            firstName: string | null;
            lastName: string | null;
          };
          sentEmail: {
            __typename: 'SentEmail';
            id: string;
            fromEmail: string;
            fromName: string;
            toEmail: string;
            subject: string | null;
            contentHtml: string | null;
            templateProps: JSONObject;
          };
        }
      | {
          __typename: 'SubmittedBidHistoryItem';
          id: string;
          isSimulated: boolean | null;
          totalNet: number;
          skontoDeadline: number;
          skontoRate: number;
          taxRate: number;
          discountRate: number | null;
          absoluteDiscount: number | null;
          occurredAt: DateTime;
          billOfQuantities: {
            __typename: 'BillOfQuantities';
            id: string;
            editingStatus: BOQ_EDITING_STATUS | null;
          } | null;
          bidRequest: {
            __typename: 'BidRequest';
            id: string;
            source: BID_REQUEST_SOURCE;
            bidPackage: {
              __typename: 'BidPackage';
              id: string;
              currency: CURRENCY;
              isBlindTender: boolean;
              blindTenderBidsOpenedAt: DateTime | null;
            };
          };
          submittedByUser: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
            company: { __typename: 'Company'; id: string; type: COMPANY_TYPE };
          } | null;
        }
      | {
          __typename: 'TermsAcceptanceResponse';
          id: string;
          occurredAt: DateTime;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          } | null;
        }
    >;
  };
};

export type SendBidAwardingAndRejectionEmailsAfterBidRequestWasAwardedMutationVariables = Exact<{
  input: SendAwardingEmailInput;
}>;

export type SendBidAwardingAndRejectionEmailsAfterBidRequestWasAwardedMutation = {
  __typename: 'Mutation';
  sendBidAwardingAndRejectionEmailsAfterBidRequestWasAwarded: boolean;
};

export type SendRejectionEmailMutationVariables = Exact<{
  input: SendRejectionEmailInput;
}>;

export type SendRejectionEmailMutation = {
  __typename: 'Mutation';
  sendBidRejectionEmailAfterBidRequestWasRejected: boolean;
};

export type SubmitBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubmitBidRequestMutation = { __typename: 'Mutation'; submitBidRequest: boolean };

export type NegotiateBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type NegotiateBidRequestMutation = { __typename: 'Mutation'; negotiateBidRequest: boolean };

export type UnAwardBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  newStatus: BID_REQUEST_STATUS;
}>;

export type UnAwardBidRequestMutation = { __typename: 'Mutation'; unAwardBidRequest: boolean };

export type RejectBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  shouldSendNotification: Scalars['Boolean']['input'];
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type RejectBidRequestMutation = { __typename: 'Mutation'; rejectBidRequest: boolean };

export type updateBidRequestProposedAwardedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}>;

export type updateBidRequestProposedAwardedMutation = {
  __typename: 'Mutation';
  updateBidRequestProposedAwarded: boolean;
};

export type RejectSomeBidRequestsMutationVariables = Exact<{
  bidRequestIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  shouldSendNotification: Scalars['Boolean']['input'];
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type RejectSomeBidRequestsMutation = {
  __typename: 'Mutation';
  rejectSomeBidRequests: boolean;
};

export type UnRejectBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnRejectBidRequestMutation = { __typename: 'Mutation'; unRejectBidRequest: boolean };

export type UpdateBidRequestStatusInBiddersMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
}>;

export type UpdateBidRequestStatusInBiddersMutation = {
  __typename: 'Mutation';
  updateBidRequestStatus: boolean;
};

export type UpdateBidRequestStatusInBiddersWithReasonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
  declinedReason?: InputMaybe<BID_REQUEST_DECLINED_REASON>;
  declinedReasonOther?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateBidRequestStatusInBiddersWithReasonMutation = {
  __typename: 'Mutation';
  updateBidRequestStatus: boolean;
};

export type UpdateSomeBidRequestStatusesInBiddersMutationVariables = Exact<{
  bidRequestIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
}>;

export type UpdateSomeBidRequestStatusesInBiddersMutation = {
  __typename: 'Mutation';
  updateSomeBidRequestStatuses: boolean;
};

export type NegotiateSomeBidRequestsMutationVariables = Exact<{
  bidRequestIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type NegotiateSomeBidRequestsMutation = {
  __typename: 'Mutation';
  negotiateSomeBidRequests: boolean;
};

export type DeleteBidMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBidMutation = { __typename: 'Mutation'; deleteBid: boolean };

export type GetDownloadUrlForBidderStatusPdfQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type GetDownloadUrlForBidderStatusPdfQuery = {
  __typename: 'Query';
  downloadUrlForBidderStatusHistoryPdf: string;
};

export type BidderDetailsQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidderDetailsQuery = {
  __typename: 'Query';
  combinedCertificateTypesReport: Array<{
    __typename: 'CertificateTypeReport';
    id: string;
    type: CERTIFICATE_TYPE_TYPE;
    scope: CERTIFICATE_TYPE_SCOPE;
    name: string;
    isExpiryDateMandatory: boolean;
    subcontractorTypeId: string | null;
    status: CERTIFICATE_TYPE_STATUS;
    maxExpiryDate: DateTime | null;
    requestedOn: DateTime | null;
    requestedDueDate: DateTime | null;
    rejectionReason: string | null;
    templateDocument: { __typename: 'Document'; id: string; filename: string; size: number } | null;
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      issueDate: string;
      expiryDate: string | null;
      createdAt: DateTime;
      fileName: string;
      fileSizeBytes: number;
      availability: CERTIFICATE_AVAILABILITY;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: { __typename: 'Project'; id: string; name: string };
      } | null;
    }>;
    pendingCertificateRequestItemsForBidPackages: Array<{
      __typename: 'CertificateRequestItem';
      id: string;
      createdAt: DateTime;
      rejectionReason: string | null;
      certificateRequest: {
        __typename: 'CertificateRequest';
        dueDate: string | null;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          project: { __typename: 'Project'; id: string; name: string };
        } | null;
      };
    }>;
  }>;
};

export type ChangeBidRequestCustomBidsDueAtMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  customBidsDueAt: Scalars['DateTimeISO']['input'];
}>;

export type ChangeBidRequestCustomBidsDueAtMutation = {
  __typename: 'Mutation';
  changeBidRequestCustomBidsDueAt: boolean;
};

export type BidRequestsStatusesQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidRequestsStatusesQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      isProposedAwarded: boolean;
    }>;
  };
};

export type BidRequestsForBidPackageQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  bidPackageId: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<BID_REQUEST_STATUS> | BID_REQUEST_STATUS>;
}>;

export type BidRequestsForBidPackageQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    totalRecords: number;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      updatedAt: DateTime | null;
      currentBidId: string | null;
      source: BID_REQUEST_SOURCE;
      distanceInKms: number | null;
      customBidsDueAt: DateTime | null;
      showAwardedStatusToSubcontractor: boolean | null;
      wasSubcontractorNotifiedOfRejection: boolean | null;
      status: BID_REQUEST_STATUS;
      isProposedAwarded: boolean;
      declinedReason: BID_REQUEST_DECLINED_REASON | null;
      declinedReasonOther: string | null;
      notes: Array<{
        __typename: 'BidRequestNote';
        id: string;
        createdAt: DateTime;
        text: string;
        user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
      }>;
      bidsEligibleForAwarding: Array<{ __typename: 'SubmittedBid'; id: string }>;
      currentBid: {
        __typename: 'SubmittedBid';
        id: string;
        message: string | null;
        totalNet: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        submittedAt: DateTime;
        boqEditingStatus: BOQ_EDITING_STATUS | null;
        hasBoQ: boolean;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
      } | null;
      awardedBid: {
        __typename: 'AwardedBid';
        id: string;
        awardedAt: DateTime;
        awardedSumType: BID_AWARDED_SUM_TYPE | null;
        mainOrderNumber: string | null;
        message: string | null;
        absoluteDiscount: number | null;
        additionalCosts: number;
        discountRate: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        totalNet: number;
        submittedByUser: {
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
        } | null;
        parentBid: { __typename: 'Bid'; id: string } | null;
      } | null;
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        type: SUBCONTRACTOR_TYPE;
        name: string;
        website: string | null;
        companySize: COMPANY_SIZE | null;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        isBanned: boolean;
        isDeleted: boolean;
        bidRequestsAwaitingResponseCount: number;
        contacts: Array<{
          __typename: 'Contact';
          id: string;
          isPerson: boolean;
          isPrivate: boolean;
          email: string | null;
          firstName: string | null;
          lastName: string | null;
          title: CONTACT_TITLE | null;
          jobTitle: string | null;
          phone: string | null;
          mobilePhone: string | null;
          fax: string | null;
        }>;
      };
      contacts: Array<{
        __typename: 'BidRequestContact';
        id: string;
        isPerson: boolean;
        title: CONTACT_TITLE | null;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phone: string | null;
        mobilePhone: string | null;
        fax: string | null;
        bidInvite: {
          __typename: 'BidInvite';
          id: string;
          status: BID_INVITE_STATUS;
          customInvitationEmailIntro: string | null;
        } | null;
      }>;
      submittedBids: Array<{
        __typename: 'SubmittedBid';
        id: string;
        totalNet: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        submittedAt: DateTime;
        version: number;
        isSimulated: boolean | null;
        documentsCount: number;
        message: string | null;
        billOfQuantitiesRevision: {
          __typename: 'BillOfQuantitiesRevision';
          id: string;
          revision: number;
          isShareableWithBidders: boolean;
          wereBiddersNotified: boolean | null;
        };
      }>;
    }>;
  };
};

export type GetDownloadUrlForBiddersListQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
}>;

export type GetDownloadUrlForBiddersListQuery = {
  __typename: 'Query';
  downloadUrlForBiddersListPdf: string;
};

export type DeleteBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBidRequestMutation = { __typename: 'Mutation'; deleteBidRequest: boolean };

export type ExportBidPackageBidsMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  boqFileType: BOQ_FILE_TYPE;
}>;

export type ExportBidPackageBidsMutation = { __typename: 'Mutation'; exportBids: boolean };

export type Bidders_bidPackageFragment = {
  __typename: 'BidPackage';
  id: string;
  status: BID_PACKAGE_STATUS;
  isBlindTender: boolean;
  blindTenderBidsOpenedAt: DateTime | null;
  currency: CURRENCY;
  customInvitationEmailIntro: string | null;
  project: { __typename: 'Project'; id: string; status: PROJECT_STATUS };
};

export type ResetContractBoQFileTreeMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type ResetContractBoQFileTreeMutation = {
  __typename: 'Mutation';
  resetContractBoQFileTree: boolean;
};

export type ToggleContractDocumentRequiringSignatureMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}>;

export type ToggleContractDocumentRequiringSignatureMutation = {
  __typename: 'Mutation';
  toggleContractDocumentRequiringSignature: TOGGLE_CONTRACT_DOCUMENT_RESULT;
};

export type RemoveOutdatedDocumentsMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type RemoveOutdatedDocumentsMutation = {
  __typename: 'Mutation';
  removeOutdatedDocuments: boolean;
};

export type CreateSenderViewUrlMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  redirectUrl: Scalars['String']['input'];
}>;

export type CreateSenderViewUrlMutation = {
  __typename: 'Mutation';
  createDocuSignSenderViewUrl: string;
};

export type RenameContractFileMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
}>;

export type RenameContractFileMutation = { __typename: 'Mutation'; renameContractFile: boolean };

export type CreateContractBoQPDFFromUploadMutationVariables = Exact<{
  documentId: Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  pdfSettings?: InputMaybe<BidPdfSettings>;
}>;

export type CreateContractBoQPDFFromUploadMutation = {
  __typename: 'Mutation';
  createContractBoQPDFFromUpload: boolean;
};

export type GetDownloadURLForBoQContractQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
  sourceBoQContractDocumentId: Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  type: BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT;
}>;

export type GetDownloadURLForBoQContractQuery = {
  __typename: 'Query';
  downloadUrlForContractBoQ: string;
};

export type GenerateContractBoQMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  boqFileType: BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT;
  pdfSettings?: InputMaybe<BidPdfSettings>;
}>;

export type GenerateContractBoQMutation = { __typename: 'Mutation'; generateContractBoQ: boolean };

export type UpdateContractRunStepUserIdsMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  contractRunStepUserIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateContractRunStepUserIdsMutation = {
  __typename: 'Mutation';
  updateContractRunStepUserIds: boolean;
};

export type UpdateContractRunAgentStepContactIdsMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  contactIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateContractRunAgentStepContactIdsMutation = {
  __typename: 'Mutation';
  updateContractRunAgentStepContactIds: boolean;
};

export type CreateSubcontractorPrivateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  contact: ContactInput;
}>;

export type CreateSubcontractorPrivateContactMutation = {
  __typename: 'Mutation';
  createSubcontractorPrivateContact: { __typename: 'Contact'; id: string };
};

export type RequestContractsUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestContractsUpsellMutation = {
  __typename: 'Mutation';
  requestContractsUpsell: boolean;
};

export type SubmitContractMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type SubmitContractMutation = { __typename: 'Mutation'; submitContract: boolean };

export type WithdrawContractMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type WithdrawContractMutation = { __typename: 'Mutation'; withdrawContract: boolean };

export type AwardedBidForBidPackageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AwardedBidForBidPackageQuery = {
  __typename: 'Query';
  awardedBidForBidPackage: {
    __typename: 'AwardedBid';
    additionalCosts: number;
    discountRate: number | null;
    absoluteDiscount: number | null;
    id: string;
    message: string | null;
    skontoDeadline: number;
    skontoRate: number;
    taxRate: number;
    totalNet: number;
    mainOrderNumber: string | null;
    parentBid: {
      __typename: 'Bid';
      id: string;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    } | null;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      contacts: Array<{
        __typename: 'BidRequestContact';
        email: string | null;
        firstName: string | null;
        id: string;
        isPerson: boolean;
        jobTitle: string | null;
        phone: string | null;
        mobilePhone: string | null;
        fax: string | null;
        lastName: string | null;
        title: CONTACT_TITLE | null;
        bidInvite: { __typename: 'BidInvite'; id: string } | null;
      }>;
      subcontractor: {
        __typename: 'Subcontractor';
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        id: string;
        name: string;
        postalCode: string | null;
        street: string | null;
        website: string | null;
        contacts: Array<{
          __typename: 'Contact';
          id: string;
          email: string | null;
          firstName: string | null;
          lastName: string | null;
        }>;
      };
    };
    submittedByUser: {
      __typename: 'User';
      email: string;
      firstName: string | null;
      id: string;
      lastName: string | null;
    } | null;
  } | null;
};

export type ContractUpdateAwardedBidMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAwardedBidInput;
}>;

export type ContractUpdateAwardedBidMutation = {
  __typename: 'Mutation';
  updateAwardedBid: { __typename: 'AwardedBid'; id: string };
};

export type UpdateIsContractSigningEnabledMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  isContractSigningEnabled: Scalars['Boolean']['input'];
}>;

export type UpdateIsContractSigningEnabledMutation = {
  __typename: 'Mutation';
  updateIsContractSigningEnabled: boolean;
};

export type CostComparisonItemPropsFragment = {
  __typename: 'CostComparisonItem';
  bidId: string;
  total: number | null;
  notFilledIn: boolean;
};

export type CostComparisonCostGroupPropsFragment = {
  __typename: 'CostComparisonCostGroup';
  id: string;
  localId: string;
  parentId: string | null;
  designation: string;
  items: Array<{
    __typename: 'CostComparisonItem';
    bidId: string;
    total: number | null;
    notFilledIn: boolean;
  }>;
};

export type ProjectCostGroupCatalogsForCostComparisonQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
}>;

export type ProjectCostGroupCatalogsForCostComparisonQuery = {
  __typename: 'Query';
  projectCostGroupCatalogs: Array<{
    __typename: 'ProjectCostGroupCatalogsResponse';
    catalogType: PROJECT_COST_CATALOG_TYPE;
    customCatalogId: string | null;
    customCatalogName: string | null;
  }>;
  project: {
    __typename: 'Project';
    id: string;
    name: string;
    status: PROJECT_STATUS;
    currency: CURRENCY;
  };
  bidPackage: { __typename: 'BidPackage'; id: string; name: string };
};

export type CostComparisonQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  bidIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CostComparisonQuery = {
  __typename: 'Query';
  costComparison: {
    __typename: 'CostComparisonResponse';
    boqHasElementsNotAssignedToCostGroups: boolean;
    groups: Array<{
      __typename: 'CostComparisonCostGroup';
      id: string;
      localId: string;
      parentId: string | null;
      designation: string;
      children: Array<{
        __typename: 'CostComparisonCostGroup';
        id: string;
        localId: string;
        parentId: string | null;
        designation: string;
        children: Array<{
          __typename: 'CostComparisonCostGroup';
          id: string;
          localId: string;
          parentId: string | null;
          designation: string;
          children: Array<{
            __typename: 'CostComparisonCostGroup';
            id: string;
            localId: string;
            parentId: string | null;
            designation: string;
            children: Array<{
              __typename: 'CostComparisonCostGroup';
              id: string;
              localId: string;
              parentId: string | null;
              designation: string;
              items: Array<{
                __typename: 'CostComparisonItem';
                bidId: string;
                total: number | null;
                notFilledIn: boolean;
              }>;
            }>;
            items: Array<{
              __typename: 'CostComparisonItem';
              bidId: string;
              total: number | null;
              notFilledIn: boolean;
            }>;
          }>;
          items: Array<{
            __typename: 'CostComparisonItem';
            bidId: string;
            total: number | null;
            notFilledIn: boolean;
          }>;
        }>;
        items: Array<{
          __typename: 'CostComparisonItem';
          bidId: string;
          total: number | null;
          notFilledIn: boolean;
        }>;
      }>;
      items: Array<{
        __typename: 'CostComparisonItem';
        bidId: string;
        total: number | null;
        notFilledIn: boolean;
      }>;
    }>;
    totals: Array<{
      __typename: 'CostComparisonItem';
      bidId: string;
      total: number | null;
      notFilledIn: boolean;
    }>;
    bids: Array<{
      __typename: 'SubmittedOrGeneratedSubmittedBid';
      id: string;
      version: number | null;
      isSimulated: boolean | null;
      bidRequest: {
        __typename: 'BidRequest';
        subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
      };
      billOfQuantitiesRevision: {
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
      };
      parentBid: {
        __typename: 'Bid';
        id: string;
        version: number | null;
        billOfQuantitiesRevision: {
          __typename: 'BillOfQuantitiesRevision';
          id: string;
          revision: number;
        };
      } | null;
    }>;
  };
};

export type DownloadUrlForCostComparisonSpreadsheetQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  bidIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  hiddenBidders: Array<HiddenBidder> | HiddenBidder;
}>;

export type DownloadUrlForCostComparisonSpreadsheetQuery = {
  __typename: 'Query';
  downloadUrlForCostComparisonSpreadsheet: string;
};

export type ProjectDocumentsCountForBidPackageQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectDocumentsCountForBidPackageQuery = {
  __typename: 'Query';
  fileTreeWithDocuments: { __typename: 'FileTreeResponse'; documentsCount: number };
};

export type BidPackagesPublishedOnMarketplaceCountQueryVariables = Exact<{ [key: string]: never }>;

export type BidPackagesPublishedOnMarketplaceCountQuery = {
  __typename: 'Query';
  bidPackagesPublishedOnMarketplaceCount: number;
};

export type BidPackagePreparationStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidPackagePreparationStatusQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    billOfQuantitiesId: string | null;
    hasAttachedDocuments: boolean;
    hasBidders: boolean;
    hasBiddersFromCosunoNetwork: boolean;
    hasUnsavedBillOfQuantitiesChanges: boolean;
    hasBillOfQuantitiesSchema: boolean;
    isPublishedOnMarketplace: boolean;
    status: BID_PACKAGE_STATUS;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      isEstimating: boolean;
      status: PROJECT_STATUS;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      office: { __typename: 'Office'; id: string; name: string };
    };
  };
  bidPackageCompletionStatus: Array<
    | {
        __typename: 'BidPackageDuration';
        bidPackageDurationIssue: BP_PUBLICATION_BID_PACKAGE_DURATION_ISSUE | null;
      }
    | { __typename: 'BoQIncluded'; boQIncludedIssue: BP_PUBLICATION_BOQ_INCLUDED_ISSUE | null }
    | {
        __typename: 'ContactDetailsIncluded';
        contactDetailsIncludedIssue: BP_PUBLICATION_CONTACT_DETAILS_INCLUDED_ISSUE | null;
      }
    | {
        __typename: 'DescriptiveTenderSetup';
        descriptiveTenderSetupIssue: BP_PUBLICATION_DESCRIPTIVE_TENDER_SETUP_ISSUE | null;
      }
    | {
        __typename: 'DocumentsIncluded';
        documentsIncludedIssue: BP_PUBLICATION_DOCUMENTS_INCLUDED_ISSUE | null;
      }
    | {
        __typename: 'EnableCosunoMarketplace';
        enableCosunoMarketplaceIssue: BP_PUBLICATION_ENABLE_MARKETPLACE | null;
      }
    | {
        __typename: 'OfficeDetailsIncluded';
        officeDetailsIncludedIssue: BP_PUBLICATION_OFFICE_DETAILS_INCLUDED_ISSUE | null;
      }
    | {
        __typename: 'ReasonableBidDueDate';
        reasonableBidDueDateIssue: BP_PUBLICATION_REASONABLE_BID_DUE_DATE_ISSUE | null;
      }
    | {
        __typename: 'SufficientNumberOfBidders';
        sufficientNumberOfBiddersIssue: BP_PUBLICATION_SUFFICIENT_NUMBER_OF_BIDDERS_ISSUE | null;
      }
  >;
};

export type CompanyTemplatesForSelectorQueryVariables = Exact<{
  type: COMPANY_TEMPLATE_TYPE;
  bidPackageId: Scalars['ID']['input'];
}>;

export type CompanyTemplatesForSelectorQuery = {
  __typename: 'Query';
  companyTemplates: {
    __typename: 'CompanyTemplatesResponse';
    totalRecords: number;
    companyTemplates: Array<{
      __typename: 'CompanyTemplate';
      id: string;
      name: string;
      content: string;
      isDefault: boolean;
    }>;
  };
};

export type BidInviteDefaultIntroQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type BidInviteDefaultIntroQuery = { __typename: 'Query'; bidInviteDefaultIntro: string };

export type BidderIntroductionEmailDefaultContentQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidderIntroductionEmailDefaultContentQuery = {
  __typename: 'Query';
  defaultBidderIntroductionTemplateContent: string;
};

export type BidPackageForPublishingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidPackageForPublishingQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    billOfQuantitiesId: string | null;
    hasAttachedDocuments: boolean;
    hasBidders: boolean;
    hasBiddersFromCosunoNetwork: boolean;
    hasUnsavedBillOfQuantitiesChanges: boolean;
    hasBillOfQuantitiesSchema: boolean;
    isPublishedOnMarketplace: boolean;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      isEstimating: boolean;
      status: PROJECT_STATUS;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      office: { __typename: 'Office'; id: string; name: string };
    };
  };
};

export type PublishBidPackageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  skipInvitations: Scalars['Boolean']['input'];
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  introductionEmailRecipients: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  sendIntroductionEmails: Scalars['Boolean']['input'];
  introductionEmailContent: Scalars['HTML']['input'];
}>;

export type PublishBidPackageMutation = { __typename: 'Mutation'; publishBidPackage: boolean };

export type BidderStatusesQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidderStatusesQuery = {
  __typename: 'Query';
  bidderStatuses: Array<{
    __typename: 'BidderStatus';
    subcontractorId: string;
    subcontractorName: string;
    status: BIDDER_STATUS;
  }>;
};

export type BidPackageOverview_bidPackageFragment = {
  __typename: 'BidPackage';
  id: string;
  number: string | null;
  name: string;
  bidsDueAt: DateTime;
  allowBidsAfterDueDate: boolean;
  isBlindTender: boolean;
  blindTenderBidsOpenedAt: DateTime | null;
  openingDate: DateTime | null;
  bindingPeriod: DateString | null;
  startsOn: DateString | null;
  endsOn: DateString | null;
  awardingDate: DateString | null;
  description: string | null;
  budget: number | null;
  notes: string | null;
  createdAt: DateTime;
  hasBidders: boolean;
  status: BID_PACKAGE_STATUS;
  currency: CURRENCY;
  bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
  bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
  isPublishedOnMarketplace: boolean;
  publishedToMarketplaceAt: DateTime | null;
  allowBiddersDownloadBoQPdf: boolean;
  primaryUser: { __typename: 'User'; id: string } | null;
  bidPackageUsers: Array<{
    __typename: 'BidPackageUser';
    hasPendingMultiUserApprovalRequest: boolean;
    id: string;
    isContactPerson: boolean;
    userRole: string;
    user: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      status: USER_STATUS;
    };
  }>;
};

export type ProjectNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectNameQuery = {
  __typename: 'Query';
  project: { __typename: 'Project'; id: string; name: string; status: PROJECT_STATUS };
};

export type ProjectDetailsForBidPackageOverviewFragment = {
  __typename: 'Project';
  id: string;
  name: string;
  startsOn: string | null;
  endsOn: string | null;
  status: PROJECT_STATUS;
  isTermsAcceptanceRequired: boolean;
  isEstimating: boolean;
  currency: CURRENCY;
  office: {
    __typename: 'Office';
    id: string;
    publishToMarketplaceForEstimation: boolean;
    publishToMarketplaceForAwarding: boolean;
    company: {
      __typename: 'Company';
      id: string;
      bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
      bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
      type: COMPANY_TYPE;
      bidsSubmittedOnMarketplace: number;
      permanentFeatureFlags: JSONObject;
      config: {
        __typename: 'CompanyConfig';
        id: string;
        additionalLanguages: Array<LANGUAGE>;
        premiumEnabled: boolean;
      };
    };
  };
  primaryUser: { __typename: 'User'; id: string } | null;
  projectUsers: Array<{
    __typename: 'ProjectUser';
    id: string;
    userRole: string;
    isContactPerson: boolean;
    user: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      status: USER_STATUS;
      isDeleted: boolean;
    };
  }>;
};

export type ProjectForBidPackageOverviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectForBidPackageOverviewQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    name: string;
    startsOn: string | null;
    endsOn: string | null;
    status: PROJECT_STATUS;
    isTermsAcceptanceRequired: boolean;
    isEstimating: boolean;
    currency: CURRENCY;
    office: {
      __typename: 'Office';
      id: string;
      publishToMarketplaceForEstimation: boolean;
      publishToMarketplaceForAwarding: boolean;
      company: {
        __typename: 'Company';
        id: string;
        bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
        bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
        type: COMPANY_TYPE;
        bidsSubmittedOnMarketplace: number;
        permanentFeatureFlags: JSONObject;
        config: {
          __typename: 'CompanyConfig';
          id: string;
          additionalLanguages: Array<LANGUAGE>;
          premiumEnabled: boolean;
        };
      };
    };
    primaryUser: { __typename: 'User'; id: string } | null;
    projectUsers: Array<{
      __typename: 'ProjectUser';
      id: string;
      userRole: string;
      isContactPerson: boolean;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        status: USER_STATUS;
        isDeleted: boolean;
      };
    }>;
  };
};

export type ContractRunStepUserFragment = {
  __typename: 'User';
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
};

export type ContractRunAgentStepContactFragment = {
  __typename: 'Contact';
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
};

export type BidPackageForShowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidPackageForShowQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    contractDocumentsRequiringSignature: Array<string>;
    contractDocumentsRequiringSignatureTotalSize: number;
    bidRequestsNotNotifiedOfRejection: Array<string>;
    hasContractDocuments: boolean;
    hasContractDocumentsRequiringSignature: boolean;
    hasContractDocumentsRequiringSignatureToggling: boolean;
    hasOutdatedContractBoQ: boolean;
    isDocuSignEnvelopeReset: boolean;
    isDocuSignOpened: boolean;
    isContractSignedByAgent: boolean;
    isContractSignedByPrincipal: boolean;
    isContractSignedDocumentPending: boolean;
    isContractSignedPerUpload: boolean;
    isContractSigningEnabled: boolean;
    isLocked: boolean;
    isLockedDueToPendingRequest: boolean;
    name: string;
    number: string | null;
    openingDate: DateTime | null;
    status: BID_PACKAGE_STATUS;
    isBlindTender: boolean;
    blindTenderBidsOpenedAt: DateTime | null;
    isPublishedOnMarketplace: boolean;
    bidRequestsAwaitingResponseCount: number;
    addedContactIds: Array<string>;
    withdrawnContactIds: Array<string>;
    networkAwaitingResponseCountIfRestricted: number | null;
    awardingDate: DateString | null;
    bidsDueAt: DateTime;
    hasBidders: boolean;
    hasBiddersFromCosunoNetwork: boolean;
    hasAttachedDocuments: boolean;
    billOfQuantitiesId: string | null;
    hasUnsavedBillOfQuantitiesChanges: boolean;
    hasBillOfQuantitiesSchema: boolean;
    currency: CURRENCY;
    customInvitationEmailIntro: string | null;
    contractAssignee: {
      __typename: 'ContractAssignee';
      currentStep: number;
      totalStepsCount: number;
      userFirstName: string | null;
      userLastName: string | null;
      userId: string | null;
    } | null;
    contractAgentAssignee: {
      __typename: 'ContractAgentAssignee';
      currentStep: number;
      totalStepsCount: number;
      name: string | null;
    } | null;
    contractRunStepUsers: Array<{
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
    }>;
    contractRunAgentStepContacts: Array<{
      __typename: 'Contact';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
    }>;
    project: {
      __typename: 'Project';
      id: string;
      status: PROJECT_STATUS;
      name: string;
      number: string | null;
      isEstimating: boolean;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      office: {
        __typename: 'Office';
        id: string;
        name: string;
        company: { __typename: 'Company'; id: string; name: string };
      };
      costGroupCatalogs: Array<{
        __typename: 'ProjectCostGroupCatalog';
        catalogType: PROJECT_COST_CATALOG_TYPE;
        customCatalogId: string | null;
        customCatalogName: string | null;
      }>;
    };
    billOfQuantitiesRevisions: Array<{
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      isShareableWithBidders: boolean;
      wereBiddersNotified: boolean | null;
      requestForProposalBoQId: string | null;
      hasBids: boolean;
    }>;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      customBidsDueAt: DateTime | null;
      subcontractorId: string | null;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
      currentBid: { __typename: 'SubmittedBid'; id: string; hasBoQ: boolean } | null;
      submittedBids: Array<{
        __typename: 'SubmittedBid';
        id: string;
        boqEditingStatus: BOQ_EDITING_STATUS | null;
        hasBoQ: boolean;
      }>;
      contacts: Array<{
        __typename: 'BidRequestContact';
        id: string;
        bidInvite: {
          __typename: 'BidInvite';
          id: string;
          status: BID_INVITE_STATUS;
          bidRequest: { __typename: 'BidRequest'; id: string };
        } | null;
      }>;
    }>;
  };
  bidPackageBoQEditorActions: {
    __typename: 'BidPackageEditorActionsResponse';
    upload: boolean;
    delete: boolean;
    edit: boolean;
  };
};

export type BidPackageForOverviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidPackageForOverviewQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    number: string | null;
    name: string;
    bidsDueAt: DateTime;
    allowBidsAfterDueDate: boolean;
    isBlindTender: boolean;
    blindTenderBidsOpenedAt: DateTime | null;
    openingDate: DateTime | null;
    bindingPeriod: DateString | null;
    startsOn: DateString | null;
    endsOn: DateString | null;
    awardingDate: DateString | null;
    description: string | null;
    budget: number | null;
    notes: string | null;
    createdAt: DateTime;
    hasBidders: boolean;
    status: BID_PACKAGE_STATUS;
    currency: CURRENCY;
    bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
    bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
    isPublishedOnMarketplace: boolean;
    publishedToMarketplaceAt: DateTime | null;
    allowBiddersDownloadBoQPdf: boolean;
    workCategories: Array<{ __typename: 'WorkCategory'; name: string; id: string }>;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      startsOn: string | null;
      endsOn: string | null;
      status: PROJECT_STATUS;
      isTermsAcceptanceRequired: boolean;
      isEstimating: boolean;
      currency: CURRENCY;
      office: {
        __typename: 'Office';
        id: string;
        publishToMarketplaceForEstimation: boolean;
        publishToMarketplaceForAwarding: boolean;
        company: {
          __typename: 'Company';
          id: string;
          bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
          bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
          type: COMPANY_TYPE;
          bidsSubmittedOnMarketplace: number;
          permanentFeatureFlags: JSONObject;
          config: {
            __typename: 'CompanyConfig';
            id: string;
            additionalLanguages: Array<LANGUAGE>;
            premiumEnabled: boolean;
          };
        };
      };
      primaryUser: { __typename: 'User'; id: string } | null;
      projectUsers: Array<{
        __typename: 'ProjectUser';
        id: string;
        userRole: string;
        isContactPerson: boolean;
        user: {
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          status: USER_STATUS;
          isDeleted: boolean;
        };
      }>;
    };
    primaryUser: { __typename: 'User'; id: string } | null;
    bidPackageUsers: Array<{
      __typename: 'BidPackageUser';
      hasPendingMultiUserApprovalRequest: boolean;
      id: string;
      isContactPerson: boolean;
      userRole: string;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        status: USER_STATUS;
      };
    }>;
  };
};

export type BidRequestsForMessagesQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidRequestsForMessagesQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    }>;
  };
};

export type MessageThreadsInBidPackageQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
}>;

export type MessageThreadsInBidPackageQuery = {
  __typename: 'Query';
  messageThreadsInBidPackage: {
    __typename: 'MessageThreadResponse';
    totalRecords: number;
    messageThreads: Array<{
      __typename: 'MessageThread';
      id: string;
      subject: string;
      hasUnreadMessages: boolean;
      respondingCompanyName: string | null;
      participatingCompanyOffice: { __typename: 'Office'; id: string; name: string };
      lastMessage: {
        __typename: 'Message';
        id: string;
        createdAt: DateTime;
        messageText: string;
        recipients: Array<{ __typename: 'MessageRecipient'; name: string }>;
      };
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        project: { __typename: 'Project'; id: string; name: string; number: string | null };
      };
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        type: BID_REQUEST_TYPE;
        status: BID_REQUEST_STATUS;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          number: string | null;
          project: {
            __typename: 'Project';
            id: string;
            name: string;
            number: string | null;
            customer: string | null;
            status: PROJECT_STATUS;
            office: {
              __typename: 'Office';
              id: string;
              name: string;
              company: { __typename: 'Company'; id: string; name: string };
            };
          };
        };
        subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
      };
    }>;
  };
};

export type FindSimilarBidPackageNamesQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type FindSimilarBidPackageNamesQuery = {
  __typename: 'Query';
  findSimilarBidPackageNames: Array<string>;
};

export type GetBidPackageOverviewDownloadUrlQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type GetBidPackageOverviewDownloadUrlQuery = {
  __typename: 'Query';
  downloadUrlForOverviewPdf: string;
};

export type CreateBidPackageMutationVariables = Exact<{
  input: BidPackageInput;
}>;

export type CreateBidPackageMutation = {
  __typename: 'Mutation';
  createBidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    project: { __typename: 'Project'; id: string; name: string; number: string | null };
  };
};

export type UpdateBidPackageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: BidPackageInput;
  notifyBidders: Scalars['Boolean']['input'];
  comment?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type UpdateBidPackageMutation = {
  __typename: 'Mutation';
  updateBidPackage: { __typename: 'BidPackage'; id: string };
};

export type BidPackageForAddedContactIdsQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidPackageForAddedContactIdsQuery = {
  __typename: 'Query';
  bidPackage: { __typename: 'BidPackage'; id: string; addedContactIds: Array<string> };
};

export type BidPackagesQueryVariables = Exact<{
  statuses?: InputMaybe<Array<BID_PACKAGE_STATUS> | BID_PACKAGE_STATUS>;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BID_PACKAGES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  excludeClosedProjects?: InputMaybe<Scalars['Boolean']['input']>;
  projectPhase?: InputMaybe<PROJECT_PHASE>;
  onlyIncludeOwnBidPackages?: InputMaybe<Scalars['Boolean']['input']>;
  onlyIncludeAwaitingResponse?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BidPackagesQuery = {
  __typename: 'Query';
  bidPackages: {
    __typename: 'BidPackagesResponse';
    totalRecords: number;
    bidPackages: Array<{
      __typename: 'BidPackage';
      id: string;
      awardingDate: DateString | null;
      bidsDueAt: DateTime;
      name: string;
      number: string | null;
      status: BID_PACKAGE_STATUS;
      bidRequestsAwaitingResponseCount: number;
      isPublishedOnMarketplace: boolean;
      isVisibleOnMarketplace: boolean;
      project: {
        __typename: 'Project';
        id: string;
        isEstimating: boolean;
        name: string;
        number: string | null;
        status: PROJECT_STATUS;
      };
    }>;
  };
};

export type BidPackagesAwaitingResponseCountQueryVariables = Exact<{ [key: string]: never }>;

export type BidPackagesAwaitingResponseCountQuery = {
  __typename: 'Query';
  bidPackagesAwaitingResponseCount: number;
};

export type TasksForBidRequestQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TASKS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<TASK_STATUS> | TASK_STATUS>;
  withDoneCount: Scalars['Boolean']['input'];
}>;

export type TasksForBidRequestQuery = {
  __typename: 'Query';
  tasks: {
    __typename: 'TasksResponse';
    totalRecords: number;
    tasks: Array<{
      __typename: 'Task';
      id: string;
      name: string;
      status: TASK_STATUS;
      dueAt: DateTime;
      assigneeType: TASK_ASSIGNEE_TYPE;
      assignee: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
      } | null;
      bidRequest: {
        __typename: 'BidRequest';
        subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
      } | null;
    }>;
  };
  doneTasks?: { __typename: 'TasksResponse'; totalRecords: number };
};

export type PricePositionsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PRICE_POSITIONS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  query: Scalars['String']['input'];
  omitBidRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type PricePositionsQuery = {
  __typename: 'Query';
  pricePositions: {
    __typename: 'PricePositionsResponse';
    totalRecords: number;
    pricePositions: Array<{
      __typename: 'PricePosition';
      id: string;
      shortText: string;
      longText: string | null;
      value: number;
      unitTag: string;
      bid: {
        __typename: 'SubmittedBid';
        id: string;
        submittedAt: DateTime;
        bidRequest: {
          __typename: 'BidRequest';
          id: string;
          bidPackage: {
            __typename: 'BidPackage';
            id: string;
            name: string;
            project: { __typename: 'Project'; id: string; name: string };
          };
        };
      };
    }>;
  };
};

export type TotalAvailablePricePositionsQueryVariables = Exact<{
  omitBidRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type TotalAvailablePricePositionsQuery = {
  __typename: 'Query';
  totalAvailablePricePositions: number;
};

export type ClaimSubcontractorBySuggestionMutationVariables = Exact<{
  suggestionId: Scalars['ID']['input'];
}>;

export type ClaimSubcontractorBySuggestionMutation = {
  __typename: 'Mutation';
  claimSubcontractorByMarketplaceSuggestion: boolean;
};

export type ClaimSubcontractorBySentEmailMutationVariables = Exact<{
  sentEmailId: Scalars['ID']['input'];
}>;

export type ClaimSubcontractorBySentEmailMutation = {
  __typename: 'Mutation';
  claimSubcontractorBySentEmailId: boolean;
};

export type BidPackageForReverseMarketplaceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  suggestionId?: InputMaybe<Scalars['ID']['input']>;
  sentEmailId?: InputMaybe<Scalars['ID']['input']>;
  referrerUserId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<LANGUAGE>;
}>;

export type BidPackageForReverseMarketplaceQuery = {
  __typename: 'Query';
  reverseMarketplaceBidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    bidsDueAt: DateTime;
    allowBidsAfterDueDate: boolean;
    bindingPeriod: DateString | null;
    startsOn: DateString | null;
    endsOn: DateString | null;
    status: BID_PACKAGE_STATUS;
    description: string | null;
    currency: CURRENCY;
    isLocked: boolean;
    isBlindTender: boolean;
    blindTenderBidsOpenedAt: DateTime | null;
    openingDate: DateTime | null;
    documentCount: number;
    isPublishedOnMarketplace: boolean;
    reasonForClosing: string | null;
    agentBidRequest: {
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
      isAgentStatusMutable: boolean;
      type: BID_REQUEST_TYPE;
      declinedReason: BID_REQUEST_DECLINED_REASON | null;
      declinedReasonOther: string | null;
      currentBidId: string | null;
      source: BID_REQUEST_SOURCE;
      isVisible: boolean;
      customBidsDueAt: DateTime | null;
      areDocumentsAvailable: boolean;
      documentCount: number;
      distanceInKms: number | null;
      notes: Array<{
        __typename: 'BidRequestNote';
        id: string;
        createdAt: DateTime;
        text: string;
        user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
      }>;
      agentStatusSetBy: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
      currentBid: {
        __typename: 'SubmittedBid';
        id: string;
        totalNet: number;
        discountRate: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        absoluteDiscount: number | null;
        submittedAt: DateTime;
        billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      } | null;
      submittedBids: Array<{
        __typename: 'SubmittedBid';
        id: string;
        totalNet: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        submittedAt: DateTime;
        version: number;
        isSimulated: boolean | null;
        documentsCount: number;
        message: string | null;
        billOfQuantities: {
          __typename: 'BillOfQuantities';
          id: string;
          editingStatus: BOQ_EDITING_STATUS | null;
        } | null;
        billOfQuantitiesRevision: {
          __typename: 'BillOfQuantitiesRevision';
          id: string;
          revision: number;
          isShareableWithBidders: boolean;
          wereBiddersNotified: boolean | null;
        };
      }>;
      awardedBid: { __typename: 'AwardedBid'; id: string } | null;
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        type: SUBCONTRACTOR_TYPE;
        isClaimedByAgentCompany: boolean;
        orderAreaType: ORDER_AREA_TYPE;
        orderRadius: ORDER_RADIUS | null;
        orderAreaStateCodes: Array<STATE_CODE> | null;
        orderAreaCountryCode: COUNTRY_CODE | null;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          name: string;
          isPrimary: boolean;
        }>;
      };
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        bidsDueAt: DateTime;
        allowBidsAfterDueDate: boolean;
        bindingPeriod: DateString | null;
        startsOn: DateString | null;
        endsOn: DateString | null;
        status: BID_PACKAGE_STATUS;
        description: string | null;
        currency: CURRENCY;
        isLocked: boolean;
        isBlindTender: boolean;
        blindTenderBidsOpenedAt: DateTime | null;
        openingDate: DateTime | null;
        documentCount: number;
        isPublishedOnMarketplace: boolean;
        reasonForClosing: string | null;
        contactPersons: Array<{
          __typename: 'BidPackageUser';
          id: string;
          userRole: string;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
            email: string;
            phone: string | null;
            fax: string | null;
            jobTitle: string | null;
          };
        }>;
        primaryContactPerson: {
          __typename: 'PrimaryContactPersonResponse';
          email: string;
          firstName: string | null;
          lastName: string | null;
          phone: string | null;
          role: string | null;
        } | null;
        billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
        billOfQuantitiesRevisions: Array<{
          __typename: 'BillOfQuantitiesRevision';
          id: string;
          revision: number;
          isShareableWithBidders: boolean;
          wereBiddersNotified: boolean | null;
        }>;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          number: string | null;
          location: string;
          customer: string | null;
          startsOn: string | null;
          endsOn: string | null;
          description: string | null;
          status: PROJECT_STATUS;
          imageKeys: Array<string>;
          isEstimating: boolean;
          customerStreet: string | null;
          customerPostalCode: string | null;
          customerCity: string | null;
          customerCountryCode: COUNTRY_CODE | null;
          customerEmail: string | null;
          customerPhone: string | null;
          customerFax: string | null;
          customerWebsite: string | null;
          preferredBoQFileFormat: BOQ_FILE_FORMAT;
          desiredSustainabilityCertification: string | null;
          office: {
            __typename: 'Office';
            id: string;
            name: string;
            logoKey: string | null;
            email: string | null;
            website: string | null;
            phone: string | null;
            fax: string | null;
            street: string | null;
            postalCode: string | null;
            city: string | null;
            countryCode: COUNTRY_CODE | null;
            company: {
              __typename: 'Company';
              id: string;
              name: string;
              createdByAgentCompanyId: string | null;
              type: COMPANY_TYPE;
              bidsSubmittedOnMarketplace: number;
              permanentFeatureFlags: JSONObject;
              config: {
                __typename: 'CompanyConfig';
                id: string;
                additionalLanguages: Array<LANGUAGE>;
                premiumEnabled: boolean;
              };
            };
          };
        };
      };
    } | null;
    contactPersons: Array<{
      __typename: 'BidPackageUser';
      id: string;
      userRole: string;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        phone: string | null;
        fax: string | null;
        jobTitle: string | null;
      };
    }>;
    primaryContactPerson: {
      __typename: 'PrimaryContactPersonResponse';
      email: string;
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
      role: string | null;
    } | null;
    billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    billOfQuantitiesRevisions: Array<{
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      isShareableWithBidders: boolean;
      wereBiddersNotified: boolean | null;
    }>;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      location: string;
      customer: string | null;
      startsOn: string | null;
      endsOn: string | null;
      description: string | null;
      status: PROJECT_STATUS;
      imageKeys: Array<string>;
      isEstimating: boolean;
      customerStreet: string | null;
      customerPostalCode: string | null;
      customerCity: string | null;
      customerCountryCode: COUNTRY_CODE | null;
      customerEmail: string | null;
      customerPhone: string | null;
      customerFax: string | null;
      customerWebsite: string | null;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      desiredSustainabilityCertification: string | null;
      office: {
        __typename: 'Office';
        id: string;
        name: string;
        logoKey: string | null;
        email: string | null;
        website: string | null;
        phone: string | null;
        fax: string | null;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        company: {
          __typename: 'Company';
          id: string;
          name: string;
          createdByAgentCompanyId: string | null;
          type: COMPANY_TYPE;
          bidsSubmittedOnMarketplace: number;
          permanentFeatureFlags: JSONObject;
          config: {
            __typename: 'CompanyConfig';
            id: string;
            additionalLanguages: Array<LANGUAGE>;
            premiumEnabled: boolean;
          };
        };
      };
    };
  };
};

export type BidRequestLandingPreviewBidPackageQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  language?: InputMaybe<LANGUAGE>;
}>;

export type BidRequestLandingPreviewBidPackageQuery = {
  __typename: 'Query';
  bidRequestLandingPreviewBidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    bidsDueAt: DateTime;
    allowBidsAfterDueDate: boolean;
    bindingPeriod: DateString | null;
    startsOn: DateString | null;
    endsOn: DateString | null;
    status: BID_PACKAGE_STATUS;
    description: string | null;
    currency: CURRENCY;
    isLocked: boolean;
    isBlindTender: boolean;
    blindTenderBidsOpenedAt: DateTime | null;
    openingDate: DateTime | null;
    documentCount: number;
    isPublishedOnMarketplace: boolean;
    reasonForClosing: string | null;
    agentBidRequest: {
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
      isAgentStatusMutable: boolean;
      type: BID_REQUEST_TYPE;
      declinedReason: BID_REQUEST_DECLINED_REASON | null;
      declinedReasonOther: string | null;
      currentBidId: string | null;
      source: BID_REQUEST_SOURCE;
      isVisible: boolean;
      customBidsDueAt: DateTime | null;
      areDocumentsAvailable: boolean;
      documentCount: number;
      distanceInKms: number | null;
      notes: Array<{
        __typename: 'BidRequestNote';
        id: string;
        createdAt: DateTime;
        text: string;
        user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
      }>;
      agentStatusSetBy: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
      currentBid: {
        __typename: 'SubmittedBid';
        id: string;
        totalNet: number;
        discountRate: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        absoluteDiscount: number | null;
        submittedAt: DateTime;
        billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      } | null;
      submittedBids: Array<{
        __typename: 'SubmittedBid';
        id: string;
        totalNet: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        submittedAt: DateTime;
        version: number;
        isSimulated: boolean | null;
        documentsCount: number;
        message: string | null;
        billOfQuantities: {
          __typename: 'BillOfQuantities';
          id: string;
          editingStatus: BOQ_EDITING_STATUS | null;
        } | null;
        billOfQuantitiesRevision: {
          __typename: 'BillOfQuantitiesRevision';
          id: string;
          revision: number;
          isShareableWithBidders: boolean;
          wereBiddersNotified: boolean | null;
        };
      }>;
      awardedBid: { __typename: 'AwardedBid'; id: string } | null;
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        type: SUBCONTRACTOR_TYPE;
        isClaimedByAgentCompany: boolean;
        orderAreaType: ORDER_AREA_TYPE;
        orderRadius: ORDER_RADIUS | null;
        orderAreaStateCodes: Array<STATE_CODE> | null;
        orderAreaCountryCode: COUNTRY_CODE | null;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          name: string;
          isPrimary: boolean;
        }>;
      };
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        bidsDueAt: DateTime;
        allowBidsAfterDueDate: boolean;
        bindingPeriod: DateString | null;
        startsOn: DateString | null;
        endsOn: DateString | null;
        status: BID_PACKAGE_STATUS;
        description: string | null;
        currency: CURRENCY;
        isLocked: boolean;
        isBlindTender: boolean;
        blindTenderBidsOpenedAt: DateTime | null;
        openingDate: DateTime | null;
        documentCount: number;
        isPublishedOnMarketplace: boolean;
        reasonForClosing: string | null;
        contactPersons: Array<{
          __typename: 'BidPackageUser';
          id: string;
          userRole: string;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
            email: string;
            phone: string | null;
            fax: string | null;
            jobTitle: string | null;
          };
        }>;
        primaryContactPerson: {
          __typename: 'PrimaryContactPersonResponse';
          email: string;
          firstName: string | null;
          lastName: string | null;
          phone: string | null;
          role: string | null;
        } | null;
        billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
        billOfQuantitiesRevisions: Array<{
          __typename: 'BillOfQuantitiesRevision';
          id: string;
          revision: number;
          isShareableWithBidders: boolean;
          wereBiddersNotified: boolean | null;
        }>;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          number: string | null;
          location: string;
          customer: string | null;
          startsOn: string | null;
          endsOn: string | null;
          description: string | null;
          status: PROJECT_STATUS;
          imageKeys: Array<string>;
          isEstimating: boolean;
          customerStreet: string | null;
          customerPostalCode: string | null;
          customerCity: string | null;
          customerCountryCode: COUNTRY_CODE | null;
          customerEmail: string | null;
          customerPhone: string | null;
          customerFax: string | null;
          customerWebsite: string | null;
          preferredBoQFileFormat: BOQ_FILE_FORMAT;
          desiredSustainabilityCertification: string | null;
          office: {
            __typename: 'Office';
            id: string;
            name: string;
            logoKey: string | null;
            email: string | null;
            website: string | null;
            phone: string | null;
            fax: string | null;
            street: string | null;
            postalCode: string | null;
            city: string | null;
            countryCode: COUNTRY_CODE | null;
            company: {
              __typename: 'Company';
              id: string;
              name: string;
              createdByAgentCompanyId: string | null;
              type: COMPANY_TYPE;
              bidsSubmittedOnMarketplace: number;
              permanentFeatureFlags: JSONObject;
              config: {
                __typename: 'CompanyConfig';
                id: string;
                additionalLanguages: Array<LANGUAGE>;
                premiumEnabled: boolean;
              };
            };
          };
        };
      };
    } | null;
    contactPersons: Array<{
      __typename: 'BidPackageUser';
      id: string;
      userRole: string;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        phone: string | null;
        fax: string | null;
        jobTitle: string | null;
      };
    }>;
    primaryContactPerson: {
      __typename: 'PrimaryContactPersonResponse';
      email: string;
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
      role: string | null;
    } | null;
    billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    billOfQuantitiesRevisions: Array<{
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      isShareableWithBidders: boolean;
      wereBiddersNotified: boolean | null;
    }>;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      location: string;
      customer: string | null;
      startsOn: string | null;
      endsOn: string | null;
      description: string | null;
      status: PROJECT_STATUS;
      imageKeys: Array<string>;
      isEstimating: boolean;
      customerStreet: string | null;
      customerPostalCode: string | null;
      customerCity: string | null;
      customerCountryCode: COUNTRY_CODE | null;
      customerEmail: string | null;
      customerPhone: string | null;
      customerFax: string | null;
      customerWebsite: string | null;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      desiredSustainabilityCertification: string | null;
      office: {
        __typename: 'Office';
        id: string;
        name: string;
        logoKey: string | null;
        email: string | null;
        website: string | null;
        phone: string | null;
        fax: string | null;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        company: {
          __typename: 'Company';
          id: string;
          name: string;
          createdByAgentCompanyId: string | null;
          type: COMPANY_TYPE;
          bidsSubmittedOnMarketplace: number;
          permanentFeatureFlags: JSONObject;
          config: {
            __typename: 'CompanyConfig';
            id: string;
            additionalLanguages: Array<LANGUAGE>;
            premiumEnabled: boolean;
          };
        };
      };
    };
  };
};

export type SubcontractorForSuggestionQueryVariables = Exact<{
  suggestionId: Scalars['ID']['input'];
  language?: InputMaybe<LANGUAGE>;
}>;

export type SubcontractorForSuggestionQuery = {
  __typename: 'Query';
  subcontractorForSuggestion: {
    __typename: 'Subcontractor';
    id: string;
    name: string;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    type: SUBCONTRACTOR_TYPE;
    isClaimedByAgentCompany: boolean;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: ORDER_RADIUS | null;
    orderAreaStateCodes: Array<STATE_CODE> | null;
    orderAreaCountryCode: COUNTRY_CODE | null;
    workCategories: Array<{
      __typename: 'WorkCategoryForSubcontractor';
      id: string;
      name: string;
      isPrimary: boolean;
    }>;
  };
};

export type SubcontractorBySentEmailQueryVariables = Exact<{
  sentEmailId: Scalars['ID']['input'];
  language?: InputMaybe<LANGUAGE>;
}>;

export type SubcontractorBySentEmailQuery = {
  __typename: 'Query';
  subcontractorBySentEmail: {
    __typename: 'Subcontractor';
    id: string;
    name: string;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    type: SUBCONTRACTOR_TYPE;
    isClaimedByAgentCompany: boolean;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: ORDER_RADIUS | null;
    orderAreaStateCodes: Array<STATE_CODE> | null;
    orderAreaCountryCode: COUNTRY_CODE | null;
    workCategories: Array<{
      __typename: 'WorkCategoryForSubcontractor';
      id: string;
      name: string;
      isPrimary: boolean;
    }>;
  } | null;
};

export type LandingPageSubcontractorFragment = {
  __typename: 'Subcontractor';
  id: string;
  name: string;
  street: string | null;
  postalCode: string | null;
  city: string | null;
  countryCode: COUNTRY_CODE | null;
  type: SUBCONTRACTOR_TYPE;
  isClaimedByAgentCompany: boolean;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius: ORDER_RADIUS | null;
  orderAreaStateCodes: Array<STATE_CODE> | null;
  orderAreaCountryCode: COUNTRY_CODE | null;
  workCategories: Array<{
    __typename: 'WorkCategoryForSubcontractor';
    id: string;
    name: string;
    isPrimary: boolean;
  }>;
};

export type BidPackageProjectFragment = {
  __typename: 'Project';
  id: string;
  name: string;
  number: string | null;
  location: string;
  customer: string | null;
  startsOn: string | null;
  endsOn: string | null;
  description: string | null;
  status: PROJECT_STATUS;
  imageKeys: Array<string>;
  isEstimating: boolean;
  customerStreet: string | null;
  customerPostalCode: string | null;
  customerCity: string | null;
  customerCountryCode: COUNTRY_CODE | null;
  customerEmail: string | null;
  customerPhone: string | null;
  customerFax: string | null;
  customerWebsite: string | null;
  preferredBoQFileFormat: BOQ_FILE_FORMAT;
  desiredSustainabilityCertification: string | null;
  office: {
    __typename: 'Office';
    id: string;
    name: string;
    logoKey: string | null;
    email: string | null;
    website: string | null;
    phone: string | null;
    fax: string | null;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    company: {
      __typename: 'Company';
      id: string;
      name: string;
      createdByAgentCompanyId: string | null;
      type: COMPANY_TYPE;
      bidsSubmittedOnMarketplace: number;
      permanentFeatureFlags: JSONObject;
      config: {
        __typename: 'CompanyConfig';
        id: string;
        additionalLanguages: Array<LANGUAGE>;
        premiumEnabled: boolean;
      };
    };
  };
};

export type LandingPageBidPackageFragment = {
  __typename: 'BidPackage';
  id: string;
  name: string;
  number: string | null;
  bidsDueAt: DateTime;
  allowBidsAfterDueDate: boolean;
  bindingPeriod: DateString | null;
  startsOn: DateString | null;
  endsOn: DateString | null;
  status: BID_PACKAGE_STATUS;
  description: string | null;
  currency: CURRENCY;
  isLocked: boolean;
  isBlindTender: boolean;
  blindTenderBidsOpenedAt: DateTime | null;
  openingDate: DateTime | null;
  documentCount: number;
  isPublishedOnMarketplace: boolean;
  reasonForClosing: string | null;
  contactPersons: Array<{
    __typename: 'BidPackageUser';
    id: string;
    userRole: string;
    user: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string | null;
      fax: string | null;
      jobTitle: string | null;
    };
  }>;
  primaryContactPerson: {
    __typename: 'PrimaryContactPersonResponse';
    email: string;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    role: string | null;
  } | null;
  billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
  billOfQuantitiesRevisions: Array<{
    __typename: 'BillOfQuantitiesRevision';
    id: string;
    revision: number;
    isShareableWithBidders: boolean;
    wereBiddersNotified: boolean | null;
  }>;
  project: {
    __typename: 'Project';
    id: string;
    name: string;
    number: string | null;
    location: string;
    customer: string | null;
    startsOn: string | null;
    endsOn: string | null;
    description: string | null;
    status: PROJECT_STATUS;
    imageKeys: Array<string>;
    isEstimating: boolean;
    customerStreet: string | null;
    customerPostalCode: string | null;
    customerCity: string | null;
    customerCountryCode: COUNTRY_CODE | null;
    customerEmail: string | null;
    customerPhone: string | null;
    customerFax: string | null;
    customerWebsite: string | null;
    preferredBoQFileFormat: BOQ_FILE_FORMAT;
    desiredSustainabilityCertification: string | null;
    office: {
      __typename: 'Office';
      id: string;
      name: string;
      logoKey: string | null;
      email: string | null;
      website: string | null;
      phone: string | null;
      fax: string | null;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
        createdByAgentCompanyId: string | null;
        type: COMPANY_TYPE;
        bidsSubmittedOnMarketplace: number;
        permanentFeatureFlags: JSONObject;
        config: {
          __typename: 'CompanyConfig';
          id: string;
          additionalLanguages: Array<LANGUAGE>;
          premiumEnabled: boolean;
        };
      };
    };
  };
};

export type LandingPageBidRequestFragment = {
  __typename: 'BidRequest';
  id: string;
  status: BID_REQUEST_STATUS;
  statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
  isAgentStatusMutable: boolean;
  type: BID_REQUEST_TYPE;
  declinedReason: BID_REQUEST_DECLINED_REASON | null;
  declinedReasonOther: string | null;
  currentBidId: string | null;
  source: BID_REQUEST_SOURCE;
  isVisible: boolean;
  customBidsDueAt: DateTime | null;
  areDocumentsAvailable: boolean;
  documentCount: number;
  distanceInKms: number | null;
  agentStatusSetBy: {
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
  currentBid: {
    __typename: 'SubmittedBid';
    id: string;
    totalNet: number;
    discountRate: number | null;
    skontoDeadline: number;
    skontoRate: number;
    taxRate: number;
    absoluteDiscount: number | null;
    submittedAt: DateTime;
    billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
  } | null;
  submittedBids: Array<{
    __typename: 'SubmittedBid';
    id: string;
    totalNet: number;
    discountRate: number | null;
    absoluteDiscount: number | null;
    skontoDeadline: number;
    skontoRate: number;
    taxRate: number;
    submittedAt: DateTime;
    version: number;
    isSimulated: boolean | null;
    documentsCount: number;
    message: string | null;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
    billOfQuantitiesRevision: {
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      isShareableWithBidders: boolean;
      wereBiddersNotified: boolean | null;
    };
  }>;
  awardedBid: { __typename: 'AwardedBid'; id: string } | null;
  subcontractor: {
    __typename: 'Subcontractor';
    id: string;
    name: string;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    type: SUBCONTRACTOR_TYPE;
    isClaimedByAgentCompany: boolean;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: ORDER_RADIUS | null;
    orderAreaStateCodes: Array<STATE_CODE> | null;
    orderAreaCountryCode: COUNTRY_CODE | null;
    workCategories: Array<{
      __typename: 'WorkCategoryForSubcontractor';
      id: string;
      name: string;
      isPrimary: boolean;
    }>;
  };
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    bidsDueAt: DateTime;
    allowBidsAfterDueDate: boolean;
    bindingPeriod: DateString | null;
    startsOn: DateString | null;
    endsOn: DateString | null;
    status: BID_PACKAGE_STATUS;
    description: string | null;
    currency: CURRENCY;
    isLocked: boolean;
    isBlindTender: boolean;
    blindTenderBidsOpenedAt: DateTime | null;
    openingDate: DateTime | null;
    documentCount: number;
    isPublishedOnMarketplace: boolean;
    reasonForClosing: string | null;
    contactPersons: Array<{
      __typename: 'BidPackageUser';
      id: string;
      userRole: string;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        phone: string | null;
        fax: string | null;
        jobTitle: string | null;
      };
    }>;
    primaryContactPerson: {
      __typename: 'PrimaryContactPersonResponse';
      email: string;
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
      role: string | null;
    } | null;
    billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    billOfQuantitiesRevisions: Array<{
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      isShareableWithBidders: boolean;
      wereBiddersNotified: boolean | null;
    }>;
    project: {
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      location: string;
      customer: string | null;
      startsOn: string | null;
      endsOn: string | null;
      description: string | null;
      status: PROJECT_STATUS;
      imageKeys: Array<string>;
      isEstimating: boolean;
      customerStreet: string | null;
      customerPostalCode: string | null;
      customerCity: string | null;
      customerCountryCode: COUNTRY_CODE | null;
      customerEmail: string | null;
      customerPhone: string | null;
      customerFax: string | null;
      customerWebsite: string | null;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      desiredSustainabilityCertification: string | null;
      office: {
        __typename: 'Office';
        id: string;
        name: string;
        logoKey: string | null;
        email: string | null;
        website: string | null;
        phone: string | null;
        fax: string | null;
        street: string | null;
        postalCode: string | null;
        city: string | null;
        countryCode: COUNTRY_CODE | null;
        company: {
          __typename: 'Company';
          id: string;
          name: string;
          createdByAgentCompanyId: string | null;
          type: COMPANY_TYPE;
          bidsSubmittedOnMarketplace: number;
          permanentFeatureFlags: JSONObject;
          config: {
            __typename: 'CompanyConfig';
            id: string;
            additionalLanguages: Array<LANGUAGE>;
            premiumEnabled: boolean;
          };
        };
      };
    };
  };
};

export type BidRequestForSubcontractorQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  language?: InputMaybe<LANGUAGE>;
}>;

export type BidRequestForSubcontractorQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    id: string;
    status: BID_REQUEST_STATUS;
    statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
    isAgentStatusMutable: boolean;
    type: BID_REQUEST_TYPE;
    declinedReason: BID_REQUEST_DECLINED_REASON | null;
    declinedReasonOther: string | null;
    currentBidId: string | null;
    source: BID_REQUEST_SOURCE;
    isVisible: boolean;
    customBidsDueAt: DateTime | null;
    areDocumentsAvailable: boolean;
    documentCount: number;
    distanceInKms: number | null;
    notes: Array<{
      __typename: 'BidRequestNote';
      id: string;
      createdAt: DateTime;
      text: string;
      user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
    }>;
    agentStatusSetBy: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    } | null;
    currentBid: {
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      absoluteDiscount: number | null;
      submittedAt: DateTime;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    } | null;
    submittedBids: Array<{
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      absoluteDiscount: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      submittedAt: DateTime;
      version: number;
      isSimulated: boolean | null;
      documentsCount: number;
      message: string | null;
      billOfQuantities: {
        __typename: 'BillOfQuantities';
        id: string;
        editingStatus: BOQ_EDITING_STATUS | null;
      } | null;
      billOfQuantitiesRevision: {
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      };
    }>;
    awardedBid: { __typename: 'AwardedBid'; id: string } | null;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      type: SUBCONTRACTOR_TYPE;
      isClaimedByAgentCompany: boolean;
      orderAreaType: ORDER_AREA_TYPE;
      orderRadius: ORDER_RADIUS | null;
      orderAreaStateCodes: Array<STATE_CODE> | null;
      orderAreaCountryCode: COUNTRY_CODE | null;
      workCategories: Array<{
        __typename: 'WorkCategoryForSubcontractor';
        id: string;
        name: string;
        isPrimary: boolean;
      }>;
    };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      bidsDueAt: DateTime;
      allowBidsAfterDueDate: boolean;
      bindingPeriod: DateString | null;
      startsOn: DateString | null;
      endsOn: DateString | null;
      status: BID_PACKAGE_STATUS;
      description: string | null;
      currency: CURRENCY;
      isLocked: boolean;
      isBlindTender: boolean;
      blindTenderBidsOpenedAt: DateTime | null;
      openingDate: DateTime | null;
      documentCount: number;
      isPublishedOnMarketplace: boolean;
      reasonForClosing: string | null;
      contactPersons: Array<{
        __typename: 'BidPackageUser';
        id: string;
        userRole: string;
        user: {
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          phone: string | null;
          fax: string | null;
          jobTitle: string | null;
        };
      }>;
      primaryContactPerson: {
        __typename: 'PrimaryContactPersonResponse';
        email: string;
        firstName: string | null;
        lastName: string | null;
        phone: string | null;
        role: string | null;
      } | null;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      billOfQuantitiesRevisions: Array<{
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      }>;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        location: string;
        customer: string | null;
        startsOn: string | null;
        endsOn: string | null;
        description: string | null;
        status: PROJECT_STATUS;
        imageKeys: Array<string>;
        isEstimating: boolean;
        customerStreet: string | null;
        customerPostalCode: string | null;
        customerCity: string | null;
        customerCountryCode: COUNTRY_CODE | null;
        customerEmail: string | null;
        customerPhone: string | null;
        customerFax: string | null;
        customerWebsite: string | null;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        desiredSustainabilityCertification: string | null;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          logoKey: string | null;
          email: string | null;
          website: string | null;
          phone: string | null;
          fax: string | null;
          street: string | null;
          postalCode: string | null;
          city: string | null;
          countryCode: COUNTRY_CODE | null;
          company: {
            __typename: 'Company';
            id: string;
            name: string;
            createdByAgentCompanyId: string | null;
            type: COMPANY_TYPE;
            bidsSubmittedOnMarketplace: number;
            permanentFeatureFlags: JSONObject;
            config: {
              __typename: 'CompanyConfig';
              id: string;
              additionalLanguages: Array<LANGUAGE>;
              premiumEnabled: boolean;
            };
          };
        };
      };
    };
  };
};

export type MessageThreadsInBidRequestQueryVariables = Exact<{
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  query: Scalars['String']['input'];
}>;

export type MessageThreadsInBidRequestQuery = {
  __typename: 'Query';
  messageThreadsInBidRequest: {
    __typename: 'MessageThreadResponse';
    totalRecords: number;
    messageThreads: Array<{
      __typename: 'MessageThread';
      id: string;
      subject: string;
      hasUnreadMessages: boolean;
      respondingCompanyName: string | null;
      participatingCompanyOffice: { __typename: 'Office'; id: string; name: string };
      lastMessage: {
        __typename: 'Message';
        id: string;
        createdAt: DateTime;
        messageText: string;
        recipients: Array<{ __typename: 'MessageRecipient'; name: string }>;
      };
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        project: { __typename: 'Project'; id: string; name: string; number: string | null };
      };
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        type: BID_REQUEST_TYPE;
        status: BID_REQUEST_STATUS;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          number: string | null;
          project: {
            __typename: 'Project';
            id: string;
            name: string;
            number: string | null;
            customer: string | null;
            status: PROJECT_STATUS;
            office: {
              __typename: 'Office';
              id: string;
              name: string;
              company: { __typename: 'Company'; id: string; name: string };
            };
          };
        };
        subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
      };
    }>;
  };
};

export type GetOrCreateMarketplaceBidRequestMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
}>;

export type GetOrCreateMarketplaceBidRequestMutation = {
  __typename: 'Mutation';
  getOrCreateMarketplaceBidRequest: { __typename: 'BidRequest'; id: string };
};

export type UpdateBidRequestStatusBySubcontractorAndReturnMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
  language?: InputMaybe<LANGUAGE>;
}>;

export type UpdateBidRequestStatusBySubcontractorAndReturnMutation = {
  __typename: 'Mutation';
  updateBidRequestStatusBySubcontractor: {
    __typename: 'BidRequest';
    id: string;
    status: BID_REQUEST_STATUS;
    statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
    isAgentStatusMutable: boolean;
    type: BID_REQUEST_TYPE;
    declinedReason: BID_REQUEST_DECLINED_REASON | null;
    declinedReasonOther: string | null;
    currentBidId: string | null;
    source: BID_REQUEST_SOURCE;
    isVisible: boolean;
    customBidsDueAt: DateTime | null;
    areDocumentsAvailable: boolean;
    documentCount: number;
    distanceInKms: number | null;
    agentStatusSetBy: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    } | null;
    currentBid: {
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      absoluteDiscount: number | null;
      submittedAt: DateTime;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    } | null;
    submittedBids: Array<{
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      absoluteDiscount: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      submittedAt: DateTime;
      version: number;
      isSimulated: boolean | null;
      documentsCount: number;
      message: string | null;
      billOfQuantities: {
        __typename: 'BillOfQuantities';
        id: string;
        editingStatus: BOQ_EDITING_STATUS | null;
      } | null;
      billOfQuantitiesRevision: {
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      };
    }>;
    awardedBid: { __typename: 'AwardedBid'; id: string } | null;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      type: SUBCONTRACTOR_TYPE;
      isClaimedByAgentCompany: boolean;
      orderAreaType: ORDER_AREA_TYPE;
      orderRadius: ORDER_RADIUS | null;
      orderAreaStateCodes: Array<STATE_CODE> | null;
      orderAreaCountryCode: COUNTRY_CODE | null;
      workCategories: Array<{
        __typename: 'WorkCategoryForSubcontractor';
        id: string;
        name: string;
        isPrimary: boolean;
      }>;
    };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      bidsDueAt: DateTime;
      allowBidsAfterDueDate: boolean;
      bindingPeriod: DateString | null;
      startsOn: DateString | null;
      endsOn: DateString | null;
      status: BID_PACKAGE_STATUS;
      description: string | null;
      currency: CURRENCY;
      isLocked: boolean;
      isBlindTender: boolean;
      blindTenderBidsOpenedAt: DateTime | null;
      openingDate: DateTime | null;
      documentCount: number;
      isPublishedOnMarketplace: boolean;
      reasonForClosing: string | null;
      contactPersons: Array<{
        __typename: 'BidPackageUser';
        id: string;
        userRole: string;
        user: {
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          phone: string | null;
          fax: string | null;
          jobTitle: string | null;
        };
      }>;
      primaryContactPerson: {
        __typename: 'PrimaryContactPersonResponse';
        email: string;
        firstName: string | null;
        lastName: string | null;
        phone: string | null;
        role: string | null;
      } | null;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      billOfQuantitiesRevisions: Array<{
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      }>;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        location: string;
        customer: string | null;
        startsOn: string | null;
        endsOn: string | null;
        description: string | null;
        status: PROJECT_STATUS;
        imageKeys: Array<string>;
        isEstimating: boolean;
        customerStreet: string | null;
        customerPostalCode: string | null;
        customerCity: string | null;
        customerCountryCode: COUNTRY_CODE | null;
        customerEmail: string | null;
        customerPhone: string | null;
        customerFax: string | null;
        customerWebsite: string | null;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        desiredSustainabilityCertification: string | null;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          logoKey: string | null;
          email: string | null;
          website: string | null;
          phone: string | null;
          fax: string | null;
          street: string | null;
          postalCode: string | null;
          city: string | null;
          countryCode: COUNTRY_CODE | null;
          company: {
            __typename: 'Company';
            id: string;
            name: string;
            createdByAgentCompanyId: string | null;
            type: COMPANY_TYPE;
            bidsSubmittedOnMarketplace: number;
            permanentFeatureFlags: JSONObject;
            config: {
              __typename: 'CompanyConfig';
              id: string;
              additionalLanguages: Array<LANGUAGE>;
              premiumEnabled: boolean;
            };
          };
        };
      };
    };
  };
};

export type UpdateBidRequestAgentStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS_FOR_AGENT;
  language?: InputMaybe<LANGUAGE>;
}>;

export type UpdateBidRequestAgentStatusMutation = {
  __typename: 'Mutation';
  updateBidRequestAgentStatus: {
    __typename: 'BidRequest';
    id: string;
    status: BID_REQUEST_STATUS;
    statusForAgent: BID_REQUEST_STATUS_FOR_AGENT;
    isAgentStatusMutable: boolean;
    type: BID_REQUEST_TYPE;
    declinedReason: BID_REQUEST_DECLINED_REASON | null;
    declinedReasonOther: string | null;
    currentBidId: string | null;
    source: BID_REQUEST_SOURCE;
    isVisible: boolean;
    customBidsDueAt: DateTime | null;
    areDocumentsAvailable: boolean;
    documentCount: number;
    distanceInKms: number | null;
    agentStatusSetBy: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    } | null;
    currentBid: {
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      absoluteDiscount: number | null;
      submittedAt: DateTime;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    } | null;
    submittedBids: Array<{
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      absoluteDiscount: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      submittedAt: DateTime;
      version: number;
      isSimulated: boolean | null;
      documentsCount: number;
      message: string | null;
      billOfQuantities: {
        __typename: 'BillOfQuantities';
        id: string;
        editingStatus: BOQ_EDITING_STATUS | null;
      } | null;
      billOfQuantitiesRevision: {
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      };
    }>;
    awardedBid: { __typename: 'AwardedBid'; id: string } | null;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      street: string | null;
      postalCode: string | null;
      city: string | null;
      countryCode: COUNTRY_CODE | null;
      type: SUBCONTRACTOR_TYPE;
      isClaimedByAgentCompany: boolean;
      orderAreaType: ORDER_AREA_TYPE;
      orderRadius: ORDER_RADIUS | null;
      orderAreaStateCodes: Array<STATE_CODE> | null;
      orderAreaCountryCode: COUNTRY_CODE | null;
      workCategories: Array<{
        __typename: 'WorkCategoryForSubcontractor';
        id: string;
        name: string;
        isPrimary: boolean;
      }>;
    };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      bidsDueAt: DateTime;
      allowBidsAfterDueDate: boolean;
      bindingPeriod: DateString | null;
      startsOn: DateString | null;
      endsOn: DateString | null;
      status: BID_PACKAGE_STATUS;
      description: string | null;
      currency: CURRENCY;
      isLocked: boolean;
      isBlindTender: boolean;
      blindTenderBidsOpenedAt: DateTime | null;
      openingDate: DateTime | null;
      documentCount: number;
      isPublishedOnMarketplace: boolean;
      reasonForClosing: string | null;
      contactPersons: Array<{
        __typename: 'BidPackageUser';
        id: string;
        userRole: string;
        user: {
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string;
          phone: string | null;
          fax: string | null;
          jobTitle: string | null;
        };
      }>;
      primaryContactPerson: {
        __typename: 'PrimaryContactPersonResponse';
        email: string;
        firstName: string | null;
        lastName: string | null;
        phone: string | null;
        role: string | null;
      } | null;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      billOfQuantitiesRevisions: Array<{
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      }>;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        location: string;
        customer: string | null;
        startsOn: string | null;
        endsOn: string | null;
        description: string | null;
        status: PROJECT_STATUS;
        imageKeys: Array<string>;
        isEstimating: boolean;
        customerStreet: string | null;
        customerPostalCode: string | null;
        customerCity: string | null;
        customerCountryCode: COUNTRY_CODE | null;
        customerEmail: string | null;
        customerPhone: string | null;
        customerFax: string | null;
        customerWebsite: string | null;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        desiredSustainabilityCertification: string | null;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          logoKey: string | null;
          email: string | null;
          website: string | null;
          phone: string | null;
          fax: string | null;
          street: string | null;
          postalCode: string | null;
          city: string | null;
          countryCode: COUNTRY_CODE | null;
          company: {
            __typename: 'Company';
            id: string;
            name: string;
            createdByAgentCompanyId: string | null;
            type: COMPANY_TYPE;
            bidsSubmittedOnMarketplace: number;
            permanentFeatureFlags: JSONObject;
            config: {
              __typename: 'CompanyConfig';
              id: string;
              additionalLanguages: Array<LANGUAGE>;
              premiumEnabled: boolean;
            };
          };
        };
      };
    };
  };
};

export type GetOrCreateBidDraftMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type GetOrCreateBidDraftMutation = {
  __typename: 'Mutation';
  getOrCreateDraftBid: {
    __typename: 'DraftBid';
    id: string;
    message: string | null;
    totalNet: number | null;
    discountRate: number | null;
    skontoDeadline: number | null;
    skontoRate: number | null;
    taxRate: number | null;
    documentsCount: number;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
    warningMetrics: {
      __typename: 'BidWarningMetrics';
      freeQuantityPositionsWithoutQuantitiesIds: Array<string>;
      notFilledPositionsIds: Array<string>;
      hasProposalInDocuments: boolean;
      elementsWithNotFilledBidderInputs: Array<{
        __typename: 'BoQElementWithNotFilledBidderInput';
        elementId: string;
        bidderInputLabel: string | null;
      }>;
    };
  };
};

export type RequestProfileCompletionMutationVariables = Exact<{ [key: string]: never }>;

export type RequestProfileCompletionMutation = {
  __typename: 'Mutation';
  requestProfileCompletion: boolean;
};

export type NetworkSubcontractorsByAgentQueryVariables = Exact<{ [key: string]: never }>;

export type NetworkSubcontractorsByAgentQuery = {
  __typename: 'Query';
  subcontractorsByAgent: Array<{ __typename: 'Subcontractor'; id: string; name: string }>;
};

export type TrackMarketplaceImpressionMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
}>;

export type TrackMarketplaceImpressionMutation = {
  __typename: 'Mutation';
  trackMarketplaceImpression: boolean;
};

export type AgentDeleteBidRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AgentDeleteBidRequestMutation = { __typename: 'Mutation'; deleteBidRequest: boolean };

export type BidsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BIDS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  fetchOwnBids: Scalars['Boolean']['input'];
  projectPhase?: InputMaybe<PROJECT_PHASE>;
}>;

export type BidsQuery = {
  __typename: 'Query';
  bids: {
    __typename: 'BidsResponse';
    totalRecords: number;
    bids: Array<{
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      discountRate: number | null;
      skontoDeadline: number;
      skontoRate: number;
      taxRate: number;
      absoluteDiscount: number | null;
      message: string | null;
      submittedAt: DateTime;
      version: number;
      isSimulated: boolean | null;
      billOfQuantitiesRevision: {
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
      };
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          number: string | null;
          currency: CURRENCY;
          isBlindTender: boolean;
          blindTenderBidsOpenedAt: DateTime | null;
          project: { __typename: 'Project'; id: string; number: string | null; name: string };
        };
      };
    }>;
  };
};

export type ContactsWithNotificationConfigQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ContactsWithNotificationConfigQuery = {
  __typename: 'Query';
  subcontractorForProfile:
    | { __typename: 'MergedIntoSubcontractor'; id: string }
    | {
        __typename: 'Subcontractor';
        id: string;
        contacts: Array<{
          __typename: 'Contact';
          id: string;
          email: string | null;
          notificationConfig: {
            __typename: 'ContactNotificationConfig';
            marketplaceSuggestions: boolean;
            insuranceSuggestions: boolean;
          };
        }>;
      };
};

export type UpdateContactNotificationConfigsMutationVariables = Exact<{
  input: Array<ContactNotificationConfigInput> | ContactNotificationConfigInput;
}>;

export type UpdateContactNotificationConfigsMutation = {
  __typename: 'Mutation';
  updateContactNotificationConfigs: Array<{
    __typename: 'ContactNotificationConfig';
    marketplaceSuggestions: boolean;
    insuranceSuggestions: boolean;
  }>;
};

export type IsCompanyWaitingForSignupApprovalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type IsCompanyWaitingForSignupApprovalQuery = {
  __typename: 'Query';
  isCompanyWaitingForSignupApproval: boolean;
};

export type RequestCompanyBrandingUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCompanyBrandingUpsellMutation = {
  __typename: 'Mutation';
  requestCompanyBrandingUpsell: boolean;
};

export type UpdateCompanyBrandingMutationVariables = Exact<{
  input: CompanyBrandingInput;
}>;

export type UpdateCompanyBrandingMutation = {
  __typename: 'Mutation';
  updateCompanyBranding: { __typename: 'Company'; id: string };
};

export type CertificateTypesQueryVariables = Exact<{ [key: string]: never }>;

export type CertificateTypesQuery = {
  __typename: 'Query';
  companyCertificateTypes: Array<{
    __typename: 'CertificateType';
    id: string;
    name: string;
    isSelectedByDefaultWhenRequesting: boolean;
    isExpiryDateMandatory: boolean;
    scope: CERTIFICATE_TYPE_SCOPE;
    isInUse: boolean;
    templateDocument: { __typename: 'Document'; id: string; filename: string; size: number } | null;
  }>;
};

export type DeleteCompanyCertificateTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCompanyCertificateTypeMutation = {
  __typename: 'Mutation';
  deleteCompanyCertificateType: boolean;
};

export type CompanyProfile_companyFragment = { __typename: 'Company'; id: string; name: string };

export type CompanyTemplatesQueryVariables = Exact<{
  type: COMPANY_TEMPLATE_TYPE;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<COMPANY_TEMPLATE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
}>;

export type CompanyTemplatesQuery = {
  __typename: 'Query';
  companyTemplates: {
    __typename: 'CompanyTemplatesResponse';
    totalRecords: number;
    companyTemplates: Array<{
      __typename: 'CompanyTemplate';
      id: string;
      name: string;
      content: string;
      type: COMPANY_TEMPLATE_TYPE;
      isDefault: boolean;
    }>;
  };
  companyTemplatePreview: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type CreateCompanyTemplateMutationVariables = Exact<{
  input: CompanyTemplateCreateInput;
}>;

export type CreateCompanyTemplateMutation = {
  __typename: 'Mutation';
  createCompanyTemplate: { __typename: 'CompanyTemplate'; id: string };
};

export type UpdateCompanyTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CompanyTemplateUpdateInput;
}>;

export type UpdateCompanyTemplateMutation = {
  __typename: 'Mutation';
  updateCompanyTemplate: { __typename: 'CompanyTemplate'; id: string };
};

export type DeleteCompanyTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCompanyTemplateMutation = {
  __typename: 'Mutation';
  deleteCompanyTemplate: boolean;
};

export type CustomCostGroupCatalogs_CostGroupsFragment = {
  __typename: 'CustomCostGroupDefinition';
  id: string;
  name: string;
  localId: string;
  childCostGroups: Array<{
    __typename: 'CustomCostGroupDefinition';
    id: string;
    name: string;
    localId: string;
    childCostGroups: Array<{
      __typename: 'CustomCostGroupDefinition';
      id: string;
      name: string;
      localId: string;
    }>;
  }>;
};

export type CustomCostGroupCatalogsQueryVariables = Exact<{
  sortBy?: InputMaybe<CUSTOM_COST_GROUP_CATALOGS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
}>;

export type CustomCostGroupCatalogsQuery = {
  __typename: 'Query';
  customCostGroupCatalogs: {
    __typename: 'CustomCostGroupCatalogsResponse';
    customCostGroupCatalogs: Array<{
      __typename: 'CustomCostGroupCatalog';
      id: string;
      name: string;
      status: CUSTOM_COST_GROUP_CATALOG_STATUS;
      isCatalogEditable: boolean;
      updatedAt: DateTime;
      changedByUser: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      };
      costGroups: Array<{
        __typename: 'CustomCostGroupDefinition';
        id: string;
        name: string;
        localId: string;
        childCostGroups: Array<{
          __typename: 'CustomCostGroupDefinition';
          id: string;
          name: string;
          localId: string;
          childCostGroups: Array<{
            __typename: 'CustomCostGroupDefinition';
            id: string;
            name: string;
            localId: string;
          }>;
        }>;
      }>;
    }>;
  };
};

export type CustomCostGroupCatalogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CustomCostGroupCatalogQuery = {
  __typename: 'Query';
  customCostGroupCatalog: {
    __typename: 'CustomCostGroupCatalog';
    id: string;
    name: string;
    status: CUSTOM_COST_GROUP_CATALOG_STATUS;
    isCatalogEditable: boolean;
    updatedAt: DateTime;
    costGroups: Array<{
      __typename: 'CustomCostGroupDefinition';
      id: string;
      name: string;
      localId: string;
      childCostGroups: Array<{
        __typename: 'CustomCostGroupDefinition';
        id: string;
        name: string;
        localId: string;
        childCostGroups: Array<{
          __typename: 'CustomCostGroupDefinition';
          id: string;
          name: string;
          localId: string;
        }>;
      }>;
    }>;
  };
};

export type UpdateCustomCostGroupCatalogMutationVariables = Exact<{
  catalogId: Scalars['ID']['input'];
  input: UpdateCustomCostGroupCatalogInput;
}>;

export type UpdateCustomCostGroupCatalogMutation = {
  __typename: 'Mutation';
  updateCustomCostGroupCatalog: { __typename: 'CustomCostGroupCatalog'; id: string };
};

export type CreateCustomCostGroupCatalogMutationVariables = Exact<{
  name: Scalars['String']['input'];
  customCostGroups: Array<CustomCostGroupDefinitionInput> | CustomCostGroupDefinitionInput;
}>;

export type CreateCustomCostGroupCatalogMutation = {
  __typename: 'Mutation';
  createCustomCostGroupCatalog:
    | { __typename: 'CreateCustomCostGroupCatalogFailure' }
    | { __typename: 'CreateCustomCostGroupCatalogSuccess' };
};

export type DuplicateCustomCostGroupCatalogMutationVariables = Exact<{
  catalogId: Scalars['ID']['input'];
}>;

export type DuplicateCustomCostGroupCatalogMutation = {
  __typename: 'Mutation';
  duplicateCustomCostGroupCatalog: boolean;
};

export type CustomEmailStateQueryVariables = Exact<{ [key: string]: never }>;

export type CustomEmailStateQuery = {
  __typename: 'Query';
  customEmailState: {
    __typename: 'CustomEmailState';
    customEmail: string | null;
    customEmailEnabled: boolean;
    customEmailValidation: {
      __typename: 'CustomEmailValidation';
      overallResult: CUSTOM_EMAIL_VALIDATION_RESULT;
      senderStatus: CUSTOM_EMAIL_VALIDATION_RESULT;
      dkimRecordName: string;
      dkimRecordValue: string;
      dkimStatus: CUSTOM_EMAIL_VALIDATION_RESULT;
      spfRecordValue: string;
      spfStatus: CUSTOM_EMAIL_VALIDATION_RESULT;
      ownershipTokenRecordName: string;
      ownershipToken: string;
    } | null;
  };
};

export type UpdateCustomEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type UpdateCustomEmailMutation = { __typename: 'Mutation'; updateCustomEmail: boolean };

export type ValidateDNSMutationVariables = Exact<{ [key: string]: never }>;

export type ValidateDNSMutation = { __typename: 'Mutation'; validateDNS: boolean };

export type EnableCustomEmailMutationVariables = Exact<{ [key: string]: never }>;

export type EnableCustomEmailMutation = { __typename: 'Mutation'; enableCustomEmail: boolean };

export type DisableCustomEmailMutationVariables = Exact<{ [key: string]: never }>;

export type DisableCustomEmailMutation = { __typename: 'Mutation'; disableCustomEmail: boolean };

export type CustomFieldsQueryVariables = Exact<{
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
}>;

export type CustomFieldsQuery = {
  __typename: 'Query';
  customFields: Array<{
    __typename: 'CustomField';
    id: string;
    isRequired: boolean;
    label: string;
    type: CUSTOM_FIELD_TYPE;
    options: Array<{ __typename: 'CustomFieldOption'; id: string; label: string }> | null;
  }>;
};

export type UpdateCustomFieldsMutationVariables = Exact<{
  fields: Array<CustomFieldInput> | CustomFieldInput;
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
}>;

export type UpdateCustomFieldsMutation = { __typename: 'Mutation'; updateCustomFields: boolean };

export type CustomFieldsUsageQueryVariables = Exact<{ [key: string]: never }>;

export type CustomFieldsUsageQuery = {
  __typename: 'Query';
  customFieldsUsage: Array<{
    __typename: 'CustomFieldsUsage';
    entityType: CUSTOM_FIELD_ENTITY_TYPE;
    count: number;
  }>;
};

export type MultiUserApprovalRulesQueryVariables = Exact<{
  action: MULTI_USER_APPROVAL_ACTION;
}>;

export type MultiUserApprovalRulesQuery = {
  __typename: 'Query';
  multiUserApprovalRules: Array<{
    __typename: 'MultiUserApprovalRule';
    id: string;
    hasPendingRequests: boolean;
    minAmount: number;
    minUsers: number;
    timeLimitMinutes: number | null;
    userRoles: Array<
      | { __typename: 'CustomUserRole'; id: string }
      | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE }
    >;
  }>;
  company: {
    __typename: 'Company';
    id: string;
    customUserRoles: Array<{ __typename: 'CustomUserRole'; id: string; name: string }>;
  };
};

export type UpdateMultiUserApprovalRulesMutationVariables = Exact<{
  action: MULTI_USER_APPROVAL_ACTION;
  inputs: Array<MultiUserApprovalRuleInput> | MultiUserApprovalRuleInput;
}>;

export type UpdateMultiUserApprovalRulesMutation = {
  __typename: 'Mutation';
  updateMultiUserApprovalRules: boolean;
};

export type MultiUserApprovalSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type MultiUserApprovalSummaryQuery = {
  __typename: 'Query';
  multiUserApprovalSummary: {
    __typename: 'MultiUserApprovalSummary';
    approveAddendum: number | null;
    approveInvoice: number | null;
    awardBid: number | null;
    openBids: number | null;
    publishBidPackage: number | null;
    sendContract: number | null;
  };
};

export type OfficesForIndexQueryVariables = Exact<{ [key: string]: never }>;

export type OfficesForIndexQuery = {
  __typename: 'Query';
  offices: Array<{
    __typename: 'Office';
    id: string;
    name: string;
    logoKey: string | null;
    email: string | null;
    phone: string | null;
    fax: string | null;
    website: string | null;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    language: LANGUAGE;
    acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
    status: OFFICE_STATUS;
    currentTerms: { __typename: 'Terms'; id: string; filename: string } | null;
  }>;
};

export type DeleteOfficeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteOfficeMutation = { __typename: 'Mutation'; deleteOffice: boolean };

export type DeactivateOfficeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeactivateOfficeMutation = { __typename: 'Mutation'; deactivateOffice: boolean };

export type ReactivateOfficeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReactivateOfficeMutation = { __typename: 'Mutation'; reactivateOffice: boolean };

export type PermissionsPageUserFragment = {
  __typename: 'User';
  id: string;
  status: USER_STATUS;
  role:
    | { __typename: 'CustomUserRole'; id: string }
    | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE };
};

export type PermissionsPageQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsPageQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UsersResponse';
    users: Array<{
      __typename: 'User';
      id: string;
      status: USER_STATUS;
      role:
        | { __typename: 'CustomUserRole'; id: string }
        | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE };
    }>;
  };
  company: {
    __typename: 'Company';
    id: string;
    customUserRoles: Array<{ __typename: 'CustomUserRole'; id: string; name: string }>;
  };
};

export type PermissionControlsConfigFragment = {
  __typename: 'PermissionControlsConfig';
  name: string | null;
  userAddEditDeactivate: boolean;
  projectsFromAccessibleOffices: boolean;
  projectsAllowDeletingWithNonDraftBidPackages: boolean;
  projectCreateEdit: boolean;
  projectInternalDataView: boolean;
  projectDelete: boolean;
  projectCloseReopen: boolean;
  bidPackageView: boolean;
  bidPackageCreateEditPublish: boolean;
  bidPackageDelete: boolean;
  bidPackageInternalDataView: boolean;
  bidPackageModifyMarketplacePublishing: boolean;
  bidderInviteManageBids: boolean;
  bidderDeleteBids: boolean;
  pctView: boolean;
  pctExport: boolean;
  boqImportEdit: boolean;
  boqDelete: boolean;
  boqTemplateCreateEdit: boolean;
  boqTemplateDelete: boolean;
  messageSend: boolean;
  messageView: boolean;
  contractSend: boolean;
  contractPrepare: boolean;
  reportingView: boolean;
  companySubcontractorsView: boolean;
  companySubcontractorsExport: boolean;
  subcontractorAddEdit: boolean;
  subcontractorBanFavorite: boolean;
  subcontractorDelete: boolean;
  subcontractorRate: boolean;
  subcontractorBidRequestsView: boolean;
  subcontractorNotesViewUpdateCreate: boolean;
  subcontractorCertificatesView: boolean;
  subcontractorCertificatesRequest: boolean;
  subcontractorCertificatesUpdateDelete: boolean;
  certificateTypesCreate: boolean;
  bidPackageProjectDocumentsViewDownload: boolean;
  companyDocumentsViewDownload: boolean;
  invoicesDeleteApprovedRejected: boolean;
};

export type RolePageQueryVariables = Exact<{
  userType: USER_TYPE;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RolePageQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UsersResponse';
    users: Array<{
      __typename: 'User';
      id: string;
      status: USER_STATUS;
      role:
        | { __typename: 'CustomUserRole'; id: string }
        | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE };
    }>;
  };
  permissionControlsConfig: {
    __typename: 'PermissionControlsConfig';
    name: string | null;
    userAddEditDeactivate: boolean;
    projectsFromAccessibleOffices: boolean;
    projectsAllowDeletingWithNonDraftBidPackages: boolean;
    projectCreateEdit: boolean;
    projectInternalDataView: boolean;
    projectDelete: boolean;
    projectCloseReopen: boolean;
    bidPackageView: boolean;
    bidPackageCreateEditPublish: boolean;
    bidPackageDelete: boolean;
    bidPackageInternalDataView: boolean;
    bidPackageModifyMarketplacePublishing: boolean;
    bidderInviteManageBids: boolean;
    bidderDeleteBids: boolean;
    pctView: boolean;
    pctExport: boolean;
    boqImportEdit: boolean;
    boqDelete: boolean;
    boqTemplateCreateEdit: boolean;
    boqTemplateDelete: boolean;
    messageSend: boolean;
    messageView: boolean;
    contractSend: boolean;
    contractPrepare: boolean;
    reportingView: boolean;
    companySubcontractorsView: boolean;
    companySubcontractorsExport: boolean;
    subcontractorAddEdit: boolean;
    subcontractorBanFavorite: boolean;
    subcontractorDelete: boolean;
    subcontractorRate: boolean;
    subcontractorBidRequestsView: boolean;
    subcontractorNotesViewUpdateCreate: boolean;
    subcontractorCertificatesView: boolean;
    subcontractorCertificatesRequest: boolean;
    subcontractorCertificatesUpdateDelete: boolean;
    certificateTypesCreate: boolean;
    bidPackageProjectDocumentsViewDownload: boolean;
    companyDocumentsViewDownload: boolean;
    invoicesDeleteApprovedRejected: boolean;
  };
};

export type UpdatePermissionControlsConfigMutationVariables = Exact<{
  userType: USER_TYPE;
  id?: InputMaybe<Scalars['ID']['input']>;
  config: PermissionControlsConfigInput;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdatePermissionControlsConfigMutation = {
  __typename: 'Mutation';
  updatePermissionControlsConfig: boolean;
};

export type DeleteCustomPermissionControlsConfigMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCustomPermissionControlsConfigMutation = {
  __typename: 'Mutation';
  deleteCustomPermissionControlsConfig: boolean;
};

export type UpdateCompanyRatingCategoriesMutationVariables = Exact<{
  ratingCategories: Array<RATING_CATEGORY> | RATING_CATEGORY;
}>;

export type UpdateCompanyRatingCategoriesMutation = {
  __typename: 'Mutation';
  updateCompanyRatingCategories: boolean;
};

export type UpdateCompanySubcontractorReminderSchedulesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  forAcceptedInvites: BID_INVITE_REMINDER_SCHEDULE;
  forInvitesWithNoResponse: BID_INVITE_REMINDER_SCHEDULE;
  forContracts: CONTRACT_REMINDER_SCHEDULE;
}>;

export type UpdateCompanySubcontractorReminderSchedulesMutation = {
  __typename: 'Mutation';
  updateCompanySubcontractorReminderSchedules: boolean;
};

export type UpdateCompanyMfaConfigMutationVariables = Exact<{
  mfaRequired: Scalars['Boolean']['input'];
}>;

export type UpdateCompanyMfaConfigMutation = {
  __typename: 'Mutation';
  updateCompanyMfaConfig: boolean;
};

export type UpdateCompanySSOConfigMutationVariables = Exact<{
  ssoRequired: Scalars['Boolean']['input'];
  identityProviderEntityId: Scalars['String']['input'];
  identityProviderTargetUrl: Scalars['String']['input'];
  signingCertificate: Scalars['String']['input'];
  ssoFilterDomains: Scalars['Boolean']['input'];
  ssoDomains?: InputMaybe<Array<SSODomainInput> | SSODomainInput>;
}>;

export type UpdateCompanySSOConfigMutation = {
  __typename: 'Mutation';
  updateCompanySSOConfig: boolean;
};

export type CreateSubcontractorImportRequestMutationVariables = Exact<{
  input: CreateSubcontractorImportRequestInput;
}>;

export type CreateSubcontractorImportRequestMutation = {
  __typename: 'Mutation';
  createSubcontractorImportRequest: {
    __typename: 'CreateSubcontractorImportRequestResponse';
    uploadUrl: string;
  };
};

export type SubcontractorImportRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type SubcontractorImportRequestsQuery = {
  __typename: 'Query';
  subcontractorImportRequests: Array<{
    __typename: 'SubcontractorImportRequest';
    id: string;
    fileName: string;
    fileType: SUBCONTRACTOR_IMPORT_REQUEST_FILE_TYPE;
    source: string | null;
    fileUrl: string;
    status: SUBCONTRACTOR_IMPORT_REQUEST_STATUS;
  }>;
};

export type ExternalCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type ExternalCredentialsQuery = {
  __typename: 'Query';
  getExternalCredentials: Array<{
    __typename: 'ExternalCredentialResponse';
    type: EXTERNAL_CREDENTIAL_TYPE;
    username: string;
  }>;
};

export type UpsertExternalCredentialMutationVariables = Exact<{
  input: ExternalCredentialInput;
}>;

export type UpsertExternalCredentialMutation = {
  __typename: 'Mutation';
  upsertExternalCredential: boolean;
};

export type DeleteExternalCredentialMutationVariables = Exact<{
  type: EXTERNAL_CREDENTIAL_TYPE;
}>;

export type DeleteExternalCredentialMutation = {
  __typename: 'Mutation';
  deleteExternalCredential: boolean;
};

export type UsersTableDropdown_userFragment = {
  __typename: 'User';
  id: string;
  status: USER_STATUS;
  invitationLink: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  mfaMethod: MFA_METHOD | null;
  mfaLastResetAt: DateTime | null;
  type: USER_TYPE;
  offices: Array<{ __typename: 'Office'; id: string; name: string }>;
  role:
    | { __typename: 'CustomUserRole'; id: string }
    | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE };
};

export type DeactivateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeactivateUserMutation = { __typename: 'Mutation'; deactivateUser: boolean };

export type ReactivateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReactivateUserMutation = { __typename: 'Mutation'; reactivateUser: boolean };

export type ResendUserInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResendUserInviteMutation = { __typename: 'Mutation'; resendUserInvite: boolean };

export type CancelInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CancelInviteMutation = { __typename: 'Mutation'; cancelInvite: boolean };

export type RestoreInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RestoreInviteMutation = { __typename: 'Mutation'; restoreInvite: boolean };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  type: USER_TYPE;
  customUserRoleId?: InputMaybe<Scalars['ID']['input']>;
  officeIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type UpdateUserMutation = {
  __typename: 'Mutation';
  updateUser: { __typename: 'User'; id: string };
};

export type ApproveJoinRequestMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  type?: InputMaybe<STANDARD_USER_TYPE>;
}>;

export type ApproveJoinRequestMutation = { __typename: 'Mutation'; approveJoinRequest: boolean };

export type DeleteJoinRequestMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type DeleteJoinRequestMutation = { __typename: 'Mutation'; deleteJoinRequest: boolean };

export type CompanyLicenseDataForEditModalQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyLicenseDataForEditModalQuery = {
  __typename: 'Query';
  company: {
    __typename: 'Company';
    id: string;
    config: {
      __typename: 'CompanyConfig';
      id: string;
      fullUserLicenseCount: number | null;
      guestLicenseCount: number | null;
    };
    usedSeats: { __typename: 'CompanyUsedSeatsResponse'; fullUsers: number; guests: number };
  };
};

export type ConfirmInviteRequestByInviteeIdMutationVariables = Exact<{
  inviteeId: Scalars['ID']['input'];
}>;

export type ConfirmInviteRequestByInviteeIdMutation = {
  __typename: 'Mutation';
  confirmInviteRequestByInviteeId: { __typename: 'User'; id: string };
};

export type DeleteInviteRequestMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type DeleteInviteRequestMutation = { __typename: 'Mutation'; deleteInviteRequest: boolean };

export type DeleteFailedInviteMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type DeleteFailedInviteMutation = { __typename: 'Mutation'; deleteFailedInvite: boolean };

export type UsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<USERS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  officeId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<USER_STATUS> | USER_STATUS>;
  userRoles?: InputMaybe<Array<UserRoleInput> | UserRoleInput>;
}>;

export type UsersQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UsersResponse';
    totalRecords: number;
    users: Array<{
      __typename: 'User';
      id: string;
      email: string;
      jobTitle: string | null;
      departmentType: DEPARTMENT_TYPE | null;
      customDepartmentName: string | null;
      status: USER_STATUS;
      lastActivityAt: DateTime | null;
      invitationLink: string;
      firstName: string | null;
      lastName: string | null;
      mfaMethod: MFA_METHOD | null;
      mfaLastResetAt: DateTime | null;
      type: USER_TYPE;
      role:
        | { __typename: 'CustomUserRole'; name: string; id: string }
        | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE };
      offices: Array<{ __typename: 'Office'; id: string; name: string }>;
    }>;
  };
};

export type FiltersDataForUserDirectoryQueryVariables = Exact<{ [key: string]: never }>;

export type FiltersDataForUserDirectoryQuery = {
  __typename: 'Query';
  offices: Array<{ __typename: 'Office'; id: string; name: string }>;
  company: {
    __typename: 'Company';
    id: string;
    customUserRoles: Array<{ __typename: 'CustomUserRole'; id: string; name: string }>;
  };
};

export type CompanyQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyQuery = {
  __typename: 'Query';
  company: {
    __typename: 'Company';
    id: string;
    name: string;
    type: COMPANY_TYPE;
    bidInviteReminderScheduleForAccepted: BID_INVITE_REMINDER_SCHEDULE;
    bidInviteReminderScheduleForNoResponse: BID_INVITE_REMINDER_SCHEDULE;
    contractReminderSchedule: CONTRACT_REMINDER_SCHEDULE;
    mfaRequired: boolean;
    ratingCategories: Array<RATING_CATEGORY>;
    organization: {
      __typename: 'Organization';
      id: string;
      ssoRequired: boolean;
      identityProviderEntityId: string | null;
      identityProviderTargetUrl: string | null;
      signingCertificate: string | null;
      ssoFilterDomains: boolean;
      serviceProviderEntityId: string;
      serviceProviderAcsUrl: string;
      ssoNameId: string;
      ssoDirectLoginUrl: string;
      ssoDomains: Array<{ __typename: 'SSODomain'; domain: string }> | null;
    };
    config: {
      __typename: 'CompanyConfig';
      id: string;
      fullUserLicenseCount: number | null;
      guestLicenseCount: number | null;
    };
    usedSeats: { __typename: 'CompanyUsedSeatsResponse'; fullUsers: number; guests: number };
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateCompanyMutation = {
  __typename: 'Mutation';
  updateCompany: { __typename: 'Company'; id: string; name: string };
};

export type CompanyOnboardingQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyOnboardingQuery = {
  __typename: 'Query';
  companyOnboarding: Array<
    | {
        __typename: 'CompleteCompanyProfile';
        isFulfilled: boolean;
        incompleteOfficeIds: Array<string>;
      }
    | { __typename: 'ImportSubcontractors'; isFulfilled: boolean }
    | { __typename: 'InviteColleagues'; isFulfilled: boolean }
  >;
};

export type CustomCostStructureSelectQueryVariables = Exact<{ [key: string]: never }>;

export type CustomCostStructureSelectQuery = {
  __typename: 'Query';
  customCostGroupCatalogs: {
    __typename: 'CustomCostGroupCatalogsResponse';
    customCostGroupCatalogs: Array<{
      __typename: 'CustomCostGroupCatalog';
      id: string;
      name: string;
      status: CUSTOM_COST_GROUP_CATALOG_STATUS;
    }>;
  };
};

export type RequestGoldPlanOfferMutationVariables = Exact<{ [key: string]: never }>;

export type RequestGoldPlanOfferMutation = {
  __typename: 'Mutation';
  requestGoldPlanOffer: boolean;
};

export type BoQTemplatesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BOQ_TEMPLATES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  fetchOwnBoQTemplates: Scalars['Boolean']['input'];
}>;

export type BoQTemplatesQuery = {
  __typename: 'Query';
  boqTemplates: {
    __typename: 'BoQTemplatesResponse';
    totalRecords: number;
    totalRecordsForCompany: number;
    boqTemplates: Array<{
      __typename: 'BoQTemplate';
      id: string;
      name: string | null;
      updatedAt: DateTime;
      changedByUser: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      };
    }>;
  };
};

export type CreateBoQTemplateMutationVariables = Exact<{
  input: BoQTemplateInput;
}>;

export type CreateBoQTemplateMutation = {
  __typename: 'Mutation';
  createBoQTemplate:
    | { __typename: 'CreateBoQTemplateFailure' }
    | {
        __typename: 'CreateBoQTemplateSuccess';
        template: { __typename: 'BoQTemplate'; id: string; name: string | null };
      };
};

export type DeleteBoQTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBoQTemplateMutation = { __typename: 'Mutation'; deleteBoQTemplate: boolean };

export type ConfirmTemplateBoQHasBeenUploadedMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  uploadKey: Scalars['ID']['input'];
}>;

export type ConfirmTemplateBoQHasBeenUploadedMutation = {
  __typename: 'Mutation';
  confirmTemplateBoQHasBeenUploaded:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
      };
};

export type ConfirmExcelTemplateBoQHasBeenUploadedMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  uploadKey: Scalars['ID']['input'];
  options: ExcelBoQImportOptions;
}>;

export type ConfirmExcelTemplateBoQHasBeenUploadedMutation = {
  __typename: 'Mutation';
  confirmExcelTemplateBoQHasBeenUploaded:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
      };
};

export type CreateInvoiceMutationVariables = Exact<{
  input: CreateInvoiceInput;
}>;

export type CreateInvoiceMutation = { __typename: 'Mutation'; createInvoice: string };

export type BidPackageInvoiceInfoQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidPackageInvoiceInfoQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    budget: number | null;
    currency: CURRENCY;
    id: string;
    name: string;
    number: string | null;
    awardedBid: {
      __typename: 'AwardedBid';
      id: string;
      skontoRate: number;
      skontoDeadline: number;
      absoluteDiscount: number | null;
      discountRate: number | null;
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        contacts: Array<{
          __typename: 'BidRequestContact';
          email: string | null;
          id: string;
          phone: string | null;
          bidInvite: { __typename: 'BidInvite'; id: string } | null;
        }>;
        subcontractor: {
          __typename: 'Subcontractor';
          city: string | null;
          countryCode: COUNTRY_CODE | null;
          id: string;
          name: string;
          postalCode: string | null;
          street: string | null;
        };
      };
    } | null;
    partialInvoices: Array<{
      __typename: 'PartialInvoice';
      approvedTotalNet: number;
      cashDiscountValue: number;
      discountValue: number;
      id: string;
      insuranceValue: number;
      partialInvoiceNumber: number;
      retentionValue: number;
      taxRate: number;
      issuedAt: string;
      customRows: Array<{
        __typename: 'InvoiceCustomRow';
        id: string;
        position: number;
        title: string;
        isNegative: boolean;
        type: INVOICE_CUSTOM_ROW_TYPE;
        rate: number;
        absoluteValue: number;
      }>;
    }>;
    lastInvoice: {
      __typename: 'Invoice';
      id: string;
      subcontractorIban: string;
      subcontractorBic: string;
    } | null;
  };
};

export type InvoiceNotesQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export type InvoiceNotesQuery = {
  __typename: 'Query';
  invoiceNotes: {
    __typename: 'InvoiceNotesResponse';
    totalRecords: number;
    notes: Array<{
      __typename: 'InvoiceNote';
      id: string;
      createdAt: DateTime;
      text: string;
      user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
    }>;
  };
};

export type CreateInvoiceNoteMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
}>;

export type CreateInvoiceNoteMutation = { __typename: 'Mutation'; createInvoiceNote: boolean };

export type UpdateInvoiceMutationVariables = Exact<{
  input: UpdateInvoiceInput;
}>;

export type UpdateInvoiceMutation = { __typename: 'Mutation'; updateInvoice: boolean };

export type InvoiceCoverSheetUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvoiceCoverSheetUrlQuery = { __typename: 'Query'; invoiceCoverSheetUrl: string };

export type ForwardInvoiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ForwardInvoiceMutation = { __typename: 'Mutation'; forwardInvoice: boolean };

export type ApproveInvoiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApproveInvoiceMutation = { __typename: 'Mutation'; approveInvoice: boolean };

export type RejectInvoiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RejectInvoiceMutation = { __typename: 'Mutation'; rejectInvoice: boolean };

export type InvoiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvoiceQuery = {
  __typename: 'Query';
  invoice: {
    __typename: 'Invoice';
    approvedTotalNet: number;
    cashDiscountDeadline: string | null;
    cashDiscountValue: number;
    discountValue: number;
    fileId: string;
    fileName: string;
    fileUrl: string;
    id: string;
    insuranceValue: number;
    issuedAt: string;
    name: string | null;
    nonApprovedTotalNet: number;
    numberExternal: string | null;
    numberInternal: string | null;
    partialInvoiceNumber: number | null;
    paymentDeadline: string;
    receiptDate: string | null;
    retentionValue: number;
    status: INVOICE_STATUS;
    subcontractorBic: string;
    subcontractorCity: string;
    subcontractorCountryCode: COUNTRY_CODE;
    subcontractorEmail: string;
    subcontractorIban: string;
    subcontractorName: string;
    subcontractorPhone: string;
    subcontractorPostalCode: string;
    subcontractorStreet: string;
    taxRate: number;
    customRows: Array<{
      __typename: 'InvoiceCustomRow';
      id: string;
      position: number;
      title: string;
      isNegative: boolean;
      type: INVOICE_CUSTOM_ROW_TYPE;
      rate: number;
      absoluteValue: number;
    }>;
    assignee: {
      __typename: 'InvoiceAssignee';
      currentStep: number | null;
      totalStepsCount: number;
      userFirstName: string | null;
      userId: string;
      userLastName: string | null;
    } | null;
    bidPackage: {
      __typename: 'BidPackage';
      currency: CURRENCY;
      id: string;
      name: string;
      project: { __typename: 'Project'; id: string; name: string; status: PROJECT_STATUS };
    };
    statusChanges: Array<{
      __typename: 'InvoiceStatusChange';
      id: string;
      status: INVOICE_HISTORY_STATUS;
      createdAt: DateTime;
      totalStepsCount: number;
      currentStep: number;
      user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
    }>;
  };
};

export type CreateInvoiceUploadUrlMutationVariables = Exact<{
  fileId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateInvoiceUploadUrlMutation = {
  __typename: 'Mutation';
  createInvoiceUploadUrl: {
    __typename: 'CreateInvoiceUploadUrlResponse';
    id: string;
    uploadUrl: string;
  };
};

export type MarketplaceSuggestionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MarketplaceSuggestionQuery = {
  __typename: 'Query';
  marketplaceSuggestion: { __typename: 'MarketplaceSuggestion'; bidPackageId: string };
};

export type MessageThreadsQueryVariables = Exact<{
  query: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  showMessageThreadsFromAllProjects?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MessageThreadsQuery = {
  __typename: 'Query';
  messageThreads: {
    __typename: 'MessageThreadResponse';
    totalRecords: number;
    messageThreads: Array<{
      __typename: 'MessageThread';
      id: string;
      subject: string;
      hasUnreadMessages: boolean;
      respondingCompanyName: string | null;
      participatingCompanyOffice: { __typename: 'Office'; id: string; name: string };
      lastMessage: {
        __typename: 'Message';
        id: string;
        createdAt: DateTime;
        messageText: string;
        recipients: Array<{ __typename: 'MessageRecipient'; name: string }>;
      };
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        project: { __typename: 'Project'; id: string; name: string; number: string | null };
      };
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        type: BID_REQUEST_TYPE;
        status: BID_REQUEST_STATUS;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          number: string | null;
          project: {
            __typename: 'Project';
            id: string;
            name: string;
            number: string | null;
            customer: string | null;
            status: PROJECT_STATUS;
            office: {
              __typename: 'Office';
              id: string;
              name: string;
              company: { __typename: 'Company'; id: string; name: string };
            };
          };
        };
        subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
      };
    }>;
  };
};

export type BidPackagesForMessageThreadsQueryVariables = Exact<{
  statuses?: InputMaybe<Array<BID_PACKAGE_STATUS> | BID_PACKAGE_STATUS>;
  excludeClosedProjects?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BidPackagesForMessageThreadsQuery = {
  __typename: 'Query';
  bidPackages: { __typename: 'BidPackagesResponse'; totalRecords: number };
};

export type BidRequestsForCommonMessagesQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidRequestsForCommonMessagesQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    }>;
  };
};

export type AgentBidRequestForMessagesQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type AgentBidRequestForMessagesQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    id: string;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      project: {
        __typename: 'Project';
        id: string;
        office: { __typename: 'Office'; id: string; name: string };
      };
    };
  };
};

export type AgentBidRequestsForMessagesQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AgentBidRequestsForMessagesQuery = {
  __typename: 'Query';
  bidRequestsForAgent: { __typename: 'BidRequestsResponse'; totalRecords: number };
};

export type OfficeForm_officeFragment = {
  __typename: 'Office';
  id: string;
  name: string;
  logoKey: string | null;
  email: string | null;
  phone: string | null;
  fax: string | null;
  website: string | null;
  street: string | null;
  postalCode: string | null;
  city: string | null;
  countryCode: COUNTRY_CODE | null;
  language: LANGUAGE;
  acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
  marketplacePublishedBidPackagesCount: number;
  legalDisclosure: string | null;
  publishToMarketplaceForEstimation: boolean;
  publishToMarketplaceForAwarding: boolean;
  aboutUs: string | null;
  aboutUsImageKeys: Array<string>;
  creditreformLink: string | null;
  registrationCity: string | null;
  registrationNumber: string | null;
  registrationPostalCode: string | null;
  tradeRegisterLink: string | null;
  companySize: COMPANY_SIZE | null;
  ownerName: string | null;
  foundingYear: number | null;
  currency: CURRENCY;
  currentTerms: { __typename: 'Terms'; id: string; filename: string } | null;
};

export type OfficeForOfficeFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OfficeForOfficeFormQuery = {
  __typename: 'Query';
  office: {
    __typename: 'Office';
    id: string;
    name: string;
    logoKey: string | null;
    email: string | null;
    phone: string | null;
    fax: string | null;
    website: string | null;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    language: LANGUAGE;
    acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
    marketplacePublishedBidPackagesCount: number;
    legalDisclosure: string | null;
    publishToMarketplaceForEstimation: boolean;
    publishToMarketplaceForAwarding: boolean;
    aboutUs: string | null;
    aboutUsImageKeys: Array<string>;
    creditreformLink: string | null;
    registrationCity: string | null;
    registrationNumber: string | null;
    registrationPostalCode: string | null;
    tradeRegisterLink: string | null;
    companySize: COMPANY_SIZE | null;
    ownerName: string | null;
    foundingYear: number | null;
    currency: CURRENCY;
    currentTerms: { __typename: 'Terms'; id: string; filename: string } | null;
  };
};

export type CreateOfficeMutationVariables = Exact<{
  input: OfficeInput;
}>;

export type CreateOfficeMutation = {
  __typename: 'Mutation';
  createOffice: { __typename: 'Office'; id: string };
};

export type UpdateOfficeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: OfficeInput;
}>;

export type UpdateOfficeMutation = {
  __typename: 'Mutation';
  updateOffice: { __typename: 'Office'; id: string };
};

export type CreateTermsMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  officeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateTermsMutation = {
  __typename: 'Mutation';
  createTerms: {
    __typename: 'CreateTermsResponse';
    id: string;
    filename: string;
    size: number;
    uploadUrl: string;
  };
};

export type RequestNDAUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestNDAUpsellMutation = { __typename: 'Mutation'; requestNDAUpsell: boolean };

export type ExternalApiAccessKeyQueryVariables = Exact<{ [key: string]: never }>;

export type ExternalApiAccessKeyQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; externalApiAccessKey: string };
};

export type UpdateCurrentUserNotificationConfigMutationVariables = Exact<{
  input: UserNotificationConfigInput;
}>;

export type UpdateCurrentUserNotificationConfigMutation = {
  __typename: 'Mutation';
  updateCurrentUserNotificationConfig: boolean;
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMeMutation = {
  __typename: 'Mutation';
  updateMe: {
    __typename: 'User';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    jobTitle: string | null;
    externalCompany: string | null;
    phone: string | null;
    fax: string | null;
  };
};

export type UpdateCurrentUserLanguageMutationVariables = Exact<{
  language: LANGUAGE;
}>;

export type UpdateCurrentUserLanguageMutation = {
  __typename: 'Mutation';
  updateCurrentUserLanguage: boolean;
};

export type UpdatePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;

export type UpdatePasswordMutation = { __typename: 'Mutation'; updatePassword: boolean };

export type RemoveMfaConfigMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveMfaConfigMutation = { __typename: 'Mutation'; removeMfaConfig: boolean };

export type PasswordPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PasswordPolicyQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    passwordPolicy: {
      __typename: 'CompanyPasswordPolicy';
      minLength: number;
      requiresLowerCaseCharacter: boolean;
      requiresUpperCaseCharacter: boolean;
      requiresSpecialCharacter: boolean;
      requiresNumber: boolean;
    } | null;
  };
};

export type RequestAdditionalLanguagesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestAdditionalLanguagesUpsellMutation = {
  __typename: 'Mutation';
  requestAdditionalLanguagesUpsell: boolean;
};

export type UpdateMessagesSignatureMutationVariables = Exact<{
  input: CustomSignatureInput;
}>;

export type UpdateMessagesSignatureMutation = {
  __typename: 'Mutation';
  updateMessagesSignature: boolean;
};

export type ProjectBidPackagesQueryVariables = Exact<{
  statuses?: InputMaybe<Array<BID_PACKAGE_STATUS> | BID_PACKAGE_STATUS>;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BID_PACKAGES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  projectId: Scalars['ID']['input'];
}>;

export type ProjectBidPackagesQuery = {
  __typename: 'Query';
  bidPackages: {
    __typename: 'BidPackagesResponse';
    totalRecords: number;
    bidPackages: Array<{
      __typename: 'BidPackage';
      id: string;
      number: string | null;
      name: string;
      status: BID_PACKAGE_STATUS;
      bidsDueAt: DateTime;
      isPublishedOnMarketplace: boolean;
      isVisibleOnMarketplace: boolean;
      bidRequestsAwaitingResponseCount: number;
      requestStatusCounts: {
        __typename: 'BidRequestStatusCounts';
        bidders: number;
        viewed: number;
        accepted: number;
        bids: number;
        inviteDeclined: number;
      };
    }>;
  };
};

export type CostEstimationStandardCatalogCostGroupPropsFragment = {
  __typename: 'CostEstimationStandardCatalogCostGroup';
  id: string;
  localId: string;
  designation: string;
  costItem: {
    __typename: 'CostItem';
    id: string;
    factor: number | null;
    quantity: number | null;
    unit: string | null;
    unitPrice: number | null;
    taxRate: number | null;
  };
  draftCostItem: {
    __typename: 'CostItem';
    id: string;
    factor: number | null;
    quantity: number | null;
    unit: string | null;
    unitPrice: number | null;
    taxRate: number | null;
  } | null;
};

export type CostEstimationCustomCatalogCostGroupPropsFragment = {
  __typename: 'CostEstimationCustomCatalogCostGroup';
  id: string;
  localId: string;
  name: string;
  costItem: {
    __typename: 'CostItem';
    id: string;
    factor: number | null;
    quantity: number | null;
    unit: string | null;
    unitPrice: number | null;
    taxRate: number | null;
  };
  draftCostItem: {
    __typename: 'CostItem';
    id: string;
    factor: number | null;
    quantity: number | null;
    unit: string | null;
    unitPrice: number | null;
    taxRate: number | null;
  } | null;
};

export type CostEstimatesQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  catalogType: COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CostEstimatesQuery = {
  __typename: 'Query';
  costEstimatesForProject: {
    __typename: 'CostEstimatesForProjectResponse';
    costEstimates: Array<
      | {
          __typename: 'CostEstimationBidPackageEstimate';
          id: string;
          projectId: string;
          catalogType: COST_CATALOG_TYPE;
          customCatalogId: string | null;
          hasUnsavedChanges: boolean;
          bidPackageCostGroups: Array<{
            __typename: 'CostEstimationBidPackageCostGroup';
            id: string;
            bidPackage: {
              __typename: 'CostEstimationBidPackage';
              id: string;
              name: string;
              number: string | null;
            };
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            draftCostItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            } | null;
          }>;
        }
      | {
          __typename: 'CostEstimationCustomCatalogEstimate';
          id: string;
          projectId: string;
          catalogType: COST_CATALOG_TYPE;
          customCatalogId: string | null;
          hasUnsavedChanges: boolean;
          customCatalogCostGroups: Array<{
            __typename: 'CostEstimationCustomCatalogCostGroup';
            id: string;
            localId: string;
            name: string;
            childCostGroups: Array<{
              __typename: 'CostEstimationCustomCatalogCostGroup';
              id: string;
              localId: string;
              name: string;
              childCostGroups: Array<{
                __typename: 'CostEstimationCustomCatalogCostGroup';
                id: string;
                localId: string;
                name: string;
                costItem: {
                  __typename: 'CostItem';
                  id: string;
                  factor: number | null;
                  quantity: number | null;
                  unit: string | null;
                  unitPrice: number | null;
                  taxRate: number | null;
                };
                draftCostItem: {
                  __typename: 'CostItem';
                  id: string;
                  factor: number | null;
                  quantity: number | null;
                  unit: string | null;
                  unitPrice: number | null;
                  taxRate: number | null;
                } | null;
              }>;
              costItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              };
              draftCostItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              } | null;
            }>;
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            draftCostItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            } | null;
          }>;
        }
      | {
          __typename: 'CostEstimationStandardCatalogEstimate';
          id: string;
          projectId: string;
          catalogType: COST_CATALOG_TYPE;
          customCatalogId: string | null;
          hasUnsavedChanges: boolean;
          standardCatalogCostGroups: Array<{
            __typename: 'CostEstimationStandardCatalogCostGroup';
            id: string;
            localId: string;
            designation: string;
            childCostGroups: Array<{
              __typename: 'CostEstimationStandardCatalogCostGroup';
              id: string;
              localId: string;
              designation: string;
              childCostGroups: Array<{
                __typename: 'CostEstimationStandardCatalogCostGroup';
                id: string;
                localId: string;
                designation: string;
                costItem: {
                  __typename: 'CostItem';
                  id: string;
                  factor: number | null;
                  quantity: number | null;
                  unit: string | null;
                  unitPrice: number | null;
                  taxRate: number | null;
                };
                draftCostItem: {
                  __typename: 'CostItem';
                  id: string;
                  factor: number | null;
                  quantity: number | null;
                  unit: string | null;
                  unitPrice: number | null;
                  taxRate: number | null;
                } | null;
              }>;
              costItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              };
              draftCostItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              } | null;
            }>;
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            draftCostItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            } | null;
          }>;
        }
      | {
          __typename: 'CostEstimationStlbWorkCategoryEstimate';
          id: string;
          projectId: string;
          catalogType: COST_CATALOG_TYPE;
          customCatalogId: string | null;
          hasUnsavedChanges: boolean;
          stlbWorkCategoryCostGroups: Array<{
            __typename: 'CostEstimationStlbWorkCategoryCostGroup';
            id: string;
            localId: string;
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            draftCostItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            } | null;
          }>;
        }
    >;
  };
};

export type UpdateCostEstimateForProjectMutationVariables = Exact<{
  costEstimateId: Scalars['ID']['input'];
  costGroupLocalId: Scalars['String']['input'];
  sessionId: Scalars['ID']['input'];
  input: UpdateCostItemInput;
}>;

export type UpdateCostEstimateForProjectMutation = {
  __typename: 'Mutation';
  updateCostEstimateForProject:
    | {
        __typename: 'UpdateBidPackageEstimateForProjectResponse';
        id: string;
        hasUnsavedChanges: boolean;
        bidPackageCostGroups: Array<{
          __typename: 'CostEstimationBidPackageCostGroup';
          id: string;
          bidPackage: {
            __typename: 'CostEstimationBidPackage';
            id: string;
            name: string;
            number: string | null;
          };
          costItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          };
          draftCostItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          } | null;
        }>;
      }
    | {
        __typename: 'UpdateCustomCatalogEstimateForProjectResponse';
        id: string;
        hasUnsavedChanges: boolean;
        customCatalogCostGroups: Array<{
          __typename: 'CostEstimationCustomCatalogCostGroup';
          id: string;
          localId: string;
          name: string;
          childCostGroups: Array<{
            __typename: 'CostEstimationCustomCatalogCostGroup';
            id: string;
            localId: string;
            name: string;
            childCostGroups: Array<{
              __typename: 'CostEstimationCustomCatalogCostGroup';
              id: string;
              localId: string;
              name: string;
              costItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              };
              draftCostItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              } | null;
            }>;
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            draftCostItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            } | null;
          }>;
          costItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          };
          draftCostItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          } | null;
        }>;
      }
    | {
        __typename: 'UpdateStandardCatalogEstimateForProjectResponse';
        id: string;
        hasUnsavedChanges: boolean;
        standardCatalogCostGroups: Array<{
          __typename: 'CostEstimationStandardCatalogCostGroup';
          id: string;
          localId: string;
          designation: string;
          childCostGroups: Array<{
            __typename: 'CostEstimationStandardCatalogCostGroup';
            id: string;
            localId: string;
            designation: string;
            childCostGroups: Array<{
              __typename: 'CostEstimationStandardCatalogCostGroup';
              id: string;
              localId: string;
              designation: string;
              costItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              };
              draftCostItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              } | null;
            }>;
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            draftCostItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            } | null;
          }>;
          costItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          };
          draftCostItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          } | null;
        }>;
      }
    | {
        __typename: 'UpdateStlbWorkCategoryEstimateForProjectResponse';
        id: string;
        hasUnsavedChanges: boolean;
        stlbWorkCategoryCostGroups: Array<{
          __typename: 'CostEstimationStlbWorkCategoryCostGroup';
          id: string;
          localId: string;
          costItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          };
          draftCostItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          } | null;
        }>;
      };
};

export type SaveCostEstimateForProjectMutationVariables = Exact<{
  costEstimateId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
}>;

export type SaveCostEstimateForProjectMutation = {
  __typename: 'Mutation';
  saveCostEstimateForProject:
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      }
    | { __typename: 'SaveCostEstimateForProjectSuccess' };
};

export type DiscardCostEstimateForProjectMutationVariables = Exact<{
  costEstimateId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
}>;

export type DiscardCostEstimateForProjectMutation = {
  __typename: 'Mutation';
  discardCostEstimateForProject:
    | { __typename: 'DiscardCostEstimateForProjectSuccess' }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type DownloadUrlForCostEstimationSpreadsheetQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  catalogType: COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DownloadUrlForCostEstimationSpreadsheetQuery = {
  __typename: 'Query';
  downloadUrlForCostEstimationSpreadsheet: string;
};

export type QueryValidateCostAnalysisForProjectQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  catalogType: COST_CATALOG_TYPE;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type QueryValidateCostAnalysisForProjectQuery = {
  __typename: 'Query';
  validateCostAnalysisForProject: {
    __typename: 'CostAnalysisForProjectValidationResponse';
    validations: {
      __typename: 'CostAnalysisValidations';
      hasMissingBoqVAT: boolean;
      hasMissingBimVAT: boolean;
      hasMissingEstimationVAT: boolean;
      hasUnassignedBimCostGroups: boolean;
      hasUnassignedBoqCostGroups: boolean;
    };
  };
};

export type DownloadUrlForCostAnalysisSpreadsheetQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  breakdown: COST_ANALYSIS_BREAKDOWN;
  topLevelGrouping: COST_ANALYSIS_TOP_LEVEL_GROUPING;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DownloadUrlForCostAnalysisSpreadsheetQuery = {
  __typename: 'Query';
  downloadUrlForCostAnalysisSpreadsheet: string;
};

export type CostAnalysisDefaultBreakdownQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type CostAnalysisDefaultBreakdownQuery = {
  __typename: 'Query';
  defaultCostAnalysisBreakdown: {
    __typename: 'CostAnalysisDefaultBreakdownResponse';
    topLevelGrouping: COST_ANALYSIS_TOP_LEVEL_GROUPING;
    breakdown: COST_ANALYSIS_BREAKDOWN;
    customCatalogId: string | null;
  } | null;
};

export type ProjectReportingQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  breakdown: COST_ANALYSIS_BREAKDOWN;
  topLevelGrouping: COST_ANALYSIS_TOP_LEVEL_GROUPING;
  customCatalogId?: InputMaybe<Scalars['ID']['input']>;
  includeEstimatedBudget: Scalars['Boolean']['input'];
  includeCostEstimate: Scalars['Boolean']['input'];
  includeCostCalculation: Scalars['Boolean']['input'];
  includeAwarded: Scalars['Boolean']['input'];
  includeAddenda: Scalars['Boolean']['input'];
  includeActual: Scalars['Boolean']['input'];
}>;

export type ProjectReportingQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    bidPackagesCurrencies: Array<CURRENCY> | null;
    bidPackagesTotalBudget: number | null;
    budget: number | null;
  };
  costAnalysis: {
    __typename: 'CostAnalysisResponse';
    groups: Array<{
      __typename: 'CostAnalysisCostGroup';
      id: string;
      parentId: string | null;
      localId: string;
      parentLocalId: string | null;
      designation: string | null;
      catalogType: COST_CATALOG_TYPE | null;
      costItemId: string | null;
      referenceId: string | null;
      estimatedBudgetNet?: number | null;
      children: Array<{
        __typename: 'CostAnalysisCostGroup';
        id: string;
        parentId: string | null;
        localId: string;
        parentLocalId: string | null;
        designation: string | null;
        catalogType: COST_CATALOG_TYPE | null;
        costItemId: string | null;
        referenceId: string | null;
        estimatedBudgetNet?: number | null;
        children: Array<{
          __typename: 'CostAnalysisCostGroup';
          id: string;
          parentId: string | null;
          localId: string;
          parentLocalId: string | null;
          designation: string | null;
          catalogType: COST_CATALOG_TYPE | null;
          costItemId: string | null;
          referenceId: string | null;
          estimatedBudgetNet?: number | null;
          children: Array<{
            __typename: 'CostAnalysisCostGroup';
            id: string;
            parentId: string | null;
            localId: string;
            parentLocalId: string | null;
            designation: string | null;
            catalogType: COST_CATALOG_TYPE | null;
            costItemId: string | null;
            referenceId: string | null;
            estimatedBudgetNet?: number | null;
            children: Array<{
              __typename: 'CostAnalysisCostGroup';
              id: string;
              parentId: string | null;
              localId: string;
              parentLocalId: string | null;
              designation: string | null;
              catalogType: COST_CATALOG_TYPE | null;
              costItemId: string | null;
              referenceId: string | null;
              estimatedBudgetNet?: number | null;
              costItem: {
                __typename: 'CostItem';
                id: string;
                factor: number | null;
                quantity: number | null;
                unit: string | null;
                unitPrice: number | null;
                taxRate: number | null;
              };
              costEstimateCost?: {
                __typename: 'CostAnalysisTotals';
                totalNet: number;
                totalGross: number;
              } | null;
              bidPackageCost?: {
                __typename: 'CostAnalysisTotals';
                totalNet: number;
                totalGross: number;
              } | null;
              awardedBidCost?: {
                __typename: 'CostAnalysisTotals';
                totalNet: number;
                totalGross: number;
              } | null;
              addendumCost?: {
                __typename: 'CostAnalysisTotals';
                totalNet: number;
                totalGross: number;
              } | null;
              actualCost?: {
                __typename: 'CostAnalysisTotals';
                totalNet: number;
                totalGross: number;
              } | null;
            }>;
            costItem: {
              __typename: 'CostItem';
              id: string;
              factor: number | null;
              quantity: number | null;
              unit: string | null;
              unitPrice: number | null;
              taxRate: number | null;
            };
            costEstimateCost?: {
              __typename: 'CostAnalysisTotals';
              totalNet: number;
              totalGross: number;
            } | null;
            bidPackageCost?: {
              __typename: 'CostAnalysisTotals';
              totalNet: number;
              totalGross: number;
            } | null;
            awardedBidCost?: {
              __typename: 'CostAnalysisTotals';
              totalNet: number;
              totalGross: number;
            } | null;
            addendumCost?: {
              __typename: 'CostAnalysisTotals';
              totalNet: number;
              totalGross: number;
            } | null;
            actualCost?: {
              __typename: 'CostAnalysisTotals';
              totalNet: number;
              totalGross: number;
            } | null;
          }>;
          costItem: {
            __typename: 'CostItem';
            id: string;
            factor: number | null;
            quantity: number | null;
            unit: string | null;
            unitPrice: number | null;
            taxRate: number | null;
          };
          costEstimateCost?: {
            __typename: 'CostAnalysisTotals';
            totalNet: number;
            totalGross: number;
          } | null;
          bidPackageCost?: {
            __typename: 'CostAnalysisTotals';
            totalNet: number;
            totalGross: number;
          } | null;
          awardedBidCost?: {
            __typename: 'CostAnalysisTotals';
            totalNet: number;
            totalGross: number;
          } | null;
          addendumCost?: {
            __typename: 'CostAnalysisTotals';
            totalNet: number;
            totalGross: number;
          } | null;
          actualCost?: {
            __typename: 'CostAnalysisTotals';
            totalNet: number;
            totalGross: number;
          } | null;
        }>;
        costItem: {
          __typename: 'CostItem';
          id: string;
          factor: number | null;
          quantity: number | null;
          unit: string | null;
          unitPrice: number | null;
          taxRate: number | null;
        };
        costEstimateCost?: {
          __typename: 'CostAnalysisTotals';
          totalNet: number;
          totalGross: number;
        } | null;
        bidPackageCost?: {
          __typename: 'CostAnalysisTotals';
          totalNet: number;
          totalGross: number;
        } | null;
        awardedBidCost?: {
          __typename: 'CostAnalysisTotals';
          totalNet: number;
          totalGross: number;
        } | null;
        addendumCost?: {
          __typename: 'CostAnalysisTotals';
          totalNet: number;
          totalGross: number;
        } | null;
        actualCost?: {
          __typename: 'CostAnalysisTotals';
          totalNet: number;
          totalGross: number;
        } | null;
      }>;
      costItem: {
        __typename: 'CostItem';
        id: string;
        factor: number | null;
        quantity: number | null;
        unit: string | null;
        unitPrice: number | null;
        taxRate: number | null;
      };
      costEstimateCost?: {
        __typename: 'CostAnalysisTotals';
        totalNet: number;
        totalGross: number;
      } | null;
      bidPackageCost?: {
        __typename: 'CostAnalysisTotals';
        totalNet: number;
        totalGross: number;
      } | null;
      awardedBidCost?: {
        __typename: 'CostAnalysisTotals';
        totalNet: number;
        totalGross: number;
      } | null;
      addendumCost?: {
        __typename: 'CostAnalysisTotals';
        totalNet: number;
        totalGross: number;
      } | null;
      actualCost?: {
        __typename: 'CostAnalysisTotals';
        totalNet: number;
        totalGross: number;
      } | null;
    }>;
  };
};

export type CostAnalysisCostGroupPropsFragment = {
  __typename: 'CostAnalysisCostGroup';
  id: string;
  parentId: string | null;
  localId: string;
  parentLocalId: string | null;
  designation: string | null;
  catalogType: COST_CATALOG_TYPE | null;
  costItemId: string | null;
  referenceId: string | null;
  estimatedBudgetNet?: number | null;
  costItem: {
    __typename: 'CostItem';
    id: string;
    factor: number | null;
    quantity: number | null;
    unit: string | null;
    unitPrice: number | null;
    taxRate: number | null;
  };
  costEstimateCost?: {
    __typename: 'CostAnalysisTotals';
    totalNet: number;
    totalGross: number;
  } | null;
  bidPackageCost?: {
    __typename: 'CostAnalysisTotals';
    totalNet: number;
    totalGross: number;
  } | null;
  awardedBidCost?: {
    __typename: 'CostAnalysisTotals';
    totalNet: number;
    totalGross: number;
  } | null;
  addendumCost?: { __typename: 'CostAnalysisTotals'; totalNet: number; totalGross: number } | null;
  actualCost?: { __typename: 'CostAnalysisTotals'; totalNet: number; totalGross: number } | null;
};

export type ProjectCostGroupCatalogsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectCostGroupCatalogsQuery = {
  __typename: 'Query';
  projectCostGroupCatalogs: Array<{
    __typename: 'ProjectCostGroupCatalogsResponse';
    catalogType: PROJECT_COST_CATALOG_TYPE;
    customCatalogId: string | null;
    customCatalogName: string | null;
  }>;
};

export type CostItemPropsFragment = {
  __typename: 'CostItem';
  id: string;
  factor: number | null;
  quantity: number | null;
  unit: string | null;
  unitPrice: number | null;
  taxRate: number | null;
};

export type BaseQuantityFragment = {
  __typename: 'ParsedIfcFileNodeBaseQuantity';
  id: string;
  amount: number;
  unit: string | null;
  type: PARSED_IFC_FILE_NODE_BASE_QUANTITY_TYPE;
};

export type CostFragment = {
  __typename: 'ParsedIfcFileNodeAssociatedCost';
  costGroupLocalId: string | null;
  factor: number | null;
  quantity: number | null;
  quantityFormula: string | null;
  taxRate: number | null;
  unit: string | null;
  unitPrice: number | null;
  bidPackageId: string | null;
};

export type NodeFragment = {
  __typename: 'ParsedIfcFileNode';
  id: string;
  name: string;
  localId: string;
  associatedCost: {
    __typename: 'ParsedIfcFileNodeAssociatedCost';
    costGroupLocalId: string | null;
    factor: number | null;
    quantity: number | null;
    quantityFormula: string | null;
    taxRate: number | null;
    unit: string | null;
    unitPrice: number | null;
    bidPackageId: string | null;
  } | null;
  baseQuantities: Array<{
    __typename: 'ParsedIfcFileNodeBaseQuantity';
    id: string;
    amount: number;
    unit: string | null;
    type: PARSED_IFC_FILE_NODE_BASE_QUANTITY_TYPE;
  }> | null;
};

export type ParsedIfcFileWithAssociatedCostsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}>;

export type ParsedIfcFileWithAssociatedCostsQuery = {
  __typename: 'Query';
  parsedIfcFileWithAssociatedCosts: {
    __typename: 'ParsedIfcFile';
    id: string;
    autodeskObjectId: string;
    autodeskProcessingState: PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE;
    document: { __typename: 'Document'; id: string; filename: string };
    nodes: Array<{
      __typename: 'ParsedIfcFileNode';
      id: string;
      name: string;
      localId: string;
      associatedCost: {
        __typename: 'ParsedIfcFileNodeAssociatedCost';
        costGroupLocalId: string | null;
        factor: number | null;
        quantity: number | null;
        quantityFormula: string | null;
        taxRate: number | null;
        unit: string | null;
        unitPrice: number | null;
        bidPackageId: string | null;
      } | null;
      baseQuantities: Array<{
        __typename: 'ParsedIfcFileNodeBaseQuantity';
        id: string;
        amount: number;
        unit: string | null;
        type: PARSED_IFC_FILE_NODE_BASE_QUANTITY_TYPE;
      }> | null;
    }> | null;
  };
  project: { __typename: 'Project'; currency: CURRENCY };
};

export type UpdateParsedIfcFileWithAssociatedCostsMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
  nodeLocalId: Scalars['ID']['input'];
  input: UpdateParsedIfcFileNodeAssociatedCost;
}>;

export type UpdateParsedIfcFileWithAssociatedCostsMutation = {
  __typename: 'Mutation';
  updateParsedIfcFileWithAssociatedCosts: {
    __typename: 'UpdateParsedIfcFileWithAssociatedCostsResponse';
    id: string;
    affectedNodes: Array<{
      __typename: 'ParsedIfcFileNode';
      id: string;
      name: string;
      localId: string;
      associatedCost: {
        __typename: 'ParsedIfcFileNodeAssociatedCost';
        costGroupLocalId: string | null;
        factor: number | null;
        quantity: number | null;
        quantityFormula: string | null;
        taxRate: number | null;
        unit: string | null;
        unitPrice: number | null;
        bidPackageId: string | null;
      } | null;
      baseQuantities: Array<{
        __typename: 'ParsedIfcFileNodeBaseQuantity';
        id: string;
        amount: number;
        unit: string | null;
        type: PARSED_IFC_FILE_NODE_BASE_QUANTITY_TYPE;
      }> | null;
    }>;
  };
};

export type ParsedIfcFileQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}>;

export type ParsedIfcFileQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    name: string;
    number: string | null;
    status: PROJECT_STATUS;
    costGroupType: COST_GROUP_TYPE;
  };
  parsedIfcFile: {
    __typename: 'ParsedIfcFile';
    id: string;
    autodeskObjectId: string;
    autodeskProcessingState: PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE;
    document: { __typename: 'Document'; id: string; filename: string };
  };
};

export type ProjectChangedBiddersNotificationPreviewQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  input: ProjectInput;
  comment?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type ProjectChangedBiddersNotificationPreviewQuery = {
  __typename: 'Query';
  projectChangedBiddersNotificationPreview: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type ProjectOverview_projectFragment = {
  __typename: 'Project';
  id: string;
  number: string | null;
  name: string;
  imageKeys: Array<string>;
  customer: string | null;
  customerStreet: string | null;
  customerPostalCode: string | null;
  customerCity: string | null;
  customerCountryCode: COUNTRY_CODE | null;
  customerEmail: string | null;
  customerPhone: string | null;
  customerFax: string | null;
  customerWebsite: string | null;
  location: string;
  startsOn: string | null;
  endsOn: string | null;
  description: string | null;
  budget: number | null;
  status: PROJECT_STATUS;
  category: PROJECT_CATEGORY | null;
  defaultTaxRate: number | null;
  marketplacePublishedBidPackagesCount: number;
  isEstimating: boolean;
  currency: CURRENCY;
  requireAcceptingTerms: boolean;
  notes: string | null;
  createdAt: DateTime;
  preferredBoQFileFormat: BOQ_FILE_FORMAT;
  desiredSustainabilityCertification: string | null;
  primaryUser: { __typename: 'User'; id: string } | null;
  projectUsers: Array<{
    __typename: 'ProjectUser';
    id: string;
    hasPendingMultiUserApprovalRequest: boolean;
    userRole: string;
    isContactPerson: boolean;
    user: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      status: USER_STATUS;
    };
  }>;
  billingRunStepUsers: Array<{
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    status: USER_STATUS;
  }>;
  office: {
    __typename: 'Office';
    id: string;
    name: string;
    acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
    status: OFFICE_STATUS;
    currency: CURRENCY;
  };
  costGroupCatalogs: Array<{
    __typename: 'ProjectCostGroupCatalog';
    catalogType: PROJECT_COST_CATALOG_TYPE;
    customCatalog: {
      __typename: 'CustomCostGroupCatalog';
      id: string;
      name: string;
      status: CUSTOM_COST_GROUP_CATALOG_STATUS;
    } | null;
  }>;
  customFields: Array<
    | {
        __typename: 'CustomFieldWithValueCheckbox';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueCheckbox: boolean;
      }
    | {
        __typename: 'CustomFieldWithValueCurrency';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueCurrency: number | null;
      }
    | {
        __typename: 'CustomFieldWithValueDate';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueDate: DateString | null;
      }
    | {
        __typename: 'CustomFieldWithValueDateTime';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueDateTime: DateTime | null;
      }
    | {
        __typename: 'CustomFieldWithValueMultiSelect';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueMultiSelect: Array<string>;
        options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
      }
    | {
        __typename: 'CustomFieldWithValueNumber';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueNumber: number | null;
      }
    | {
        __typename: 'CustomFieldWithValueSingleSelect';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueSingleSelect: string | null;
        options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
      }
    | {
        __typename: 'CustomFieldWithValueText';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueText: string | null;
      }
  > | null;
};

export type ProjectOverview_officeFragment = {
  __typename: 'Office';
  id: string;
  name: string;
  status: OFFICE_STATUS;
  acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
  currency: CURRENCY;
};

export type ProjectForShowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectForShowQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    name: string;
    number: string | null;
    status: PROJECT_STATUS;
    isEstimating: boolean;
    costGroupType: COST_GROUP_TYPE;
    preferredBoQFileFormat: BOQ_FILE_FORMAT;
    currency: CURRENCY;
  };
};

export type ProjectOverviewQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectOverviewQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    number: string | null;
    name: string;
    imageKeys: Array<string>;
    customer: string | null;
    customerStreet: string | null;
    customerPostalCode: string | null;
    customerCity: string | null;
    customerCountryCode: COUNTRY_CODE | null;
    customerEmail: string | null;
    customerPhone: string | null;
    customerFax: string | null;
    customerWebsite: string | null;
    location: string;
    startsOn: string | null;
    endsOn: string | null;
    description: string | null;
    budget: number | null;
    status: PROJECT_STATUS;
    category: PROJECT_CATEGORY | null;
    defaultTaxRate: number | null;
    marketplacePublishedBidPackagesCount: number;
    isEstimating: boolean;
    currency: CURRENCY;
    requireAcceptingTerms: boolean;
    notes: string | null;
    createdAt: DateTime;
    preferredBoQFileFormat: BOQ_FILE_FORMAT;
    desiredSustainabilityCertification: string | null;
    primaryUser: { __typename: 'User'; id: string } | null;
    projectUsers: Array<{
      __typename: 'ProjectUser';
      id: string;
      hasPendingMultiUserApprovalRequest: boolean;
      userRole: string;
      isContactPerson: boolean;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
        status: USER_STATUS;
      };
    }>;
    billingRunStepUsers: Array<{
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      status: USER_STATUS;
    }>;
    office: {
      __typename: 'Office';
      id: string;
      name: string;
      acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
      status: OFFICE_STATUS;
      currency: CURRENCY;
    };
    costGroupCatalogs: Array<{
      __typename: 'ProjectCostGroupCatalog';
      catalogType: PROJECT_COST_CATALOG_TYPE;
      customCatalog: {
        __typename: 'CustomCostGroupCatalog';
        id: string;
        name: string;
        status: CUSTOM_COST_GROUP_CATALOG_STATUS;
      } | null;
    }>;
    customFields: Array<
      | {
          __typename: 'CustomFieldWithValueCheckbox';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueCheckbox: boolean;
        }
      | {
          __typename: 'CustomFieldWithValueCurrency';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueCurrency: number | null;
        }
      | {
          __typename: 'CustomFieldWithValueDate';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueDate: DateString | null;
        }
      | {
          __typename: 'CustomFieldWithValueDateTime';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueDateTime: DateTime | null;
        }
      | {
          __typename: 'CustomFieldWithValueMultiSelect';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueMultiSelect: Array<string>;
          options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
        }
      | {
          __typename: 'CustomFieldWithValueNumber';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueNumber: number | null;
        }
      | {
          __typename: 'CustomFieldWithValueSingleSelect';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueSingleSelect: string | null;
          options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
        }
      | {
          __typename: 'CustomFieldWithValueText';
          id: string;
          isRequired: boolean;
          label: string;
          entityId: string | null;
          valueText: string | null;
        }
    > | null;
  };
  offices: Array<{
    __typename: 'Office';
    id: string;
    name: string;
    status: OFFICE_STATUS;
    acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
    currency: CURRENCY;
  }>;
};

export type OfficesForProjectOverviewCreateQueryVariables = Exact<{ [key: string]: never }>;

export type OfficesForProjectOverviewCreateQuery = {
  __typename: 'Query';
  offices: Array<{
    __typename: 'Office';
    id: string;
    name: string;
    status: OFFICE_STATUS;
    acceptingTerms: OFFICE_REQUIRE_TERMS_OPTION;
    currency: CURRENCY;
  }>;
};

export type ProjectDefaultTaxRateQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectDefaultTaxRateQuery = {
  __typename: 'Query';
  project: { __typename: 'Project'; id: string; defaultTaxRate: number | null };
};

export type ProjectsCountQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  sortBy: PROJECTS_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
}>;

export type ProjectsCountQuery = {
  __typename: 'Query';
  projects: { __typename: 'ProjectsResponse'; totalRecords: number };
};

export type CloseProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  emailTemplate?: InputMaybe<Scalars['HTML']['input']>;
  emailType: PROJECT_CLOSE_EMAIL_TYPE;
  shouldNotifyBidders: Scalars['Boolean']['input'];
}>;

export type CloseProjectMutation = { __typename: 'Mutation'; closeProject: boolean };

export type ReopenProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReopenProjectMutation = { __typename: 'Mutation'; reopenProject: boolean };

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ProjectInput;
  notifyBidders: Scalars['Boolean']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateProjectMutation = {
  __typename: 'Mutation';
  updateProject: { __typename: 'Project'; id: string };
};

export type CreateProjectMutationVariables = Exact<{
  input: ProjectInput;
  projectTemplateInput?: InputMaybe<ProjectTemplateInput>;
}>;

export type CreateProjectMutation = {
  __typename: 'Mutation';
  createProject: { __typename: 'Project'; id: string };
};

export type DownloadUrlForProjectOverviewPdfQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DownloadUrlForProjectOverviewPdfQuery = {
  __typename: 'Query';
  downloadUrlForProjectOverviewPdf: string;
};

export type SubcontractorRatingsForProjectQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubcontractorRatingsForProjectQuery = {
  __typename: 'Query';
  projectRatingsPerBidRequest: Array<{
    __typename: 'SubcontractorRatingPerBidRequest';
    bidPackageId: string;
    bidPackageName: string;
    projectId: string;
    projectName: string;
    rating: number;
    userRatedCategories: Array<RATING_CATEGORY>;
    ratingCategories: Array<RATING_CATEGORY> | null;
    subcontractor: { __typename: 'Subcontractor'; id: string; name: string; isDeleted: boolean };
    ratingByCategory: Array<{
      __typename: 'CategoryRating';
      category: RATING_CATEGORY;
      rating: number;
    }>;
    bidRequest: { __typename: 'BidRequest'; id: string; status: BID_REQUEST_STATUS };
  }>;
  company: { __typename: 'Company'; id: string; ratingCategories: Array<RATING_CATEGORY> };
};

export type ProjectBidRequestsToNotifyOfDeletionCountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectBidRequestsToNotifyOfDeletionCountQuery = {
  __typename: 'Query';
  project: { __typename: 'Project'; bidRequestsToNotifyOfDeletionCount: number };
};

export type ProjectClosingConditionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectClosingConditionsQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    closingConditions: {
      __typename: 'ProjectClosingConditionsResponse';
      mustNotifyBidders: boolean;
      canSendNotification: boolean;
      shouldWarnAboutUnawardedBidders: boolean;
    };
  };
};

export type OfficesForProjectListFilterQueryVariables = Exact<{ [key: string]: never }>;

export type OfficesForProjectListFilterQuery = {
  __typename: 'Query';
  offices: Array<{ __typename: 'Office'; id: string; name: string }>;
};

export type ProjectCustomFieldsWithTypesQueryVariables = Exact<{
  types?: InputMaybe<Array<CUSTOM_FIELD_TYPE> | CUSTOM_FIELD_TYPE>;
}>;

export type ProjectCustomFieldsWithTypesQuery = {
  __typename: 'Query';
  customFields: Array<{
    __typename: 'CustomField';
    id: string;
    label: string;
    type: CUSTOM_FIELD_TYPE;
  }>;
};

export type RequestMarketplaceUsageReportMutationVariables = Exact<{ [key: string]: never }>;

export type RequestMarketplaceUsageReportMutation = {
  __typename: 'Mutation';
  requestMarketplaceUsageReport: boolean;
};

export type ProjectsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PROJECTS_SORT_FIELD>;
  sortById?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  status?: InputMaybe<Scalars['String']['input']>;
  fetchAllOfficeProjects?: InputMaybe<Scalars['Boolean']['input']>;
  phase?: InputMaybe<PROJECT_PHASE>;
  primaryUserId?: InputMaybe<Scalars['String']['input']>;
  fromStartDate?: InputMaybe<Scalars['Date']['input']>;
  toStartDate?: InputMaybe<Scalars['Date']['input']>;
  fromEndDate?: InputMaybe<Scalars['Date']['input']>;
  toEndDate?: InputMaybe<Scalars['Date']['input']>;
  officeId?: InputMaybe<Scalars['String']['input']>;
  fromCreationDate?: InputMaybe<Scalars['Date']['input']>;
  toCreationDate?: InputMaybe<Scalars['Date']['input']>;
  includeCustomer: Scalars['Boolean']['input'];
  includeManager: Scalars['Boolean']['input'];
  includeCreatedAt: Scalars['Boolean']['input'];
  includeStartDate: Scalars['Boolean']['input'];
  includeEndDate: Scalars['Boolean']['input'];
  includeOffice: Scalars['Boolean']['input'];
  includeCustomFields: Scalars['Boolean']['input'];
  includeCustomFieldIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type ProjectsQuery = {
  __typename: 'Query';
  projects: {
    __typename: 'ProjectsResponse';
    totalRecords: number;
    hasAnyOpenProjects: boolean | null;
    projects: Array<{
      __typename: 'Project';
      id: string;
      number: string | null;
      name: string;
      customer?: string | null;
      status: PROJECT_STATUS;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      bidRequestsAwaitingResponseCount: number;
      startsOn?: string | null;
      endsOn?: string | null;
      createdAt?: DateTime;
      primaryUser?: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
      } | null;
      bidPackages: Array<{ __typename: 'BidPackage'; id: string; status: BID_PACKAGE_STATUS }>;
      office?: { __typename: 'Office'; name: string };
      customFields?: Array<
        | {
            __typename: 'CustomFieldWithValueCheckbox';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueCheckbox: boolean;
          }
        | {
            __typename: 'CustomFieldWithValueCurrency';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueCurrency: number | null;
          }
        | {
            __typename: 'CustomFieldWithValueDate';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueDate: DateString | null;
          }
        | {
            __typename: 'CustomFieldWithValueDateTime';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueDateTime: DateTime | null;
          }
        | {
            __typename: 'CustomFieldWithValueMultiSelect';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueMultiSelect: Array<string>;
            options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
          }
        | {
            __typename: 'CustomFieldWithValueNumber';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueNumber: number | null;
          }
        | {
            __typename: 'CustomFieldWithValueSingleSelect';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueSingleSelect: string | null;
            options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
          }
        | {
            __typename: 'CustomFieldWithValueText';
            id: string;
            isRequired: boolean;
            label: string;
            entityId: string | null;
            valueText: string | null;
          }
      > | null;
    }>;
  };
  customFields?: Array<{
    __typename: 'CustomField';
    id: string;
    label: string;
    type: CUSTOM_FIELD_TYPE;
  }>;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeleteProjectMutation = { __typename: 'Mutation'; deleteProject: boolean };

export type DeleteMarketplaceSearchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteMarketplaceSearchMutation = {
  __typename: 'Mutation';
  deleteMarketplaceSavedSearch: boolean;
};

export type UpdateMarketplaceSavedSearchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isNotificationEnabled: Scalars['Boolean']['input'];
}>;

export type UpdateMarketplaceSavedSearchMutation = {
  __typename: 'Mutation';
  updateMarketplaceSavedSearch: {
    __typename: 'MarketplaceSavedSearch';
    id: string;
    isNotificationEnabled: boolean;
  };
};

export type SaveMarketplaceSearchMutationVariables = Exact<{
  input: MarketplaceSavedSearchInput;
}>;

export type SaveMarketplaceSearchMutation = {
  __typename: 'Mutation';
  saveMarketplaceSearch: boolean;
};

export type MarketplaceSavedSearchesQueryVariables = Exact<{ [key: string]: never }>;

export type MarketplaceSavedSearchesQuery = {
  __typename: 'Query';
  marketplaceSavedSearches: Array<{
    __typename: 'MarketplaceSavedSearch';
    id: string;
    name: string;
    isNotificationEnabled: boolean;
    query: string | null;
    address: string | null;
    geoCoords: string | null;
    endsOn: DateString | null;
    startsOn: DateString | null;
    sortBy: string;
    distanceInKm: number | null;
    dueDateInMinDays: number | null;
    showExpiredTenders: boolean;
    workCategories: Array<{ __typename: 'WorkCategory'; id: string; name: string }>;
  }>;
};

export type ReverseMarketplaceListQueryVariables = Exact<{
  calculateDefaultFilters?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BID_PACKAGES_MARKETPLACE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  geoCoords?: InputMaybe<Scalars['String']['input']>;
  distanceInKm?: InputMaybe<Scalars['Float']['input']>;
  startsOn?: InputMaybe<Scalars['Date']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  workCategoryIds?: InputMaybe<Array<WorkCategoryIds> | WorkCategoryIds>;
  excludeWorkCategoryIds?: InputMaybe<Array<WorkCategoryIds> | WorkCategoryIds>;
  dueDateInMinDays?: InputMaybe<Scalars['Int']['input']>;
  allowBidsAfterDueDate?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ReverseMarketplaceListQuery = {
  __typename: 'Query';
  bidPackageListForMarketplace: {
    __typename: 'BidPackagesForMarketplaceResponse';
    totalRecords: number;
    recentBidPackagesMatchingFiltersCount: number | null;
    bidPackages: Array<{
      __typename: 'BidPackage';
      id: string;
      bidsDueAt: DateTime;
      distanceInKm: number | null;
      endsOn: DateString | null;
      name: string;
      publishedToMarketplaceAt: DateTime | null;
      startsOn: DateString | null;
      queryMatchesBoQ: boolean | null;
      project: {
        __typename: 'Project';
        id: string;
        customer: string | null;
        name: string;
        location: string;
        office: { __typename: 'Office'; id: string; logoKey: string | null; name: string };
      };
    }>;
    defaultFilters: {
      __typename: 'BidPackagesForMarketplaceDefaultFilters';
      address: string | null;
      geoCoords: string | null;
      distanceInKm: number | null;
      workCategoryList: Array<{ __typename: 'WorkCategory'; id: string; name: string }> | null;
    } | null;
  };
};

export type SubcontractorBidBidRequestQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type SubcontractorBidBidRequestQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    id: string;
    type: BID_REQUEST_TYPE;
    status: BID_REQUEST_STATUS;
    currentBidId: string | null;
    source: BID_REQUEST_SOURCE;
    customBidsDueAt: DateTime | null;
    subcontractor: {
      __typename: 'Subcontractor';
      id: string;
      name: string;
      type: SUBCONTRACTOR_TYPE;
      isClaimedByAgentCompany: boolean;
      street: string | null;
      postalCode: string | null;
      countryCode: COUNTRY_CODE | null;
      city: string | null;
      workCategories: Array<{
        __typename: 'WorkCategoryForSubcontractor';
        id: string;
        isPrimary: boolean;
        name: string;
      }>;
    };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      isLocked: boolean;
      isBlindTender: boolean;
      blindTenderBidsOpenedAt: DateTime | null;
      number: string | null;
      status: BID_PACKAGE_STATUS;
      bidsDueAt: DateTime;
      allowBidsAfterDueDate: boolean;
      name: string;
      currency: CURRENCY;
      reasonForClosing: string | null;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      project: {
        __typename: 'Project';
        id: string;
        status: PROJECT_STATUS;
        imageKeys: Array<string>;
        name: string;
        number: string | null;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          logoKey: string | null;
          company: {
            __typename: 'Company';
            id: string;
            name: string;
            createdByAgentCompanyId: string | null;
            type: COMPANY_TYPE;
            bidsSubmittedOnMarketplace: number;
            permanentFeatureFlags: JSONObject;
            config: {
              __typename: 'CompanyConfig';
              id: string;
              additionalLanguages: Array<LANGUAGE>;
              premiumEnabled: boolean;
            };
          };
        };
      };
    };
  };
};

export type AgentSubmitBid_draftBidFragment = {
  __typename: 'DraftBid';
  message: string | null;
  totalNet: number | null;
  discountRate: number | null;
  skontoDeadline: number | null;
  skontoRate: number | null;
  taxRate: number | null;
  documentsCount: number;
  hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
  billOfQuantities: {
    __typename: 'BillOfQuantities';
    id: string;
    editingStatus: BOQ_EDITING_STATUS | null;
  } | null;
  warningMetrics: {
    __typename: 'BidWarningMetrics';
    freeQuantityPositionsWithoutQuantitiesIds: Array<string>;
    notFilledPositionsIds: Array<string>;
    hasProposalInDocuments: boolean;
    elementsWithNotFilledBidderInputs: Array<{
      __typename: 'BoQElementWithNotFilledBidderInput';
      elementId: string;
      bidderInputLabel: string | null;
    }>;
  };
};

export type SubcontractorBidDraftBidQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type SubcontractorBidDraftBidQuery = {
  __typename: 'Query';
  draftBid: {
    __typename: 'DraftBid';
    id: string;
    message: string | null;
    totalNet: number | null;
    discountRate: number | null;
    skontoDeadline: number | null;
    skontoRate: number | null;
    taxRate: number | null;
    documentsCount: number;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
    warningMetrics: {
      __typename: 'BidWarningMetrics';
      freeQuantityPositionsWithoutQuantitiesIds: Array<string>;
      notFilledPositionsIds: Array<string>;
      hasProposalInDocuments: boolean;
      elementsWithNotFilledBidderInputs: Array<{
        __typename: 'BoQElementWithNotFilledBidderInput';
        elementId: string;
        bidderInputLabel: string | null;
      }>;
    };
  } | null;
};

export type SubcontractorBidBidQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubcontractorBidBidQuery = {
  __typename: 'Query';
  bid: {
    __typename: 'SubmittedBid';
    id: string;
    documentsCount: number;
    submittedAt: DateTime;
    version: number;
    message: string | null;
    totalNet: number;
    additionalCosts: number | null;
    skontoRate: number;
    skontoDeadline: number;
    discountRate: number | null;
    absoluteDiscount: number | null;
    taxRate: number;
    billOfQuantitiesRevision: {
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
    };
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
  };
};

export type SubcontractorBidUpdateDraftBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  input: BidInput;
}>;

export type SubcontractorBidUpdateDraftBidMutation = {
  __typename: 'Mutation';
  updateDraftBid: {
    __typename: 'DraftBid';
    id: string;
    totalNet: number | null;
    skontoRate: number | null;
    skontoDeadline: number | null;
    discountRate: number | null;
    absoluteDiscount: number | null;
    taxRate: number | null;
    message: string | null;
  };
};

export type SubcontractorBidGetOrCreateDraftBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type SubcontractorBidGetOrCreateDraftBidMutation = {
  __typename: 'Mutation';
  getOrCreateDraftBid: {
    __typename: 'DraftBid';
    id: string;
    message: string | null;
    totalNet: number | null;
    discountRate: number | null;
    skontoDeadline: number | null;
    skontoRate: number | null;
    taxRate: number | null;
    documentsCount: number;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      editingStatus: BOQ_EDITING_STATUS | null;
    } | null;
    warningMetrics: {
      __typename: 'BidWarningMetrics';
      freeQuantityPositionsWithoutQuantitiesIds: Array<string>;
      notFilledPositionsIds: Array<string>;
      hasProposalInDocuments: boolean;
      elementsWithNotFilledBidderInputs: Array<{
        __typename: 'BoQElementWithNotFilledBidderInput';
        elementId: string;
        bidderInputLabel: string | null;
      }>;
    };
  };
};

export type SubcontractorBidSubmitBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  bidId?: InputMaybe<Scalars['ID']['input']>;
  input: BidInput;
}>;

export type SubcontractorBidSubmitBidMutation = {
  __typename: 'Mutation';
  subcontractorSubmitBid: { __typename: 'Bid'; id: string };
};

export type ClaimAnonymousDraftBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  bidId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ClaimAnonymousDraftBidMutation = {
  __typename: 'Mutation';
  claimAnonymousDraftBid: boolean;
};

export type SubcontractorsByAgentForPostSubmitQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
}>;

export type SubcontractorsByAgentForPostSubmitQuery = {
  __typename: 'Query';
  shouldPromptWorkCategoriesUpdate: boolean;
  subcontractorsByAgent: Array<{ __typename: 'Subcontractor'; id: string }>;
};

export type DiscardDraftBidMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  draftBidId: Scalars['ID']['input'];
}>;

export type DiscardDraftBidMutation = { __typename: 'Mutation'; discardDraftBid: boolean };

export type SubcontractorReferenceProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubcontractorReferenceProjectQuery = {
  __typename: 'Query';
  subcontractorReferenceProject: {
    __typename: 'SubcontractorReferenceProject';
    id: string;
    imageKeys: Array<string>;
    description: string;
    name: string;
    subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
  };
};

export type BidRequestsAgentForTaskFragment = {
  __typename: 'BidRequest';
  id: string;
  bidPackage: {
    __typename: 'BidPackage';
    name: string;
    project: {
      __typename: 'Project';
      name: string;
      office: { __typename: 'Office'; name: string };
    };
  };
};

export type BidRequestsAgentForTaskQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  sortBy: BID_REQUESTS_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
}>;

export type BidRequestsAgentForTaskQuery = {
  __typename: 'Query';
  bidRequestsForAgent: {
    __typename: 'BidRequestsResponse';
    totalRecords: number;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      bidPackage: {
        __typename: 'BidPackage';
        name: string;
        project: {
          __typename: 'Project';
          name: string;
          office: { __typename: 'Office'; name: string };
        };
      };
    }>;
  };
};

export type BidRequestForTaskQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type BidRequestForTaskQuery = {
  __typename: 'Query';
  bidRequestForSubcontractor: {
    __typename: 'BidRequest';
    id: string;
    bidPackage: {
      __typename: 'BidPackage';
      name: string;
      project: {
        __typename: 'Project';
        name: string;
        office: { __typename: 'Office'; name: string };
      };
    };
  };
};

export type BidRequestsPrincipalForTaskFragment = {
  __typename: 'BidRequest';
  id: string;
  subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
};

export type BidRequestsForTaskQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  sortBy: BID_REQUESTS_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
  statuses?: InputMaybe<Array<BID_REQUEST_STATUS> | BID_REQUEST_STATUS>;
}>;

export type BidRequestsForTaskQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    totalRecords: number;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    }>;
  };
};

export type TaskNotesQueryVariables = Exact<{
  taskId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export type TaskNotesQuery = {
  __typename: 'Query';
  taskNotes: {
    __typename: 'TaskNotesResponse';
    totalRecords: number;
    notes: Array<{
      __typename: 'TaskNote';
      createdAt: DateTime;
      id: string;
      text: string;
      user: { __typename: 'User'; firstName: string | null; id: string; lastName: string | null };
    }>;
  };
};

export type AddTaskNoteMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
}>;

export type AddTaskNoteMutation = { __typename: 'Mutation'; addTaskNote: boolean };

export type DeleteTaskNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTaskNoteMutation = { __typename: 'Mutation'; deleteTaskNote: boolean };

export type ProjectsForSelectQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  sortBy: PROJECTS_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
}>;

export type ProjectsForSelectQuery = {
  __typename: 'Query';
  projects: {
    __typename: 'ProjectsResponse';
    totalRecords: number;
    projects: Array<{ __typename: 'Project'; id: string; name: string; number: string | null }>;
  };
};

export type SelectedProjectForSelectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SelectedProjectForSelectQuery = {
  __typename: 'Query';
  project: { __typename: 'Project'; id: string; name: string; number: string | null };
};

export type UserForTaskFragment = {
  __typename: 'User';
  id: string;
  firstName: string | null;
  lastName: string | null;
  status: USER_STATUS;
  email: string;
};

export type TaskQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TaskQuery = {
  __typename: 'Query';
  task: {
    __typename: 'Task';
    id: string;
    name: string;
    status: TASK_STATUS;
    dueAt: DateTime;
    comment: string | null;
    description: string | null;
    assigneeType: TASK_ASSIGNEE_TYPE;
    assignee: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      status: USER_STATUS;
      email: string;
    } | null;
    createdBy: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    };
    project: { __typename: 'Project'; id: string; number: string | null; name: string } | null;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      number: string | null;
      name: string;
    } | null;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
      bidPackage: {
        __typename: 'BidPackage';
        name: string;
        project: {
          __typename: 'Project';
          name: string;
          office: { __typename: 'Office'; name: string };
        };
      };
    } | null;
  };
};

export type TaskPublicDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TaskPublicDataQuery = {
  __typename: 'Query';
  taskPublicData: {
    __typename: 'TaskPublicDataResponse';
    id: string | null;
    bidPackageId: string | null;
    bidPackageName: string | null;
    bidRequestId: string | null;
    subcontractorId: string | null;
  };
};

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: TaskInput;
}>;

export type UpdateTaskMutation = {
  __typename: 'Mutation';
  updateTask: { __typename: 'Task'; id: string };
};

export type CreateTaskMutationVariables = Exact<{
  input: TaskInput;
}>;

export type CreateTaskMutation = {
  __typename: 'Mutation';
  createTask: { __typename: 'Task'; id: string };
};

export type AllUsersForTaskQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersForTaskQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UsersResponse';
    users: Array<{
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      status: USER_STATUS;
      email: string;
    }>;
  };
};

export type UsersWithAccessToProjectQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type UsersWithAccessToProjectQuery = {
  __typename: 'Query';
  usersWithAccessToProject: Array<{
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    status: USER_STATUS;
    email: string;
  }>;
};

export type UsersWithAccessToBidPackageQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type UsersWithAccessToBidPackageQuery = {
  __typename: 'Query';
  usersWithAccessToBidPackage: Array<{
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    status: USER_STATUS;
    email: string;
  }>;
};

export type TermsQueryVariables = Exact<{
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type TermsQuery = {
  __typename: 'Query';
  termsForBidRequest: {
    __typename: 'Terms';
    id: string;
    filename: string;
    size: number;
    downloadUrls: { __typename: 'TermsUrls'; url: string; openInNewTabUrl: string };
  };
};

export type AcceptTermsMutationVariables = Exact<{
  termsId: Scalars['ID']['input'];
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AcceptTermsMutation = { __typename: 'Mutation'; acceptTerms: boolean };

export type AgentSignupForCertificatesSubmissionMutationVariables = Exact<{
  input: AgentSignupForCertificatesSubmissionInput;
}>;

export type AgentSignupForCertificatesSubmissionMutation = {
  __typename: 'Mutation';
  agentSignupForCertificatesSubmission: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type UploadCertificatesViewQueryVariables = Exact<{
  officeId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
  certificateRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UploadCertificatesViewQuery = {
  __typename: 'Query';
  subcontractorType: SUBCONTRACTOR_TYPE;
  office: {
    __typename: 'Office';
    id: string;
    name: string;
    logoKey: string | null;
    company: {
      __typename: 'Company';
      id: string;
      type: COMPANY_TYPE;
      bidsSubmittedOnMarketplace: number;
      permanentFeatureFlags: JSONObject;
      config: {
        __typename: 'CompanyConfig';
        id: string;
        additionalLanguages: Array<LANGUAGE>;
        premiumEnabled: boolean;
      };
    };
  };
  certificateRequest: {
    __typename: 'CertificateRequest';
    id: string;
    dueDate: string | null;
    statusForAgent: CERTIFICATE_REQUEST_STATUS_FOR_AGENT;
    contact: {
      __typename: 'Contact';
      id: string;
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        type: SUBCONTRACTOR_TYPE;
        isClaimedByAgentCompany: boolean;
        countryCode: COUNTRY_CODE | null;
      };
    };
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      project: { __typename: 'Project'; id: string; name: string };
    } | null;
    certificateRequestItems: Array<{
      __typename: 'CertificateRequestItem';
      id: string;
      createdAt: DateTime;
      withdrawn: boolean;
      rejectionReason: string | null;
      canSaveInProfile: boolean;
      certificateType: {
        __typename: 'CertificateType';
        id: string;
        name: string;
        isExpiryDateMandatory: boolean;
        scope: CERTIFICATE_TYPE_SCOPE;
        certificateRequestCertificates: Array<{
          __typename: 'Certificate';
          id: string;
          createdAt: DateTime;
          fileName: string;
          fileSizeBytes: number;
          issueDate: string;
          expiryDate: string | null;
          status: CERTIFICATE_STATUS;
        }>;
        templateDocument: {
          __typename: 'Document';
          id: string;
          filename: string;
          size: number;
        } | null;
      };
    }>;
  } | null;
};

export type RejectCertificateRequestItemMutationVariables = Exact<{
  certificateRequestItemId: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
}>;

export type RejectCertificateRequestItemMutation = {
  __typename: 'Mutation';
  rejectCertificateRequestItem: boolean;
};

export type BoQTemplateForTextProviderModalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BoQTemplateForTextProviderModalQuery = {
  __typename: 'Query';
  boqTemplate: { __typename: 'BoQTemplate'; id: string; boqFileFormat: BOQ_FILE_FORMAT };
};

export type BoqTemplatesForTextProviderModalQueryVariables = Exact<{
  boqFileFormat?: InputMaybe<BOQ_FILE_FORMAT>;
}>;

export type BoqTemplatesForTextProviderModalQuery = {
  __typename: 'Query';
  boqTemplates: {
    __typename: 'BoQTemplatesResponse';
    totalRecords: number;
    boqTemplates: Array<{
      __typename: 'BoQTemplate';
      id: string;
      costGroupCatalogs: Array<{
        __typename: 'BoQTemplateCostGroupCatalog';
        catalogType: BOQ_TEMPLATE_COST_CATALOG_TYPE;
        customCatalog: { __typename: 'CustomCostGroupCatalog'; id: string } | null;
      }>;
    }>;
  };
};

export type GetHeinzeAtBoQElementsQueryVariables = Exact<{
  file: Scalars['String']['input'];
}>;

export type GetHeinzeAtBoQElementsQuery = {
  __typename: 'Query';
  heinzeAtBoQElements: Array<JSONObject>;
};

export type GetHeinzeAtGuiUrlQueryVariables = Exact<{ [key: string]: never }>;

export type GetHeinzeAtGuiUrlQuery = {
  __typename: 'Query';
  heinzeAtGuiUrl: { __typename: 'HeinzeGuiUrlResponse'; baseUrl: string; homeUrl: string };
};

export type GetHeinzeVobBoQElementsQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type GetHeinzeVobBoQElementsQuery = {
  __typename: 'Query';
  heinzeVobBoQElements: Array<JSONObject>;
};

export type GetHeinzeVobGuiUrlQueryVariables = Exact<{
  forceAnonymous: Scalars['Boolean']['input'];
}>;

export type GetHeinzeVobGuiUrlQuery = {
  __typename: 'Query';
  heinzeVobGuiUrl: { __typename: 'HeinzeGuiUrlResponse'; baseUrl: string; homeUrl: string };
};

export type lBHochbauTextProviderElementsTreeQueryVariables = Exact<{ [key: string]: never }>;

export type lBHochbauTextProviderElementsTreeQuery = {
  __typename: 'Query';
  lBHochbauTextProviderElementsTree: {
    __typename: 'LbHochbauTextProviderElementsTreeResponse';
    lbHochbauBoQElements: JSONObject;
  };
};

export type RefreshStlbSessionQueryVariables = Exact<{
  oldSessionId?: InputMaybe<Scalars['String']['input']>;
}>;

export type RefreshStlbSessionQuery = {
  __typename: 'Query';
  refreshStlbSession: {
    __typename: 'StlbSessionResponse';
    sessionId: string;
    authorizationFailed: boolean;
  };
};

export type StlbHomeUrlQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;

export type StlbHomeUrlQuery = { __typename: 'Query'; stlbHomeUrl: string };

export type DataFromStlbQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;

export type DataFromStlbQuery = {
  __typename: 'Query';
  stlbGetData: {
    __typename: 'ParsedStlbDataResponse';
    htmlLongText: string;
    shortText: string;
    unitPrice: number;
    unitTag: string;
    costGroupId: string | null;
    standardizedDescription: {
      __typename: 'StandardizedDescriptionResponse';
      standardReferenceType: string;
      stlbReference: {
        __typename: 'StlbReferenceResponse';
        catalogueName: string;
        group: string;
        versionDate: DateTime;
        keys: Array<{
          __typename: 'StlbKeyResponse';
          artIdentifier: string;
          artIndex: string | null;
          kindIdentifier: string | null;
        }>;
      };
    };
  };
};

export type BoQTemplatesForBidPackageQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BOQ_TEMPLATES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  boqFileFormat: BOQ_FILE_FORMAT;
}>;

export type BoQTemplatesForBidPackageQuery = {
  __typename: 'Query';
  boqTemplates: {
    __typename: 'BoQTemplatesResponse';
    totalRecords: number;
    totalRecordsForCompany: number;
    boqTemplates: Array<{ __typename: 'BoQTemplate'; id: string; name: string | null }>;
  };
};

export type BoQTemplateForImportModalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BoQTemplateForImportModalQuery = {
  __typename: 'Query';
  boqTemplate: {
    __typename: 'BoQTemplate';
    id: string;
    boqFileFormat: BOQ_FILE_FORMAT;
    costGroupCatalogs: Array<{
      __typename: 'BoQTemplateCostGroupCatalog';
      catalogType: BOQ_TEMPLATE_COST_CATALOG_TYPE;
      customCatalog: {
        __typename: 'CustomCostGroupCatalog';
        id: string;
        name: string;
        status: CUSTOM_COST_GROUP_CATALOG_STATUS;
      } | null;
    }>;
  };
};

export type UseTemplateBoQInBidPackageImportModalMutationVariables = Exact<{
  sourceBoQTemplateId: Scalars['ID']['input'];
  destinationBidPackageId: Scalars['ID']['input'];
  fieldsToImport: TemplateBoQFieldsToImportInput;
  costGroupCatalogsToImport: TemplateBoQCostGroupCatalogsToImportInput;
}>;

export type UseTemplateBoQInBidPackageImportModalMutation = {
  __typename: 'Mutation';
  useTemplateBoQInBidPackage: boolean;
};

export type ReuseBidPackageBoQInBidPackageMutationVariables = Exact<{
  sourceBidPackageId: Scalars['ID']['input'];
  destinationBidPackageId: Scalars['ID']['input'];
}>;

export type ReuseBidPackageBoQInBidPackageMutation = {
  __typename: 'Mutation';
  duplicateBoQ: boolean;
};

export type ConfirmExcelRequestForProposalBoQUploadedMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  bidPackageId: Scalars['ID']['input'];
  uploadKey: Scalars['ID']['input'];
  options: ExcelBoQImportOptions;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ConfirmExcelRequestForProposalBoQUploadedMutation = {
  __typename: 'Mutation';
  confirmExcelRequestForProposalBoQUploaded:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type BoQChangedNotificationDefaultTemplateQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BoQChangedNotificationDefaultTemplateQuery = {
  __typename: 'Query';
  boqChangedNotificationDefaultTemplate: string;
};

export type BoQChangedNotificationPreviewQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['HTML']['input']>;
}>;

export type BoQChangedNotificationPreviewQuery = {
  __typename: 'Query';
  boqChangedNotificationPreview: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type BoQFragment = {
  __typename: 'BillOfQuantities';
  id: string;
  editingStatus: BOQ_EDITING_STATUS | null;
  content: JSONObject;
  comments: Array<{
    __typename: 'BillOfQuantitiesComment';
    id: string;
    path: string;
    text: string;
  }>;
};

export type BoQWithoutContentFragment = {
  __typename: 'BillOfQuantities';
  id: string;
  editingStatus: BOQ_EDITING_STATUS | null;
  comments: Array<{
    __typename: 'BillOfQuantitiesComment';
    id: string;
    path: string;
    text: string;
  }>;
};

export type BillOfQuantitiesForBidPackageQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type BillOfQuantitiesForBidPackageQuery = {
  __typename: 'Query';
  billOfQuantitiesForBidPackage: {
    __typename: 'BillOfQuantities';
    id: string;
    currency: CURRENCY;
    editingStatus: BOQ_EDITING_STATUS | null;
    content: JSONObject;
    comments: Array<{
      __typename: 'BillOfQuantitiesComment';
      id: string;
      path: string;
      text: string;
    }>;
  } | null;
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    hasUnsavedBillOfQuantitiesChanges: boolean;
    boqPositionTextProvider: BOQ_POSITION_TEXT_PROVIDER;
    unsavedBillOfQuantitiesAddedElementsIds: Array<string>;
    billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
  };
};

export type BillOfQuantitiesForBidPackageEditorQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type BillOfQuantitiesForBidPackageEditorQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    currency: CURRENCY;
    status: BID_PACKAGE_STATUS;
  };
  billOfQuantitiesForEditing: {
    __typename: 'BillOfQuantitiesForEditingResponse';
    id: string | null;
    content: JSONObject;
    largestTextAdditionLabel: number;
    massDeterminationRowIdsNotToKeep: Array<string>;
    preferredFileFormat: BOQ_FILE_FORMAT;
    massDeterminations: Array<{
      __typename: 'MassDetermination';
      id: string;
      boqRowId: string;
      status: BOQ_MASS_DETERMINATION_STATUS;
      rows: Array<
        | {
            __typename: 'MassDeterminationCommentRow';
            id: string;
            comment: string | null;
            localId: string;
          }
        | {
            __typename: 'MassDeterminationCustomFormulaRow';
            id: string;
            formulaId: string;
            expression: string | null;
            note: string | null;
            factor: number | null;
            localId: string;
          }
        | {
            __typename: 'MassDeterminationFormulaRow';
            id: string;
            formulaId: string;
            value1: number | null;
            value2: number | null;
            value3: number | null;
            value4: number | null;
            value5: number | null;
            note: string | null;
            factor: number | null;
            localId: string;
          }
      >;
    }>;
  };
};

export type BillOfQuantitiesForSubmittedBidQueryVariables = Exact<{
  bidId: Scalars['ID']['input'];
}>;

export type BillOfQuantitiesForSubmittedBidQuery = {
  __typename: 'Query';
  bid: {
    __typename: 'SubmittedBid';
    id: string;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      currency: CURRENCY;
      editingStatus: BOQ_EDITING_STATUS | null;
      content: JSONObject;
      comments: Array<{
        __typename: 'BillOfQuantitiesComment';
        id: string;
        path: string;
        text: string;
      }>;
    } | null;
    bidRequest: {
      __typename: 'BidRequest';
      id: string;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        currency: CURRENCY;
        project: {
          __typename: 'Project';
          id: string;
          preferredBoQFileFormat: BOQ_FILE_FORMAT;
          costGroupCatalogs: Array<{
            __typename: 'ProjectCostGroupCatalog';
            catalogType: PROJECT_COST_CATALOG_TYPE;
            customCatalogId: string | null;
            customCatalogName: string | null;
          }>;
        };
      };
    };
  };
};

export type BillOfQuantitiesForDraftBidPreviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  suggestionId?: InputMaybe<Scalars['ID']['input']>;
  sentEmailId?: InputMaybe<Scalars['ID']['input']>;
  referrerUserId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type BillOfQuantitiesForDraftBidPreviewQuery = {
  __typename: 'Query';
  reverseMarketplaceBidPackage: {
    __typename: 'BidPackage';
    id: string;
    detachedProposalBillOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      currency: CURRENCY;
      editingStatus: BOQ_EDITING_STATUS | null;
      content: JSONObject;
      comments: Array<{
        __typename: 'BillOfQuantitiesComment';
        id: string;
        path: string;
        text: string;
      }>;
    } | null;
  };
  billOfQuantitiesForBidPackage: { __typename: 'BillOfQuantities'; id: string } | null;
};

export type BillOfQuantitiesForDraftBidQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  bidRequestId: Scalars['ID']['input'];
}>;

export type BillOfQuantitiesForDraftBidQuery = {
  __typename: 'Query';
  changedElementIds: Array<string>;
  draftBid: {
    __typename: 'DraftBid';
    id: string;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      currency: CURRENCY;
      editingStatus: BOQ_EDITING_STATUS | null;
      comments: Array<{
        __typename: 'BillOfQuantitiesComment';
        id: string;
        path: string;
        text: string;
      }>;
    } | null;
  } | null;
  billOfQuantitiesForBidPackage: { __typename: 'BillOfQuantities'; id: string } | null;
};

export type BillOfQuantitiesWithContentForDraftBidQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  bidRequestId: Scalars['ID']['input'];
  isUserPrincipal: Scalars['Boolean']['input'];
}>;

export type BillOfQuantitiesWithContentForDraftBidQuery = {
  __typename: 'Query';
  changedElementIds: Array<string>;
  draftBid: {
    __typename: 'DraftBid';
    id: string;
    hasOutdatedBoQ: REASON_PROPOSAL_BOQ_IS_OUTDATED | null;
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      id: string;
      currency: CURRENCY;
      editingStatus: BOQ_EDITING_STATUS | null;
      content: JSONObject;
      comments: Array<{
        __typename: 'BillOfQuantitiesComment';
        id: string;
        path: string;
        text: string;
      }>;
    } | null;
  } | null;
  bidPackage?: {
    __typename: 'BidPackage';
    project: {
      __typename: 'Project';
      costGroupCatalogs: Array<{
        __typename: 'ProjectCostGroupCatalog';
        catalogType: PROJECT_COST_CATALOG_TYPE;
        customCatalogId: string | null;
        customCatalogName: string | null;
      }>;
    };
  };
  billOfQuantitiesForBidPackage: { __typename: 'BillOfQuantities'; id: string } | null;
};

export type UpdateBillOfQuantitiesCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  text: Scalars['String']['input'];
}>;

export type UpdateBillOfQuantitiesCommentMutation = {
  __typename: 'Mutation';
  updateBillOfQuantitiesComment: boolean;
};

export type DiscardDraftBoQInBoQTableMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type DiscardDraftBoQInBoQTableMutation = {
  __typename: 'Mutation';
  discardDraftBoQ: boolean;
};

export type GetBoQEditorPreSaveStateMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type GetBoQEditorPreSaveStateMutation = {
  __typename: 'Mutation';
  getBoQEditorPreSaveState: {
    __typename: 'BoQEditorPreSaveStateResponse';
    hasFormalReviewErrors: boolean;
    hasNotifiableChanges: boolean;
  };
};

export type ConfirmRequestForProposalBoqUploadedMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  bidPackageId: Scalars['ID']['input'];
  uploadKey: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ConfirmRequestForProposalBoqUploadedMutation = {
  __typename: 'Mutation';
  confirmRequestForProposalBoqUploaded:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type ConfirmProposalBoQUploadedByPrincipalUserMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  bidRequestId: Scalars['ID']['input'];
  uploadKey: Scalars['ID']['input'];
}>;

export type ConfirmProposalBoQUploadedByPrincipalUserMutation = {
  __typename: 'Mutation';
  confirmProposalBoQUploadedByPrincipalUser:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        netTotal: number | null;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
      };
};

export type ConfirmProposalBoQUploadedBySubcontractorMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  bidRequestId: Scalars['ID']['input'];
  uploadKey: Scalars['ID']['input'];
}>;

export type ConfirmProposalBoQUploadedBySubcontractorMutation = {
  __typename: 'Mutation';
  confirmProposalBoQUploadedBySubcontractor:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        netTotal: number | null;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
        pricePredictions: Array<{
          __typename: 'PositionPricePredictionEvaluation';
          positionId: string;
          location: string;
          rowNumber: number | null;
          reason: PRICE_PREDICTION_EVALUATION_STATUS | null;
          prediction: { __typename: 'PriceRange'; min: number; max: number } | null;
        }> | null;
      };
};

export type BillOfQuantitiesForBoQTemplateQueryVariables = Exact<{
  boqTemplateId: Scalars['ID']['input'];
}>;

export type BillOfQuantitiesForBoQTemplateQuery = {
  __typename: 'Query';
  customCostGroupCatalogs: {
    __typename: 'CustomCostGroupCatalogsResponse';
    customCostGroupCatalogs: Array<{
      __typename: 'CustomCostGroupCatalog';
      id: string;
      name: string;
      status: CUSTOM_COST_GROUP_CATALOG_STATUS;
    }>;
  };
  boqTemplateBoQ: {
    __typename: 'BillOfQuantities';
    id: string;
    currency: CURRENCY;
    editingStatus: BOQ_EDITING_STATUS | null;
    content: JSONObject;
    comments: Array<{
      __typename: 'BillOfQuantitiesComment';
      id: string;
      path: string;
      text: string;
    }>;
  } | null;
  boqTemplate: {
    __typename: 'BoQTemplate';
    id: string;
    hasUnsavedBillOfQuantitiesChanges: boolean;
    costGroupCatalogs: Array<{
      __typename: 'BoQTemplateCostGroupCatalog';
      catalogType: BOQ_TEMPLATE_COST_CATALOG_TYPE;
      customCatalogId: string | null;
      customCatalogName: string | null;
    }>;
  };
};

export type BillOfQuantitiesForBoQTemplateEditorQueryVariables = Exact<{
  boqTemplateId: Scalars['ID']['input'];
}>;

export type BillOfQuantitiesForBoQTemplateEditorQuery = {
  __typename: 'Query';
  boqTemplate: {
    __typename: 'BoQTemplate';
    id: string;
    currency: CURRENCY;
    boqFileFormat: BOQ_FILE_FORMAT;
  };
  boqTemplateBoQForEditing: {
    __typename: 'BillOfQuantitiesForEditingResponse';
    id: string | null;
    content: JSONObject;
    largestTextAdditionLabel: number;
    massDeterminationRowIdsNotToKeep: Array<string>;
    massDeterminations: Array<{
      __typename: 'MassDetermination';
      id: string;
      boqRowId: string;
      status: BOQ_MASS_DETERMINATION_STATUS;
      rows: Array<
        | {
            __typename: 'MassDeterminationCommentRow';
            id: string;
            comment: string | null;
            localId: string;
          }
        | {
            __typename: 'MassDeterminationCustomFormulaRow';
            id: string;
            formulaId: string;
            expression: string | null;
            note: string | null;
            factor: number | null;
            localId: string;
          }
        | {
            __typename: 'MassDeterminationFormulaRow';
            id: string;
            formulaId: string;
            value1: number | null;
            value2: number | null;
            value3: number | null;
            value4: number | null;
            value5: number | null;
            note: string | null;
            factor: number | null;
            localId: string;
          }
      >;
    }>;
  };
};

export type GetBoQTemplateBoQEditorPreSaveStateMutationVariables = Exact<{
  boqTemplateId: Scalars['ID']['input'];
}>;

export type GetBoQTemplateBoQEditorPreSaveStateMutation = {
  __typename: 'Mutation';
  getBoQTemplateBoQEditorPreSaveState: {
    __typename: 'BoQTemplateBoQEditorPreSaveStateResponse';
    hasFormalReviewErrors: boolean;
  };
};

export type GetContractBoQContentFromFileTreeQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  bidPackageId: Scalars['ID']['input'];
}>;

export type GetContractBoQContentFromFileTreeQuery = {
  __typename: 'Query';
  contractBoQContent: JSONObject;
};

type baseRow_MassDeterminationCommentRow_Fragment = {
  __typename: 'MassDeterminationCommentRow';
  id: string;
  localId: string;
};

type baseRow_MassDeterminationCustomFormulaRow_Fragment = {
  __typename: 'MassDeterminationCustomFormulaRow';
  id: string;
  localId: string;
};

type baseRow_MassDeterminationFormulaRow_Fragment = {
  __typename: 'MassDeterminationFormulaRow';
  id: string;
  localId: string;
};

export type baseRowFragment =
  | baseRow_MassDeterminationCommentRow_Fragment
  | baseRow_MassDeterminationCustomFormulaRow_Fragment
  | baseRow_MassDeterminationFormulaRow_Fragment;

export type rowsPropFragment = {
  __typename: 'MassDetermination';
  id: string;
  rows: Array<
    | {
        __typename: 'MassDeterminationCommentRow';
        id: string;
        comment: string | null;
        localId: string;
      }
    | {
        __typename: 'MassDeterminationCustomFormulaRow';
        id: string;
        formulaId: string;
        expression: string | null;
        note: string | null;
        factor: number | null;
        localId: string;
      }
    | {
        __typename: 'MassDeterminationFormulaRow';
        id: string;
        formulaId: string;
        value1: number | null;
        value2: number | null;
        value3: number | null;
        value4: number | null;
        value5: number | null;
        note: string | null;
        factor: number | null;
        localId: string;
      }
  >;
};

export type MassDeterminationQueryVariables = Exact<{
  boqPositionRowId: Scalars['ID']['input'];
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  billOfQuantitiesId: Scalars['ID']['input'];
}>;

export type MassDeterminationQuery = {
  __typename: 'Query';
  getBoQPositionMassDeterminationForEditing: {
    __typename: 'MassDetermination';
    id: string;
    lastUsedLocalId: string | null;
    rows: Array<
      | {
          __typename: 'MassDeterminationCommentRow';
          id: string;
          comment: string | null;
          localId: string;
        }
      | {
          __typename: 'MassDeterminationCustomFormulaRow';
          id: string;
          formulaId: string;
          expression: string | null;
          note: string | null;
          factor: number | null;
          localId: string;
        }
      | {
          __typename: 'MassDeterminationFormulaRow';
          id: string;
          formulaId: string;
          value1: number | null;
          value2: number | null;
          value3: number | null;
          value4: number | null;
          value5: number | null;
          note: string | null;
          factor: number | null;
          localId: string;
        }
    >;
  } | null;
};

export type SetMassDeterminationMutationVariables = Exact<{
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  billOfQuantitiesId: Scalars['ID']['input'];
  boqPositionRowId: Scalars['ID']['input'];
  lastUsedLocalId?: InputMaybe<Scalars['String']['input']>;
  input: Array<SetMassDeterminationRowInput> | SetMassDeterminationRowInput;
}>;

export type SetMassDeterminationMutation = {
  __typename: 'Mutation';
  setBoQPositionMassDetermination: { __typename: 'MassDetermination'; id: string };
};

export type DeleteMassDeterminationMutationVariables = Exact<{
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  boqRowId: Scalars['ID']['input'];
  billOfQuantitiesId: Scalars['ID']['input'];
}>;

export type DeleteMassDeterminationMutation = {
  __typename: 'Mutation';
  deleteBoQPositionMassDetermination: boolean;
};

export type GetDownloadURLForBoQQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  type: BOQ_FILE_TYPE;
  options: BoQExportInput;
}>;

export type GetDownloadURLForBoQQuery = {
  __typename: 'Query';
  downloadURLForBillOfQuantities: string;
};

export type DownloadUrlForRequestForProposalBoQPdfQueryVariables = Exact<{
  boqId: Scalars['ID']['input'];
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  pdfSettings?: InputMaybe<BoQPdfSettings>;
}>;

export type DownloadUrlForRequestForProposalBoQPdfQuery = {
  __typename: 'Query';
  downloadUrlForRequestForProposalBoQPdf: string;
};

export type DownloadUrlForBidPackageBoQPdfQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  pdfSettings?: InputMaybe<BoQPdfSettings>;
}>;

export type DownloadUrlForBidPackageBoQPdfQuery = {
  __typename: 'Query';
  downloadUrlForBidPackageBoQPdf: string;
};

export type BoQTextEditorImageUploadUrlQueryVariables = Exact<{
  filename: Scalars['String']['input'];
}>;

export type BoQTextEditorImageUploadUrlQuery = {
  __typename: 'Query';
  imageUploadUrl: {
    __typename: 'ImageUploadUrlResponse';
    uploadUrl: string;
    storageKey: string;
    url: string;
  };
};

export type BillOfQuantitiesFormalReviewQueryVariables = Exact<{
  boqContainerId: Scalars['ID']['input'];
  boqContainerType: BOQ_CONTAINER_TYPE;
  selectedStandard?: InputMaybe<BOQ_FILE_FORMAT>;
  skipUnsavedBoQChanges?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BillOfQuantitiesFormalReviewQuery = {
  __typename: 'Query';
  billOfQuantitiesFormalReview: {
    __typename: 'BoQFormalReviewResponse';
    formalReviewResult: BOQ_FORMAL_REVIEW_RESULT;
    issues: Array<{
      __typename: 'BoQFormalReviewIssue';
      reason: BOQ_FORMAL_REVIEW_ERROR_REASON;
      level: BOQ_FORMAL_REVIEW_ISSUE_LEVEL;
      elementId: string | null;
      rowNumber: number | null;
    }>;
  };
};

export type UpdateBillOfQuantitiesProposalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FillOutBoQInput;
}>;

export type UpdateBillOfQuantitiesProposalMutation = {
  __typename: 'Mutation';
  fillOutBidBoQ: {
    __typename: 'UpdateBoQFieldResponse';
    id: string;
    positions: Array<{
      __typename: 'UpdateBoQFieldResponsePosition';
      quantity: number;
      unitPrice: number;
      totalPrice: number;
      totalPriceGross: number | null;
      totalPriceGrossDeducted: number | null;
      deductedPrice: number;
      deductionFactor: number;
      longText: string | null;
      htmlLongText: string | null;
      path: string;
      complementingPricePercentage: number | null;
      quantityComponents: Array<{ __typename: 'QuantityComponent'; result: number }>;
      labourComponents: {
        __typename: 'UpdateBoQFieldResponsePositionLabourPriceComponent';
        label: string | null;
        totalTime: number;
        values: Array<{
          __typename: 'UpdateBoQFieldResponsePositionPriceCalculation';
          result: number;
        }> | null;
      };
      priceComponents: Array<{
        __typename: 'UpdateBoQFieldResponsePositionPriceComponent';
        price: number;
        label: string | null;
        values: Array<{
          __typename: 'UpdateBoQFieldResponsePositionPriceCalculation';
          result: number;
        }> | null;
      }>;
    }>;
    groups: Array<{
      __typename: 'UpdateBoQFieldResponseGroup';
      totalPrice: number;
      totalPriceGross: number | null;
      totalPriceGrossDeducted: number | null;
      deductedPrice: number;
      deductionFactor: number;
      absoluteDeduction: number | null;
      path: string;
    }>;
    subDescriptions: Array<{
      __typename: 'UpdateBoQFieldResponseSubDescription';
      htmlLongText: string | null;
      path: string;
    }>;
    notes: Array<{
      __typename: 'UpdateBoQFieldResponseNote';
      htmlLongText: string | null;
      path: string;
    }>;
  };
};

export type UpdateBillOfQuantitiesCalculationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FillOutBoQInput;
}>;

export type UpdateBillOfQuantitiesCalculationMutation = {
  __typename: 'Mutation';
  fillOutCalculationBoQ: {
    __typename: 'UpdateBoQFieldResponse';
    id: string;
    positions: Array<{
      __typename: 'UpdateBoQFieldResponsePosition';
      quantity: number;
      unitPrice: number;
      totalPrice: number;
      totalPriceGross: number | null;
      totalPriceGrossDeducted: number | null;
      deductedPrice: number;
      deductionFactor: number;
      longText: string | null;
      htmlLongText: string | null;
      path: string;
      complementingPricePercentage: number | null;
      quantityComponents: Array<{ __typename: 'QuantityComponent'; result: number }>;
      labourComponents: {
        __typename: 'UpdateBoQFieldResponsePositionLabourPriceComponent';
        label: string | null;
        totalTime: number;
        values: Array<{
          __typename: 'UpdateBoQFieldResponsePositionPriceCalculation';
          result: number;
        }> | null;
      };
      priceComponents: Array<{
        __typename: 'UpdateBoQFieldResponsePositionPriceComponent';
        price: number;
        label: string | null;
        values: Array<{
          __typename: 'UpdateBoQFieldResponsePositionPriceCalculation';
          result: number;
        }> | null;
      }>;
    }>;
    groups: Array<{
      __typename: 'UpdateBoQFieldResponseGroup';
      totalPrice: number;
      totalPriceGross: number | null;
      totalPriceGrossDeducted: number | null;
      deductedPrice: number;
      deductionFactor: number;
      absoluteDeduction: number | null;
      path: string;
    }>;
    subDescriptions: Array<{
      __typename: 'UpdateBoQFieldResponseSubDescription';
      htmlLongText: string | null;
      path: string;
    }>;
    notes: Array<{
      __typename: 'UpdateBoQFieldResponseNote';
      htmlLongText: string | null;
      path: string;
    }>;
  };
};

export type BoQFromCacheFragment = {
  __typename: 'BillOfQuantities';
  id: string;
  content: JSONObject;
};

export type EditRequestBoQSuccessFragment = {
  __typename: 'EditRequestBoQSuccess';
  id: string | null;
  success: boolean;
  hasTaxRateChanges: boolean | null;
  hasUnitChanges: boolean | null;
  hasUnitPriceChanges: boolean | null;
};

export type DeleteBoQInBoQTableMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DeleteBoQInBoQTableMutation = {
  __typename: 'Mutation';
  deleteBoQInBidPackage:
    | {
        __typename: 'EditRequestBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type EditTemplateBoQSuccessFragment = {
  __typename: 'EditTemplateBoQSuccess';
  id: string | null;
  success: boolean;
  hasTaxRateChanges: boolean | null;
  hasUnitChanges: boolean | null;
  hasUnitPriceChanges: boolean | null;
};

export type DiscardRequestBillOfQuantitiesMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DiscardRequestBillOfQuantitiesMutation = {
  __typename: 'Mutation';
  discardRequestBillOfQuantities:
    | {
        __typename: 'EditRequestBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type DiscardTemplateBillOfQuantitiesMutationVariables = Exact<{
  boqTemplateId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DiscardTemplateBillOfQuantitiesMutation = {
  __typename: 'Mutation';
  discardTemplateBillOfQuantities:
    | {
        __typename: 'EditTemplateBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type EditRequestBoQMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  inputs: Array<EditRequestForProposalsBoQInput> | EditRequestForProposalsBoQInput;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type EditRequestBoQMutation = {
  __typename: 'Mutation';
  editRequestBillOfQuantities:
    | {
        __typename: 'EditRequestBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type EditTemplateBoQMutationVariables = Exact<{
  boqTemplateId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  inputs: Array<EditRequestForProposalsBoQInput> | EditRequestForProposalsBoQInput;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type EditTemplateBoQMutation = {
  __typename: 'Mutation';
  editTemplateBillOfQuantities:
    | {
        __typename: 'EditTemplateBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type LBHochbauRestrictedItemNumbersQueryVariables = Exact<{ [key: string]: never }>;

export type LBHochbauRestrictedItemNumbersQuery = {
  __typename: 'Query';
  lbHochbauRestrictedItemNumbers: {
    __typename: 'LbHochbauRestrictedItemNumbersResponse';
    restrictedItemNumbers: Array<string>;
  };
};

export type SaveBidPackageBoQMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  message: Scalars['String']['input'];
  isShareableWithBidders: Scalars['Boolean']['input'];
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SaveBidPackageBoQMutation = {
  __typename: 'Mutation';
  saveRequestBillOfQuantities:
    | {
        __typename: 'EditRequestBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type SaveTemplateBoQMutationVariables = Exact<{
  boqTemplateId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  verifyEditOperationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SaveTemplateBoQMutation = {
  __typename: 'Mutation';
  saveTemplateBillOfQuantities:
    | {
        __typename: 'EditTemplateBoQSuccess';
        id: string | null;
        success: boolean;
        hasTaxRateChanges: boolean | null;
        hasUnitChanges: boolean | null;
        hasUnitPriceChanges: boolean | null;
      }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type BoQTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BoQTemplateQuery = {
  __typename: 'Query';
  boqTemplate: {
    __typename: 'BoQTemplate';
    id: string;
    name: string | null;
    updatedAt: DateTime;
    boqFileFormat: BOQ_FILE_FORMAT;
    currency: CURRENCY;
    taxRate: number | null;
    changedByUser: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    };
  };
};

export type CompanyConfigFragment = {
  __typename: 'Company';
  id: string;
  type: COMPANY_TYPE;
  bidsSubmittedOnMarketplace: number;
  permanentFeatureFlags: JSONObject;
  config: {
    __typename: 'CompanyConfig';
    id: string;
    additionalLanguages: Array<LANGUAGE>;
    premiumEnabled: boolean;
  };
};

export type BidPackagePricePredictionsQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  pricePredictionContext: PRICE_PREDICTION_CONTEXT;
}>;

export type BidPackagePricePredictionsQuery = {
  __typename: 'Query';
  predictPriceForBidPackage: {
    __typename: 'PricePredictionResponse';
    predictions: JSONObject;
    meta: {
      __typename: 'PricePredictionMeta';
      version: string;
      supportedPriceRange: {
        __typename: 'PricePredictionConstraint';
        lt: number | null;
        lte: number | null;
        gt: number | null;
        gte: number | null;
      };
    };
  };
};

export type StartTrialMutationVariables = Exact<{
  module: TRIAL_MODULE;
}>;

export type StartTrialMutation = { __typename: 'Mutation'; startTrial: boolean };

export type RequestPricingPlanUpsellMutationVariables = Exact<{
  pricingPlan?: InputMaybe<PRICING_PLAN>;
}>;

export type RequestPricingPlanUpsellMutation = {
  __typename: 'Mutation';
  requestPricingPlanUpsell: boolean;
};

export type RequestBidderIntroductionEmailUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestBidderIntroductionEmailUpsellMutation = {
  __typename: 'Mutation';
  requestBidderIntroductionEmailUpsell: boolean;
};

export type RequestAVAToolMoreInfoMutationVariables = Exact<{ [key: string]: never }>;

export type RequestAVAToolMoreInfoMutation = {
  __typename: 'Mutation';
  requestAVAToolMoreInfo: boolean;
};

export type RequestCustomAccountTypesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCustomAccountTypesUpsellMutation = {
  __typename: 'Mutation';
  requestCustomAccountTypesUpsell: boolean;
};

export type RequestDataAndAnalyticsUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestDataAndAnalyticsUpsellMutation = {
  __typename: 'Mutation';
  requestDataAndAnalyticsUpsell: boolean;
};

export type RequestInternalDocumentManagementUpsellMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RequestInternalDocumentManagementUpsellMutation = {
  __typename: 'Mutation';
  requestInternalDocumentManagementUpsell: boolean;
};

export type RequestMessageTemplatesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestMessageTemplatesUpsellMutation = {
  __typename: 'Mutation';
  requestCompanyTemplatesUpsell: boolean;
};

export type RequestReverseTrialUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestReverseTrialUpsellMutation = {
  __typename: 'Mutation';
  requestReverseTrialUpsell: boolean;
};

export type RequestMultipleOfficesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestMultipleOfficesUpsellMutation = {
  __typename: 'Mutation';
  requestMultipleOfficesUpsell: boolean;
};

export type RequestCustomSignaturesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCustomSignaturesUpsellMutation = {
  __typename: 'Mutation';
  requestCustomSignaturesUpsell: boolean;
};

export type CreateDocumentMutationVariables = Exact<{
  input: CreateDocumentInput;
}>;

export type CreateDocumentMutation = {
  __typename: 'Mutation';
  createDocument: { __typename: 'CreateDocumentResponse'; id: string; uploadUrl: string };
};

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateDocumentInput;
}>;

export type UpdateDocumentMutation = {
  __typename: 'Mutation';
  updateDocument: { __typename: 'Document'; id: string };
};

export type AddContactsToBidRequestMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
  skipInvitation: Scalars['Boolean']['input'];
  contactId?: InputMaybe<Scalars['ID']['input']>;
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  customBidsDueAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
}>;

export type AddContactsToBidRequestMutation = {
  __typename: 'Mutation';
  addContactsToBidRequest:
    | { __typename: 'BidRequest'; id: string }
    | { __typename: 'BidderAlreadyAddedError'; contactId: string | null }
    | { __typename: 'BiddersLimitExceededError'; limit: number };
};

export type DeleteContactFromBidRequestMutationVariables = Exact<{
  bidInviteId: Scalars['ID']['input'];
}>;

export type DeleteContactFromBidRequestMutation = {
  __typename: 'Mutation';
  deleteContactFromBidRequest: boolean;
};

export type CreateDraftAddendumMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type CreateDraftAddendumMutation = {
  __typename: 'Mutation';
  createDraftAddendum: {
    __typename: 'DraftAddendum';
    id: string;
    contractRefNumber: string | null;
    executionDate: DateString | null;
    name: string | null;
    number: number;
    remarks: string | null;
    status: ADDENDUM_STATUS;
    totalGross: number | null;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      addendumNumbers: Array<number>;
      currency: CURRENCY;
      name: string;
      number: string | null;
      awardedBid: {
        __typename: 'AwardedBid';
        id: string;
        bidRequest: {
          __typename: 'BidRequest';
          id: string;
          contacts: Array<{
            __typename: 'BidRequestContact';
            email: string | null;
            firstName: string | null;
            id: string;
            isPerson: boolean;
            jobTitle: string | null;
            phone: string | null;
            mobilePhone: string | null;
            fax: string | null;
            lastName: string | null;
            title: CONTACT_TITLE | null;
            bidInvite: { __typename: 'BidInvite'; id: string } | null;
          }>;
          subcontractor: {
            __typename: 'Subcontractor';
            city: string | null;
            countryCode: COUNTRY_CODE | null;
            id: string;
            name: string;
            postalCode: string | null;
            street: string | null;
            website: string | null;
          };
        };
        parentBid: {
          __typename: 'Bid';
          id: string;
          billOfQuantities: {
            __typename: 'BillOfQuantities';
            content: JSONObject;
            currency: CURRENCY;
            id: string;
            comments: Array<{
              __typename: 'BillOfQuantitiesComment';
              id: string;
              path: string;
              text: string;
            }>;
          } | null;
        } | null;
        submittedByUser: {
          __typename: 'User';
          email: string;
          firstName: string | null;
          id: string;
          lastName: string | null;
        } | null;
      } | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        status: PROJECT_STATUS;
        preferredBoQFileFormat: BOQ_FILE_FORMAT;
        costGroupCatalogs: Array<{
          __typename: 'ProjectCostGroupCatalog';
          catalogType: PROJECT_COST_CATALOG_TYPE;
          customCatalogId: string | null;
          customCatalogName: string | null;
        }>;
      };
    };
    billOfQuantities: {
      __typename: 'BillOfQuantities';
      content: JSONObject;
      id: string;
      comments: Array<{
        __typename: 'BillOfQuantitiesComment';
        id: string;
        path: string;
        text: string;
      }>;
    };
  };
};

export type AddendumsQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ADDENDUM_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AddendumsQuery = {
  __typename: 'Query';
  addendums: {
    __typename: 'AddendumsResponse';
    totalRecords: number;
    addendums: Array<{
      __typename: 'Addendum';
      createdAt: DateTime;
      id: string;
      name: string;
      number: number;
      status: ADDENDUM_STATUS;
      totalGross: number;
      bidPackage: {
        __typename: 'BidPackage';
        currency: CURRENCY;
        id: string;
        name: string;
        number: string | null;
        project: { __typename: 'Project'; id: string; name: string; number: string | null };
        awardedBid: {
          __typename: 'AwardedBid';
          id: string;
          bidRequest: {
            __typename: 'BidRequest';
            id: string;
            subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
          };
        } | null;
      };
    }>;
  };
};

export type DeleteAddendumMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteAddendumMutation = { __typename: 'Mutation'; deleteAddendum: boolean };

export type ClaimSubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  type: SUBCONTRACTOR_CLAIM_REFERENCE;
}>;

export type ClaimSubcontractorMutation = { __typename: 'Mutation'; claimSubcontractorBy: boolean };

export type PublicSubcontractorProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublicSubcontractorProfileQuery = {
  __typename: 'Query';
  publicSubcontractorProfile: {
    __typename: 'PublicSubcontractorProfile';
    id: string;
    name: string;
    isClaimedByAgentCompany: boolean;
  };
};

export type BidRequestsForSelectQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
}>;

export type BidRequestsForSelectQuery = {
  __typename: 'Query';
  bidRequestsForAgent: {
    __typename: 'BidRequestsResponse';
    totalRecords: number;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      type: BID_REQUEST_TYPE;
      status: BID_REQUEST_STATUS;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        project: {
          __typename: 'Project';
          id: string;
          name: string;
          number: string | null;
          customer: string | null;
          status: PROJECT_STATUS;
          office: {
            __typename: 'Office';
            id: string;
            name: string;
            company: { __typename: 'Company'; id: string; name: string };
          };
        };
      };
      subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
    }>;
  };
};

export type SendVerificationCodeMutationVariables = Exact<{
  loginToken: Scalars['String']['input'];
}>;

export type SendVerificationCodeMutation = {
  __typename: 'Mutation';
  sendVerificationCode: boolean;
};

export type CheckVerificationCodeMutationVariables = Exact<{
  loginToken: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;

export type CheckVerificationCodeMutation = {
  __typename: 'Mutation';
  checkVerificationCode: { __typename: 'CheckVerificationCodeResponse'; mfaToken: string | null };
};

export type RequestMfaResetMutationVariables = Exact<{
  loginToken: Scalars['String']['input'];
}>;

export type RequestMfaResetMutation = { __typename: 'Mutation'; requestMfaReset: boolean };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResetPasswordMutation = { __typename: 'Mutation'; resetPassword: boolean };

export type CheckCredentialsMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  companyType?: InputMaybe<COMPANY_TYPE>;
}>;

export type CheckCredentialsMutation = {
  __typename: 'Mutation';
  checkCredentials: {
    __typename: 'CheckCredentialsResponse';
    loginToken: string;
    mfaRequired: boolean;
    mfaMethod: MFA_METHOD | null;
    mfaPhoneCensored: string | null;
    companyName: string;
  };
};

type RequestPasswordlessLoginCode_RequestPasswordlessLoginCodeFailure_Fragment = {
  __typename: 'RequestPasswordlessLoginCodeFailure';
  reason: REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON;
};

type RequestPasswordlessLoginCode_RequestPasswordlessLoginCodeSuccess_Fragment = {
  __typename: 'RequestPasswordlessLoginCodeSuccess';
};

export type RequestPasswordlessLoginCodeFragment =
  | RequestPasswordlessLoginCode_RequestPasswordlessLoginCodeFailure_Fragment
  | RequestPasswordlessLoginCode_RequestPasswordlessLoginCodeSuccess_Fragment;

export type RequestPasswordlessLoginCodeForBidSubmissionMutationVariables = Exact<{
  email: Scalars['String']['input'];
  bidPackageId: Scalars['ID']['input'];
  redirectUrl: Scalars['String']['input'];
}>;

export type RequestPasswordlessLoginCodeForBidSubmissionMutation = {
  __typename: 'Mutation';
  requestPasswordlessLoginCodeForBidSubmission:
    | {
        __typename: 'RequestPasswordlessLoginCodeFailure';
        reason: REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON;
      }
    | { __typename: 'RequestPasswordlessLoginCodeSuccess' };
};

export type RequestPasswordlessLoginCodeForTaskMutationVariables = Exact<{
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
}>;

export type RequestPasswordlessLoginCodeForTaskMutation = {
  __typename: 'Mutation';
  requestPasswordlessLoginCodeForTask:
    | {
        __typename: 'RequestPasswordlessLoginCodeFailure';
        reason: REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON;
      }
    | { __typename: 'RequestPasswordlessLoginCodeSuccess' };
};

export type RequestPasswordlessLoginCodeForInternalNoteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
}>;

export type RequestPasswordlessLoginCodeForInternalNoteMutation = {
  __typename: 'Mutation';
  requestPasswordlessLoginCodeForInternalNote:
    | {
        __typename: 'RequestPasswordlessLoginCodeFailure';
        reason: REQUEST_PASSWORDLESS_LOGIN_CODE_FAILURE_REASON;
      }
    | { __typename: 'RequestPasswordlessLoginCodeSuccess' };
};

type ValidatePasswordlessLoginResponse_CheckCredentialsResponse_Fragment = {
  __typename: 'CheckCredentialsResponse';
  loginToken: string;
  mfaRequired: boolean;
  mfaMethod: MFA_METHOD | null;
  mfaPhoneCensored: string | null;
  companyName: string;
};

type ValidatePasswordlessLoginResponse_ValidatePasswordlessLoginFailure_Fragment = {
  __typename: 'ValidatePasswordlessLoginFailure';
  reason: VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON;
};

export type ValidatePasswordlessLoginResponseFragment =
  | ValidatePasswordlessLoginResponse_CheckCredentialsResponse_Fragment
  | ValidatePasswordlessLoginResponse_ValidatePasswordlessLoginFailure_Fragment;

export type ValidatePasswordlessLoginCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
  companyType?: InputMaybe<COMPANY_TYPE>;
}>;

export type ValidatePasswordlessLoginCodeMutation = {
  __typename: 'Mutation';
  validatePasswordlessLoginCode:
    | {
        __typename: 'CheckCredentialsResponse';
        loginToken: string;
        mfaRequired: boolean;
        mfaMethod: MFA_METHOD | null;
        mfaPhoneCensored: string | null;
        companyName: string;
      }
    | {
        __typename: 'ValidatePasswordlessLoginFailure';
        reason: VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON;
      };
};

export type ValidatePasswordlessLoginTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
  companyType?: InputMaybe<COMPANY_TYPE>;
}>;

export type ValidatePasswordlessLoginTokenMutation = {
  __typename: 'Mutation';
  validatePasswordlessLoginToken:
    | {
        __typename: 'CheckCredentialsResponse';
        loginToken: string;
        mfaRequired: boolean;
        mfaMethod: MFA_METHOD | null;
        mfaPhoneCensored: string | null;
        companyName: string;
      }
    | {
        __typename: 'ValidatePasswordlessLoginFailure';
        reason: VALIDATE_PASSWORDLESS_LOGIN_FAILURE_REASON;
      };
};

export type ResendConfirmationEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ResendConfirmationEmailMutation = {
  __typename: 'Mutation';
  resendOwnConfirmationEmail: boolean;
};

export type AgentSignupMutationVariables = Exact<{
  input: AgentSignupUnsolicitedInput;
}>;

export type AgentSignupMutation = {
  __typename: 'Mutation';
  agentSignupUnsolicited: {
    __typename: 'AgentSignupResponse';
    isFirstUser: boolean;
    subcontractorName: string;
    company: {
      __typename: 'Company';
      id: string;
      config: { __typename: 'CompanyConfig'; premiumEnabled: boolean };
    };
  };
};

export type DuplicateBidPackageModalProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type DuplicateBidPackageModalProjectsQuery = {
  __typename: 'Query';
  projectsForDuplicatingBidPackages: {
    __typename: 'ProjectsResponse';
    projects: Array<{ __typename: 'Project'; id: string; number: string | null; name: string }>;
  };
};

export type DeleteBidPackageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeleteBidPackageMutation = { __typename: 'Mutation'; deleteBidPackage: boolean };

export type CloseBidPackageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
}>;

export type CloseBidPackageMutation = { __typename: 'Mutation'; closeBidPackage: boolean };

export type ReopenBidPackageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReopenBidPackageMutation = { __typename: 'Mutation'; reopenBidPackage: boolean };

export type DuplicateBidPackageModalDuplicateBidPackageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  includeContacts: Scalars['Boolean']['input'];
  includeDescription: Scalars['Boolean']['input'];
  includeDocuments: Scalars['Boolean']['input'];
  includeBoQ: Scalars['Boolean']['input'];
  includeBindingPeriod: Scalars['Boolean']['input'];
  includeBidPackageTeam: Scalars['Boolean']['input'];
  newName: Scalars['String']['input'];
  destinationProjectId: Scalars['ID']['input'];
}>;

export type DuplicateBidPackageModalDuplicateBidPackageMutation = {
  __typename: 'Mutation';
  duplicateBidPackage: boolean;
};

export type BidPackageForBiddersAwaitingResponseCalloutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidPackageForBiddersAwaitingResponseCalloutQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    blindTenderBidsOpenedAt: DateTime | null;
    isBlindTender: boolean;
    openingDate: DateTime | null;
    bidRequests: Array<{ __typename: 'BidRequest'; id: string; status: BID_REQUEST_STATUS }>;
  };
};

export type BidRequestsToNotifyOfDeletionCountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidRequestsToNotifyOfDeletionCountQuery = {
  __typename: 'Query';
  bidPackage: { __typename: 'BidPackage'; bidRequestsToNotifyOfDeletionCount: number };
};

export type BidRequestsToNotifyOfClosingCountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidRequestsToNotifyOfClosingCountQuery = {
  __typename: 'Query';
  bidPackage: { __typename: 'BidPackage'; bidRequestsToNotifyOfClosingCount: number };
};

export type BidPackagesBasicInfoQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type BidPackagesBasicInfoQuery = {
  __typename: 'Query';
  bidPackages: {
    __typename: 'BidPackagesResponse';
    bidPackages: Array<{
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
    }>;
  };
};

export type BidPackagesForSelectQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  query: Scalars['String']['input'];
  sortBy: BID_PACKAGES_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
  statuses?: InputMaybe<Array<BID_PACKAGE_STATUS> | BID_PACKAGE_STATUS>;
  excludeClosedProjects?: InputMaybe<Scalars['Boolean']['input']>;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type BidPackagesForSelectQuery = {
  __typename: 'Query';
  bidPackages: {
    __typename: 'BidPackagesResponse';
    totalRecords: number;
    bidPackages: Array<{
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
      project: { __typename: 'Project'; id: string; name: string; number: string | null };
    }>;
  };
};

export type SelectedBidPackageForSelectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SelectedBidPackageForSelectQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    billOfQuantities: { __typename: 'BillOfQuantities'; id: string } | null;
    project: { __typename: 'Project'; id: string; name: string; number: string | null };
  };
};

export type AddBidRequestNoteMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
}>;

export type AddBidRequestNoteMutation = { __typename: 'Mutation'; addBidRequestNote: boolean };

export type DeleteBidRequestNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBidRequestNoteMutation = {
  __typename: 'Mutation';
  deleteBidRequestNote: boolean;
};

export type DiscardDraftBoQInSubmitBidModalMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type DiscardDraftBoQInSubmitBidModalMutation = {
  __typename: 'Mutation';
  discardDraftBoQ: boolean;
};

export type UpdateDraftBidInSummaryTableMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  input: BidInput;
}>;

export type UpdateDraftBidInSummaryTableMutation = {
  __typename: 'Mutation';
  updateDraftBid: {
    __typename: 'DraftBid';
    id: string;
    totalNet: number | null;
    skontoRate: number | null;
    skontoDeadline: number | null;
    discountRate: number | null;
    absoluteDiscount: number | null;
    taxRate: number | null;
  };
};

export type BidsList_bidsListFragment = {
  __typename: 'BidRequest';
  id: string;
  submittedBids: Array<{
    __typename: 'SubmittedBid';
    id: string;
    totalNet: number;
    discountRate: number | null;
    absoluteDiscount: number | null;
    skontoDeadline: number;
    skontoRate: number;
    taxRate: number;
    submittedAt: DateTime;
    version: number;
    isSimulated: boolean | null;
    documentsCount: number;
    message: string | null;
    billOfQuantitiesRevision: {
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      isShareableWithBidders: boolean;
      wereBiddersNotified: boolean | null;
    };
  }>;
};

export type BoQTemplatesForSelectQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BOQ_TEMPLATES_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  boqFileFormat?: InputMaybe<BOQ_FILE_FORMAT>;
}>;

export type BoQTemplatesForSelectQuery = {
  __typename: 'Query';
  boqTemplates: {
    __typename: 'BoQTemplatesResponse';
    totalRecords: number;
    boqTemplates: Array<{ __typename: 'BoQTemplate'; id: string; name: string | null }>;
  };
};

export type UpdateBoQTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateBoQTemplateInput;
}>;

export type UpdateBoQTemplateMutation = {
  __typename: 'Mutation';
  updateBoQTemplate:
    | { __typename: 'UpdateBoQTemplateFailure' }
    | {
        __typename: 'UpdateBoQTemplateSuccess';
        template: { __typename: 'BoQTemplate'; id: string; name: string | null };
      };
};

export type UpdateCertificateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  issueDate: Scalars['String']['input'];
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateCertificateMutation = { __typename: 'Mutation'; updateCertificate: boolean };

export type CreateAgentCompanyCertificateMutationVariables = Exact<{
  certificateTypeId: Scalars['ID']['input'];
  fileSizeBytes: Scalars['Int']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  uploadKey: Scalars['ID']['input'];
}>;

export type CreateAgentCompanyCertificateMutation = {
  __typename: 'Mutation';
  createAgentCompanyCertificate: {
    __typename: 'CreateCertificateResponse';
    certificate: {
      __typename: 'Certificate';
      id: string;
      issueDate: string;
      expiryDate: string | null;
      createdAt: DateTime;
      fileName: string;
      fileSizeBytes: number;
      availability: CERTIFICATE_AVAILABILITY;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: { __typename: 'Project'; id: string; name: string };
      } | null;
    };
  };
};

export type CreateSubcontractorCertificateMutationVariables = Exact<{
  certificateTypeId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
  fileSizeBytes: Scalars['Int']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  uploadKey: Scalars['ID']['input'];
}>;

export type CreateSubcontractorCertificateMutation = {
  __typename: 'Mutation';
  createSubcontractorCertificate: {
    __typename: 'CreateCertificateResponse';
    certificate: {
      __typename: 'Certificate';
      id: string;
      issueDate: string;
      expiryDate: string | null;
      createdAt: DateTime;
      fileName: string;
      fileSizeBytes: number;
      availability: CERTIFICATE_AVAILABILITY;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: { __typename: 'Project'; id: string; name: string };
      } | null;
    };
  };
};

export type CertificateModal_OfficeFragment = {
  __typename: 'Office';
  id: string;
  name: string;
  company: {
    __typename: 'Company';
    id: string;
    type: COMPANY_TYPE;
    bidsSubmittedOnMarketplace: number;
    permanentFeatureFlags: JSONObject;
    config: {
      __typename: 'CompanyConfig';
      id: string;
      additionalLanguages: Array<LANGUAGE>;
      premiumEnabled: boolean;
    };
  };
};

export type CreateCertificateBySubcontractorMutationVariables = Exact<{
  certificateTypeId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
  certificateRequestItemId?: InputMaybe<Scalars['ID']['input']>;
  fileSizeBytes: Scalars['Int']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  uploadKey?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateCertificateBySubcontractorMutation = {
  __typename: 'Mutation';
  createSubcontractorCertificateDraft: {
    __typename: 'CreateCertificateResponse';
    certificate: {
      __typename: 'Certificate';
      id: string;
      issueDate: string;
      certificateType: { __typename: 'CertificateType'; id: string; name: string };
    };
  };
};

export type UpdateCertificateBySubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  issueDate: Scalars['String']['input'];
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateCertificateBySubcontractorMutation = {
  __typename: 'Mutation';
  updateCertificate: boolean;
};

export type SubmitCertificateRequestBySubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  certificateId: Scalars['ID']['input'];
}>;

export type SubmitCertificateRequestBySubcontractorMutation = {
  __typename: 'Mutation';
  submitCertificateRequest: boolean;
};

export type SaveInProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SaveInProfileMutation = { __typename: 'Mutation'; saveInProfile: boolean };

export type CertificateModal_CertificateRequestFragment = {
  __typename: 'CertificateRequest';
  id: string;
  bidPackage: { __typename: 'BidPackage'; id: string } | null;
  contact: {
    __typename: 'Contact';
    id: string;
    subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
  };
};

export type CertificateModal_CertificateTypeFragment = {
  __typename: 'CertificateType';
  id: string;
  scope: CERTIFICATE_TYPE_SCOPE;
  isExpiryDateMandatory: boolean;
  name: string;
};

export type CertificateModal_CertificateRequestItemFragment = {
  __typename: 'CertificateRequestItem';
  id: string;
  canSaveInProfile: boolean;
};

export type CertificateModal_CertificateFragment = {
  __typename: 'Certificate';
  id: string;
  issueDate: string;
  expiryDate: string | null;
  fileName: string;
  fileSizeBytes: number;
};

export type CertificateFileNameQueryVariables = Exact<{
  certificateTypeId: Scalars['ID']['input'];
  fileType: CERTIFICATE_FILE_TYPE;
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CertificateFileNameQuery = { __typename: 'Query'; certificateFileName: string };

export type CertificateUploadUrlQueryVariables = Exact<{ [key: string]: never }>;

export type CertificateUploadUrlQuery = {
  __typename: 'Query';
  certificateUploadUrl: { __typename: 'FileUploadResponse'; uploadKey: string; uploadUrl: string };
};

export type CertificateTypeModal_companyCertificateTypeFragment = {
  __typename: 'CertificateType';
  id: string;
  name: string;
  isSelectedByDefaultWhenRequesting: boolean;
  isExpiryDateMandatory: boolean;
  scope: CERTIFICATE_TYPE_SCOPE;
  isInUse: boolean;
  templateDocument: { __typename: 'Document'; id: string; filename: string; size: number } | null;
};

export type SuggestedGlobalCertificateTypesQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestedGlobalCertificateTypesQuery = {
  __typename: 'Query';
  suggestedGlobalCertificateTypes: {
    __typename: 'SuggestedGlobalCertificateTypesResponse';
    certificateTypes: Array<string>;
  };
};

export type CreateCompanyCertificateTypeMutationVariables = Exact<{
  name: Scalars['String']['input'];
  isSelectedByDefaultWhenRequesting: Scalars['Boolean']['input'];
  isExpiryDateMandatory: Scalars['Boolean']['input'];
  templateDocumentId?: InputMaybe<Scalars['ID']['input']>;
  scope: CERTIFICATE_TYPE_SCOPE;
}>;

export type CreateCompanyCertificateTypeMutation = {
  __typename: 'Mutation';
  createCompanyCertificateType: { __typename: 'CertificateType'; id: string };
};

export type UpdateCompanyCertificateTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  isSelectedByDefaultWhenRequesting: Scalars['Boolean']['input'];
  isExpiryDateMandatory: Scalars['Boolean']['input'];
  templateDocumentId?: InputMaybe<Scalars['ID']['input']>;
  scope: CERTIFICATE_TYPE_SCOPE;
}>;

export type UpdateCompanyCertificateTypeMutation = {
  __typename: 'Mutation';
  updateCompanyCertificateType: { __typename: 'CertificateType'; id: string };
};

export type CertificatesTable_CertificateTypeReportFragment = {
  __typename: 'CertificateTypeReport';
  id: string;
  type: CERTIFICATE_TYPE_TYPE;
  scope: CERTIFICATE_TYPE_SCOPE;
  name: string;
  isExpiryDateMandatory: boolean;
  subcontractorTypeId: string | null;
  status: CERTIFICATE_TYPE_STATUS;
  maxExpiryDate: DateTime | null;
  requestedOn: DateTime | null;
  requestedDueDate: DateTime | null;
  rejectionReason: string | null;
  templateDocument: { __typename: 'Document'; id: string; filename: string; size: number } | null;
  certificates: Array<{
    __typename: 'Certificate';
    id: string;
    issueDate: string;
    expiryDate: string | null;
    createdAt: DateTime;
    fileName: string;
    fileSizeBytes: number;
    availability: CERTIFICATE_AVAILABILITY;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      project: { __typename: 'Project'; id: string; name: string };
    } | null;
  }>;
  pendingCertificateRequestItemsForBidPackages: Array<{
    __typename: 'CertificateRequestItem';
    id: string;
    createdAt: DateTime;
    rejectionReason: string | null;
    certificateRequest: {
      __typename: 'CertificateRequest';
      dueDate: string | null;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: { __typename: 'Project'; id: string; name: string };
      } | null;
    };
  }>;
};

export type CertificatesTable_PendingCertificateRequestItemForBidPackageFragment = {
  __typename: 'CertificateRequestItem';
  id: string;
  createdAt: DateTime;
  rejectionReason: string | null;
  certificateRequest: {
    __typename: 'CertificateRequest';
    dueDate: string | null;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      project: { __typename: 'Project'; id: string; name: string };
    } | null;
  };
};

export type CertificatesTable_CertificateFragment = {
  __typename: 'Certificate';
  id: string;
  issueDate: string;
  expiryDate: string | null;
  createdAt: DateTime;
  fileName: string;
  fileSizeBytes: number;
  availability: CERTIFICATE_AVAILABILITY;
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    project: { __typename: 'Project'; id: string; name: string };
  } | null;
};

export type DeleteCertificateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCertificateMutation = { __typename: 'Mutation'; deleteCertificate: boolean };

export type CancelCertificationRequestMutationVariables = Exact<{
  certificateTypeId: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CancelCertificationRequestMutation = {
  __typename: 'Mutation';
  cancelCertificateRequest: boolean;
};

export type GetParsedTemplatesByTypeQueryVariables = Exact<{
  type: COMPANY_TEMPLATE_TYPE;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  bidRequestId?: InputMaybe<Scalars['ID']['input']>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  emailTemplatePlaceholderInput?: InputMaybe<EmailTemplatePlaceholderInput>;
  salutationMode?: InputMaybe<EMAIL_SALUTATION_MODE>;
}>;

export type GetParsedTemplatesByTypeQuery = {
  __typename: 'Query';
  parsedCompanyTemplatesByType: Array<{
    __typename: 'CompanyTemplate';
    id: string;
    name: string;
    content: string;
    type: COMPANY_TEMPLATE_TYPE;
    isDefault: boolean;
  }>;
};

export type RequestCompanyTemplatesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCompanyTemplatesUpsellMutation = {
  __typename: 'Mutation';
  requestCompanyTemplatesUpsell: boolean;
};

export type CompleteContractSigningMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  signedDocumentId?: InputMaybe<Scalars['ID']['input']>;
  contractRunAgentStepId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CompleteContractSigningMutation = {
  __typename: 'Mutation';
  completeContractSigning: boolean;
};

export type CreateRecipientViewUrlMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  contractRunAgentStepId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<LANGUAGE>;
}>;

export type CreateRecipientViewUrlMutation = {
  __typename: 'Mutation';
  createDocuSignRecipientViewUrl: string;
};

export type ContractRunAgentStepQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
}>;

export type ContractRunAgentStepQuery = {
  __typename: 'Query';
  contractRunAgentStep: { __typename: 'ContractRunAgentStep'; id: string; index: number };
};

export type CopyFromInternalFilingMutationVariables = Exact<{
  sourceAttachable: AttachableInput;
  targetAttachable: AttachableInput;
  paths: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CopyFromInternalFilingMutation = {
  __typename: 'Mutation';
  copyItemsFromAnotherFileTree: boolean;
};

export type RequestCustomBidInvitesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCustomBidInvitesUpsellMutation = {
  __typename: 'Mutation';
  requestCustomBidInvitesUpsell: boolean;
};

type CustomFieldWithValue_CustomFieldWithValueCheckbox_Fragment = {
  __typename: 'CustomFieldWithValueCheckbox';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueCheckbox: boolean;
};

type CustomFieldWithValue_CustomFieldWithValueCurrency_Fragment = {
  __typename: 'CustomFieldWithValueCurrency';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueCurrency: number | null;
};

type CustomFieldWithValue_CustomFieldWithValueDate_Fragment = {
  __typename: 'CustomFieldWithValueDate';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueDate: DateString | null;
};

type CustomFieldWithValue_CustomFieldWithValueDateTime_Fragment = {
  __typename: 'CustomFieldWithValueDateTime';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueDateTime: DateTime | null;
};

type CustomFieldWithValue_CustomFieldWithValueMultiSelect_Fragment = {
  __typename: 'CustomFieldWithValueMultiSelect';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueMultiSelect: Array<string>;
  options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
};

type CustomFieldWithValue_CustomFieldWithValueNumber_Fragment = {
  __typename: 'CustomFieldWithValueNumber';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueNumber: number | null;
};

type CustomFieldWithValue_CustomFieldWithValueSingleSelect_Fragment = {
  __typename: 'CustomFieldWithValueSingleSelect';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueSingleSelect: string | null;
  options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
};

type CustomFieldWithValue_CustomFieldWithValueText_Fragment = {
  __typename: 'CustomFieldWithValueText';
  id: string;
  isRequired: boolean;
  label: string;
  entityId: string | null;
  valueText: string | null;
};

export type CustomFieldWithValueFragment =
  | CustomFieldWithValue_CustomFieldWithValueCheckbox_Fragment
  | CustomFieldWithValue_CustomFieldWithValueCurrency_Fragment
  | CustomFieldWithValue_CustomFieldWithValueDate_Fragment
  | CustomFieldWithValue_CustomFieldWithValueDateTime_Fragment
  | CustomFieldWithValue_CustomFieldWithValueMultiSelect_Fragment
  | CustomFieldWithValue_CustomFieldWithValueNumber_Fragment
  | CustomFieldWithValue_CustomFieldWithValueSingleSelect_Fragment
  | CustomFieldWithValue_CustomFieldWithValueText_Fragment;

export type CustomFieldsWithInitialValuesQueryVariables = Exact<{
  entityType: CUSTOM_FIELD_ENTITY_TYPE;
}>;

export type CustomFieldsWithInitialValuesQuery = {
  __typename: 'Query';
  customFieldsWithInitialValues: Array<
    | {
        __typename: 'CustomFieldWithValueCheckbox';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueCheckbox: boolean;
      }
    | {
        __typename: 'CustomFieldWithValueCurrency';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueCurrency: number | null;
      }
    | {
        __typename: 'CustomFieldWithValueDate';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueDate: DateString | null;
      }
    | {
        __typename: 'CustomFieldWithValueDateTime';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueDateTime: DateTime | null;
      }
    | {
        __typename: 'CustomFieldWithValueMultiSelect';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueMultiSelect: Array<string>;
        options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
      }
    | {
        __typename: 'CustomFieldWithValueNumber';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueNumber: number | null;
      }
    | {
        __typename: 'CustomFieldWithValueSingleSelect';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueSingleSelect: string | null;
        options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
      }
    | {
        __typename: 'CustomFieldWithValueText';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueText: string | null;
      }
  > | null;
};

export type RequestCustomFieldsUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCustomFieldsUpsellMutation = {
  __typename: 'Mutation';
  requestCustomFieldsUpsell: boolean;
};

export type BidInviteIntroTemplatesQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  companyTemplateType: COMPANY_TEMPLATE_TYPE;
}>;

export type BidInviteIntroTemplatesQuery = {
  __typename: 'Query';
  bidInviteDefaultIntro: string;
  companyTemplates: {
    __typename: 'CompanyTemplatesResponse';
    companyTemplates: Array<{
      __typename: 'CompanyTemplate';
      id: string;
      name: string;
      content: string;
      isDefault: boolean;
    }>;
  };
};

export type DocumentsTable_documentsFragment = {
  __typename: 'Document';
  id: string;
  filename: string;
  size: number;
  updatedAt: DateTime;
  hasBeenDownloaded: boolean;
  user: {
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
  parsedIfcFile?: {
    __typename: 'ParsedIfcFile';
    id: string;
    autodeskProcessingState: PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE;
  } | null;
};

export type DocumentsTable_fileTreeFragment = {
  __typename: 'FileTree';
  id: string;
  fileTree: string;
};

export type FileTreeWithDocumentsQueryVariables = Exact<{
  attachable: AttachableInput;
  isAttachableProjectBim: Scalars['Boolean']['input'];
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type FileTreeWithDocumentsQuery = {
  __typename: 'Query';
  fileTreeWithDocuments: {
    __typename: 'FileTreeResponse';
    documents: Array<{
      __typename: 'Document';
      id: string;
      filename: string;
      size: number;
      updatedAt: DateTime;
      hasBeenDownloaded: boolean;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
      parsedIfcFile?: {
        __typename: 'ParsedIfcFile';
        id: string;
        autodeskProcessingState: PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE;
      } | null;
    }>;
    fileTree: { __typename: 'FileTree'; id: string; fileTree: string };
  };
};

export type CombinedFileTreeWithDocumentsQueryVariables = Exact<{
  attachables: Array<AttachableInput> | AttachableInput;
  isAttachableProjectBim: Scalars['Boolean']['input'];
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CombinedFileTreeWithDocumentsQuery = {
  __typename: 'Query';
  combinedFileTreeWithDocuments: {
    __typename: 'CombinedFileTreeResponse';
    documents: Array<{
      __typename: 'Document';
      id: string;
      filename: string;
      size: number;
      updatedAt: DateTime;
      hasBeenDownloaded: boolean;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
      parsedIfcFile?: {
        __typename: 'ParsedIfcFile';
        id: string;
        autodeskProcessingState: PARSED_IFC_FILE_AUTODESK_PROCESSING_STATE;
      } | null;
    }>;
    fileTrees: Array<{ __typename: 'FileTree'; id: string; fileTree: string }>;
  };
};

export type FileTreeAdditionsUploadDataQueryVariables = Exact<{ [key: string]: never }>;

export type FileTreeAdditionsUploadDataQuery = {
  __typename: 'Query';
  fileTreeAdditionsUploadData: {
    __typename: 'FileTreeAdditionsUploadDataResponse';
    id: string;
    uploadUrl: string;
  };
};

export type RenameFileTreeItemMutationVariables = Exact<{
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type RenameFileTreeItemMutation = { __typename: 'Mutation'; renameFileTreeItem: boolean };

export type DeleteFileTreeItemMutationVariables = Exact<{
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
}>;

export type DeleteFileTreeItemMutation = { __typename: 'Mutation'; deleteFileTreeItem: boolean };

export type MoveFileTreeItemMutationVariables = Exact<{
  sourceFileTreeId: Scalars['ID']['input'];
  targetFileTreeId: Scalars['ID']['input'];
  sourcePath: Scalars['String']['input'];
  targetPath: Scalars['String']['input'];
}>;

export type MoveFileTreeItemMutation = { __typename: 'Mutation'; moveFileTreeItem: boolean };

export type AddFilesToFileTreeMutationVariables = Exact<{
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  fileTreeAdditionsId: Scalars['ID']['input'];
}>;

export type AddFilesToFileTreeMutation = { __typename: 'Mutation'; addFilesToFileTree: boolean };

export type AddFolderToFileTreeMutationVariables = Exact<{
  fileTreeId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;

export type AddFolderToFileTreeMutation = { __typename: 'Mutation'; addFolderToFileTree: boolean };

export type MarkDocumentAsDownloadedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  subcontractorId: Scalars['ID']['input'];
}>;

export type MarkDocumentAsDownloadedMutation = {
  __typename: 'Mutation';
  markDocumentAsDownloaded: boolean;
};

export type DownloadUrlForProjectDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  orientation: ORIENTATION;
}>;

export type DownloadUrlForProjectDocumentsQuery = {
  __typename: 'Query';
  downloadUrlForProjectDocuments: string;
};

export type DownloadUrlForBidPackageDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  orientation: ORIENTATION;
}>;

export type DownloadUrlForBidPackageDocumentsQuery = {
  __typename: 'Query';
  downloadUrlForBidPackageDocuments: string;
};

export type DownloadUrlForBidRequestInNegotiationDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  orientation: ORIENTATION;
}>;

export type DownloadUrlForBidRequestInNegotiationDocumentsQuery = {
  __typename: 'Query';
  downloadUrlForBidRequestInNegotiationDocuments: string;
};

export type RequestProjectExportMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  boqType: BOQ_FILE_TYPE;
}>;

export type RequestProjectExportMutation = {
  __typename: 'Mutation';
  requestProjectExport: boolean;
};

export type ProjectsForProjectSelectQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  sortBy: PROJECTS_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
  includeClosed?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProjectsForProjectSelectQuery = {
  __typename: 'Query';
  projects: {
    __typename: 'ProjectsResponse';
    totalRecords: number;
    projects: Array<{
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      office: { __typename: 'Office'; id: string; name: string };
    }>;
  };
};

export type OriginalProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OriginalProjectQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    number: string | null;
    name: string;
    startsOn: string | null;
    endsOn: string | null;
    location: string;
    category: PROJECT_CATEGORY | null;
    office: { __typename: 'Office'; id: string; name: string; status: OFFICE_STATUS };
  };
  offices: Array<{ __typename: 'Office'; id: string; name: string; status: OFFICE_STATUS }>;
};

export type DuplicateProjectMutationVariables = Exact<{
  input: DuplicateProjectInput;
}>;

export type DuplicateProjectMutation = { __typename: 'Mutation'; duplicateProject: boolean };

export type TemplatePreviewQueryVariables = Exact<{
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['HTML']['input']>;
  salutationMode: EMAIL_SALUTATION_MODE;
  templateType: COMPANY_TEMPLATE_TYPE;
}>;

export type TemplatePreviewQuery = {
  __typename: 'Query';
  companyTemplatePreview: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type CreateEmailTemplateMutationVariables = Exact<{
  input: CompanyTemplateCreateInput;
}>;

export type CreateEmailTemplateMutation = {
  __typename: 'Mutation';
  createCompanyTemplate: { __typename: 'CompanyTemplate'; id: string };
};

export type InvoicesQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<INVOICE_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
  requiresApprovalByCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type InvoicesQuery = {
  __typename: 'Query';
  invoices: {
    __typename: 'InvoicesResponse';
    totalRecords: number;
    invoices: Array<{
      __typename: 'Invoice';
      approvedTotalNet: number;
      cashDiscountValue: number;
      discountValue: number;
      id: string;
      insuranceValue: number;
      name: string | null;
      numberExternal: string | null;
      numberInternal: string | null;
      partialInvoiceNumber: number | null;
      retentionValue: number;
      status: INVOICE_STATUS;
      taxRate: number;
      customRows: Array<{
        __typename: 'InvoiceCustomRow';
        id: string;
        position: number;
        title: string;
        isNegative: boolean;
        type: INVOICE_CUSTOM_ROW_TYPE;
        rate: number;
        absoluteValue: number;
      }>;
      assignee: {
        __typename: 'InvoiceAssignee';
        currentStep: number | null;
        totalStepsCount: number;
        userFirstName: string | null;
        userId: string;
        userLastName: string | null;
      } | null;
      bidPackage: {
        __typename: 'BidPackage';
        currency: CURRENCY;
        id: string;
        name: string;
        number: string | null;
        project: { __typename: 'Project'; id: string; name: string; number: string | null };
      };
    }>;
  };
};

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteInvoiceMutation = { __typename: 'Mutation'; deleteInvoice: boolean };

export type CreateMessageThreadMutationVariables = Exact<{
  authorType: COMPANY_TYPE;
  bidRequestIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  messageText: Scalars['HTML']['input'];
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CreateMessageThreadMutation = {
  __typename: 'Mutation';
  createMessageThread: { __typename: 'MessageThread'; id: string };
};

export type MessageThreadInMessagesQueryVariables = Exact<{
  messageThreadId: Scalars['ID']['input'];
}>;

export type MessageThreadInMessagesQuery = {
  __typename: 'Query';
  messageThread: {
    __typename: 'MessageThread';
    id: string;
    subject: string;
    hasUnreadMessages: boolean;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        status: PROJECT_STATUS;
      };
    };
    bidRequest: { __typename: 'BidRequest'; id: string };
    messages: Array<{
      __typename: 'Message';
      id: string;
      createdAt: DateTime;
      authorType: COMPANY_TYPE;
      authorName: string | null;
      isMyMessage: boolean;
      authorCompanyName: string | null;
      messageText: string;
      hasBeenRead: boolean;
      recipients: Array<{ __typename: 'MessageRecipient'; name: string; hasReadMessage: boolean }>;
      documents: Array<{ __typename: 'Document'; id: string; filename: string }>;
    }>;
  };
};

export type ReplyToMessageThreadMutationVariables = Exact<{
  authorType: COMPANY_TYPE;
  messageThreadId: Scalars['ID']['input'];
  messageText: Scalars['HTML']['input'];
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ReplyToMessageThreadMutation = {
  __typename: 'Mutation';
  replyToMessageThread: { __typename: 'Message'; id: string };
};

export type ThreadsList_messageThreadFragment = {
  __typename: 'MessageThread';
  id: string;
  subject: string;
  hasUnreadMessages: boolean;
  respondingCompanyName: string | null;
  participatingCompanyOffice: { __typename: 'Office'; id: string; name: string };
  lastMessage: {
    __typename: 'Message';
    id: string;
    createdAt: DateTime;
    messageText: string;
    recipients: Array<{ __typename: 'MessageRecipient'; name: string }>;
  };
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    project: { __typename: 'Project'; id: string; name: string; number: string | null };
  };
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    type: BID_REQUEST_TYPE;
    status: BID_REQUEST_STATUS;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        customer: string | null;
        status: PROJECT_STATUS;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          company: { __typename: 'Company'; id: string; name: string };
        };
      };
    };
    subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
  };
};

export type Messages_messageThreadFragment = {
  __typename: 'MessageThread';
  id: string;
  subject: string;
  hasUnreadMessages: boolean;
  respondingCompanyName: string | null;
  participatingCompanyOffice: { __typename: 'Office'; id: string; name: string };
  lastMessage: {
    __typename: 'Message';
    id: string;
    createdAt: DateTime;
    messageText: string;
    recipients: Array<{ __typename: 'MessageRecipient'; name: string }>;
  };
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    name: string;
    number: string | null;
    project: { __typename: 'Project'; id: string; name: string; number: string | null };
  };
  bidRequest: {
    __typename: 'BidRequest';
    id: string;
    type: BID_REQUEST_TYPE;
    status: BID_REQUEST_STATUS;
    bidPackage: {
      __typename: 'BidPackage';
      id: string;
      name: string;
      number: string | null;
      project: {
        __typename: 'Project';
        id: string;
        name: string;
        number: string | null;
        customer: string | null;
        status: PROJECT_STATUS;
        office: {
          __typename: 'Office';
          id: string;
          name: string;
          company: { __typename: 'Company'; id: string; name: string };
        };
      };
    };
    subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
  };
};

export type MarkThreadsAsReadMutationVariables = Exact<{
  messageThreadIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type MarkThreadsAsReadMutation = { __typename: 'Mutation'; markThreadsAsRead: boolean };

export type ProjectUsersForMessageQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectUsersForMessageQuery = {
  __typename: 'Query';
  project: {
    __typename: 'Project';
    id: string;
    projectUsers: Array<{
      __typename: 'ProjectUser';
      id: string;
      user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
    }>;
  };
};

export type CompanyUsersForMessageQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyUsersForMessageQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UsersResponse';
    users: Array<{
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    }>;
  };
};

export type MessageTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type MessageTemplatesQuery = {
  __typename: 'Query';
  companyTemplates: {
    __typename: 'CompanyTemplatesResponse';
    companyTemplates: Array<{
      __typename: 'CompanyTemplate';
      id: string;
      name: string;
      content: string;
    }>;
  };
};

export type VerifyMfaPhoneNumberMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  loginToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type VerifyMfaPhoneNumberMutation = {
  __typename: 'Mutation';
  verifyMfaPhoneNumber: boolean;
};

export type StoreMfaPhoneNumberMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  code: Scalars['String']['input'];
  loginToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type StoreMfaPhoneNumberMutation = {
  __typename: 'Mutation';
  storeMfaPhoneNumber: { __typename: 'CheckVerificationCodeResponse'; mfaToken: string | null };
};

export type GenerateAuthenticatorSecretMutationVariables = Exact<{
  loginToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GenerateAuthenticatorSecretMutation = {
  __typename: 'Mutation';
  generateAuthenticatorSecret: { __typename: 'AuthenticatorData'; secret: string; uri: string };
};

export type StoreAuthenticatorSecretMutationVariables = Exact<{
  secret: Scalars['String']['input'];
  code: Scalars['String']['input'];
  loginToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type StoreAuthenticatorSecretMutation = {
  __typename: 'Mutation';
  storeAuthenticatorSecret: {
    __typename: 'CheckVerificationCodeResponse';
    mfaToken: string | null;
  };
};

export type CreateMultiUserApprovalRequestMutationVariables = Exact<{
  entityId: Scalars['ID']['input'];
  requestProps?: InputMaybe<MultiUserApprovalRequestPropsInput>;
  ruleId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CreateMultiUserApprovalRequestMutation = {
  __typename: 'Mutation';
  createMultiUserApprovalRequest: boolean;
};

export type RejectMultiUserApprovalRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
}>;

export type RejectMultiUserApprovalRequestMutation = {
  __typename: 'Mutation';
  rejectMultiUserApprovalRequest: boolean;
};

export type WithdrawMultiUserApprovalRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type WithdrawMultiUserApprovalRequestMutation = {
  __typename: 'Mutation';
  withdrawMultiUserApprovalRequest: boolean;
};

export type ApproveMultiUserApprovalRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApproveMultiUserApprovalRequestMutation = {
  __typename: 'Mutation';
  approveMultiUserApprovalRequest: boolean;
};

type Notes_note_BidRequestNote_Fragment = {
  __typename: 'BidRequestNote';
  id: string;
  createdAt: DateTime;
  text: string;
  user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
};

type Notes_note_InvoiceNote_Fragment = {
  __typename: 'InvoiceNote';
  id: string;
  createdAt: DateTime;
  text: string;
  user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
};

type Notes_note_SubcontractorNote_Fragment = {
  __typename: 'SubcontractorNote';
  id: string;
  createdAt: DateTime;
  text: string;
  user: {
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
};

type Notes_note_TaskNote_Fragment = {
  __typename: 'TaskNote';
  id: string;
  createdAt: DateTime;
  text: string;
  user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
};

export type Notes_noteFragment =
  | Notes_note_BidRequestNote_Fragment
  | Notes_note_InvoiceNote_Fragment
  | Notes_note_SubcontractorNote_Fragment
  | Notes_note_TaskNote_Fragment;

export type BidPackageChangedDefaultTemplateQueryVariables = Exact<{
  bidPackageName?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
}>;

export type BidPackageChangedDefaultTemplateQuery = {
  __typename: 'Query';
  bidPackageChangedDefaultTemplate: string;
};

export type MarketplaceSuggestionsAfterBidSubmitQueryVariables = Exact<{ [key: string]: never }>;

export type MarketplaceSuggestionsAfterBidSubmitQuery = {
  __typename: 'Query';
  marketplaceBidPackageSuggestions: Array<{
    __typename: 'MarketplaceBidPackageSample';
    id: string;
    name: string;
    projectName: string;
    officeName: string;
    officeLogoKey: string | null;
    customerName: string | null;
  }>;
};

export type BidInvitePreviewQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  contactId?: InputMaybe<Scalars['String']['input']>;
  customInvitationEmailIntro?: InputMaybe<Scalars['HTML']['input']>;
  customBidsDueAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
}>;

export type BidInvitePreviewQuery = {
  __typename: 'Query';
  bidInvitePreview: {
    __typename: 'TemplatePreview';
    content: string;
    fromEmail: string;
    fromName: string;
    subject: string;
  };
};

export type UpdateBoQInternalNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  text: Scalars['String']['input'];
}>;

export type UpdateBoQInternalNoteMutation = {
  __typename: 'Mutation';
  updateBillOfQuantitiesInternalNote: boolean;
};

export type GetDownloadUrlForSpreadsheetQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  hiddenComparisonColumns: Array<BOQ_COMPARISON_MODE> | BOQ_COMPARISON_MODE;
  bidColumnConfigs: Array<BidColumnConfig> | BidColumnConfig;
  hiddenElementIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  hiddenBidders?: InputMaybe<Array<HiddenBidder> | HiddenBidder>;
  alternativePositionSelections: Array<AlternativePositionSelection> | AlternativePositionSelection;
  revision?: InputMaybe<Scalars['Int']['input']>;
  includeOptions: PriceComparisonSpreadsheetIncludeOptions;
  highlightMode?: InputMaybe<HighlightPriceMode>;
  outlierSettings?: InputMaybe<PriceComparisonOutlierSettings>;
}>;

export type GetDownloadUrlForSpreadsheetQuery = {
  __typename: 'Query';
  downloadUrlForPriceComparisonSpreadsheet: string;
};

export type GetDownloadUrlForPdfQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  hiddenComparisonColumns: Array<BOQ_COMPARISON_MODE> | BOQ_COMPARISON_MODE;
  bidColumnConfigs: Array<BidColumnConfig> | BidColumnConfig;
  hiddenElementIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  hiddenBidders?: InputMaybe<Array<HiddenBidder> | HiddenBidder>;
  alternativePositionSelections: Array<AlternativePositionSelection> | AlternativePositionSelection;
  revision?: InputMaybe<Scalars['Int']['input']>;
  printOptions?: InputMaybe<PrintOptions>;
  pdfSettings?: InputMaybe<PCTPdfSettings>;
  highlightMode: HighlightPriceMode;
  outlierSettings?: InputMaybe<PriceComparisonOutlierSettings>;
}>;

export type GetDownloadUrlForPdfQuery = {
  __typename: 'Query';
  downloadUrlForPriceComparisonPdf: string;
};

export type ConfirmCustomBudgetBoQHasBeenUploadedMutationVariables = Exact<{
  filename: Scalars['String']['input'];
  bidPackageId: Scalars['ID']['input'];
  uploadKey: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ConfirmCustomBudgetBoQHasBeenUploadedMutation = {
  __typename: 'Mutation';
  confirmCustomBudgetBillOfQuantitiesHasBeenUploaded:
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedError';
        errors: Array<{
          __typename: 'BoqValidationError';
          location: string | null;
          reason: BOQ_VALIDATION_ERROR_REASON;
          rowNumber: number | null;
        }>;
      }
    | {
        __typename: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess';
        billOfQuantitiesId: string;
        warnings: Array<{
          __typename: 'BoqValidationWarning';
          location: string | null;
          reason: BOQ_VALIDATION_WARNING_REASON;
          rowNumber: number | null;
        }>;
      };
};

export type BidPackageForPriceComparisonQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  bidColumnConfigs: Array<BidColumnConfig> | BidColumnConfig;
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type BidPackageForPriceComparisonQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    status: BID_PACKAGE_STATUS;
    currency: CURRENCY;
    budget: number | null;
    isBlindTender: boolean;
    blindTenderBidsOpenedAt: DateTime | null;
    hasBiddersWithSubmittedBids: boolean;
    project: {
      __typename: 'Project';
      id: string;
      preferredBoQFileFormat: BOQ_FILE_FORMAT;
      defaultTaxRate: number | null;
    };
    billOfQuantitiesRevision: {
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
      requestForProposalBoQ: {
        __typename: 'BillOfQuantities';
        id: string;
        content: JSONObject;
        internalNotes: Array<{
          __typename: 'BillOfQuantitiesInternalNote';
          id: string;
          path: string;
          text: string;
        }>;
      } | null;
    } | null;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    }>;
    submittableBidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    }>;
    allBidColumnsForPriceComparison: Array<{
      __typename: 'BidColumnResponse';
      availableBids: Array<{
        __typename: 'Bid';
        id: string;
        version: number | null;
        isSimulated: boolean | null;
        isEligibleForAwarding: boolean;
        totalNet: number;
        skontoRate: number | null;
        skontoDeadline: number | null;
        discountRate: number | null;
        absoluteDiscount: number | null;
        taxRate: number | null;
        createdAt: DateTime;
        parentBid: {
          __typename: 'Bid';
          id: string;
          version: number | null;
          billOfQuantitiesRevision: {
            __typename: 'BillOfQuantitiesRevision';
            id: string;
            revision: number;
          };
        } | null;
      }>;
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        currentBidId: string | null;
        status: BID_REQUEST_STATUS;
        subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
      };
    }>;
    filteredBidColumnsForPriceComparison: Array<{
      __typename: 'BidColumnResponse';
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        status: BID_REQUEST_STATUS;
        currentBidId: string | null;
        awardedBid: { __typename: 'AwardedBid'; id: string } | null;
        bidsEligibleForAwarding: Array<{ __typename: 'SubmittedBid'; id: string }>;
        subcontractor: {
          __typename: 'Subcontractor';
          city: string | null;
          countryCode: COUNTRY_CODE | null;
          postalCode: string | null;
          street: string | null;
          website: string | null;
          id: string;
          name: string;
        };
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          currency: CURRENCY;
          isLocked: boolean;
          name: string;
          status: BID_PACKAGE_STATUS;
          project: { __typename: 'Project'; id: string; name: string; status: PROJECT_STATUS };
        };
      };
      bid: {
        __typename: 'SubmittedOrGeneratedSubmittedBid';
        totalNet: number;
        skontoRate: number;
        skontoDeadline: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        taxRate: number;
        status: BID_STATUS;
        submittedAt: DateTime | null;
        isEligibleForAwarding: boolean;
        id: string;
        version: number | null;
        isSimulated: boolean | null;
        parentBid: {
          __typename: 'Bid';
          id: string;
          version: number | null;
          billOfQuantitiesRevision: {
            __typename: 'BillOfQuantitiesRevision';
            id: string;
            revision: number;
          };
        } | null;
      };
      billOfQuantities: {
        __typename: 'BillOfQuantities';
        id: string;
        content: JSONObject;
        editingStatus: BOQ_EDITING_STATUS | null;
        comments: Array<{
          __typename: 'BillOfQuantitiesComment';
          id: string;
          path: string;
          text: string;
        }>;
        internalNotes: Array<{
          __typename: 'BillOfQuantitiesInternalNote';
          id: string;
          path: string;
          text: string;
        }>;
      } | null;
    }>;
    customBudgetBoQ: { __typename: 'BillOfQuantities'; id: string; content: JSONObject } | null;
  };
};

export type GetSimulationChangesQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSimulationChangesQuery = {
  __typename: 'Query';
  simulationChanges: {
    __typename: 'SimulationChanges';
    boqQuantityChanges: Array<{
      __typename: 'SimulationQuantityChange';
      path: string;
      value: number;
    }>;
    customBudgetBoQUnitPriceChanges: Array<{
      __typename: 'SimulationNullableChange';
      path: string;
      value: number | null;
    }>;
    customBudgetBoQFreeQuantityChanges: Array<{
      __typename: 'SimulationNullableChange';
      path: string;
      value: number | null;
    }>;
    bids: Array<{
      __typename: 'SimulationBidChanges';
      bidId: string;
      boqUnitPriceChanges: Array<{
        __typename: 'SimulationNullableChange';
        path: string;
        value: number | null;
      }>;
      freeQuantityChanges: Array<{
        __typename: 'SimulationNullableChange';
        path: string;
        value: number | null;
      }>;
    }>;
  };
};

export type SavePriceComparisonSimulationMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  editingSessionId?: InputMaybe<Scalars['ID']['input']>;
  saveOptions: SaveSimulationOptionsInput;
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SavePriceComparisonSimulationMutation = {
  __typename: 'Mutation';
  savePriceComparisonSimulation:
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      }
    | { __typename: 'SavePriceComparisonSuccess' };
};

export type StoreSimulationChangeMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  editingSessionId?: InputMaybe<Scalars['ID']['input']>;
  input: SimulationChangeInput;
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type StoreSimulationChangeMutation = {
  __typename: 'Mutation';
  storeSimulationChange:
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      }
    | { __typename: 'StoreSimulationChangesSuccess' };
};

export type DiscardSimulationChangesMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  editingSessionId?: InputMaybe<Scalars['ID']['input']>;
  revision?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscardSimulationChangesMutation = {
  __typename: 'Mutation';
  discardSimulationChanges:
    | { __typename: 'DiscardSimulationChangesSuccess' }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type GetBidDownloadUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  pdfSettings?: InputMaybe<BidPdfSettings>;
}>;

export type GetBidDownloadUrlQuery = { __typename: 'Query'; downloadUrlForBidPdf: string };

export type FinancesProjectsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query: Scalars['String']['input'];
  sortBy: PROJECTS_SORT_FIELD;
  sortDirection: SORT_DIRECTION;
}>;

export type FinancesProjectsQuery = {
  __typename: 'Query';
  projects: {
    __typename: 'ProjectsResponse';
    totalRecords: number;
    projects: Array<{
      __typename: 'Project';
      id: string;
      name: string;
      number: string | null;
      office: { __typename: 'Office'; id: string; name: string };
    }>;
  };
};

export type FinancesSelectedProjectsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type FinancesSelectedProjectsQuery = {
  __typename: 'Query';
  projectsByIds: Array<{
    __typename: 'Project';
    id: string;
    name: string;
    number: string | null;
    office: { __typename: 'Office'; id: string; name: string };
  }>;
};

export type BidForSubcontractorBidColumnHeaderFragment = {
  __typename: 'SubmittedOrGeneratedSubmittedBid';
  id: string;
  version: number | null;
  isSimulated: boolean | null;
  parentBid: {
    __typename: 'Bid';
    id: string;
    version: number | null;
    billOfQuantitiesRevision: {
      __typename: 'BillOfQuantitiesRevision';
      id: string;
      revision: number;
    };
  } | null;
};

export type SubcontractorForSubcontractorBidColumnHeaderFragment = {
  __typename: 'Subcontractor';
  id: string;
  name: string;
};

export type SubmitBiddersSearchRequestMutationVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
  input: BiddersSearchInput;
}>;

export type SubmitBiddersSearchRequestMutation = {
  __typename: 'Mutation';
  submitBiddersSearchRequest: boolean;
};

export type NetworkSubcontractorsForSignupQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type NetworkSubcontractorsForSignupQuery = {
  __typename: 'Query';
  networkSubcontractors: {
    __typename: 'NetworkSubcontractorsResponse';
    subcontractors: Array<{
      __typename: 'NetworkSubcontractor';
      id: string;
      name: string;
      postalCode: string | null;
      city: string | null;
    }>;
  };
};

export type AgentSubcontractorForm_subcontractorFragment = {
  __typename: 'Subcontractor';
  id: string;
  logoKey: string | null;
  name: string;
  aboutUs: string | null;
  imageKeys: Array<string>;
  street: string | null;
  postalCode: string | null;
  city: string | null;
  countryCode: COUNTRY_CODE | null;
  website: string | null;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius: ORDER_RADIUS | null;
  orderAreaStateCodes: Array<STATE_CODE> | null;
  orderAreaCountryCode: COUNTRY_CODE | null;
  companySize: COMPANY_SIZE | null;
  confirmedByAgent: boolean;
  lat: number | null;
  long: number | null;
  nationalId?: string | null;
  registrationPostalCode?: string | null;
  registrationCity?: string | null;
  foundingYear?: number | null;
  ownerName?: string | null;
  revenueYear?: number | null;
  taxId?: string | null;
  yearlyRevenue?: number | null;
  hasSustainableConstruction: boolean | null;
  sustainableConstructionDetails: string | null;
  referenceProjects: Array<{
    __typename: 'SubcontractorReferenceProject';
    id: string;
    name: string;
    description: string;
    imageKeys: Array<string>;
    position: number;
  }>;
  workCategories: Array<{
    __typename: 'WorkCategoryForSubcontractor';
    id: string;
    name: string;
    isPrimary: boolean;
  }>;
  contacts: Array<{
    __typename: 'Contact';
    id: string;
    isPerson: boolean;
    title: CONTACT_TITLE | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    jobTitle: string | null;
    phone: string | null;
    mobilePhone: string | null;
    fax: string | null;
    isPrivate: boolean;
  }>;
};

export type CreateNetworkSubcontractorByAgentUserMutationVariables = Exact<{
  input: SubcontractorInput;
}>;

export type CreateNetworkSubcontractorByAgentUserMutation = {
  __typename: 'Mutation';
  createNetworkSubcontractorByAgentUser: {
    __typename: 'Subcontractor';
    id: string;
    street: string | null;
    countryCode: COUNTRY_CODE | null;
    postalCode: string | null;
    city: string | null;
  };
};

export type UpdateNetworkSubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SubcontractorInput;
}>;

export type UpdateNetworkSubcontractorMutation = {
  __typename: 'Mutation';
  updateNetworkSubcontractorByAgentUser: {
    __typename: 'Subcontractor';
    id: string;
    street: string | null;
    countryCode: COUNTRY_CODE | null;
    postalCode: string | null;
    city: string | null;
  };
};

export type AgentCompanyProfilePrefillQueryVariables = Exact<{ [key: string]: never }>;

export type AgentCompanyProfilePrefillQuery = {
  __typename: 'Query';
  agentCompanyProfilePrefill: {
    __typename: 'AgentCompanyProfilePrefillResponse';
    city: string | null;
    companySize: COMPANY_SIZE | null;
    countryCode: COUNTRY_CODE | null;
    logoKey: string | null;
    name: string | null;
    aboutUs: string | null;
    imageKeys: Array<string>;
    orderAreaType: ORDER_AREA_TYPE | null;
    orderRadius: ORDER_RADIUS | null;
    orderAreaStateCodes: Array<STATE_CODE> | null;
    orderAreaCountryCode: COUNTRY_CODE | null;
    postalCode: string | null;
    street: string | null;
    website: string | null;
    contacts: Array<{
      __typename: 'AgentCompanyProfilePrefillContact';
      email: string | null;
      fax: string | null;
      firstName: string | null;
      isPerson: boolean;
      jobTitle: string | null;
      lastName: string | null;
      mobilePhone: string | null;
      phone: string | null;
      title: CONTACT_TITLE | null;
    }>;
    workCategories: Array<{
      __typename: 'AgentCompanyProfilePrefillWorkCategory';
      id: string;
      name: string;
    }>;
  };
};

export type PrincipalSubcontractorForm_subcontractorFragment = {
  __typename: 'Subcontractor';
  id: string;
  name: string;
  logoKey: string | null;
  street: string | null;
  city: string | null;
  postalCode: string | null;
  countryCode: COUNTRY_CODE | null;
  website: string | null;
  orderAreaType: ORDER_AREA_TYPE;
  orderRadius: ORDER_RADIUS | null;
  orderAreaStateCodes: Array<STATE_CODE> | null;
  orderAreaCountryCode: COUNTRY_CODE | null;
  companySize: COMPANY_SIZE | null;
  rating: number | null;
  isBanned: boolean;
  isFavorite: boolean;
  type: SUBCONTRACTOR_TYPE;
  moreInfo: string | null;
  nationalId: string | null;
  registrationPostalCode: string | null;
  registrationCity: string | null;
  foundingYear: number | null;
  ownerName: string | null;
  revenueYear: number | null;
  taxId: string | null;
  yearlyRevenue: number | null;
  description: string | null;
  generatedDescription: string | null;
  pqNumber: string | null;
  aboutUs: string | null;
  imageKeys: Array<string>;
  isDeleted: boolean;
  bidRequestsAwaitingResponseCount: number;
  workCategories: Array<{
    __typename: 'WorkCategoryForSubcontractor';
    id: string;
    isPrimary: boolean;
    name: string;
    type: WORK_CATEGORY_TYPE;
  }>;
  referenceProjects: Array<{
    __typename: 'SubcontractorReferenceProject';
    id: string;
    imageKeys: Array<string>;
    name: string;
  }>;
  customFields: Array<
    | {
        __typename: 'CustomFieldWithValueCheckbox';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueCheckbox: boolean;
      }
    | {
        __typename: 'CustomFieldWithValueCurrency';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueCurrency: number | null;
      }
    | {
        __typename: 'CustomFieldWithValueDate';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueDate: DateString | null;
      }
    | {
        __typename: 'CustomFieldWithValueDateTime';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueDateTime: DateTime | null;
      }
    | {
        __typename: 'CustomFieldWithValueMultiSelect';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueMultiSelect: Array<string>;
        options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
      }
    | {
        __typename: 'CustomFieldWithValueNumber';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueNumber: number | null;
      }
    | {
        __typename: 'CustomFieldWithValueSingleSelect';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueSingleSelect: string | null;
        options: Array<{ __typename: 'CustomFieldWithValueOption'; id: string; label: string }>;
      }
    | {
        __typename: 'CustomFieldWithValueText';
        id: string;
        isRequired: boolean;
        label: string;
        entityId: string | null;
        valueText: string | null;
      }
  > | null;
  contacts: Array<{
    __typename: 'Contact';
    id: string;
    isPerson: boolean;
    title: CONTACT_TITLE | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    jobTitle: string | null;
    phone: string | null;
    mobilePhone: string | null;
    fax: string | null;
    isPrivate: boolean;
  }>;
};

export type UpdateCompanySubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SubcontractorInput;
}>;

export type UpdateCompanySubcontractorMutation = {
  __typename: 'Mutation';
  updateCompanySubcontractor: { __typename: 'Subcontractor'; id: string };
};

export type SuggestChangesToNetworkSubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SubcontractorSuggestionInput;
}>;

export type SuggestChangesToNetworkSubcontractorMutation = {
  __typename: 'Mutation';
  suggestChangesToNetworkSubcontractor: boolean;
};

export type SuggestChangesToNetworkSubcontractorContactsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  contacts: Array<ContactInput> | ContactInput;
  suggestionComment: Scalars['String']['input'];
}>;

export type SuggestChangesToNetworkSubcontractorContactsMutation = {
  __typename: 'Mutation';
  suggestChangesToNetworkSubcontractorContacts: boolean;
};

export type UpdateNetworkSubcontractorPrivateContactsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  contacts: Array<ContactInput> | ContactInput;
}>;

export type UpdateNetworkSubcontractorPrivateContactsMutation = {
  __typename: 'Mutation';
  updateSubcontractorPrivateContacts: { __typename: 'Subcontractor'; id: string };
};

export type CreateCompanySubcontractorMutationVariables = Exact<{
  input: SubcontractorInput;
}>;

export type CreateCompanySubcontractorMutation = {
  __typename: 'Mutation';
  createCompanySubcontractor: { __typename: 'Subcontractor'; id: string; name: string };
};

export type UpdateSubcontractorIsFavoriteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isFavorite: Scalars['Boolean']['input'];
}>;

export type UpdateSubcontractorIsFavoriteMutation = {
  __typename: 'Mutation';
  updateSubcontractorIsFavorite: { __typename: 'Subcontractor'; id: string; isFavorite: boolean };
};

export type UpdateSubcontractorIsBannedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isBanned: Scalars['Boolean']['input'];
}>;

export type UpdateSubcontractorIsBannedMutation = {
  __typename: 'Mutation';
  updateSubcontractorIsBanned: { __typename: 'Subcontractor'; id: string; isBanned: boolean };
};

export type UpdateSubcontractorCustomFieldsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  customFields: Array<CustomFieldValueInput> | CustomFieldValueInput;
}>;

export type UpdateSubcontractorCustomFieldsMutation = {
  __typename: 'Mutation';
  updateSubcontractorCustomFields: boolean;
};

export type ContactsForSubcontractorFormFragment = {
  __typename: 'Subcontractor';
  id: string;
  contacts: Array<{
    __typename: 'Contact';
    id: string;
    isPerson: boolean;
    title: CONTACT_TITLE | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    jobTitle: string | null;
    phone: string | null;
    mobilePhone: string | null;
    fax: string | null;
    isPrivate: boolean;
  }>;
};

export type RequestCertificatesUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type RequestCertificatesUpsellMutation = {
  __typename: 'Mutation';
  requestCertificatesUpsell: boolean;
};

export type InfoForCertificateRequestQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
}>;

export type InfoForCertificateRequestQuery = {
  __typename: 'Query';
  offices: Array<{ __typename: 'Office'; id: string; name: string; status: OFFICE_STATUS }>;
  subcontractor: {
    __typename: 'Subcontractor';
    id: string;
    contacts: Array<{ __typename: 'Contact'; id: string; email: string | null }>;
  };
  lastCertificateRequest: {
    __typename: 'CertificateRequest';
    id: string;
    officeId: string;
    contactId: string;
  } | null;
};

export type SendCertificateRequestMutationVariables = Exact<{
  certificateTypeIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  dueDate?: InputMaybe<Scalars['String']['input']>;
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SendCertificateRequestMutation = {
  __typename: 'Mutation';
  sendCertificateRequest: boolean;
};

export type CertificatesQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
}>;

export type CertificatesQuery = {
  __typename: 'Query';
  combinedCertificateTypesReport: Array<{
    __typename: 'CertificateTypeReport';
    isSelectedByDefaultWhenRequesting: boolean;
    isInUse: boolean;
    id: string;
    type: CERTIFICATE_TYPE_TYPE;
    scope: CERTIFICATE_TYPE_SCOPE;
    name: string;
    isExpiryDateMandatory: boolean;
    subcontractorTypeId: string | null;
    status: CERTIFICATE_TYPE_STATUS;
    maxExpiryDate: DateTime | null;
    requestedOn: DateTime | null;
    requestedDueDate: DateTime | null;
    rejectionReason: string | null;
    templateDocument: { __typename: 'Document'; id: string; filename: string; size: number } | null;
    certificates: Array<{
      __typename: 'Certificate';
      id: string;
      issueDate: string;
      expiryDate: string | null;
      createdAt: DateTime;
      fileName: string;
      fileSizeBytes: number;
      availability: CERTIFICATE_AVAILABILITY;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        project: { __typename: 'Project'; id: string; name: string };
      } | null;
    }>;
    pendingCertificateRequestItemsForBidPackages: Array<{
      __typename: 'CertificateRequestItem';
      id: string;
      createdAt: DateTime;
      rejectionReason: string | null;
      certificateRequest: {
        __typename: 'CertificateRequest';
        dueDate: string | null;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          project: { __typename: 'Project'; id: string; name: string };
        } | null;
      };
    }>;
  }>;
};

export type MessageThreadsForSubcontractorQueryVariables = Exact<{
  showMessageThreadsFromAllProjects?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
  subcontractorId: Scalars['ID']['input'];
}>;

export type MessageThreadsForSubcontractorQuery = {
  __typename: 'Query';
  messageThreadsForSubcontractor: {
    __typename: 'MessageThreadResponse';
    totalRecords: number;
    messageThreads: Array<{
      __typename: 'MessageThread';
      id: string;
      subject: string;
      hasUnreadMessages: boolean;
      respondingCompanyName: string | null;
      participatingCompanyOffice: { __typename: 'Office'; id: string; name: string };
      lastMessage: {
        __typename: 'Message';
        id: string;
        createdAt: DateTime;
        messageText: string;
        recipients: Array<{ __typename: 'MessageRecipient'; name: string }>;
      };
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        number: string | null;
        project: { __typename: 'Project'; id: string; name: string; number: string | null };
      };
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        type: BID_REQUEST_TYPE;
        status: BID_REQUEST_STATUS;
        bidPackage: {
          __typename: 'BidPackage';
          id: string;
          name: string;
          number: string | null;
          project: {
            __typename: 'Project';
            id: string;
            name: string;
            number: string | null;
            customer: string | null;
            status: PROJECT_STATUS;
            office: {
              __typename: 'Office';
              id: string;
              name: string;
              company: { __typename: 'Company'; id: string; name: string };
            };
          };
        };
        subcontractor: { __typename: 'Subcontractor'; id: string; type: SUBCONTRACTOR_TYPE };
      };
    }>;
  };
};

export type BidRequestsForSubcontractorQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
  bidPackageId: Scalars['ID']['input'];
}>;

export type BidRequestsForSubcontractorQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
    }>;
  };
};

export type BidPackagesForSubcontractorQueryVariables = Exact<{
  statuses?: InputMaybe<Array<BID_PACKAGE_STATUS> | BID_PACKAGE_STATUS>;
  excludeClosedProjects?: InputMaybe<Scalars['Boolean']['input']>;
  subcontractorId: Scalars['ID']['input'];
}>;

export type BidPackagesForSubcontractorQuery = {
  __typename: 'Query';
  bidPackages: { __typename: 'BidPackagesResponse'; totalRecords: number };
};

export type SubcontractorRatingOverviewQueryVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
}>;

export type SubcontractorRatingOverviewQuery = {
  __typename: 'Query';
  subcontractorRatingSummary: {
    __typename: 'SubcontractorRatingSummaryResponse';
    ratingByCategory: Array<{
      __typename: 'CategoryRating';
      category: RATING_CATEGORY;
      rating: number;
    }>;
  };
  subcontractorRatingPerBidRequest: Array<{
    __typename: 'SubcontractorRatingPerBidRequest';
    bidPackageId: string;
    bidPackageName: string;
    projectId: string;
    projectName: string;
    rating: number;
    userRatedCategories: Array<RATING_CATEGORY>;
    ratingCategories: Array<RATING_CATEGORY> | null;
    subcontractor: { __typename: 'Subcontractor'; id: string; name: string; isDeleted: boolean };
    ratingByCategory: Array<{
      __typename: 'CategoryRating';
      category: RATING_CATEGORY;
      rating: number;
    }>;
    bidRequest: { __typename: 'BidRequest'; id: string; status: BID_REQUEST_STATUS };
  }>;
  company: { __typename: 'Company'; id: string; ratingCategories: Array<RATING_CATEGORY> };
};

export type SubcontractorForProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubcontractorForProfileQuery = {
  __typename: 'Query';
  subcontractorForProfile:
    | { __typename: 'MergedIntoSubcontractor'; id: string }
    | {
        __typename: 'Subcontractor';
        id: string;
        name: string;
        isBanned: boolean;
        isFavorite: boolean;
        bidRequestsCount: number | null;
        unreadMessageThreadsCount: number | null;
        notesCount: number | null;
        description: string | null;
        generatedDescription: string | null;
        nationalId: string | null;
        foundingYear: number | null;
        ownerName: string | null;
        revenueYear: number | null;
        taxId: string | null;
        yearlyRevenue: number | null;
        bidRequestsAwaitingResponseCount: number;
        logoKey: string | null;
        street: string | null;
        city: string | null;
        postalCode: string | null;
        countryCode: COUNTRY_CODE | null;
        website: string | null;
        orderAreaType: ORDER_AREA_TYPE;
        orderRadius: ORDER_RADIUS | null;
        orderAreaStateCodes: Array<STATE_CODE> | null;
        orderAreaCountryCode: COUNTRY_CODE | null;
        companySize: COMPANY_SIZE | null;
        rating: number | null;
        type: SUBCONTRACTOR_TYPE;
        moreInfo: string | null;
        registrationPostalCode: string | null;
        registrationCity: string | null;
        pqNumber: string | null;
        aboutUs: string | null;
        imageKeys: Array<string>;
        isDeleted: boolean;
        pendingBidRequestsCount: number | null;
        submittedBidRequestsCount: number | null;
        awardedBidRequestsCount: number | null;
        declinedBidRequestsCount: number | null;
        rejectedBidRequestsCount: number | null;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          isPrimary: boolean;
          name: string;
          type: WORK_CATEGORY_TYPE;
        }>;
        referenceProjects: Array<{
          __typename: 'SubcontractorReferenceProject';
          id: string;
          imageKeys: Array<string>;
          name: string;
        }>;
        customFields: Array<
          | {
              __typename: 'CustomFieldWithValueCheckbox';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueCheckbox: boolean;
            }
          | {
              __typename: 'CustomFieldWithValueCurrency';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueCurrency: number | null;
            }
          | {
              __typename: 'CustomFieldWithValueDate';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueDate: DateString | null;
            }
          | {
              __typename: 'CustomFieldWithValueDateTime';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueDateTime: DateTime | null;
            }
          | {
              __typename: 'CustomFieldWithValueMultiSelect';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueMultiSelect: Array<string>;
              options: Array<{
                __typename: 'CustomFieldWithValueOption';
                id: string;
                label: string;
              }>;
            }
          | {
              __typename: 'CustomFieldWithValueNumber';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueNumber: number | null;
            }
          | {
              __typename: 'CustomFieldWithValueSingleSelect';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueSingleSelect: string | null;
              options: Array<{
                __typename: 'CustomFieldWithValueOption';
                id: string;
                label: string;
              }>;
            }
          | {
              __typename: 'CustomFieldWithValueText';
              id: string;
              isRequired: boolean;
              label: string;
              entityId: string | null;
              valueText: string | null;
            }
        > | null;
        contacts: Array<{
          __typename: 'Contact';
          id: string;
          isPerson: boolean;
          title: CONTACT_TITLE | null;
          firstName: string | null;
          lastName: string | null;
          email: string | null;
          jobTitle: string | null;
          phone: string | null;
          mobilePhone: string | null;
          fax: string | null;
          isPrivate: boolean;
        }>;
      };
};

export type SubcontractorBidRequestsForProfileQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BID_REQUESTS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  subcontractorId: Scalars['ID']['input'];
}>;

export type SubcontractorBidRequestsForProfileQuery = {
  __typename: 'Query';
  bidRequests: {
    __typename: 'BidRequestsResponse';
    totalRecords: number;
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      lastStatusChangeAt: DateTime;
      declinedReasonOther: string | null;
      isAwaitingResponse: boolean;
      currentBid: {
        __typename: 'SubmittedBid';
        id: string;
        totalNet: number;
        discountRate: number | null;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
        absoluteDiscount: number | null;
        billOfQuantities: {
          __typename: 'BillOfQuantities';
          id: string;
          editingStatus: BOQ_EDITING_STATUS | null;
        } | null;
      } | null;
      awardedBid: {
        __typename: 'AwardedBid';
        id: string;
        additionalCosts: number;
        discountRate: number | null;
        absoluteDiscount: number | null;
        totalNet: number;
        skontoDeadline: number;
        skontoRate: number;
        taxRate: number;
      } | null;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        name: string;
        currency: CURRENCY;
        isBlindTender: boolean;
        blindTenderBidsOpenedAt: DateTime | null;
        project: { __typename: 'Project'; id: string; number: string | null; name: string };
      };
    }>;
  };
};

export type SubcontractorWithNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubcontractorWithNotesQuery = {
  __typename: 'Query';
  subcontractor: {
    __typename: 'Subcontractor';
    id: string;
    isDeleted: boolean;
    name: string;
    notes: Array<{
      __typename: 'SubcontractorNote';
      id: string;
      createdAt: DateTime;
      text: string;
      user: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
      } | null;
    }> | null;
  };
};

export type BidPackageForSubcontractorProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BidPackageForSubcontractorProfileQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    status: BID_PACKAGE_STATUS;
    customInvitationEmailIntro: string | null;
    addedContactIds: Array<string>;
    withdrawnContactIds: Array<string>;
    bidsDueAt: DateTime;
    networkAwaitingResponseCountIfRestricted: number | null;
    project: { __typename: 'Project'; id: string; isEstimating: boolean };
    bidRequests: Array<{
      __typename: 'BidRequest';
      id: string;
      status: BID_REQUEST_STATUS;
      customBidsDueAt: DateTime | null;
      subcontractorId: string | null;
      contacts: Array<{
        __typename: 'BidRequestContact';
        id: string;
        bidInvite: { __typename: 'BidInvite'; id: string; status: BID_INVITE_STATUS } | null;
      }>;
    }>;
  };
};

export type AddSubcontractorNoteMutationVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
}>;

export type AddSubcontractorNoteMutation = {
  __typename: 'Mutation';
  addSubcontractorNote: boolean;
};

export type DeleteSubcontractorNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubcontractorNoteMutation = {
  __typename: 'Mutation';
  deleteSubcontractorNote: boolean;
};

export type BidRequestRatingFragment = {
  __typename: 'SubcontractorRatingPerBidRequest';
  bidPackageId: string;
  bidPackageName: string;
  projectId: string;
  projectName: string;
  rating: number;
  userRatedCategories: Array<RATING_CATEGORY>;
  ratingCategories: Array<RATING_CATEGORY> | null;
  subcontractor: { __typename: 'Subcontractor'; id: string; name: string; isDeleted: boolean };
  ratingByCategory: Array<{
    __typename: 'CategoryRating';
    category: RATING_CATEGORY;
    rating: number;
  }>;
  bidRequest: { __typename: 'BidRequest'; id: string; status: BID_REQUEST_STATUS };
};

export type SubcontractorRatingByBidRequestQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type SubcontractorRatingByBidRequestQuery = {
  __typename: 'Query';
  subcontractorRatingByBidRequest: Array<{
    __typename: 'SubcontractorRating';
    id: string;
    rating: number;
    category: RATING_CATEGORY;
    comment: string;
    createdAt: DateTime;
    bidRequestId: string;
    user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
  }>;
};

export type UpsertSubcontractorRatingMutationVariables = Exact<{
  input: SubcontractorRatingInput;
}>;

export type UpsertSubcontractorRatingMutation = {
  __typename: 'Mutation';
  upsertSubcontractorRating: { __typename: 'SubcontractorRating'; id: string };
};

export type DeleteSubcontractorRatingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubcontractorRatingMutation = {
  __typename: 'Mutation';
  deleteSubcontractorRating: boolean;
};

export type ContactInfo_ContactFragment = {
  __typename: 'Contact';
  id: string;
  isPerson: boolean;
  isPrivate: boolean;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  title: CONTACT_TITLE | null;
  jobTitle: string | null;
  phone: string | null;
  mobilePhone: string | null;
  fax: string | null;
};

export type SubcontractorContactsList_subcontractorFragment = {
  __typename: 'Subcontractor';
  id: string;
  contacts: Array<{
    __typename: 'Contact';
    id: string;
    isPerson: boolean;
    isPrivate: boolean;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    title: CONTACT_TITLE | null;
    jobTitle: string | null;
    phone: string | null;
    mobilePhone: string | null;
    fax: string | null;
  }>;
};

export type DeleteSubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubcontractorMutation = { __typename: 'Mutation'; deleteSubcontractor: boolean };

export type SuggestNetworkSubcontractorRemovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type SuggestNetworkSubcontractorRemovalMutation = {
  __typename: 'Mutation';
  suggestNetworkSubcontractorRemoval: boolean;
};

export type SubcontractorsForFilterableListQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SUBCONTRACTORS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  filters?: InputMaybe<SubcontractorFiltersInput>;
}>;

export type SubcontractorsForFilterableListQuery = {
  __typename: 'Query';
  subcontractors: {
    __typename: 'SubcontractorsResponse';
    totalRecords: number;
    subcontractors: Array<{
      __typename: 'SubcontractorResponse';
      distanceInMeters: number | null;
      subcontractor: {
        __typename: 'Subcontractor';
        id: string;
        isBanned: boolean;
        name: string;
        postalCode: string | null;
        logoKey: string | null;
        city: string | null;
        isFavorite: boolean;
        type: SUBCONTRACTOR_TYPE;
        companySize: COMPANY_SIZE | null;
        rating: number | null;
        bidRequestsAwaitingResponseCount: number;
        submittedCertificateTypesCount: number | null;
        pendingBidRequestsCount: number | null;
        workCategories: Array<{
          __typename: 'WorkCategoryForSubcontractor';
          id: string;
          name: string;
          isPrimary: boolean;
        }>;
        referenceProjects: Array<{
          __typename: 'SubcontractorReferenceProject';
          id: string;
          imageKeys: Array<string>;
          name: string;
        }>;
        contacts: Array<{
          __typename: 'Contact';
          id: string;
          isPerson: boolean;
          isPrivate: boolean;
          email: string | null;
          firstName: string | null;
          lastName: string | null;
          title: CONTACT_TITLE | null;
          jobTitle: string | null;
          phone: string | null;
          mobilePhone: string | null;
          fax: string | null;
        }>;
      };
    }>;
  };
};

export type HasCompanyImportedSCsQueryVariables = Exact<{ [key: string]: never }>;

export type HasCompanyImportedSCsQuery = {
  __typename: 'Query';
  hasImportedSubcontractors: boolean;
};

export type SubcontractorsListItem_subcontractorsListItemFragment = {
  __typename: 'Subcontractor';
  id: string;
  name: string;
  postalCode: string | null;
  logoKey: string | null;
  city: string | null;
  isBanned: boolean;
  isFavorite: boolean;
  type: SUBCONTRACTOR_TYPE;
  companySize: COMPANY_SIZE | null;
  rating: number | null;
  bidRequestsAwaitingResponseCount: number;
  submittedCertificateTypesCount: number | null;
  pendingBidRequestsCount: number | null;
  workCategories: Array<{
    __typename: 'WorkCategoryForSubcontractor';
    id: string;
    name: string;
    isPrimary: boolean;
  }>;
  referenceProjects: Array<{
    __typename: 'SubcontractorReferenceProject';
    id: string;
    imageKeys: Array<string>;
    name: string;
  }>;
};

export type ProjectForTaskListFragment = {
  __typename: 'Project';
  id: string;
  number: string | null;
  name: string;
};

export type BidPackageForTaskListFragment = {
  __typename: 'BidPackage';
  id: string;
  number: string | null;
  name: string;
};

export type BidRequestForTaskListFragment = {
  __typename: 'BidRequest';
  id: string;
  bidPackage: {
    __typename: 'BidPackage';
    name: string;
    project: { __typename: 'Project'; name: string };
  };
};

export type TasksQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TASKS_SORT_FIELD>;
  sortDirection?: InputMaybe<SORT_DIRECTION>;
  fetchOwnTasks?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<TASK_STATUS> | TASK_STATUS>;
}>;

export type TasksQuery = {
  __typename: 'Query';
  tasks: {
    __typename: 'TasksResponse';
    totalRecords: number;
    tasks: Array<{
      __typename: 'Task';
      id: string;
      name: string;
      status: TASK_STATUS;
      dueAt: DateTime;
      assigneeType: TASK_ASSIGNEE_TYPE;
      project: { __typename: 'Project'; id: string; number: string | null; name: string } | null;
      bidPackage: {
        __typename: 'BidPackage';
        id: string;
        number: string | null;
        name: string;
      } | null;
      bidRequest: {
        __typename: 'BidRequest';
        id: string;
        subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
        bidPackage: {
          __typename: 'BidPackage';
          name: string;
          project: { __typename: 'Project'; name: string };
        };
      } | null;
      assignee: {
        __typename: 'User';
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string;
      } | null;
    }>;
  };
  allTasks: { __typename: 'TasksResponse'; totalRecords: number };
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTaskMutation = { __typename: 'Mutation'; deleteTask: boolean };

export type MarkTaskAsDoneMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
}>;

export type MarkTaskAsDoneMutation = {
  __typename: 'Mutation';
  markTaskAsDone: { __typename: 'Task'; id: string };
};

export type MarkTaskAsTodoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MarkTaskAsTodoMutation = {
  __typename: 'Mutation';
  markTaskAsTodo: { __typename: 'Task'; id: string };
};

export type MarkTaskAsInProgressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MarkTaskAsInProgressMutation = {
  __typename: 'Mutation';
  markTaskAsInProgress: { __typename: 'Task'; id: string };
};

export type TextEditorImageUploadUrlQueryVariables = Exact<{
  filename: Scalars['String']['input'];
}>;

export type TextEditorImageUploadUrlQuery = {
  __typename: 'Query';
  imageUploadUrl: {
    __typename: 'ImageUploadUrlResponse';
    uploadUrl: string;
    storageKey: string;
    url: string;
  };
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllNotificationsAsReadMutation = {
  __typename: 'Mutation';
  markNotificationsAsRead: boolean;
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MarkNotificationAsReadMutation = {
  __typename: 'Mutation';
  markNotificationAsRead: boolean;
};

export type BidsEligibleForAwardingQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
}>;

export type BidsEligibleForAwardingQuery = {
  __typename: 'Query';
  bidRequest: {
    __typename: 'BidRequest';
    bidPackage: { __typename: 'BidPackage'; id: string; currency: CURRENCY };
    currentBid: { __typename: 'SubmittedBid'; id: string } | null;
    bidsEligibleForAwarding: Array<{
      __typename: 'SubmittedBid';
      id: string;
      totalNet: number;
      skontoRate: number;
      taxRate: number;
      discountRate: number | null;
      absoluteDiscount: number | null;
      submittedAt: DateTime;
      version: number;
      isSimulated: boolean | null;
      documentsCount: number;
      message: string | null;
      billOfQuantitiesRevision: {
        __typename: 'BillOfQuantitiesRevision';
        id: string;
        revision: number;
        isShareableWithBidders: boolean;
        wereBiddersNotified: boolean | null;
      };
    }>;
  };
};

export type UserEditOrInvite_officeFragment = {
  __typename: 'Office';
  id: string;
  name: string;
  status: OFFICE_STATUS;
};

export type UserEditOrInviteQueryVariables = Exact<{ [key: string]: never }>;

export type UserEditOrInviteQuery = {
  __typename: 'Query';
  company: {
    __typename: 'Company';
    id: string;
    customUserRoles: Array<{ __typename: 'CustomUserRole'; id: string; name: string }>;
    config: {
      __typename: 'CompanyConfig';
      id: string;
      fullUserLicenseCount: number | null;
      guestLicenseCount: number | null;
    };
    usedSeats: { __typename: 'CompanyUsedSeatsResponse'; fullUsers: number; guests: number };
  };
  offices: Array<{ __typename: 'Office'; id: string; name: string; status: OFFICE_STATUS }>;
};

export type ResetMfaConfigMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type ResetMfaConfigMutation = { __typename: 'Mutation'; resetMfaConfig: boolean };

export type InvitedUserFragment = {
  __typename: 'User';
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  status: USER_STATUS;
  type: USER_TYPE;
};

export type InviteUsersMutationVariables = Exact<{
  users: Array<UserToInviteInput> | UserToInviteInput;
}>;

export type InviteUsersMutation = {
  __typename: 'Mutation';
  inviteUsers: Array<{
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    status: USER_STATUS;
    type: USER_TYPE;
  }>;
};

export type SendInviteRequestsMutationVariables = Exact<{
  users: Array<UserToInviteInput> | UserToInviteInput;
  source: InviteRequestSourceInput;
}>;

export type SendInviteRequestsMutation = {
  __typename: 'Mutation';
  sendInviteRequests: Array<{
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    status: USER_STATUS;
    type: USER_TYPE;
  }>;
};

export type WorkCategorySuggestionsQueryVariables = Exact<{
  categories: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  hints?: InputMaybe<WorkCategorySuggestionsHints>;
}>;

export type WorkCategorySuggestionsQuery = {
  __typename: 'Query';
  workCategorySuggestions: Array<{
    __typename: 'WorkCategorySuggestion';
    score: number;
    workCategory: { __typename: 'WorkCategory'; id: string; name: string };
  }>;
};

export type AddContactAction_bidPackageFragment = {
  __typename: 'BidPackage';
  id: string;
  status: BID_PACKAGE_STATUS;
  customInvitationEmailIntro: string | null;
  addedContactIds: Array<string>;
  withdrawnContactIds: Array<string>;
  bidsDueAt: DateTime;
  networkAwaitingResponseCountIfRestricted: number | null;
  project: { __typename: 'Project'; id: string; isEstimating: boolean };
  bidRequests: Array<{
    __typename: 'BidRequest';
    id: string;
    status: BID_REQUEST_STATUS;
    customBidsDueAt: DateTime | null;
    subcontractorId: string | null;
    contacts: Array<{
      __typename: 'BidRequestContact';
      id: string;
      bidInvite: { __typename: 'BidInvite'; id: string; status: BID_INVITE_STATUS } | null;
    }>;
  }>;
};

export type RecentMarketplaceBidRequestsCreationDatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecentMarketplaceBidRequestsCreationDatesQuery = {
  __typename: 'Query';
  recentMarketplaceBidRequestsCreationDates: Array<DateTime>;
};

export type AgentCompanyHasProfileCompleteQueryVariables = Exact<{ [key: string]: never }>;

export type AgentCompanyHasProfileCompleteQuery = {
  __typename: 'Query';
  company: { __typename: 'Company'; id: string; isAgentProfileComplete: boolean };
};

export type AgentSignupForAwardedBidMutationVariables = Exact<{
  input: AgentSignupForAwardedBidInput;
}>;

export type AgentSignupForAwardedBidMutation = {
  __typename: 'Mutation';
  agentSignupForAwardedBid: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type AgentSignupForBidInviteMutationVariables = Exact<{
  input: AgentSignupForBidInviteInput;
}>;

export type AgentSignupForBidInviteMutation = {
  __typename: 'Mutation';
  agentSignupForBidInvite: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type AgentSignupForBidSubmissionMutationVariables = Exact<{
  input: AgentSignupForBidSubmissionInput;
}>;

export type AgentSignupForBidSubmissionMutation = {
  __typename: 'Mutation';
  agentSignupForBidSubmission: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type AgentSignupForMarketplaceSuggestionMutationVariables = Exact<{
  input: AgentSignupForMarketplaceSuggestionInput;
}>;

export type AgentSignupForMarketplaceSuggestionMutation = {
  __typename: 'Mutation';
  agentSignupForMarketplaceSuggestion: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type AgentSignupForSubcontractorSentEmailMutationVariables = Exact<{
  input: AgentSignupForSubcontractorSentEmailInput;
}>;

export type AgentSignupForSubcontractorSentEmailMutation = {
  __typename: 'Mutation';
  agentSignupForSubcontractorSentEmail: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type AllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersQuery = {
  __typename: 'Query';
  users: {
    __typename: 'UsersResponse';
    users: Array<{
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
      status: USER_STATUS;
      type: USER_TYPE;
      email: string;
      role:
        | { __typename: 'CustomUserRole'; id: string }
        | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE };
    }>;
  };
};

export type CreateAnalyticsEventMutationVariables = Exact<{
  input: AnalyticsEventInput;
}>;

export type CreateAnalyticsEventMutation = {
  __typename: 'Mutation';
  createAnalyticsEvent: boolean;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    jobTitle: string | null;
    externalCompany: string | null;
    phone: string | null;
    fax: string | null;
    intercomHash: string;
    customSignature: string | null;
    featureFlags: JSONObject;
    type: USER_TYPE;
    isLocked: boolean;
    language: LANGUAGE;
    departmentType: DEPARTMENT_TYPE | null;
    customDepartmentName: string | null;
    isActiveCompanyOnboarding: boolean | null;
    ssoEnabled: boolean;
    mfaMethod: MFA_METHOD | null;
    mfaPhoneCensored: string | null;
    celloToken: string;
    company: {
      __typename: 'Company';
      id: string;
      name: string;
      type: COMPANY_TYPE;
      tags: JSONObject;
      primaryColor: string | null;
      contrastColor: string | null;
      logoKey: string | null;
      mfaRequired: boolean;
      hasOverdueInvoice: boolean;
      packageType: SALESFORCE_PACKAGE_TYPE | null;
      isTestCompany: boolean;
      isAgentProfileComplete: boolean;
      isEnterpriseOpportunity: boolean | null;
      bidRequestsCount: number;
      bidRequestsWithSubmittedBidsCount: number;
      submittedCertificatesCount: number;
      hasNetworkSubcontractorsAssociated: boolean;
      hasBillingAccount: boolean;
      contractEndDate: DateTime | null;
      bidsSubmittedOnMarketplace: number;
      permanentFeatureFlags: JSONObject;
      organization: { __typename: 'Organization'; id: string; ssoRequired: boolean };
      config: {
        __typename: 'CompanyConfig';
        id: string;
        fullUserLicenseCount: number | null;
        guestLicenseCount: number | null;
        additionalLanguages: Array<LANGUAGE>;
        premiumEnabled: boolean;
      };
    };
    notificationConfig: {
      __typename: 'UserNotificationConfig';
      id: string;
      appAgentSignedContract: boolean;
      appBiddersAwaitingResponse: boolean;
      appBiddingPeriodEnded: boolean;
      appBiddingPeriodEnding: boolean;
      appBidRequestDeclined: boolean;
      appCertificateExpired: boolean;
      appCertificateExpiring: boolean;
      appCertificateNotProvidedInTime: boolean;
      appCertificateUploadedBySubcontractor: boolean;
      appContractMultiSigningCompleted: boolean;
      appBidPackageStatusChanged: boolean;
      appNewBid: boolean;
      emailAgentSignedContract: boolean;
      emailBiddersAwaitingResponse: boolean;
      emailBidPackageStatusChanged: boolean;
      emailBiddingPeriodEnded: boolean;
      emailBiddingPeriodEnding: boolean;
      emailBidRequestDeclined: boolean;
      emailNewBid: boolean;
      emailCertificateExpired: boolean;
      emailCertificateExpiring: boolean;
      emailCertificateNotProvidedInTime: boolean;
      emailCertificateUploadedBySubcontractor: boolean;
      emailContractMultiSigningCompleted: boolean;
      emailNewMessageFromSubcontractor: boolean;
      emailUserAddedToBidPackageTeam: boolean;
    };
    workspaces: Array<{
      __typename: 'Workspace';
      id: string;
      isCurrent: boolean;
      ssoEnabled: boolean;
      mfaEnabled: boolean;
      mfaRequired: boolean;
      company: { __typename: 'Company'; id: string; name: string };
    }>;
  };
};

export type CurrentAdminQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentAdminQuery = {
  __typename: 'Query';
  adminMe: { __typename: 'Admin'; id: string } | null;
};

export type CheckWorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;

export type CheckWorkspaceQuery = {
  __typename: 'Query';
  checkWorkspace: { __typename: 'LoginTokenResponse'; loginToken: string };
};

export type SSORedirectUrlQueryVariables = Exact<{
  email: Scalars['String']['input'];
  expectedUserStatus: USER_STATUS;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SSORedirectUrlQuery = { __typename: 'Query'; ssoRedirectUrl: string };

export type BoQUploadUrlQueryVariables = Exact<{ [key: string]: never }>;

export type BoQUploadUrlQuery = {
  __typename: 'Query';
  boqUploadUrl: { __typename: 'FileUploadResponse'; uploadKey: string; uploadUrl: string };
};

export type CombinedNotificationsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  includeMessageNotifications: Scalars['Boolean']['input'];
  includeTaskNotifications: Scalars['Boolean']['input'];
}>;

export type CombinedNotificationsQuery = {
  __typename: 'Query';
  unreadMessageThreadsCount?: number;
  myTasksInStatusCount?: number;
  notifications: {
    __typename: 'NotificationsResponse';
    totalRecords: number;
    totalUnread: number;
    notifications: Array<
      | {
          __typename: 'AwardBidderReminderNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'AwardBidderReminderNotificationProps';
            bidPackageId: string;
            bidPackageName: string;
            bidPackageBidsDueWeeksAgo: number;
            projectId: string;
            projectName: string;
          };
        }
      | {
          __typename: 'BidPackageExportBidsNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'BidPackageExportBidsNotificationProps';
            projectId: string;
            projectName: string;
            bidPackageId: string;
            bidPackageName: string;
            downloadUrl: string;
          };
        }
      | {
          __typename: 'BidPackageStatusChangedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'BidPackageStatusChangedNotificationProps';
            status: BID_PACKAGE_STATUS;
            bidPackageId: string;
            bidPackageName: string;
            projectName: string;
            projectId: string;
          };
        }
      | {
          __typename: 'BidRequestDeclinedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'BidRequestDeclinedNotificationProps';
            projectId: string;
            projectName: string;
            bidPackageId: string;
            bidPackageName: string;
            subcontractorName: string;
            bidRequestId: string;
          };
        }
      | {
          __typename: 'BiddersAwaitingResponseNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props:
            | {
                __typename: 'BiddersAwaitingResponseNotificationV1Props';
                bidPackageId: string;
                bidPackageName: string;
                bidPackageBidsDueWeeksAgo: number;
                projectId: string;
                projectName: string;
                subcontractorNames: Array<string>;
              }
            | {
                __typename: 'BiddersAwaitingResponseNotificationV2Props';
                bidPackageId: string;
                bidPackageName: string;
                projectId: string;
                projectName: string;
                subcontractorNames: Array<string>;
                durationSinceBidsDue: { __typename: 'Duration'; value: number; unit: string };
              };
        }
      | {
          __typename: 'BiddingPeriodEndingNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'BiddingPeriodEndingNotificationProps';
            bidPackageId: string;
            bidPackageName: string;
            bidsCount: number;
            endingInDays: number;
            projectId: string;
            projectName: string;
          };
        }
      | {
          __typename: 'CertificateExpiringNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'CertificateExpiringNotificationProps';
            certificateTypeName: string;
            certificateTypeId: string | null;
            expiresInDays: number;
            subcontractorId: string;
            subcontractorName: string;
          };
        }
      | {
          __typename: 'CertificateExpiringSubcontractorNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'CertificateExpiringSubcontractorNotificationProps';
            certificateTypeName: string;
            expiresInDays: number;
          };
        }
      | {
          __typename: 'CertificateNotProvidedInTimeNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'CertificateNotProvidedInTimeNotificationProps';
            certificateTypeNames: Array<string>;
            subcontractorId: string;
            subcontractorName: string;
          };
        }
      | {
          __typename: 'CertificateUploadedBySubcontractorNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'CertificateUploadedBySubcontractorNotificationProps';
            certificateTypeNames: Array<string>;
            subcontractorId: string;
            subcontractorName: string;
          };
        }
      | {
          __typename: 'ContractAgentSignatureRequestedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'ContractAgentSignatureRequestedNotificationProps';
            awardedBidId: string;
            bidPackageName: string;
            projectName: string;
            officeName: string;
          };
        }
      | {
          __typename: 'ContractMultiSigningCompletedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'ContractMultiSigningCompletedNotificationProps';
            bidPackageId: string;
            bidPackageName: string;
            projectId: string;
            projectName: string;
          };
        }
      | {
          __typename: 'ContractPrincipalSignatureRequestedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'ContractPrincipalSignatureRequestedNotificationProps';
            bidPackageId: string;
            bidPackageName: string;
            projectId: string;
            projectName: string;
          };
        }
      | {
          __typename: 'ContractSignedByAgentNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'ContractSignedByAgentNotificationProps';
            bidPackageId: string;
            bidPackageName: string;
            projectId: string;
            projectName: string;
            subcontractorName: string;
          };
        }
      | {
          __typename: 'InvoiceAwaitingApprovalNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'InvoiceAwaitingApprovalNotificationProps';
            invoiceId: string;
            invoiceNumber: string | null;
            invoiceName: string | null;
            bidPackageName: string;
            projectName: string;
          };
        }
      | {
          __typename: 'InvoiceRejectedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'InvoiceRejectedNotificationProps';
            invoiceId: string;
            invoiceNumber: string | null;
            invoiceName: string | null;
            bidPackageName: string;
            projectName: string;
          };
        }
      | {
          __typename: 'NewBidNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'NewBidNotificationProps';
            bidId: string;
            bidPackageId: string;
            bidPackageName: string;
            bidRequestId: string;
            projectId: string;
            projectName: string;
            subcontractorName: string;
            teamMemberName: string | null;
            totalNet: number | null;
            taxRate: number | null;
            skontoRate: number | null;
            skontoDeadline: number | null;
            discountRate: number | null;
            absoluteDiscount: number | null;
            additionalCosts: number | null;
            currency: CURRENCY | null;
          };
        }
      | {
          __typename: 'NewMessageNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'NewMessageNotificationProps';
            bidPackageId: string;
            bidPackageName: string;
            bidRequestId: string;
            messageThreadId: string;
            projectId: string;
            projectName: string;
            authorId: string;
            authorName: string;
            authorType: COMPANY_TYPE;
          };
        }
      | {
          __typename: 'ProjectExportCompleteNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'ProjectExportCompleteNotificationProps';
            projectName: string;
            downloadUrl: string;
          };
        }
      | {
          __typename: 'SubcontractorImportRequestCompletedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: {
            __typename: 'SubcontractorImportRequestCompletedNotificationProps';
            importRequestId: string;
          };
        }
      | {
          __typename: 'TaskAssignedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: { __typename: 'TaskAssignedNotificationProps'; taskId: string; taskName: string };
        }
      | {
          __typename: 'TaskCompletedNotification';
          id: string;
          isRead: boolean;
          createdAt: DateTime;
          props: { __typename: 'TaskCompletedNotificationProps'; taskId: string; taskName: string };
        }
    >;
  };
};

export type CostGroupDefinitionsQueryVariables = Exact<{
  costGroupType: COST_GROUP_TYPE;
}>;

export type CostGroupDefinitionsQuery = {
  __typename: 'Query';
  costGroupDefinitions: {
    __typename: 'CostGroupDefinitionResponse';
    type: COST_GROUP_TYPE;
    definitions: Array<{
      __typename: 'CostGroupDefinition';
      localId: string;
      parentLocalId: string | null;
      designation: string;
    }>;
  };
};

export type CustomCostGroupCatalogDefinitionQueryVariables = Exact<{
  catalogId: Scalars['ID']['input'];
}>;

export type CustomCostGroupCatalogDefinitionQuery = {
  __typename: 'Query';
  customCostGroupCatalog: {
    __typename: 'CustomCostGroupCatalog';
    costGroups: Array<{
      __typename: 'CustomCostGroupDefinition';
      localId: string;
      name: string;
      childCostGroups: Array<{
        __typename: 'CustomCostGroupDefinition';
        localId: string;
        name: string;
        childCostGroups: Array<{
          __typename: 'CustomCostGroupDefinition';
          localId: string;
          name: string;
        }>;
      }>;
    }>;
  };
};

export type RequestSessionFailureFragment = {
  __typename: 'RequestEditingSessionFailure';
  existingSession: {
    __typename: 'EditingSession';
    id: string;
    type: EDITING_SESSION_TYPE;
    user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
  };
};

export type RequestEditingSessionMutationVariables = Exact<{
  type: EDITING_SESSION_TYPE;
  resourceId: Scalars['ID']['input'];
}>;

export type RequestEditingSessionMutation = {
  __typename: 'Mutation';
  requestEditingSession:
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      }
    | {
        __typename: 'RequestEditingSessionSuccess';
        newSession: { __typename: 'EditingSession'; id: string };
      };
};

export type AbandonEditingSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
}>;

export type AbandonEditingSessionMutation = {
  __typename: 'Mutation';
  abandonEditingSession: boolean;
};

export type TakeOverEditingSessionMutationVariables = Exact<{
  type: EDITING_SESSION_TYPE;
  resourceId: Scalars['ID']['input'];
}>;

export type TakeOverEditingSessionMutation = {
  __typename: 'Mutation';
  takeOverEditingSession: string;
};

export type RefreshEditingSessionMutationVariables = Exact<{
  type: EDITING_SESSION_TYPE;
  resourceId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
}>;

export type RefreshEditingSessionMutation = {
  __typename: 'Mutation';
  refreshEditingSession:
    | { __typename: 'RefreshEditingSessionSuccess'; success: boolean }
    | {
        __typename: 'RequestEditingSessionFailure';
        existingSession: {
          __typename: 'EditingSession';
          id: string;
          type: EDITING_SESSION_TYPE;
          user: {
            __typename: 'User';
            id: string;
            firstName: string | null;
            lastName: string | null;
          };
        };
      };
};

export type ApplicableMultiUserApprovalRuleQueryVariables = Exact<{
  action: MULTI_USER_APPROVAL_ACTION;
  entityId: Scalars['ID']['input'];
  requestProps?: InputMaybe<MultiUserApprovalRequestPropsInput>;
}>;

export type ApplicableMultiUserApprovalRuleQuery = {
  __typename: 'Query';
  applicableMultiUserApprovalRule: {
    __typename: 'MultiUserApprovalRule';
    id: string;
    minUsers: number;
    timeLimitMinutes: number | null;
    userRoles: Array<
      | { __typename: 'CustomUserRole'; id: string }
      | { __typename: 'StandardUserRole'; type: STANDARD_USER_TYPE }
    >;
  } | null;
};

export type MultiUserApprovalRequestQueryVariables = Exact<{
  action: MULTI_USER_APPROVAL_ACTION;
  entityId: Scalars['ID']['input'];
}>;

export type MultiUserApprovalRequestQuery = {
  __typename: 'Query';
  multiUserApprovalRequest: {
    __typename: 'MultiUserApprovalRequest';
    id: string;
    props:
      | {
          __typename: 'MultiUserApprovalRequestAwardBidProps';
          bidId: string;
          absoluteDiscount: number | null;
          additionalCosts: number;
          bidRequestId: string;
          discountRate: number | null;
          message: string;
          skontoDeadline: number;
          skontoRate: number;
          taxRate: number;
          totalNet: number;
          awardedSumType: BID_AWARDED_SUM_TYPE;
          mainOrderNumber: string | null;
          awarding: {
            __typename: 'EmailTemplateNotification';
            emailTemplate: string | null;
            shouldSend: boolean;
          };
          rejection: {
            __typename: 'EmailTemplateNotification';
            emailTemplate: string | null;
            shouldSend: boolean;
          };
        }
      | { __typename: 'MultiUserApprovalRequestPublishBidPackageProps' }
      | { __typename: 'MultiUserApprovalRequestSendContractProps' }
      | null;
    requester: {
      __typename: 'User';
      id: string;
      firstName: string | null;
      lastName: string | null;
    };
    users: Array<{
      __typename: 'MultiUserApprovalRequestUser';
      id: string;
      hasApproved: boolean;
      user: { __typename: 'User'; id: string; firstName: string | null; lastName: string | null };
    }>;
  } | null;
};

export type CostGroupCatalog_costStructureFragment = {
  __typename: 'ProjectCostGroupCatalog';
  catalogType: PROJECT_COST_CATALOG_TYPE;
  customCatalog: {
    __typename: 'CustomCostGroupCatalog';
    id: string;
    name: string;
    status: CUSTOM_COST_GROUP_CATALOG_STATUS;
  } | null;
};

export type ProjectStructuresForBidPackageQueryVariables = Exact<{
  bidPackageId: Scalars['ID']['input'];
}>;

export type ProjectStructuresForBidPackageQuery = {
  __typename: 'Query';
  bidPackage: {
    __typename: 'BidPackage';
    id: string;
    project: {
      __typename: 'Project';
      id: string;
      costGroupCatalogs: Array<{
        __typename: 'ProjectCostGroupCatalog';
        catalogType: PROJECT_COST_CATALOG_TYPE;
        customCatalog: {
          __typename: 'CustomCostGroupCatalog';
          id: string;
          name: string;
          status: CUSTOM_COST_GROUP_CATALOG_STATUS;
        } | null;
      }>;
    };
  };
};

export type ShowSubcontractorProfilePromptQueryVariables = Exact<{ [key: string]: never }>;

export type ShowSubcontractorProfilePromptQuery = {
  __typename: 'Query';
  company: { __typename: 'Company'; id: string; isAgentProfileComplete: boolean };
};

export type StlbWorkCategoryDefinitionsQueryVariables = Exact<{ [key: string]: never }>;

export type StlbWorkCategoryDefinitionsQuery = {
  __typename: 'Query';
  stlbWorkCategoryDefinitions: {
    __typename: 'StlbWorkCategoryDefinitionResponse';
    definitions: Array<{
      __typename: 'StlbWorkCategoryDefinition';
      id: string;
      designation: string;
    }>;
  };
};

export type BidDataForSummaryTableQueryVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  bidPackageId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type BidDataForSummaryTableQuery = {
  __typename: 'Query';
  draftBid: {
    __typename: 'DraftBid';
    id: string;
    totalNet: number | null;
    skontoRate: number | null;
    skontoDeadline: number | null;
    discountRate: number | null;
    absoluteDiscount: number | null;
    taxRate: number | null;
  } | null;
};

export type CompanyLicenseDataFragment = {
  __typename: 'Company';
  config: {
    __typename: 'CompanyConfig';
    id: string;
    fullUserLicenseCount: number | null;
    guestLicenseCount: number | null;
  };
  usedSeats: { __typename: 'CompanyUsedSeatsResponse'; fullUsers: number; guests: number };
};

export type PasswordPolicyFragment = {
  __typename: 'CompanyPasswordPolicy';
  minLength: number;
  requiresLowerCaseCharacter: boolean;
  requiresUpperCaseCharacter: boolean;
  requiresSpecialCharacter: boolean;
  requiresNumber: boolean;
};

export type WorkCategoriesQueryVariables = Exact<{
  type: WORK_CATEGORY_TYPE_FILTER;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type WorkCategoriesQuery = {
  __typename: 'Query';
  workCategories: {
    __typename: 'WorkCategoriesResponse';
    totalRecords: number;
    workCategories: Array<{
      __typename: 'WorkCategory';
      id: string;
      name: string;
      type: WORK_CATEGORY_TYPE;
      isNew: boolean | null;
    }>;
  };
};

export type WorkCategoriesWithGroupsQueryVariables = Exact<{
  type: WORK_CATEGORY_TYPE_FILTER;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  language?: InputMaybe<LANGUAGE>;
}>;

export type WorkCategoriesWithGroupsQuery = {
  __typename: 'Query';
  workCategories: {
    __typename: 'WorkCategoriesResponse';
    totalRecords: number;
    workCategories: Array<{
      __typename: 'WorkCategory';
      id: string;
      name: string;
      type: WORK_CATEGORY_TYPE;
      isNew: boolean | null;
      group: {
        __typename: 'WorkCategoryGroup';
        id: string;
        name: string;
        position: number;
        translation: string;
      } | null;
    }>;
  };
};

export type AvailableWorkCategoriesQueryVariables = Exact<{
  type: WORK_CATEGORY_TYPE_FILTER;
  query?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type AvailableWorkCategoriesQuery = {
  __typename: 'Query';
  workCategories: {
    __typename: 'WorkCategoriesResponse';
    totalRecords: number;
    workCategories: Array<{
      __typename: 'WorkCategory';
      id: string;
      name: string;
      type: WORK_CATEGORY_TYPE;
      isNew: boolean | null;
    }>;
  };
};

export type OutdatedWorkCategoriesQueryVariables = Exact<{
  type: WORK_CATEGORY_TYPE_FILTER;
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type OutdatedWorkCategoriesQuery = {
  __typename: 'Query';
  workCategories: {
    __typename: 'WorkCategoriesResponse';
    totalRecords: number;
    workCategories: Array<{
      __typename: 'WorkCategory';
      id: string;
      name: string;
      type: WORK_CATEGORY_TYPE;
    }>;
  };
};

export type UpdateBidRequestStatusBySubcontractorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: BID_REQUEST_STATUS;
}>;

export type UpdateBidRequestStatusBySubcontractorMutation = {
  __typename: 'Mutation';
  updateBidRequestStatusBySubcontractor: {
    __typename: 'BidRequest';
    id: string;
    status: BID_REQUEST_STATUS;
  };
};

export type mutationUpdateBidRequestDeclinedReasonMutationVariables = Exact<{
  input: UpdateBidRequestDeclinedReasonByAgentInput;
}>;

export type mutationUpdateBidRequestDeclinedReasonMutation = {
  __typename: 'Mutation';
  updateBidRequestDeclinedReasonBySubcontractor: boolean;
};

export type ImageUploadUrlQueryVariables = Exact<{
  filename: Scalars['String']['input'];
}>;

export type ImageUploadUrlQuery = {
  __typename: 'Query';
  imageUploadUrl: { __typename: 'ImageUploadUrlResponse'; uploadUrl: string; storageKey: string };
};

export type AgentSendPremiumPurchaseRequestMutationVariables = Exact<{
  subcontractorId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AgentSendPremiumPurchaseRequestMutation = {
  __typename: 'Mutation';
  agentSendPremiumPurchaseRequest: boolean;
};

export type SubcontractorsByAgentQueryVariables = Exact<{
  includePremiumFields: Scalars['Boolean']['input'];
}>;

export type SubcontractorsByAgentQuery = {
  __typename: 'Query';
  subcontractorsByAgent: Array<{
    __typename: 'Subcontractor';
    id: string;
    logoKey: string | null;
    name: string;
    aboutUs: string | null;
    imageKeys: Array<string>;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    countryCode: COUNTRY_CODE | null;
    website: string | null;
    orderAreaType: ORDER_AREA_TYPE;
    orderRadius: ORDER_RADIUS | null;
    orderAreaStateCodes: Array<STATE_CODE> | null;
    orderAreaCountryCode: COUNTRY_CODE | null;
    companySize: COMPANY_SIZE | null;
    confirmedByAgent: boolean;
    lat: number | null;
    long: number | null;
    nationalId?: string | null;
    registrationPostalCode?: string | null;
    registrationCity?: string | null;
    foundingYear?: number | null;
    ownerName?: string | null;
    revenueYear?: number | null;
    taxId?: string | null;
    yearlyRevenue?: number | null;
    hasSustainableConstruction: boolean | null;
    sustainableConstructionDetails: string | null;
    referenceProjects: Array<{
      __typename: 'SubcontractorReferenceProject';
      id: string;
      name: string;
      description: string;
      imageKeys: Array<string>;
      position: number;
    }>;
    workCategories: Array<{
      __typename: 'WorkCategoryForSubcontractor';
      id: string;
      name: string;
      isPrimary: boolean;
    }>;
    contacts: Array<{
      __typename: 'Contact';
      id: string;
      isPerson: boolean;
      title: CONTACT_TITLE | null;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      jobTitle: string | null;
      phone: string | null;
      mobilePhone: string | null;
      fax: string | null;
      isPrivate: boolean;
    }>;
  }>;
};

export type UpdateSubcontractorWorkCategoriesByAgentMutationVariables = Exact<{
  bidRequestId: Scalars['ID']['input'];
  workCategories?: InputMaybe<Array<WorkCategoryInput> | WorkCategoryInput>;
  confirmWorkCategoriesModal?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateSubcontractorWorkCategoriesByAgentMutation = {
  __typename: 'Mutation';
  updateSubcontractorWorkCategoriesByAgent: boolean;
};

export type UpdateSubcontractorWorkCategoriesMutationVariables = Exact<{
  subcontractorId: Scalars['ID']['input'];
  token: Scalars['ID']['input'];
  workCategories?: InputMaybe<Array<WorkCategoryInput> | WorkCategoryInput>;
  confirmWorkCategoriesModal?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateSubcontractorWorkCategoriesMutation = {
  __typename: 'Mutation';
  updateSubcontractorWorkCategories: boolean;
};

export type GermanRegistrationCourtsQueryVariables = Exact<{ [key: string]: never }>;

export type GermanRegistrationCourtsQuery = {
  __typename: 'Query';
  germanRegistrationCourts: Array<{
    __typename: 'RegistrationCourt';
    city: string;
    postalCode: string;
  }>;
};

export type TaskForAssigneeLabelFragment = {
  __typename: 'Task';
  assignee: {
    __typename: 'User';
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
  } | null;
  bidRequest: {
    __typename: 'BidRequest';
    subcontractor: { __typename: 'Subcontractor'; id: string; name: string };
  } | null;
};

export type AuthorizeMultipleQueryVariables = Exact<{
  inputs: Array<InputMaybe<AuthorizeInput>> | InputMaybe<AuthorizeInput>;
}>;

export type AuthorizeMultipleQuery = {
  __typename: 'Query';
  authorizeMultiple: Array<boolean | null>;
};

export const AwardedBid_bidRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AwardedBid_bidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardedBid_bidRequestFragment, unknown>;
export const AwardedBid_bidFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AwardedBid_bid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardedBid_bidFragment, unknown>;
export const BidSummary_bidFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidSummary_bid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmittedBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidSummary_bidFragment, unknown>;
export const PrincipalSubmitBid_draftBidFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrincipalSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canNotifyBidderOnPrincipalSubmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrincipalSubmitBid_draftBidFragment, unknown>;
export const Bidders_bidPackageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bidders_bidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customInvitationEmailIntro' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Bidders_bidPackageFragment, unknown>;
export const CostComparisonItemPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostComparisonItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostComparisonItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notFilledIn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostComparisonItemPropsFragment, unknown>;
export const CostComparisonCostGroupPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostComparisonCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostComparisonCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostComparisonItemProps' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostComparisonItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostComparisonItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notFilledIn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostComparisonCostGroupPropsFragment, unknown>;
export const BidPackageOverview_bidPackageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageOverview_bidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'awardingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBidders' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidInviteReminderScheduleForAccepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInviteReminderScheduleForNoResponse' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedToMarketplaceAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBiddersDownloadBoQPdf' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPendingMultiUserApprovalRequest' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageOverview_bidPackageFragment, unknown>;
export const CompanyConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyConfigFragment, unknown>;
export const ProjectDetailsForBidPackageOverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailsForBidPackageOverview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTermsAcceptanceRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishToMarketplaceForEstimation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForAwarding' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInviteReminderScheduleForAccepted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInviteReminderScheduleForNoResponse' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailsForBidPackageOverviewFragment, unknown>;
export const ContractRunStepUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractRunStepUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractRunStepUserFragment, unknown>;
export const ContractRunAgentStepContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractRunAgentStepContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractRunAgentStepContactFragment, unknown>;
export const BidsList_bidsListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidsList_bidsListFragment, unknown>;
export const LandingPageSubcontractorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandingPageSubcontractorFragment, unknown>;
export const BidPackageProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageProjectFragment, unknown>;
export const LandingPageBidPackageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandingPageBidPackageFragment, unknown>;
export const LandingPageBidRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgentStatusMutable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentStatusSetBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidsList_bidsList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'areDocumentsAvailable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandingPageBidRequestFragment, unknown>;
export const CompanyProfile_companyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyProfile_company' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyProfile_companyFragment, unknown>;
export const CustomCostGroupCatalogs_CostGroupsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomCostGroupCatalogs_CostGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomCostGroupDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childCostGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childCostGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomCostGroupCatalogs_CostGroupsFragment, unknown>;
export const PermissionsPageUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PermissionsPageUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StandardUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PermissionsPageUserFragment, unknown>;
export const PermissionControlsConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PermissionControlsConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PermissionControlsConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userAddEditDeactivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectsFromAccessibleOffices' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectsAllowDeletingWithNonDraftBidPackages' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCreateEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectInternalDataView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCloseReopen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageCreateEditPublish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageInternalDataView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageModifyMarketplacePublishing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidderInviteManageBids' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidderDeleteBids' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pctView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pctExport' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqImportEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqTemplateCreateEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqTemplateDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractPrepare' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportingView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySubcontractorsView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySubcontractorsExport' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorAddEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorBanFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorBidRequestsView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorNotesViewUpdateCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCertificatesView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCertificatesRequest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCertificatesUpdateDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateTypesCreate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageProjectDocumentsViewDownload' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyDocumentsViewDownload' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoicesDeleteApprovedRejected' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PermissionControlsConfigFragment, unknown>;
export const UsersTableDropdown_userFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersTableDropdown_user' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mfaLastResetAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StandardUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersTableDropdown_userFragment, unknown>;
export const OfficeForm_officeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfficeForm_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplacePublishedBidPackagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalDisclosure' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForEstimation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForAwarding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUsImageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditreformLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeRegisterLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'foundingYear' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentTerms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfficeForm_officeFragment, unknown>;
export const CostItemPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostItemPropsFragment, unknown>;
export const CostEstimationStandardCatalogCostGroupPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostEstimationStandardCatalogCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostEstimationStandardCatalogCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftCostItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostEstimationStandardCatalogCostGroupPropsFragment, unknown>;
export const CostEstimationCustomCatalogCostGroupPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostEstimationCustomCatalogCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostEstimationCustomCatalogCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftCostItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostEstimationCustomCatalogCostGroupPropsFragment, unknown>;
export const CostAnalysisCostGroupPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostAnalysisCostGroupProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostAnalysisCostGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentLocalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costItemId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'estimatedBudgetNet' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeEstimatedBudget' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costEstimateCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeCostEstimate' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeCostCalculation' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBidCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeAwarded' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addendumCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeAddenda' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actualCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeActual' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostAnalysisCostGroupPropsFragment, unknown>;
export const CostFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Cost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeAssociatedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'costGroupLocalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityFormula' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostFragment, unknown>;
export const BaseQuantityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuantity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeBaseQuantity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseQuantityFragment, unknown>;
export const NodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Node' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ParsedIfcFileNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Cost' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseQuantity' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Cost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeAssociatedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'costGroupLocalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityFormula' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuantity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeBaseQuantity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NodeFragment, unknown>;
export const CustomFieldWithValueFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomFieldWithValueFragment, unknown>;
export const ProjectOverview_projectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOverview_project' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTaxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplacePublishedBidPackagesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPendingMultiUserApprovalRequest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingRunStepUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requireAcceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costGroupCatalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customCatalog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectOverview_projectFragment, unknown>;
export const ProjectOverview_officeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOverview_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectOverview_officeFragment, unknown>;
export const AgentSubmitBid_draftBidFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warningMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeQuantityPositionsWithoutQuantitiesIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notFilledPositionsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasProposalInDocuments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elementsWithNotFilledBidderInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'elementId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidderInputLabel' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentSubmitBid_draftBidFragment, unknown>;
export const BidRequestsAgentForTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsAgentForTaskFragment, unknown>;
export const BidRequestsPrincipalForTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsPrincipalForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsPrincipalForTaskFragment, unknown>;
export const UserForTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserForTaskFragment, unknown>;
export const BoQFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQ' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQFragment, unknown>;
export const BoQWithoutContentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQWithoutContent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQWithoutContentFragment, unknown>;
export const baseRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'baseRow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AbstractMassDeterminationRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<baseRowFragment, unknown>;
export const rowsPropFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rowsProp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MassDetermination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rows' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value5' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCustomFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expression' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCommentRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'baseRow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AbstractMassDeterminationRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<rowsPropFragment, unknown>;
export const BoQFromCacheFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQFromCache' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQFromCacheFragment, unknown>;
export const EditRequestBoQSuccessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditRequestBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditRequestBoQSuccessFragment, unknown>;
export const EditTemplateBoQSuccessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditTemplateBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditTemplateBoQSuccessFragment, unknown>;
export const RequestPasswordlessLoginCodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestPasswordlessLoginCodeFragment, unknown>;
export const ValidatePasswordlessLoginResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ValidatePasswordlessLoginFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CheckCredentialsResponse' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'loginToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaPhoneCensored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidatePasswordlessLoginResponseFragment, unknown>;
export const CertificateModal_OfficeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_Office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateModal_OfficeFragment, unknown>;
export const CertificateModal_CertificateRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_CertificateRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateModal_CertificateRequestFragment, unknown>;
export const CertificateModal_CertificateTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_CertificateType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateModal_CertificateTypeFragment, unknown>;
export const CertificateModal_CertificateRequestItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_CertificateRequestItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canSaveInProfile' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateModal_CertificateRequestItemFragment, unknown>;
export const CertificateModal_CertificateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateModal_CertificateFragment, unknown>;
export const CertificateTypeModal_companyCertificateTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateTypeModal_companyCertificateType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInUse' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateTypeModal_companyCertificateTypeFragment, unknown>;
export const CertificatesTable_CertificateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificatesTable_CertificateFragment, unknown>;
export const CertificatesTable_PendingCertificateRequestItemForBidPackageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CertificatesTable_PendingCertificateRequestItemForBidPackageFragment,
  unknown
>;
export const CertificatesTable_CertificateTypeReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateTypeReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpiryDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingCertificateRequestItemsForBidPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificatesTable_CertificateTypeReportFragment, unknown>;
export const DocumentsTable_documentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentsTable_documents' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasBeenDownloaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsedIfcFile' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isAttachableProjectBim' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskProcessingState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentsTable_documentsFragment, unknown>;
export const DocumentsTable_fileTreeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentsTable_fileTree' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileTree' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileTree' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentsTable_fileTreeFragment, unknown>;
export const ThreadsList_messageThreadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThreadsList_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participatingCompanyOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondingCompanyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadsList_messageThreadFragment, unknown>;
export const Messages_messageThreadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Messages_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThreadsList_messageThread' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThreadsList_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participatingCompanyOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondingCompanyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Messages_messageThreadFragment, unknown>;
export const Notes_noteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Notes_noteFragment, unknown>;
export const BidForSubcontractorBidColumnHeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidForSubcontractorBidColumnHeader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubmittedOrGeneratedSubmittedBid' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidForSubcontractorBidColumnHeaderFragment, unknown>;
export const SubcontractorForSubcontractorBidColumnHeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorForSubcontractorBidColumnHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorForSubcontractorBidColumnHeaderFragment, unknown>;
export const ContactsForSubcontractorFormFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactsForSubcontractorForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactsForSubcontractorFormFragment, unknown>;
export const AgentSubcontractorForm_subcontractorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentSubcontractorForm_subcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmedByAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'long' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalId' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrationPostalCode' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrationCity' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foundingYear' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerName' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revenueYear' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxId' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearlyRevenue' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSustainableConstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sustainableConstructionDetails' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactsForSubcontractorForm' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactsForSubcontractorForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentSubcontractorForm_subcontractorFragment, unknown>;
export const PrincipalSubcontractorForm_subcontractorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrincipalSubcontractorForm_subcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moreInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'foundingYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenueYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearlyRevenue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generatedDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pqNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactsForSubcontractorForm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactsForSubcontractorForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrincipalSubcontractorForm_subcontractorFragment, unknown>;
export const BidRequestRatingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestRating' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubcontractorRatingPerBidRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratingByCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userRatedCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratingCategories' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestRatingFragment, unknown>;
export const ContactInfo_ContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo_Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactInfo_ContactFragment, unknown>;
export const SubcontractorContactsList_subcontractorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorContactsList_subcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo_Contact' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo_Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorContactsList_subcontractorFragment, unknown>;
export const SubcontractorsListItem_subcontractorsListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorsListItem_subcontractorsListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'pendingBidRequestsCount' },
            name: { kind: 'Name', value: 'bidRequestsCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'invited' },
                    { kind: 'EnumValue', value: 'opened' },
                    { kind: 'EnumValue', value: 'viewed' },
                    { kind: 'EnumValue', value: 'accepted' },
                    { kind: 'EnumValue', value: 'undecided' },
                  ],
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedCertificateTypesCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorsListItem_subcontractorsListItemFragment, unknown>;
export const ProjectForTaskListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectForTaskList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectForTaskListFragment, unknown>;
export const BidPackageForTaskListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageForTaskList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageForTaskListFragment, unknown>;
export const BidRequestForTaskListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestForTaskList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestForTaskListFragment, unknown>;
export const UserEditOrInvite_officeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserEditOrInvite_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserEditOrInvite_officeFragment, unknown>;
export const InvitedUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitedUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvitedUserFragment, unknown>;
export const AddContactAction_bidPackageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddContactAction_bidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customInvitationEmailIntro' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addedContactIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withdrawnContactIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkAwaitingResponseCountIfRestricted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddContactAction_bidPackageFragment, unknown>;
export const RequestSessionFailureFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestSessionFailureFragment, unknown>;
export const CostGroupCatalog_costStructureFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostGroupCatalog_costStructure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCostGroupCatalog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCatalog' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostGroupCatalog_costStructureFragment, unknown>;
export const CompanyLicenseDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyLicenseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullUserLicenseCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestLicenseCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usedSeats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fullUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guests' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyLicenseDataFragment, unknown>;
export const PasswordPolicyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PasswordPolicy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyPasswordPolicy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'minLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresLowerCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresUpperCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresSpecialCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordPolicyFragment, unknown>;
export const TaskForAssigneeLabelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Task' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskForAssigneeLabelFragment, unknown>;
export const UpdateAddendumStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAddendumStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newStatus' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ADDENDUM_STATUS' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAddendumStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newStatus' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAddendumStatusMutation, UpdateAddendumStatusMutationVariables>;
export const UpdateAddendumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAddendum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAddendumInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAddendum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAddendumMutation, UpdateAddendumMutationVariables>;
export const AddendumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Addendum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addendum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contractRefNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addendumNumbers' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'firstName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isPerson' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'jobTitle' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'mobilePhone' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bidInvite' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractor' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'postalCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'billOfQuantities' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'comments' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'path' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'submittedByUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preferredBoQFileFormat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costGroupCatalogs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customCatalogId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customCatalogName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddendumQuery, AddendumQueryVariables>;
export const AwardedBidForAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AwardedBidForAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractRunAgentStepId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBidForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractRunAgentStepId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contractRunAgentStepId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractDocumentsRequiringSignature' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isContractSignedByAgent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isContractSignedByPrincipal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isContractSignedDocumentPending' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isContractSignedPerUpload' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isContractSigningEnabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractAssignee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalStepsCount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userFirstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userLastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractAgentAssignee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalStepsCount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'office' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'company' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isClaimedByAgentCompany' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardedBidForAgentQuery, AwardedBidForAgentQueryVariables>;
export const TasksForContractedBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksForContractedBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TASKS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assigneeType' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Task' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TasksForContractedBidQuery, TasksForContractedBidQueryVariables>;
export const AwardedBidsForAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AwardedBidsForAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeContractSignedByAgent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BIDS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBidsForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeContractSignedByAgent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeContractSignedByAgent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awardedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidPackage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'project' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'office' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardedBidsForAgentQuery, AwardedBidsForAgentQueryVariables>;
export const BidRequestForEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestForEdit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestForEditQuery, BidRequestForEditQueryVariables>;
export const BidPackageDetailsFromBidRequestForAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageDetailsFromBidRequestForAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submittedBids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preferredBoQFileFormat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costGroupCatalogs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customCatalogId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customCatalogName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageDetailsFromBidRequestForAgentQuery,
  BidPackageDetailsFromBidRequestForAgentQueryVariables
>;
export const CreateBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequestByAgentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBidRequestByAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBidRequestMutation, CreateBidRequestMutationVariables>;
export const UpdateBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequestByAgentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestByAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBidRequestMutation, UpdateBidRequestMutationVariables>;
export const BidRequestsForAgentCountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForAgentCounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestsForAgentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'allRecords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeRecords' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestsForAgentCountsQuery,
  BidRequestsForAgentCountsQueryVariables
>;
export const BidRequestsForAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestsForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyActive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActive' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'office' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'absoluteDiscount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skontoDeadline' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notesCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsForAgentQuery, BidRequestsForAgentQueryVariables>;
export const MarketplaceBidPackageSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketplaceBidPackageSuggestions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceBidPackageSuggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '5' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'officeName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarketplaceBidPackageSuggestionsQuery,
  MarketplaceBidPackageSuggestionsQueryVariables
>;
export const AvailableGlobalCertificateTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AvailableGlobalCertificateTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COUNTRY_CODE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableGlobalCertificateTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AvailableGlobalCertificateTypesQuery,
  AvailableGlobalCertificateTypesQueryVariables
>;
export const AgentCertificateTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentCertificateTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COUNTRY_CODE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentCertificateTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentCertificateTypesReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorTypeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentCertificateTypesQuery, AgentCertificateTypesQueryVariables>;
export const SelectCertificateTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SelectCertificateType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectCertificateType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectCertificateTypeMutation, SelectCertificateTypeMutationVariables>;
export const CreateAgentCompanyCertificateTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAgentCompanyCertificateType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAgentCompanyCertificateType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAgentCompanyCertificateTypeMutation,
  CreateAgentCompanyCertificateTypeMutationVariables
>;
export const SubcontractorCertificateOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorCertificateOverview' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentCertificateTypesReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorsByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmedByAgent' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateTypeReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpiryDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingCertificateRequestItemsForBidPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorCertificateOverviewQuery,
  SubcontractorCertificateOverviewQueryVariables
>;
export const CertificateRequestsForAgentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CertificateRequestsForAgentUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CERTIFICATE_REQUESTS_SORT_FIELD' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequestsForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificateRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcontractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateRequestItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CertificateRequestsForAgentUserQuery,
  CertificateRequestsForAgentUserQueryVariables
>;
export const AgentCertificatesViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentCertificatesView' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incompleteCertificateRequestsCountForAgent' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentCertificatesViewQuery, AgentCertificatesViewQueryVariables>;
export const AgentCompanyOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentCompanyOnboarding' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentCompanyOnboarding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardCompleteCompanyProfile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectsWithInvitesCount' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardCreateOwnRequest' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardDiscoverCosunoMarketplace' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectsInMarketplaceCount' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardInviteColleagues' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardSaveSearch' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardUploadCertificates' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardAddReferenceProject' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardSpecifyMainTrades' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AgentCompanyOnboardRespondToRequests' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCompletable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unansweredRequests' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentCompanyOnboardingQuery, AgentCompanyOnboardingQueryVariables>;
export const CompleteAgentCompanyOnboardingItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteAgentCompanyOnboardingItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AGENT_COMPANY_ONBOARDING_ITEM_TYPE' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeAgentCompanyOnboardItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteAgentCompanyOnboardingItemMutation,
  CompleteAgentCompanyOnboardingItemMutationVariables
>;
export const MarketplaceBidPackagesForPremiumPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketplaceBidPackagesForPremiumPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'geoCoords' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distanceInKm' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceBidPackagesForMap' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geoCoords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'geoCoords' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distanceInKm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distanceInKm' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'long' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarketplaceBidPackagesForPremiumPageQuery,
  MarketplaceBidPackagesForPremiumPageQueryVariables
>;
export const AgentPublicCertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentPublicCertificates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicNetworkSubcontractorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PublicNetworkSubcontractorProfile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateTypesReport' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CertificatesTable_CertificateTypeReport',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MergedIntoSubcontractor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateTypeReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpiryDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingCertificateRequestItemsForBidPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentPublicCertificatesQuery, AgentPublicCertificatesQueryVariables>;
export const BidRequestForBidInviteFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestForBidInviteFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidInviteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'office' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'company' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isClaimedByAgentCompany' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderAreaCountryCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workCategories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'language' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'language' },
                                        },
                                      },
                                    ],
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestForBidInviteFeedbackQuery,
  BidRequestForBidInviteFeedbackQueryVariables
>;
export const MarketplaceSamplesForBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketplaceSamplesForBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplaceSamples' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bidsDueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'officeLogoKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'officeName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarketplaceSamplesForBidRequestQuery,
  MarketplaceSamplesForBidRequestQueryVariables
>;
export const BidRequestForUpdateProfileUnauthenticatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestForUpdateProfileUnauthenticated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestForUpdateProfileUnauthenticatedQuery,
  BidRequestForUpdateProfileUnauthenticatedQueryVariables
>;
export const SubcontractorForUpdateProfileUnauthenticatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorForUpdateProfileUnauthenticated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicNetworkSubcontractorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PublicNetworkSubcontractorProfile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorForUpdateProfileUnauthenticatedQuery,
  SubcontractorForUpdateProfileUnauthenticatedQueryVariables
>;
export const ReportAgentCompanyClosedByBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportAgentCompanyClosedByBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportAgentCompanyClosed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportAgentCompanyClosedByBidRequestMutation,
  ReportAgentCompanyClosedByBidRequestMutationVariables
>;
export const ReportAgentCompanyClosedBySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportAgentCompanyClosedBySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportAgentCompanyClosedBySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modificationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportAgentCompanyClosedBySubcontractorMutation,
  ReportAgentCompanyClosedBySubcontractorMutationVariables
>;
export const RequestContractExtensionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestContractExtension' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestContractExtension' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestContractExtensionMutation,
  RequestContractExtensionMutationVariables
>;
export const CreatePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'loginToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaPhoneCensored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const CheckResetPasswordTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckResetPasswordToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkResetPasswordToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passwordPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PasswordPolicy' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PasswordPolicy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyPasswordPolicy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'minLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresLowerCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresUpperCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresSpecialCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckResetPasswordTokenQuery, CheckResetPasswordTokenQueryVariables>;
export const InvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Invitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inviter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssoEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isInvitationToWorkspace' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passwordPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PasswordPolicy' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPasswordUpdateRequired' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PasswordPolicy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyPasswordPolicy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'minLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresLowerCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresUpperCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresSpecialCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvitationQuery, InvitationQueryVariables>;
export const ActivateInviteeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateInvitee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateInvitee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivateInviteeMutation, ActivateInviteeMutationVariables>;
export const ConfirmInviteRequestByTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmInviteRequestByToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmInviteRequestByToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmInviteRequestByTokenMutation,
  ConfirmInviteRequestByTokenMutationVariables
>;
export const SubcontractorLoginSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorLoginSuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorLoginSuggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorLoginSuggestionsQuery,
  SubcontractorLoginSuggestionsQueryVariables
>;
export const ShowAwardedBidPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShowAwardedBidPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awardedBidId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'awardedBid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awardedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awardedSumType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainOrderNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billOfQuantities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AwardedBid_bidRequest' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AwardedBid_bidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShowAwardedBidPageQuery, ShowAwardedBidPageQueryVariables>;
export const AwardBidPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AwardBidPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AwardedBid_bidRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submittedBid' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'bidId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEligibleForAwarding' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AwardedBid_bidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardBidPageQuery, AwardBidPageQueryVariables>;
export const ValidateBidMainOrderNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateBidMainOrderNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mainOrderNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ignoreBidId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateBidMainOrderNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mainOrderNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mainOrderNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ignoreBidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ignoreBidId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'isValid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateBidMainOrderNumberQuery,
  ValidateBidMainOrderNumberQueryVariables
>;
export const UpdateAwardedBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAwardedBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAwardedBidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAwardedBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAwardedBidMutation, UpdateAwardedBidMutationVariables>;
export const AwardBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AwardBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AwardBidRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardBidRequestMutation, AwardBidRequestMutationVariables>;
export const ShowBidPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShowBidPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEligibleForAwarding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidSummary_bid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billOfQuantities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submittedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidSummary_bid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmittedBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShowBidPageQuery, ShowBidPageQueryVariables>;
export const SubmitBidPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubmitBidPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'CompanyConfig' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preferredBoQFileFormat' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftBid' },
            name: { kind: 'Name', value: 'sideEffectFreeDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrincipalSubmitBid_draftBid' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrincipalSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canNotifyBidderOnPrincipalSubmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitBidPageQuery, SubmitBidPageQueryVariables>;
export const GetOrCreateDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrincipalSubmitBid_draftBid' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrincipalSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canNotifyBidderOnPrincipalSubmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrCreateDraftBidMutation, GetOrCreateDraftBidMutationVariables>;
export const UpdateDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDraftBidMutation, UpdateDraftBidMutationVariables>;
export const SubmitBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PrincipalSubmitBidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitBidMutation, SubmitBidMutationVariables>;
export const PreviewSubmissionConfirmationEmailTemplatePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewSubmissionConfirmationEmailTemplatePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draftBidId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewBidConfirmationEmailAfterPrincipalSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draftBidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draftBidId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PreviewSubmissionConfirmationEmailTemplatePreviewQuery,
  PreviewSubmissionConfirmationEmailTemplatePreviewQueryVariables
>;
export const BidInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidInviteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'office' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidInviteQuery, BidInviteQueryVariables>;
export const TrackBidInviteViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackBidInviteView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackBidInviteView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidInviteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TrackBidInviteViewMutation, TrackBidInviteViewMutationVariables>;
export const BulkAddContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkAddContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BULK_ADD_CONTACTS_AMOUNT' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SUBCONTRACTORS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customInvitationEmailIntro' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipInvitation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkAddContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customBidsDueAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipInvitation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skipInvitation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkAddContactsMutation, BulkAddContactsMutationVariables>;
export const BidPackageForAddBiddersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForAddBidders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addedContactIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnContactIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customInvitationEmailIntro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networkAwaitingResponseCountIfRestricted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'long' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locationCountryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locationStateCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidInvite' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidRequest' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allCorrespondingWorkCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageForAddBiddersQuery, BidPackageForAddBiddersQueryVariables>;
export const BidPackageChangedBiddersNotificationPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageChangedBiddersNotificationPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackageInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageChangedBiddersNotificationPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageChangedBiddersNotificationPreviewQuery,
  BidPackageChangedBiddersNotificationPreviewQueryVariables
>;
export const OpenBlindTenderBidsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OpenBlindTenderBids' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openBlindTenderBids' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenBlindTenderBidsMutation, OpenBlindTenderBidsMutationVariables>;
export const WithdrawBidInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WithdrawBidInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawBidInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WithdrawBidInviteMutation, WithdrawBidInviteMutationVariables>;
export const SendInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customInvitationEmailIntro' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidInviteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendInvitationMutation, SendInvitationMutationVariables>;
export const BidRequestWithHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestWithHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historyItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BidRequestStatusChangeResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bidRequestStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'changedBy' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currentBid' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'billOfQuantities' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'editingStatus' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BidInviteStatusChangeResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contact' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'bidInviteStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SubmittedBidHistoryItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billOfQuantities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isBlindTender' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'submittedByUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SentEmailForStatusHistoryResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'templateName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contact' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sentEmail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'toEmail' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentHtml' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'templateProps' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TermsAcceptanceResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BlindTenderOpeningResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'users' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BidRequestCustomBidsDueAtChangeResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occurredAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestWithHistoryQuery, BidRequestWithHistoryQueryVariables>;
export const SendBidAwardingAndRejectionEmailsAfterBidRequestWasAwardedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendBidAwardingAndRejectionEmailsAfterBidRequestWasAwarded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendAwardingEmailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'sendBidAwardingAndRejectionEmailsAfterBidRequestWasAwarded',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendBidAwardingAndRejectionEmailsAfterBidRequestWasAwardedMutation,
  SendBidAwardingAndRejectionEmailsAfterBidRequestWasAwardedMutationVariables
>;
export const SendRejectionEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendRejectionEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendRejectionEmailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendBidRejectionEmailAfterBidRequestWasRejected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendRejectionEmailMutation, SendRejectionEmailMutationVariables>;
export const SubmitBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitBidRequestMutation, SubmitBidRequestMutationVariables>;
export const NegotiateBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NegotiateBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'negotiateBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NegotiateBidRequestMutation, NegotiateBidRequestMutationVariables>;
export const UnAwardBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnAwardBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newStatus' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unAwardBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newStatus' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnAwardBidRequestMutation, UnAwardBidRequestMutationVariables>;
export const RejectBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldSendNotification' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldSendNotification' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldSendNotification' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RejectBidRequestMutation, RejectBidRequestMutationVariables>;
export const updateBidRequestProposedAwardedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBidRequestProposedAwarded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestProposedAwarded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  updateBidRequestProposedAwardedMutation,
  updateBidRequestProposedAwardedMutationVariables
>;
export const RejectSomeBidRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectSomeBidRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldSendNotification' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectSomeBidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldSendNotification' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldSendNotification' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RejectSomeBidRequestsMutation, RejectSomeBidRequestsMutationVariables>;
export const UnRejectBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnRejectBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unRejectBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnRejectBidRequestMutation, UnRejectBidRequestMutationVariables>;
export const UpdateBidRequestStatusInBiddersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidRequestStatusInBidders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBidRequestStatusInBiddersMutation,
  UpdateBidRequestStatusInBiddersMutationVariables
>;
export const UpdateBidRequestStatusInBiddersWithReasonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidRequestStatusInBiddersWithReason' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'declinedReason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_DECLINED_REASON' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'declinedReasonOther' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'declinedReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'declinedReason' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'declinedReasonOther' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'declinedReasonOther' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBidRequestStatusInBiddersWithReasonMutation,
  UpdateBidRequestStatusInBiddersWithReasonMutationVariables
>;
export const UpdateSomeBidRequestStatusesInBiddersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSomeBidRequestStatusesInBidders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSomeBidRequestStatuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSomeBidRequestStatusesInBiddersMutation,
  UpdateSomeBidRequestStatusesInBiddersMutationVariables
>;
export const NegotiateSomeBidRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NegotiateSomeBidRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'negotiateSomeBidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NegotiateSomeBidRequestsMutation,
  NegotiateSomeBidRequestsMutationVariables
>;
export const DeleteBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBidMutation, DeleteBidMutationVariables>;
export const GetDownloadUrlForBidderStatusPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadUrlForBidderStatusPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForBidderStatusHistoryPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDownloadUrlForBidderStatusPdfQuery,
  GetDownloadUrlForBidderStatusPdfQueryVariables
>;
export const BidderDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidderDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'combinedCertificateTypesReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateTypeReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpiryDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingCertificateRequestItemsForBidPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidderDetailsQuery, BidderDetailsQueryVariables>;
export const ChangeBidRequestCustomBidsDueAtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeBidRequestCustomBidsDueAt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeBidRequestCustomBidsDueAt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customBidsDueAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeBidRequestCustomBidsDueAtMutation,
  ChangeBidRequestCustomBidsDueAtMutationVariables
>;
export const BidRequestsStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isProposedAwarded' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsStatusesQuery, BidRequestsStatusesQueryVariables>;
export const BidRequestsForBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BidsList_bidsList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showAwardedStatusToSubcontractor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wasSubcontractorNotifiedOfRejection' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notes_note' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidsEligibleForAwarding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'boqEditingStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasBoQ' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'awardedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'awardedSumType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainOrderNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'submittedByUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isProposedAwarded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contacts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ContactInfo_Contact' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidInvite' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo_Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsForBidPackageQuery, BidRequestsForBidPackageQueryVariables>;
export const GetDownloadUrlForBiddersListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadUrlForBiddersList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForBiddersListPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDownloadUrlForBiddersListQuery,
  GetDownloadUrlForBiddersListQueryVariables
>;
export const DeleteBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBidRequestMutation, DeleteBidRequestMutationVariables>;
export const ExportBidPackageBidsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportBidPackageBids' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportBids' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqFileType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportBidPackageBidsMutation, ExportBidPackageBidsMutationVariables>;
export const ResetContractBoQFileTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetContractBoQFileTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetContractBoQFileTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetContractBoQFileTreeMutation,
  ResetContractBoQFileTreeMutationVariables
>;
export const ToggleContractDocumentRequiringSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleContractDocumentRequiringSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleContractDocumentRequiringSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ToggleContractDocumentRequiringSignatureMutation,
  ToggleContractDocumentRequiringSignatureMutationVariables
>;
export const RemoveOutdatedDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveOutdatedDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeOutdatedDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveOutdatedDocumentsMutation,
  RemoveOutdatedDocumentsMutationVariables
>;
export const CreateSenderViewUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSenderViewUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDocuSignSenderViewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSenderViewUrlMutation, CreateSenderViewUrlMutationVariables>;
export const RenameContractFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameContractFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameContractFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameContractFileMutation, RenameContractFileMutationVariables>;
export const CreateContractBoQPDFFromUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContractBoQPDFFromUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPdfSettings' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContractBoQPDFFromUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateContractBoQPDFFromUploadMutation,
  CreateContractBoQPDFFromUploadMutationVariables
>;
export const GetDownloadURLForBoQContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadURLForBoQContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sourceBoQContractDocumentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForContractBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceBoQContractDocumentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sourceBoQContractDocumentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'exportType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDownloadURLForBoQContractQuery,
  GetDownloadURLForBoQContractQueryVariables
>;
export const GenerateContractBoQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateContractBoQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileType' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BOQ_FILE_TYPE_DOWNLOADABLE_FOR_CONTRACT' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPdfSettings' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateContractBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqFileType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateContractBoQMutation, GenerateContractBoQMutationVariables>;
export const UpdateContractRunStepUserIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContractRunStepUserIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractRunStepUserIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContractRunStepUserIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractRunStepUserIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contractRunStepUserIds' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContractRunStepUserIdsMutation,
  UpdateContractRunStepUserIdsMutationVariables
>;
export const UpdateContractRunAgentStepContactIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContractRunAgentStepContactIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContractRunAgentStepContactIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContractRunAgentStepContactIdsMutation,
  UpdateContractRunAgentStepContactIdsMutationVariables
>;
export const CreateSubcontractorPrivateContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubcontractorPrivateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubcontractorPrivateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSubcontractorPrivateContactMutation,
  CreateSubcontractorPrivateContactMutationVariables
>;
export const RequestContractsUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestContractsUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestContractsUpsell' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestContractsUpsellMutation,
  RequestContractsUpsellMutationVariables
>;
export const SubmitContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitContractMutation, SubmitContractMutationVariables>;
export const WithdrawContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WithdrawContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WithdrawContractMutation, WithdrawContractMutationVariables>;
export const AwardedBidForBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AwardedBidForBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBidForBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mainOrderNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentBid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidInvite' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contacts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submittedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AwardedBidForBidPackageQuery, AwardedBidForBidPackageQueryVariables>;
export const ContractUpdateAwardedBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ContractUpdateAwardedBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAwardedBidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAwardedBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ContractUpdateAwardedBidMutation,
  ContractUpdateAwardedBidMutationVariables
>;
export const UpdateIsContractSigningEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateIsContractSigningEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isContractSigningEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIsContractSigningEnabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isContractSigningEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isContractSigningEnabled' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateIsContractSigningEnabledMutation,
  UpdateIsContractSigningEnabledMutationVariables
>;
export const ProjectCostGroupCatalogsForCostComparisonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectCostGroupCatalogsForCostComparison' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectCostGroupCatalogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customCatalogName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectCostGroupCatalogsForCostComparisonQuery,
  ProjectCostGroupCatalogsForCostComparisonQueryVariables
>;
export const CostComparisonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CostComparison' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECT_COST_CATALOG_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costComparison' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CostComparisonCostGroupProps' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CostComparisonCostGroupProps' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CostComparisonCostGroupProps' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CostComparisonCostGroupProps',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'children' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'CostComparisonCostGroupProps',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CostComparisonItemProps' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BidForSubcontractorBidColumnHeader' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcontractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SubcontractorForSubcontractorBidColumnHeader',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boqHasElementsNotAssignedToCostGroups' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostComparisonItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostComparisonItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notFilledIn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostComparisonCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostComparisonCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostComparisonItemProps' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidForSubcontractorBidColumnHeader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubmittedOrGeneratedSubmittedBid' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorForSubcontractorBidColumnHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostComparisonQuery, CostComparisonQueryVariables>;
export const DownloadUrlForCostComparisonSpreadsheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForCostComparisonSpreadsheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECT_COST_CATALOG_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenBidders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'HiddenBidder' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForCostComparisonSpreadsheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenBidders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenBidders' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForCostComparisonSpreadsheetQuery,
  DownloadUrlForCostComparisonSpreadsheetQueryVariables
>;
export const ProjectDocumentsCountForBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectDocumentsCountForBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileTreeWithDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachable' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attachableType' },
                      value: { kind: 'EnumValue', value: 'Project' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attachableId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDocumentsCountForBidPackageQuery,
  ProjectDocumentsCountForBidPackageQueryVariables
>;
export const BidPackagesPublishedOnMarketplaceCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagesPublishedOnMarketplaceCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackagesPublishedOnMarketplaceCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackagesPublishedOnMarketplaceCountQuery,
  BidPackagesPublishedOnMarketplaceCountQueryVariables
>;
export const BidPackagePreparationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagePreparationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAttachedDocuments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBiddersFromCosunoNetwork' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasUnsavedBillOfQuantitiesChanges' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBillOfQuantitiesSchema' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageCompletionStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DescriptiveTenderSetup' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'descriptiveTenderSetupIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BidPackageDuration' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'bidPackageDurationIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BoQIncluded' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'boQIncludedIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DocumentsIncluded' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'documentsIncludedIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ContactDetailsIncluded' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'contactDetailsIncludedIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'OfficeDetailsIncluded' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'officeDetailsIncludedIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ReasonableBidDueDate' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'reasonableBidDueDateIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SufficientNumberOfBidders' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'sufficientNumberOfBiddersIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EnableCosunoMarketplace' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'enableCosunoMarketplaceIssue' },
                        name: { kind: 'Name', value: 'issue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackagePreparationStatusQuery,
  BidPackagePreparationStatusQueryVariables
>;
export const CompanyTemplatesForSelectorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyTemplatesForSelector' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TEMPLATE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '100' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyTemplatesForSelectorQuery,
  CompanyTemplatesForSelectorQueryVariables
>;
export const BidInviteDefaultIntroDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidInviteDefaultIntro' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInviteDefaultIntro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidInviteDefaultIntroQuery, BidInviteDefaultIntroQueryVariables>;
export const BidderIntroductionEmailDefaultContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidderIntroductionEmailDefaultContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBidderIntroductionTemplateContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidderIntroductionEmailDefaultContentQuery,
  BidderIntroductionEmailDefaultContentQueryVariables
>;
export const BidPackageForPublishingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForPublishing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAttachedDocuments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBiddersFromCosunoNetwork' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasUnsavedBillOfQuantitiesChanges' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBillOfQuantitiesSchema' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageForPublishingQuery, BidPackageForPublishingQueryVariables>;
export const PublishBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipInvitations' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customInvitationEmailIntro' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'introductionEmailRecipients' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendIntroductionEmails' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'introductionEmailContent' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipInvitations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skipInvitations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'introductionEmailRecipients' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'introductionEmailRecipients' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendIntroductionEmails' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sendIntroductionEmails' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'introductionEmailContent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'introductionEmailContent' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishBidPackageMutation, PublishBidPackageMutationVariables>;
export const BidderStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidderStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidderStatuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidderStatusesQuery, BidderStatusesQueryVariables>;
export const ProjectNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectNameQuery, ProjectNameQueryVariables>;
export const ProjectForBidPackageOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectForBidPackageOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailsForBidPackageOverview' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailsForBidPackageOverview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTermsAcceptanceRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishToMarketplaceForEstimation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForAwarding' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInviteReminderScheduleForAccepted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInviteReminderScheduleForNoResponse' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectForBidPackageOverviewQuery,
  ProjectForBidPackageOverviewQueryVariables
>;
export const BidPackageForShowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForShow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDocumentsRequiringSignature' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractDocumentsRequiringSignatureTotalSize' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequestsNotNotifiedOfRejection' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasContractDocuments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasContractDocumentsRequiringSignature' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasContractDocumentsRequiringSignatureToggling' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedContractBoQ' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDocuSignEnvelopeReset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDocuSignOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContractSignedByAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContractSignedByPrincipal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContractSignedDocumentPending' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContractSignedPerUpload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContractSigningEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLockedDueToPendingRequest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'addedContactIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnContactIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networkAwaitingResponseCountIfRestricted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'awardingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractAssignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalStepsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractAgentAssignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalStepsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractRunStepUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContractRunStepUser' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractRunAgentStepContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContractRunAgentStepContact' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costGroupCatalogs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBiddersFromCosunoNetwork' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAttachedDocuments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasUnsavedBillOfQuantitiesChanges' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBillOfQuantitiesSchema' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestForProposalBoQId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasBids' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasBoQ' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submittedBids' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'boqEditingStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasBoQ' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidInvite' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidRequest' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Bidders_bidPackage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageBoQEditorActions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'upload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractRunStepUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractRunAgentStepContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bidders_bidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customInvitationEmailIntro' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageForShowQuery, BidPackageForShowQueryVariables>;
export const BidPackageForOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BidPackageOverview_bidPackage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailsForBidPackageOverview' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageOverview_bidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'awardingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBidders' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidInviteReminderScheduleForAccepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInviteReminderScheduleForNoResponse' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedToMarketplaceAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBiddersDownloadBoQPdf' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPendingMultiUserApprovalRequest' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailsForBidPackageOverview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTermsAcceptanceRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishToMarketplaceForEstimation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForAwarding' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInviteReminderScheduleForAccepted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInviteReminderScheduleForNoResponse' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageForOverviewQuery, BidPackageForOverviewQueryVariables>;
export const BidRequestsForMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'subcontractorName' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeDeletedSubcontractors' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsForMessagesQuery, BidRequestsForMessagesQueryVariables>;
export const MessageThreadsInBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageThreadsInBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThreadsInBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThreads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Messages_messageThread' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThreadsList_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participatingCompanyOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondingCompanyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Messages_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThreadsList_messageThread' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageThreadsInBidPackageQuery,
  MessageThreadsInBidPackageQueryVariables
>;
export const FindSimilarBidPackageNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindSimilarBidPackageNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSimilarBidPackageNames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindSimilarBidPackageNamesQuery,
  FindSimilarBidPackageNamesQueryVariables
>;
export const GetBidPackageOverviewDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBidPackageOverviewDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForOverviewPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBidPackageOverviewDownloadUrlQuery,
  GetBidPackageOverviewDownloadUrlQueryVariables
>;
export const CreateBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBidPackageMutation, CreateBidPackageMutationVariables>;
export const UpdateBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackageInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notifyBidders' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notifyBidders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notifyBidders' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBidPackageMutation, UpdateBidPackageMutationVariables>;
export const BidPackageForAddedContactIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForAddedContactIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addedContactIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageForAddedContactIdsQuery,
  BidPackageForAddedContactIdsQueryVariables
>;
export const BidPackagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGE_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGES_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectPhase' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECT_PHASE' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlyIncludeOwnBidPackages' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlyIncludeAwaitingResponse' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeClosedProjects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectPhase' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectPhase' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyIncludeOwnBidPackages' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'onlyIncludeOwnBidPackages' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyIncludeAwaitingResponse' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'onlyIncludeAwaitingResponse' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awardingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVisibleOnMarketplace' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackagesQuery, BidPackagesQueryVariables>;
export const BidPackagesAwaitingResponseCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagesAwaitingResponseCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackagesAwaitingResponseCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackagesAwaitingResponseCountQuery,
  BidPackagesAwaitingResponseCountQueryVariables
>;
export const TasksForBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TasksForBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TASKS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'TASK_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withDoneCount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assigneeType' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'doneTasks' },
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'done' }] },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withDoneCount' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Task' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TasksForBidRequestQuery, TasksForBidRequestQueryVariables>;
export const PricePositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PricePositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PRICE_POSITIONS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'omitBidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'omitBidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'omitBidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricePositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unitTag' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'project' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PricePositionsQuery, PricePositionsQueryVariables>;
export const TotalAvailablePricePositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalAvailablePricePositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'omitBidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAvailablePricePositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'omitBidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'omitBidRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TotalAvailablePricePositionsQuery,
  TotalAvailablePricePositionsQueryVariables
>;
export const ClaimSubcontractorBySuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimSubcontractorBySuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimSubcontractorByMarketplaceSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'suggestionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClaimSubcontractorBySuggestionMutation,
  ClaimSubcontractorBySuggestionMutationVariables
>;
export const ClaimSubcontractorBySentEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimSubcontractorBySentEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimSubcontractorBySentEmailId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentEmailId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClaimSubcontractorBySentEmailMutation,
  ClaimSubcontractorBySentEmailMutationVariables
>;
export const BidPackageForReverseMarketplaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForReverseMarketplace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referrerUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reverseMarketplaceBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'suggestionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referrerUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referrerUserId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentEmailId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agentBidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LandingPageBidRequest' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notes_note' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgentStatusMutable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentStatusSetBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidsList_bidsList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'areDocumentsAvailable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageForReverseMarketplaceQuery,
  BidPackageForReverseMarketplaceQueryVariables
>;
export const BidRequestLandingPreviewBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestLandingPreviewBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestLandingPreviewBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agentBidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LandingPageBidRequest' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notes_note' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgentStatusMutable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentStatusSetBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidsList_bidsList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'areDocumentsAvailable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestLandingPreviewBidPackageQuery,
  BidRequestLandingPreviewBidPackageQueryVariables
>;
export const SubcontractorForSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorForSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorForSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'suggestionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorForSuggestionQuery,
  SubcontractorForSuggestionQueryVariables
>;
export const SubcontractorBySentEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorBySentEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorBySentEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentEmailId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorBySentEmailQuery, SubcontractorBySentEmailQueryVariables>;
export const BidRequestForSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestForSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notes_note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgentStatusMutable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentStatusSetBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidsList_bidsList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'areDocumentsAvailable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestForSubcontractorQuery,
  BidRequestForSubcontractorQueryVariables
>;
export const MessageThreadsInBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageThreadsInBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThreadsInBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThreads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Messages_messageThread' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThreadsList_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participatingCompanyOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondingCompanyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Messages_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThreadsList_messageThread' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageThreadsInBidRequestQuery,
  MessageThreadsInBidRequestQueryVariables
>;
export const GetOrCreateMarketplaceBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateMarketplaceBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateMarketplaceBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateMarketplaceBidRequestMutation,
  GetOrCreateMarketplaceBidRequestMutationVariables
>;
export const UpdateBidRequestStatusBySubcontractorAndReturnDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidRequestStatusBySubcontractorAndReturn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestStatusBySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidRequest' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgentStatusMutable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentStatusSetBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidsList_bidsList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'areDocumentsAvailable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBidRequestStatusBySubcontractorAndReturnMutation,
  UpdateBidRequestStatusBySubcontractorAndReturnMutationVariables
>;
export const UpdateBidRequestAgentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidRequestAgentStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BID_REQUEST_STATUS_FOR_AGENT' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestAgentStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidRequest' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidsList_bidsList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSubcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByAgentCompanyId' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bindingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContactPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isShareableWithBidders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidPackageProject' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageBidRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgentStatusMutable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentStatusSetBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidsList_bidsList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LandingPageSubcontractor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandingPageBidPackage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'areDocumentsAvailable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInKms' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBidRequestAgentStatusMutation,
  UpdateBidRequestAgentStatusMutationVariables
>;
export const GetOrCreateBidDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateBidDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warningMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeQuantityPositionsWithoutQuantitiesIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notFilledPositionsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasProposalInDocuments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elementsWithNotFilledBidderInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'elementId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidderInputLabel' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrCreateBidDraftMutation, GetOrCreateBidDraftMutationVariables>;
export const RequestProfileCompletionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestProfileCompletion' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestProfileCompletion' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestProfileCompletionMutation,
  RequestProfileCompletionMutationVariables
>;
export const NetworkSubcontractorsByAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkSubcontractorsByAgent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorsByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NetworkSubcontractorsByAgentQuery,
  NetworkSubcontractorsByAgentQueryVariables
>;
export const TrackMarketplaceImpressionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackMarketplaceImpression' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackMarketplaceImpression' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrackMarketplaceImpressionMutation,
  TrackMarketplaceImpressionMutationVariables
>;
export const AgentDeleteBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentDeleteBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentDeleteBidRequestMutation, AgentDeleteBidRequestMutationVariables>;
export const BidsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Bids' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BIDS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fetchOwnBids' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectPhase' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECT_PHASE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bids' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchOwnBids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fetchOwnBids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectPhase' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectPhase' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcontractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidPackage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'project' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidsQuery, BidsQueryVariables>;
export const ContactsWithNotificationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactsWithNotificationConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorForProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MergedIntoSubcontractor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Subcontractor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notificationConfig' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'marketplaceSuggestions' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'insuranceSuggestions' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ContactsWithNotificationConfigQuery,
  ContactsWithNotificationConfigQueryVariables
>;
export const UpdateContactNotificationConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContactNotificationConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ContactNotificationConfigInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContactNotificationConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceSuggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceSuggestions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContactNotificationConfigsMutation,
  UpdateContactNotificationConfigsMutationVariables
>;
export const IsCompanyWaitingForSignupApprovalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsCompanyWaitingForSignupApproval' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isCompanyWaitingForSignupApproval' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IsCompanyWaitingForSignupApprovalQuery,
  IsCompanyWaitingForSignupApprovalQueryVariables
>;
export const RequestCompanyBrandingUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCompanyBrandingUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestCompanyBrandingUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCompanyBrandingUpsellMutation,
  RequestCompanyBrandingUpsellMutationVariables
>;
export const UpdateCompanyBrandingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyBranding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyBrandingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyBranding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyBrandingMutation, UpdateCompanyBrandingMutationVariables>;
export const CertificateTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CertificateTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyCertificateTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateTypeModal_companyCertificateType' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateTypeModal_companyCertificateType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInUse' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateTypesQuery, CertificateTypesQueryVariables>;
export const DeleteCompanyCertificateTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCompanyCertificateType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCompanyCertificateType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCompanyCertificateTypeMutation,
  DeleteCompanyCertificateTypeMutationVariables
>;
export const CompanyTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TEMPLATE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TEMPLATE_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTemplatePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salutationMode' },
                value: { kind: 'EnumValue', value: 'editable' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyTemplatesQuery, CompanyTemplatesQueryVariables>;
export const CreateCompanyTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompanyTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyTemplateCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompanyTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCompanyTemplateMutation, CreateCompanyTemplateMutationVariables>;
export const UpdateCompanyTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyTemplateUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyTemplateMutation, UpdateCompanyTemplateMutationVariables>;
export const DeleteCompanyTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCompanyTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCompanyTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCompanyTemplateMutation, DeleteCompanyTemplateMutationVariables>;
export const CustomCostGroupCatalogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomCostGroupCatalogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CUSTOM_COST_GROUP_CATALOGS_SORT_FIELD' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCostGroupCatalogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customCostGroupCatalogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCatalogEditable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomCostGroupCatalogs_CostGroups' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomCostGroupCatalogs_CostGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomCostGroupDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childCostGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childCostGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomCostGroupCatalogsQuery, CustomCostGroupCatalogsQueryVariables>;
export const CustomCostGroupCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomCostGroupCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCostGroupCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCatalogEditable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomCostGroupCatalogs_CostGroups' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomCostGroupCatalogs_CostGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomCostGroupDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childCostGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childCostGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomCostGroupCatalogQuery, CustomCostGroupCatalogQueryVariables>;
export const UpdateCustomCostGroupCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomCostGroupCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomCostGroupCatalogInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomCostGroupCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomCostGroupCatalogMutation,
  UpdateCustomCostGroupCatalogMutationVariables
>;
export const CreateCustomCostGroupCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomCostGroupCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCostGroups' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'CustomCostGroupDefinitionInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomCostGroupCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCostGroups' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCostGroups' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomCostGroupCatalogMutation,
  CreateCustomCostGroupCatalogMutationVariables
>;
export const DuplicateCustomCostGroupCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateCustomCostGroupCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateCustomCostGroupCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateCustomCostGroupCatalogMutation,
  DuplicateCustomCostGroupCatalogMutationVariables
>;
export const CustomEmailStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomEmailState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customEmailState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customEmailEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customEmailValidation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'overallResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'senderStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dkimRecordName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dkimRecordValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dkimStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spfRecordValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spfStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownershipTokenRecordName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownershipToken' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomEmailStateQuery, CustomEmailStateQueryVariables>;
export const UpdateCustomEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomEmailMutation, UpdateCustomEmailMutationVariables>;
export const ValidateDNSDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidateDNS' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'validateDNS' } }],
      },
    },
  ],
} as unknown as DocumentNode<ValidateDNSMutation, ValidateDNSMutationVariables>;
export const EnableCustomEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableCustomEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enableCustomEmail' } }],
      },
    },
  ],
} as unknown as DocumentNode<EnableCustomEmailMutation, EnableCustomEmailMutationVariables>;
export const DisableCustomEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisableCustomEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'disableCustomEmail' } }],
      },
    },
  ],
} as unknown as DocumentNode<DisableCustomEmailMutation, DisableCustomEmailMutationVariables>;
export const CustomFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CUSTOM_FIELD_ENTITY_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomFieldsQuery, CustomFieldsQueryVariables>;
export const UpdateCustomFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CUSTOM_FIELD_ENTITY_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fields' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomFieldsMutation, UpdateCustomFieldsMutationVariables>;
export const CustomFieldsUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomFieldsUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldsUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomFieldsUsageQuery, CustomFieldsUsageQueryVariables>;
export const MultiUserApprovalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MultiUserApprovalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MULTI_USER_APPROVAL_ACTION' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multiUserApprovalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPendingRequests' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeLimitMinutes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'StandardUserRole' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CustomUserRole' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customUserRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultiUserApprovalRulesQuery, MultiUserApprovalRulesQueryVariables>;
export const UpdateMultiUserApprovalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMultiUserApprovalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MULTI_USER_APPROVAL_ACTION' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'MultiUserApprovalRuleInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMultiUserApprovalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMultiUserApprovalRulesMutation,
  UpdateMultiUserApprovalRulesMutationVariables
>;
export const MultiUserApprovalSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MultiUserApprovalSummary' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multiUserApprovalSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approveAddendum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approveInvoice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awardBid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openBids' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishBidPackage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendContract' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultiUserApprovalSummaryQuery, MultiUserApprovalSummaryQueryVariables>;
export const OfficesForIndexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfficesForIndex' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentTerms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfficesForIndexQuery, OfficesForIndexQueryVariables>;
export const DeleteOfficeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOffice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOffice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOfficeMutation, DeleteOfficeMutationVariables>;
export const DeactivateOfficeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeactivateOffice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deactivateOffice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeactivateOfficeMutation, DeactivateOfficeMutationVariables>;
export const ReactivateOfficeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReactivateOffice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivateOffice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReactivateOfficeMutation, ReactivateOfficeMutationVariables>;
export const PermissionsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PermissionsPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PermissionsPageUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customUserRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PermissionsPageUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StandardUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PermissionsPageQuery, PermissionsPageQueryVariables>;
export const RolePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RolePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'USER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PermissionsPageUser' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissionControlsConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PermissionControlsConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PermissionsPageUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StandardUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PermissionControlsConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PermissionControlsConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userAddEditDeactivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectsFromAccessibleOffices' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectsAllowDeletingWithNonDraftBidPackages' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCreateEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectInternalDataView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectCloseReopen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageCreateEditPublish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageInternalDataView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageModifyMarketplacePublishing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidderInviteManageBids' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidderDeleteBids' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pctView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pctExport' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqImportEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqTemplateCreateEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boqTemplateDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractPrepare' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportingView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySubcontractorsView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySubcontractorsExport' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorAddEdit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorBanFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorBidRequestsView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorNotesViewUpdateCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCertificatesView' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCertificatesRequest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCertificatesUpdateDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateTypesCreate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageProjectDocumentsViewDownload' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyDocumentsViewDownload' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoicesDeleteApprovedRejected' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RolePageQuery, RolePageQueryVariables>;
export const UpdatePermissionControlsConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePermissionControlsConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'USER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PermissionControlsConfigInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePermissionControlsConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'config' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePermissionControlsConfigMutation,
  UpdatePermissionControlsConfigMutationVariables
>;
export const DeleteCustomPermissionControlsConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomPermissionControlsConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomPermissionControlsConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomPermissionControlsConfigMutation,
  DeleteCustomPermissionControlsConfigMutationVariables
>;
export const UpdateCompanyRatingCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyRatingCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ratingCategories' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'RATING_CATEGORY' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyRatingCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ratingCategories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ratingCategories' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyRatingCategoriesMutation,
  UpdateCompanyRatingCategoriesMutationVariables
>;
export const UpdateCompanySubcontractorReminderSchedulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanySubcontractorReminderSchedules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'forAcceptedInvites' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BID_INVITE_REMINDER_SCHEDULE' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'forInvitesWithNoResponse' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BID_INVITE_REMINDER_SCHEDULE' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'forContracts' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CONTRACT_REMINDER_SCHEDULE' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanySubcontractorReminderSchedules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forAcceptedInvites' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'forAcceptedInvites' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forInvitesWithNoResponse' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'forInvitesWithNoResponse' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forContracts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'forContracts' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanySubcontractorReminderSchedulesMutation,
  UpdateCompanySubcontractorReminderSchedulesMutationVariables
>;
export const UpdateCompanyMfaConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyMfaConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mfaRequired' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyMfaConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mfaRequired' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mfaRequired' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyMfaConfigMutation,
  UpdateCompanyMfaConfigMutationVariables
>;
export const UpdateCompanySSOConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanySSOConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ssoRequired' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identityProviderEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'identityProviderTargetUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signingCertificate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ssoFilterDomains' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ssoDomains' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SSODomainInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanySSOConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ssoRequired' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ssoRequired' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identityProviderEntityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'identityProviderEntityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identityProviderTargetUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'identityProviderTargetUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signingCertificate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'signingCertificate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ssoFilterDomains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ssoFilterDomains' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ssoDomains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ssoDomains' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanySSOConfigMutation,
  UpdateCompanySSOConfigMutationVariables
>;
export const CreateSubcontractorImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubcontractorImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSubcontractorImportRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubcontractorImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSubcontractorImportRequestMutation,
  CreateSubcontractorImportRequestMutationVariables
>;
export const SubcontractorImportRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorImportRequests' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorImportRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorImportRequestsQuery,
  SubcontractorImportRequestsQueryVariables
>;
export const ExternalCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExternalCredentials' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getExternalCredentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExternalCredentialsQuery, ExternalCredentialsQueryVariables>;
export const UpsertExternalCredentialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertExternalCredential' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalCredentialInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertExternalCredential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalCredentialInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertExternalCredentialMutation,
  UpsertExternalCredentialMutationVariables
>;
export const DeleteExternalCredentialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteExternalCredential' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EXTERNAL_CREDENTIAL_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteExternalCredential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalCredentialType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteExternalCredentialMutation,
  DeleteExternalCredentialMutationVariables
>;
export const DeactivateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeactivateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deactivateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeactivateUserMutation, DeactivateUserMutationVariables>;
export const ReactivateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReactivateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReactivateUserMutation, ReactivateUserMutationVariables>;
export const ResendUserInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendUserInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendUserInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResendUserInviteMutation, ResendUserInviteMutationVariables>;
export const CancelInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelInviteMutation, CancelInviteMutationVariables>;
export const RestoreInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreInviteMutation, RestoreInviteMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'USER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customUserRoleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customUserRoleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customUserRoleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const ApproveJoinRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveJoinRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'STANDARD_USER_TYPE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveJoinRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveJoinRequestMutation, ApproveJoinRequestMutationVariables>;
export const DeleteJoinRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteJoinRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteJoinRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteJoinRequestMutation, DeleteJoinRequestMutationVariables>;
export const CompanyLicenseDataForEditModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyLicenseDataForEditModal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyLicenseData' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyLicenseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullUserLicenseCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestLicenseCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usedSeats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fullUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guests' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyLicenseDataForEditModalQuery,
  CompanyLicenseDataForEditModalQueryVariables
>;
export const ConfirmInviteRequestByInviteeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmInviteRequestByInviteeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inviteeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmInviteRequestByInviteeId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inviteeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inviteeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmInviteRequestByInviteeIdMutation,
  ConfirmInviteRequestByInviteeIdMutationVariables
>;
export const DeleteInviteRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInviteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInviteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteInviteRequestMutation, DeleteInviteRequestMutationVariables>;
export const DeleteFailedInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFailedInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFailedInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFailedInviteMutation, DeleteFailedInviteMutationVariables>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'USERS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'USER_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userRoles' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserRoleInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userRoles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userRoles' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'departmentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customDepartmentName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastActivityAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StandardUserRole' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CustomUserRole' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UsersTableDropdown_user' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersTableDropdown_user' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mfaLastResetAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StandardUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomUserRole' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const FiltersDataForUserDirectoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FiltersDataForUserDirectory' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customUserRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FiltersDataForUserDirectoryQuery,
  FiltersDataForUserDirectoryQueryVariables
>;
export const CompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Company' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyProfile_company' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidInviteReminderScheduleForAccepted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidInviteReminderScheduleForNoResponse' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contractReminderSchedule' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ratingCategories' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssoRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityProviderEntityId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityProviderTargetUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signingCertificate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssoFilterDomains' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ssoDomains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'serviceProviderEntityId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serviceProviderAcsUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssoNameId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssoDirectLoginUrl' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyLicenseData' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyProfile_company' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyLicenseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullUserLicenseCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestLicenseCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usedSeats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fullUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guests' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyQuery, CompanyQueryVariables>;
export const UpdateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CompanyOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyOnboarding' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyOnboarding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CompleteCompanyProfile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isFulfilled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'incompleteOfficeIds' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImportSubcontractors' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'isFulfilled' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InviteColleagues' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'isFulfilled' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyOnboardingQuery, CompanyOnboardingQueryVariables>;
export const CustomCostStructureSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomCostStructureSelect' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCostGroupCatalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customCostGroupCatalogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomCostStructureSelectQuery,
  CustomCostStructureSelectQueryVariables
>;
export const RequestGoldPlanOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestGoldPlanOffer' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestGoldPlanOffer' } }],
      },
    },
  ],
} as unknown as DocumentNode<RequestGoldPlanOfferMutation, RequestGoldPlanOfferMutationVariables>;
export const BoQTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_TEMPLATES_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fetchOwnBoQTemplates' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchOwnBoQTemplates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fetchOwnBoQTemplates' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecordsForCompany' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boqTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQTemplatesQuery, BoQTemplatesQueryVariables>;
export const CreateBoQTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBoQTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BoQTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBoQTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateBoQTemplateSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'template' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBoQTemplateMutation, CreateBoQTemplateMutationVariables>;
export const DeleteBoQTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBoQTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBoQTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBoQTemplateMutation, DeleteBoQTemplateMutationVariables>;
export const ConfirmTemplateBoQHasBeenUploadedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmTemplateBoQHasBeenUploaded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmTemplateBoQHasBeenUploaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmTemplateBoQHasBeenUploadedMutation,
  ConfirmTemplateBoQHasBeenUploadedMutationVariables
>;
export const ConfirmExcelTemplateBoQHasBeenUploadedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmExcelTemplateBoQHasBeenUploaded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExcelBoQImportOptions' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmExcelTemplateBoQHasBeenUploaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmExcelTemplateBoQHasBeenUploadedMutation,
  ConfirmExcelTemplateBoQHasBeenUploadedMutationVariables
>;
export const CreateInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateInvoiceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const BidPackageInvoiceInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageInvoiceInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'awardedBid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contacts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidInvite' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcontractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partialInvoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedTotalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cashDiscountValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialInvoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'retentionValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customRows' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isNegative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInvoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subcontractorIban' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subcontractorBic' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackageInvoiceInfoQuery, BidPackageInvoiceInfoQueryVariables>;
export const InvoiceNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvoiceNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notes_note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoiceNotesQuery, InvoiceNotesQueryVariables>;
export const CreateInvoiceNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInvoiceNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInvoiceNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInvoiceNoteMutation, CreateInvoiceNoteMutationVariables>;
export const UpdateInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateInvoiceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const InvoiceCoverSheetUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvoiceCoverSheetUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceCoverSheetUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoiceCoverSheetUrlQuery, InvoiceCoverSheetUrlQueryVariables>;
export const ForwardInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForwardInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forwardInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForwardInvoiceMutation, ForwardInvoiceMutationVariables>;
export const ApproveInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>;
export const RejectInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RejectInvoiceMutation, RejectInvoiceMutationVariables>;
export const InvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Invoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedTotalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cashDiscountDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cashDiscountValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nonApprovedTotalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberExternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partialInvoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'receiptDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retentionValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorBic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorCountryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorIban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorPhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorPostalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorStreet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customRows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNegative' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalStepsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userLastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statusChanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalStepsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoiceQuery, InvoiceQueryVariables>;
export const CreateInvoiceUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInvoiceUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInvoiceUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInvoiceUploadUrlMutation,
  CreateInvoiceUploadUrlMutationVariables
>;
export const MarketplaceSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketplaceSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceSuggestionQuery, MarketplaceSuggestionQueryVariables>;
export const MessageThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageThreads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showMessageThreadsFromAllProjects' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThreads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showMessageThreadsFromAllProjects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showMessageThreadsFromAllProjects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThreads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Messages_messageThread' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThreadsList_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participatingCompanyOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondingCompanyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Messages_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThreadsList_messageThread' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageThreadsQuery, MessageThreadsQueryVariables>;
export const BidPackagesForMessageThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagesForMessageThreads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGE_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'IntValue', value: '0' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'number' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeClosedProjects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackagesForMessageThreadsQuery,
  BidPackagesForMessageThreadsQueryVariables
>;
export const BidRequestsForCommonMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForCommonMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'subcontractorName' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeDeletedSubcontractors' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestsForCommonMessagesQuery,
  BidRequestsForCommonMessagesQueryVariables
>;
export const AgentBidRequestForMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentBidRequestForMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentBidRequestForMessagesQuery,
  AgentBidRequestForMessagesQueryVariables
>;
export const AgentBidRequestsForMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentBidRequestsForMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestsForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyActive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActive' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'IntValue', value: '0' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'subcontractorName' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentBidRequestsForMessagesQuery,
  AgentBidRequestsForMessagesQueryVariables
>;
export const OfficeForOfficeFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfficeForOfficeForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OfficeForm_office' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfficeForm_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplacePublishedBidPackagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalDisclosure' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForEstimation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishToMarketplaceForAwarding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUsImageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditreformLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeRegisterLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'foundingYear' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentTerms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfficeForOfficeFormQuery, OfficeForOfficeFormQueryVariables>;
export const CreateOfficeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOffice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfficeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOffice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOfficeMutation, CreateOfficeMutationVariables>;
export const UpdateOfficeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOffice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfficeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOffice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOfficeMutation, UpdateOfficeMutationVariables>;
export const CreateTermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTerms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTerms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTermsMutation, CreateTermsMutationVariables>;
export const RequestNDAUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestNDAUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestNDAUpsell' } }],
      },
    },
  ],
} as unknown as DocumentNode<RequestNDAUpsellMutation, RequestNDAUpsellMutationVariables>;
export const ExternalApiAccessKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExternalApiAccessKey' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalApiAccessKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExternalApiAccessKeyQuery, ExternalApiAccessKeyQueryVariables>;
export const UpdateCurrentUserNotificationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCurrentUserNotificationConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserNotificationConfigInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCurrentUserNotificationConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCurrentUserNotificationConfigMutation,
  UpdateCurrentUserNotificationConfigMutationVariables
>;
export const UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalCompany' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>;
export const UpdateCurrentUserLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCurrentUserLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCurrentUserLanguage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCurrentUserLanguageMutation,
  UpdateCurrentUserLanguageMutationVariables
>;
export const UpdatePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const RemoveMfaConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMfaConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'removeMfaConfig' } }],
      },
    },
  ],
} as unknown as DocumentNode<RemoveMfaConfigMutation, RemoveMfaConfigMutationVariables>;
export const PasswordPolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PasswordPolicy' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passwordPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PasswordPolicy' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PasswordPolicy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyPasswordPolicy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'minLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresLowerCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresUpperCaseCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresSpecialCharacter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordPolicyQuery, PasswordPolicyQueryVariables>;
export const RequestAdditionalLanguagesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestAdditionalLanguagesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestAdditionalLanguagesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestAdditionalLanguagesUpsellMutation,
  RequestAdditionalLanguagesUpsellMutationVariables
>;
export const UpdateMessagesSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMessagesSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomSignatureInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMessagesSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMessagesSignatureMutation,
  UpdateMessagesSignatureMutationVariables
>;
export const ProjectBidPackagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectBidPackages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGE_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGES_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPublishedOnMarketplace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVisibleOnMarketplace' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestStatusCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bidders' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'viewed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bids' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inviteDeclined' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectBidPackagesQuery, ProjectBidPackagesQueryVariables>;
export const CostEstimatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CostEstimates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COST_CATALOG_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costEstimatesForProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costEstimates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CostEstimationBidPackageEstimate' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidPackageCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'costItem' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'CostItemProps' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'draftCostItem' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'CostItemProps' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CostEstimationStandardCatalogEstimate' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'standardCatalogCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CostEstimationStandardCatalogCostGroupProps',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'childCostGroups' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CostEstimationStandardCatalogCostGroupProps',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'childCostGroups' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'CostEstimationStandardCatalogCostGroupProps',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CostEstimationCustomCatalogEstimate' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customCatalogCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CostEstimationCustomCatalogCostGroupProps',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'childCostGroups' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CostEstimationCustomCatalogCostGroupProps',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'childCostGroups' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'CostEstimationCustomCatalogCostGroupProps',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CostEstimationStlbWorkCategoryEstimate' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stlbWorkCategoryCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'costItem' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'CostItemProps' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'draftCostItem' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'CostItemProps' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostEstimationStandardCatalogCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostEstimationStandardCatalogCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftCostItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostEstimationCustomCatalogCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostEstimationCustomCatalogCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftCostItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostEstimatesQuery, CostEstimatesQueryVariables>;
export const UpdateCostEstimateForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCostEstimateForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costEstimateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costGroupLocalId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCostItemInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCostEstimateForProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costEstimateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'costEstimateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costGroupLocalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'costGroupLocalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateBidPackageEstimateForProjectResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackageCostGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidPackage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costItem' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CostItemProps' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftCostItem' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CostItemProps' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateStandardCatalogEstimateForProjectResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standardCatalogCostGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CostEstimationStandardCatalogCostGroupProps',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'childCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CostEstimationStandardCatalogCostGroupProps',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'childCostGroups' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CostEstimationStandardCatalogCostGroupProps',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateCustomCatalogEstimateForProjectResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customCatalogCostGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CostEstimationCustomCatalogCostGroupProps',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'childCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CostEstimationCustomCatalogCostGroupProps',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'childCostGroups' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CostEstimationCustomCatalogCostGroupProps',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateStlbWorkCategoryEstimateForProjectResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnsavedChanges' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stlbWorkCategoryCostGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costItem' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CostItemProps' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftCostItem' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CostItemProps' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostEstimationStandardCatalogCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostEstimationStandardCatalogCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftCostItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostEstimationCustomCatalogCostGroupProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CostEstimationCustomCatalogCostGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftCostItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCostEstimateForProjectMutation,
  UpdateCostEstimateForProjectMutationVariables
>;
export const SaveCostEstimateForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveCostEstimateForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costEstimateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveCostEstimateForProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costEstimateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'costEstimateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveCostEstimateForProjectMutation,
  SaveCostEstimateForProjectMutationVariables
>;
export const DiscardCostEstimateForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardCostEstimateForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costEstimateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardCostEstimateForProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costEstimateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'costEstimateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscardCostEstimateForProjectMutation,
  DiscardCostEstimateForProjectMutationVariables
>;
export const DownloadUrlForCostEstimationSpreadsheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForCostEstimationSpreadsheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COST_CATALOG_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForCostEstimationSpreadsheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForCostEstimationSpreadsheetQuery,
  DownloadUrlForCostEstimationSpreadsheetQueryVariables
>;
export const QueryValidateCostAnalysisForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryValidateCostAnalysisForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COST_CATALOG_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateCostAnalysisForProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMissingBoqVAT' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMissingBimVAT' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMissingEstimationVAT' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasUnassignedBimCostGroups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasUnassignedBoqCostGroups' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QueryValidateCostAnalysisForProjectQuery,
  QueryValidateCostAnalysisForProjectQueryVariables
>;
export const DownloadUrlForCostAnalysisSpreadsheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForCostAnalysisSpreadsheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'breakdown' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COST_ANALYSIS_BREAKDOWN' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topLevelGrouping' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'COST_ANALYSIS_TOP_LEVEL_GROUPING' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForCostAnalysisSpreadsheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'breakdown' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'breakdown' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topLevelGrouping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'topLevelGrouping' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForCostAnalysisSpreadsheetQuery,
  DownloadUrlForCostAnalysisSpreadsheetQueryVariables
>;
export const CostAnalysisDefaultBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CostAnalysisDefaultBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultCostAnalysisBreakdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'topLevelGrouping' } },
                { kind: 'Field', name: { kind: 'Name', value: 'breakdown' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CostAnalysisDefaultBreakdownQuery,
  CostAnalysisDefaultBreakdownQueryVariables
>;
export const ProjectReportingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectReporting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'breakdown' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COST_ANALYSIS_BREAKDOWN' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topLevelGrouping' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'COST_ANALYSIS_TOP_LEVEL_GROUPING' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeEstimatedBudget' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCostEstimate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCostCalculation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAwarded' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAddenda' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeActual' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidPackagesCurrencies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidPackagesTotalBudget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costAnalysis' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topLevelGrouping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'topLevelGrouping' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'breakdown' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'breakdown' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customCatalogId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customCatalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CostAnalysisCostGroupProps' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CostAnalysisCostGroupProps' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CostAnalysisCostGroupProps' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CostAnalysisCostGroupProps',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'children' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'CostAnalysisCostGroupProps',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostItemProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostAnalysisCostGroupProps' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CostAnalysisCostGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentLocalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costItemId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costItem' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostItemProps' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'estimatedBudgetNet' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeEstimatedBudget' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costEstimateCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeCostEstimate' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeCostCalculation' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardedBidCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeAwarded' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addendumCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeAddenda' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actualCost' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeActual' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectReportingQuery, ProjectReportingQueryVariables>;
export const ProjectCostGroupCatalogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectCostGroupCatalogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectCostGroupCatalogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customCatalogName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectCostGroupCatalogsQuery, ProjectCostGroupCatalogsQueryVariables>;
export const ParsedIfcFileWithAssociatedCostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ParsedIfcFileWithAssociatedCosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsedIfcFileWithAssociatedCosts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskProcessingState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Node' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currency' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Cost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeAssociatedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'costGroupLocalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityFormula' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuantity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeBaseQuantity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Node' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ParsedIfcFileNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Cost' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseQuantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParsedIfcFileWithAssociatedCostsQuery,
  ParsedIfcFileWithAssociatedCostsQueryVariables
>;
export const UpdateParsedIfcFileWithAssociatedCostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParsedIfcFileWithAssociatedCosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nodeLocalId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateParsedIfcFileNodeAssociatedCost' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateParsedIfcFileWithAssociatedCosts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nodeLocalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nodeLocalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affectedNodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Node' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Cost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeAssociatedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'costGroupLocalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityFormula' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseQuantity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ParsedIfcFileNodeBaseQuantity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Node' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ParsedIfcFileNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Cost' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseQuantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateParsedIfcFileWithAssociatedCostsMutation,
  UpdateParsedIfcFileWithAssociatedCostsMutationVariables
>;
export const ParsedIfcFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ParsedIfcFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costGroupType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsedIfcFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskProcessingState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParsedIfcFileQuery, ParsedIfcFileQueryVariables>;
export const ProjectChangedBiddersNotificationPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectChangedBiddersNotificationPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectChangedBiddersNotificationPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectChangedBiddersNotificationPreviewQuery,
  ProjectChangedBiddersNotificationPreviewQueryVariables
>;
export const ProjectForShowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectForShow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costGroupType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectForShowQuery, ProjectForShowQueryVariables>;
export const ProjectOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectOverview_project' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectOverview_office' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOverview_project' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerWebsite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTaxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplacePublishedBidPackagesCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPendingMultiUserApprovalRequest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isContactPerson' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingRunStepUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requireAcceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costGroupCatalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customCatalog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'desiredSustainabilityCertification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOverview_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectOverviewQuery, ProjectOverviewQueryVariables>;
export const OfficesForProjectOverviewCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfficesForProjectOverviewCreate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectOverview_office' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOverview_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptingTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OfficesForProjectOverviewCreateQuery,
  OfficesForProjectOverviewCreateQueryVariables
>;
export const ProjectDefaultTaxRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectDefaultTaxRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultTaxRate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDefaultTaxRateQuery, ProjectDefaultTaxRateQueryVariables>;
export const ProjectsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECTS_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectsCountQuery, ProjectsCountQueryVariables>;
export const CloseProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CloseProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECT_CLOSE_EMAIL_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldNotifyBidders' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldNotifyBidders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldNotifyBidders' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CloseProjectMutation, CloseProjectMutationVariables>;
export const ReopenProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReopenProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reopenProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReopenProjectMutation, ReopenProjectMutationVariables>;
export const UpdateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notifyBidders' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notifyBidders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notifyBidders' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectTemplateInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectTemplateInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectTemplateInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectTemplateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>;
export const DownloadUrlForProjectOverviewPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForProjectOverviewPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForProjectOverviewPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForProjectOverviewPdfQuery,
  DownloadUrlForProjectOverviewPdfQueryVariables
>;
export const SubcontractorRatingsForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorRatingsForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectRatingsPerBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidRequestRating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ratingCategories' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestRating' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubcontractorRatingPerBidRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratingByCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userRatedCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratingCategories' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorRatingsForProjectQuery,
  SubcontractorRatingsForProjectQueryVariables
>;
export const ProjectBidRequestsToNotifyOfDeletionCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectBidRequestsToNotifyOfDeletionCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequestsToNotifyOfDeletionCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectBidRequestsToNotifyOfDeletionCountQuery,
  ProjectBidRequestsToNotifyOfDeletionCountQueryVariables
>;
export const ProjectClosingConditionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectClosingConditions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'closingConditions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mustNotifyBidders' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canSendNotification' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shouldWarnAboutUnawardedBidders' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectClosingConditionsQuery, ProjectClosingConditionsQueryVariables>;
export const OfficesForProjectListFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfficesForProjectListFilter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OfficesForProjectListFilterQuery,
  OfficesForProjectListFilterQueryVariables
>;
export const ProjectCustomFieldsWithTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectCustomFieldsWithTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'CUSTOM_FIELD_TYPE' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'types' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'project' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectCustomFieldsWithTypesQuery,
  ProjectCustomFieldsWithTypesQueryVariables
>;
export const RequestMarketplaceUsageReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestMarketplaceUsageReport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestMarketplaceUsageReport' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestMarketplaceUsageReportMutation,
  RequestMarketplaceUsageReportMutationVariables
>;
export const ProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Projects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECTS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortById' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fetchAllOfficeProjects' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phase' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECT_PHASE' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'primaryUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromStartDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toStartDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromEndDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toEndDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromCreationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toCreationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCustomer' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeManager' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCreatedAt' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeStartDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeEndDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeOffice' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCustomFields' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCustomFieldIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortById' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortById' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchAllOfficeProjects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fetchAllOfficeProjects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phase' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phase' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'primaryUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'primaryUserId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromStartDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromStartDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toStartDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toStartDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromEndDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromEndDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toEndDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toEndDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromCreationDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromCreationDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toCreationDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toCreationDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeCustomer' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primaryUser' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeManager' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsOn' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeStartDate' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsOn' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeEndDate' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeOffice' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeCreatedAt' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFields' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'ids' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'includeCustomFieldIds' },
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeCustomFields' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CustomFieldWithValue' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAnyOpenProjects' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeCustomFieldIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'EnumValue', value: 'project' },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeCustomFields' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectsQuery, ProjectsQueryVariables>;
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const DeleteMarketplaceSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMarketplaceSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMarketplaceSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'savedSearchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMarketplaceSearchMutation,
  DeleteMarketplaceSearchMutationVariables
>;
export const UpdateMarketplaceSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketplaceSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isNotificationEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMarketplaceSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isNotificationEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isNotificationEnabled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isNotificationEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMarketplaceSavedSearchMutation,
  UpdateMarketplaceSavedSearchMutationVariables
>;
export const SaveMarketplaceSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveMarketplaceSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarketplaceSavedSearchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveMarketplaceSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveMarketplaceSearchMutation, SaveMarketplaceSearchMutationVariables>;
export const MarketplaceSavedSearchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketplaceSavedSearches' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceSavedSearches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isNotificationEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geoCoords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortBy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'distanceInKm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDateInMinDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showExpiredTenders' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceSavedSearchesQuery, MarketplaceSavedSearchesQueryVariables>;
export const ReverseMarketplaceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReverseMarketplaceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'calculateDefaultFilters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'BID_PACKAGES_MARKETPLACE_SORT_FIELD' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'geoCoords' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distanceInKm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startsOn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endsOn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workCategoryIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkCategoryIds' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeWorkCategoryIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkCategoryIds' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dueDateInMinDays' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageListForMarketplace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'calculateDefaultFilters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'calculateDefaultFilters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geoCoords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'geoCoords' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distanceInKm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distanceInKm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startsOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startsOn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endsOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endsOn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workCategoryIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workCategoryIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeWorkCategoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeWorkCategoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dueDateInMinDays' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dueDateInMinDays' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allowBidsAfterDueDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'distanceInKm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedToMarketplaceAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'queryMatchesBoQ' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geoCoords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distanceInKm' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workCategoryList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recentBidPackagesMatchingFiltersCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReverseMarketplaceListQuery, ReverseMarketplaceListQueryVariables>;
export const SubcontractorBidBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorBidBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowBidsAfterDueDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reasonForClosing' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createdByAgentCompanyId' },
                                        },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'CompanyConfig' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preferredBoQFileFormat' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorBidBidRequestQuery,
  SubcontractorBidBidRequestQueryVariables
>;
export const SubcontractorBidDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorBidDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftBid' },
            name: { kind: 'Name', value: 'sideEffectFreeDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warningMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeQuantityPositionsWithoutQuantitiesIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notFilledPositionsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasProposalInDocuments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elementsWithNotFilledBidderInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'elementId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidderInputLabel' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorBidDraftBidQuery, SubcontractorBidDraftBidQueryVariables>;
export const SubcontractorBidBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorBidBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorBidBidQuery, SubcontractorBidBidQueryVariables>;
export const SubcontractorBidUpdateDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubcontractorBidUpdateDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorBidUpdateDraftBidMutation,
  SubcontractorBidUpdateDraftBidMutationVariables
>;
export const SubcontractorBidGetOrCreateDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubcontractorBidGetOrCreateDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentSubmitBid_draftBid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftBid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warningMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeQuantityPositionsWithoutQuantitiesIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notFilledPositionsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasProposalInDocuments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'elementsWithNotFilledBidderInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'elementId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidderInputLabel' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorBidGetOrCreateDraftBidMutation,
  SubcontractorBidGetOrCreateDraftBidMutationVariables
>;
export const SubcontractorBidSubmitBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubcontractorBidSubmitBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorSubmitBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorBidSubmitBidMutation,
  SubcontractorBidSubmitBidMutationVariables
>;
export const ClaimAnonymousDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimAnonymousDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimAnonymousDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClaimAnonymousDraftBidMutation,
  ClaimAnonymousDraftBidMutationVariables
>;
export const SubcontractorsByAgentForPostSubmitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorsByAgentForPostSubmit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorsByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldPromptWorkCategoriesUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorsByAgentForPostSubmitQuery,
  SubcontractorsByAgentForPostSubmitQueryVariables
>;
export const DiscardDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draftBidId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draftBidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draftBidId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscardDraftBidMutation, DiscardDraftBidMutationVariables>;
export const SubcontractorReferenceProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorReferenceProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorReferenceProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorReferenceProjectQuery,
  SubcontractorReferenceProjectQueryVariables
>;
export const BidRequestsAgentForTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestsForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsAgentForTaskQuery, BidRequestsAgentForTaskQueryVariables>;
export const BidRequestForTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestForTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestForTaskQuery, BidRequestForTaskQueryVariables>;
export const BidRequestsForTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeDeletedSubcontractors' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchByContactNames' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BidRequestsPrincipalForTask' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsPrincipalForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsForTaskQuery, BidRequestsForTaskQueryVariables>;
export const TaskNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TaskNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskNotesQuery, TaskNotesQueryVariables>;
export const AddTaskNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTaskNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTaskNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTaskNoteMutation, AddTaskNoteMutationVariables>;
export const DeleteTaskNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTaskNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTaskNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaskNoteMutation, DeleteTaskNoteMutationVariables>;
export const ProjectsForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectsForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECTS_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectsForSelectQuery, ProjectsForSelectQueryVariables>;
export const SelectedProjectForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectedProjectForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectedProjectForSelectQuery, SelectedProjectForSelectQueryVariables>;
export const TaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Task' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigneeType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserForTask' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BidRequestsPrincipalForTask' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsPrincipalForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestsAgentForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskQuery, TaskQueryVariables>;
export const TaskPublicDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TaskPublicData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskPublicData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidPackageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidRequestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskPublicDataQuery, TaskPublicDataQueryVariables>;
export const UpdateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const CreateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>;
export const AllUsersForTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllUsersForTask' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserForTask' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUsersForTaskQuery, AllUsersForTaskQueryVariables>;
export const UsersWithAccessToProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UsersWithAccessToProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersWithAccessToProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserForTask' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersWithAccessToProjectQuery, UsersWithAccessToProjectQueryVariables>;
export const UsersWithAccessToBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UsersWithAccessToBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersWithAccessToBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserForTask' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserForTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsersWithAccessToBidPackageQuery,
  UsersWithAccessToBidPackageQueryVariables
>;
export const TermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Terms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsForBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'downloadUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openInNewTabUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TermsQuery, TermsQueryVariables>;
export const AcceptTermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptTerms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'termsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptTerms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'termsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'termsId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptTermsMutation, AcceptTermsMutationVariables>;
export const AgentSignupForCertificatesSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignupForCertificatesSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupForCertificatesSubmissionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupForCertificatesSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSignupForCertificatesSubmissionMutation,
  AgentSignupForCertificatesSubmissionMutationVariables
>;
export const UploadCertificatesViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UploadCertificatesView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'office' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateModal_Office' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateModal_CertificateRequest' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'statusForAgent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isClaimedByAgentCompany' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificateRequestItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CertificateModal_CertificateRequestItem' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withdrawn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canSaveInProfile' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CertificateModal_CertificateType' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isExpiryDateMandatory' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'certificateRequestCertificates' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'subcontractorId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subcontractorId' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'certificateRequestId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'certificateRequestId' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateDocument' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_Office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_CertificateRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_CertificateRequestItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canSaveInProfile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateModal_CertificateType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadCertificatesViewQuery, UploadCertificatesViewQueryVariables>;
export const RejectCertificateRequestItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectCertificateRequestItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateRequestItemId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rejectionReason' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectCertificateRequestItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateRequestItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'certificateRequestItemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rejectionReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rejectionReason' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectCertificateRequestItemMutation,
  RejectCertificateRequestItemMutationVariables
>;
export const BoQTemplateForTextProviderModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTemplateForTextProviderModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'boqFileFormat' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoQTemplateForTextProviderModalQuery,
  BoQTemplateForTextProviderModalQueryVariables
>;
export const BoqTemplatesForTextProviderModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoqTemplatesForTextProviderModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileFormat' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_FORMAT' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqFileFormat' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileFormat' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchOwnBoQTemplates' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boqTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costGroupCatalogs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customCatalog' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoqTemplatesForTextProviderModalQuery,
  BoqTemplatesForTextProviderModalQueryVariables
>;
export const GetHeinzeAtBoQElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHeinzeAtBoQElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heinzeAtBoQElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHeinzeAtBoQElementsQuery, GetHeinzeAtBoQElementsQueryVariables>;
export const GetHeinzeAtGuiUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHeinzeAtGuiUrl' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heinzeAtGuiUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHeinzeAtGuiUrlQuery, GetHeinzeAtGuiUrlQueryVariables>;
export const GetHeinzeVobBoQElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHeinzeVobBoQElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heinzeVobBoQElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHeinzeVobBoQElementsQuery, GetHeinzeVobBoQElementsQueryVariables>;
export const GetHeinzeVobGuiUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHeinzeVobGuiUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceAnonymous' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heinzeVobGuiUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forceAnonymous' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'forceAnonymous' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHeinzeVobGuiUrlQuery, GetHeinzeVobGuiUrlQueryVariables>;
export const lBHochbauTextProviderElementsTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'lBHochbauTextProviderElementsTree' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lBHochbauTextProviderElementsTree' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lbHochbauBoQElements' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  lBHochbauTextProviderElementsTreeQuery,
  lBHochbauTextProviderElementsTreeQueryVariables
>;
export const RefreshStlbSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshStlbSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oldSessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshStlbSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldSessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oldSessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorizationFailed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshStlbSessionQuery, RefreshStlbSessionQueryVariables>;
export const StlbHomeUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StlbHomeUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stlbHomeUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StlbHomeUrlQuery, StlbHomeUrlQueryVariables>;
export const DataFromStlbDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataFromStlb' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stlbGetData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costGroupId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'standardizedDescription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'standardReferenceType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stlbReference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogueName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'versionDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'keys' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'artIdentifier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'artIndex' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kindIdentifier' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataFromStlbQuery, DataFromStlbQueryVariables>;
export const BoQTemplatesForBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTemplatesForBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_TEMPLATES_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileFormat' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_FORMAT' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqFileFormat' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileFormat' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchOwnBoQTemplates' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecordsForCompany' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boqTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoQTemplatesForBidPackageQuery,
  BoQTemplatesForBidPackageQueryVariables
>;
export const BoQTemplateForImportModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTemplateForImportModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'boqFileFormat' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costGroupCatalogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customCatalog' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoQTemplateForImportModalQuery,
  BoQTemplateForImportModalQueryVariables
>;
export const UseTemplateBoQInBidPackageImportModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UseTemplateBoQInBidPackageImportModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceBoQTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationBidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fieldsToImport' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TemplateBoQFieldsToImportInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'costGroupCatalogsToImport' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TemplateBoQCostGroupCatalogsToImportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'useTemplateBoQInBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceBoQTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceBoQTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationBidPackageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destinationBidPackageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fieldsToImport' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fieldsToImport' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costGroupCatalogsToImport' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'costGroupCatalogsToImport' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseTemplateBoQInBidPackageImportModalMutation,
  UseTemplateBoQInBidPackageImportModalMutationVariables
>;
export const ReuseBidPackageBoQInBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReuseBidPackageBoQInBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceBidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationBidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceBidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceBidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationBidPackageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destinationBidPackageId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReuseBidPackageBoQInBidPackageMutation,
  ReuseBidPackageBoQInBidPackageMutationVariables
>;
export const ConfirmExcelRequestForProposalBoQUploadedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmExcelRequestForProposalBoQUploaded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExcelBoQImportOptions' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmExcelRequestForProposalBoQUploaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmExcelRequestForProposalBoQUploadedMutation,
  ConfirmExcelRequestForProposalBoQUploadedMutationVariables
>;
export const BoQChangedNotificationDefaultTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQChangedNotificationDefaultTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqChangedNotificationDefaultTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoQChangedNotificationDefaultTemplateQuery,
  BoQChangedNotificationDefaultTemplateQueryVariables
>;
export const BoQChangedNotificationPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQChangedNotificationPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqChangedNotificationPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoQChangedNotificationPreviewQuery,
  BoQChangedNotificationPreviewQueryVariables
>;
export const BillOfQuantitiesForBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesForBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BoQ' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasUnsavedBillOfQuantitiesChanges' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'boqPositionTextProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unsavedBillOfQuantitiesAddedElementsIds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQ' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForBidPackageQuery,
  BillOfQuantitiesForBidPackageQueryVariables
>;
export const BillOfQuantitiesForBidPackageEditorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForBidPackageEditor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesForEditing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'largestTextAdditionLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'massDeterminationRowIdsNotToKeep' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'massDeterminations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'boqRowId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'rowsProp' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredFileFormat' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'baseRow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AbstractMassDeterminationRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rowsProp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MassDetermination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rows' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value5' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCustomFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expression' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCommentRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForBidPackageEditorQuery,
  BillOfQuantitiesForBidPackageEditorQueryVariables
>;
export const BillOfQuantitiesForSubmittedBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForSubmittedBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BoQ' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preferredBoQFileFormat' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'costGroupCatalogs' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'catalogType' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'customCatalogId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'customCatalogName' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQ' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForSubmittedBidQuery,
  BillOfQuantitiesForSubmittedBidQueryVariables
>;
export const BillOfQuantitiesForDraftBidPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForDraftBidPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referrerUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reverseMarketplaceBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'suggestionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referrerUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referrerUserId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentEmailId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sentEmailId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'detachedProposalBillOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BoQ' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesForBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQ' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForDraftBidPreviewQuery,
  BillOfQuantitiesForDraftBidPreviewQueryVariables
>;
export const BillOfQuantitiesForDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftBid' },
            name: { kind: 'Name', value: 'sideEffectFreeDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BoQWithoutContent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesForBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'changedElementIds' },
            name: { kind: 'Name', value: 'changedElementIdsSinceLatestSubmittedBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQWithoutContent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForDraftBidQuery,
  BillOfQuantitiesForDraftBidQueryVariables
>;
export const BillOfQuantitiesWithContentForDraftBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesWithContentForDraftBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isUserPrincipal' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftBid' },
            name: { kind: 'Name', value: 'sideEffectFreeDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasOutdatedBoQ' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BoQ' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isUserPrincipal' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costGroupCatalogs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customCatalogName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesForBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'changedElementIds' },
            name: { kind: 'Name', value: 'changedElementIdsSinceLatestSubmittedBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQ' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesWithContentForDraftBidQuery,
  BillOfQuantitiesWithContentForDraftBidQueryVariables
>;
export const UpdateBillOfQuantitiesCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBillOfQuantitiesComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillOfQuantitiesComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBillOfQuantitiesCommentMutation,
  UpdateBillOfQuantitiesCommentMutationVariables
>;
export const DiscardDraftBoQInBoQTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardDraftBoQInBoQTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardDraftBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscardDraftBoQInBoQTableMutation,
  DiscardDraftBoQInBoQTableMutationVariables
>;
export const GetBoQEditorPreSaveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetBoQEditorPreSaveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBoQEditorPreSaveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasFormalReviewErrors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNotifiableChanges' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBoQEditorPreSaveStateMutation,
  GetBoQEditorPreSaveStateMutationVariables
>;
export const ConfirmRequestForProposalBoqUploadedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmRequestForProposalBoqUploaded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmRequestForProposalBoqUploaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmRequestForProposalBoqUploadedMutation,
  ConfirmRequestForProposalBoqUploadedMutationVariables
>;
export const ConfirmProposalBoQUploadedByPrincipalUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmProposalBoQUploadedByPrincipalUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmProposalBoQUploadedByPrincipalUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'netTotal' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmProposalBoQUploadedByPrincipalUserMutation,
  ConfirmProposalBoQUploadedByPrincipalUserMutationVariables
>;
export const ConfirmProposalBoQUploadedBySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmProposalBoQUploadedBySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmProposalBoQUploadedBySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricePredictions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'positionId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'reason' },
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'prediction' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'netTotal' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmProposalBoQUploadedBySubcontractorMutation,
  ConfirmProposalBoQUploadedBySubcontractorMutationVariables
>;
export const BillOfQuantitiesForBoQTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForBoQTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCostGroupCatalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customCostGroupCatalogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplateBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BoQ' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasUnsavedBillOfQuantitiesChanges' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costGroupCatalogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customCatalogId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customCatalogName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoQ' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillOfQuantities' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForBoQTemplateQuery,
  BillOfQuantitiesForBoQTemplateQueryVariables
>;
export const BillOfQuantitiesForBoQTemplateEditorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesForBoQTemplateEditor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'boqFileFormat' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplateBoQForEditing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'largestTextAdditionLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'massDeterminationRowIdsNotToKeep' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'massDeterminations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'boqRowId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'rowsProp' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'baseRow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AbstractMassDeterminationRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rowsProp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MassDetermination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rows' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value5' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCustomFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expression' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCommentRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesForBoQTemplateEditorQuery,
  BillOfQuantitiesForBoQTemplateEditorQueryVariables
>;
export const GetBoQTemplateBoQEditorPreSaveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetBoQTemplateBoQEditorPreSaveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBoQTemplateBoQEditorPreSaveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasFormalReviewErrors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBoQTemplateBoQEditorPreSaveStateMutation,
  GetBoQTemplateBoQEditorPreSaveStateMutationVariables
>;
export const GetContractBoQContentFromFileTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContractBoQContentFromFileTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractBoQContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContractBoQContentFromFileTreeQuery,
  GetContractBoQContentFromFileTreeQueryVariables
>;
export const MassDeterminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MassDetermination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqPositionRowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_CONTAINER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBoQPositionMassDeterminationForEditing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqPositionRowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqPositionRowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billOfQuantitiesId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUsedLocalId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'rowsProp' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'baseRow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AbstractMassDeterminationRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rowsProp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MassDetermination' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rows' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value5' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCustomFormulaRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formulaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expression' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MassDeterminationCommentRow' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'baseRow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MassDeterminationQuery, MassDeterminationQueryVariables>;
export const SetMassDeterminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetMassDetermination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_CONTAINER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqPositionRowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastUsedLocalId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SetMassDeterminationRowInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setBoQPositionMassDetermination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billOfQuantitiesId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqPositionRowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqPositionRowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastUsedLocalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastUsedLocalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetMassDeterminationMutation, SetMassDeterminationMutationVariables>;
export const DeleteMassDeterminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMassDetermination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_CONTAINER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqRowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBoQPositionMassDetermination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqRowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqRowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billOfQuantitiesId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMassDeterminationMutation,
  DeleteMassDeterminationMutationVariables
>;
export const GetDownloadURLForBoQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadURLForBoQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BoQExportInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadURLForBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDownloadURLForBoQQuery, GetDownloadURLForBoQQueryVariables>;
export const DownloadUrlForRequestForProposalBoQPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForRequestForProposalBoQPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BoQPdfSettings' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForRequestForProposalBoQPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForRequestForProposalBoQPdfQuery,
  DownloadUrlForRequestForProposalBoQPdfQueryVariables
>;
export const DownloadUrlForBidPackageBoQPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForBidPackageBoQPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BoQPdfSettings' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForBidPackageBoQPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForBidPackageBoQPdfQuery,
  DownloadUrlForBidPackageBoQPdfQueryVariables
>;
export const BoQTextEditorImageUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTextEditorImageUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storageKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BoQTextEditorImageUploadUrlQuery,
  BoQTextEditorImageUploadUrlQueryVariables
>;
export const BillOfQuantitiesFormalReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillOfQuantitiesFormalReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_CONTAINER_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStandard' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_FORMAT' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipUnsavedBoQChanges' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billOfQuantitiesFormalReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqContainerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqContainerType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedStandard' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStandard' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipUnsavedBoQChanges' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skipUnsavedBoQChanges' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'formalReviewResult' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'issues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'elementId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillOfQuantitiesFormalReviewQuery,
  BillOfQuantitiesFormalReviewQueryVariables
>;
export const UpdateBillOfQuantitiesProposalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBillOfQuantitiesProposal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FillOutBoQInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fillOutBidBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'positions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantityComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'result' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGross' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGrossDeducted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductedPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductionFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labourComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalTime' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'complementingPricePercentage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGross' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGrossDeducted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductedPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductionFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDeduction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subDescriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBillOfQuantitiesProposalMutation,
  UpdateBillOfQuantitiesProposalMutationVariables
>;
export const UpdateBillOfQuantitiesCalculationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBillOfQuantitiesCalculation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FillOutBoQInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fillOutCalculationBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'positions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantityComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'result' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGross' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGrossDeducted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductedPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductionFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labourComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalTime' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'complementingPricePercentage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGross' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPriceGrossDeducted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductedPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deductionFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDeduction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subDescriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlLongText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBillOfQuantitiesCalculationMutation,
  UpdateBillOfQuantitiesCalculationMutationVariables
>;
export const DeleteBoQInBoQTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBoQInBoQTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBoQInBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditRequestBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBoQInBoQTableMutation, DeleteBoQInBoQTableMutationVariables>;
export const DiscardRequestBillOfQuantitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardRequestBillOfQuantities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardRequestBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyEditOperationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditRequestBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscardRequestBillOfQuantitiesMutation,
  DiscardRequestBillOfQuantitiesMutationVariables
>;
export const DiscardTemplateBillOfQuantitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardTemplateBillOfQuantities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardTemplateBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyEditOperationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditTemplateBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscardTemplateBillOfQuantitiesMutation,
  DiscardTemplateBillOfQuantitiesMutationVariables
>;
export const EditRequestBoQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditRequestBoQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'EditRequestForProposalsBoQInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editRequestBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyEditOperationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditRequestBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditRequestBoQMutation, EditRequestBoQMutationVariables>;
export const EditTemplateBoQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditTemplateBoQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'EditRequestForProposalsBoQInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTemplateBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyEditOperationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditTemplateBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditTemplateBoQMutation, EditTemplateBoQMutationVariables>;
export const LBHochbauRestrictedItemNumbersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LBHochbauRestrictedItemNumbers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lbHochbauRestrictedItemNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedItemNumbers' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LBHochbauRestrictedItemNumbersQuery,
  LBHochbauRestrictedItemNumbersQueryVariables
>;
export const SaveBidPackageBoQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveBidPackageBoQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isShareableWithBidders' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveRequestBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isShareableWithBidders' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isShareableWithBidders' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyEditOperationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditRequestBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditRequestBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveBidPackageBoQMutation, SaveBidPackageBoQMutationVariables>;
export const SaveTemplateBoQDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveTemplateBoQ' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveTemplateBillOfQuantities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyEditOperationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verifyEditOperationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditTemplateBoQSuccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditTemplateBoQSuccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'success' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTaxRateChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitChanges' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnitPriceChanges' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveTemplateBoQMutation, SaveTemplateBoQMutationVariables>;
export const BoQTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'boqFileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'changedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQTemplateQuery, BoQTemplateQueryVariables>;
export const BidPackagePricePredictionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagePricePredictions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pricePredictionContext' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PRICE_PREDICTION_CONTEXT' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predictPriceForBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pricePredictionContext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pricePredictionContext' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'predictions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'supportedPriceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'lt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lte' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gte' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackagePricePredictionsQuery,
  BidPackagePricePredictionsQueryVariables
>;
export const StartTrialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartTrial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'module' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TRIAL_MODULE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startTrial' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'module' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'module' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartTrialMutation, StartTrialMutationVariables>;
export const RequestPricingPlanUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPricingPlanUpsell' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pricingPlan' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PRICING_PLAN' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPricingPlanUpsell' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pricingPlan' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pricingPlan' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPricingPlanUpsellMutation,
  RequestPricingPlanUpsellMutationVariables
>;
export const RequestBidderIntroductionEmailUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestBidderIntroductionEmailUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestBidderIntroductionEmailUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestBidderIntroductionEmailUpsellMutation,
  RequestBidderIntroductionEmailUpsellMutationVariables
>;
export const RequestAVAToolMoreInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestAVAToolMoreInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestAVAToolMoreInfo' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestAVAToolMoreInfoMutation,
  RequestAVAToolMoreInfoMutationVariables
>;
export const RequestCustomAccountTypesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCustomAccountTypesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestCustomAccountTypesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCustomAccountTypesUpsellMutation,
  RequestCustomAccountTypesUpsellMutationVariables
>;
export const RequestDataAndAnalyticsUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestDataAndAnalyticsUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestDataAndAnalyticsUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestDataAndAnalyticsUpsellMutation,
  RequestDataAndAnalyticsUpsellMutationVariables
>;
export const RequestInternalDocumentManagementUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestInternalDocumentManagementUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestInternalDocumentManagementUpsell' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestInternalDocumentManagementUpsellMutation,
  RequestInternalDocumentManagementUpsellMutationVariables
>;
export const RequestMessageTemplatesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestMessageTemplatesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestCompanyTemplatesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestMessageTemplatesUpsellMutation,
  RequestMessageTemplatesUpsellMutationVariables
>;
export const RequestReverseTrialUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestReverseTrialUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestReverseTrialUpsell' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestReverseTrialUpsellMutation,
  RequestReverseTrialUpsellMutationVariables
>;
export const RequestMultipleOfficesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestMultipleOfficesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestMultipleOfficesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestMultipleOfficesUpsellMutation,
  RequestMultipleOfficesUpsellMutationVariables
>;
export const RequestCustomSignaturesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCustomSignaturesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestCustomSignaturesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCustomSignaturesUpsellMutation,
  RequestCustomSignaturesUpsellMutationVariables
>;
export const CreateDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const AddContactsToBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddContactsToBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipInvitation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customInvitationEmailIntro' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addContactsToBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipInvitation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skipInvitation' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customBidsDueAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BiddersLimitExceededError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'limit' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BidderAlreadyAddedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddContactsToBidRequestMutation,
  AddContactsToBidRequestMutationVariables
>;
export const DeleteContactFromBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContactFromBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContactFromBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidInviteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidInviteId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteContactFromBidRequestMutation,
  DeleteContactFromBidRequestMutationVariables
>;
export const CreateDraftAddendumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDraftAddendum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDraftAddendum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contractRefNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addendumNumbers' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'firstName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isPerson' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'jobTitle' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'mobilePhone' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bidInvite' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractor' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'postalCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'billOfQuantities' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'comments' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'path' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'submittedByUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preferredBoQFileFormat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costGroupCatalogs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customCatalogId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customCatalogName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDraftAddendumMutation, CreateDraftAddendumMutationVariables>;
export const AddendumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Addendums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ADDENDUM_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addendums' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addendums' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalGross' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awardedBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidRequest' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subcontractor' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddendumsQuery, AddendumsQueryVariables>;
export const DeleteAddendumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAddendum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAddendum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAddendumMutation, DeleteAddendumMutationVariables>;
export const ClaimSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SUBCONTRACTOR_CLAIM_REFERENCE' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimSubcontractorBy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimSubcontractorMutation, ClaimSubcontractorMutationVariables>;
export const PublicSubcontractorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicSubcontractorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSubcontractorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClaimedByAgentCompany' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublicSubcontractorProfileQuery,
  PublicSubcontractorProfileQueryVariables
>;
export const BidRequestsForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequestsForAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyActive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActive' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'office' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'company' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidRequestsForSelectQuery, BidRequestsForSelectQueryVariables>;
export const SendVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>;
export const CheckVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mfaToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckVerificationCodeMutation, CheckVerificationCodeMutationVariables>;
export const RequestMfaResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestMfaReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestMfaReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestMfaResetMutation, RequestMfaResetMutationVariables>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reCaptchaToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reCaptchaToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reCaptchaToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CheckCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TYPE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkCredentials' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'loginToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaPhoneCensored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckCredentialsMutation, CheckCredentialsMutationVariables>;
export const RequestPasswordlessLoginCodeForBidSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeForBidSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPasswordlessLoginCodeForBidSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPasswordlessLoginCodeForBidSubmissionMutation,
  RequestPasswordlessLoginCodeForBidSubmissionMutationVariables
>;
export const RequestPasswordlessLoginCodeForTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeForTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPasswordlessLoginCodeForTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPasswordlessLoginCodeForTaskMutation,
  RequestPasswordlessLoginCodeForTaskMutationVariables
>;
export const RequestPasswordlessLoginCodeForInternalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeForInternalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPasswordlessLoginCodeForInternalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestPasswordlessLoginCode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestPasswordlessLoginCodeFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPasswordlessLoginCodeForInternalNoteMutation,
  RequestPasswordlessLoginCodeForInternalNoteMutationVariables
>;
export const ValidatePasswordlessLoginCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidatePasswordlessLoginCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TYPE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validatePasswordlessLoginCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ValidatePasswordlessLoginFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CheckCredentialsResponse' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'loginToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaPhoneCensored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidatePasswordlessLoginCodeMutation,
  ValidatePasswordlessLoginCodeMutationVariables
>;
export const ValidatePasswordlessLoginTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidatePasswordlessLoginToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TYPE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validatePasswordlessLoginToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ValidatePasswordlessLoginResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ValidatePasswordlessLoginFailure' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reason' } }],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CheckCredentialsResponse' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'loginToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaPhoneCensored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidatePasswordlessLoginTokenMutation,
  ValidatePasswordlessLoginTokenMutationVariables
>;
export const ResendConfirmationEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendConfirmationEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendOwnConfirmationEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;
export const AgentSignupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupUnsolicitedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupUnsolicited' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isFirstUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentSignupMutation, AgentSignupMutationVariables>;
export const DuplicateBidPackageModalProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DuplicateBidPackageModalProjects' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectsForDuplicatingBidPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateBidPackageModalProjectsQuery,
  DuplicateBidPackageModalProjectsQueryVariables
>;
export const DeleteBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBidPackageMutation, DeleteBidPackageMutationVariables>;
export const CloseBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CloseBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailTemplate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CloseBidPackageMutation, CloseBidPackageMutationVariables>;
export const ReopenBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReopenBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reopenBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReopenBidPackageMutation, ReopenBidPackageMutationVariables>;
export const DuplicateBidPackageModalDuplicateBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateBidPackageModalDuplicateBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeContacts' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeDescription' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeDocuments' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeBoQ' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeBindingPeriod' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeBidPackageTeam' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationProjectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateBidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeContacts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeContacts' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeDescription' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeDescription' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeDocuments' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeDocuments' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeBoQ' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeBoQ' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeBindingPeriod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeBindingPeriod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeBidPackageTeam' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeBidPackageTeam' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationProjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'destinationProjectId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateBidPackageModalDuplicateBidPackageMutation,
  DuplicateBidPackageModalDuplicateBidPackageMutationVariables
>;
export const BidPackageForBiddersAwaitingResponseCalloutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForBiddersAwaitingResponseCallout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openingDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageForBiddersAwaitingResponseCalloutQuery,
  BidPackageForBiddersAwaitingResponseCalloutQueryVariables
>;
export const BidRequestsToNotifyOfDeletionCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsToNotifyOfDeletionCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequestsToNotifyOfDeletionCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestsToNotifyOfDeletionCountQuery,
  BidRequestsToNotifyOfDeletionCountQueryVariables
>;
export const BidRequestsToNotifyOfClosingCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsToNotifyOfClosingCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequestsToNotifyOfClosingCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestsToNotifyOfClosingCountQuery,
  BidRequestsToNotifyOfClosingCountQueryVariables
>;
export const BidPackagesBasicInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagesBasicInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackagesBasicInfoQuery, BidPackagesBasicInfoQueryVariables>;
export const BidPackagesForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagesForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGES_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGE_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeClosedProjects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidPackagesForSelectQuery, BidPackagesForSelectQueryVariables>;
export const SelectedBidPackageForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectedBidPackageForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectedBidPackageForSelectQuery,
  SelectedBidPackageForSelectQueryVariables
>;
export const AddBidRequestNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBidRequestNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBidRequestNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddBidRequestNoteMutation, AddBidRequestNoteMutationVariables>;
export const DeleteBidRequestNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBidRequestNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBidRequestNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBidRequestNoteMutation, DeleteBidRequestNoteMutationVariables>;
export const DiscardDraftBoQInSubmitBidModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardDraftBoQInSubmitBidModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardDraftBoQ' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscardDraftBoQInSubmitBidModalMutation,
  DiscardDraftBoQInSubmitBidModalMutationVariables
>;
export const UpdateDraftBidInSummaryTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDraftBidInSummaryTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDraftBidInSummaryTableMutation,
  UpdateDraftBidInSummaryTableMutationVariables
>;
export const BoQTemplatesForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQTemplatesForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_TEMPLATES_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileFormat' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_FORMAT' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqFileFormat' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqFileFormat' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchOwnBoQTemplates' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boqTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQTemplatesForSelectQuery, BoQTemplatesForSelectQueryVariables>;
export const UpdateBoQTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBoQTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateBoQTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBoQTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateBoQTemplateSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'template' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBoQTemplateMutation, UpdateBoQTemplateMutationVariables>;
export const UpdateCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'issueDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expiryDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'issueDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'issueDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expiryDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'expiryDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCertificateMutation, UpdateCertificateMutationVariables>;
export const CreateAgentCompanyCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAgentCompanyCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileSizeBytes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CERTIFICATE_FILE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAgentCompanyCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileSizeBytes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileSizeBytes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAgentCompanyCertificateMutation,
  CreateAgentCompanyCertificateMutationVariables
>;
export const CreateSubcontractorCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubcontractorCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileSizeBytes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CERTIFICATE_FILE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubcontractorCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileSizeBytes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileSizeBytes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSubcontractorCertificateMutation,
  CreateSubcontractorCertificateMutationVariables
>;
export const CreateCertificateBySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCertificateBySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateRequestItemId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileSizeBytes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CERTIFICATE_FILE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubcontractorCertificateDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateRequestItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'certificateRequestItemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileSizeBytes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileSizeBytes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCertificateBySubcontractorMutation,
  CreateCertificateBySubcontractorMutationVariables
>;
export const UpdateCertificateBySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCertificateBySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'issueDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expiryDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'issueDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'issueDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expiryDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'expiryDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCertificateBySubcontractorMutation,
  UpdateCertificateBySubcontractorMutationVariables
>;
export const SubmitCertificateRequestBySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitCertificateRequestBySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitCertificateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitCertificateRequestBySubcontractorMutation,
  SubmitCertificateRequestBySubcontractorMutationVariables
>;
export const SaveInProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveInProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveInProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveInProfileMutation, SaveInProfileMutationVariables>;
export const CertificateFileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CertificateFileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CERTIFICATE_FILE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateFileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateFileNameQuery, CertificateFileNameQueryVariables>;
export const CertificateUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CertificateUploadUrl' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateUploadUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateUploadUrlQuery, CertificateUploadUrlQueryVariables>;
export const SuggestedGlobalCertificateTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SuggestedGlobalCertificateTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestedGlobalCertificateTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'certificateTypes' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuggestedGlobalCertificateTypesQuery,
  SuggestedGlobalCertificateTypesQueryVariables
>;
export const CreateCompanyCertificateTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompanyCertificateType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateDocumentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CERTIFICATE_TYPE_SCOPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompanyCertificateType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isExpiryDateMandatory' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateDocumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateDocumentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCompanyCertificateTypeMutation,
  CreateCompanyCertificateTypeMutationVariables
>;
export const UpdateCompanyCertificateTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyCertificateType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateDocumentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CERTIFICATE_TYPE_SCOPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyCertificateType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isExpiryDateMandatory' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateDocumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateDocumentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyCertificateTypeMutation,
  UpdateCompanyCertificateTypeMutationVariables
>;
export const DeleteCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCertificateMutation, DeleteCertificateMutationVariables>;
export const CancelCertificationRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelCertificationRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelCertificateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelCertificationRequestMutation,
  CancelCertificationRequestMutationVariables
>;
export const GetParsedTemplatesByTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetParsedTemplatesByType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TEMPLATE_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailTemplatePlaceholderInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmailTemplatePlaceholderInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salutationMode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EMAIL_SALUTATION_MODE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsedCompanyTemplatesByType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailTemplatePlaceholderInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'emailTemplatePlaceholderInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salutationMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salutationMode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetParsedTemplatesByTypeQuery, GetParsedTemplatesByTypeQueryVariables>;
export const RequestCompanyTemplatesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCompanyTemplatesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestCompanyTemplatesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCompanyTemplatesUpsellMutation,
  RequestCompanyTemplatesUpsellMutationVariables
>;
export const CompleteContractSigningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteContractSigning' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signedDocumentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractRunAgentStepId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeContractSigning' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signedDocumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'signedDocumentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractRunAgentStepId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contractRunAgentStepId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteContractSigningMutation,
  CompleteContractSigningMutationVariables
>;
export const CreateRecipientViewUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRecipientViewUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractRunAgentStepId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDocuSignRecipientViewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractRunAgentStepId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contractRunAgentStepId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRecipientViewUrlMutation,
  CreateRecipientViewUrlMutationVariables
>;
export const ContractRunAgentStepDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractRunAgentStep' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractRunAgentStep' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractRunAgentStepQuery, ContractRunAgentStepQueryVariables>;
export const CopyFromInternalFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyFromInternalFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceAttachable' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachableInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetAttachable' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachableInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paths' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyItemsFromAnotherFileTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceAttachable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceAttachable' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetAttachable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetAttachable' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paths' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paths' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyFromInternalFilingMutation,
  CopyFromInternalFilingMutationVariables
>;
export const RequestCustomBidInvitesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCustomBidInvitesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'requestCustomBidInvitesUpsell' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCustomBidInvitesUpsellMutation,
  RequestCustomBidInvitesUpsellMutationVariables
>;
export const CustomFieldsWithInitialValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomFieldsWithInitialValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CUSTOM_FIELD_ENTITY_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldsWithInitialValues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomFieldsWithInitialValuesQuery,
  CustomFieldsWithInitialValuesQueryVariables
>;
export const RequestCustomFieldsUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCustomFieldsUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestCustomFieldsUpsell' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCustomFieldsUpsellMutation,
  RequestCustomFieldsUpsellMutationVariables
>;
export const BidInviteIntroTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidInviteIntroTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyTemplateType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TEMPLATE_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInviteDefaultIntro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyTemplateType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidInviteIntroTemplatesQuery, BidInviteIntroTemplatesQueryVariables>;
export const FileTreeWithDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FileTreeWithDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachable' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachableInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isAttachableProjectBim' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileTreeWithDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attachable' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DocumentsTable_documents' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileTree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DocumentsTable_fileTree' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentsTable_documents' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasBeenDownloaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsedIfcFile' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isAttachableProjectBim' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskProcessingState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentsTable_fileTree' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileTree' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileTree' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileTreeWithDocumentsQuery, FileTreeWithDocumentsQueryVariables>;
export const CombinedFileTreeWithDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CombinedFileTreeWithDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachables' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachableInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isAttachableProjectBim' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'combinedFileTreeWithDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachables' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attachables' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DocumentsTable_documents' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileTrees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DocumentsTable_fileTree' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentsTable_documents' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasBeenDownloaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsedIfcFile' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isAttachableProjectBim' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autodeskProcessingState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentsTable_fileTree' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileTree' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileTree' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CombinedFileTreeWithDocumentsQuery,
  CombinedFileTreeWithDocumentsQueryVariables
>;
export const FileTreeAdditionsUploadDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FileTreeAdditionsUploadData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileTreeAdditionsUploadData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FileTreeAdditionsUploadDataQuery,
  FileTreeAdditionsUploadDataQueryVariables
>;
export const RenameFileTreeItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameFileTreeItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameFileTreeItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileTreeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameFileTreeItemMutation, RenameFileTreeItemMutationVariables>;
export const DeleteFileTreeItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFileTreeItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFileTreeItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileTreeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileTreeItemMutation, DeleteFileTreeItemMutationVariables>;
export const MoveFileTreeItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveFileTreeItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceFileTreeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetFileTreeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourcePath' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetPath' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveFileTreeItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceFileTreeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceFileTreeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetFileTreeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetFileTreeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourcePath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourcePath' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetPath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetPath' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveFileTreeItemMutation, MoveFileTreeItemMutationVariables>;
export const AddFilesToFileTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFilesToFileTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeAdditionsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFilesToFileTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileTreeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileTreeAdditionsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeAdditionsId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFilesToFileTreeMutation, AddFilesToFileTreeMutationVariables>;
export const AddFolderToFileTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFolderToFileTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFolderToFileTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileTreeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileTreeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFolderToFileTreeMutation, AddFolderToFileTreeMutationVariables>;
export const MarkDocumentAsDownloadedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkDocumentAsDownloaded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markDocumentAsDownloaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkDocumentAsDownloadedMutation,
  MarkDocumentAsDownloadedMutationVariables
>;
export const DownloadUrlForProjectDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForProjectDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orientation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ORIENTATION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForProjectDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orientation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orientation' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForProjectDocumentsQuery,
  DownloadUrlForProjectDocumentsQueryVariables
>;
export const DownloadUrlForBidPackageDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForBidPackageDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orientation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ORIENTATION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForBidPackageDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orientation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orientation' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForBidPackageDocumentsQuery,
  DownloadUrlForBidPackageDocumentsQueryVariables
>;
export const DownloadUrlForBidRequestInNegotiationDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadUrlForBidRequestInNegotiationDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orientation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ORIENTATION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForBidRequestInNegotiationDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orientation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orientation' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadUrlForBidRequestInNegotiationDocumentsQuery,
  DownloadUrlForBidRequestInNegotiationDocumentsQueryVariables
>;
export const RequestProjectExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestProjectExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'boqType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_FILE_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestProjectExport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'boqType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'boqType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestProjectExportMutation, RequestProjectExportMutationVariables>;
export const ProjectsForProjectSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectsForProjectSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECTS_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeClosed' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeClosed' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeClosed' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectsForProjectSelectQuery, ProjectsForProjectSelectQueryVariables>;
export const OriginalProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OriginalProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'office' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OriginalProjectQuery, OriginalProjectQueryVariables>;
export const DuplicateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DuplicateProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateProjectMutation, DuplicateProjectMutationVariables>;
export const TemplatePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TemplatePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salutationMode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EMAIL_SALUTATION_MODE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TEMPLATE_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTemplatePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'content' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salutationMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salutationMode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplatePreviewQuery, TemplatePreviewQueryVariables>;
export const CreateEmailTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEmailTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyTemplateCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompanyTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>;
export const InvoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Invoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'INVOICE_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requiresApprovalByCurrentUser' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requiresApprovalByCurrentUser' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'requiresApprovalByCurrentUser' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedTotalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cashDiscountValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberExternal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberInternal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialInvoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'retentionValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customRows' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isNegative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currentStep' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalStepsCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userFirstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userLastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoicesQuery, InvoicesQueryVariables>;
export const DeleteInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const CreateMessageThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMessageThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authorType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authorType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authorType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageText' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMessageThreadMutation, CreateMessageThreadMutationVariables>;
export const MessageThreadInMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageThreadInMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageThreadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageThreadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageThreadId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isMyMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorCompanyName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipients' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasReadMessage' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasBeenRead' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageThreadInMessagesQuery, MessageThreadInMessagesQueryVariables>;
export const ReplyToMessageThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReplyToMessageThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authorType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COMPANY_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageThreadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replyToMessageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authorType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authorType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageThreadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageThreadId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageText' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplyToMessageThreadMutation, ReplyToMessageThreadMutationVariables>;
export const MarkThreadsAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkThreadsAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageThreadIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markThreadsAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageThreadIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageThreadIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkThreadsAsReadMutation, MarkThreadsAsReadMutationVariables>;
export const ProjectUsersForMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectUsersForMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectUsersForMessageQuery, ProjectUsersForMessageQueryVariables>;
export const CompanyUsersForMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyUsersForMessage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'name' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyUsersForMessageQuery, CompanyUsersForMessageQueryVariables>;
export const MessageTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'message' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageTemplatesQuery, MessageTemplatesQueryVariables>;
export const VerifyMfaPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyMfaPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyMfaPhoneNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyMfaPhoneNumberMutation, VerifyMfaPhoneNumberMutationVariables>;
export const StoreMfaPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StoreMfaPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeMfaPhoneNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mfaToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreMfaPhoneNumberMutation, StoreMfaPhoneNumberMutationVariables>;
export const GenerateAuthenticatorSecretDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateAuthenticatorSecret' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateAuthenticatorSecret' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'secret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateAuthenticatorSecretMutation,
  GenerateAuthenticatorSecretMutationVariables
>;
export const StoreAuthenticatorSecretDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StoreAuthenticatorSecret' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'secret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeAuthenticatorSecret' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secret' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'secret' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loginToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mfaToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StoreAuthenticatorSecretMutation,
  StoreAuthenticatorSecretMutationVariables
>;
export const CreateMultiUserApprovalRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMultiUserApprovalRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestProps' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MultiUserApprovalRequestPropsInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ruleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMultiUserApprovalRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestProps' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestProps' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ruleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ruleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMultiUserApprovalRequestMutation,
  CreateMultiUserApprovalRequestMutationVariables
>;
export const RejectMultiUserApprovalRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectMultiUserApprovalRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rejectionReason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectMultiUserApprovalRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rejectionReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rejectionReason' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectMultiUserApprovalRequestMutation,
  RejectMultiUserApprovalRequestMutationVariables
>;
export const WithdrawMultiUserApprovalRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WithdrawMultiUserApprovalRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawMultiUserApprovalRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WithdrawMultiUserApprovalRequestMutation,
  WithdrawMultiUserApprovalRequestMutationVariables
>;
export const ApproveMultiUserApprovalRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveMultiUserApprovalRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveMultiUserApprovalRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveMultiUserApprovalRequestMutation,
  ApproveMultiUserApprovalRequestMutationVariables
>;
export const BidPackageChangedDefaultTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageChangedDefaultTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackageChangedDefaultTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageChangedDefaultTemplateQuery,
  BidPackageChangedDefaultTemplateQueryVariables
>;
export const MarketplaceSuggestionsAfterBidSubmitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketplaceSuggestionsAfterBidSubmit' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceBidPackageSuggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '3' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'officeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'officeLogoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarketplaceSuggestionsAfterBidSubmitQuery,
  MarketplaceSuggestionsAfterBidSubmitQueryVariables
>;
export const BidInvitePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidInvitePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customInvitationEmailIntro' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HTML' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidInvitePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customInvitationEmailIntro' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customBidsDueAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customBidsDueAt' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidInvitePreviewQuery, BidInvitePreviewQueryVariables>;
export const UpdateBoQInternalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBoQInternalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillOfQuantitiesInternalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBoQInternalNoteMutation, UpdateBoQInternalNoteMutationVariables>;
export const GetDownloadUrlForSpreadsheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadUrlForSpreadsheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenComparisonColumns' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_COMPARISON_MODE' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidColumnConfigs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidColumnConfig' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenElementIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenBidders' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HiddenBidder' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'alternativePositionSelections' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AlternativePositionSelection' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeOptions' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PriceComparisonSpreadsheetIncludeOptions' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'highlightMode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HighlightPriceMode' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'outlierSettings' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PriceComparisonOutlierSettings' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForPriceComparisonSpreadsheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenComparisonColumns' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hiddenComparisonColumns' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidColumnConfigs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidColumnConfigs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenElementIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenElementIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenBidders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenBidders' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alternativePositionSelections' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'alternativePositionSelections' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeOptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeOptions' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'highlightPriceMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'highlightMode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outlierSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'outlierSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDownloadUrlForSpreadsheetQuery,
  GetDownloadUrlForSpreadsheetQueryVariables
>;
export const GetDownloadUrlForPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadUrlForPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenComparisonColumns' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'BOQ_COMPARISON_MODE' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidColumnConfigs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidColumnConfig' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenElementIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenBidders' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HiddenBidder' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'alternativePositionSelections' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AlternativePositionSelection' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'printOptions' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PrintOptions' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PCTPdfSettings' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'highlightMode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HighlightPriceMode' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'outlierSettings' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PriceComparisonOutlierSettings' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForPriceComparisonPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenComparisonColumns' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hiddenComparisonColumns' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidColumnConfigs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidColumnConfigs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenElementIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenElementIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hiddenBidders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hiddenBidders' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alternativePositionSelections' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'alternativePositionSelections' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'printOptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'printOptions' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'highlightPriceMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'highlightMode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outlierSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'outlierSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDownloadUrlForPdfQuery, GetDownloadUrlForPdfQueryVariables>;
export const ConfirmCustomBudgetBoQHasBeenUploadedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmCustomBudgetBoQHasBeenUploaded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmCustomBudgetBillOfQuantitiesHasBeenUploaded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billOfQuantitiesId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warnings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ConfirmBillOfQuantitiesHasBeenUploadedError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmCustomBudgetBoQHasBeenUploadedMutation,
  ConfirmCustomBudgetBoQHasBeenUploadedMutationVariables
>;
export const BidPackageForPriceComparisonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForPriceComparison' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidColumnConfigs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidColumnConfig' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredBoQFileFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultTaxRate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'revision' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestForProposalBoQ' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internalNotes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submittableBidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'allBidColumnsForPriceComparison' },
                  name: { kind: 'Name', value: 'bidColumnsForPriceComparison' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'bidColumnConfigs' },
                      value: { kind: 'ListValue', values: [] },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'revision' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableBids' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isEligibleForAwarding' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'revision' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcontractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'filteredBidColumnsForPriceComparison' },
                  name: { kind: 'Name', value: 'bidColumnsForPriceComparison' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'bidColumnConfigs' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'bidColumnConfigs' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'revision' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awardedBid' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidsEligibleForAwarding' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcontractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SubcontractorForSubcontractorBidColumnHeader',
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentBidId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bidPackage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'project' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'BidForSubcontractorBidColumnHeader' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isEligibleForAwarding' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internalNotes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customBudgetBoQ' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'revision' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBiddersWithSubmittedBids' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorForSubcontractorBidColumnHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidForSubcontractorBidColumnHeader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubmittedOrGeneratedSubmittedBid' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentBid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageForPriceComparisonQuery,
  BidPackageForPriceComparisonQueryVariables
>;
export const GetSimulationChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSimulationChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simulationChanges' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boqQuantityChanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customBudgetBoQUnitPriceChanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customBudgetBoQFreeQuantityChanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bids' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bidId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boqUnitPriceChanges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeQuantityChanges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSimulationChangesQuery, GetSimulationChangesQueryVariables>;
export const SavePriceComparisonSimulationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SavePriceComparisonSimulation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'editingSessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'saveOptions' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveSimulationOptionsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'savePriceComparisonSimulation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editingSessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'editingSessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'saveOptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'saveOptions' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SavePriceComparisonSimulationMutation,
  SavePriceComparisonSimulationMutationVariables
>;
export const StoreSimulationChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StoreSimulationChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'editingSessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SimulationChangeInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeSimulationChange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editingSessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'editingSessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreSimulationChangeMutation, StoreSimulationChangeMutationVariables>;
export const DiscardSimulationChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardSimulationChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'editingSessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardSimulationChanges' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editingSessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'editingSessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revision' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscardSimulationChangesMutation,
  DiscardSimulationChangesMutationVariables
>;
export const GetBidDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBidDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPdfSettings' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrlForBidPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pdfSettings' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBidDownloadUrlQuery, GetBidDownloadUrlQueryVariables>;
export const FinancesProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancesProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PROJECTS_SORT_FIELD' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'office' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinancesProjectsQuery, FinancesProjectsQueryVariables>;
export const FinancesSelectedProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancesSelectedProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectsByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'office' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinancesSelectedProjectsQuery, FinancesSelectedProjectsQueryVariables>;
export const SubmitBiddersSearchRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitBiddersSearchRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddersSearchInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitBiddersSearchRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitBiddersSearchRequestMutation,
  SubmitBiddersSearchRequestMutationVariables
>;
export const NetworkSubcontractorsForSignupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkSubcontractorsForSignup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkSubcontractors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NetworkSubcontractorsForSignupQuery,
  NetworkSubcontractorsForSignupQueryVariables
>;
export const CreateNetworkSubcontractorByAgentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNetworkSubcontractorByAgentUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNetworkSubcontractorByAgentUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNetworkSubcontractorByAgentUserMutation,
  CreateNetworkSubcontractorByAgentUserMutationVariables
>;
export const UpdateNetworkSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNetworkSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNetworkSubcontractorByAgentUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNetworkSubcontractorMutation,
  UpdateNetworkSubcontractorMutationVariables
>;
export const AgentCompanyProfilePrefillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentCompanyProfilePrefill' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentCompanyProfilePrefill' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentCompanyProfilePrefillQuery,
  AgentCompanyProfilePrefillQueryVariables
>;
export const UpdateCompanySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanySubcontractorMutation,
  UpdateCompanySubcontractorMutationVariables
>;
export const SuggestChangesToNetworkSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SuggestChangesToNetworkSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubcontractorSuggestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestChangesToNetworkSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuggestChangesToNetworkSubcontractorMutation,
  SuggestChangesToNetworkSubcontractorMutationVariables
>;
export const SuggestChangesToNetworkSubcontractorContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SuggestChangesToNetworkSubcontractorContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contacts' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionComment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestChangesToNetworkSubcontractorContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contacts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contacts' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'suggestionComment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'suggestionComment' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuggestChangesToNetworkSubcontractorContactsMutation,
  SuggestChangesToNetworkSubcontractorContactsMutationVariables
>;
export const UpdateNetworkSubcontractorPrivateContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNetworkSubcontractorPrivateContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contacts' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubcontractorPrivateContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contacts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contacts' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNetworkSubcontractorPrivateContactsMutation,
  UpdateNetworkSubcontractorPrivateContactsMutationVariables
>;
export const CreateCompanySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompanySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompanySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCompanySubcontractorMutation,
  CreateCompanySubcontractorMutationVariables
>;
export const UpdateSubcontractorIsFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubcontractorIsFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isFavorite' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubcontractorIsFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isFavorite' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isFavorite' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubcontractorIsFavoriteMutation,
  UpdateSubcontractorIsFavoriteMutationVariables
>;
export const UpdateSubcontractorIsBannedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubcontractorIsBanned' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isBanned' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubcontractorIsBanned' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isBanned' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isBanned' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubcontractorIsBannedMutation,
  UpdateSubcontractorIsBannedMutationVariables
>;
export const UpdateSubcontractorCustomFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubcontractorCustomFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customFields' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldValueInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubcontractorCustomFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customFields' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customFields' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubcontractorCustomFieldsMutation,
  UpdateSubcontractorCustomFieldsMutationVariables
>;
export const RequestCertificatesUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestCertificatesUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'requestCertificatesUpsell' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCertificatesUpsellMutation,
  RequestCertificatesUpsellMutationVariables
>;
export const InfoForCertificateRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InfoForCertificateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastCertificateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'officeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InfoForCertificateRequestQuery,
  InfoForCertificateRequestQueryVariables
>;
export const SendCertificateRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendCertificateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dueDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendCertificateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateTypeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateTypeIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'officeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dueDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dueDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendCertificateRequestMutation,
  SendCertificateRequestMutationVariables
>;
export const CertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Certificates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'combinedCertificateTypesReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSelectedByDefaultWhenRequesting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isInUse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateRequestItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificatesTable_CertificateTypeReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CertificateTypeReport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpiryDateMandatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subcontractorTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpiryDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificatesTable_Certificate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingCertificateRequestItemsForBidPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificatesTable_PendingCertificateRequestItemForBidPackage',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificatesQuery, CertificatesQueryVariables>;
export const MessageThreadsForSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageThreadsForSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showMessageThreadsFromAllProjects' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThreadsForSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showMessageThreadsFromAllProjects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showMessageThreadsFromAllProjects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThreads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Messages_messageThread' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThreadsList_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasUnreadMessages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participatingCompanyOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondingCompanyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'office' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Messages_messageThread' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThreadsList_messageThread' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageThreadsForSubcontractorQuery,
  MessageThreadsForSubcontractorQueryVariables
>;
export const BidRequestsForSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidRequestsForSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'subcontractorName' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidRequestsForSubcontractorQuery,
  BidRequestsForSubcontractorQueryVariables
>;
export const BidPackagesForSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackagesForSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_PACKAGE_STATUS' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'IntValue', value: '0' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'EnumValue', value: 'number' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'EnumValue', value: 'ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeClosedProjects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'excludeClosedProjects' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackagesForSubcontractorQuery,
  BidPackagesForSubcontractorQueryVariables
>;
export const SubcontractorRatingOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorRatingOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorRatingSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingByCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorRatingPerBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BidRequestRating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ratingCategories' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestRating' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubcontractorRatingPerBidRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidPackageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratingByCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userRatedCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratingCategories' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorRatingOverviewQuery,
  SubcontractorRatingOverviewQueryVariables
>;
export const SubcontractorForProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorForProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorForProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Subcontractor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidRequestsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageThreadsCount' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'pendingBidRequestsCount' },
                        name: { kind: 'Name', value: 'bidRequestsCount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'invited' },
                                { kind: 'EnumValue', value: 'opened' },
                                { kind: 'EnumValue', value: 'viewed' },
                                { kind: 'EnumValue', value: 'accepted' },
                                { kind: 'EnumValue', value: 'undecided' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'submittedBidRequestsCount' },
                        name: { kind: 'Name', value: 'bidRequestsCount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'bidSubmitted' },
                                { kind: 'EnumValue', value: 'bidAwarded' },
                                { kind: 'EnumValue', value: 'inNegotiation' },
                                { kind: 'EnumValue', value: 'bidRejected' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'awardedBidRequestsCount' },
                        name: { kind: 'Name', value: 'bidRequestsCount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'EnumValue', value: 'bidAwarded' }],
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'declinedBidRequestsCount' },
                        name: { kind: 'Name', value: 'bidRequestsCount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'EnumValue', value: 'inviteDeclined' }],
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'rejectedBidRequestsCount' },
                        name: { kind: 'Name', value: 'bidRequestsCount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'EnumValue', value: 'bidRejected' }],
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'generatedDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nationalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'foundingYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revenueYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yearlyRevenue' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrincipalSubcontractorForm_subcontractor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MergedIntoSubcontractor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactsForSubcontractorForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldWithValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCheckbox' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCheckbox' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueCurrency' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueCurrency' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDate' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDate' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueDateTime' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueDateTime' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueMultiSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueMultiSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueNumber' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueNumber' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueSingleSelect' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueSingleSelect' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldWithValueText' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'valueText' },
                  name: { kind: 'Name', value: 'value' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrincipalSubcontractorForm_subcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moreInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'foundingYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenueYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearlyRevenue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generatedDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pqNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactsForSubcontractorForm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldWithValue' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorForProfileQuery, SubcontractorForProfileQueryVariables>;
export const SubcontractorBidRequestsForProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorBidRequestsForProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUESTS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastStatusChangeAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billOfQuantities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'editingStatus' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBid' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBlindTender' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blindTenderBidsOpenedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'declinedReasonOther' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAwaitingResponse' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorBidRequestsForProfileQuery,
  SubcontractorBidRequestsForProfileQueryVariables
>;
export const SubcontractorWithNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorWithNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notes_note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notes_note' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Note' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorWithNotesQuery, SubcontractorWithNotesQueryVariables>;
export const BidPackageForSubcontractorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidPackageForSubcontractorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddContactAction_bidPackage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddContactAction_bidPackage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customInvitationEmailIntro' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addedContactIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withdrawnContactIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsDueAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkAwaitingResponseCountIfRestricted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEstimating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customBidsDueAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcontractorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidInvite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BidPackageForSubcontractorProfileQuery,
  BidPackageForSubcontractorProfileQueryVariables
>;
export const AddSubcontractorNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSubcontractorNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSubcontractorNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddSubcontractorNoteMutation, AddSubcontractorNoteMutationVariables>;
export const DeleteSubcontractorNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSubcontractorNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSubcontractorNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSubcontractorNoteMutation,
  DeleteSubcontractorNoteMutationVariables
>;
export const SubcontractorRatingByBidRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorRatingByBidRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorRatingByBidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bidRequestId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorRatingByBidRequestQuery,
  SubcontractorRatingByBidRequestQueryVariables
>;
export const UpsertSubcontractorRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertSubcontractorRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorRatingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertSubcontractorRating' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertSubcontractorRatingMutation,
  UpsertSubcontractorRatingMutationVariables
>;
export const DeleteSubcontractorRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSubcontractorRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSubcontractorRating' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSubcontractorRatingMutation,
  DeleteSubcontractorRatingMutationVariables
>;
export const DeleteSubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;
export const SuggestNetworkSubcontractorRemovalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SuggestNetworkSubcontractorRemoval' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestNetworkSubcontractorRemoval' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuggestNetworkSubcontractorRemovalMutation,
  SuggestNetworkSubcontractorRemovalMutationVariables
>;
export const SubcontractorsForFilterableListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorsForFilterableList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SUBCONTRACTORS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcontractorFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'distanceInMeters' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subcontractor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SubcontractorsListItem_subcontractorsListItem',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SubcontractorContactsList_subcontractor',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo_Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorsListItem_subcontractorsListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidRequestsAwaitingResponseCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'pendingBidRequestsCount' },
            name: { kind: 'Name', value: 'bidRequestsCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'invited' },
                    { kind: 'EnumValue', value: 'opened' },
                    { kind: 'EnumValue', value: 'viewed' },
                    { kind: 'EnumValue', value: 'accepted' },
                    { kind: 'EnumValue', value: 'undecided' },
                  ],
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedCertificateTypesCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubcontractorContactsList_subcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo_Contact' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubcontractorsForFilterableListQuery,
  SubcontractorsForFilterableListQueryVariables
>;
export const HasCompanyImportedSCsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HasCompanyImportedSCs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasImportedSubcontractors' } }],
      },
    },
  ],
} as unknown as DocumentNode<HasCompanyImportedSCsQuery, HasCompanyImportedSCsQueryVariables>;
export const TasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TASKS_SORT_FIELD' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SORT_DIRECTION' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fetchOwnTasks' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'TASK_STATUS' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchOwnTasks' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fetchOwnTasks' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assigneeType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProjectForTaskList' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidPackage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'BidPackageForTaskList' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'BidRequestForTaskList' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allTasks' },
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectForTaskList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidPackageForTaskList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BidRequestForTaskList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BidRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskForAssigneeLabel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Task' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subcontractor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TasksQuery, TasksQueryVariables>;
export const DeleteTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const MarkTaskAsDoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkTaskAsDone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markTaskAsDone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkTaskAsDoneMutation, MarkTaskAsDoneMutationVariables>;
export const MarkTaskAsTodoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkTaskAsTodo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markTaskAsTodo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkTaskAsTodoMutation, MarkTaskAsTodoMutationVariables>;
export const MarkTaskAsInProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkTaskAsInProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markTaskAsInProgress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkTaskAsInProgressMutation, MarkTaskAsInProgressMutationVariables>;
export const TextEditorImageUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TextEditorImageUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storageKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextEditorImageUploadUrlQuery, TextEditorImageUploadUrlQueryVariables>;
export const MarkAllNotificationsAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkAllNotificationsAsRead' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'markNotificationsAsRead' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables
>;
export const MarkNotificationAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkNotificationAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markNotificationAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;
export const BidsEligibleForAwardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidsEligibleForAwarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentBid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bidsEligibleForAwarding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSimulated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'documentsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billOfQuantitiesRevision' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isShareableWithBidders' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'wereBiddersNotified' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidsEligibleForAwardingQuery, BidsEligibleForAwardingQueryVariables>;
export const UserEditOrInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserEditOrInvite' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyLicenseData' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customUserRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserEditOrInvite_office' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyLicenseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullUserLicenseCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestLicenseCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usedSeats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fullUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guests' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserEditOrInvite_office' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Office' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserEditOrInviteQuery, UserEditOrInviteQueryVariables>;
export const ResetMfaConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetMfaConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetMfaConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetMfaConfigMutation, ResetMfaConfigMutationVariables>;
export const InviteUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserToInviteInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'users' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitedUser' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitedUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteUsersMutation, InviteUsersMutationVariables>;
export const SendInviteRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendInviteRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserToInviteInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteRequestSourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendInviteRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'users' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitedUser' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitedUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendInviteRequestsMutation, SendInviteRequestsMutationVariables>;
export const WorkCategorySuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WorkCategorySuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hints' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkCategorySuggestionsHints' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategorySuggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hints' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hints' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkCategorySuggestionsQuery, WorkCategorySuggestionsQueryVariables>;
export const RecentMarketplaceBidRequestsCreationDatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RecentMarketplaceBidRequestsCreationDates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recentMarketplaceBidRequestsCreationDates' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecentMarketplaceBidRequestsCreationDatesQuery,
  RecentMarketplaceBidRequestsCreationDatesQueryVariables
>;
export const AgentCompanyHasProfileCompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentCompanyHasProfileComplete' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAgentProfileComplete' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentCompanyHasProfileCompleteQuery,
  AgentCompanyHasProfileCompleteQueryVariables
>;
export const AgentSignupForAwardedBidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignupForAwardedBid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupForAwardedBidInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupForAwardedBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSignupForAwardedBidMutation,
  AgentSignupForAwardedBidMutationVariables
>;
export const AgentSignupForBidInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignupForBidInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupForBidInviteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupForBidInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSignupForBidInviteMutation,
  AgentSignupForBidInviteMutationVariables
>;
export const AgentSignupForBidSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignupForBidSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupForBidSubmissionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupForBidSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSignupForBidSubmissionMutation,
  AgentSignupForBidSubmissionMutationVariables
>;
export const AgentSignupForMarketplaceSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignupForMarketplaceSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupForMarketplaceSuggestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupForMarketplaceSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSignupForMarketplaceSuggestionMutation,
  AgentSignupForMarketplaceSuggestionMutationVariables
>;
export const AgentSignupForSubcontractorSentEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSignupForSubcontractorSentEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgentSignupForSubcontractorSentEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSignupForSubcontractorSentEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSignupForSubcontractorSentEmailMutation,
  AgentSignupForSubcontractorSentEmailMutationVariables
>;
export const AllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StandardUserRole' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CustomUserRole' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUsersQuery, AllUsersQueryVariables>;
export const CreateAnalyticsEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAnalyticsEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnalyticsEventInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAnalyticsEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAnalyticsEventMutation, CreateAnalyticsEventMutationVariables>;
export const CurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalCompany' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intercomHash' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customSignature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyConfig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contrastColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasOverdueInvoice' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ssoRequired' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isTestCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAgentProfileComplete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnterpriseOpportunity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bidRequestsCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bidRequestsWithSubmittedBidsCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submittedCertificatesCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNetworkSubcontractorsAssociated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullUserLicenseCount' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'guestLicenseCount' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasBillingAccount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contractEndDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'departmentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customDepartmentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActiveCompanyOnboarding' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appAgentSignedContract' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appBiddersAwaitingResponse' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'appBiddingPeriodEnded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appBiddingPeriodEnding' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appBidRequestDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appCertificateExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appCertificateExpiring' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appCertificateNotProvidedInTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appCertificateUploadedBySubcontractor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appContractMultiSigningCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appBidPackageStatusChanged' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'appNewBid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAgentSignedContract' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailBiddersAwaitingResponse' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailBidPackageStatusChanged' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailBiddingPeriodEnded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailBiddingPeriodEnding' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailBidRequestDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailNewBid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailCertificateExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailCertificateExpiring' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailCertificateNotProvidedInTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailCertificateUploadedBySubcontractor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailContractMultiSigningCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailNewMessageFromSubcontractor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailUserAddedToBidPackageTeam' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ssoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaPhoneCensored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'celloToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspaces' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCurrent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssoEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mfaEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mfaRequired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bidsSubmittedOnMarketplace' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premiumEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'permanentFeatureFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;
export const CurrentAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentAdmin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminMe' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentAdminQuery, CurrentAdminQueryVariables>;
export const CheckWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'loginToken' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckWorkspaceQuery, CheckWorkspaceQueryVariables>;
export const SSORedirectUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SSORedirectUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expectedUserStatus' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'USER_STATUS' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPath' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ssoRedirectUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expectedUserStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'expectedUserStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectPath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPath' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SSORedirectUrlQuery, SSORedirectUrlQueryVariables>;
export const BoQUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoQUploadUrl' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boqUploadUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoQUploadUrlQuery, BoQUploadUrlQueryVariables>;
export const CombinedNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CombinedNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeMessageNotifications' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeTaskNotifications' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Notification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AwardBidderReminderNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageBidsDueWeeksAgo' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BiddersAwaitingResponseNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'BiddersAwaitingResponseNotificationV1Props',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bidPackageId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bidPackageName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'bidPackageBidsDueWeeksAgo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projectId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projectName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subcontractorNames' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'BiddersAwaitingResponseNotificationV2Props',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bidPackageId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bidPackageName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projectId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projectName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subcontractorNames' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'durationSinceBidsDue' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'unit' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BiddingPeriodEndingNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidsCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endingInDays' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BidPackageExportBidsNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BidRequestDeclinedNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidRequestId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CertificateExpiringNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificateTypeName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificateTypeId' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expiresInDays' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'CertificateExpiringSubcontractorNotification',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificateTypeName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expiresInDays' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CertificateNotProvidedInTimeNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificateTypeNames' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'CertificateUploadedBySubcontractorNotification',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificateTypeNames' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ContractAgentSignatureRequestedNotification',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'awardedBidId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'officeName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ContractMultiSigningCompletedNotification',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ContractPrincipalSignatureRequestedNotification',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ContractSignedByAgentNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NewBidNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidRequestId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subcontractorName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teamMemberName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skontoDeadline' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'absoluteDiscount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'additionalCosts' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NewMessageNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidRequestId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messageThreadId' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'authorName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'authorType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'InvoiceAwaitingApprovalNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'invoiceName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'InvoiceRejectedNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'invoiceName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'SubcontractorImportRequestCompletedNotification',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'importRequestId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ProjectExportCompleteNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TaskAssignedNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'taskId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'taskName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TaskCompletedNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'taskId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'taskName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'BidPackageStatusChangedNotification' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'props' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bidPackageId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bidPackageName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUnread' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unreadMessageThreadsCount' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeMessageNotifications' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myTasksInStatusCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'EnumValue', value: 'todo' },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeTaskNotifications' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CombinedNotificationsQuery, CombinedNotificationsQueryVariables>;
export const CostGroupDefinitionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CostGroupDefinitions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costGroupType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COST_GROUP_TYPE' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costGroupDefinitions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costGroupType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'costGroupType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'definitions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentLocalId' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'costGroupType' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'costGroupType' },
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostGroupDefinitionsQuery, CostGroupDefinitionsQueryVariables>;
export const CustomCostGroupCatalogDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomCostGroupCatalogDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCostGroupCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childCostGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'childCostGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'localId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomCostGroupCatalogDefinitionQuery,
  CustomCostGroupCatalogDefinitionQueryVariables
>;
export const RequestEditingSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestEditingSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EDITING_SESSION_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestEditingSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newSession' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestEditingSessionMutation, RequestEditingSessionMutationVariables>;
export const AbandonEditingSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AbandonEditingSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abandonEditingSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AbandonEditingSessionMutation, AbandonEditingSessionMutationVariables>;
export const TakeOverEditingSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TakeOverEditingSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EDITING_SESSION_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'takeOverEditingSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TakeOverEditingSessionMutation,
  TakeOverEditingSessionMutationVariables
>;
export const RefreshEditingSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshEditingSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EDITING_SESSION_TYPE' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshEditingSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RefreshEditingSessionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestSessionFailure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSessionFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RequestEditingSessionFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshEditingSessionMutation, RefreshEditingSessionMutationVariables>;
export const ApplicableMultiUserApprovalRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicableMultiUserApprovalRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MULTI_USER_APPROVAL_ACTION' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestProps' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MultiUserApprovalRequestPropsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicableMultiUserApprovalRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestProps' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestProps' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeLimitMinutes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'StandardUserRole' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CustomUserRole' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicableMultiUserApprovalRuleQuery,
  ApplicableMultiUserApprovalRuleQueryVariables
>;
export const MultiUserApprovalRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MultiUserApprovalRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MULTI_USER_APPROVAL_ACTION' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multiUserApprovalRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'props' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'MultiUserApprovalRequestAwardBidProps' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bidId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalCosts' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarding' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'emailTemplate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shouldSend' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'bidRequestId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rejection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'emailTemplate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shouldSend' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'awardedSumType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainOrderNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasApproved' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultiUserApprovalRequestQuery, MultiUserApprovalRequestQueryVariables>;
export const ProjectStructuresForBidPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectStructuresForBidPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bidPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costGroupCatalogs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CostGroupCatalog_costStructure' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostGroupCatalog_costStructure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCostGroupCatalog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'catalogType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCatalog' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectStructuresForBidPackageQuery,
  ProjectStructuresForBidPackageQueryVariables
>;
export const ShowSubcontractorProfilePromptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShowSubcontractorProfilePrompt' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAgentProfileComplete' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShowSubcontractorProfilePromptQuery,
  ShowSubcontractorProfilePromptQueryVariables
>;
export const StlbWorkCategoryDefinitionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StlbWorkCategoryDefinitions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stlbWorkCategoryDefinitions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'definitions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'designation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StlbWorkCategoryDefinitionsQuery,
  StlbWorkCategoryDefinitionsQueryVariables
>;
export const BidDataForSummaryTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BidDataForSummaryTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftBid' },
            name: { kind: 'Name', value: 'sideEffectFreeDraftBid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidPackageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidPackageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skontoDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'absoluteDiscount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxRate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BidDataForSummaryTableQuery, BidDataForSummaryTableQueryVariables>;
export const WorkCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WorkCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WORK_CATEGORY_TYPE_FILTER' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkCategoriesQuery, WorkCategoriesQueryVariables>;
export const WorkCategoriesWithGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WorkCategoriesWithGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WORK_CATEGORY_TYPE_FILTER' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LANGUAGE' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'language' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'translation' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'language' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkCategoriesWithGroupsQuery, WorkCategoriesWithGroupsQueryVariables>;
export const AvailableWorkCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AvailableWorkCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WORK_CATEGORY_TYPE_FILTER' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableWorkCategoriesQuery, AvailableWorkCategoriesQueryVariables>;
export const OutdatedWorkCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OutdatedWorkCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WORK_CATEGORY_TYPE_FILTER' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OutdatedWorkCategoriesQuery, OutdatedWorkCategoriesQueryVariables>;
export const UpdateBidRequestStatusBySubcontractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBidRequestStatusBySubcontractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BID_REQUEST_STATUS' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestStatusBySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBidRequestStatusBySubcontractorMutation,
  UpdateBidRequestStatusBySubcontractorMutationVariables
>;
export const mutationUpdateBidRequestDeclinedReasonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'mutationUpdateBidRequestDeclinedReason' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBidRequestDeclinedReasonByAgentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBidRequestDeclinedReasonBySubcontractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  mutationUpdateBidRequestDeclinedReasonMutation,
  mutationUpdateBidRequestDeclinedReasonMutationVariables
>;
export const ImageUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImageUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storageKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageUploadUrlQuery, ImageUploadUrlQueryVariables>;
export const AgentSendPremiumPurchaseRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AgentSendPremiumPurchaseRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentSendPremiumPurchaseRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgentSendPremiumPurchaseRequestMutation,
  AgentSendPremiumPurchaseRequestMutationVariables
>;
export const SubcontractorsByAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubcontractorsByAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includePremiumFields' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subcontractorsByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AgentSubcontractorForm_subcontractor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactsForSubcontractorForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentSubcontractorForm_subcontractor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subcontractor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRadius' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaStateCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderAreaCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmedByAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'long' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalId' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrationPostalCode' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrationCity' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foundingYear' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerName' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revenueYear' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxId' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearlyRevenue' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePremiumFields' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageKeys' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSustainableConstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sustainableConstructionDetails' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactsForSubcontractorForm' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubcontractorsByAgentQuery, SubcontractorsByAgentQueryVariables>;
export const UpdateSubcontractorWorkCategoriesByAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubcontractorWorkCategoriesByAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workCategories' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkCategoryInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'confirmWorkCategoriesModal' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubcontractorWorkCategoriesByAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bidRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bidRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workCategories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workCategories' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'confirmWorkCategoriesModal' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'confirmWorkCategoriesModal' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubcontractorWorkCategoriesByAgentMutation,
  UpdateSubcontractorWorkCategoriesByAgentMutationVariables
>;
export const UpdateSubcontractorWorkCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubcontractorWorkCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workCategories' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkCategoryInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'confirmWorkCategoriesModal' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubcontractorWorkCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subcontractorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subcontractorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modificationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workCategories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workCategories' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'confirmWorkCategoriesModal' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'confirmWorkCategoriesModal' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubcontractorWorkCategoriesMutation,
  UpdateSubcontractorWorkCategoriesMutationVariables
>;
export const GermanRegistrationCourtsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GermanRegistrationCourts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'germanRegistrationCourts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GermanRegistrationCourtsQuery, GermanRegistrationCourtsQueryVariables>;
export const AuthorizeMultipleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthorizeMultiple' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthorizeInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorizeMultiple' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthorizeMultipleQuery, AuthorizeMultipleQueryVariables>;
