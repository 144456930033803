export class MissingMfaToken extends Error {
  constructor() {
    super('Missing MFA token');
  }
}

export class ErrorDuringSignup extends Error {
  constructor() {
    super('Error during signup');
  }
}
