import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

interface UseRestoreScrollPositionProps {
  /**
   * Element whose scroll position to restore.
   * Leave empty to disable the hook behavior.
   */
  containerRef?: React.MutableRefObject<HTMLElement | null>;
}

/**
 * If a component gets re-rendered its scroll position is reset to the top.
 * This is a problem in infinite scroll scenarios where you add items to a list
 * when you reach the bottom and it jumps to the top again.
 *
 * Use this hook if you know that a DOM change is pending that will cause a
 * re-render of your container and you want to restore the scroll position once
 * the pending DOM changes have applied.
 *
 * @returns a function that allows restoring its vertical scroll position as soon
 * as it breaks.
 */
const useRestoreScrollPosition = ({ containerRef }: UseRestoreScrollPositionProps) => {
  /** The container's `scrollTop` value before it broke. */
  const lastScrollTop = useRef(0);
  /** Whether the scroll position will break and needs to be restored. */
  const [shouldRestore, setShouldRestore] = useState(false);

  const doSave = useCallback(() => {
    if (!containerRef?.current) return;
    lastScrollTop.current = containerRef.current.scrollTop;
  }, [containerRef]);

  const doRestore = useCallback(() => {
    setShouldRestore(false);
    if (!containerRef?.current) return;
    if (lastScrollTop.current === 0) return;
    containerRef.current.scrollTop = lastScrollTop.current;
    lastScrollTop.current = 0;
  }, [containerRef]);

  /**
   * Save the scroll position once we know it will break
   * but before the browser breaks it.
   */
  useLayoutEffect(() => {
    if (!shouldRestore) return;
    doSave();
  }, [shouldRestore, doSave]);

  /** Restore the scroll position after it broke due to repainting. */
  useEffect(() => {
    if (!shouldRestore) return;
    doRestore();
  }, [shouldRestore, doRestore]);

  const restoreScrollPosition = useCallback(() => {
    setShouldRestore(true);
  }, []);

  return restoreScrollPosition;
};

export default useRestoreScrollPosition;
