import { COLOR } from '@cosuno/cosuno-ui';
import React from 'react';

import type { PRICING_PLAN } from '~/__gql__/graphql';

import {
  Card,
  CardWrapper,
  FeaturesItemsList,
  FeaturesSection,
  FeaturesTitle,
  HighlightTag,
  PackageDescription,
  PackageTitle,
  Section,
} from './Styles';

interface Props {
  plan: PRICING_PLAN;
  color: COLOR;
  isHighlighted: boolean;
  highlightTagText: string;
  packageTitle: string;
  packageDescription: string;
  featuresTitle: string;
  featuresItems: string[];
  actionButton: React.ReactNode;
}

export const PricingPlanCard: React.FC<Props> = ({
  plan,
  color,
  isHighlighted,
  highlightTagText,
  packageTitle,
  packageDescription,
  featuresTitle,
  featuresItems,
  actionButton,
}) => (
  <CardWrapper data-cy-pricing-plan={plan} $borderColor={color} $isHighlighted={isHighlighted}>
    {isHighlighted && (
      <HighlightTag data-cy-pricing-plan-recommended $background={color} color={COLOR.white}>
        {highlightTagText}
      </HighlightTag>
    )}
    <Card>
      <Section>
        <PackageTitle $color={color}>{packageTitle}</PackageTitle>
        <PackageDescription>{packageDescription}</PackageDescription>
      </Section>
      <FeaturesSection>
        <FeaturesTitle>{featuresTitle}</FeaturesTitle>
        <FeaturesItemsList>
          {featuresItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </FeaturesItemsList>
      </FeaturesSection>
      {actionButton}
    </Card>
  </CardWrapper>
);
