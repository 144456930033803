import React, { type PropsWithChildren, useLayoutEffect, useRef } from 'react';

import { PROMPT_HEIGHT } from '~/shared/constants';

const DEFAULT_TRANSFORM = 'translate(-100%)';

export const PromptsWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    const updatePromptHeight = () => {
      const height = wrapper.getBoundingClientRect().height ?? 0;
      window.document.documentElement.style.setProperty(PROMPT_HEIGHT, `${height}px`);
      wrapper.style.setProperty('transform', height > 0 ? 'translate(0px)' : DEFAULT_TRANSFORM);
    };

    updatePromptHeight();
    const observer = new ResizeObserver(updatePromptHeight);
    observer.observe(wrapper);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={wrapperRef} style={{ transform: DEFAULT_TRANSFORM }}>
      {children}
    </div>
  );
};
