import { Callout } from '@cosuno/cosuno-ui';
import React, { type ComponentPropsWithoutRef } from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

type CalloutProps = ComponentPropsWithoutRef<typeof Callout>;
type Props = Omit<CalloutProps, 'children'>;

export const UpsellSuccessCallout: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Callout size="compact" variant="success" {...props}>
      {t('upsells.successCallout')}
    </Callout>
  );
};
