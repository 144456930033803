import { COLOR, Icon, Spinner } from '@cosuno/cosuno-ui';
import { zipWith } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import useOnUnmount from '~/shared/hooks/useOnUnmount';
import useTranslation from '~/shared/hooks/useTranslation';
import { getAcceptConfigFromMimeTypes } from '~/shared/utils/acceptFiles';
import { validateImageSize } from '~/shared/utils/images';
import toast from '~/shared/utils/toast';

import { Dropzone, Message, SpinnerContainer, StyledImagesUploader } from './Styles';
import { UploadedImages } from './UploadedImages';

export interface ImagesUploaderProps {
  name: string;
  value: string[] | null;
  disabled?: boolean;
  uploadImage: (
    file: File,
    fetchOptions: {
      headers: {
        'Content-Type': string;
      };
    },
  ) => Promise<{ storageKey: string }>;
  onChange: (storageKeys: string[] | null) => void;
}

const ImagesUploader: React.FC<ImagesUploaderProps> = ({
  name,
  value,
  disabled = false,
  uploadImage,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const [previews, setPreviews] = useState<Array<{ imageKey?: string; previewUrl: string }>>([]);
  const [isUploading, setUploading] = useState(false);

  const handleDrop = async (files: File[]) => {
    if (files.length === 0) return;

    const isValid = validateImageSize(files, t);
    if (!isValid) return;

    setUploading(true);

    const newPreviews = files.map((file) => ({ previewUrl: URL.createObjectURL(file) }));
    setPreviews(newPreviews);

    try {
      const uploadedImages = await Promise.all(
        files.map((file) => uploadImage(file, { headers: { 'Content-Type': file.type } })),
      );

      onChange([...(value || []), ...uploadedImages.map(({ storageKey }) => storageKey)]);

      setPreviews(
        zipWith(uploadedImages, newPreviews, ({ storageKey }, { previewUrl }) => ({
          imageKey: storageKey,
          previewUrl,
        })),
      );
    } catch (error) {
      toast.error(error);
    }

    setUploading(false);
  };

  const dropzone = useDropzone({
    accept: getAcceptConfigFromMimeTypes(['image/*']),
    multiple: true,
    disabled,
    onDrop: (acceptedFiles) => void handleDrop(acceptedFiles),
  });

  useOnUnmount(() => {
    previews.forEach(({ previewUrl }) => URL.revokeObjectURL(previewUrl));
  });

  const hasFiles = Boolean(previews.length > 0 || (value && value.length > 0));
  const images = useMemo(
    () => [
      ...(value || []).map((imageKey) => ({
        imageKey,
        previewUrl: previews.find((preview) => preview.imageKey === imageKey)?.previewUrl,
      })),
      ...previews.filter((preview) => !preview.imageKey),
    ],
    [value, previews],
  );

  return (
    <StyledImagesUploader
      data-cy-images-uploader={name}
      $hasFiles={hasFiles}
      $disabled={disabled}
      {...props}
    >
      {isUploading && (
        <SpinnerContainer>
          <Spinner color={COLOR.textTertiary} size={60} />
        </SpinnerContainer>
      )}
      <Dropzone {...dropzone.getRootProps()} $paddingBottom={hasFiles ? 10 : 25}>
        <input {...dropzone.getInputProps()} disabled={disabled} />
        <Message>
          <Icon type="upload" />
          {t('imageUploader.uploadImages')}
        </Message>
      </Dropzone>
      {hasFiles && (
        <UploadedImages
          images={images}
          removePreview={(previewUrl) => {
            setPreviews(previews.filter((preview) => preview.previewUrl !== previewUrl));
            URL.revokeObjectURL(previewUrl);
          }}
          removeImage={(imageKey) => {
            onChange(value && value.filter((valueImageKey) => valueImageKey !== imageKey));
          }}
        />
      )}
    </StyledImagesUploader>
  );
};

export default ImagesUploader;
export { IMAGE_UPLOADER_IMAGE_OPTIONS } from './UploadedImages';
