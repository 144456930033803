import genZIndexValues from './genZIndexValues';

export const zIndexValues = genZIndexValues(
  'stickyToolbar',
  'tableItemDropdown',
  'tableLoadingIndicator',
  'topbar',
  'scLandingPageBidBar',
  'priceAssistant',
  'navbar',
  'stickyHeader',
  'promptBar',
  'documentsTableProgress',
  ['intercom', 2147483001],
  'modalOverlayInactive',
  'modalInactive',
  'modalOverlay',
  'modal',
  'modalContent',
  'modalCloseIcon',
  'lightbox',
  'downloadPreparationMessage',
  'overlayBackground',
  'autocompleteSuggestions',
  'topLevelModalOverlay',
  'topLevelModal',
  'draggedTableRow',
  'overlayElement',
  'toast',
  'appOutdatedNotification',
  'notifications',
  'dragMask',
  'loadingOverlay',
);
