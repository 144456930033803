import { PageLoader } from '@cosuno/cosuno-ui';
import loadable, { type DefaultComponent } from '@loadable/component';

const maxRetries = 10;

function retry<Props>(fn: () => Promise<DefaultComponent<Props>>, { attempt = 0 } = {}) {
  return new Promise<DefaultComponent<Props>>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        const remainingAttempts = maxRetries - attempt;
        if (remainingAttempts === 0) {
          reject(error);
          return;
        }

        // Log error for debugging purposes
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line no-console
        console.warn(
          `Loading chunk failed. Will retry ${remainingAttempts} more times. Retrying...`,
        );

        setTimeout(
          () => {
            retry(fn, { attempt: attempt + 1 }).then(resolve, reject);
          },
          100 * 2 ** attempt,
        );
      });
  });
}

/**
 * Lazy loads component via @loadable/component.
 * Retries up to 4 times on failure and displays a loading indicator as fallback.
 */
export function lazyLoad<Props>(fn: () => Promise<DefaultComponent<Props>>) {
  return loadable<Props>(() => retry(fn), {
    fallback: <PageLoader />,
  });
}
