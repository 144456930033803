import styled from 'styled-components';

import { font } from '~/shared/utils/styles';

// We have to cast to preserve generic parameters
export const ModalContent = styled.div`
  padding: 30px 40px 40px;
`;

export const Title = styled.div`
  padding-bottom: 20px;
  ${font.bold}
  ${font.size(24)}
  line-height: 1.5;
`;
