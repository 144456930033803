import { FullStory } from '@fullstory/browser';
import { snakeCase } from 'change-case';
import { useCallback, useContext, useMemo } from 'react';
import type { XOR } from 'ts-essentials';

import { COMPANY_TYPE, type CurrentUserQuery, SALESFORCE_PACKAGE_TYPE } from '~/__gql__/graphql';
import type { Experiments } from '~/shared/components/Experiment';
import AnalyticsEntryPointContext, {
  type ANALYTICS_ENTRY_POINT,
} from '~/shared/contexts/analyticsEntryPointContext';
import { AnalyticsExperimentsContext } from '~/shared/contexts/analyticsExperimentsContext';
import AuthContext from '~/shared/contexts/authContext';
import { createConsole } from '~/shared/utils/console';
import { env } from '~/shared/utils/env';
import { getUserName } from '~/shared/utils/names';
import { convertObjectKeysToSnakeCase } from '~/shared/utils/object';

import useAnalyticsEventStore from '../useAnalyticsEventStore';
import type {
  AddBidderTrackingProps,
  AnalyticsEvent,
  AnalyticsEventProps,
  BidRequestPageProps,
  MessagesProps,
  MultiUserApprovalProps,
  ProjectsBidPackageProps,
  SubcontractorBidProps,
  SubcontractorsSearchProps,
} from './events';
import type { Analytics, Mixpanel } from './types';
import { storeEventForCypress } from './utils';

type CurrentUser_me = CurrentUserQuery['me'];

declare let window: Window & {
  analytics?: Analytics;
  mixpanel?: Mixpanel;
};

export const UNSET_ENTRY_POINT = Symbol('unset_entry_point');

type TrackEventOptions = {
  overrideEntryPoint?: ANALYTICS_ENTRY_POINT | typeof UNSET_ENTRY_POINT;
} & XOR<
  {
    onlyGoogleAnalytics?: boolean;
  },
  { excludeGoogleAnalytics?: boolean }
>;

const fetchDeviceId = () => {
  const deviceId = window.mixpanel?.get_property?.('$device_id');

  return deviceId ? String(deviceId) : undefined;
};

interface UseContextlessAnalyticsProps {
  isSuperAdmin: boolean;
  contextEntryPoint: ANALYTICS_ENTRY_POINT | undefined;
  experiments: Partial<Experiments> | undefined;
}

const getPlanFromUser = (user: CurrentUser_me) => {
  if (user.company.type === COMPANY_TYPE.agent) {
    return user.company.config.premiumEnabled ? 'Premium' : 'No pricing package';
  }

  const { packageType } = user.company;

  if (packageType) {
    return {
      [SALESFORCE_PACKAGE_TYPE.bronze]: 'Bronze',
      [SALESFORCE_PACKAGE_TYPE.silver]: 'Silver',
      [SALESFORCE_PACKAGE_TYPE.gold]: 'Gold',
      [SALESFORCE_PACKAGE_TYPE.enterprise]: 'Enterprise',
      [SALESFORCE_PACKAGE_TYPE.noPricingPackage]: 'Licence-based',
    }[packageType];
  }

  return 'No associated plan';
};

/**
 * Use this hook in the bootstrapping phase when Contexts are not yet
 * initialized.
 */
const useContextlessAnalytics = ({
  isSuperAdmin,
  contextEntryPoint,
  experiments,
}: UseContextlessAnalyticsProps) => {
  const { createAnalyticsEvent } = useAnalyticsEventStore();

  const trackPageview = useCallback(() => {
    if (isSuperAdmin) return;

    const event = {
      name: 'pageView',
      props: { path: window.location.pathname },
    } as const;

    storeEventForCypress(event);
    void createAnalyticsEvent(event.name, event.props, fetchDeviceId());

    if (!window.analytics) return;

    // Segment.com tracking
    // The title property needs to be overwritten because otherwise the page title
    // is used automatically which is not correct because by the time the route changes
    // the page title has not been updated yet. It is better to have no title than to have
    // an incorrect title.
    window.analytics.page({ title: '' });
  }, [isSuperAdmin, createAnalyticsEvent]);

  /**
   * Tracks an analytics event.
   *
   * We allow passing `undefined` as the second argument even when an event
   * requires some props. That's because there is a very widespread code pattern
   * where we pass `trackingInfo` as event props, and this object is typically
   * taken from a React Context. To properly handle the fact that `trackingInfo`
   * can be `undefined`, we'd have throw an error or wrap the call to
   * `trackEvent` in a conditional every time. However, since that requires more
   * code, while not being very useful (i.e. we end up with missing events
   * etc.), we just accept that props may be missing.
   */
  const trackEvent = useCallback(
    <E extends AnalyticsEvent>(
      event: E,
      ...[propsWithInaccurateType, options]: AnalyticsEventProps[E] extends undefined
        ? [props?: AnalyticsEventProps[E] | undefined, options?: TrackEventOptions]
        : [props: AnalyticsEventProps[E] | undefined, options?: TrackEventOptions]
    ) => {
      // `propsWithInaccurateType` has a type `AnalyticsEventProps[E] |
      // AnalyticsEventProps[E] | undefined`, which for some reason is not
      // assignable to `Record<string, unknown>`, while `AnalyticsEventProps[E]
      // | undefined` is. This may be a bug in Typescript 5.1.
      const props: AnalyticsEventProps[E] | undefined = propsWithInaccurateType;
      const entryPoint = options?.overrideEntryPoint ?? contextEntryPoint;
      const propsWithGlobals = {
        ...props,
        experiments,
        entryPoint:
          entryPoint && entryPoint !== UNSET_ENTRY_POINT ? snakeCase(entryPoint) : undefined,
      };

      const Console = createConsole(!isSuperAdmin);

      Console.groupCollapsed('[Analytics] Tracking event:', event);
      if (options) Console.log('[Analytics] Tracking options:', options);
      if (propsWithGlobals.entryPoint) {
        Console.log('[Analytics] Entry point:', propsWithGlobals.entryPoint);
      }
      if (props) {
        Console.log('[Analytics] Props:');
        Console.table(props);
      }
      if (propsWithGlobals.experiments) {
        Console.log('[Analytics] Active experiments:');
        Console.table(propsWithGlobals.experiments);
      }
      Console.groupEnd();

      if (isSuperAdmin) return;

      const integrations = (() => {
        if (options?.excludeGoogleAnalytics) return { 'Google Analytics': false };
        if (options?.onlyGoogleAnalytics) return { All: false, 'Google Analytics': true };
        return { All: true };
      })();

      storeEventForCypress({
        name: event,
        props: propsWithGlobals,
      });
      void createAnalyticsEvent(event, propsWithGlobals, fetchDeviceId());

      const eventName = snakeCase(event);
      const eventProps = convertObjectKeysToSnakeCase(propsWithGlobals);

      if (!window.analytics) return;

      window.analytics.track(eventName, eventProps, { integrations });

      if (env.REACT_APP_FULLSTORY_ORG_ID) {
        FullStory('trackEvent', { name: eventName, properties: eventProps });
      }
    },
    [isSuperAdmin, contextEntryPoint, createAnalyticsEvent, experiments],
  );

  const trackUserIdentity = useCallback((user: CurrentUser_me) => {
    const { firstName, lastName, language, email, company, intercomHash, type } = user;
    const userInfo = {
      firstName,
      lastName,
      email,
      company: {
        id: company.id,
        name: company.name,
        type: company.type,
        plan: getPlanFromUser(user),
        tags: company.tags,
      },
      type,
      language,
    };

    storeEventForCypress({
      name: 'identify',
      props: { userId: user.id, userInfo },
    });

    if (!window.analytics) return;

    window.analytics.identify(user.id, userInfo, {
      integrations: {
        Intercom: {
          user_hash: intercomHash,
        },
      },
    });

    if (env.REACT_APP_FULLSTORY_ORG_ID) {
      FullStory('setIdentity', {
        uid: user.id,
        properties: { ...userInfo, displayName: getUserName(userInfo) },
      });
    }
  }, []);

  const resetUserIdentity = useCallback(() => {
    if (!window.analytics) return;

    window.analytics.reset();

    if (env.REACT_APP_FULLSTORY_ORG_ID) {
      FullStory('setIdentity', { anonymous: true });
    }
  }, []);

  const load = useCallback((segmentKey: string) => {
    if (!window.analytics?.load) return;

    window.analytics.load(segmentKey);

    if (env.REACT_APP_FULLSTORY_ORG_ID) {
      FullStory('start');
    }
  }, []);

  return useMemo(
    () => ({ load, trackPageview, trackEvent, trackUserIdentity, resetUserIdentity }),
    [load, trackPageview, trackEvent, trackUserIdentity, resetUserIdentity],
  );
};

const useAnalytics = () => {
  const contextEntryPoint = useContext(AnalyticsEntryPointContext);
  const { experiments } = useContext(AnalyticsExperimentsContext);
  const { isSuperAdmin } = useContext(AuthContext);

  return useContextlessAnalytics({
    isSuperAdmin,
    contextEntryPoint,
    experiments,
  });
};

export type {
  AddBidderTrackingProps,
  Analytics,
  AnalyticsEvent,
  AnalyticsEventProps,
  BidRequestPageProps,
  MessagesProps,
  MultiUserApprovalProps,
  ProjectsBidPackageProps,
  SubcontractorBidProps,
  SubcontractorsSearchProps,
};

export { getInformedBiddersPropValue } from './utils';
export { useContextlessAnalytics };
export default useAnalytics;
