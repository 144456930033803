import moment from 'moment';

import {
  type AnyDate,
  HUMAN_READABLE_DATE_FORMAT,
  HUMAN_READABLE_DATE_TIME_FORMAT,
  STANDARD_DATE_FORMAT,
  STANDARD_TIME_FORMAT,
} from '~/shared/constants/dateTime';

export const formatDate = (date: AnyDate): string =>
  date ? moment(date).format(STANDARD_DATE_FORMAT) : date;

export const formatTime = (date: AnyDate): string =>
  date ? moment(date).format(STANDARD_TIME_FORMAT) : date;

export const formatDateTime = (date: AnyDate) => (date ? moment(date).calendar() : date);

export const formatDateForAPI = (date: AnyDate): string =>
  date ? moment(date).format('YYYY-MM-DD') : date;

export const formatDateHumanReadable = (date: AnyDate): string =>
  date ? moment(date).format(HUMAN_READABLE_DATE_FORMAT) : date;

export const formatDateTimeHumanReadable = (date: AnyDate): string =>
  date ? moment(date).format(HUMAN_READABLE_DATE_TIME_FORMAT) : date;
