import {
  Button,
  ButtonGroup,
  COLOR,
  DropdownMenu,
  mixinHtml,
  Option,
  SelectSingle,
  textStyles,
} from '@cosuno/cosuno-ui';
import { AllStyledComponent } from '@remirror/styles/styled-components';
import styled, { css } from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

const remirrorParagraphLineHeight = 21;
const verticalPadding = 9;
const borderHeight = 1;

export const StyledMenuButton = styled(Button)`
  border: 0 none;
  background: transparent;
  color: ${COLOR.textSecondary};
  transition-property: none;

  ${(props) =>
    props['aria-pressed'] &&
    css`
      background: ${COLOR.brandLighterAlpha};
      color: ${COLOR.brand};

      [data-whatinput='mouse'] &:focus {
        border: none;
        outline: none;
      }
    `};
`;

export const MenuButtonGroup = styled(ButtonGroup)`
  padding: 6px 5px;
  border: 1px solid ${COLOR.borderLight};
  border-bottom-width: 0;
  border-radius: 8px 8px 0 0;
  background: ${COLOR.backgroundLight};
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Separator = styled.div`
  border-right: 1px solid ${COLOR.borderLight};
  height: 20px;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const StyledTextEditor = styled(AllStyledComponent)<{
  $invalid: boolean;
  $disabled: boolean;
  $fixedParagraphLineHeight: boolean;
}>`
  .remirror-editor-wrapper {
    ${font.family}
    padding-top: 0;
  }

  .remirror-editor.ProseMirror {
    ${textStyles.bodyMedium450}
    ${mixinHtml()}

    /* placeholder styling */
    .remirror-is-empty::before {
      color: ${COLOR.textTertiary};
      font-style: normal;
    }

    p {
      ${({ $fixedParagraphLineHeight }) =>
        $fixedParagraphLineHeight
          ? css`
              line-height: ${remirrorParagraphLineHeight}px;
            `
          : css`
              min-height: ${remirrorParagraphLineHeight}px;
            `}
    }

    a {
      text-decoration: underline;
    }

    box-shadow: none;
    border: ${borderHeight}px solid ${COLOR.borderLight};
    border-radius: 0 0 8px 8px;
    padding: ${verticalPadding}px 12px;
    overflow-y: auto;
    background: ${COLOR.white};
    min-height: ${5 * remirrorParagraphLineHeight + 2 * verticalPadding + 2 * borderHeight}px;

    ${(props) =>
      props.$disabled &&
      css`
        background-color: ${COLOR.brandLightestAlpha};
        pointer-events: none;
      `}

    &:focus {
      box-shadow: none;
      border: 1px solid ${COLOR.highlightNeon};
      outline: 2px solid ${COLOR.brandLightAlpha};
    }

    ${({ $invalid }) =>
      $invalid &&
      css`
        border: 1px solid ${COLOR.danger};

        &:focus {
          border: 1px solid ${COLOR.danger};
          outline-color: ${mixins.rgba(COLOR.danger, 0.2)};
        }
      `}
  }
`;

export const StyledFontFamilySelect = styled(SelectSingle)`
  width: 150px;
`;

export const StyledFontSizeSelect = styled(SelectSingle)`
  width: 80px;
`;

export const StyledFontFamilySelectOption = styled(Option)<{ $fontFamily?: string }>`
  ${({ $fontFamily }) =>
    $fontFamily &&
    css`
      font-family: ${$fontFamily};
    `}
`;

export const StyledColorButton = styled(Button)<{ $forceIconColor: string }>`
  svg {
    color: ${({ $forceIconColor }) => $forceIconColor};
  }
`;

export const StyledColorDropdownAction = styled(DropdownMenu.Action)<{ $forceIconColor: string }>`
  svg {
    color: ${({ $forceIconColor }) => $forceIconColor};
  }
`;
