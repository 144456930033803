import { useMemo } from 'react';

import type { InfiniteScrollProps } from '~/shared/components/InfiniteScroll';
import type { QueryVariables } from '~/shared/components/TableWithFetch';
import type { FetchMore } from '~/shared/types/graphql';

interface Props {
  itemsCount: number;
  totalRecords: number;
  fetchMore?: FetchMore;
  variables?: QueryVariables<unknown> | null;
  loadMoreOffset?: number;
  isLoading: boolean;
}

const useInfiniteScroll = ({
  itemsCount,
  totalRecords,
  fetchMore,
  variables,
  loadMoreOffset = 100,
  isLoading,
}: Props): InfiniteScrollProps =>
  useMemo(() => {
    const hasMore = itemsCount < totalRecords;

    const onLoadMore = async () => {
      if (hasMore && !isLoading && fetchMore) {
        await fetchMore({
          variables: { ...variables, offset: itemsCount },
        });
      }
    };

    return { hasMore, isLoadingMore: isLoading, onLoadMore, loadMoreOffset };
  }, [fetchMore, isLoading, itemsCount, loadMoreOffset, totalRecords, variables]);

export default useInfiniteScroll;
