import type {
  ExecutionDescriptionDto,
  IElementDto,
  PositionDto,
} from '@dangl/avacloud-client-node';

import type {
  BOQ_ELEMENT_TYPE_DISCRIMINATOR,
  BOQ_POSITION_TEXT_PROVIDER,
  EditRequestForProposalsBoQInput,
} from '~/__gql__/graphql';
import type { SURCHARGE_TYPE } from '~/packages/BoQ/constants';
import type { PushInput } from '~/shared/hooks/useBatchedUpdates';
import type { ModalStateWithParamsData } from '~/shared/hooks/useModalStateWithParams';

import type { OperationDescriptor } from '../../types/BoQEditor';
import type { ToggleablePositionType } from '../../types/positionTypes';
import type {
  BoQContentWithSchema,
  EditableElementType,
  ElementDescriptor,
  ElementFieldName,
  FieldChangeParameters,
  ParsedElement,
  ValidatedBoQContent,
} from '../../types/shared';

export interface ElementInput {
  id: string;
  elementTypeDiscriminator: BOQ_ELEMENT_TYPE_DISCRIMINATOR;
}

export type GetElementAtIndex = (index: number) => ParsedElement | undefined;
export type GetElementDescriptorForPath = (path: string) => ElementDescriptor | undefined;

export type IsElementOpen = (id: string) => boolean;

export type PersistOperation = PushInput<EditRequestForProposalsBoQInput>;

/**
 * Applies changes to mutable content to the current `BoQ` state.
 *
 * Re-creates the mutable content so that changes to it stay outside
 * of the BoQ editor state and will not cause an immediate re-render.
 */
export type Rerender = () => void;

export type ClearFieldWithoutRendering = (fieldName: string, path: string) => void;

export type FillOutFieldWithoutRendering = (
  newValue: string,
  fieldName: string,
  path: string,
) => void;

export type DeleteBoQ = () => Promise<void>;

export type DeleteSelectedElementsResult = {
  undoSteps: OperationDescriptor[];
  becameEmpty: boolean;
} | null;

export type DeleteSelectedElements = (props?: {
  onEmptyDeleteBoQ?: boolean;
  onEmptyPreventRerender?: boolean;
  onEmptyPreventPersistence?: boolean;
}) => Promise<DeleteSelectedElementsResult>;

export type AppendElement = (
  newElementType: BOQ_ELEMENT_TYPE_DISCRIMINATOR,
  tierIndex?: number,
) =>
  | {
      createdElements: ElementDescriptor[];
      isPartialImport: boolean;
    }
  | undefined;

export type MoveSelectedElements = (targetRowIndexInVisible: number) => Promise<void>;

export type ToggleSelectedPositionTypes = (
  positionType: ToggleablePositionType,
  elementDescriptors: ElementDescriptor[],
) => {
  undoSteps: OperationDescriptor[];
};

export type ToggleExecutionDescriptionReference = (
  positionDescriptors: ElementDescriptor[],
  executionDescription: ExecutionDescriptionDto | null,
) => {
  undoSteps: OperationDescriptor[];
};

export type ToggleReferralDescriptionReference = (
  positionDescriptors: ElementDescriptor[],
  referralDescription: PositionDto | null,
) => {
  undoSteps: OperationDescriptor[];
};

export type ToggleSurchargePositionReference = (
  positionDescriptors: ElementDescriptor[],
  positionsToSurcharge: PositionDto[] | null,
  surchargeType?: SURCHARGE_TYPE,
) => {
  undoSteps: OperationDescriptor[];
};

export type NormalizeSurchargePositionsAfterChange = () => {
  undoSteps: OperationDescriptor[];
};

export type GenerateOperationsToRemoveSurcharge = (positionDescriptors: ElementDescriptor[]) => {
  operations: OperationDescriptor[];
  undoSteps: OperationDescriptor[];
};

export type AddSubDescription = (parentPosition: PositionDto) => {
  undoSteps: OperationDescriptor[];
};

export type DeleteSubDescription = (
  parentPosition: PositionDto,
  subDescriptionIndex: number,
) => {
  undoSteps: OperationDescriptor[];
};

export type FieldChangeHandler<
  TType extends EditableElementType,
  TRequiredField extends ElementFieldName<TType> = ElementFieldName<TType>,
  TParams extends Partial<FieldChangeParameters> | undefined = undefined,
> = TParams extends {}
  ? (
      newValue: string,
      fieldName: ElementFieldName<TType> & TRequiredField,
      parameters: TParams,
    ) => void
  : (newValue: string, fieldName: ElementFieldName<TType> & TRequiredField) => void;

export interface ParsedBoQ {
  elements: ParsedElement[];
}

export type TextProviderImportHandler<
  T extends BOQ_POSITION_TEXT_PROVIDER = BOQ_POSITION_TEXT_PROVIDER,
> = <S extends IElementDto>(
  data: Array<S>,
  textProvider: T,
  options?: {
    /**
     * Indicates that the elements to be added should be inserted at the exact
     * paths specified in their corresponding `itemNumber`. This means that
     * the import callback should not try to insert the new elements under
     * the currently selected row and should not generate new item numbers.
     * What happens in case there already exists items with the same number in
     * the importing BoQ is up to the caller.
     */
    preserveHierarchy?: boolean;
    sourceContent?: ValidatedBoQContent & BoQContentWithSchema;
    parsedSourceContent?: ParsedBoQ;
  },
) => Promise<void>;

export interface UnitPriceComponentsModalParams {
  elementsToApplyPositionTypeOnSave?: ElementDescriptor[];
}

export type UnitPriceComponentsModalState =
  ModalStateWithParamsData<UnitPriceComponentsModalParams>;

export interface ConfirmRemoveUnitPriceComponentsModalParams {
  elementsToRemovePositionType: ElementDescriptor[];
}

export type ConfirmRemoveUnitPriceComponentsModalState =
  ModalStateWithParamsData<ConfirmRemoveUnitPriceComponentsModalParams>;

export interface ConfirmClearStandardizedDescriptionModalParams {
  onConfirm: () => void;
  onCancel: () => void;
}

export type ConfirmClearStandardizedDescriptionModalState =
  ModalStateWithParamsData<ConfirmClearStandardizedDescriptionModalParams>;

export enum ADDITIONAL_TEXT_POSITION {
  opening = 'opening',
  closing = 'closing',
}

interface BaseDropTarget {
  isOpen: boolean;
}

export interface RootLevelDropTarget extends BaseDropTarget {
  path: null;
}

export interface ElementBasedDropTarget extends BaseDropTarget {
  path: string;
}

export type DropTarget = RootLevelDropTarget | ElementBasedDropTarget;

export enum MOVE_DESTINATION_TYPE {
  within = 'within',
  after = 'after',
}
