import { Checkbox, COLOR } from '@cosuno/cosuno-ui';
import { type FC, useRef } from 'react';

import NewTag from '~/shared/components/NewTag';
import type { WorkCategoryInputWithId } from '~/shared/types/workCategories';

import { CollapseExpandIcon, CountTag, GroupBody, GroupHeader, GroupWrapper } from './Styles';
import type { GroupsForDisplay } from './types';

type Props = GroupsForDisplay[number] & {
  isExpanded: boolean;
  setExpanded: (group: string | null) => void;
  setValue: (value: WorkCategoryInputWithId[]) => void;
  selected: WorkCategoryInputWithId[];
  showIsNewIndicators?: boolean;
};

export const SingleGroup: FC<Props> = ({
  id,
  name,
  isExpanded,
  setExpanded,
  selectedCount,
  setValue,
  workCategories,
  selected,
  showIsNewIndicators = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <GroupWrapper ref={ref} key={id}>
      <GroupHeader
        onClick={() => {
          setExpanded(isExpanded ? null : id);
          if (!isExpanded) {
            // Execute scroll after 50ms to allow for the parent to expand before scrolling to the top
            setTimeout(
              () => ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' }),
              50,
            );
          }
        }}
      >
        <CollapseExpandIcon type={isExpanded ? 'triangle-down' : 'triangle-right'} />
        <span>{name}</span>
        {!isExpanded && selectedCount > 0 && (
          <CountTag color={COLOR.highlightNeon}>{selectedCount}</CountTag>
        )}
      </GroupHeader>
      <GroupBody $expanded={isExpanded}>
        {workCategories.map((workCategory) => (
          <Checkbox
            key={workCategory.id}
            checkboxLabel={
              <span>
                {workCategory.name}
                {showIsNewIndicators && workCategory.isNew && <NewTag />}
              </span>
            }
            checked={workCategory.selected}
            onChange={(checked) =>
              setValue(
                checked
                  ? [...selected, workCategory]
                  : selected.filter(({ id: categoryId }) => categoryId !== workCategory.id),
              )
            }
          />
        ))}
      </GroupBody>
    </GroupWrapper>
  );
};
