import { Input } from '@cosuno/cosuno-ui';
import { type ReactNode, useLayoutEffect, useState } from 'react';

import Form from '~/shared/components/Form';
import { Heading, SubmitButton } from '~/shared/components/UnauthenticatedStyles';
import useTranslation from '~/shared/hooks/useTranslation';

import type { LoginSuggestion } from '../types';
import { ErrorMessages, type LoginError } from './ErrorMessages';
import { LoginSuggestionsList } from './LoginSuggestionsList';

interface FormValues {
  suggestion: LoginSuggestion | null;
}

interface RequestEmailStepProps {
  onSubmit: (values: FormValues) => void;
  title: string;
  explanation: [ReactNode, ReactNode];
  isSubmitting: boolean;
  error: LoginError;
  /** User's email if it has been provided previously. */
  alreadyProvidedEmail: string | undefined;
  resendConfirmationEmail: (email: string) => Promise<void>;
  loginSuggestions: LoginSuggestion[];
  isLoading?: boolean;
}

export const RequestEmailStep: React.FC<RequestEmailStepProps> = ({
  onSubmit,
  title,
  explanation,
  isLoading,
  loginSuggestions,
  error,
  alreadyProvidedEmail,
  resendConfirmationEmail,
}) => {
  const { t } = useTranslation();
  const [hasResentConfirmationEmail, setHasResentConfirmationEmail] = useState(false);

  const matchingSuggestion = loginSuggestions?.find(
    (suggestion) => suggestion.email === alreadyProvidedEmail,
  );
  const firstSuggestion = loginSuggestions?.[0];

  useLayoutEffect(() => {
    if (matchingSuggestion) {
      void onSubmit({ suggestion: matchingSuggestion });
    }
  }, [onSubmit, matchingSuggestion]);

  if (isLoading || matchingSuggestion) {
    return null;
  }

  const hasAtLeastOneSuggestion = Boolean(firstSuggestion);
  const suggestionFromEmail = alreadyProvidedEmail
    ? {
        firstName: null,
        lastName: null,
        email: alreadyProvidedEmail,
      }
    : null;

  return (
    <Form<FormValues>
      initialValues={{
        suggestion: matchingSuggestion ?? firstSuggestion ?? suggestionFromEmail,
      }}
      validations={{ suggestion: [Form.is.required()] }}
      onSubmit={(formValues) => {
        onSubmit(formValues);
        setHasResentConfirmationEmail(false);
      }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <>
          <ErrorMessages
            error={error}
            hasResentConfirmationEmail={hasResentConfirmationEmail}
            resendConfirmationEmail={async () => {
              await resendConfirmationEmail(values.suggestion?.email ?? '');
              setHasResentConfirmationEmail(true);
            }}
          />
          {explanation[0]}
          <Heading>{title}</Heading>
          {explanation[1]}
          {!hasAtLeastOneSuggestion && (
            <>
              <Input
                value={values.suggestion?.email ?? ''}
                onChange={async (value) => {
                  await setFieldValue('suggestion', {
                    firstName: null,
                    lastName: null,
                    email: value,
                  });
                }}
                placeholder={t('email')}
                name="email"
                type="email"
                icon="email"
              />
              <SubmitButton
                onClick={async () => {
                  await submitForm();
                }}
                type="submit"
                working={isLoading}
              >
                {t('continue')}
              </SubmitButton>
            </>
          )}
          {hasAtLeastOneSuggestion && (
            <LoginSuggestionsList
              autoChooseSingleSuggestion
              options={loginSuggestions ?? []}
              onSelectChange={async (suggestion) => {
                await setFieldValue('suggestion', suggestion);
                await submitForm();
              }}
            />
          )}
        </>
      )}
    </Form>
  );
};
