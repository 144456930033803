import useImmutableSetState from './useImmutableSetState';

export interface DownloadPreparationManagerMethods {
  startDownloadPreparation: (id: string) => void;
  stopDownloadPreparation: (id: string) => void;
  getIsPreparingDownload: (id: string) => boolean;
  isPreparingAnyDownload: boolean;
}

const useDownloadPreparationManager = (): DownloadPreparationManagerMethods => {
  const {
    addItem: startDownloadPreparation,
    removeItem: stopDownloadPreparation,
    hasItem: getIsPreparingDownload,
    itemsCount,
  } = useImmutableSetState<string>();

  const isPreparingAnyDownload = itemsCount > 0;

  return {
    startDownloadPreparation,
    stopDownloadPreparation,
    getIsPreparingDownload,
    isPreparingAnyDownload,
  };
};

export default useDownloadPreparationManager;
