import { ExternalLink } from '@cosuno/cosuno-ui';
import type { FC } from 'react';

import { agentLearnMoreUrl } from '~/shared/constants/urls';
import useTranslation from '~/shared/hooks/useTranslation';

const UTM_MEDIUM = 'sign-up-form';

export const AgentSignupLearnMoreLink: FC<{ utmCampaign: string }> = ({
  utmCampaign,
  ...props
}) => {
  const { i18n } = useTranslation('agentSignupForm');

  return <ExternalLink {...props} to={agentLearnMoreUrl(UTM_MEDIUM, utmCampaign, i18n.language)} />;
};
