import { Callout, MiniTooltip, Table } from '@cosuno/cosuno-ui';

import useModalState from '~/shared/hooks/useModalState';
import useTranslation from '~/shared/hooks/useTranslation';
import type { WorkCategoryInputForSubcontractorWithId } from '~/shared/types/workCategories';

import AddMoreLink from '../AddMoreLink';
import { GroupedWorkCategoriesModal } from '../GroupedWorkCategoriesModal';
import { type SuggestionHints, WorkCategorySuggestions } from '../WorkCategorySuggestions';
import { Row } from './Row';
import {
  AddMoreWrapper,
  EmptyPlaceholder,
  InvalidColumnHeader,
  InvalidSelectionMessage,
} from './Styles';

export interface AgentWorkCategoriesFieldProps {
  isReadOnly?: boolean;
  onChange: (value: WorkCategoryInputForSubcontractorWithId[]) => void;
  required?: boolean;
  value: WorkCategoryInputForSubcontractorWithId[];
  invalid?: boolean;
  suggestionHints?: SuggestionHints;
}

export const AgentWorkCategoriesField = ({
  isReadOnly,
  onChange,
  value,
  invalid,
  suggestionHints,
}: AgentWorkCategoriesFieldProps) => {
  const { t } = useTranslation();

  const modalState = useModalState();

  return (
    <div data-cy-work-categories>
      {!isReadOnly && (
        <>
          <GroupedWorkCategoriesModal
            modalState={modalState}
            onChange={(inputs) =>
              onChange(
                inputs.map((input) => {
                  const existingCategory = value.find((item) => item.id === input.id);
                  return { ...input, isPrimary: existingCategory?.isPrimary ?? false };
                }),
              )
            }
            value={value}
          />
          <Callout marginBottom size="compact">
            {t('subcontractor.workCategories.primarySecondaryExplanation')}
          </Callout>
        </>
      )}
      <Table
        columns={[
          { label: t('entities.workCategory'), widthFractions: 2 },
          {
            alignItems: 'center',
            isPrimary: true,
            label: invalid ? (
              <MiniTooltip<HTMLSpanElement>
                isTriggerElementClickable={false}
                bodyText={t('validation:required')}
              >
                {({ ref, getReferenceProps }) => (
                  <InvalidColumnHeader ref={ref} {...getReferenceProps()}>
                    {t('subcontractor.workCategories.primary')}
                  </InvalidColumnHeader>
                )}
              </MiniTooltip>
            ) : (
              t('subcontractor.workCategories.primary')
            ),
          },
          {
            alignItems: 'center',
            label: t('subcontractor.workCategories.secondary'),
          },
          ...(isReadOnly ? [] : [{ fixedWidth: 36 }]),
        ]}
        contentIfEmpty={() => (
          <EmptyPlaceholder>{t('subcontractor.workCategories.selectAtLeastOne')}</EmptyPlaceholder>
        )}
        data-cy-work-categories-table
        dataCount={value.length}
        rows={value.map((workCategory) => (
          <Row
            key={workCategory.id}
            isReadOnly={isReadOnly}
            onChange={onChange}
            value={value}
            invalid={invalid}
            workCategory={workCategory}
          />
        ))}
      />
      {invalid && (
        <InvalidSelectionMessage>
          {t('subcontractor.workCategories.selectAtLeastOnePrimaryError')}
        </InvalidSelectionMessage>
      )}
      {!isReadOnly && (
        <AddMoreWrapper>
          <AddMoreLink data-cy-add-more-work-categories onClick={modalState.openModal}>
            {t('subcontractor.workCategories.add')}
          </AddMoreLink>
          <WorkCategorySuggestions
            suggestionHints={suggestionHints}
            onChange={onChange}
            value={value}
          />
        </AddMoreWrapper>
      )}
    </div>
  );
};
