import { ButtonGroup, Callout, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import Form from '../Form';

export const Section = styled.div`
  margin-bottom: 40px;
`;

export const StyledFormElement = styled(Form.Element)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TopHint = styled.span`
  ${textStyles.bodyMedium450}
  color: ${COLOR.textTertiary}
`;

export const LoginMessage = styled(Callout)`
  margin-bottom: 10px;
`;

export const LoginInMethodButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  direction: column;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
`;

export const BackContainer = styled(ButtonGroup)`
  margin-bottom: 30px;
`;
