import { useEffect, useState } from 'react';
import type { TypedEventListener, TypedEventTarget } from 'typescript-event-target';

import useModalStateWithParams, {
  type ModalStateWithParamsData,
  type UseModalStateWithParamsProps,
} from '~/shared/hooks/useModalStateWithParams';

import { ConfirmationModalApi, type ConfirmationModalEventMap } from './imperativeApi';

type ConfirmationModalStateProps<Params> = UseModalStateWithParamsProps<Params>;

export type ConfirmationModalEvents<Params, Result> = TypedEventTarget<
  ConfirmationModalEventMap<Params, Result>
>;

export type ConfirmationModalStateData<Params = {}, Result = {}> = Omit<
  ModalStateWithParamsData<Params>,
  'events'
> & {
  events: ConfirmationModalEvents<Params, Result>;
  prompt(params: Params): Promise<Result | false>;
};

export function useConfirmationModalState<Params = {}, Result = {}>(
  props?: ConfirmationModalStateProps<Params>,
): ConfirmationModalStateData<Params, Result> {
  const modalState = useModalStateWithParams<Params>(props);
  const { events: baseEvents, openModal } = modalState;
  const [events] = useState(
    () => new ConfirmationModalApi<Params, Result>(modalState.openModal, modalState.closeModal),
  );

  useEffect(() => {
    const handler: TypedEventListener<ConfirmationModalEventMap<Params, Result>, 'closed'> = (
      event,
    ) => {
      baseEvents.dispatchTypedEvent('closed', event);
    };

    events.addEventListener('closed', handler);

    return () => events.removeEventListener('closed', handler);
  }, [events, baseEvents, modalState.closeModal]);

  return {
    ...modalState,
    events,
    prompt(params: Params) {
      return new Promise<Result | false>((resolve) => {
        events.addEventListener(
          'actionPerformed',
          ({ detail: { action, result } }) => {
            switch (action) {
              case 'confirm':
                resolve(result);
                break;
              default:
                resolve(false);
                break;
            }
          },
          { once: true },
        );
        openModal(params);
      });
    },
  };
}
