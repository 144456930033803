import { useExperimentFlags } from '@cosuno/feature-flags-frontend';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { CUSTOM_FIELD_ENTITY_TYPE } from '~/__gql__/graphql';
import PageNotFound from '~/components/PageNotFound';
import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import { ErrorBoundary } from '~/shared/components/ErrorBoundary';
import {
  BID_PACKAGES_ROUTE_TYPE,
  generateRoutes,
  PROJECTS_ROUTE_TYPE,
} from '~/shared/constants/routes';
import { CompanySettingsTabs } from '~/shared/constants/routeTabs';
import useUserAuthState from '~/shared/hooks/useUserAuthState';
import { lazyLoad } from '~/shared/utils/lazyLoad';
import { queryStringToObject } from '~/shared/utils/url';

import { MarketplaceSuggestionRedirect } from '../MarketplaceSuggestion/MarketplaceSuggestionRedirect';
import { AuthRoute } from './AuthRoute';

const AgentCreateBidRequest = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentCreateBidRequest" */ '~/components/AgentBidRequest/CreateBidRequest'
    ),
);
const AgentEditBidRequest = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentEditBidRequest" */ '~/components/AgentBidRequest/EditBidRequest'
    ),
);
const AwardBid = lazyLoad(
  () => import(/* webpackChunkName: "AwardBid" */ '~/components/AwardedBid/AwardBid'),
);
const ShowAwardedBid = lazyLoad(
  () => import(/* webpackChunkName: "ShowAwardedBid" */ '~/components/AwardedBid/ShowAwardedBid'),
);
const ShowBid = lazyLoad(
  () => import(/* webpackChunkName: "ShowBid" */ '~/components/Bid/ShowBid'),
);
const SubmitBid = lazyLoad(
  () => import(/* webpackChunkName: "SubmitBid" */ '~/components/Bid/SubmitBid'),
);
const BidPackages = lazyLoad(() =>
  import(/* webpackChunkName: "BidPackages" */ '~/components/BidPackages').then((module) => ({
    default: module.BidPackages,
  })),
);
const BidRequestLandingPage = lazyLoad(() =>
  import(/* webpackChunkName: "BidRequestLandingPage" */ '~/components/BidRequestLandingPage').then(
    (module) => ({
      default: module.BidRequestLandingPage,
    }),
  ),
);
const PreviewBidRequestLandingPage = lazyLoad(() =>
  import(
    /* webpackChunkName: "PreviewBidRequestLandingPage" */ '~/components/BidRequestLandingPage/PreviewBidRequestLandingPage'
  ).then((module) => ({
    default: module.PreviewBidRequestLandingPage,
  })),
);
const ReverseMarketplaceBidPackage = lazyLoad(() =>
  import(
    /* webpackChunkName: "ReverseMarketplaceBidPackage" */ '~/components/BidRequestLandingPage/ReverseMarketplace'
  ).then((module) => ({
    default: module.ReverseMarketplaceBidPackage,
  })),
);
const CreateCustomCostGroupCatalog = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "CreateCustomCostGroupCatalog" */ '~/components/Company/CustomCatalogs/CreateCustomCostGroupCatalog'
    ),
);
const EditCustomCostGroupCatalog = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "EditCustomCostGroupCatalog" */ '~/components/Company/CustomCatalogs/EditCustomCostGroupCatalog'
    ),
);
const ShowCustomCostGroupCatalog = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "ShowCustomCostGroupCatalog" */ '~/components/Company/CustomCatalogs/ShowCustomCostGroupCatalog'
    ),
);
const CustomFieldsForEntityType = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "CustomFieldsForEntityType" */ '~/components/Company/CustomFields/CustomFieldsForEntityType'
    ),
);
const MultiUserApprovalRules = lazyLoad(() =>
  import(
    /* webpackChunkName: "MultiUserApprovalRules" */ '~/components/Company/MultiUserApproval'
  ).then((module) => ({
    default: module.MultiUserApprovalRules,
  })),
);
const CreateOffice = lazyLoad(
  () => import(/* webpackChunkName: "CreateOffice" */ '~/components/Office/CreateOffice'),
);
const EditOffice = lazyLoad(
  () => import(/* webpackChunkName: "EditOffice" */ '~/components/Office/EditOffice'),
);
const ReverseMarketplaceRoute = lazyLoad(() =>
  import(/* webpackChunkName: "ReverseMarketplaceRoute" */ '~/components/ReverseMarketplace').then(
    (module) => ({
      default: module.ReverseMarketplaceRoute,
    }),
  ),
);
const SubcontractorBidPage = lazyLoad(() =>
  import(/* webpackChunkName: "SubcontractorBidPage" */ '~/components/SubcontractorBid').then(
    (module) => ({
      default: module.SubcontractorBidPage,
    }),
  ),
);
const SubcontractorReferenceProject = lazyLoad(() =>
  import(
    /* webpackChunkName: "SubcontractorReferenceProject" */ '~/components/SubcontractorReferenceProject'
  ).then((module) => ({
    default: module.SubcontractorReferenceProject,
  })),
);
const TermsRoute = lazyLoad(() =>
  import(/* webpackChunkName: "TermsRoute" */ '~/components/Terms/TermsRoute').then((module) => ({
    default: module.TermsRoute,
  })),
);
const BoQTemplate = lazyLoad(() =>
  import(/* webpackChunkName: "BoQTemplate" */ '~/packages/BoQ').then((module) => ({
    default: module.BoQTemplate,
  })),
);
const CreateSubcontractor = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "CreateSubcontractor" */ '~/shared/components/SubcontractorProfile/Create'
    ),
);
const SubcontractorProfile = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "SubcontractorProfile" */ '~/shared/components/SubcontractorProfile/Show'
    ),
);

const Addendums = lazyLoad(
  () => import(/* webpackChunkName: "Addendums" */ '~/components/Addendums'),
);
const CreateAddendum = lazyLoad(
  () => import(/* webpackChunkName: "CreateAddendum" */ '~/components/Addendums/Create'),
);
const ViewAddendum = lazyLoad(
  () => import(/* webpackChunkName: "ViewAddendum" */ '~/components/Addendums/View'),
);
const AgentAwardedBid = lazyLoad(
  () => import(/* webpackChunkName: "AgentAwardedBid" */ '~/components/AgentAwardedBid'),
);
const AgentAwardedBids = lazyLoad(
  () => import(/* webpackChunkName: "AgentAwardedBids" */ '~/components/AgentAwardedBids'),
);
const AgentBidRequests = lazyLoad(
  () => import(/* webpackChunkName: "AgentBidRequests" */ '~/components/AgentBidRequests'),
);
const AgentCertificates = lazyLoad(
  () => import(/* webpackChunkName: "AgentCertificates" */ '~/components/AgentCertificates'),
);
const AgentPublicCertificates = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentPublicCertificates" */ '~/components/AgentPublicCertificates'
    ),
);

const AgentCompanyOnboarding = lazyLoad(
  () =>
    import(/* webpackChunkName: "AgentCompanyOnboarding" */ '~/components/AgentCompanyOnboarding'),
);
const AgentPremiumSuccess = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentPremiumSuccess" */ '~/components/Company/AgentPremiumSuccess'
    ),
);
const BidInviteFeedback = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "BidInviteFeedback" */ '~/components/AgentUnauthenticated/BidInviteFeedback'
    ),
);
const CreatePassword = lazyLoad(
  () => import(/* webpackChunkName: "CreatePassword" */ '~/components/Auth/CreatePassword'),
);
const Invitation = lazyLoad(
  () => import(/* webpackChunkName: "Invitation" */ '~/components/Auth/Invitation'),
);
const ConfirmInviteRequest = lazyLoad(
  () =>
    import(/* webpackChunkName: "ConfirmInviteRequest" */ '~/components/Auth/ConfirmInviteRequest'),
);
const Login = lazyLoad(() => import(/* webpackChunkName: "Login" */ '~/components/Auth/Login'));
const LoginWithSSO = lazyLoad(
  () => import(/* webpackChunkName: "LoginWithSSO" */ '~/components/Auth/LoginWithSSO'),
);
const MfaSetup = lazyLoad(
  () => import(/* webpackChunkName: "MfaSetup" */ '~/components/Auth/MfaSetup'),
);
const MfaInitialSetup = lazyLoad(
  () =>
    import(/* webpackChunkName: "MfaInitialSetup" */ '~/components/Auth/MfaSetup/MfaInitialSetup'),
);
const ResetPassword = lazyLoad(
  () => import(/* webpackChunkName: "ResetPassword" */ '~/components/Auth/ResetPassword'),
);
const AgentSignup = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentSignup" */ '~/shared/components/AuthForms/AgentSignupPage/AgentSignup'
    ),
);
const AgentUnauthenticatedMarketplaceSamples = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentUnauthenticatedMarketplaceSamples" */ '~/components/AgentUnauthenticated/MarketplaceSamples'
    ),
);
const AgentUnauthenticatedUpdateProfileByBidRequest = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentUnauthenticatedUpdateProfileByBidRequest" */ '~/components/AgentUnauthenticated/UpdateProfile/ByBidRequest'
    ),
);
const AgentUnauthenticatedUpdateProfileBySubcontractor = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "AgentUnauthenticatedUpdateProfileBySubcontractor" */ '~/components/AgentUnauthenticated/UpdateProfile/BySubcontractor'
    ),
);
const SsoError = lazyLoad(
  () => import(/* webpackChunkName: "SsoError" */ '~/components/Auth/SsoError'),
);
const UserLockedPage = lazyLoad(
  () => import(/* webpackChunkName: "UserLockedPage" */ '~/components/Auth/UserLockedPage'),
);
const BidInvite = lazyLoad(
  () => import(/* webpackChunkName: "BidInvite" */ '~/components/BidInvite'),
);
const BidPackage = lazyLoad(
  () => import(/* webpackChunkName: "BidPackage" */ '~/components/BidPackage'),
);
const Bids = lazyLoad(() => import(/* webpackChunkName: "Bids" */ '~/components/Bids'));
const CloseDocuSignModal = lazyLoad(
  () => import(/* webpackChunkName: "CloseDocuSignModal" */ '~/components/CloseDocuSignModal'),
);
const Company = lazyLoad(() => import(/* webpackChunkName: "Company" */ '~/components/Company'));
const RolePage = lazyLoad(
  () => import(/* webpackChunkName: "RolePage" */ '~/components/Company/Permissions/RolePage'),
);
const CreateRolePage = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "CreateRolePage" */ '~/components/Company/Permissions/CreateRolePage'
    ),
);
const CompanyOnboarding = lazyLoad(
  () => import(/* webpackChunkName: "CompanyOnboarding" */ '~/components/CompanyOnboarding'),
);
const InternalDocuments = lazyLoad(() =>
  import(/* webpackChunkName: "InternalDocuments" */ '~/components/InternalDocuments').then(
    (module) => ({
      default: module.InternalDocuments,
    }),
  ),
);

const CreateBoQTemplate = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "CreateBoQTemplate" */ '~/components/InternalDocuments/BoQLibrary/CreateBoQTemplate'
    ),
);
const Invoices = lazyLoad(() => import(/* webpackChunkName: "Invoices" */ '~/components/Invoices'));
const CreateInvoice = lazyLoad(
  () => import(/* webpackChunkName: "CreateInvoice" */ '~/components/Invoices/Create'),
);
const ViewInvoice = lazyLoad(
  () => import(/* webpackChunkName: "ViewInvoice" */ '~/components/Invoices/View'),
);
const MarketplaceSuggestion = lazyLoad(
  () =>
    import(/* webpackChunkName: "MarketplaceSuggestion" */ '~/components/MarketplaceSuggestion'),
);
const CommonMessages = lazyLoad(
  () => import(/* webpackChunkName: "CommonMessages" */ '~/components/Messages'),
);
const Profile = lazyLoad(() => import(/* webpackChunkName: "Profile" */ '~/components/Profile'));
const Project = lazyLoad(() => import(/* webpackChunkName: "Project" */ '~/components/Project'));
const ExpiredProjectDownloadLink = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "ExpiredProjectDownloadLink" */ '~/components/Project/ExpiredProjectDownloadLink'
    ),
);
const Projects = lazyLoad(() => import(/* webpackChunkName: "Projects" */ '~/components/Projects'));
const Subcontractors = lazyLoad(
  () => import(/* webpackChunkName: "Subcontractors" */ '~/components/Subcontractors'),
);
const ViewTask = lazyLoad(
  () => import(/* webpackChunkName: "ViewTask" */ '~/components/Tasks/Task/ViewTask'),
);
const TaskLogin = lazyLoad(
  () => import(/* webpackChunkName: "TaskLogin" */ '~/components/Tasks/Task/AuthPage'),
);
const CreateTask = lazyLoad(
  () => import(/* webpackChunkName: "CreateTask" */ '~/components/Tasks/Task/CreateTask'),
);
const Tasks = lazyLoad(() => import(/* webpackChunkName: "Tasks" */ '~/components/Tasks'));
const UploadCertificates = lazyLoad(
  () => import(/* webpackChunkName: "UploadCertificates" */ '~/components/UploadCertificates'),
);

const SubcontractorBidCreationPage = () => <SubcontractorBidPage mode="create" />;
const SubcontractorBidExternalViewPage = () => <SubcontractorBidPage mode="external" />;
const SubcontractorBidViewPage = () => <SubcontractorBidPage mode="view" />;
const AgentPremium = lazyLoad(
  () => import(/* webpackChunkName: "AgentPremium" */ '~/components/AgentPremium'),
);

const Redirecting = lazyLoad(
  () => import(/* webpackChunkName: "Redirecting" */ '~/components/Redirecting'),
);

const GoldPackageOverview = lazyLoad(
  () => import(/* webpackChunkName: "GoldPackageOverview" */ '~/components/GoldPackageOverview'),
);

const PublishMarketplaceRestrictedView = lazyLoad(
  () =>
    import(
      /* webpackChunkName: "PublishMarketplaceRestrictedView" */ '~/components/PublishMarketplaceRestrictedView'
    ),
);

const routes = generateRoutes();

const Routes: React.FC = () => {
  const { isUserAuthenticated, userBaseUrl, currentUser } = useUserAuthState();
  const location = useLocation();

  const experimentFlags = useExperimentFlags();

  return (
    <ErrorBoundary>
      <Switch>
        {/* Routes for agent users */}
        <AuthRoute
          allowAgent
          exact
          withLayout
          path={routes.agentBidRequests()}
          component={AgentBidRequests}
        />
        <AuthRoute
          allowAgent
          exact
          withLayout
          path={routes.agentCreateBidRequest()}
          component={AgentCreateBidRequest}
        />
        <AuthRoute
          allowAgent
          exact
          withLayout
          path={routes.agentEditBidRequest()}
          component={AgentEditBidRequest}
        />
        <AuthRoute
          allowAgent
          withLayout
          path={routes.agentAwardedBids()}
          component={AgentAwardedBids}
          exact
        />
        <AuthRoute
          allowAgent
          withLayout
          path={routes.agentCompanyOnboardingSubpage()}
          component={AgentCompanyOnboarding}
        />
        <AuthRoute
          allowAgent
          withLayout
          path={routes.agentCompanyOnboarding()}
          component={AgentCompanyOnboarding}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          withLayout={false}
          path={routes.agentSignContract()}
          component={AgentAwardedBid}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          withLayout
          path={routes.agentAwardedBid()}
          component={AgentAwardedBid}
        />
        <AuthRoute
          allowAgent
          withLayout
          path={routes.agentCertificates()}
          component={AgentCertificates}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          withLayout
          path={routes.agentPublicCertificates()}
          component={AgentPublicCertificates}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          withLayout
          exact
          path={routes.reverseMarketplaceBidPackage()}
          component={ReverseMarketplaceBidPackage}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          exact
          path={routes.reverseMarketplaceBidPackageAcceptTerms()}
          component={TermsRoute}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          withLayout
          exact
          path={routes.agentReverseMarketplace()}
          component={ReverseMarketplaceRoute}
        />
        {/* Routes for all users */}
        <AuthRoute allowAuthenticated withLayout path={routes.profile()} component={Profile} />
        <AuthRoute
          allowAuthenticated
          withLayout
          path={routes.companyOnboarding()}
          component={CompanyOnboarding}
        />
        <AuthRoute
          allowCompanyAdmin
          extraCondition={isCompanyFeatureEnabled(
            currentUser?.company,
            'featureCustomAccountTypes',
          )}
          withLayout
          path={routes.showCompanyRole('new')}
          component={CreateRolePage}
        />
        <AuthRoute
          allowCompanyAdmin
          withLayout
          path={routes.showCompanyRole()}
          component={RolePage}
        />
        <AuthRoute
          allowCompanyAdmin
          withLayout
          path={routes.newOffice()}
          component={CreateOffice}
        />
        <AuthRoute allowCompanyAdmin withLayout path={routes.office()} component={EditOffice} />
        <AuthRoute
          allowCompanyAdmin
          extraCondition={isCompanyFeatureEnabled(
            currentUser?.company,
            'featureMultiUserApprovals',
          )}
          withLayout
          path={routes.multiUserApprovalRules()}
          component={MultiUserApprovalRules}
        />
        <AuthRoute
          allowCompanyAdmin
          withLayout
          path={routes.createCustomCostGroupCatalog()}
          component={CreateCustomCostGroupCatalog}
        />
        <AuthRoute
          allowCompanyAdmin
          withLayout
          path={routes.editCustomCostGroupCatalog()}
          component={EditCustomCostGroupCatalog}
        />
        <AuthRoute
          allowCompanyAdmin
          withLayout
          path={routes.showCustomCostGroupCatalog()}
          component={ShowCustomCostGroupCatalog}
        />
        {!isCompanyFeatureEnabled(currentUser?.company, 'featureCustomScFields') && (
          <Redirect
            exact
            from={routes.companyCustomFieldsForEntityType(CUSTOM_FIELD_ENTITY_TYPE.subcontractor)}
            to={routes.company({ tab: CompanySettingsTabs.customFields })}
          />
        )}
        {!isCompanyFeatureEnabled(currentUser?.company, 'featureProjectCustomFields') && (
          <Redirect
            exact
            from={routes.companyCustomFieldsForEntityType(CUSTOM_FIELD_ENTITY_TYPE.project)}
            to={routes.company({ tab: CompanySettingsTabs.customFields })}
          />
        )}
        <AuthRoute
          allowCompanyAdmin
          withLayout
          path={routes.companyCustomFieldsForEntityType()}
          component={CustomFieldsForEntityType}
        />
        {/* Keep old custom project fields link working */}
        <Redirect
          exact
          from={routes.company({ tab: CompanySettingsTabs.customProjectFields })}
          to={routes.companyCustomFieldsForEntityType(CUSTOM_FIELD_ENTITY_TYPE.project)}
        />
        {/* Keep old email templates link working */}
        <Redirect
          exact
          from={routes.company({ tab: CompanySettingsTabs.emailTemplates })}
          to={routes.company({ tab: CompanySettingsTabs.templates })}
        />
        <Redirect
          path={routes.company({ tab: CompanySettingsTabs.premium })}
          to={routes.premium(queryStringToObject(location.search))}
        />
        <Redirect
          path={routes.company({ tab: CompanySettingsTabs.premium, skipWorkspace: true })}
          to={routes.premium(queryStringToObject(location.search))}
        />
        <AuthRoute allowAuthenticated withLayout path={routes.company()} component={Company} />
        <AuthRoute allowAgent withLayout path={routes.premium()} component={AgentPremium} />
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.bidInviteAction()}
          component={BidInviteFeedback}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          withLayout
          path={routes.bidInvite()}
          component={BidInvite}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          withLayout
          path={routes.bidRequestLandingPage()}
          component={BidRequestLandingPage}
          exact
        />
        <Route
          path={routes.previewBidRequestLandingPage()}
          component={PreviewBidRequestLandingPage}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.bidRequestAcceptTerms()}
          component={TermsRoute}
        />
        <Route
          path={routes.subcontractorExternalBid()}
          component={SubcontractorBidExternalViewPage}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          withLayout
          path={routes.subcontractorCreateBid()}
          component={SubcontractorBidCreationPage}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          withLayout
          path={routes.subcontractorBid()}
          component={SubcontractorBidViewPage}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          withLayout
          path={routes.subcontractorCertificateRequest()}
          component={UploadCertificates}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          withLayout
          path={routes.uploadCertificates()}
          component={UploadCertificates}
        />
        <Route path={routes.closeDocuSignModal()} component={CloseDocuSignModal} />
        <AuthRoute allowAuthenticated path={routes.mfaInitialSetup()} component={MfaInitialSetup} />
        <AuthRoute allowAuthenticated path={routes.mfaSetup()} component={MfaSetup} />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.invitation()}
          component={Invitation}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.confirmInviteRequest()}
          component={ConfirmInviteRequest}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          exact
          path={routes.agentReverseMarketplaceSuggestionRedirect()}
          component={MarketplaceSuggestionRedirect}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.agentReverseMarketplaceSuggestion()}
          component={MarketplaceSuggestion}
        />
        <AuthRoute
          allowAuthenticated
          withLayout
          path={routes.messages()}
          component={CommonMessages}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.expiredProjectDownloadLink()}
          component={ExpiredProjectDownloadLink}
        />
        {/* Routes for principal users */}
        <Redirect
          exact
          from={routes.projectsRoot()}
          to={routes.projects(PROJECTS_ROUTE_TYPE.open)}
        />
        <Redirect
          exact
          from={routes.bidPackagesRoot()}
          to={routes.bidPackages(BID_PACKAGES_ROUTE_TYPE.open)}
        />

        <AuthRoute
          allowPrincipal
          path={routes.publishMarketplaceRestrictedView()}
          component={PublishMarketplaceRestrictedView}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.createBidPackageInvoice()}
          component={CreateInvoice}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.bidPackageInvoice()}
          component={ViewInvoice}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.createBidPackageAddendum()}
          component={CreateAddendum}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.bidPackageAddendum()}
          component={ViewAddendum}
        />
        <AuthRoute allowPrincipal withLayout path={routes.projects()} component={Projects} />
        <AuthRoute allowPrincipal withLayout path={routes.submitBid()} component={SubmitBid} />
        <AuthRoute allowPrincipal withLayout path={routes.showBid()} component={ShowBid} />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.showAwardedBid()}
          component={ShowAwardedBid}
        />
        <AuthRoute allowPrincipal withLayout path={routes.awardBid()} component={AwardBid} />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.createBidPackage()}
          component={BidPackage}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.showBidPackage()}
          component={BidPackage}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.costComparison()}
          component={BidPackage}
        />
        <AuthRoute allowPrincipal withLayout path={routes.createProject()} component={Project} />
        <AuthRoute allowPrincipal withLayout path={routes.showProject()} component={Project} />
        <AuthRoute allowPrincipal withLayout path={routes.bidPackages()} component={BidPackages} />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.newSubcontractor()}
          component={CreateSubcontractor}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          withLayout
          path={routes.subcontractorReferenceProject()}
          component={SubcontractorReferenceProject}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.subcontractor()}
          component={SubcontractorProfile}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.subcontractors()}
          component={Subcontractors}
        />
        <AuthRoute allowPrincipal withLayout path={routes.bids()} component={Bids} />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.createBoQTemplate()}
          component={CreateBoQTemplate}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.showBoQTemplate()}
          component={BoQTemplate}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.internalDocuments()}
          component={InternalDocuments}
        />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.createInvoice()}
          component={CreateInvoice}
        />
        <AuthRoute allowPrincipal withLayout path={routes.invoice()} component={ViewInvoice} />
        <AuthRoute allowPrincipal withLayout path={routes.invoices()} component={Invoices} />
        <AuthRoute
          allowPrincipal
          withLayout
          path={routes.createAddendum()}
          component={CreateAddendum}
        />
        <AuthRoute allowPrincipal withLayout path={routes.addendums()} component={Addendums} />
        <AuthRoute
          allowPrincipal
          extraCondition={experimentFlags.experimentGoldPackageOnePager === 'Enabled'}
          withLayout
          path={routes.goldPackageOverview()}
          component={GoldPackageOverview}
        />
        <Redirect
          from={routes.myTasks()}
          to={{ pathname: routes.tasks(), state: { showMyTasks: true } }}
          exact
        />
        <Redirect from={routes.tasksRoot()} to={routes.tasks()} exact />
        <AuthRoute
          allowAuthenticated
          extraCondition={isCompanyFeatureEnabled(currentUser?.company, 'featureTaskManagement')}
          withLayout
          path={routes.createTask()}
          component={CreateTask}
        />
        <AuthRoute
          allowAuthenticated
          extraCondition={isCompanyFeatureEnabled(currentUser?.company, 'featureTaskManagement')}
          withLayout
          path={routes.tasks()}
          component={Tasks}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.taskLogin()}
          component={TaskLogin}
        />
        <AuthRoute
          allowAuthenticated
          extraCondition={isCompanyFeatureEnabled(currentUser?.company, 'featureTaskManagement')}
          withLayout
          path={routes.showTask()}
          component={ViewTask}
        />
        {/* Unauthenticated routes */}
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.agentPremiumSuccess()}
          component={AgentPremiumSuccess}
        />
        <AuthRoute allowAnonymous path={routes.login()} component={Login} />
        <AuthRoute allowAnonymous path={routes.loginWithSSO()} component={LoginWithSSO} />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.ssoError()}
          component={SsoError}
        />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.agentSignup()}
          component={AgentSignup}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.agentUnauthenticatedUpdateProfileBySubcontractor()}
          component={AgentUnauthenticatedUpdateProfileBySubcontractor}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.agentUnauthenticatedUpdateProfileByBidRequest()}
          component={AgentUnauthenticatedUpdateProfileByBidRequest}
        />
        <AuthRoute
          allowAgent
          allowAnonymous
          path={routes.agentUnauthenticatedMarketplaceSamples()}
          component={AgentUnauthenticatedMarketplaceSamples}
        />
        <AuthRoute allowAnonymous path={routes.resetPassword()} component={ResetPassword} />
        <AuthRoute allowAnonymous path={routes.createPassword()} component={CreatePassword} />
        <AuthRoute allowAnonymous path={routes.userLocked()} component={UserLockedPage} />
        <AuthRoute
          allowAnonymous
          allowAuthenticated
          path={routes.redirect()}
          component={Redirecting}
        />
        {/* Shared redirects */}
        <Redirect exact from={routes.root()} to={userBaseUrl} />
        {/* PageNotFound handling */}
        {isUserAuthenticated ? (
          <AuthRoute
            allowAuthenticated
            withLayout
            component={PageNotFound.Authenticated}
            path={undefined}
          />
        ) : (
          <Route component={PageNotFound.Unauthenticated} />
        )}
      </Switch>
    </ErrorBoundary>
  );
};

export default Routes;
