import { useCallback, useMemo, useState } from 'react';

interface ImmutableSetStateMethods<T> {
  addItems: (...items: T[]) => void;
  addItem: (item: T) => void;
  getItems: () => T[];
  removeItems: (...items: T[]) => void;
  removeItem: (item: T) => void;
  toggleItem: (item: T) => void;
  resetToInitialValue: () => void;
  hasItem: (item: T) => boolean;
  itemsCount: number;
}

const useImmutableSetState = <T = never>(initialValue?: T[]): ImmutableSetStateMethods<T> => {
  const [state, setState] = useState(new Set<T>(initialValue));

  const addItems = useCallback(
    (...items: T[]) =>
      setState((currentState) => {
        const newState = new Set(currentState);

        items.forEach((item) => newState.add(item));

        return newState;
      }),
    [],
  );

  const addItem = useCallback((item: T) => addItems(item), [addItems]);

  const getItems = useCallback(() => Array.from(state), [state]);

  const removeItems = useCallback(
    (...items: T[]) =>
      setState((currentState) => {
        const newState = new Set(currentState);

        items.forEach((item) => newState.delete(item));

        return newState;
      }),
    [],
  );

  const removeItem = useCallback((item: T) => removeItems(item), [removeItems]);

  const toggleItem = useCallback(
    (item: T) =>
      setState((currentState) => {
        const newState = new Set(currentState);

        if (newState.has(item)) {
          newState.delete(item);
        } else {
          newState.add(item);
        }

        return newState;
      }),
    [],
  );

  const resetToInitialValue = useCallback(() => setState(new Set(initialValue)), [initialValue]);

  const hasItem = useCallback((item: T) => state.has(item), [state]);

  const itemsCount = state.size;

  return useMemo(
    () => ({
      addItems,
      addItem,
      getItems,
      removeItems,
      removeItem,
      toggleItem,
      resetToInitialValue,
      hasItem,
      itemsCount,
    }),
    [
      addItem,
      addItems,
      getItems,
      hasItem,
      itemsCount,
      removeItem,
      removeItems,
      resetToInitialValue,
      toggleItem,
    ],
  );
};

export default useImmutableSetState;
