import { Clickable } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

export const Wrapper = styled(Clickable)`
  ${font.size(14)}
  ${mixins.link()}

  align-items: center;
  display: flex;
  gap: 5px;
  height: 24px;
  justify-content: center;
`;
