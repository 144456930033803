import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useAnalytics from '~/shared/hooks/useAnalytics';
import type { ModalStateData } from '~/shared/hooks/useModalState';
import useModalState from '~/shared/hooks/useModalState';
import useTranslation from '~/shared/hooks/useTranslation';
import type { WorkCategoryInputWithId } from '~/shared/types/workCategories';

import ConfirmationModal from '../ConfirmationModal';
import Modal from '../Modal';
import { Dialog } from '../ModalStyles';
import { ModalContent } from './ModalContent';

export interface GroupedWorkCategoriesModalProps {
  modalState: ModalStateData;
  onChange: (value: WorkCategoryInputWithId[]) => void;
  value: WorkCategoryInputWithId[];
  showIsNewIndicators?: boolean;
}

export const GroupedWorkCategoriesModal: React.FC<GroupedWorkCategoriesModalProps> = (props) => {
  const { modalState, onChange, value, showIsNewIndicators } = props;

  const { trackEvent } = useAnalytics();
  const { t } = useTranslation('workCategoriesAdvancedSearch');

  const [newValue, setNewValue] = useState<WorkCategoryInputWithId[]>([]);

  const hasChanged = useMemo(
    () => !isEqual(value.map(({ id }) => id).sort(), newValue.map(({ id }) => id).sort()),
    [value, newValue],
  );

  const closeConfirmationModalState = useModalState();

  const onCloseModal = useCallback(() => {
    trackEvent('workCategorySelectorCancel');
    modalState.closeModal();
    if (hasChanged) {
      closeConfirmationModalState.openModal();
    }
  }, [closeConfirmationModalState, modalState, hasChanged, trackEvent]);

  useEffect(() => {
    if (!modalState.isOpen) {
      return;
    }

    trackEvent('workCategorySelectorSelect');
    setNewValue(cloneDeep(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.isOpen]);

  return (
    <>
      <Modal
        name="workCategoriesGroupedSelect"
        maxWidth={800}
        {...modalState}
        renderContent={() => (
          <ModalContent
            setValue={setNewValue}
            selected={newValue}
            onCloseModal={onCloseModal}
            onSave={() => {
              trackEvent('workCategorySelectorConfirmSelect');
              onChange(newValue);
              modalState.closeModal();
            }}
            showIsNewIndicators={showIsNewIndicators}
          />
        )}
        closeModal={onCloseModal}
      />
      <ConfirmationModal
        name="workCategoriesGroupedSelectCloseConfirmation"
        {...closeConfirmationModalState}
        title={t('navigationConfirmation:title')}
        message={t('navigationConfirmation:message')}
        confirmText={t('navigationConfirmation:saveButton')}
        onConfirm={({ closeModal }) => {
          trackEvent('workCategorySelectorCancelSaveChanges');
          onChange(newValue);
          closeModal();
        }}
        onCancel={() => {
          trackEvent('workCategorySelectorCancelCancel');
          modalState.openModal();
        }}
        renderExtraButtons={() => (
          <Dialog.ControlButton
            variant="secondary"
            onClick={() => {
              trackEvent('workCategorySelectorCancelDontSave');
              closeConfirmationModalState.closeModal();
            }}
          >
            {t('navigationConfirmation:discardButton')}
          </Dialog.ControlButton>
        )}
      />
    </>
  );
};
