import { graphql } from '~/__gql__';

export const getSuggestionsQuery = graphql(/* GraphQL */ `
  query WorkCategorySuggestions($categories: [ID!]!, $hints: WorkCategorySuggestionsHints) {
    workCategorySuggestions(categories: $categories, hints: $hints) {
      score
      workCategory {
        id
        name
      }
    }
  }
`);
