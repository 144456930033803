import React, { type ComponentProps, type PropsWithChildren, type ReactElement } from 'react';
import Dropzone, { type DropzoneRootProps } from 'react-dropzone';

interface Props {
  name?: string;
  className?: string;
  dropzoneProps?: Omit<ComponentProps<typeof Dropzone>, 'noDrag' | 'useFsAccessApi'>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & { webkitdirectory?: string };
  renderWrapper?: (props: DropzoneRootProps) => ReactElement;
}

const DropzoneLink: React.FC<PropsWithChildren<Props>> = ({
  name,
  className,
  dropzoneProps,
  inputProps,
  children,
  renderWrapper = (props) => <span {...props} />,
}) => (
  <Dropzone {...dropzoneProps} noDrag useFsAccessApi={false}>
    {({ getRootProps, getInputProps }) =>
      renderWrapper({
        ...getRootProps(),
        'data-cy-dropzone-name': name,
        className,
        children: (
          <>
            <input data-cy-dropzone-input={name} {...getInputProps(inputProps)} />
            {children}
          </>
        ),
      })
    }
  </Dropzone>
);

export default DropzoneLink;
