import { createContext, useCallback, useMemo, useRef } from 'react';

export interface FormInput {
  submit: () => Promise<unknown>;
  hasSubmitFailed: () => boolean;
  hasSubmitInterrupted?: () => boolean;
  onCancelledNavigation?: () => void;
  onDiscardChanges?: () => void;
  onSubmitResume?: (callback: () => void) => void;
}

interface ActiveForm extends FormInput {
  id: string;
  subscriptions: Set<string>;
}

export const useNavigationConfirmationManager = () => {
  const forms = useRef<Map<string, ActiveForm>>(new Map());

  const getAllFormsInEnvironment = useCallback(
    (environmentId: string) =>
      Array.from(forms.current.values()).filter((form) => form.subscriptions.has(environmentId)),
    [],
  );

  const markFormAsDirty = useCallback((formId: string, input: FormInput) => {
    forms.current.set(formId, { ...input, id: formId, subscriptions: new Set() });
  }, []);

  const markFormAsClean = useCallback((formId: string) => {
    forms.current.delete(formId);
  }, []);

  const subscribeToForm = useCallback((formId: string, environmentId: string) => {
    const form = forms.current.get(formId);

    form?.subscriptions.add(environmentId);
  }, []);

  return useMemo(
    () => ({
      getAllFormsInEnvironment,
      markFormAsDirty,
      markFormAsClean,
      subscribeToForm,
    }),
    [getAllFormsInEnvironment, markFormAsDirty, markFormAsClean, subscribeToForm],
  );
};

export const NavigationConfirmationManagerContext = createContext<
  ReturnType<typeof useNavigationConfirmationManager>
>({
  getAllFormsInEnvironment: () => [],
  markFormAsDirty: () => {},
  markFormAsClean: () => {},
  subscribeToForm: () => {},
});
