import { COLOR, Tag } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { gradients } from '~/shared/utils/styles';

export const StyledTag = styled(Tag)`
  background: ${gradients.premium};
  color: ${COLOR.white};
  height: max-content;
`;
