export const sizes = {
  navbarWidthDesktop: 75,
  navbarHeightMobile: 60,
  topbarHeight: 50,
  minViewportWidth: 1000,
  secondarySideBarWidth: 230,
  cellMinHeight: 55,
  cellMinHeightCompact: 42,
  cellContentVerticalPadding: 5,
  cellContentHorizontalPadding: 10,
  edgeCellsExtraHorizontalPadding: 5,
  buttonHeightSmall: 30,
  buttonHeight: 36,
  buttonHeightLarge: 48,
  inputBorderWidth: 1,
  inputHeightSmall: 30,
  inputHeightLarge: 64,
  inputHeight: 36,
  iconButtonSize: 42,
  iconPaddedSize: 32,
  dropdownCardContentPadding: 10,
  tableBorderRadius: 4,
} as const;
