import { SELECT_TRIGGER_HORIZONTAL_PADDING_REGULAR, SelectSingle } from '@cosuno/cosuno-ui';
import styled, { css } from 'styled-components';

export const SelectSingleStyled = styled(SelectSingle)`
  height: 100%;
  margin-left: -${SELECT_TRIGGER_HORIZONTAL_PADDING_REGULAR}px;
`;

export const getSelectTriggerStyles = () => css`
  gap: 8px;
  height: 100%;
  padding-left: ${SELECT_TRIGGER_HORIZONTAL_PADDING_REGULAR}px;

  svg:first-child {
    width: 20px;
    box-shadow: 0 0 1px 0 #888;
  }
`;
