import {
  BUTTON_VARIANT,
  type ButtonProps,
  INFO_MESSAGE_TYPE,
  InfoMessage,
} from '@cosuno/cosuno-ui';
import React, { type ReactNode, useCallback } from 'react';

import { Dialog } from '~/shared/components/ModalStyles';
import { KEY_NAME } from '~/shared/constants';
import useKeyboardEventCallback from '~/shared/hooks/useKeyboardEventCallback';
import type { ModalStateWithParamsData } from '~/shared/hooks/useModalStateWithParams';
import useTranslation from '~/shared/hooks/useTranslation';

interface ConfirmationModalContentUserProvidedProps<Params>
  extends Omit<ModalStateWithParamsData<Params>, 'events'> {
  title?: string;
  message?: string | ReactNode;
  warning?: string | string[];
  confirmText?: string | null;
  confirmButtonProps?: ButtonProps;
  cancelText?: string | null;
  confirmationButtonVariant?: ButtonProps['variant'];
  onConfirm: ({
    closeModal,
    params,
  }: {
    closeModal: () => void;
    params: Params;
  }) => Promise<void> | void;
  renderContent?: ({ params }: { params: Params }) => ReactNode;
  renderTitle?: () => ReactNode;
  renderExtraButtons?: ({ params }: { params: Params }) => ReactNode;
  isConfirmDisabled?: boolean;
}

interface ConfirmationModalContentWrapperProvidedProps<Params> {
  isWorking: boolean | undefined;
  params: Params;
  onCancel: () => void;
  startWorking: () => void;
  stopWorking: () => void;
}

type ConfirmationModalContentProps<Params> = ConfirmationModalContentUserProvidedProps<Params> &
  ConfirmationModalContentWrapperProvidedProps<Params>;

export function ConfirmationModalContent<Params>({
  title,
  message,
  confirmText,
  cancelText,
  confirmButtonProps,
  warning,
  confirmationButtonVariant = BUTTON_VARIANT.primary,
  onCancel,
  onConfirm,
  isWorking,
  renderContent = () => null,
  renderTitle = () => title,
  renderExtraButtons = () => null,
  isConfirmDisabled,
  params,
  startWorking,
  stopWorking,
  ...modalState
}: ConfirmationModalContentProps<Params>): ReturnType<
  React.FC<ConfirmationModalContentProps<Params>>
> {
  const { t } = useTranslation();

  const handleConfirm = useCallback(async () => {
    startWorking();
    await onConfirm({
      closeModal: () => {
        stopWorking();
        modalState.closeModal();
      },
      params,
    });
  }, [startWorking, onConfirm, params, stopWorking, modalState]);

  const handleEnter = async () => {
    if (modalState.isActive && !isWorking) {
      await handleConfirm();
    }
  };

  useKeyboardEventCallback(document, KEY_NAME.enter, handleEnter, {
    condition: modalState.isOpen && !isConfirmDisabled,
  });

  return (
    <Dialog.Content>
      <Dialog.Title>{renderTitle() || t('warning')}</Dialog.Title>
      {message !== null && <Dialog.Paragraph>{message || t('confirmMessage')}</Dialog.Paragraph>}
      {renderContent({ params })}
      {warning && <InfoMessage type={INFO_MESSAGE_TYPE.warning}>{warning}</InfoMessage>}
      <Dialog.ControlWrapper>
        {renderExtraButtons({ params })}
        {cancelText !== null && (
          <Dialog.ControlButton
            hollow={confirmText !== null}
            onClick={onCancel}
            data-testid="cancel-button"
            data-cy-button-name="cancel"
          >
            {cancelText || t('cancel')}
          </Dialog.ControlButton>
        )}
        {confirmText !== null && (
          <Dialog.ControlButton
            {...confirmButtonProps}
            variant={confirmationButtonVariant}
            working={isWorking}
            onClick={handleConfirm}
            data-cy-button-name="confirm"
            data-testid="confirm-button"
            disabled={isConfirmDisabled}
          >
            {confirmText || t('confirm')}
          </Dialog.ControlButton>
        )}
      </Dialog.ControlWrapper>
    </Dialog.Content>
  );
}
