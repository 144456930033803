import { COLOR, MessagePopup, MessagePopupsList } from '@cosuno/cosuno-ui';
import React, { useContext } from 'react';

import DownloadPreparationContext from '~/shared/contexts/downloadPreparationContext';
import useTranslation from '~/shared/hooks/useTranslation';

import { RotatingIcon } from './DownloadPreparationMessage.styles';

const DownloadPreparationMessage: React.FC = () => {
  const { t } = useTranslation('downloadPreparation');
  const { isPreparingAnyDownload } = useContext(DownloadPreparationContext);

  const defaultMessage = {
    id: 'ID',
    title: t('mainText'),
    message: t('secondaryText'),
  };

  return (
    <MessagePopupsList
      transitionTime={200}
      position="top"
      messages={isPreparingAnyDownload ? [defaultMessage] : []}
    >
      {(message) => (
        <MessagePopup
          color={COLOR.brand}
          title={message.title}
          message={message.message}
          leftIcon={<RotatingIcon type="settings-filled" />}
        />
      )}
    </MessagePopupsList>
  );
};

export default DownloadPreparationMessage;
