import type { FC } from 'react';

import type { PRICING_PLAN } from '~/__gql__/graphql';
import Modal from '~/shared/components/Modal';
import { ModalWindow } from '~/shared/components/ModalStyles';
import useAnalytics from '~/shared/hooks/useAnalytics';
import { useEnsureCurrentUser } from '~/shared/hooks/useCurrentUser';
import type { ModalStateData } from '~/shared/hooks/useModalState';
import useTranslation from '~/shared/hooks/useTranslation';

import PricingPlansContent from '../PricingPlansContent';
import { PricingPlansModalContent } from '../Styles';

interface InternalDocumentManagementUpsellModalProps extends ModalStateData {
  highlightedPlan: PRICING_PLAN | null;
}

export const PricingPlanModal: FC<InternalDocumentManagementUpsellModalProps> = ({
  highlightedPlan,
  ...modalState
}) => {
  const currentUser = useEnsureCurrentUser();
  const { t } = useTranslation('company');
  const { trackEvent } = useAnalytics();

  return (
    <Modal
      {...modalState}
      name="pricingPlanModal"
      isTopLevelModal
      maxWidth={1270}
      renderContent={() => (
        <>
          <ModalWindow.Header>{t('pricingPlans.title')}</ModalWindow.Header>
          <PricingPlansModalContent>
            <PricingPlansContent
              highlightedPlan={highlightedPlan}
              stretchCards
              onRequestPlan={(plan) => {
                trackEvent('upsellPlansTierInfo', { tier: plan, role: currentUser.type });
              }}
            />
          </PricingPlansModalContent>
        </>
      )}
    />
  );
};
