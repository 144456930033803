import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { uniqueId } from '~/shared/utils/javascript';

import { NavigationConfirmationManagerContext } from './useNavigationConfirmationManager';
import { NavigationConfirmationPromptContext } from './useNavigationConfirmationPrompt';

const useNavigationConfirmationTrigger = (environmentId: string) => {
  const manager = useContext(NavigationConfirmationManagerContext);
  const { confirmNavigation } = useContext(NavigationConfirmationPromptContext);

  const [isNavigatingAway, setIsNavigatingAway] = useState(false);

  const submitDirtyForms = useCallback(async () => {
    const forms = manager.getAllFormsInEnvironment(environmentId);
    const submitPromises = forms.map((form) => Promise.resolve(form.submit()));

    await Promise.all(submitPromises);
  }, [environmentId, manager]);

  const cancelDirtyForms = useCallback(() => {
    const forms = manager.getAllFormsInEnvironment(environmentId);

    forms.forEach((form) => form.onCancelledNavigation?.());
  }, [environmentId, manager]);

  const hasSubmitFailed = useCallback(
    (): boolean =>
      manager.getAllFormsInEnvironment(environmentId).some((form) => form.hasSubmitFailed()),
    [environmentId, manager],
  );

  const hasSubmitInterrupted = useCallback(
    (): boolean =>
      manager.getAllFormsInEnvironment(environmentId).some((form) => form.hasSubmitInterrupted?.()),
    [environmentId, manager],
  );

  const discardChanges = useCallback(() => {
    const forms = manager.getAllFormsInEnvironment(environmentId);

    forms.forEach((form) => form.onDiscardChanges?.());
  }, [environmentId, manager]);

  const submitResume = useCallback(
    (callback: () => void) => {
      const forms = manager.getAllFormsInEnvironment(environmentId);

      forms.forEach((form) => form.onSubmitResume?.(callback));
    },
    [environmentId, manager],
  );

  const tryNavigatingAway = useCallback(
    (onNavigationSuccess: () => void) => {
      if (manager.getAllFormsInEnvironment(environmentId).length === 0) {
        onNavigationSuccess();
        return;
      }

      setIsNavigatingAway(true);

      confirmNavigation({
        cancel: () => {
          cancelDirtyForms();
          setIsNavigatingAway(false);
        },
        discard: () => {
          discardChanges();
          onNavigationSuccess();
          setIsNavigatingAway(false);
        },
        save: async () => {
          await submitDirtyForms();
          // Wait for next tick to ensure that form status is updated, without this e2e tests fail
          setTimeout(() => {
            if (!hasSubmitFailed()) {
              if (hasSubmitInterrupted()) {
                submitResume(() => {
                  onNavigationSuccess();
                  setIsNavigatingAway(false);
                });
              } else {
                onNavigationSuccess();
                setIsNavigatingAway(false);
              }
            }
          });
        },
      });
    },
    [
      cancelDirtyForms,
      confirmNavigation,
      environmentId,
      hasSubmitFailed,
      hasSubmitInterrupted,
      manager,
      submitDirtyForms,
      discardChanges,
      submitResume,
    ],
  );

  return useMemo(
    () => ({ tryNavigatingAway, isNavigatingAway }),
    [isNavigatingAway, tryNavigatingAway],
  );
};

export const useNavigationConfirmationEnvironment = () => {
  const environmentId = useRef(uniqueId());
  const parentEnvironment = useContext(NavigationConfirmationEnvironmentContext);
  const manager = useContext(NavigationConfirmationManagerContext);

  const { tryNavigatingAway, isNavigatingAway } = useNavigationConfirmationTrigger(
    environmentId.current,
  );

  const subscribeToForm: (formId: string) => void = useCallback(
    (formId: string) => {
      manager.subscribeToForm(formId, environmentId.current);
      parentEnvironment?.subscribeToForm(formId);
    },
    [manager, parentEnvironment],
  );

  return useMemo(
    () => ({
      subscribeToForm,
      environmentId: environmentId.current,
      tryNavigatingAway,
      isNavigatingAway,
    }),
    [isNavigatingAway, subscribeToForm, tryNavigatingAway],
  );
};

export const NavigationConfirmationEnvironmentContext = createContext<ReturnType<
  typeof useNavigationConfirmationEnvironment
> | null>(null);
