import { type ButtonProps, type IconKeys, MiniTooltip } from '@cosuno/cosuno-ui';
import type { RemirrorEventListenerProps } from '@remirror/core';
import { Remirror, useActive, useCommands, useRemirror } from '@remirror/react';
import { Fragment, useEffect } from 'react';
import { prosemirrorNodeToHtml } from 'remirror';
import {
  BoldExtension,
  BulletListExtension,
  FontFamilyExtension,
  FontSizeExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  TextColorExtension,
  UnderlineExtension,
} from 'remirror/extensions';

import useTranslation, { type TypeSafeTFunction } from '~/shared/hooks/useTranslation';
import { isNotFalse } from '~/shared/utils/typescript';

import ColorDropdown from './ColorDropdown';
import { EMPTY_HTML_STRING, IMAGE_MIME_TYPES } from './constants';
import ImageExtension from './extensions/images/ImageExtension';
import FontFamilySelect from './FontFamilySelect';
import FontSizeSelect from './FontSizeSelect';
import ImageSelect from './ImageSelect';
import { LinkEditor, LinkPopover } from './LinkEditor';
import { MenuButtonGroup, Separator, Spacer, StyledMenuButton, StyledTextEditor } from './Styles';
import {
  isHtmlContentEmpty,
  replaceBreaksWithEmptyParagraphs,
  replaceEmptyParagraphsWithBreaks,
  sanitizeHtmlString,
} from './utils';

const extensions =
  ({ t }: { t: TypeSafeTFunction }) =>
  () => [
    new BoldExtension({}),
    new BulletListExtension({}),
    new FontFamilyExtension(),
    new FontSizeExtension({}),
    new ItalicExtension(),
    new MarkdownExtension({}),
    new OrderedListExtension(),
    new StrikeExtension(),
    new TextColorExtension({}),
    new UnderlineExtension(),
    new LinkExtension({
      defaultTarget: '_blank',
      // @see https://github.com/remirror/remirror/issues/1491
      markOverride: { excludes: undefined },
    }),
    // these are image formats that we support in the ImageExtension in cosuno-ui
    // excluding bmp and svg that are buggy in GMail
    new ImageExtension(IMAGE_MIME_TYPES, t),
  ];

interface TextEditorMenuButtonProps {
  isPressed: boolean;
  disabled: boolean;
  onChange: (isPressed: boolean) => void;
}

interface EditorButtonProps {
  type: 'button';
  toggle: () => void;
  isActive: () => boolean;
  icon: IconKeys;
  tooltip: string;
}

interface CustomElementProps {
  type: 'custom';
  render: (params: { setContent: (content: string) => void }) => React.ReactNode;
}

type MenuElement =
  | {
      type: 'separator' | 'spacer';
    }
  | CustomElementProps
  | EditorButtonProps;

const TextEditorMenuButton: React.FC<
  TextEditorMenuButtonProps & { icon: ButtonProps['onlyIcon']; tooltip: string }
> = ({ icon, isPressed, onChange, tooltip, ...buttonProps }) => (
  <MiniTooltip<HTMLButtonElement>
    isTriggerElementClickable={!buttonProps.disabled}
    bodyText={tooltip}
  >
    {({ ref, getReferenceProps }) => (
      <StyledMenuButton
        size="small"
        aria-pressed={isPressed}
        onlyIcon={icon}
        variant="secondary"
        forwardRef={ref}
        {...buttonProps}
        {...getReferenceProps({ onClick: () => onChange(isPressed) })}
      />
    )}
  </MiniTooltip>
);

interface StandardMenuElementsConfig {
  showFontFamily: boolean;
  showFontSize: boolean;
  showBold: boolean;
  showItalic: boolean;
  showUnderline: boolean;
  showStrikethrough: boolean;
  showColor: boolean;
  showBulletList: boolean;
  showNumberedList: boolean;
  showLink: boolean;
  showImage: boolean;
}

const STANDARD_MENU_ELEMENTS_DEFAULTS: StandardMenuElementsConfig = {
  showFontFamily: false,
  showFontSize: false,
  showBold: true,
  showItalic: true,
  showUnderline: true,
  showStrikethrough: true,
  showColor: false,
  showBulletList: true,
  showNumberedList: true,
  showLink: false,
  showImage: false,
};

interface EditorMenuProps {
  additionalMenuElements: MenuElement[];
  disabled: boolean;
  standardMenuElementsConfig: StandardMenuElementsConfig;
}

const EditorMenu: React.FC<EditorMenuProps> = ({
  additionalMenuElements,
  disabled,
  standardMenuElementsConfig: {
    showFontFamily,
    showFontSize,
    showBold,
    showItalic,
    showUnderline,
    showStrikethrough,
    showColor,
    showBulletList,
    showNumberedList,
    showLink,
    showImage,
  },
}) => {
  const { t } = useTranslation('textEditor');

  const cmd = useCommands();
  const active = useActive();

  const elements: MenuElement[] = [
    showFontFamily &&
      ({
        type: 'custom',
        render: () => <FontFamilySelect disabled={disabled} />,
      } as const),
    showFontSize &&
      ({
        type: 'custom',
        render: () => <FontSizeSelect disabled={disabled} />,
      } as const),
    showBold &&
      ({
        type: 'button',
        toggle: cmd.toggleBold,
        isActive: active.bold,
        icon: 'bold',
        tooltip: t('tooltips.bold'),
      } as const),
    showItalic &&
      ({
        type: 'button',
        toggle: cmd.toggleItalic,
        isActive: active.italic,
        icon: 'italic',
        tooltip: t('tooltips.italic'),
      } as const),
    showUnderline &&
      ({
        type: 'button',
        toggle: cmd.toggleUnderline,
        isActive: active.underline,
        icon: 'underline',
        tooltip: t('tooltips.underline'),
      } as const),
    showStrikethrough &&
      ({
        type: 'button',
        toggle: cmd.toggleStrike,
        isActive: active.strike,
        icon: 'strikethrough',
        tooltip: t('tooltips.strikethrough'),
      } as const),
    showColor &&
      ({
        type: 'custom',
        render: () => <ColorDropdown disabled={disabled} />,
      } as const),
    { type: 'separator' } as const,
    showBulletList &&
      ({
        type: 'button',
        toggle: cmd.toggleBulletList,
        isActive: active.bulletList,
        icon: 'bullet-list',
        tooltip: t('tooltips.bulletedList'),
      } as const),
    showNumberedList &&
      ({
        type: 'button',
        toggle: cmd.toggleOrderedList,
        isActive: active.orderedList,
        icon: 'numbered-list',
        tooltip: t('tooltips.numberedList'),
      } as const),
    { type: 'separator' } as const,
    showImage &&
      ({
        type: 'custom',
        render: () => <ImageSelect disabled={disabled} />,
      } as const),
    showLink &&
      ({
        type: 'custom',
        render: () => <LinkEditor disabled={disabled} />,
      } as const),
    ...additionalMenuElements,
  ]
    .filter(isNotFalse)
    .filter(({ type }, index, filteredElements) => {
      if (type !== 'separator') {
        return true;
      }

      const notFirstElement = index > 0;
      const notLastElement = index < filteredElements.length - 1;
      const previousElementIsNotSeparator = filteredElements[index - 1]?.type !== 'separator';

      return notFirstElement && notLastElement && previousElementIsNotSeparator;
    });

  const renderElement = (element: MenuElement, id: number) => {
    switch (element.type) {
      case 'button': {
        const { isActive, toggle, icon, tooltip } = element;
        return (
          <TextEditorMenuButton
            key={id}
            isPressed={isActive()}
            onChange={() => {
              toggle();
              cmd.focus();
            }}
            icon={{ type: icon }}
            tooltip={tooltip}
            disabled={disabled}
          />
        );
      }
      case 'custom': {
        const { render } = element;
        return (
          <Fragment key={id}>
            {render({
              setContent: (content) => {
                cmd.setContent(content);
                cmd.focus();
              },
            })}
          </Fragment>
        );
      }
      case 'separator':
        return <Separator key={id} />;
      case 'spacer':
        return <Spacer key={id} />;
    }
  };

  return (
    <MenuButtonGroup>{elements.map((element, id) => renderElement(element, id))}</MenuButtonGroup>
  );
};

interface TextEditorProps {
  placeholder?: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  isTouched?: boolean;
  onChange?: (newValue: string) => void;
  onBlur?: (event: Event) => void;
  setTouched?: () => void;
  additionalMenuElements?: MenuElement[];
  standardMenuElementsConfig?: Partial<StandardMenuElementsConfig>;
  initialValue?: string;
  autoFocus?: boolean;
  fixedParagraphLineHeight?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  disabled = false,
  invalid = false,
  onChange,
  onBlur,
  isTouched = false,
  setTouched = () => {},
  placeholder,
  additionalMenuElements,
  initialValue = EMPTY_HTML_STRING,
  autoFocus = false,
  standardMenuElementsConfig,
  fixedParagraphLineHeight = true,
  ...props
}) => {
  const { t } = useTranslation();

  const { manager, state, getContext } = useRemirror({
    extensions: extensions({ t }),
    content: value,
    selection: 'end',
    stringHandler: 'html',
  });

  // used for resetting text editor value after submitting
  useEffect(() => {
    if (value === initialValue) {
      getContext()?.setContent(value);
    }
  }, [value, initialValue, getContext]);

  const handleChange = (change: RemirrorEventListenerProps<MarkdownExtension>) => {
    if (!isTouched && !isHtmlContentEmpty(prosemirrorNodeToHtml(change.state.doc))) {
      setTouched();
    }
    onChange?.(prosemirrorNodeToHtml(change.state.doc));
  };

  const handleBlur = !onBlur ? undefined : (_: unknown, event: Event) => onBlur(event);

  const standardMenuElements = {
    ...STANDARD_MENU_ELEMENTS_DEFAULTS,
    ...standardMenuElementsConfig,
  };

  return (
    <StyledTextEditor
      $invalid={invalid}
      $disabled={disabled}
      $fixedParagraphLineHeight={fixedParagraphLineHeight}
      data-cy-text-editor
      data-cy-disabled={disabled}
      {...props}
    >
      <Remirror
        placeholder={placeholder}
        manager={manager}
        initialContent={state}
        autoRender="end"
        onChange={handleChange}
        onBlur={handleBlur}
        editable={!disabled}
        autoFocus={autoFocus}
      >
        <EditorMenu
          additionalMenuElements={additionalMenuElements ?? []}
          disabled={disabled}
          standardMenuElementsConfig={standardMenuElements}
        />
        {!standardMenuElements.showLink && <LinkPopover />}
      </Remirror>
    </StyledTextEditor>
  );
};

export type { TextEditorProps };
export {
  EMPTY_HTML_STRING,
  isHtmlContentEmpty,
  replaceBreaksWithEmptyParagraphs,
  replaceEmptyParagraphsWithBreaks,
  sanitizeHtmlString,
};
export { TEXT_EDITOR_COLOR } from './constants';

export default TextEditor;
