import { COLOR, GRADIENT } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import unauthenticatedPageBackground from '~/shared/assets/unauthenticatedPageBackground.svg';
import { mediaQueries } from '~/shared/utils/styles';

export const Wrapper = styled.div`
  flex: 1;
  min-height: 100%;

  @media ${mediaQueries.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${(props) => props.theme.topOffset}px;
    padding: 30px 0;

    /* prettier-ignore */
    background: url("${unauthenticatedPageBackground}"), ${GRADIENT.gradientBrand};
    background-repeat: no-repeat;
    background-position:
      calc(50% + 300px) center,
      0 0;
    background-size:
      2800px auto,
      auto;
  }
`;

export const SplitScreenWrapper = styled.div`
  background-image: url('/images/AgentSignupLogoBackground.svg');
  background-size: cover;
  height: fit-content;
  display: flex;
`;

export const RightPanel = styled.div`
  background-color: ${COLOR.white};
  width: 100%;
  margin-left: auto;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  @media ${mediaQueries.desktop} {
    flex-shrink: 0;
    width: 50%;
  }
`;

export const RightPanelContentWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 60px 30px;
  gap: 20px;
  width: fit-content;

  @media ${mediaQueries.desktop} {
    padding: 30px 60px;
  }

  @media ${mediaQueries.tablet} {
    width: 100%;
    max-width: 480px;
  }

  @media ${mediaQueries.desktop} {
    max-width: 640px;
    width: 100%;
  }
`;

export const LeftPanel = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  display: none;

  @media ${mediaQueries.desktop} {
    display: flex;
  }
`;

export const RightPanelLogoWrapper = styled.div`
  margin-bottom: 20px;

  @media ${mediaQueries.desktop} {
    display: none;
  }
`;

export const Circle = styled.div`
  background-color: ${COLOR.successLight};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
