import { forwardRef } from 'react';

import SvgIcon from './GoldIcon.svg?react';

interface GoldIconProps extends React.SVGAttributes<SVGSVGElement> {
  size?: number;
}

export const GoldIcon = forwardRef<SVGSVGElement, GoldIconProps>(
  ({ size = 16, style, ...props }, ref) => (
    <span style={{ display: 'flex' }}>
      <SvgIcon
        width={size}
        height={size}
        style={{ pointerEvents: 'none', ...style }}
        ref={ref}
        {...props}
      />
    </span>
  ),
);
