import { COLOR, DropdownMenu, HoverableElement } from '@cosuno/cosuno-ui';
import { useActive, useChainedCommands } from '@remirror/react';
import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';
import { translateTextEditorColor } from '~/shared/utils/dynamicTranslationMaps';

import { TEXT_EDITOR_COLOR } from './constants';
import { StyledColorButton, StyledColorDropdownAction } from './Styles';

const COLORS = [
  { colorKey: TEXT_EDITOR_COLOR.black, colorValue: COLOR.textPrimary },
  { colorKey: TEXT_EDITOR_COLOR.darkRed, colorValue: '#6B170B' },
  { colorKey: TEXT_EDITOR_COLOR.darkGreen, colorValue: '#2A7300' },
  { colorKey: TEXT_EDITOR_COLOR.ocher, colorValue: '#747500' },
  { colorKey: TEXT_EDITOR_COLOR.darkBlue, colorValue: '#0D0075' },
  { colorKey: TEXT_EDITOR_COLOR.violet, colorValue: '#6C1575' },
  { colorKey: TEXT_EDITOR_COLOR.teal, colorValue: '#2C7274' },
  { colorKey: TEXT_EDITOR_COLOR.grey, colorValue: '#757575' },
  { colorKey: TEXT_EDITOR_COLOR.lightGrey, colorValue: '#B8B8B8' },
  { colorKey: TEXT_EDITOR_COLOR.red, colorValue: '#EB3117' },
  { colorKey: TEXT_EDITOR_COLOR.green, colorValue: '#5CFB00' },
  { colorKey: TEXT_EDITOR_COLOR.yellow, colorValue: '#FDFF0C' },
  { colorKey: TEXT_EDITOR_COLOR.blue, colorValue: '#1D00FF' },
  { colorKey: TEXT_EDITOR_COLOR.purple, colorValue: '#6B170B' },
  { colorKey: TEXT_EDITOR_COLOR.aquamarine, colorValue: '#61FAFE' },
] as const;

const ColorDropdown: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation('textEditor');
  const active = useActive();
  const chain = useChainedCommands();

  const activeColor = COLORS.find(({ colorValue }) => active.textColor({ color: colorValue })) ?? {
    colorKey: TEXT_EDITOR_COLOR.black,
    colorValue: COLOR.textPrimary,
  };
  return (
    <DropdownMenu.Container
      renderTrigger={({ isTriggered, toggleTrigger }) => (
        <HoverableElement
          isTriggerElementClickable={!disabled}
          tooltipText={disabled ? null : t('tooltips.color')}
        >
          <StyledColorButton
            size="small"
            disabled={disabled}
            variant="secondary"
            onClick={toggleTrigger}
            isTriggered={isTriggered}
            onlyIcon={{ type: 'rounded-square' }}
            $forceIconColor={activeColor.colorValue}
          />
        </HoverableElement>
      )}
    >
      {COLORS.map(({ colorKey, colorValue }) => (
        <StyledColorDropdownAction
          key={colorKey}
          iconLeft={{ type: 'rounded-square' }}
          onClick={() => {
            chain.setTextColor(colorValue).focus().run();
          }}
          $forceIconColor={colorValue}
        >
          {translateTextEditorColor(t, colorKey)}
        </StyledColorDropdownAction>
      ))}
    </DropdownMenu.Container>
  );
};

export default ColorDropdown;
