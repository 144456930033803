import {
  type CamelCaseFeatureFlags,
  camelCaseFeatureFlags,
  permanentFeatureFlagDefaults,
  type PermanentFeatureFlags,
} from '@cosuno/feature-flags';
import { omit } from 'lodash';
import { type PropsWithChildren, useMemo } from 'react';

import useCurrentUser from '~/shared/hooks/useCurrentUser';

import { FeatureFlagsContext } from './FeatureFlagsContext';

const defaultValue = camelCaseFeatureFlags(permanentFeatureFlagDefaults);

export const CurrentUserFeatureFlagsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const currentUser = useCurrentUser();

  const value = useMemo(
    () =>
      currentUser?.company
        ? ({
            ...omit(currentUser.company.config, '__typename', 'id', 'additionalLanguages'),
            ...(currentUser.company
              .permanentFeatureFlags as CamelCaseFeatureFlags<PermanentFeatureFlags>),
          } as const)
        : defaultValue,
    [currentUser?.company],
  );

  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};
