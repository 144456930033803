import { COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { mixins, zIndexValues } from '~/shared/utils/styles';

export const AutocompleteContainer = styled.div`
  position: relative;
`;

export const SuggestionsContainer = styled.ul<{ $isVisible: boolean }>`
  z-index: ${zIndexValues.autocompleteSuggestions};
  position: absolute;
  top: calc(100% + 2px);
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  width: 100%;
  max-height: 200px;
  border-radius: 4px;
  overflow-y: auto;
  background-color: white;
  padding: 10px 0;
  ${mixins.boxShadowBorderMedium()}
  ${mixins.boxShadowMedium()}
`;

export const Suggestion = styled.li<{ $isHighlighted: boolean }>`
  background-color: ${({ $isHighlighted }) => ($isHighlighted ? COLOR.borderLight : 'transparent')};
  padding: 6px 20px;
  font-size: 14px;
  &:hover {
    background-color: ${COLOR.borderLight};
  }
  ${mixins.clickable()}
  ${mixins.truncateText()}
`;
