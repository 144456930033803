import { COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const MainContentWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

export const PageLoaderOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${COLOR.white};
`;
