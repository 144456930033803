import { COLOR, Icon as UiIcon, type IconProps } from '@cosuno/cosuno-ui';
import type { TdHTMLAttributes } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import RelativeTime from '~/shared/components/RelativeTime';
import { font } from '~/shared/utils/styles';

export const Link = styled(RouterLink)`
  border-top: 1px solid ${COLOR.borderMedium};
  display: flex;
  padding: 20px 60px 15px;
  position: relative;
  transition: 100ms linear;

  &:hover {
    background-color: ${COLOR.backgroundLight};
  }
`;

export const UnreadIndicator = styled.div`
  background-color: ${COLOR.brand};
  border-radius: 8px;
  height: 16px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 16px;
`;

export const Emphasized = styled.strong`
  ${font.medium}

  color: ${COLOR.textPrimary};
`;

interface BaseNotificationIconProps {
  $color: string;
}

const BaseNotificationIcon = styled(UiIcon)<BaseNotificationIconProps>`
  color: ${({ $color }) => $color};
  flex-shrink: 0;
`;

const iconStyles: Record<string, { color: string; type: IconProps['type'] }> = {
  alert: { type: 'alert', color: COLOR.danger },
  checkmark: { type: 'checkmark', color: COLOR.success },
  clock: { type: 'clock', color: COLOR.warning },
  help: { type: 'help', color: COLOR.brand },
  message: { type: 'message', color: COLOR.textSecondary },
  signature: { type: 'signature', color: COLOR.brand },
  watch: { type: 'watch', color: COLOR.brand },
  rejected: { type: 'close', color: COLOR.danger },
  taskAssigned: { type: 'task', color: COLOR.warning },
  taskCompleted: { type: 'task', color: COLOR.success },
  bidPackage_published: { type: 'publish', color: COLOR.brand },
  bidPackage_inNegotiation: { type: 'file-text', color: COLOR.brand },
  bidPackage_bidAwarded: { type: 'gavel', color: COLOR.success },
  bidPackage_contractSigning: { type: 'signature', color: COLOR.brand },
  bidPackage_contracted: { type: 'bid-up-to-date', color: COLOR.success },
};

export const Icon = ({ $icon }: { $icon: keyof typeof iconStyles }) => {
  const { color, type } = iconStyles[$icon];
  return <BaseNotificationIcon $color={color} size={18} type={type} />;
};

export const TextContainer = styled.div`
  ${font.size(14)}

  margin-left: 15px;
`;

export const Text = styled.div`
  color: ${COLOR.textSecondary};
  line-height: 18px;
  margin-top: -2px;
`;

interface DateTimeProps extends TdHTMLAttributes<HTMLDivElement> {
  date: string;
}

export const DateTime = styled(({ date, ...props }: DateTimeProps) => (
  <div {...props}>
    <RelativeTime date={date} />
  </div>
))<DateTimeProps>`
  ${font.size(14)}

  color: ${COLOR.textTertiary};
  margin-top: 5px;
`;
