import React, { type PropsWithChildren, useState } from 'react';

import InfiniteScroll from '~/shared/components/InfiniteScroll';
import type { QueryVariables } from '~/shared/components/TableWithFetch';
import useIsMounted from '~/shared/hooks/useIsMounted';
import { FETCH_MORE_RACE_CONDITION_REJECTION } from '~/shared/hooks/useQueryMethodsWithoutRaceConditions';
import type { FetchMore } from '~/shared/types/graphql';

export interface InfiniteScrollWithFetchProps {
  itemsCount: number;
  totalRecords: number;
  fetchMore?: FetchMore;
  variables?: QueryVariables<unknown> | null;
  loadMoreOffset?: number;
  containerRef?: React.MutableRefObject<HTMLElement | null>;
  callbackOnEveryIntersection?: boolean;
}

/**
 * Consumers of this component have to register the used query with pagination options in
 * apolloClient.ts (initialization of InMemoryCache). This ensures that the results of fetchMore
 * will be concatenated to existing results.
 */
const InfiniteScrollWithFetch: React.FC<PropsWithChildren<InfiniteScrollWithFetchProps>> = ({
  children,
  itemsCount,
  totalRecords,
  fetchMore,
  variables,
  loadMoreOffset = 100,
  containerRef,
  callbackOnEveryIntersection,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const isMounted = useIsMounted();

  const hasMore = itemsCount < totalRecords;

  const handleLoadMore = async () => {
    if (hasMore && !isLoadingMore && fetchMore) {
      setIsLoadingMore(true);

      // Trying to fetchMore for an unmounted query will result in an error. We
      // don't have a way to cancel the cache update after completing the
      // request, so instead we catch the error and only rethrow it if the
      // component is still mounted (which suggests something else went wrong).
      // See: https://github.com/apollographql/apollo-client/issues/4114
      try {
        await fetchMore({
          variables: { ...variables, offset: itemsCount },
        });
        setIsLoadingMore(false);
      } catch (error) {
        if (isMounted.current) {
          setIsLoadingMore(false);

          if (error !== FETCH_MORE_RACE_CONDITION_REJECTION) {
            throw error;
          }
        }
      }
    }
  };

  return (
    <InfiniteScroll
      hasMore={hasMore}
      isLoadingMore={isLoadingMore}
      onLoadMore={handleLoadMore}
      loadMoreOffset={loadMoreOffset}
      containerRef={containerRef}
      callbackOnEveryIntersection={callbackOnEveryIntersection}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollWithFetch;
