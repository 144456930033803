import { useState } from 'react';

import type { MFA_METHOD } from '~/__gql__/graphql';

const useMfaFormProps = () => {
  const [mfaFormProps, setMfaFormProps] = useState<{
    loginToken: string;
    mfaMethod: MFA_METHOD | null;
    mfaPhoneCensored: string | null;
    companyName: string;
  } | null>(null);

  return { mfaFormProps, setMfaFormProps };
};

export default useMfaFormProps;
