import type { ButtonProps } from '@cosuno/cosuno-ui';
import React, {
  forwardRef,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import Modal from '~/shared/components/Modal';
import { MODAL_VARIANTS } from '~/shared/constants';
import type { ModalStateWithParamsData } from '~/shared/hooks/useModalStateWithParams';

import { ConfirmationModalContent } from './Content';
import {
  ConfirmationModalActionPerformedEvent,
  ConfirmationModalApi,
  ConfirmationModalEvent,
} from './imperativeApi';

interface ConfirmationModalContentUserProvidedProps<Params>
  extends Omit<ModalStateWithParamsData<Params>, 'events'> {
  name?: string;
  title?: string;
  message?: string | ReactNode;
  warning?: string | string[];
  confirmText?: string | null;
  confirmButtonProps?: ButtonProps;
  cancelText?: string | null;
  confirmationButtonVariant?: ButtonProps['variant'];
  onConfirm: ({
    closeModal,
    params,
  }: {
    closeModal: () => void;
    params: Params;
  }) => Promise<void> | void;
  renderContent?: ({ params }: { params: Params }) => ReactNode;
  renderTitle?: () => ReactNode;
  renderExtraButtons?: ({ params }: { params: Params }) => ReactNode;
  isConfirmDisabled?: boolean;
}

export interface ConfirmationModalProps<Params>
  extends ConfirmationModalContentUserProvidedProps<Params> {
  className?: string;
  onCancel?: ({ params }: { params: Params | null }) => void;
  isWorking?: boolean;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
  isTopLevelModal?: boolean;
}

function BaseConfirmationModal<Params>(
  {
    name,
    className = '',
    onCancel: providedOnCancel = () => {},
    forwardRef: providedForwardRef,
    params,
    isWorking: isWorkingProp = undefined,
    onConfirm: providedOnConfirm,
    isTopLevelModal,
    ...modalState
  }: ConfirmationModalProps<Params>,
  ref: React.ForwardedRef<ConfirmationModalApi<Params>>,
) {
  const [target] = useState(
    () => new ConfirmationModalApi<Params>(modalState.openModal, modalState.closeModal),
  );

  useImperativeHandle(ref, () => {
    target.open = modalState.openModal;
    return target;
  }, [modalState.openModal, target]);

  const onCancel: typeof providedOnCancel = useCallback(
    (arg) => {
      providedOnCancel(arg);
      target.dispatchTypedEvent('cancel', new ConfirmationModalEvent('cancel', arg.params));
      target.dispatchTypedEvent(
        'actionPerformed',
        new ConfirmationModalActionPerformedEvent('actionPerformed', {
          action: 'cancel' as const,
          result: false,
          params: arg.params,
        }),
      );
    },
    [target, providedOnCancel],
  );

  const onConfirm: typeof providedOnConfirm = useCallback(
    async (arg) => {
      await providedOnConfirm(arg);
      target.dispatchTypedEvent('confirm', new ConfirmationModalEvent('confirm', arg.params));
      target.dispatchTypedEvent(
        'actionPerformed',
        new ConfirmationModalActionPerformedEvent('actionPerformed', {
          action: 'confirm',
          result: {},
          params: arg.params,
        }),
      );
    },
    [target, providedOnConfirm],
  );

  const [isWorkingState, setIsWorkingState] = useState(false);

  const isWorking = isWorkingProp === undefined ? isWorkingState : isWorkingProp;

  const handleCancel = useCallback(() => {
    setIsWorkingState(false);
    modalState.closeModal();
    onCancel({ params });
  }, [modalState, onCancel, params]);

  return (
    <Modal
      data-cy-confirmation-modal
      forwardRef={providedForwardRef}
      {...modalState}
      name={name}
      params={params}
      closeModal={handleCancel}
      className={className}
      variant={MODAL_VARIANTS.center}
      isTopLevelModal
      renderContent={({ params: contentParams }) => (
        <ConfirmationModalContent<Params>
          {...modalState}
          onConfirm={onConfirm}
          params={contentParams}
          isWorking={isWorking}
          onCancel={handleCancel}
          startWorking={() => setIsWorkingState(true)}
          stopWorking={() => setIsWorkingState(false)}
        />
      )}
    />
  );
}

const ConfirmationModal = forwardRef(BaseConfirmationModal);

export default ConfirmationModal;
