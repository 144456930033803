import { Cell, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { font, mixins } from '~/shared/utils/styles';

export const EmptyPlaceholder = styled.div`
  ${textStyles.bodyMedium450}

  color: ${COLOR.danger};
`;

export const NameCell = styled(Cell.Text)`
  ${textStyles.bodySmall450}
`;

export const AddMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
`;

export const InvalidColumnHeader = styled.span`
  color: ${COLOR.danger};

  ${mixins.truncateText()}
`;

export const InvalidSelectionMessage = styled.span`
  color: ${COLOR.danger};
  ${font.size(12)}
  padding: 4px 0 0 10px;
`;
