import { COLOR, Lightbox, Toast } from '@cosuno/cosuno-ui';
import React, { useEffect } from 'react';

import Routes from '~/components/Routes';
import HtmlHeadTags from '~/shared/components/HtmlHeadTags';
import useAnalytics from '~/shared/hooks/useAnalytics';

import AppOutdatedNotifier from './AppOutdatedNotifier';
import DownloadPreparationMessage from './DownloadPreparationMessage';
import NavigationConfirmationPrompt from './NavigationConfirmationPrompt';
import { ContractEndingPrompt, OverdueInvoiceWarning, SubcontractorProfilePrompt } from './Prompts';
import { PromptsWrapper } from './PromptsWrapper';

const AppContent: React.FC = () => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    const trackNonLeftMouseLinkClick = (event: MouseEvent) => {
      const buttons = {
        left: 0,
        middle: 1,
        right: 2,
      };

      if (
        event.target instanceof HTMLAnchorElement &&
        [buttons.middle, buttons.right].includes(event.button)
      ) {
        trackEvent('nonLeftMouseLinkClick', { href: event.target.href });
      }
    };

    document.body.addEventListener('mousedown', trackNonLeftMouseLinkClick);

    return () => {
      document.body.removeEventListener('mousedown', trackNonLeftMouseLinkClick);
    };
  }, [trackEvent]);

  return (
    <NavigationConfirmationPrompt>
      <AppOutdatedNotifier>
        <HtmlHeadTags title={null} themeColor={COLOR.white} />
        <PromptsWrapper>
          <OverdueInvoiceWarning />
          <SubcontractorProfilePrompt />
          <ContractEndingPrompt />
        </PromptsWrapper>
        <Toast />
        <Routes />
        <Lightbox />
        <DownloadPreparationMessage />
      </AppOutdatedNotifier>
    </NavigationConfirmationPrompt>
  );
};

export default AppContent;
