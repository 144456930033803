import { SelectSingle, type SelectSingleProps } from '@cosuno/cosuno-ui';

export const DISTANCES = {
  '20': 20,
  '50': 50,
  '100': 100,
  '200': 200,
  '350': 350,
  '500': 500,
  '750': 750,
  '1000': 1000,
  '1000+': Infinity,
} as const;

type DistanceKeysType = keyof typeof DISTANCES;
export type DistanceValuesType = (typeof DISTANCES)[DistanceKeysType] | null;

export interface DistanceInputProps extends Pick<SelectSingleProps, 'name' | 'size'> {
  value: DistanceValuesType;
  required?: boolean;
  onChange: (value: DistanceValuesType) => void;
}

const distanceOptions = Object.entries(DISTANCES).map(([label, value]) => ({
  label,
  value: String(value),
}));

export function DistanceInput({ onChange, value, ...props }: DistanceInputProps) {
  return (
    <SelectSingle
      {...props}
      value={String(value ?? '')}
      onChange={(newValue) =>
        onChange(newValue === '' ? null : Number(newValue as DistanceKeysType))
      }
      options={distanceOptions}
      showSearch={false}
    />
  );
}
