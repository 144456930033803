import { COLOR } from '@cosuno/cosuno-ui';
import { createGlobalStyle } from 'styled-components';

import { font, zIndexValues } from '~/shared/utils/styles';

// Google maps autocomplete dropdown styles
export default createGlobalStyle`
  .pac-container {
    z-index: ${zIndexValues.overlayElement};
    border: 1px solid #eee;
    transform: translateY(8px);
    ${font.regular};

    .pac-item {
      line-height: 36px;
      ${font.size(14)}
      &:first-of-type {
        border-top: none;
      }
      .pac-icon {
        margin-top: 10px;
      }
      .pac-item-query {
        ${font.size(14)}
      }
      .pac-matched {
        ${font.bold}
      }
    }
    .pac-item:hover,
    .pac-item-selected {
      background: ${COLOR.backgroundLight};
    }
  }
`;
