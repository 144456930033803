import { type RefObject, useCallback, useEffect, useRef } from 'react';

const useClickOutside = (
  containers: RefObject<HTMLElement>[],
  onClickOutside: () => void,
  options: { condition?: boolean; baseSelector?: string } = {},
): void => {
  const { condition = true, baseSelector } = options;

  const containersRef = useRef(containers);
  const callbackRef = useRef(onClickOutside);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (condition === false) return;
      if (!(event.target instanceof Element)) return;

      // Assignment necessary because TS is stupid and doesn't know
      // event.target is Element otherwise
      const { target } = event;
      const targetIsStillMounted = document.body.contains(target);
      const atLeastOneContainerHasTarget = containersRef.current.some((ref) =>
        ref.current?.contains(target),
      );
      const targetIsInsideBase = Boolean(!baseSelector || target.closest(baseSelector));

      if (targetIsStillMounted && !atLeastOneContainerHasTarget && targetIsInsideBase) {
        callbackRef.current();
      }
    },
    [condition, baseSelector],
  );

  useEffect(() => {
    containersRef.current = containers;
    callbackRef.current = onClickOutside;
  }, [containers, onClickOutside]);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

export default useClickOutside;
