import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import AuthContext from '~/shared/contexts/authContext';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import { useEffectOnce } from '~/shared/hooks/useEffectOnce';
import { useInterval } from '~/shared/hooks/useInterval';
import { usePageVisibility } from '~/shared/hooks/usePageVisibility';
import Console from '~/shared/utils/console';
import { env } from '~/shared/utils/env';

export const CHECK_PERIOD_IN_SECONDS = 5;

export const RefreshFeatureFlagsContext = createContext<{ refresh: () => Promise<void> }>({
  refresh: () => Promise.resolve(),
});

export const RefreshFeatureFlagsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [version, setVersion] = useState<string | null>();
  const user = useCurrentUser();
  const apolloClient = useApolloClient();
  const { fetchCurrentUser } = useContext(AuthContext);
  const { isPageVisible } = usePageVisibility();

  const pollingReasonable = user?.type !== undefined && isPageVisible;

  const updateVersion = useCallback(async () => {
    try {
      const { data, status } = await axios.get<string | null>(
        `${env.REACT_APP_API_BASE}/internal/feature-flags-version`,
        {
          withCredentials: true,
        },
      );

      if (status === 200) {
        const latestVersion = data !== null ? data.toString() : null;
        if (version !== undefined && version !== latestVersion) {
          // remove data from apollo cache to re-request it based on the latest values of feature flags
          await apolloClient.resetStore();
          // fetch current user to get the latest values of feature flags
          await fetchCurrentUser();
        }
        setVersion(latestVersion);
      }
    } catch (error) {
      Console.error(`Error while retrieving feature flags version: ${String(error)}`);
    }
  }, [apolloClient, fetchCurrentUser, version]);

  useEffectOnce(updateVersion, { when: pollingReasonable });

  useInterval(updateVersion, CHECK_PERIOD_IN_SECONDS * 1000, pollingReasonable);

  return (
    <RefreshFeatureFlagsContext.Provider value={{ refresh: updateVersion }}>
      {children}
    </RefreshFeatureFlagsContext.Provider>
  );
};
