import { Button, ExternalLink, getCosunoUiThemeValues } from '@cosuno/cosuno-ui';
import { ThemeProvider } from 'styled-components';

import googleChromeLogoUrl from '~/shared/assets/googleChromeLogo.svg';
import microsoftEdgeLogoUrl from '~/shared/assets/microsoftEdgeLogo.svg';
import mozillaFirefoxLogoUrl from '~/shared/assets/mozillaFirefoxLogo.svg';
import { SplitScreenUnauthenticatedPage } from '~/shared/components/UnauthenticatedPage';
import { Heading, Tip } from '~/shared/components/UnauthenticatedStyles';
import useTranslation from '~/shared/hooks/useTranslation';
import useUiTranslations from '~/shared/hooks/useUiTranslations';

import BaseStyles from '../App/BaseStyles';
import NormalizeStyles from '../App/NormalizeStyles';
import { BrowserIcon, Col, ExternalLinkIcon, Row } from './styles';

const CONTACT_EMAIL = 'info@cosuno.de';

const EndOfSupportMessageIE = () => {
  const { t } = useTranslation('ie11EndOfSupportMessage');

  const translations = useUiTranslations();

  return (
    <ThemeProvider
      theme={getCosunoUiThemeValues({
        translations,
        topOffset: 0,
      })}
    >
      <NormalizeStyles />
      <BaseStyles />
      <SplitScreenUnauthenticatedPage title={t('title')}>
        <Heading>{t('title')}</Heading>
        <Tip>{t('body')}</Tip>
        <Row>
          <Col>
            <BrowserIcon src={microsoftEdgeLogoUrl} alt={t('microsoftEdge')} />
            <ExternalLink to="https://www.microsoft.com/edge">
              {t('microsoftEdge')}
              <ExternalLinkIcon />
            </ExternalLink>
          </Col>
          <Col>
            <BrowserIcon src={googleChromeLogoUrl} alt={t('googleChrome')} />
            <ExternalLink to="https://www.google.com/chrome">
              {t('googleChrome')}
              <ExternalLinkIcon />
            </ExternalLink>
          </Col>
          <Col>
            <BrowserIcon src={mozillaFirefoxLogoUrl} alt={t('mozillaFirefox')} />
            <ExternalLink to="https://www.mozilla.org/firefox/download">
              {t('mozillaFirefox')}
              <ExternalLinkIcon />
            </ExternalLink>
          </Col>
        </Row>
        <Tip>{t('questions')}</Tip>
        <Button linkToExternal={`mailto:${CONTACT_EMAIL}`}>{t('contactUs')}</Button>
      </SplitScreenUnauthenticatedPage>
    </ThemeProvider>
  );
};

export default EndOfSupportMessageIE;
