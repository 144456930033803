import { useMutation } from '@apollo/client';
import { Clickable, COLOR, Icon, Label } from '@cosuno/cosuno-ui';
import moment from 'moment';
import React from 'react';

import { useFeatureFlags } from '~/packages/FeatureFlags';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useTranslation from '~/shared/hooks/useTranslation';
import { formatDate } from '~/shared/utils/dateFormatting';
import toast from '~/shared/utils/toast';

import { PromptBar, PromptButton, TextWrapper } from '../Styles';
import { mutationRequestContractExtension } from './api';

export const ContractEndingPrompt: React.FC = () => {
  const { t } = useTranslation('contractEndingPrompt');
  const { trackEvent } = useAnalytics();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();

  const [requestContractExtension] = useMutation(mutationRequestContractExtension);

  if (!currentUser) return null;

  if (featureFlags.defaultContractEndingWarning2 === false) return null;

  if (currentUser.company.contractEndDate === null) return null;

  const daysUntilEndDate = Math.abs(moment().diff(currentUser.company.contractEndDate, 'd'));

  if (daysUntilEndDate > 30) return null;

  return (
    <PromptBar
      as={Clickable}
      data-cy-contract-ending-prompt
      onClick={async () => {
        trackEvent('contractExtend', { userType: currentUser.type });
        try {
          await requestContractExtension();
          toast.success(t('common:upsells.successToast'));
        } catch (error) {
          toast.error(error);
        }
      }}
      $color={COLOR.highlightNeon}
    >
      <TextWrapper>
        <Icon type="info" color={COLOR.white} size={20} />
        <Label variant="large" color={COLOR.white}>
          {t('message', { date: formatDate(currentUser.company.contractEndDate) })}
        </Label>
      </TextWrapper>
      <PromptButton size="small">{t('buttonLabel')}</PromptButton>
    </PromptBar>
  );
};
