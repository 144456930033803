import { Button, RadioButtonGroup } from '@cosuno/cosuno-ui';
import React, { useState } from 'react';

import { MFA_METHOD } from '~/__gql__/graphql';
import { Heading, Tip } from '~/shared/components/UnauthenticatedStyles';
import useTranslation from '~/shared/hooks/useTranslation';

interface Props {
  setMfaMethod: (mfaMethod: MFA_METHOD) => void;
  companyName: string;
}

const ChooseMethod: React.FC<Props> = ({ setMfaMethod, companyName }) => {
  const { t } = useTranslation('auth');

  const [selectedMfaMethod, setSelectedMfaMethod] = useState(MFA_METHOD.phone);

  return (
    <>
      <Heading>{t('mfa.headline')}</Heading>
      <Tip>{t('mfa.setupExplanation')}</Tip>
      <Tip>{t('mfa.chooseMethod')}</Tip>
      <RadioButtonGroup
        name="mfaMethod"
        value={selectedMfaMethod}
        options={[
          { value: MFA_METHOD.phone, label: t('mfaMethods.phone') },
          { value: MFA_METHOD.authenticator, label: t('mfaMethods.authenticator') },
        ]}
        onChange={(value) => setSelectedMfaMethod(value)}
      />
      <Button
        onClick={() => {
          setMfaMethod(selectedMfaMethod);
        }}
      >
        {t('continue')}
      </Button>
      <Tip>{t('mfa.noAccessInstructions', { companyName })}</Tip>
    </>
  );
};

export default ChooseMethod;
