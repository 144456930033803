import { COLOR, Icon, Label } from '@cosuno/cosuno-ui';
import React from 'react';

import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useTranslation from '~/shared/hooks/useTranslation';

import { PromptBar, TextWrapper } from '../Styles';

export const OverdueInvoiceWarning: React.FC = () => {
  const { t } = useTranslation('overdueInvoiceWarning');
  const user = useCurrentUser();

  if (!user?.company.hasOverdueInvoice) {
    return null;
  }

  return (
    <PromptBar $color={COLOR.danger}>
      <TextWrapper>
        <Icon type="alert" color={COLOR.white} size={20} />
        <Label variant="large" color={COLOR.white}>
          {t('message')}
        </Label>
      </TextWrapper>
    </PromptBar>
  );
};
