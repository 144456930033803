import { useMutation } from '@apollo/client';
import React from 'react';

import Trans from '~/shared/components/Trans';
import useAnalytics from '~/shared/hooks/useAnalytics';

import { mutationMarkNotificationAsRead } from './api';
import {
  DateTime,
  Icon,
  Link,
  Text,
  TextContainer,
  UnreadIndicator,
} from './NotificationItem.styles';
import type { Notification } from './types';
import { useNotificationInfo } from './utils';

interface NotificationItemProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onClick }) => {
  const { trackEvent } = useAnalytics();
  const { path, icon, onNotificationClick, ...translationInfo } = useNotificationInfo(notification);
  const [markNotificationAsRead] = useMutation(mutationMarkNotificationAsRead, {
    refetchQueries: ['CombinedNotifications'],
  });

  const handleClick = () => {
    trackEvent('notificationsOpenEnter');
    void markNotificationAsRead({ variables: { id: notification.id } });
    onNotificationClick?.();
    onClick();
  };

  return (
    <Link to={path} onClick={handleClick} data-cy-notification-item={notification.__typename}>
      {!notification.isRead && <UnreadIndicator data-cy-unread-indicator />}
      <Icon $icon={icon} />
      <TextContainer>
        <Text data-cy-notification-item-text>
          <Trans {...translationInfo} />
        </Text>
        <DateTime date={notification.createdAt} />
      </TextContainer>
    </Link>
  );
};

export default NotificationItem;
