import { css } from 'styled-components';

export const font = {
  family: css`
    font-family: CircularStd, 'SF Pro', 'Segoe UI', system-ui, sans-serif;
  `,
  regular: css`
    font-weight: 450;
  `,
  medium: css`
    font-weight: 500;
  `,
  bold: css`
    font-weight: 700;
  `,
  black: css`
    font-weight: 900;
  `,
  size: (size: number) => css`
    font-size: ${size}px;
  `,
};
