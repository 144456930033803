import { ApolloError } from '@apollo/client';

import { ERROR_TYPES } from '~/shared/constants';
import toast from '~/shared/utils/toast';

export const handleLoginError = (
  error: unknown,
  options: {
    onInvalidCredentialsError: () => void;
    onUserNotApprovedError: () => void;
    onUserEmailNotConfirmedError: () => void;
    onUserLockedError: () => void;
    onCompanyTypeNotFoundError: () => void;
    onSsoUserPasswordLoginAttempt: () => void;
  },
) => {
  const firstError = error instanceof ApolloError && error.graphQLErrors[0];

  if (firstError) {
    const errorCode = firstError.extensions?.code;

    if (errorCode === 'INVALID_CREDENTIALS') {
      options.onInvalidCredentialsError();
      return;
    }

    if (errorCode === 'USER_NOT_APPROVED') {
      options.onUserNotApprovedError();
      return;
    }

    if (errorCode === 'USER_EMAIL_NOT_CONFIRMED') {
      options.onUserEmailNotConfirmedError();
      return;
    }

    if (errorCode === 'USER_LOCKED') {
      options.onUserLockedError();
      return;
    }

    if (errorCode === 'COMPANY_TYPE_NOT_FOUND') {
      options.onCompanyTypeNotFoundError();
      return;
    }

    if (errorCode === ERROR_TYPES.ssoUserPasswordLoginAttempt) {
      options.onSsoUserPasswordLoginAttempt();
      return;
    }
  }

  if (error instanceof Error) {
    toast.error(error);
  } else {
    toast.error();
  }
};
