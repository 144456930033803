import type sanitizeHtml from 'sanitize-html';

import type { MimeType } from '~/shared/utils/acceptFiles';

export const EMPTY_HTML_STRING = '<p></p>';

// keep this list of allowed tags in sync with the one in the backend
export const MESSAGE_HTML_ALLOWED_TAGS = [
  'p',
  'br',
  'ol',
  'ul',
  'li',
  'strong',
  'em',
  'u',
  's',
  'span',
  'a',
  'img',
];

export const MESSAGE_TEXT_HTML_ALLOWED_ATTRIBUTES: sanitizeHtml.IOptions['allowedAttributes'] = {
  span: ['style'],
  a: ['href', 'rel', 'target'],
  img: ['src', 'alt'],
};

export const MESSAGE_TEXT_HTML_ALLOWED_STYLES: sanitizeHtml.IOptions['allowedStyles'] = {
  span: {
    color: [/^#(0x)?[0-9a-f]+$/i],
    'font-size': [/^\d+(\.\d+)?pt$/],
    'font-family': [/^(?:Courier New|Times New Roman|Arial)$/],
  },
};

export enum TEXT_EDITOR_FONT_FAMILY {
  arial = 'arial',
  courierNew = 'courierNew',
  timesNewRoman = 'timesNewRoman',
}

export enum TEXT_EDITOR_COLOR {
  aquamarine = 'aquamarine',
  black = 'black',
  blue = 'blue',
  darkBlue = 'darkBlue',
  darkGreen = 'darkGreen',
  darkRed = 'darkRed',
  green = 'green',
  grey = 'grey',
  lightGrey = 'lightGrey',
  ocher = 'ocher',
  purple = 'purple',
  red = 'red',
  teal = 'teal',
  violet = 'violet',
  yellow = 'yellow',
}

export const IMAGE_MIME_TYPES: MimeType[] = ['image/png', 'image/jpeg'];
