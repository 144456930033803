import { PageLoader } from '@cosuno/cosuno-ui';
import React, { type PropsWithChildren, useCallback } from 'react';

import ScrollSentinel from '~/shared/components/ScrollSentinel';
import useRestoreScrollPosition from '~/shared/hooks/useRestoreScrollPosition';

export interface InfiniteScrollProps {
  hasMore: boolean;
  isLoadingMore: boolean;
  onLoadMore: () => void | Promise<void>;

  /**
   * The point that triggers `loadMore` can be offset by moving it upwards, such
   * that the user will hit it before hitting the end of the content. Expressed
   * in pixels.
   */
  loadMoreOffset?: number;

  containerRef?: React.MutableRefObject<HTMLElement | null>;

  callbackOnEveryIntersection?: boolean;
}

const InfiniteScroll: React.FC<PropsWithChildren<InfiniteScrollProps>> = ({
  children,
  hasMore,
  isLoadingMore,
  onLoadMore,
  loadMoreOffset = 100,
  containerRef,
  callbackOnEveryIntersection,
}) => {
  // If the container is not the viewport, the browser will reset the scroll position to 0 every
  // time new items are added. This effect resets the position to the last scroll position before
  // items were added.
  const restoreScrollPosition = useRestoreScrollPosition({
    containerRef,
  });

  const handleView = useCallback(() => {
    if (!hasMore) return;
    void onLoadMore();
    restoreScrollPosition();
  }, [hasMore, onLoadMore, restoreScrollPosition]);

  return (
    <>
      {children}
      <ScrollSentinel
        onView={handleView}
        offset={loadMoreOffset}
        containerRef={containerRef}
        callbackOnEveryIntersection={callbackOnEveryIntersection}
        data-cy-scroll-sentinel
        data-cy-has-more={hasMore}
      />
      {isLoadingMore && <PageLoader />}
    </>
  );
};

export default InfiniteScroll;
