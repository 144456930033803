import { ButtonGroup, COLOR, textStyles } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

import { mixins } from '~/shared/utils/styles';

// popover must use zero space if it's not open so the link positioner returns valid coordinates
export const PopoverWrapper = styled.div`
  position: absolute;
  height: 0;
  width: 0;
`;

export const PopoverContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  gap: 4px;
`;

export const PopoverLinkWrapper = styled.div`
  ${textStyles.labelSmall500};
  color: ${COLOR.textPrimary};
  margin-right: 10px;
  ${mixins.truncateText()};
`;
