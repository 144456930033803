import { useMutation } from '@apollo/client';
import { Button, ClickableText, PageLoader } from '@cosuno/cosuno-ui';
import React, { useEffect, useState } from 'react';

import { MFA_METHOD } from '~/__gql__/graphql';
import { MissingMfaToken } from '~/packages/Authentication/errors';
import Field from '~/shared/components/Field';
import Form from '~/shared/components/Form';
import MfaSetupForm from '~/shared/components/MfaSetupForm';
import { Heading, Tip } from '~/shared/components/UnauthenticatedStyles';
import type { LoginParams } from '~/shared/hooks/useAuthentication';
import useTranslation from '~/shared/hooks/useTranslation';
import toast from '~/shared/utils/toast';

import {
  mutationCheckVerificationCode,
  mutationRequestMfaReset,
  mutationSendVerificationCode,
} from './api';
import { ActionLinks, StyledLink } from './Styles';

interface Props {
  loginToken: string;
  mfaMethod: MFA_METHOD | null;
  mfaPhoneCensored: string | null;
  companyName: string;
  onLogIn: (params: LoginParams) => Promise<void>;
}

const MfaForm: React.FC<Props> = ({
  loginToken,
  mfaMethod,
  mfaPhoneCensored,
  companyName,
  onLogIn,
}) => {
  const { t } = useTranslation('auth');

  const [stage, setStage] = useState<'enterCode' | 'requestReset'>('enterCode');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [restoreAccessRequestSent, setRestoreAccessRequestSent] = useState(false);

  const [
    sendVerificationCode,
    { loading: sendingVerificationCode, called: sendVerificationCodeCalled },
  ] = useMutation(mutationSendVerificationCode);

  const [checkVerificationCode] = useMutation(mutationCheckVerificationCode);

  const [requestMfaReset, { loading: requestingMfaReset }] = useMutation(mutationRequestMfaReset);

  useEffect(() => {
    if (mfaMethod === MFA_METHOD.phone) {
      void sendVerificationCode({ variables: { loginToken } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mfaMethod === null) {
    return <MfaSetupForm companyName={companyName} loginToken={loginToken} onLogIn={onLogIn} />;
  }

  if (mfaMethod === MFA_METHOD.phone && (!sendVerificationCodeCalled || sendingVerificationCode)) {
    return <PageLoader />;
  }

  if (stage === 'requestReset') {
    return (
      <>
        <Heading>{t('mfa.restoreAccess')}</Heading>
        <Tip>
          <span>{t('mfa.restoreAccessExplanation')}</span>
          <span>{t('mfa.restoreAccessInstructions')}</span>
        </Tip>
        <Button
          fullWidth
          disabled={restoreAccessRequestSent}
          onClick={async () => {
            try {
              await requestMfaReset({ variables: { loginToken } });
              setRestoreAccessRequestSent(true);
              toast.success(t('mfa.restoreAccessRequestSent'));
            } catch (error) {
              toast.error(error);
            }
          }}
          working={requestingMfaReset}
        >
          {t('mfa.restoreAccessRequest')}
        </Button>
        <ActionLinks>
          <StyledLink onClick={() => setStage('enterCode')}>{t('back')}</StyledLink>
        </ActionLinks>
      </>
    );
  }

  return (
    <Form<{ code: string }>
      initialValues={{ code: '' }}
      validations={{
        code: Form.is.required(),
      }}
      onSubmit={async (values, helpers) => {
        try {
          setIsLoggingIn(true);

          const { data } = await checkVerificationCode({
            variables: { loginToken, code: values.code },
          });
          const mfaToken = data?.checkVerificationCode.mfaToken;

          if (mfaToken) {
            await onLogIn({ loginToken, mfaToken });
          } else {
            throw new MissingMfaToken();
          }
        } catch (error) {
          setIsLoggingIn(false);
          helpers.setFieldError('code', t('mfa.errorCodeInvalidOrExpired'));
          void helpers.setFieldTouched('code', true);
        }
      }}
    >
      <Heading>{t('mfa.headline')}</Heading>
      <Tip>
        {t('mfa.loginExplanation')}
        <span>
          {mfaMethod === MFA_METHOD.phone &&
            t('mfa.enterCodePrompt', { phone: mfaPhoneCensored ?? '' })}
          {mfaMethod === MFA_METHOD.authenticator && t('mfa.authenticatorLoginEnterCode')}
        </span>
      </Tip>
      <Form.Element>
        <Field.Input required name="code" label={t('mfa.enterCode')} />
        <Button fullWidth type="submit" working={isLoggingIn}>
          {t('continue')}
        </Button>
      </Form.Element>
      <ActionLinks>
        {mfaMethod === MFA_METHOD.phone && (
          <ClickableText
            onClick={async () => {
              try {
                await sendVerificationCode({ variables: { loginToken } });
                toast.success(t('mfa.resendCodeSuccess'));
              } catch (error) {
                toast.error(error);
              }
            }}
          >
            {t('mfa.resendCode')}
          </ClickableText>
        )}
        <ClickableText onClick={() => setStage('requestReset')}>
          {t('mfa.noAccessAnymore')}
        </ClickableText>
      </ActionLinks>
    </Form>
  );
};

export default MfaForm;
export { default as useMfaFormProps } from './useMfaFormProps';
