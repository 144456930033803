import { useCallback, useEffect, useMemo, useRef } from 'react';

import { INCREASED_SECURITY_FEATURES_AUTH_TOKEN_EXPIRES_AFTER_DURATION } from '~/shared/constants/authToken';
import { throttle } from '~/shared/utils/javascript';

import useLocalStorageState from './useLocalStorageState';

interface Props {
  onInactive: () => void;
}

interface InactivityTracker {
  start: () => void;
  stop: () => void;
}

/**
 * Tracks inactivity and calls onInactive if user does not trigger mousemove or keypress for the
 * specified duration. Storing the timestamp of the last activity in localStorage allows us to track
 * inactivity across multiple tabs. The user should only ever have to be active in one tab.
 */
const useInactivityTracker = ({ onInactive }: Props): InactivityTracker => {
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const currentTabLastActivityAt = useRef<number | null>(null);

  const [lastActivityAt, setLastActivityAt] = useLocalStorageState<number | null>(
    'useInactivityTracker.lastActivityAt',
    null,
  );

  const reset = useCallback(() => {
    if (timeoutId.current !== null) clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(
      handleTimeout,
      INCREASED_SECURITY_FEATURES_AUTH_TOKEN_EXPIRES_AFTER_DURATION.asMilliseconds(),
    );
    // We don't want to set onInactive as a dependency, because then the tracker would be reset
    // every time we refresh the token.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleActivity = useCallback(
    // Unless we throttle here, there will be a lot of writing to localStorage and synchronizing
    // localStorage in other tabs, which notable degrades the performance.
    throttle(() => {
      // If we notice an activity, we want to restart the timeout right away without waiting for our
      // state to be updated.
      if (timeoutId.current !== null) {
        reset();
      }
      const now = Date.now();
      currentTabLastActivityAt.current = now;
      setLastActivityAt(now);
    }, 1000),
    [],
  );

  /** Reset if there is a timeout and activity in another tab */
  useEffect(() => {
    if (timeoutId.current === null || lastActivityAt === currentTabLastActivityAt.current) {
      return;
    }
    reset();
  }, [reset, lastActivityAt]);

  const stop = useCallback(() => {
    document.removeEventListener('mousemove', handleActivity);
    document.removeEventListener('keypress', handleActivity);

    if (timeoutId.current !== null) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, [handleActivity]);

  const start = useCallback(() => {
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keypress', handleActivity);

    reset();
  }, [handleActivity, reset]);

  const handleTimeout = useCallback(() => {
    stop();
    onInactive();
  }, [onInactive, stop]);

  return useMemo(
    () => ({
      start,
      stop,
    }),
    [start, stop],
  );
};

export default useInactivityTracker;
