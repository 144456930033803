import { Button } from '@cosuno/cosuno-ui';

import useTranslation from '~/shared/hooks/useTranslation';

import { BackContainer } from './Styles';

export function Back({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation('agentAuth');

  return (
    <BackContainer alignContentWithText>
      <Button
        onClick={onClick}
        size="small"
        iconLeft={{ type: 'arrow-left' }}
        variant="secondaryBrand"
      >
        {t('revamp.back')}
      </Button>
    </BackContainer>
  );
}
