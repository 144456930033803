import { Callout, ClickableText } from '@cosuno/cosuno-ui';
import type { FC } from 'react';

import Trans from '~/shared/components/Trans';
import useTranslation from '~/shared/hooks/useTranslation';
import toast from '~/shared/utils/toast';

import { Section } from '../Styles';

export type LoginError =
  | 'isCodeExpired'
  | 'isInvalidCode'
  | 'isUserNotApproved'
  | 'isUserEmailNotConfirmed'
  | 'isUserDeactivated'
  | 'isCompanyTypeNotFound'
  | null;

interface ErrorMessagesProps {
  error: LoginError;
  resendConfirmationEmail: () => Promise<void>;
  hasResentConfirmationEmail: boolean;
}

export const ErrorMessages: FC<ErrorMessagesProps> = ({
  error,
  resendConfirmationEmail,
  hasResentConfirmationEmail,
}) => {
  const { t } = useTranslation();

  switch (error) {
    case null:
      return null;
    case 'isCodeExpired':
      return (
        <Section>
          <Callout icon="info" variant="danger">
            {t('passwordlessLoginForm:provideCode.expiredCallout')}
          </Callout>
        </Section>
      );
    case 'isUserNotApproved':
      return (
        <Section>
          <Callout icon="info" variant="danger">
            {t('apiErrors:userNotApproved')}
          </Callout>
        </Section>
      );
    case 'isInvalidCode':
      return (
        <Section>
          <Callout icon="info" variant="danger">
            {t('passwordlessLoginForm:provideCode.invalidCode')}
          </Callout>
        </Section>
      );
    case 'isUserEmailNotConfirmed': {
      return hasResentConfirmationEmail ? (
        <Section>
          <Callout icon="info" variant="success">
            {t('auth:confirmationEmailResentSuccess')}
          </Callout>
        </Section>
      ) : (
        <Section>
          <Callout icon="info" variant="danger">
            <Trans
              t={t}
              i18nKey="apiErrors:userEmailNotConfirmed"
              components={{
                Link: (
                  <ClickableText
                    onClick={async () => {
                      try {
                        await resendConfirmationEmail();
                      } catch (resendConfirmationEmailError) {
                        toast.error(resendConfirmationEmailError);
                      }
                    }}
                  />
                ),
              }}
            />
          </Callout>
        </Section>
      );
    }
    case 'isUserDeactivated': {
      return (
        <Section>
          <Callout icon="info" variant="danger">
            {t('passwordlessLoginForm:provideCode.userDeactivated')}
          </Callout>
        </Section>
      );
    }
    case 'isCompanyTypeNotFound': {
      return (
        <Section>
          <Callout icon="info" variant="danger">
            {t('auth:companyTypeNotFoundError')}
          </Callout>
        </Section>
      );
    }
  }
};
