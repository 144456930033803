import { Button, COLOR } from '@cosuno/cosuno-ui';
import styled from 'styled-components';

export const CelloButtonWrapper = styled.div`
  position: relative;
`;

export const CelloButton = styled(Button).attrs({ hollow: true })`
  /* Make sure Cello notification badge does not push icon to the left */
  gap: 0;

  div:last-child > div {
    left: 18px;
    top: 2px;
    pointer-events: none;

    & > div {
      background: ${COLOR.danger};
    }
  }
`;
