import { Button, HoverableElement } from '@cosuno/cosuno-ui';
import React, { useContext } from 'react';

import CombinedNotificationsContext from '~/shared/contexts/combinedNotificationsContext';
import useAnalytics from '~/shared/hooks/useAnalytics';
import useCurrentUser from '~/shared/hooks/useCurrentUser';
import useRoutes from '~/shared/hooks/useRoutes';
import useTranslation from '~/shared/hooks/useTranslation';

import { ButtonWithCountBadgeWrapper, CountBadge } from './Styles';
import { getBadgeNumberWithMax3Digits } from './utils';

const Tasks: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const routes = useRoutes();

  const currentUser = useCurrentUser();

  const { combinedNotificationsResponse } = useContext(CombinedNotificationsContext);

  if (
    !currentUser ||
    !combinedNotificationsResponse?.data ||
    combinedNotificationsResponse.data.myTasksInStatusCount === undefined
  ) {
    return null;
  }

  const tasksCount = getBadgeNumberWithMax3Digits(
    combinedNotificationsResponse.data.myTasksInStatusCount,
  );

  return (
    <>
      <HoverableElement isTriggerElementClickable tooltipText={t('tooltips.icons.tasks')}>
        <ButtonWithCountBadgeWrapper>
          <Button
            data-cy-task-icon
            variant="secondary"
            linkTo={routes.myTasks()}
            onlyIcon={{ type: 'task' }}
            onClick={() => {
              trackEvent('topNavigationTasks', {
                userType: currentUser.type,
                userCompanyType: currentUser.company.type,
              });
            }}
          />
          {tasksCount ? <CountBadge data-cy-task-count-badge>{tasksCount}</CountBadge> : null}
        </ButtonWithCountBadgeWrapper>
      </HoverableElement>
    </>
  );
};

export default Tasks;
