import { useCallback, useMemo, useState } from 'react';

import Console from '~/shared/utils/console';

interface StackMethods<T> {
  peek: () => T;
  pop: () => void;
  push: (value: T) => void;
  toggle: (value: T) => void;
  contains: (value: T) => boolean;
  remove: (value: T) => void;
  isEmpty: boolean;
  debug: () => void;
}

/**
 * This implements a LIFO stack.
 */
const useStack = <T>(initialValue?: T): StackMethods<T> => {
  const [stack, setStack] = useState(initialValue ? [initialValue] : ([] as T[]));

  const peek = useCallback(() => stack[stack.length - 1], [stack]);
  const pop = useCallback(() => setStack((currentStack) => currentStack.slice(0, -1)), []);
  const push = useCallback((value: T) => setStack((currentStack) => [...currentStack, value]), []);
  const toggle = useCallback(
    (value: T) => (peek() === value ? pop() : push(value)),
    [peek, pop, push],
  );
  const contains = useCallback((value: T) => stack.includes(value), [stack]);
  const remove = useCallback(
    (value: T) =>
      setStack((currentStack) => {
        if (currentStack.includes(value)) {
          return currentStack.filter((item) => item !== value);
        }
        return currentStack;
      }),
    [],
  );
  const isEmpty = stack.length === 0;
  const debug = useCallback(() => {
    Console.log(stack);
  }, [stack]);

  return useMemo(
    () => ({ peek, pop, push, toggle, contains, remove, isEmpty, debug }),
    [contains, debug, isEmpty, peek, pop, push, remove, toggle],
  );
};

export default useStack;
