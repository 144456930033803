import { graphql } from '~/__gql__';

export const queryCombinedNotifications = graphql(/* GraphQL */ `
  query CombinedNotifications(
    $offset: Int!
    $limit: Int!
    $includeMessageNotifications: Boolean!
    $includeTaskNotifications: Boolean!
  ) {
    notifications(offset: $offset, limit: $limit) {
      notifications {
        ... on Notification {
          id
          isRead
          createdAt
        }
        ... on AwardBidderReminderNotification {
          id
          props {
            bidPackageId
            bidPackageName
            bidPackageBidsDueWeeksAgo
            projectId
            projectName
          }
        }
        ... on BiddersAwaitingResponseNotification {
          id
          props {
            ... on BiddersAwaitingResponseNotificationV1Props {
              bidPackageId
              bidPackageName
              bidPackageBidsDueWeeksAgo
              projectId
              projectName
              subcontractorNames
            }
            ... on BiddersAwaitingResponseNotificationV2Props {
              bidPackageId
              bidPackageName
              projectId
              projectName
              subcontractorNames
              durationSinceBidsDue {
                value
                unit
              }
            }
          }
        }
        ... on BiddingPeriodEndingNotification {
          id
          props {
            bidPackageId
            bidPackageName
            bidsCount
            endingInDays
            projectId
            projectName
          }
        }
        ... on BidPackageExportBidsNotification {
          id
          props {
            projectId
            projectName
            bidPackageId
            bidPackageName
            downloadUrl
          }
        }
        ... on BidRequestDeclinedNotification {
          id
          props {
            projectId
            projectName
            bidPackageId
            bidPackageName
            subcontractorName
            bidRequestId
          }
        }
        ... on CertificateExpiringNotification {
          id
          props {
            certificateTypeName
            certificateTypeId
            expiresInDays
            subcontractorId
            subcontractorName
          }
        }
        ... on CertificateExpiringSubcontractorNotification {
          id
          props {
            certificateTypeName
            expiresInDays
          }
        }
        ... on CertificateNotProvidedInTimeNotification {
          id
          props {
            certificateTypeNames
            subcontractorId
            subcontractorName
          }
        }
        ... on CertificateUploadedBySubcontractorNotification {
          id
          props {
            certificateTypeNames
            subcontractorId
            subcontractorName
          }
        }
        ... on ContractAgentSignatureRequestedNotification {
          id
          props {
            awardedBidId
            bidPackageName
            projectName
            officeName
          }
        }
        ... on ContractMultiSigningCompletedNotification {
          id
          props {
            bidPackageId
            bidPackageName
            projectId
            projectName
          }
        }
        ... on ContractPrincipalSignatureRequestedNotification {
          id
          props {
            bidPackageId
            bidPackageName
            projectId
            projectName
          }
        }
        ... on ContractSignedByAgentNotification {
          id
          props {
            bidPackageId
            bidPackageName
            projectId
            projectName
            subcontractorName
          }
        }
        ... on NewBidNotification {
          id
          props {
            bidId
            bidPackageId
            bidPackageName
            bidRequestId
            projectId
            projectName
            subcontractorName
            teamMemberName
            totalNet
            taxRate
            skontoRate
            skontoDeadline
            discountRate
            absoluteDiscount
            additionalCosts
            currency
          }
        }
        ... on NewMessageNotification {
          id
          props {
            bidPackageId
            bidPackageName
            bidRequestId
            messageThreadId
            projectId
            projectName
            authorId
            authorName
            authorType
          }
        }
        ... on InvoiceAwaitingApprovalNotification {
          id
          props {
            invoiceId
            invoiceNumber
            invoiceName
            bidPackageName
            projectName
          }
        }
        ... on InvoiceRejectedNotification {
          id
          props {
            invoiceId
            invoiceNumber
            invoiceName
            bidPackageName
            projectName
          }
        }
        ... on SubcontractorImportRequestCompletedNotification {
          id
          props {
            importRequestId
          }
        }
        ... on ProjectExportCompleteNotification {
          id
          props {
            projectName
            downloadUrl
          }
        }
        ... on TaskAssignedNotification {
          id
          props {
            taskId
            taskName
          }
        }
        ... on TaskCompletedNotification {
          id
          props {
            taskId
            taskName
          }
        }
        ... on BidPackageStatusChangedNotification {
          id
          props {
            status
            bidPackageId
            bidPackageName
            projectName
            projectId
          }
        }
      }
      totalRecords
      totalUnread
    }
    unreadMessageThreadsCount @include(if: $includeMessageNotifications)
    myTasksInStatusCount(status: todo) @include(if: $includeTaskNotifications)
  }
`);
