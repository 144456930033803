import type { QueryResult } from '@apollo/client';
import { authFor, useAuth } from '@cosuno/authorization-frontend';

import {
  type CombinedNotificationsQuery,
  type CombinedNotificationsQueryVariables,
  COMPANY_TYPE,
} from '~/__gql__/graphql';
import { isCompanyFeatureEnabled } from '~/packages/FeatureFlags';
import { AUTH_STATUS } from '~/shared/constants';
import type { AuthState } from '~/shared/hooks/useAuthentication';

import { usePollWhenVisible } from '../usePollWhenVisible';
import { queryCombinedNotifications } from './api';

export interface CombinedNotificationsResponse {
  combinedNotificationsResponse?: QueryResult<
    CombinedNotificationsQuery,
    CombinedNotificationsQueryVariables
  >;
}

const useCombinedNotifications = (authState: AuthState): CombinedNotificationsResponse => {
  const {
    results: { canViewMessages },
    loading: loadingAuth,
  } = useAuth({
    canViewMessages:
      authState.status === AUTH_STATUS.authenticated &&
      authFor('BidPackage').canI('view__messages').any(),
  });

  const includeMessageNotifications =
    authState.status === AUTH_STATUS.authenticated &&
    (authState.companyType !== COMPANY_TYPE.principal || canViewMessages);

  const includeTaskNotifications =
    authState.status === AUTH_STATUS.authenticated &&
    isCompanyFeatureEnabled(authState.user.company, 'featureTaskManagement');

  const combinedNotificationsResponse = usePollWhenVisible(queryCombinedNotifications, {
    variables: {
      offset: 0,
      limit: 10,
      includeMessageNotifications,
      includeTaskNotifications,
    },
    skip: authState.status !== AUTH_STATUS.authenticated || loadingAuth,
    pollInterval: 60 * 1000,
  });

  return { combinedNotificationsResponse };
};

export default useCombinedNotifications;
