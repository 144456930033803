import { Redirect, useLocation } from 'react-router-dom';

import useRoutes from '~/shared/hooks/useRoutes';
import { queryStringToObject } from '~/shared/utils/url';

interface MarketplaceSuggestionQueryParams {
  bidPackageId?: string;
  sentEmailId?: string;
  subcontractorId?: string;
}

export const MarketplaceSuggestionRedirect = () => {
  const routes = useRoutes();
  const location = useLocation();

  const { bidPackageId, ...queryParams } = queryStringToObject<MarketplaceSuggestionQueryParams>(
    location.search,
  );

  if (!bidPackageId) {
    return <Redirect to={routes.agentReverseMarketplace()} />;
  }

  return <Redirect to={routes.reverseMarketplaceBidPackage(bidPackageId, queryParams)} />;
};
