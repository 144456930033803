import { type ClickableProps, COLOR, Icon } from '@cosuno/cosuno-ui';
import React from 'react';

import useTranslation from '~/shared/hooks/useTranslation';

import { Wrapper } from './Styles';

const AddMoreLink: React.FC<ClickableProps> = ({ children, disabled, ...props }) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props} data-cy-add-more-link disabled={disabled}>
      <Icon color={disabled ? COLOR.textTertiary : COLOR.brand} size={16} type="plus" />
      <div>{children || t('addMore')}</div>
    </Wrapper>
  );
};

export default AddMoreLink;
